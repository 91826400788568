import React, { Component } from 'react';
import styles from './pipefyTable.scss';

export default class PipefyTableHeader extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <thead className={styles.pipefy_table_header}>
                <tr>
                    {
                        this.props.header.map(header => (
                            <th style={{width: header.width || 'auto'}}>{ header.title }</th>
                        ))
                    }
                </tr>
            </thead>
        );
    }
}