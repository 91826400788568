import React, { Component } from 'react'

import styles from './modal.scss';

export default class Modal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, backdrop, rest } = this.props;
        return (
            <div className="modalContainer" {...rest}>
                <div className="box_modal">{children}</div>
                
                { backdrop && <div className={styles.modalBackdrop}></div>}
            </div>
        )
    }
}

Modal.defaultProps = {
    backdrop: true
}