import { AutoComplete } from 'common/MaterialForm';
import { NButton, NButtonGroup, NDatePickerInput, NForm, NFormTitle, NInputTime } from 'common/NForm';
import { request, requestRelatorio } from 'common/request';
import { default as MultiSelect } from 'components/AutoComplete';
import { Checkbox, Divider, RaisedButton } from 'material-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import { APIEndpoints } from '../../common/Constants';
import MonthYearRangePicker from 'components/MonthYearRangePicker';
import { orderBy } from 'lodash';
import moment from 'moment';

import styles from '../menuRelatorio.scss';

const ModalRelatorioOee = ({
    closeModal
}) => {
    const { t } = useTranslation('reports');
    const [firstNoteAt, setFirstNoteAt] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState();
    const [equipment, setEquipment] = useState([]);
    const [shifts, setShifts] = useState([]);

    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(7);

    const [selectedPeriodGrouping, setSelectedPeriodGrouping] = useState('daily');
    const [selectedRange, setSelectedRange] = useState(
        {
            start: moment().subtract(7, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        });

    const [selectedCustomShift, setSelectedCustomShift] = useState({
        startTime: moment().format("HH:mm:ss"),
        endTime: moment().format("HH:mm:ss")
    })

    const [oeeReport, setOeeReport] = useState(true);
    const [classeReport, setClasseReport] = useState(false);
    const [eventoReport, setEventoReport] = useState(false);
    const [manutencaoReport, setManutencaoReport] = useState(false);
    const [customIndicatorReport, setCustomIndicatorReport] = useState(false);
    const [segmentedByEquipment, setSegmentedByEquipment] = useState(false);

    useEffect(() => {
        setLoading(true);

        Promise.all([
            loadEquipment(),
            loadEquipmentGroups(),
            loadFirstNote(),
            loadShifts()
        ]).then((response) => {
            let result = orderBy(response[1], ['name'], 'asc');
            result = result.concat(orderBy(response[0], ['name'], 'asc'));

            setEquipment(result);
        }).finally(() => setLoading(false))
    }, []);

    const loadFirstNote = useCallback(() => {
        return request(APIEndpoints.APONTAMENTO + '/primeiro', { method: 'get' })
            .then((response) => {
                if (response.status == 200) return response.json();
            })
            .then(data => {
                if (data !== null) setFirstNoteAt(new Date(data.inicio));

                return data;
            });
    }, [])

    const loadEquipment = useCallback(() => {
        return request(APIEndpoints.EQUIPAMENTOS, { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { return data.map(c => ({ id: c.id, name: c.nome, isGroup: false })) });
    }, [])

    const loadEquipmentGroups = useCallback(() => {
        return request(APIEndpoints.GRUPO_EQUIPAMENTO + '?comEquipamento=true', { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { if (data) return data.map(c => ({ id: c.id, uuid: c.uuid, name: c.nome, isGroup: true, equipamentos: c.equipamentos })) });
    }, [])

    const loadShifts = useCallback(() => {
        return request(APIEndpoints.TURNO, { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => {
                let values = data.map(c => ({ id: c.id, name: c.nome }));

                values = values.concat({ id: 0, name: t("labels.customize", { ns: 'common' }) })

                setShifts(values)
            });
    }, [])

    const handleEquipmentSelect = (searchText) => {
        var group = equipment.filter(e => e.name.trim() == searchText.trim() && e.isGroup)[0];

        let equipmentIds = [];
        let groupId;

        if (group) {
            equipmentIds = group.equipamentos.map(e => e.id);
            groupId = group.uuid;
        } else {
            let eq = equipment.filter(e => e.name.trim() == searchText.trim() && !e.isGroup)[0];

            if (eq) {
                equipmentIds.push(eq.id);
            }
            groupId = 0;
        }

        setSelectedEquipment(equipmentIds);
        setSelectedGroup(groupId);
    }

    const handlePeriodChanged = (e) => {
        let value = parseInt(e.currentTarget.value);

        let range = {
            start: moment().subtract(value, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        }

        if (value == 0) {
            range.start = moment().subtract(30, 'days').startOf('day').toDate(),
                range.end = moment().endOf('day').toDate()
        }

        setSelectedPeriod(value);
        setSelectedRange(range);
    }

    const handlePeriodGroupingChanged = (e) => {
        let value = e.currentTarget.value;

        let range = {
            start: moment().subtract(30, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        }

        if (value === 'weekly') {
            range.start = moment().startOf('week').toDate(),
                range.end = moment().endOf('week').toDate()
        }

        if (value === 'monthly') {
            range.start = moment().subtract(1, 'month').startOf('month').toDate(),
                range.end = moment().endOf('month').toDate()
        }

        setSelectedPeriodGrouping(value);
        setSelectedRange(range);
    }

    const handleStartDate = (date) => {
        let range = {
            start: moment(date).startOf('day').toDate(),
            end: moment(date).endOf('day').toDate()
        }

        setSelectedRange(range);
    }

    const handleStartTime = (value) => {
        let parsedTime = value.split(':');

        let range = {
            start: moment(selectedRange.start).set(
                {
                    hour: parsedTime[0],
                    minute: parsedTime[1],
                    second: parsedTime[2]
                }).toDate(),
            end: selectedRange.end
        }

        setSelectedRange(range);
    }

    const handleEndDate = (date) => {
        let range = {
            start: selectedRange.start,
            end: moment(date).endOf('day').toDate()
        }

        setSelectedRange(range);
    }

    const handleEndTime = (value) => {
        let parsedTime = value.split(':');

        let range = {
            start: selectedRange.start,
            end: moment(selectedRange.end).set(
                {
                    hour: parsedTime[0],
                    minute: parsedTime[1],
                    second: parsedTime[2]
                }).toDate()
        }

        setSelectedRange(range);
    }

    const submit = () => {
        var erroCampo = false;

        if (!erroCampo) {
            setSending(true);

            let agrupamento;

            if (selectedPeriodGrouping == 'monthly' || selectedPeriod == 90) {
                agrupamento = 'mensal'
            } else if (selectedPeriodGrouping == 'weekly') {
                agrupamento = 'semanal'
            }
            else {
                agrupamento = 'diario'
            }

            let startDAte = moment(selectedRange.start).format('DD-MM-YYYY HH:mm:ss');
            let endDate = moment(selectedRange.end).format('DD-MM-YYYY HH:mm:ss')

            let equipamentos = selectedEquipment.map((e, i) => {
                return `equipamentos[${i}]=${e}`;
            }).join('&');

            let customShiftStart = "00:00:00";
            let customShiftEnd = "23:59:59";

            let hasCustomShift = selectedShifts.map(c => c.id).includes(0);

            if (hasCustomShift) {
                customShiftStart = selectedCustomShift.startTime;
                customShiftEnd = selectedCustomShift.endTime;
            }

            let params = `/${startDAte}/${endDate}/${oeeReport}/${eventoReport}/${classeReport}/${manutencaoReport}/${customIndicatorReport}/${segmentedByEquipment}/${hasCustomShift}/${customShiftStart}/${customShiftEnd}?${equipamentos}&agrupamento=${agrupamento}`;

            let turnos = selectedShifts.map((e, i) => {
                return `turnos[${i}]=${e.id}`;
            }).join('&');

            if (turnos) {
                params += `&${turnos}`;
            }

            if (selectedGroup) {
                params += `&grupoEquipamentoUuid=${selectedGroup}`
            }

            return requestRelatorio(
                APIEndpoints.RELATORIO_OEE + params,
                t("titles.oeeReport"))
                .then(() => handleCloseModal())
                .catch(error => {
                    console.log(error);
                    setError({
                        message: t("errors.cannotGenerateReport")
                    })
                })
                .finally(() => setSending(false));
        }
    }

    const handleCloseModal = () => {
        closeModal ? closeModal() : null;
    }


    const modalError = () => {
        return (
            <div className="modal" style={{ width: '500px', height: '100%' }}>
                <div>
                    <div style={{ textAlign: 'left', fontWeight: 'bold' }} >
                        {t("labels.warning", { ns: 'common' })}
                    </div>
                    <div>
                        <i className="fa fa-close cursor-pointer" onClick={handleCloseModal} />
                    </div>
                </div>
                <div style={{ textAlign: 'left' }} >
                    {error.message}
                </div>
                {
                    error.support ?
                        <div style={{ paddingTop: '1em' }} >
                            E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
                        </div>
                        :
                        null
                }
                <NButtonGroup>
                    <NButton style={{ backgroundColor: '#2D4F7F', color: 'white' }} onClick={handleCloseModal}>OK</NButton>
                </NButtonGroup>
            </div>
        )
    }

    return <>
        {loading ?
            <div className="report_oee">
                <div className={'loading-wrapper'}>
                    <MDSpinner singleColor='#2D4F7F' size={60} />
                </div>
            </div>
            :
            error ?
                modalError()
                :
                <div className="report_oee">
                    <NForm style={{ minWidth: '36em' }}>
                        <NFormTitle>{t("titles.oeeReport")}</NFormTitle>
                        <div className={'flex flex-flow-col flex-just-between'} style={{ marginTop: '-2em', height: '14em', paddingBottom: '3em' }}>
                            <span style={{ paddingTop: '1em' }}>
                                <AutoComplete
                                    textFieldStyle={{ marginTop: '-10px' }}
                                    fullWidth={true}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    floatingLabelStyle={{ color: '#5B5B5B' }}
                                    listStyle={{ width: '100%' }}
                                    floatingLabelText={t("labels.equipment")}
                                    title={t("labels.equipment")}
                                    onUpdateInput={handleEquipmentSelect}
                                    filter={AutoComplete.fuzzyFilter}
                                    dataSource={equipment}
                                    dataSourceConfig={{ value: 'id', text: 'name' }}
                                    openOnFocus={true}
                                    popoverProps={{ className: 'autocomplete' }} />

                            </span>

                            <div className={'flex'} style={{ marginTop: '-3em', cursor: 'not-allowed' }}>
                                <input
                                    className={'disabledDiv'}
                                    style={{
                                        width: '100%',
                                        outline: 0,
                                        borderWidth: '0 0 2px',
                                        borderColor: 'rgba(91, 91, 91, 0.2)',
                                        pointerEvents: 'none',
                                        backgroundColor: 'rgba(91, 91, 91, 0.1)',
                                        color: 'rgba(91, 91, 91)',
                                        marginTop: 20
                                    }}
                                    value={t("labels.product")}
                                ></input>
                            </div>

                            <div className={'flex'} style={{ marginTop: '-2em', maxWidth: '33em' }}>
                                <div style={{ width: '56.5%' }}>
                                    <MultiSelect
                                        isMulti
                                        value={selectedShifts}
                                        options={shifts}
                                        allowSelectAll={false}
                                        placeholder={t("labels.shift")}
                                        onGetOptionLabel={(option) => option.name}
                                        onGetOptionValue={(option) => option.id}
                                        onSelectOption={(e) => setSelectedShifts(e)}
                                    />
                                </div>
                                {
                                    selectedShifts.map(c => c.id).includes(0)
                                        ?
                                        <div style={{ width: '40%', marginLeft: '2em', marginTop: '-2em' }} className={'flex'}>
                                            <NInputTime
                                                type='time'
                                                step={1}
                                                label={t("labels.startHour")}
                                                style={{ marginRight: 10 }}
                                                value={selectedCustomShift.startTime}
                                                onChange={(_, value) => setSelectedCustomShift({ startTime: value, endTime: selectedCustomShift.endTime })}
                                                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9em', background: '#fff' }}
                                            />

                                            <NInputTime
                                                type='time'
                                                step={1}
                                                label={t("labels.endHour")}
                                                value={selectedCustomShift.endTime}
                                                onChange={(_, value) => setSelectedCustomShift({ startTime: selectedCustomShift.startTime, endTime: value })}
                                                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9em', background: '#fff' }}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <Divider />

                        <h3 style={{ paddingTop: '1.5em', paddingBottom: '1.5em', fontSize: '.8em', textTransform: 'uppercase' }}> {t("labels.selectPeriod")} <span style={{ fontSize: '.7em' }} > ({t("labels.days")}) </span> </h3>
                        <div className="control-group">
                            <div style={{ textAlign: 'center' }}>
                                <label>7</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 7} value={7} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label>15</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 15} value={15} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label>30</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 30} value={30} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label>90</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 90} value={90} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label style={{ marginLeft: '-2em' }}>{t("labels.customize", { ns: 'common' })}</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 0} value={0} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                        </div>

                        {
                            selectedPeriod == 0
                                ?
                                <div>
                                    <div className="control-group">
                                        <div style={{ width: '21.26%' }}>
                                            <label style={{ fontSize: '.8em', paddingLeft: '2.3em', textTransform: 'uppercase' }}>{t("date.period.daily", { ns: 'common' })}</label>
                                            <label style={{ width: '21.26%' }} className="control control-radio">
                                                <input checked={selectedPeriodGrouping == 'daily'} value="daily" type="radio" name="agrupamento" onChange={handlePeriodGroupingChanged} />
                                                <div style={{ top: '-1.2em' }} className="control_indicator"></div>
                                            </label>
                                        </div>

                                        <div style={{ width: '21.26%' }}>
                                            <label style={{ fontSize: '.8em', paddingLeft: '2.3em', textTransform: 'uppercase' }}>{t("date.period.weekly", { ns: 'common' })}</label>
                                            <label style={{ width: '21.26%' }} className="control control-radio">
                                                <input checked={selectedPeriodGrouping == 'weekly'} value="weekly" type="radio" name="agrupamento" onChange={handlePeriodGroupingChanged} />
                                                <div style={{ top: '-1.2em' }} className="control_indicator"></div>
                                            </label>
                                        </div>

                                        <div style={{ width: '26%' }}>
                                            <label style={{ fontSize: '.8em', paddingLeft: '2.3em', textTransform: 'uppercase' }}>{t("date.period.monthly", { ns: 'common' })}</label>
                                            <label className="control control-radio">
                                                <input checked={selectedPeriodGrouping == 'monthly'} value="monthly" type="radio" name="agrupamento" onChange={handlePeriodGroupingChanged} />
                                                <div style={{ top: '-1.2em' }} className="control_indicator"></div>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        selectedPeriodGrouping === 'daily' || selectedPeriodGrouping === 'weekly'
                                            ?
                                            <div className={'flex'} style={{ marginTop: '-2em', marginBottom: '1em' }}>
                                                <div style={{ margin: '0 0.8em', width: '25%' }}>
                                                    <NDatePickerInput
                                                        id="start_date"
                                                        label={t("labels.startDate")}
                                                        value={moment(selectedRange.start)}
                                                        maxDate={moment()}
                                                        onChange={handleStartDate}
                                                        onChangeRaw={(_, date) => { handleStartDate(date) }}
                                                        autoComplete="off" />
                                                </div>
                                                <NInputTime
                                                    type='time'
                                                    step={1}
                                                    label={t("labels.startHour")}
                                                    style={{ width: '28%', marginRight: '3.5%' }}
                                                    value={moment(selectedRange.start).format('HH:mm:ss')}
                                                    onChange={(_, value) => handleStartTime(value)}
                                                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'uppercase' }}
                                                />

                                                <div style={{ margin: '0 0.8em', width: '25%' }}>
                                                    <NDatePickerInput
                                                        id="end_date"
                                                        value={moment(selectedRange.end)}
                                                        minDate={selectedRange.start ? selectedRange.start : null}
                                                        maxDate={selectedRange.start && moment().diff(moment(selectedRange.start), 'days') > 30 ? moment(selectedRange.start).add(30, 'days') : moment()}
                                                        label={t("labels.endDate")}
                                                        onChange={date => handleEndDate(date)}
                                                        onChangeRaw={(_, date) => handleEndDate(date)}
                                                        autoComplete="off" />
                                                </div>

                                                <NInputTime
                                                    type='time'
                                                    step={1}
                                                    label={t("labels.endHour")}
                                                    style={{ width: '26%', marginRight: '3.5%' }}
                                                    value={moment(selectedRange.end).format('HH:mm:ss')}
                                                    onChange={(_, value) => handleEndTime(value)}
                                                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'uppercase' }}
                                                />
                                            </div>
                                            :
                                            <div className={'flex'} style={{ marginTop: '-2em', marginBottom: '.6em', width: '35em' }}>
                                                <MonthYearRangePicker
                                                    startDate={selectedRange.start}
                                                    endDate={selectedRange.end}
                                                    onRangeChange={(range) => setSelectedRange(range)}
                                                    minDate={firstNoteAt} />
                                            </div>
                                    }
                                </div>
                                :
                                null
                        }

                        <Divider />

                        <h3 style={{ paddingTop: '1.5em', paddingBottom: '1.5em', fontSize: '.8em', justifyContent: 'space-between', textTransform: 'uppercase' }}> {t("labels.showOnReport")}</h3>
                        <div className={'flex flex-flow-col flex-just-between'} style={{ paddingBottom: '1.5em' }} >
                            <div>
                                <Checkbox
                                    label={t("labels.oee")}
                                    checked={oeeReport}
                                    onCheck={((_, value) => setOeeReport(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label={t("labels.classes")}
                                    checked={classeReport}
                                    onCheck={((_, value) => setClasseReport(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label={t("labels.event")}
                                    checked={eventoReport}
                                    onCheck={((_, value) => setEventoReport(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label={t("labels.maintenance")}
                                    checked={manutencaoReport}
                                    onCheck={((_, value) => setManutencaoReport(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label={t("labels.customIndicators")}
                                    checked={customIndicatorReport}
                                    onCheck={((_, value) => setCustomIndicatorReport(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label={t("labels.groupedByEquipment")}
                                    checked={segmentedByEquipment}
                                    onCheck={((_, value) => setSegmentedByEquipment(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                    disabled={selectedGroup == 0}
                                />
                                <i id="iconMA"
                                    style={{ color: 'gray', position: 'relative', left: 250, top: -21, zIndex: 3 }}
                                    className={'fa fa-question-circle fa-md'}
                                    title={t("labels.forEquipmentGroup")}
                                />
                            </div>
                        </div>

                        <div className={'flex flex-just-end'}>
                            <RaisedButton
                                onClick={submit}
                                disabled={selectedEquipment.length == 0 || sending}
                                backgroundColor='#2D4F7F'
                                buttonStyle={{ fontFamily: '' }}
                                style={{ marginRight: '1em' }}
                                labelColor='#FFF'
                                label={t("labels.generate", { ns: 'common' })} />
                            <RaisedButton onClick={handleCloseModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} label={t("labels.cancel", { ns: 'common' })} />
                        </div>
                    </NForm>
                </div>

        }
    </>
}

export default ModalRelatorioOee;