import { observable, computed, action } from "mobx";
import moment from 'moment';

import { APIEndpoints } from '../../../common/Constants';
import { request } from "../../../common/request";

class AlertVigenciaRotas {
    // Necessario pois como existem dois tipos de invocar modal
    // Posteriormente realizar uma classe para o submit

    @observable customNoFunction
    @observable customYesFunction
    @observable index = undefined

    _submit(values, method, url, header) {
        request(`${APIEndpoints.TIMEMACHINE}${url}`, {
            method: method,
            headers: header,
            body: JSON.stringify(values)
        }).then(response => {
            if (response.status === 200) return response.json()
        }).then(data => data);
    }
    _resetNewItensId(dataToSend) {
        return dataToSend.map(data => {
            return {
                originalId: data.originalId,
                originalUuid: data.originalUuid,
                dataEfetivo: moment(data.dataEfetivo),
                ativo: data.ativo,
                id: !data.destinoId ? 0 : data.id,
                destinoId: data.destinoId,
                Observacao: data.observacoes,
                novo: data.novo
            }
        })

    }


    @action
    Yes = (itemIndex, stateArray ) => {
        let arrayResetado = this._resetNewItensId(stateArray)
        let item = stateArray[itemIndex]
        
        // Pega o item no arrayResetado para verificar se ja possui no banco se sim entao ele lanca um post para exclui-lo
        let itemVerificacao = arrayResetado[itemIndex]
        
        stateArray.splice(itemIndex, 1)
        
        if (itemVerificacao.id != 0){
            this._submit(item, 'post', '/rotas/vigencia/excluir', { 'Content-Type': 'application/json' })
        }
        
        return stateArray
    }
    @action
    No = () => {
        this.customNoFunction();
    }

    @action
    setIndex = index => {
        this.index = index;
    }

    @action
    setCustomNoFunction =  customNoFunction => {
        this.customNoFunction = customNoFunction;
    }
    @action
    setCustomYesFunction = customYesFunction => {
        this.customYesFunction = customYesFunction;
    }

    @computed get CustomNoFunction(){
        return this.customNoFunction;
    }

    @computed get CustomYesFunction(){
        return this.customYesFunction
    }
    @computed get Index(){
        return this.index;
    }

}

export default AlertVigenciaRotas