import React, { Component } from 'react';
import { FormInput } from './Form';
import MDSpinner from 'react-md-spinner';
import { withTranslation } from 'react-i18next';
import style from './filterBox.scss';

class FilterBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof this.props.searchTextFilterBox !== 'undefined') {
      if (prevProps.searchTextFilterBox != this.props.searchTextFilterBox) {
        this.setState({
          value: this.props.searchTextFilterBox
        })
      }
    }
  }

  onChange(event) {
    this.setState({
      value: event.target.value
    });
    this.props.onFilter(event.target.value);
  }

  render() {
    return (
      <div className={style.filterBox}>
        {this.props.waitingRequest
          ? <MDSpinner singleColor='#2D4F7F' size={20} />
          : <span style={{ width: 20 }} className='fa fa-search' />
        }

        <FormInput
          ref='search'
          id='search'
          placeholder={this.props.t('filterBox.search')}
          value={this.state.value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default withTranslation('components')(FilterBox);
