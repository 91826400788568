import React from 'react';
import Container from '../common/Container';
import { Paper } from 'material-ui';
import styles from './menuPaineis.scss';
import { hasRole } from '../helper/SecurityHelper';
import { Link } from 'react-router-dom';
import LinkClientV2 from 'common/LinkClientV2';
import { withTranslation } from 'react-i18next';

const iconePainelFluxo = '/assets/icones-paineis/iconePainelFluxo.svg';
const iconeDataboardPersonalizado = '/assets/icones-paineis/iconeDataboard.svg';
const iconeTimeline = '/assets/icones-paineis/iconeTimeline.svg';
const iconePainelManutencao =
  '/assets/icones-paineis/iconePainelManutencao.svg';
const iconeMonitorEquipamentos =
  '/assets/icones-paineis/iconeMonitorEquipamentos.svg';
const iconePainelSetup = '/assets/icones-paineis/iconePainelSetup.svg';
const iconePainelDashboardEquipamentos = '/assets/icones-paineis/iconeDashboardEquipamentos.svg'

class MenuPaineis extends Container {
  constructor(props) {
    super(props);
    this.state = {
      permissoes: {
        equipamentos: hasRole(['ROLE_MONITOR_EQUIPAMENTO_VISUALIZAR']),
        dashboardEquipamentos: hasRole(['ROLE_MONITOR_EQUIPAMENTO_VISUALIZAR']),
        fluxo: hasRole(['ROLE_PAINEL_FLUXO_VISUALIZAR']),
        manutencao: hasRole(['ROLE_PAINEL_MANUTENCAO_VISUALIZAR']),
        personalizado: hasRole(['ROLE_PAINEL_PERSONALIZADO_VISUALIZAR']),
        timeline: hasRole(['ROLE_PAINEL_TIMELINE_VISUALIZAR']),
        setup: hasRole(['ROLE_PAINEL_SETUP_VISUALIZAR']),
      },
    };
  }

  render() {
    return (
      <Container
        sectionStyle={{ width: '100%', overflow: 'auto', height: '100%' }}
      >
        <div className={styles.container}>
          <h2 className={styles.title}> {this.props.t('titles.panels')}</h2>
          <div className={styles.grid}>
            <Link
              disabled={!this.state.permissoes.dashboardEquipamentos}
              to='/dashboard-equipamentos'
            >
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.dashboardEquipamentos
                    ? styles.option
                    : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img className={styles.img} src={iconePainelDashboardEquipamentos} />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.dashboardEquipment.t1')} <br />{' '}
                    {this.props.t('titles.dashboardEquipment.t2')}
                  </div>
                </div>
              </Paper>
            </Link>
            <LinkClientV2 to='/painel-equipamentos'>
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.equipamentos
                    ? styles.option
                    : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img className={styles.img} src={iconeMonitorEquipamentos} />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.equipment')}
                  </div>
                </div>
              </Paper>
            </LinkClientV2>
            <Link disabled={!this.state.permissoes.fluxo} to='/painel-fluxo'>
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.fluxo ? styles.option : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img className={styles.img} src={iconePainelFluxo} />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.flow')}
                  </div>
                </div>
              </Paper>
            </Link>
            <LinkClientV2 to={'painel-manutencao'}>
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.manutencao
                    ? styles.option
                    : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img className={styles.img} src={iconePainelManutencao} />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.kanban')}
                  </div>
                </div>
              </Paper>
            </LinkClientV2>
            <LinkClientV2 to={'databoard'}>
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.personalizado
                    ? styles.option
                    : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img
                    className={styles.img}
                    src={iconeDataboardPersonalizado}
                  />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.customized')}
                  </div>
                </div>
              </Paper>
            </LinkClientV2>
            <LinkClientV2 to={'painel-timeline'}>
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.timeline
                    ? styles.option
                    : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img className={styles.img} src={iconeTimeline} />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.timeLine')}
                  </div>
                </div>
              </Paper>
            </LinkClientV2>
            <LinkClientV2 to={'painel-setup'}>
              <Paper
                zDepth={2}
                className={
                  this.state.permissoes.setup ? styles.option : styles.disabled
                }
              >
                <div className={styles.card}>
                  <img className={styles.img} src={iconePainelSetup} />
                  <div className={styles.nome_card}>
                    {this.props.t('titles.setup')}
                  </div>
                </div>
              </Paper>
            </LinkClientV2>
          </div>
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(MenuPaineis);
