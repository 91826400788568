import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Utils from 'utils/Utils';

const legendOpts = {
		display: false
};

class HoldingTimeChart extends Component {
	constructor(props) {
		super(props); 
	}

	formatOptions(histograma) {
		this.histograma = histograma;
		let ticksFlag = false;

		let showRiscoTick = (i, histograma) => {
			if (histograma.dados[i].porcentagem >= histograma.risco*100 && histograma.dados[i].porcentagem < histograma.excedente*100) {
				return true;
			}
			return false;
		};

		return {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [{
					display: true,
					labels: Array.from([].constructor(30).keys()).map((i) => i + 1),
					barPercentage: 1,
					categoryPercentage: 1,
					ticks: {
						beginAtZero: true,
						autoskip: false,
						maxRotation: 0,
						callback: function (value, index, values) {
							return null;
						}
					},
					gridLines: {
						display: false,
					}
				}],
				yAxes: [{
					barPercentage: 1,
					categoryPercentage: 1,
					scaleLabel: {
						display: false
					},
					ticks: {
						suggestedMin: 0,
						beginAtZero: true,
						display: false
					},
					gridLines: {
						display: false
					}
				}]
			},
			tooltips: {
				titleFontSize: 16,
				bodyFontSize: 14,
				displayColors: false,
				mode: 'index',
				intersect: false,
				filter: function (tooltipItem) {
					tooltipItem.label = '';
					return tooltipItem.datasetIndex === 0;
				}
			},
			dangerArea: {
				backgroundColor: 'rgba(229,115,115, 0.5)',
				dangerCallback: function (data, i) {
					var histograma = this.props.data;
					if (histograma) {
						return histograma.dados[i].porcentagem >= histograma.risco*100 && histograma.dados[i].porcentagem < histograma.excedente*100;
					}
					return false;
				}.bind(this)
			},
			customArea: {
				backgroundColor: 'rgba(189,189,189, 0.5)',
				barCount: 5
			},
			histogramaTicks: {
				callback: (i) => {
					let histo = this.histograma;
					if (!histo || !(histo.dados)) return '';
					if (i === 0) {
						ticksFlag = false;
						return '0%';
					} else if (i === histo.dados.length - 4) {
						return parseFloat(histo.excedente * 100).toFixed(0) + '%';
					} else if (!ticksFlag && showRiscoTick(i, histo)) {
						ticksFlag = true;
						return parseFloat(histo.risco * 100).toFixed(0) + '%';
					}
					return '';
				}
			}
		};
	}

	formatData(histograma) {
		if (!histograma) return {};
		let max = _.sumBy(histograma.dados, (h) => h.total);
		let data = _.map(histograma.dados, (h) => h.total);

		let ctx = document.createElement('CANVAS').getContext('2d');
		let gradientStroke = ctx.createLinearGradient(0, 0, 0, 120);
		gradientStroke.addColorStop(0, '#91A8CA');
		gradientStroke.addColorStop(.25, '#5C7EB0');
		gradientStroke.addColorStop(.5, '#3A597C');
		gradientStroke.addColorStop(1, '#314966');

		return {
			datasets: [
				{
					label: Utils.getReferenciaLotesOrdens(),
					fill: false,
					backgroundColor: gradientStroke,
					hoverBackgroundColor: gradientStroke,
					borderColor: 'rgba(190, 200, 255, 1)',
					borderWidth: 1,
					data: data
				}
			]
		};
	}

	render() {    
		let options = this.formatOptions(this.props.data);
		let data = this.formatData(this.props.data, options)

		return (
			<div id={this.props.id} style={{ width: '95%', maxHeight: '150px' }}>
			{
				this.props.data.dados !== null
				?
					<Bar ref='chart' data={data} legend={legendOpts} options={options} />
				:
					<div
						className={'flex flex-dir-row flex-just-center'}
					 	style={{ height: '100%', width: '100%', textAlign: 'center'}}>
						<i style={{ marginRight: '15px', color: 'rgba(0, 0, 0, 0.4)' }} className="material-icons">warning</i>
						<div> 
							<h3 style={{color: 'rgba(0, 0, 0, 0.4)'}}>{this.props.t("customIndicator.noData", { ns: "components" })}</h3>
						</div>
					</div>
			}
			</div>
		);
	}
}

export default withTranslation("flowDashboard")(HoldingTimeChart);
