import React, { Component } from 'react';
import styles from './route.scss';

export default class ListTitle extends Component {
  render() {
    let {stages} = this.props;
    
    return(
      <div ref="list_title" className={styles.list_titles}>
        { stages.map(stage => <div key={stage.id}>{stage.name}</div>) }
      </div>
    );
  }
}