import React, { Component } from 'react';
import Button from '../../common/Button';
import listStyles from './list.scss';
import utils from '../../utils/Utils';
import { withTranslation } from 'react-i18next';

class ListQuality extends Component {
  render() {
    let { handleModal, listWarning, store } = this.props;
    let getReferenciaSecundaria = utils.getReferenciaOrdemLote() != '' ? <td>{utils.getReferenciaOrdemLote()}</td> : null;

    var flags = {};
    var listWarningUnique = listWarning.filter(l => {
      if (flags[l.num]) {
        return false;
      }
      flags[l.num] = true;
      return true;
    });

    let list = listWarningUnique.map((l, k) => {
      let numeroSecundario = getReferenciaSecundaria != null ? <td>{l.num_secundario != null ? l.num_secundario : '-'}</td> : null;
      let stage = store.stages.filter(s => s.id == l.fk_id_stages)[0];
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{l.sku}</td>
          <td>{l.product}</td>
          <td>{l.num}</td>
          {numeroSecundario}
          <td>{stage !== undefined ? stage.name : '-'}</td>
          <td>{l.tp_error}</td>
        </tr>
      );
    });

    return (
      <div className={listStyles.warning_modal}>
        <div>
          <div><h3>{this.props.t("labels.inconsistentBatches", { primaryReference: utils.getReferenciaLotesOrdens() })}</h3></div>
          <div>
            <div className={listStyles.box_button}>
              <div>
                <Button button={{ type: 'buttonIcon', icon: 'fa fa-close', text: '', onClick: handleModal }} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxHeight: '60vh', overflowX: 'hidden' }}>
          <table className='mui-table mui-table--bordered'>
            <thead>
              <tr>
                <td>#</td>
                <td>{this.props.t("labels.SKU")}</td>
                <td>{this.props.t("labels.name")}</td>
                <td>{utils.getReferenciaLoteOrdem()}</td>
                <td>{utils.getReferenciaOrdemLote()}</td>
                <td>{this.props.t("labels.stage")}</td>
                <td>{this.props.t("labels.inconsistencyType")}</td>
              </tr>
            </thead>
            <tbody className='box_items'>
              {list}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withTranslation("flowView")(ListQuality);
