import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import {
  NButton, NForm, NFormTitle,
  NButtonGroup, NMaskedOee, alertBox
} from '../../common/NForm';
import $ from 'jquery';
import { TextField, Tabs, Tab } from 'material-ui';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FilterBox from '../../common/FilterBox';
import update from 'immutability-helper';
import { request } from '../../common/request';
import MDSpinner from 'react-md-spinner';
import { hasRole } from '../../helper/SecurityHelper';
import styles from '../../common/form.scss';
import CustomIndicatorForm from '../customIndicator/CustomIndicatorForm';
import {
  requisicaoValorPadraoIndicadores,
  respostaValorPadraoIndicadoresPersonalizados
} from '../../common/mappers';
import { withTranslation } from 'react-i18next';

@observer
class GroupEquipamentPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      modalType: '',
      value: null,
      permitirTudo: false,
      permissionsChild: false,
      permissionsChild2: false,
      items: [],
      grupo: null,
      filter: '',
      salvarButton: true,
      loadingPage: true,
      erroCampo: false,
      erroEquip: ''
    };

    this.onFilter = this.onChangeFilter.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.carregarGrupoEquipamento = this.carregarGrupoEquipamento.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.getIndicatorsGroupEquipment = this.getIndicatorsGroupEquipment.bind(this);
  }

  componentWillMount() {
    this.carregarGrupoEquipamento();
  }

  carregarGrupoEquipamento() {
    request(this.endpoints.GRUPO_EQUIPAMENTO, {
      method: 'get'
    })
      .then((res) => {
        this.setState({
          loadingPage: false
        });

        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        if (data != null) {
          this.setState({
            items: data
          });
          data.map(value => {
            this.setState({
              grupo: value
            });
          });
        }
      });
  }

  getIndicatorsGroupEquipment(id) {
    const params = { method: 'GET' };
    const pathUrl = id == undefined ? '/valores-padrao/ativos' : `/grupo-equipamento/${id}`;
    const url = this.endpoints.INDICADORES_PERSONALIZADOS + pathUrl;

    return new Promise((resolve, reject) => {
      const failure = error => reject(error);

      const success = response => (response.status == 200)
        ? resolve(response.json())
        : reject(`The response was rejected with status: ${response.status}`);


      request(url, params)
        .then(success)
        .catch(failure);
    });
  };

  handleModal(item, value) {
    let customIndicators = [];
    let groupEquipment = (value == 'cadastrarGrupoEquipamento') ? {} : (item || {});

    const success = data => customIndicators = respostaValorPadraoIndicadoresPersonalizados(data);
    const failure = error => console.warn(`Erro ao tentar carregar Indicadores para Grupo de Equipamentos: ${error}`);

    const updateState = () => {
      this.setState({
        modal: true,
        modalType: value,
        grupo: groupEquipment,
        indicators: customIndicators
      });
    };

    this.getIndicatorsGroupEquipment(groupEquipment.id)
      .then(success)
      .catch(failure)
      .finally(updateState);
  }

  closeModal() {
    this.setState({
      modal: false,
      modalType: '',
      erroCampo: ''
    });
  }

  handleChange = (event, index, value) => this.setState({ value });

  onChangeFilter(value) {
    this.setState({
      filter: value
    });
  }

  onSave(data) {
    const { grupo } = this.state;
    let method = 'post';

    let url = this.endpoints.GRUPO_EQUIPAMENTO;

    if (!!grupo) {
      method = 'put';
      url = `${url}/${grupo.uuid}`;
    }

    let acao = (method === 'post') ? this.props.t("actions.created", { ns: 'common', context: 'male' }) : this.props.t("actions.updated", { ns: 'common', context: 'male' });
    let acao2 = (method === 'post') ? this.props.t("actions.create", { ns: 'common' }) : this.props.t("actions.update", { ns: 'common' });

    if (!data.nome) {
      this.setState({
        erroCampo: this.props.t("errors.requiredField", { ns: "validation" }),
        modal: true,
        modalType: 'cadastrarGrupoEquipamento'
      });
      return;
    } else {

      const indicadores = requisicaoValorPadraoIndicadores(data.indicators, data.id, false);
      data.indicadores = indicadores || [];

      request(url, {
        method: method,
        body: JSON.stringify(data)
      })
        .then((res) => {
          if ((method === 'post' && res.status === 201) || (method === 'put' && res.status === 200)) {
            alertBox(this.props.t("messages.actionSuccess", { action: acao }), this.props.t("title"), 'success');
          } else if (res.status == 409) {
            alertBox(this.props.t("errors.alreadyExists"), this.props.t("title"), 'error');
            return;
          } else {
            alertBox(this.props.t("errors.cannotAction", { action: acao2 }), this.props.t("title"), 'error');
            return;
          }
        }).then(() => this.carregarGrupoEquipamento());

      this.setState({
        modal: false,
        erroEquip: ''
      });
    }
  }

  onDelete() {
    this.closeModal();

    const { grupo } = this.state;
    const url = `${this.endpoints.GRUPO_EQUIPAMENTO}/${grupo.uuid}`;

    request(url, {
      method: 'delete'
    })
      .then(function (res) {
        if ((res.status == 201) || (res.status == 200)) {
          alertBox(this.props.t("messages.actionSuccess", { action: this.props.t("actions.deleted", { ns: 'common', context: 'male' }) }), this.props.t("title"), 'success');
        } else {
          alertBox(this.props.t("errors.cannotAction", { action: this.props.t("actions.delete", { ns: 'common' }) }), this.props.t("title"), 'error');
        }
      });

    this.carregarGrupoEquipamento();
  }

  contentModal() {
    const { grupo, indicators } = this.state;

    switch (this.state.modalType) {
      case 'cadastrarGrupoEquipamento':
        this.state.grupo = null;
        return <RegGroupUsers modalType='add' onSave={this.onSave.bind(this)} grupo={{}} indicators={indicators} erroCampo={this.state.erroCampo} closeModal={this.closeModal} />;
      case 'editarGrupoEquipamento':
        return <RegGroupUsers modalType='up' onSave={this.onSave.bind(this)} grupo={grupo} indicators={indicators} erroCampo={this.state.erroCampo} closeModal={this.closeModal} />;
      case 'deletarGrupoEquipamento':
        return <DeleteGroupUsers onDelete={this.onDelete.bind(this)} {...this.state} grupo={grupo} closeModal={this.closeModal} />;
    }
  }

  render() {
    const { items, filter } = this.state;

    let regex = new RegExp(filter, 'i');
    let filtrado = items.filter(l => regex.test(l.nome));

    if (filtrado.length <= 0) {
      filtrado = items;
    }

    const equips = filtrado.map((item, i) =>
      <tr key={item.uuid}>
        <td className='id'>{i + 1}</td>
        <td className='name'>{item.nome}</td>
        <td className='actions'>
          <div className='options'>
            <i className='material-icons' title={this.props.t("list.actions.edit", { ns: 'common' })} onClick={() => this.handleModal(item, 'editarGrupoEquipamento')}>edit</i>
            <i className='material-icons' title={this.props.t("list.actions.delete", { ns: 'common' })} onClick={() => hasRole('ROLE_GRUPO_EQUIPAMENTOS_EXCLUIR') && this.handleModal(item, 'deletarGrupoEquipamento')}>cancel</i>
          </div>
        </td>
      </tr>
    );

    if (equips.length > 0) {
      return (
        <div>
          {this.state.loadingPage
            ?
            <Container sectionStyle={{ height: '94vh' }}>
              <div className={'loading-wrapper'}>
                <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
              </div>
            </Container>
            :
            <Container showSideBar modal={this.state.modal} contentModal={this.contentModal}>
              <div className='box_list'>
                <Card className='box_list_card'>
                  <FloatingActionButton disabled={!hasRole('ROLE_GRUPO_EQUIPAMENTOS_CRIAR')} className='box_add' onClick={() => this.handleModal(event, 'cadastrarGrupoEquipamento')}>
                    <ContentAdd />
                  </FloatingActionButton>
                  <CardHeader className='box_header' title={this.props.t('title')} />
                  <CardText className='table_overflow'>
                    <FilterBox onFilter={this.onFilter} />
                    <table className='mui-table mui-table--bordered'>
                      <thead>
                        <tr>
                          <td className='id'>#</td>
                          <td className='name'>{this.props.t("list.columns.name")}</td>
                          <td className='actions'>{this.props.t("list.columns.action", { ns: 'common', count: 2 })}</td>
                        </tr>
                      </thead>
                      <tbody className='box_items'>
                        {equips}
                      </tbody>
                    </table>
                  </CardText>
                </Card>
              </div>
            </Container>
          }
        </div>
      );
    } else {
      return (
        <div>
          {this.state.loadingPage
            ?
            <Container sectionStyle={{ height: '94vh' }}>
              <div className={'loading-wrapper'}>
                <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
              </div>
            </Container>
            :
            <Container showSideBar modal={this.state.modal} styleModal={{ width: 350 }} contentModal={this.contentModal}>
              <div className='box_list'>
                <Card className='box_list_card'>
                  <FloatingActionButton disabled={!hasRole('ROLE_GRUPO_EQUIPAMENTOS_CRIAR')} className='box_add' onClick={() => this.handleModal(event, 'cadastrarGrupoEquipamento')}>
                    <ContentAdd />
                  </FloatingActionButton>
                  <CardHeader className='box_header' title='Agrupador de Equipamentos' />
                  <CardText>
                    <div style={{ marginTop: 30 }}>
                      <h1>{this.props.t("list.empty", { ns: 'common' })}</h1>
                    </div>
                  </CardText>
                </Card>
              </div>
            </Container>
          }
        </div>
      );
    }
  }
}

@observer
class RegGroupUsersExport extends Component {
  constructor(props) {
    super(props);

    const grupo = props.grupo || {};
    const indicators = props.indicators || [];

    this.state = {
      id: grupo.id || 0,
      nome: grupo.nome,
      oeeRealComSetup: grupo.oee_real_com_setup || 0,
      oeeRealSemSetup: grupo.oee_real_sem_setup || 0,
      oeeEfetivoComSetup: grupo.oee_efetivo_com_setup || 0,
      oeeEfetivoSemSetup: grupo.oee_efetivo_sem_setup || 0,
      oeeOtimizadoComSetup: grupo.oee_otimizado_com_setup || 0,
      oeeOtimizadoSemSetup: grupo.oee_otimizado_sem_setup || 0,
      oeeTeepComSetup: grupo.oee_teep_com_setup || 0,
      oeeTeepSemSetup: grupo.oee_teep_sem_setup || 0,
      mtbf: grupo.mtbf || '00:00:00',
      mtbp: grupo.mtbp || '00:00:00',
      mttr: grupo.mttr || '00:00:00',
      mttp: grupo.mttp || '00:00:00',
      mwt: grupo.mwt || '00:00:00',
      modalType: props.modalType,
      indicators: indicators,
    };

    this.setStateMeta = this.setStateMeta.bind(this);
  }

  onNomeChange(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  mascara(campoState, valor, idCampo) {
    var mask = valor.replace(/\D/g, '').length > 6 ? '000:00:00' : '00:00:009';
    $(`#${idCampo}`).mask(mask);
    this.setState({
      [campoState]: $(`#${idCampo}`).masked()
    });
  }

  mascaraSetup(campoState, valor, idCampo) {
    var mask = valor.replace(/\D/g, '').length > 6 ? '000:00:00' : '00:00:009';
    $(`#${idCampo}`).mask(mask);

    this.setState(update(this.state, {
      equipamentoEventos: {
        [campoState]: {
          tempo: { $set: $(`#${idCampo}`).masked() }
        }
      }
    }));
  }

  substituirVirgula(stateValue) {
    return stateValue.toString().includes(',') ? stateValue.replace(',', '.') : stateValue;
  }

  setStateMeta(campoState, valor) {
    this.setState({
      [campoState]: valor
    });
  }

  render() {
    const { nome, mtbf, mtbp, mttr, mttp, mwt } = this.state;

    let indicators = (
      <CustomIndicatorForm
        sizeChunk={2}
        groupEquipments={true}
        beautifulDisplay={true}
        setStateMeta={this.setStateMeta}
        customIndicators={this.state.indicators}
      />
    );

    return (
      <div style={{ padding: 0, minWidth: '40em' }} className='modal'>
        <div>
          <Tabs tabItemContainerStyle={{ background: '#F1F1F1' }} inkBarStyle={{ background: '#5B5B5B' }} className={'tabs-labels'}>
            <Tab label={this.props.t("form.group.title")} >
              <div style={{ padding: '1em' }} className={'flex flex-just-start'}>
                <TextField
                  style={{ marginTop: '-16px', width: '300px', textAlign: 'left' }}
                  underlineFocusStyle={{ borderColor: '#000000' }}
                  floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                  floatingLabelText={this.props.t("form.group.labels.name")} title={this.props.t("form.group.labels.name")} id='nome'
                  errorText={this.props.erroEquip}
                  onChange={this.onNomeChange.bind(this)}
                  value={nome} required="true"
                />
              </div>
            </Tab>
            <Tab label={this.props.t("form.goals.title")} >
              <div style={{ padding: '1em' }}>
                <div className={'flex flex-just-around'}>
                  <TextField
                    style={{ marginTop: '-16px', width: '45%', textAlign: 'left' }}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    floatingLabelText={this.props.t("form.goals.labels.mtbf")} title={this.props.t("form.goals.labels.mtbf")} id='mtbf'
                    onChange={(event, value) => this.mascara('mtbf', value, 'mtbf')}
                    value={mtbf} required="true"
                  />

                  <TextField
                    style={{ marginTop: '-16px', width: '45%', textAlign: 'left' }}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    floatingLabelText={this.props.t("form.goals.labels.mtbp")} title={this.props.t("form.goals.labels.mtbp")} id='mtbp'
                    onChange={(event, value) => this.mascara('mtbp', value, 'mtbp')}
                    value={mtbp} required="true"
                  />
                </div>

                <div className={'flex flex-just-around'}>
                  <TextField
                    style={{ marginTop: '-16px', width: '45%', textAlign: 'left' }}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    floatingLabelText={this.props.t("form.goals.labels.mttr")} title={this.props.t("form.goals.labels.mttr")} id='mttr'
                    onChange={(event, value) => this.mascara('mttr', value, 'mttr')}
                    value={mttr} required="true"
                  />

                  <TextField
                    style={{ marginTop: '-16px', width: '45%', textAlign: 'left' }}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    floatingLabelText={this.props.t("form.goals.labels.mttp")} title={this.props.t("form.goals.labels.mttp")} id='mttp'
                    onChange={(event, value) => this.mascara('mttp', value, 'mttp')}
                    value={mttp} required="true"
                  />
                </div>
                <div className={'flex flex-just-around'}>
                  <TextField
                    style={{ marginTop: '-16px', width: '45%', textAlign: 'left' }}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    floatingLabelText={this.props.t("form.goals.labels.mwt")} title={this.props.t("form.goals.labels.mwt")} id='mwt'
                    onChange={(event, value) => this.mascara('mwt', value, 'mwt')}
                    value={mwt} required="true"
                  />
                  <div style={{ width: '45%' }} />
                </div>
              </div>

              <div className={'flex flex-just-around'}>
                <form className={styles.row_form}>
                  <div style={{ padding: '1em' }}>
                    {indicators}
                  </div>
                </form>
              </div>

            </Tab>
            <Tab label={this.props.t("form.oee.title")}>
              <div style={{ padding: '1em' }}>
                <div className={'flex flex-just-around'}>
                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.realWithSetup")}
                    id='oeecomsetup'
                    onChange={(valor => this.setState({ oeeRealComSetup: valor.target.value }))}
                    value={this.state.oeeRealComSetup}
                    maskChar={null}
                  />

                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.realWithoutSetup")}
                    id='oeesemsetup'
                    onChange={(valor => this.setState({ oeeRealSemSetup: valor.target.value }))}
                    value={this.state.oeeRealSemSetup}
                    maskChar={null}
                  />
                </div>

                <div className={'flex flex-just-around'}>
                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.effectiveWithSetup")}
                    id='oeeefetivocomsetup'
                    onChange={(valor => this.setState({ oeeEfetivoComSetup: valor.target.value }))}
                    value={this.state.oeeEfetivoComSetup}
                    maskChar={null}
                  />

                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.effectiveWithoutSetup")}
                    id='oeeefetivosemsetup'
                    onChange={(valor => this.setState({ oeeEfetivoSemSetup: valor.target.value }))}
                    value={this.state.oeeEfetivoSemSetup}
                    maskChar={null}
                  />
                </div>

                <div className={'flex flex-just-around'}>
                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.optimizedWithSetup")}
                    id='oeeotimizadocomsetup'
                    onChange={(valor => this.setState({ oeeOtimizadoComSetup: valor.target.value }))}
                    value={this.state.oeeOtimizadoComSetup}
                    maskChar={null}
                  />

                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.optimizedWithoutSetup")}
                    id='oeeotimizadosemsetup'
                    onChange={(valor => this.setState({ oeeOtimizadoSemSetup: valor.target.value }))}
                    value={this.state.oeeOtimizadoSemSetup}
                    maskChar={null}
                  />
                </div>

                <div className={'flex flex-just-around'}>
                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.teepWithSetup")}
                    id='oeeteepcomsetup'
                    onChange={(valor => this.setState({ oeeTeepComSetup: valor.target.value }))}
                    value={this.state.oeeTeepComSetup}
                    maskChar={null}
                  />

                  <NMaskedOee
                    {...this.props}
                    className={styles.oeeInput}
                    alwaysShowMask={false}
                    mask={'999,99%'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '11px', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={this.props.t("form.oee.labels.teepWithoutSetup")}
                    id='oeeteepsemsetup'
                    onChange={(valor => this.setState({ oeeTeepSemSetup: valor.target.value }))}
                    value={this.state.oeeTeepSemSetup}
                    maskChar={null}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <NButtonGroup style={{ padding: '1em' }}>
          <NButton type="primary" onClick={() => this.props.onSave({
            id: this.state.id,
            nome: this.state.nome?.trim(),
            oee_real_com_setup: parseFloat(this.substituirVirgula(this.state.oeeRealComSetup)) || 0,
            oee_real_sem_setup: parseFloat(this.substituirVirgula(this.state.oeeRealSemSetup)) || 0,
            oee_efetivo_com_setup: parseFloat(this.substituirVirgula(this.state.oeeEfetivoComSetup)) || 0,
            oee_efetivo_sem_setup: parseFloat(this.substituirVirgula(this.state.oeeEfetivoSemSetup)) || 0,
            oee_otimizado_com_setup: parseFloat(this.substituirVirgula(this.state.oeeOtimizadoComSetup)) || 0,
            oee_otimizado_sem_setup: parseFloat(this.substituirVirgula(this.state.oeeOtimizadoSemSetup)) || 0,
            oee_teep_com_setup: parseFloat(this.substituirVirgula(this.state.oeeTeepComSetup)) || 0,
            oee_teep_sem_setup: parseFloat(this.substituirVirgula(this.state.oeeTeepSemSetup)) || 0,
            mtbf: this.state.mtbf,
            mtbp: this.state.mtbp,
            mttr: this.state.mttr,
            mwt: this.state.mwt,
            mttp: this.state.mttp,
            indicators: this.state.indicators
          })}
            disabled={((!hasRole('ROLE_GRUPO_EQUIPAMENTOS_CRIAR') && this.state.modalType === 'add')
              || (!hasRole('ROLE_GRUPO_EQUIPAMENTOS_ATUALIZAR') && this.state.modalType === 'up'))}
          >{this.props.t("labels.save", { ns: 'common' })}</NButton>
          <NButton onClick={this.props.closeModal}>{this.props.t("labels.cancel", { ns: 'common' })}</NButton>
        </NButtonGroup>
      </div>
    );
  }
}

const RegGroupUsers = withTranslation("groupEquipment")(RegGroupUsersExport);

@observer
class DeleteGroupUsersExport extends Component {
  constructor(props) {
    super(props);

    const grupo = props.grupo || {};

    this.state = {
      nome: grupo.nome,
    };
  }

  render() {
    const { nome } = this.state;
    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("messages.confirmDelete", { groupName: nome })}</NFormTitle>
          <NButtonGroup>
            <NButton type="primary" onClick={this.props.onDelete}>{this.props.t("labels.delete", { ns: "common" })}</NButton>
            <NButton onClick={this.props.closeModal}>{this.props.t("labels.cancel", { ns: "common" })}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }
}

const DeleteGroupUsers = withTranslation("groupEquipment")(DeleteGroupUsersExport);

export default withTranslation("groupEquipment")(GroupEquipamentPage);