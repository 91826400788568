import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { request } from '../../common/request';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { alertBox } from '../../common/NForm';
import FilterBox from '../../common/FilterBox';
import { hasRole } from '../../helper/SecurityHelper';
import LinkClientV2 from 'common/LinkClientV2';
import { withTranslation } from 'react-i18next';

@observer
class EquipmentsList extends Component {
  constructor() {
    super();
    this.onFilter = this.onFilter.bind(this);
    this.checkTravaEquipamento = this.checkTravaEquipamento.bind(this);
  }

  componentWillMount() {
    this.props.store.searchModel = 'equipments';
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }

  onFilter(val) {
    this.props.store.searchValue = val;
  }

  checkTravaEquipamento() {
    request(this.props.endpoints.CONFIGURACAO_EQUIPAMENTO, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
      .then(response => {
        if (response.status == 200) {
          response.json()
            .then(data => {
              if (data.limite) {
                return this.props.onOpenModal('', 'add')
              }
              return alertBox(
                this.props.t('errors.equipmentLimitExceeded'),
                this.props.t('labels.error', { ns: 'common ' }),
                'error'
              )
            })
        }
      });
  }

  countUniqueFields(listsOfList) {
    var uniq = {}, total = 0;

    for (const list of listsOfList) {
      for (const el of list) {
        if (!uniq[el.id]) {
          uniq[el.id] = true;
          total++;
        }
      }
    }

    return total;
  }

  render() {
    let items = this.props.store.searchItems.map((f, k) => {
      let totalFields = f.fields ? this.countUniqueFields([f.fields]) : 0;

      //if ternario para verificar se o wait_time é zero , se for deve colocar o valor do campo como --:-- se nao o valor do wait_time
      var wait_timer = (f.wait_time != 0) ? this.props.utils.convertTime(f.wait_time, 'view') : "- - : - -";
      return (
        <tr key={`item-${k}`}>
          <td>{k + 1}</td>
          <td>{f.name}</td>
          <td>{f.tag}</td>
          <td>{f.fields ? this.props.t('list.values.fieldsWithCount', { count: totalFields }) : + '-'}</td>
          <td>{f.shifts ? this.props.t('list.values.shiftsWithCount', { count: f.shifts.length }) : '-'}</td>
          <td>{wait_timer}</td>
          <td>{f.fifo}</td>
          <td>{f.state ? this.props.t('labels.active', { ns: 'common' }) : this.props.t('labels.inactive', { ns: 'common' })}</td>
          <td>{f.is_batch ? this.props.t('labels.yes', { ns: 'common' }) : this.props.t('labels.no', { ns: 'common' })}</td>
          <td>
            <div className='options'>
              <LinkClientV2 to={`equipment-dashboard/${f.id}`}>
                <i className='material-icons' title={this.props.t('list.actions.dashboard')}>tv</i>
              </LinkClientV2>

              <LinkClientV2 to={`equipment-dashboard/${f.id}/simplified`}>
                <i className='material-icons' title={this.props.t('list.actions.simplifiedDashboard')}>tablet</i>
              </LinkClientV2>

              <i className='material-icons' title={this.props.t('list.actions.edit', { ns: 'common' })} onClick={() => this.props.onOpenModal(f, 'up')}>edit</i>
              <i className='material-icons' title={this.props.t('list.actions.delete', { ns: 'common' })} onClick={() => hasRole('ROLE_EQUIPAMENTOS_EXCLUIR') && this.props.onDelete(f)}>cancel</i>
            </div>
          </td>
        </tr>
      )

    });

    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_EQUIPAMENTOS_CRIAR')} className='box_add' onClick={this.checkTravaEquipamento}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('title')} />
            <CardText className='table_overflow'>
              <FilterBox onFilter={this.onFilter} />
              <table className='mui-table mui-table--bordered' >
                <thead>
                  <tr>
                    <td className='id'>#</td>
                    <td className="name">{this.props.t('list.columns.name')}</td>
                    <td className="tag">{this.props.t('list.columns.tag')}</td>
                    <td className="areas">{this.props.t('list.columns.fields')}</td>
                    <td className="shifts">{this.props.t('list.columns.shifts')}</td>
                    <td className="wait">{this.props.t('list.columns.waitTime')}</td>
                    <td className="fifo">{this.props.t('list.columns.fifo')}</td>
                    <td className="status">{this.props.t('list.columns.status')}</td>
                    <td className="batch">{this.props.t('list.columns.batch')}</td>
                    <td className="actions" style={{ width: '8%' }}>{this.props.t('list.columns.action', { ns: 'common', count: 2 })}</td>
                  </tr>
                </thead>
                <tbody className='box_items'>
                  {items}
                </tbody>
              </table>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_EQUIPAMENTOS_CRIAR')} className='box_add' onClick={() => this.props.onOpenModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('title')} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t('list.empty', { ns: 'common' })}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation('equipments')(EquipmentsList);
