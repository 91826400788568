import equipments from './equipments.json';
import products from './products.json';
import groupUser from './groupUser.json';
import user from './user.json';
import customFields from './customFields.json';
import routes from './routes.json';
import availability from './availability.json';
import batches from './batches.json';
import batchesLink from './batchesLink.json';
import events from './events.json';
import flowDashboard from './flowDashboard.json';
import flowView from './flowView.json';
import groupEquipment from './groupEquipment.json';
import groupEvent from './groupEvent.json';
import maintenance from './maintenance.json';
import notes from './notes.json';
import parameters from './parameters.json';
import shifts from './shifts.json';
import stages from './stages.json';
import oee from './oee.json';
import fields from './fields.json';
import sessions from './sessions.json';
import reports from './reports.json';
import dataCollection from './dataCollection.json';
import holdingTime from './holdingTime.json';

export {
    equipments,
    products,
    groupUser,
    user,
    customFields,
    routes,
    availability,
    batches,
    batchesLink,
    events,
    flowDashboard,
    flowView,
    groupEquipment,
    groupEvent,
    maintenance,
    notes,
    parameters,
    shifts,
    stages,
    oee,
    fields,
    sessions,
    reports,
    dataCollection,
    holdingTime
}

export default {
    equipments,
    products,
    groupUser,
    user,
    customFields,
    routes,
    availability,
    batches,
    batchesLink,
    events,
    flowDashboard,
    flowView,
    groupEquipment,
    groupEvent,
    maintenance,
    notes,
    parameters,
    shifts,
    stages,
    oee,
    fields,
    sessions,
    reports,
    dataCollection,
    holdingTime
}