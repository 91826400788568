import React, { Component } from 'react';
import Toggle from 'material-ui/Toggle';
import defaultStyle from './button.scss';

export default class Button extends Component {
  render() {
    let styles = this.props.styles && this.props.styles.button_ghost ? this.props.styles : defaultStyle;

    let { button } = this.props;

    switch (button.type) {
      case 'round':
        return <ButtonRound styles={styles} {...button} />

      case 'submit':
        return <ButtonRoundSubmit styles={styles} {...button} />

      case 'roundRT':
        return <ButtonRoundRT styles={styles} {...button} />

      case 'toggleButton':
        return <ToggleButton styles={styles} {...button} />

      case 'buttonAddIconTriangle':
        return <ButtonTriangleAddIcon styles={styles} {...button} />

      case 'buttonCloseIconTriangle':
        return <ButtonTriangleCloseIcon styles={styles} {...button} />

      case 'buttonIcon':
        return <ButtonIcon styles={styles} {...button} />

      case 'buttonLoading':
        return <ButtonLoading styles={styles} {...button} />

      case 'buttonInsert':
        return <ButtonInsert styles={styles} {...button} />

      default:
        return <div />
    }
  }
}

export class ButtonRound extends Component {
  componentDidMount() {
    this.props.disabled ? document.getElementById('buttonRound').disabled = true : document.getElementById('buttonRound').disabled = false;
  }

  render() {
    let { id, text, icon, onClick, styles, className, disabled, hidden } = this.props;
    styles = styles || defaultStyle;

    return (
      <button type="button" id='buttonRound' disabled={disabled} className={disabled ? styles.button_ghost_disable + " " + styles.round : styles.button_ghost + " " + styles.round + " " + styles[className]} onClick={!disabled ? onClick : ''}>{(icon != undefined) ? <i className={icon} /> : ''} {text}</button>
    );
  }
}

export class ButtonInsert extends Component {
  componentDidMount() {
    this.props.disabled ? document.getElementById('buttonInsert').disabled = true : document.getElementById('buttonInsert').disabled = false;
  }

  render() {
    let { id, text, icon, onClick, styles, className, disabled, hidden } = this.props;
    styles = styles || defaultStyle;

    return (
      <button type="button" id='buttonInsert' className={disabled ? styles.button_ghost_disable + " " + styles.round : styles.button_ghost + " " + styles.round + " " + styles[className]} onClick={onClick} hidden={hidden}>
        {(icon != undefined) ? <i className={icon} /> : ''}
        {text}
      </button>
    );
  }
}

export class ButtonLoading extends Component {
  componentDidMount() {
    this.props.disabled ? document.getElementById('buttonLoading').disabled = true : document.getElementById('buttonLoading').disabled = false;
  }

  render() {
    let { id, text, icon, onClick, styles, className, hidden } = this.props;
    let disabled = true;
    styles = styles || defaultStyle;

    return (
      <button type="button" id='buttonLoading'
        className={disabled ? styles.button_ghost_disable + " " + styles.round : styles.button_ghost + " " + styles.round + " " + styles[className]} hidden={true}>
        {text}
        <br />
        <img src='../assets/loading.gif' color='#2D4F7F' style={{ width: '70px' }} thickness={2} />
      </button>
    );
  }
}

export class ButtonIcon extends Component {
  render() {
    let { id, text, icon, onClick, styles } = this.props;
    styles = styles || defaultStyle;
    return (
      <div className="cursor-pointer" onClick={onClick}>{(icon != undefined) ? <i className={icon}></i> : ''}</div>
    );
  }
}

class ButtonRoundSubmit extends Component {
  render() {
    let { id, text, icon, onClick, styles } = this.props;
    styles = styles || defaultStyle;
    return (
      <button type="button" className={styles.button_ghost + " " + styles.round} onClick={onClick}>{(icon != undefined) ? <i className={icon}></i> : ''} {text}</button>
    );
  }
}

class ButtonRoundRT extends Component {
  render() {
    let { id, text, icon, onClick, styles } = this.props;
    styles = styles || defaultStyle;
    return (
      <button type="button" className={styles.button_ghost + " " + styles.round} onClick={onClick}>{text} {(icon != undefined) ? <i className={icon}></i> : ''}</button>
    );
  }
}

export class ToggleButton extends Component {
  constructor() {
    super();
    this.state = { status: true }
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentWillMount() {
    this.state.status = this.props.checked;
  }

  componentWillReceiveProps(nextProps) {
    this.state.status = nextProps.checked;
  }

  handleToggle() {
    this.setState({ status: !this.state.status });
    if (this.props.onChange) this.props.onChange();
  }

  render() {
    let { id, onChange, dataOn, dataOff, styles } = this.props;
    let { status } = this.state;
    styles = styles || defaultStyle;
    return (
      <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
        <Toggle defaultToggled={status} onToggle={this.handleToggle} />
      </div>
    );
  }
}

class ButtonTriangleAddIcon extends Component {
  render() {
    let { icon, onClick, styles } = this.props;

    return (
      <button type="button" className={styles.button_add_triangle} onClick={onClick}>{(icon != undefined) ? <i className={icon}></i> : ''}</button>
    );
  }
}

class ButtonTriangleCloseIcon extends Component {
  render() {
    let { icon, onClick, styles } = this.props;

    return (
      <button type="button" className={styles.button_close_triangle} onClick={onClick}>{(icon != undefined) ? <i className={icon}></i> : ''}</button>
    );
  }
}
