import AutoComplete from 'material-ui/AutoComplete';
import TextField from 'material-ui/TextField';
import React from 'react';
import styles from './nform.scss';
import DatePicker from 'react-datepicker';
import $ from 'jquery';
import 'jquery-mask-plugin';
import Notifier from '../utils/Notifier';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import Util from '../utils/Utils';
import i18n from 'utils/i18n';
import moment from 'moment';

/**
 *
 * @param {Object} props - Aceita type(primary ou secundary) e children
 */
export function NButton(props) {
    let { type = 'secundary', children, className = null } = props;
    type = styles[`button_ghost--${type}`] || '';

    var cn = [styles['button_ghost'], type];

    if (className) {
        cn.push(className);
    }

    return (
        <button
            {...props}
            className={_.join(cn, ' ')}
        >{children}</button>
    );
}

/**
 *
 * @param {Object} props - Aceita children
 */
export function NForm(props) {
    let { className = null, children } = props;

    var cn = [styles['nform']];

    if (className) {
        cn.push(className);
    }

    return (
        <div style={props.style} className={_.join(cn, ' ')}>
            {children}
        </div>
    );
}

/**
 *
 * @param {Object} props - Aceita children
 */
export function NFormTitle(props) {
    return (
        <div style={props.style} className={styles.nform_title}>
            {props.children}
        </div>
    );
}

/**
 *
 * @param {Object} props - Aceita direction(right ou left) e children
 */
export function NButtonGroup(props) {
    let { children, direction = 'right' } = props;

    direction = styles[`nbutton_group--${direction}`] || '';

    return (
        <div {...props} className={`${styles.nbutton_group} ${direction}`}>
            {children}
        </div>
    );
}


export function NTextArea(props) {

    return (
        <textarea cols={props.cols} rows={props.row} className={`${props.className} NTextArea`}  >
            {props.text}
        </textarea>
    )
}

/**
 *
 * @param {Object} props - Aceita htmlFor, text e children
 */
export function NLabel(props) {
    let { children, text, htmlFor, classLabelName } = props;

    return (
        <div className={`${styles.nlabel}`}>
            <label style={props.style} className={`${classLabelName}`} htmlFor={htmlFor}>{text}</label>
            {children}
        </div>
    );
}

/**
 *
 * @param {Object} props - Aceita label, id e weekdays
 */
export class NDatePicker extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $(`#${this.props.id}`).mask("99/99/9999");
    }

    render() {
        let {
            label,
            id,
            weekdays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            compact,
        } = this.props;

        return (
            <div className={styles.ndatepicker + " " + (compact ? styles.ndatepicker_compact : "")}>
                <NLabel text={label} htmlFor={id}>
                    <DatePicker
                        className={this.props.required ? styles.require : ''}
                        {...this.props}
                        weekdays={weekdays}
                        disabled={this.props.disabled}
                        popperPlacement="bottom" // lugar padrão de onde o calendário deve aparecer
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"]  // permanece o calendário no canto inferior
                            },
                            hide: {
                                enabled: false
                            },
                            offset: {
                                offset: '30px'
                            }
                        }}
                    />
                    {this.props.required ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{i18n.t('messages.requiredField', { ns: 'common' })}</div> : ''}
                </NLabel>
            </div>
        );
    }
}

/**
*
* @param {Object} props - Aceita direction(vertical ou horizontal) e children
Add a comment to this line
*/
export class NDatePickerInput extends React.Component {
    constructor(props) {
        super(props);

        var value = props.value || null;
        this.state = {
            value: value
        };

        this._valueDate = this._valueDate.bind(this);
        this.setDate = this.setDate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        var data = nextProps.value;
        this.setState({
            value: data,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        let a = this.state.value !== nextState.value || this.props.require !== nextState.require || this.state.value !== nextProps.value;
        return a;
    }

    setDate = date => this.setState({ value: date });

    _valueDate(data) {
        this.setState({
            value: this.state.value
        });
    }

    render() {
        let {
            label,
            id,
            disabled,
            onChange,
            maxDate,
            minDate,
            onBlur,
            onKeyDown,
            onChangeRaw,
            style,
            weekdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            className,
            floatingLabelStyle,
            selected,
            onSelect,
            autoComplete,
            labelClassName,
            name,
            underLineError
        } = this.props;

        var { value } = this.state;

        return (
            <div className={`${styles.ndatepicker} ${className}`} style={style}>
                <NLabel classLabelName={labelClassName} style={floatingLabelStyle ? floatingLabelStyle : { marginBottom: 0, textTransform: 'uppercase' }} text={label} htmlFor={id}>
                    <DatePicker className={this.props.require ? styles.require : className}
                        minDate={minDate}
                        value={value}
                        autoComplete={autoComplete}
                        styles={{ background: 'red' }}
                        onSelect={onSelect}
                        onChangeRaw={onChangeRaw ? onChangeRaw : event => Util.formatDate(event, this.setDate)}
                        onChange={onChange ? date => onChange(date, this.refs) : this._valueDate}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        placeholder={''}
                        selected={selected ? selected : value}
                        maxDate={maxDate}
                        weekdays={weekdays}
                        locale={moment.locale()}
                        type='date'
                        id={id}
                        name={name}
                        disabled={disabled}
                        popperPlacement="bottom" // lugar padrão de onde o calendário deve aparecer
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"]  // permanece o calendário no canto inferior
                            },
                            hide: {
                                enabled: false
                            },
                            offset: {
                                offset: '30px'
                            }
                        }}
                    />
                    {disabled ? <hr className='disabled-field' /> : null}
                    {this.props.require ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '5px' }} ref="validate">{i18n.t('messages.requiredField', { ns: 'common' })}</div> : ''}
                    {underLineError ? <hr style={{ borderBottom: '1px solid #F00', marginTop: '-15px', width: '100%', marginLeft: '0px', position: 'relative' }} /> : null}
                </NLabel>
            </div>
        );
    }
}

export class NInputTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            value: '',
            defaultStyle: {
                width: '100%'
            }
        }

        this.validarHora = this.validarHora.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        $('input[type="time"]:not([step=1])').mask('00:00').trigger('change');
        $('input[type="time"][step=1]').mask('00:00:00').trigger('change');
    }

    validarHora() {
        if (!this.state.isValid) {
            this.setState({
                isValid: true
            });
        }

        if (this.props.value.length == 5 || this.props.value.length == 8) {
            const regex = new RegExp(/^(([0-1]\d|2[0-3]):([0-5][0-9]))(:[0-5][0-9])?/);

            if (!regex.test(this.props.value)) {
                alertBox(i18n.t('messages.invalidLabel', { ns: 'common', label: this.props.label }), this.props.label, 'warning');
                this.setState(() => {
                    return {
                        isValid: false,
                        value: ''
                    }
                })
            }
        } else {
            alertBox(i18n.t('messages.invalidLabel', { ns: 'common', label: this.props.label }), this.props.label, 'warning');

            this.setState(() => {
                return {
                    isValid: false,
                    value: ''
                }
            })
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    handleChange(event, value) {
        let time = value.replace(/[^0-9]/g, '').match(/\d{1,2}/g);
        time = (time === null ? [] : time);

        if (this.props.withoutSeconds) {
            if (time.length > 2) return;
        } else {
            if (time.length > 3) return;
        }

        this.setState({ value: time.join(':') });

        if (this.props.handleChange) {
            this.props.handleChange(time.join(':'), this.state.isValid);
        }

        if (this.props.onChange) {
            this.props.onChange(event, value);
        }
    }

    render() {
        return (
            <TextField id={this.props.id}
                onChange={this.handleChange}
                floatingLabelText={this.props.label}
                type={this.props.type}
                step={this.props.step}
                value={this.props.value}
                floatingLabelFixed={true}
                errorText={this.props.require ? (this.props.errorText == '' ? this.props.errorText : i18n.t('messages.requiredField', { ns: 'common' })) : null}
                onBlur={this.props.validarHora ? this.props.validarHora : this.validarHora}
                floatingLabelStyle={this.props.floatingLabelStyle ? this.props.floatingLabelStyle : {
                    color: '#5B5B5B',
                    fontSize: '0.9rem',
                    textTransform: 'uppercase',
                    background: '#fff',
                    textOverflow: 'ellipsis'
                }}
                underlineStyle={this.props.require && !this.state.isValid ? { borderColor: 'red' } : null}
                underlineFocusStyle={{ borderColor: '#000' }}
                inputStyle={this.props.inputStyle}
                style={this.props.style || this.state.defaultStyle}
                disabled={this.props.disabled}
                errorStyle={this.props.errorStyle}
            />
        );
    }
}

export class NInputNumber extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $(`#${this.props.id}`).mask('0#');
    }

    render() {
        let {
            label,
            id,
            floatingLabelStyle,
            value
        } = this.props;

        return (
            <TextField id={id} floatingLabelText={label}
                {...this.props}
                value={value}
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={floatingLabelStyle ? floatingLabelStyle : { color: '#5B5B5B', textTransform: 'uppercase' }} />
        );
    }
}

export class IF extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.test) {
            return this.props.children;
        } else {
            return false;
        }

    }
}

export class NInputTelefone extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $(`#${this.props.id}`).mask('(00) 00000-0000');
    }

    render() {
        let {
            label,
            id,
            floatingLabelStyle
        } = this.props;

        return (
            <TextField id={id} floatingLabelText={label}
                {...this.props}
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={floatingLabelStyle ? floatingLabelStyle : { color: '#5B5B5B', textTransform: 'uppercase' }} />
        );
    }
}

export function NContainer(props) {
    let { children, direction = 'vertical' } = props;

    let style = styles[`ncontainer--${direction}`] || '';

    return (
        <div className={`${styles.ncontainer} ${style}`}>
            {children.map((c, i) => <div key={`i-${i}`} className={styles.ncontainer__item}>{c}</div>)}
        </div>
    );
}


/*
*  @param {String} msg - Mensagem a ser exibida
*  @param {String} title - Título do Alerta
*  @param {String} type - Tipo do alerta (error, warning, info, qualquer outro)
*/

export function alertBox(msg, title, type) {
    switch (type) {
        case 'error':
            Notifier.error(msg || 'Ops!', title || i18n.t('labels.error', { ns: 'common' }));
            break;
        case 'warning':
            Notifier.warning(msg || 'Ops!', title || i18n.t('labels.warning', { ns: 'common' }));
            break;
        case 'info':
            Notifier.info(msg || 'Ops!', title || i18n.t('labels.information', { ns: 'common' }));
            break;
        case 'success':
        default:
            Notifier.success(msg || 'Ops!', title || i18n.t('labels.information', { ns: 'common' }));
    }
}

export class NEditableInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { disabled } = nextProps;
        const { editing } = this.props;

        if (disabled && editing) {
            this.setState({ editing: false });
        }
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.props.onBlur) {
                if (this.props.onBlur()) {
                    this.setState({ editing: false });
                }
            } else {
                this.setState({ editing: false });
            }
        }
    }

    _handleChange(e) {
        if (this.props.onChange) this.props.onChange(e.target.value);
    }

    _handleBlur(e) {
        if (this.props.onBlur) {
            if (this.props.onBlur()) {
                this.setState({ editing: false });
            }
        } else {
            this.setState({ editing: false });
        }
    }

    _handleOnClick(e) {
        if (this.props.onClick &&
            (!$.contains(this._edit, e.target)) &&
            (!$.contains(this._delete, e.target))
        ) {
            this.props.onClick(e);
        }
    }

    render() {
        let { text, active = false, disabled = false } = this.props;
        let { editing } = this.state;

        return (
            <div
                onClick={this._handleOnClick.bind(this)}
            >
                {
                    editing ?
                        <input
                            autoFocus={true}
                            type="text"
                            onChange={this._handleChange.bind(this)}
                            onBlur={this._handleBlur.bind(this)}
                            onKeyPress={this._handleKeyPress.bind(this)}
                            value={text} />
                        : <span>
                            {text}&nbsp;
                            {
                                disabled ? null :
                                    <a href="javascript:void(0)" onClick={() => this.setState({ editing: true })}>
                                        <i className="material-icons">mode_edit</i>
                                    </a>
                            }
                        </span>
                }
            </div>
        );
    }
}

export class NEditableBagde extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            editable: props.editable === undefined ? true : props.editable,
            deletable: props.deletable === undefined ? true : props.deletable,
        };
        this._edit = false;
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.props.onBlur) {
                if (this.props.onBlur()) {
                    this.setState({ editing: false });
                }
            } else {
                this.setState({ editing: false });
            }
        }
    }

    _handleChange(e) {
        if (this.props.onChange) this.props.onChange(e.target.value);
    }

    _handleBlur(e) {
        if (this.props.onBlur) {
            if (this.props.onBlur()) {
                this.setState({ editing: false });
            }
        } else {
            this.setState({ editing: false });
        }
    }

    _handleDelete(e) {
        if (this.props.onDelete) this.props.onDelete(this.state.text);
    }

    _handleOnClick(e) {
        if (this.props.onClick &&
            (!$.contains(this._edit, e.target)) &&
            (!$.contains(this._delete, e.target))
        ) {
            this.props.onClick(e);
        }
    }

    getRefEdit(a) {
        this._edit = a;
    }

    getRefDelete(a) {
        this._delete = a;
    }

    render() {
        let { type = 'gray', text, active = false } = this.props;
        let { editing } = this.state;

        let style = styles[`neditablebagde--${type}`] || '';

        if (active) {
            style += ' ' + styles['neditablebagde--active'];
        }

        return (
            <div
                className={`${styles.neditablebagde} ${style}`}
                onClick={this._handleOnClick.bind(this)}
            >
                {
                    editing ?
                        <input
                            autoFocus={true}
                            className={styles.neditablebagde__select}
                            type="text"
                            onChange={this._handleChange.bind(this)}
                            onBlur={this._handleBlur.bind(this)}
                            onKeyPress={this._handleKeyPress.bind(this)}
                            value={text} />
                        : <div className={styles.neditablebagde_editable_holder}>
                            <span className={styles.neditablebagde__text_holder}>{text}</span>
                            <span className={styles.neditablebagde__buttons_holder}>
                                <IF test={this.state.editable}>
                                    <a
                                        ref={this.getRefEdit.bind(this)}
                                        href="javascript:void(0)"
                                        onClick={() => this.setState({ editing: true })}>
                                        <i className="material-icons">mode_edit</i>
                                    </a>
                                </IF>
                                <IF test={this.state.deletable}>
                                    <a
                                        ref={this.getRefDelete.bind(this)}
                                        href="javascript:void(0)"
                                        onClick={this._handleDelete.bind(this)}>
                                        <i className="material-icons">cancel</i>
                                    </a>
                                </IF>
                            </span>
                        </div>
                }
            </div>
        );
    }
}

/**
 *
 * @param {Object} props - Aceita mask e placeholder
 */
export class NMaskedInput extends React.Component {
    constructor(props) {
        super(props);
    }

    bindConfigurations() {
        let options = this.props.options || {};

        if (this.props.placeholder) {
            options.placeholder = this.props.placeholder;
        }

        if (this.props.onChange) {
            options.onChange = this.props.onChange;
        }

        $(this.input).unmask();

        $(this.input).mask(this.props.mask || "99/99/9999", options);
    }

    componentDidMount() {
        this.bindConfigurations();
    }

    componentDidUpdate() {
        this.bindConfigurations();
    }

    render() {
        let { className = '' } = this.props;

        className = styles.nmaskedinput + ' ' + className;

        const props = {
            ...this.props,
            onChange: () => { },
        };

        delete props.options;

        return (
            <input ref={input => this.input = input} type="text" {...props} className={className} />
        );
    }
}

export class NMaskedTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            masked: props.value
        }
    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        return {
            value,
            selection
        };
    }

    render() {
        var { ref, onChange, onBlur, onKeyDown, className, id, value, labelName, style, tabIndex } = this.props;

        let mask = value ? value.replace(/\D/g, '').length > 6 ? '999:99:99' : '99:99:99?' : '99:99:99';
        let maskChar = value ? value.replace(/\D/g, '').length == 6 ? " " : "-" : "-";

        return (
            <span>
                <InputMask
                    mask={mask}
                    maskChar={maskChar}
                    formatChars={{ "9": "[0-9]", "?": "[0-9]" }}
                    tabIndex={tabIndex}
                    ref={ref}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    required={true}
                    className={className}
                    style={style}
                    id={id}
                    type={'text'}
                    value={value}
                    autoComplete='off'
                    beforeMaskedValueChange={this.beforeMaskedValueChange}
                />
                <label htmlFor={id}>{labelName}</label>
            </span>
        );

    }
}

export class NMaskedOee extends React.Component {
    constructor(props) {
        super(props);
    }

    formatarNumero(value) {
        let stateValue = value.toString();
        stateValue = stateValue.includes(",") ? stateValue.replace(",", ".") : stateValue; //Como a máscara possui virgula, o parseFloat n reconhece como decimal
        stateValue = parseFloat(stateValue);
        stateValue = stateValue.toFixed(2).toString();
        stateValue = Util.zeroAEsquerda(stateValue, 6) //Deixa os números com a mesma quantidade de casas.

        return stateValue;
    }

    render() {
        let { title, id, mask, floatingLabelStyle, onChange, value, className, formatChars, maskChar, doNotShowLabel, inputStyle, onBlur } = this.props
        value = this.formatarNumero(value)

        return (
            <span>
                {
                    doNotShowLabel ?
                        null
                        :

                        <NLabel
                            style={floatingLabelStyle}
                            classLabelName={id}
                            text={title}
                        />
                }
                <InputMask
                    mask={mask}
                    className={className}
                    value={value}
                    id={id}
                    onChange={onChange}
                    formatChars={formatChars}
                    maskChar={maskChar}
                    style={inputStyle}
                    onBlur={onBlur}
                />
            </span>
        );
    }
}

export class NMaskedInputPhone extends React.Component {
    constructor(props) {
        super(props);
    }

    bindConfigurations() {
        let options = this.props.options || {};

        if (this.props.placeholder) {
            options.placeholder = this.props.placeholder;
        }

        if (this.props.onChange) {
            options.onChange = this.props.onChange;
        }

        $(`#${this.props.id}`).unmask();

        $(`#${this.props.id}`).mask(this.props.mask || "(99) 99999 - 9999", options);
    }

    componentDidMount() {
        this.bindConfigurations();
    }

    render() {
        let {
            label,
            id,
        } = this.props;

        return (
            <TextField id={id} floatingLabelText={label}
                {...this.props}
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '0.9em', textTransform: 'uppercase' }} />
        );
    }
}

export class NStepSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleOnClick(e, i) {
        const { disabled = [] } = this.props;
        if (this.props.onChange && (!disabled[i])) this.props.onChange(e, i);
    }

    render() {
        const { steps, disabled = [] } = this.props;
        let { active } = this.props;

        active = parseInt(active);

        let stepHolders =
            steps.map((s, i) => {
                let style = '';

                if (disabled[i] || (typeof (s.disabled) !== 'undefined' && s.disabled)) {
                    style = ` ${styles['nstepselector--disabled']} disabled`;
                } else if (active !== NaN && active == i) {
                    style = ` ${styles['nstepselector--active']} active`;
                }

                return (
                    <div
                        key={`step-${i}`}
                        className={`${styles.nstepselector__stepholder}${style}`}
                        onClick={e => this._handleOnClick(e, i)}
                    >
                        {this.props.onDraw ? this.props.onDraw(s, i) : s.name}
                    </div>
                );
            });

        return (
            <div className={styles.nstepselector}>
                <div className={styles.nstepselector__stepsholder}>
                    {stepHolders}
                </div>
            </div>
        );
    }
}

export class NInputPercentage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $(`#${this.props.id}`).mask('##%', {
            reverse: true, onChange: (val) => {
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            }
        });
    }

    render() {
        var props = {
            ...this.props,
            onChange: () => { }
        };

        return (
            <div >
                <input
                    {...props}
                    defaultValue={this.props.value}
                    id={this.props.id}
                    maxLength={6}
                    className={styles.inputPercentage} />
            </div>
        );
    }
}

export class InputPercentage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        $(`#${this.props.id}`).mask('##.##%', {
            reverse: true, onChange: (val) => {
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            }
        });

    }

    render() {
        var props = {
            ...this.props,
            onChange: () => { }
        };

        return (
            <TextField
                {...props}
                defaultValue={this.props.value}
                id={this.props.id}
                maxLength={7} />
        );
    }
}

export class NAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { text: '', value: null }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(obj) {
        this.setState({ value: obj.value });
        if (this.props.onChange) this.props.onChange(obj);
    }

    render() {
        return (
            <AutoComplete
                floatingLabelText={this.props.label}
                dataSourceConfig={{ text: 'text', value: 'value' }}
                openOnFocus={true}
                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase' }}
                errorText={this.state.erroEquip}
                underlineFocusStyle={{ borderColor: '#000000' }}
                onNewRequest={this.handleChange}
                dataSource={this.props.options}
                filter={AutoComplete.fuzzyFilter}
                popoverProps={{ className: 'autocomplete' }}
                style={this.props.style}
            />
        );
    }
}

/**
 *
 * @param {Object} props - Aceita mask e placeholder
 */
export function NInput(props) {

    let { className = '', required } = props;

    className = styles.ninput + ' ' + className;

    let onChange = props.onChange || (() => { });
    let value = props.value || '';

    return (
        <input
            type="text"
            {...props}
            onChange={onChange}
            value={value}
            style={{ borderBottom: required ? 'red 2px solid' : '' }}
            className={className}
        />
    );
}

export function NFormGroup(props) {
    let { className = null, children, direction = null, disabled = false } = props;

    var cn = [styles['nform-group']];

    if (direction) {
        cn.push(styles[`nform-group--${direction}`]);
    }

    if (className) {
        cn.push(className);
    }

    if (disabled) {
        return null;
    }

    return <div className={_.join(cn, ' ')}>
        {children}
    </div>
}

export function NSmall(props) {
    let { className = null, children, disabled = false } = props;

    var cn = [styles['nform-small']];

    if (className) {
        cn.push(className);
    }

    return disabled ? <small className={_.join(cn, ' ')}>
        {children}
    </small> : null
}

export class NTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'text',
            value: ''
        }

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleFocus() {
        this.setState({
            type: 'time'
        });
    }

    handleBlur() {
        if (this.state.value === undefined || this.state.value === '') {
            this.setState({
                type: 'text'
            });
        }
    }

    render() {
        let {
            label,
            id,
        } = this.props;

        return (
            <TextField id={id}
                floatingLabelText={label}
                {...this.props}
                type={this.state.type}
                autoComplete={'off'}
                placeholder={''}
                onFocus={this.handleFocus.bind(this)}
                onBlur={this.handleBlur.bind(this)}
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1em', textTransform: 'uppercase' }} />
        );
    }
}

export function NConfirm(props) {
    let { className = null, children } = props;

    var cn = [styles['nconfirm']];

    if (className) {
        cn.push(className);
    }

    return (
        <NForm className={_.join(cn, ' ')}>
            <div>
                {
                    props.mensagens.map(mensagem => <div>{mensagem}</div>)
                }
            </div>
            <br />
            <NButtonGroup direction="end">
                <NButton className={styles['nconfirm__button']} type="primary" onClick={props.yes}>{i18n.t('labels.yes', { ns: 'common' })}</NButton>
                <NButton className={styles['nconfirm__button']} onClick={props.no}>{i18n.t('labels.no', { ns: 'common' })}</NButton>
            </NButtonGroup>
        </NForm>
    )
}
