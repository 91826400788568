import React from 'react'
import NumberFormat from 'react-number-format';

import { TextField } from './TextField';

export class CurrencyInput extends NumberFormat {

    currencyFormatter(value) {
        if (!Number(value)) return "";

        const amount = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(value / 100);

        return `${amount}`;
      }

    render() {
        return <NumberFormat customInput={TextField} {...this.props} format={this.currencyFormatter} />
    }
   
}