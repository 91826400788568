import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NButton } from '../../../NForm';

@inject('store')
@observer
class DeleteVigenciaRotas extends Component {
    constructor(props){
        super(props);
        
        this.alertVigenciaStore = this.props.store.alertVigenciaRotas;
        this.modalStore = this.props.store.modalCommon;
        this.modalItensStore = this.props.store.modalItens;
        this.timeMachineRotas = this.props.store.timeMachineRotas;

        // Item que será excluido
        this.vigenciaExclusao = this.modalStore.Values.slice()[0].itemIndex;
        
        // Estado comum entre meus componentes de timeMachineRotas
        this.commonState = this.modalStore.Values.slice()[0].commonState.slice();

    }
    
    render(){
        return(
            <div>
                <NButton type="primary" onClick={() => {
                    let newState = this.alertVigenciaStore.Yes(this.vigenciaExclusao, this.commonState)

                    this.modalItensStore.setCustomArray(newState);
                    this.timeMachineRotas.setArrayItensCommon(newState);
                    this.modalItensStore.setTriggredAdd(true);
                    this.alertVigenciaStore.CustomNoFunction();

                }}>
                    Sim
                </NButton>
                <NButton onClick={() => this.alertVigenciaStore.No()}>
                    Não
                </NButton>
            </div>
        )
    }
}


export default DeleteVigenciaRotas