import _ from 'lodash';
import { Chart, HorizontalBar } from 'react-chartjs-2';

export const SecondBarPlugin = {
    afterDatasetsDraw: function (chart, easing) {
        if (!chart.options.secondBarPlugin || !chart.options.secondBarPlugin.display) return;

        let ctx = chart.chart.ctx;
        let chartArea = chart.chartArea;
        let options = chart.options.secondBarPlugin;
        let layoutPadding = chart.options.layout.padding;
        let helpers = Chart.helpers;
        let globalConfig = Chart.defaults.global;

        let fontStyle = helpers.getValueOrDefault(options.fontStyle, globalConfig.defaultFontStyle);
        let fontFamily = helpers.getValueOrDefault(options.fontFamily, globalConfig.defaultFontFamily);
        let fontSize = helpers.getValueOrDefault(options.fontSize, 12);

        let configs = {
            font: helpers.fontString(fontSize, fontStyle, fontFamily),
            fillStyle: helpers.getValueOrDefault(options.fontColor, globalConfig.defaultFontColor),
            baseLine: helpers.getValueOrDefault(options.baseLine, 'middle'),
            formatter: helpers.getValueOrDefault(options.formatter, function (value) { return value.toString(); }),
            paddingLeft: helpers.getValueOrDefault(options.paddingLeft, 0),
            paddingRight: helpers.getValueOrDefault(options.paddingRight, 0),
            paddingTop: helpers.getValueOrDefault(options.paddingTop, -8),
            backgroundColor: helpers.getValueOrDefault(options.backgroundColor, 'rgba(128, 128, 128, .6)'),
            overlap: helpers.getValueOrDefault(options.overlap, true),
            halfCornerRadius: helpers.getValueOrDefault(options.halfCornerRadius, true)
        }

        ctx.restore();
        ctx.save();
        for (let i = 0; i < chart.data.datasets.length; i++) {
            let dataset = chart.data.datasets[i];
            for (let j = 0; j < chart.data.labels.length && chart.getDatasetMeta(i).data.length; j++) {

                let meta = chart.getDatasetMeta(i);
                let model = meta.data[j]._model;
                let height = model.height / 2;
                let xAxis = chart.scales[meta.xAxisID];
                let yAxis = chart.scales[meta.yAxisID];
                let minX = xAxis.getPixelForTick(xAxis.zeroLineIndex);
                let maxWidth = xAxis.width;
                let x = minX;
                let y = yAxis.getPixelForTick(j) - height;

                let dataValue = dataset.data[j];
                let barValue = dataset.data2[j];
                let max = Math.max.apply(null, dataset.data);
                let peso = (barValue / (max || 1));
                let width = configs.overlap ? peso * (maxWidth - configs.paddingRight) : ((peso > 1 ? 1 : peso) * maxWidth);
                ctx.fillStyle = configs.backgroundColor;
                ctx.beginPath();
                //ctx.fillRect(x, y, width, height);
                let radius = 10;
                if (configs.halfCornerRadius) {
                    if (radius > width) {
                        radius = width;
                    }
                    ctx.moveTo(x, y);
                    ctx.lineTo(x + width, y);
                    ctx.lineTo(x + width, y + height - radius);
                    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                    ctx.lineTo(x, y + height);
                    ctx.lineTo(x, y);
                } else {
                    ctx.moveTo(x, y);
                    ctx.lineTo(x + width, y);
                    ctx.lineTo(x + width, y + height);
                    ctx.lineTo(x, y + height);
                    ctx.lineTo(x, y);
                }

                ctx.closePath();
                ctx.fill();
                ctx.font = configs.font;
                ctx.textAlign = 'start';
                ctx.textBaseline = configs.baseLine;

                let textX = 0;
                let text = configs.formatter(barValue, j);
                let color = helpers.color(model.backgroundColor);
                let isBgBlack = color.greyscale().luminosity() < 100;
                let middle = maxWidth / 2;
                let textSize = (text.length * fontSize);
                let forceColor = false;
                let modelX = minX + width;
                if ((modelX / 2) > (textSize + minX + configs.paddingLeft)) {
                    textX = modelX / 2;
                } else if (modelX >= (textSize + minX + configs.paddingLeft)) {
                    textX = modelX - textSize;
                    forceColor = true;
                } else {
                    textX = modelX;
                }

                if (!configs.fillStyle) {
                    let haveBg = modelX > (textX + textSize + minX + configs.paddingLeft);
                    if (isBgBlack && haveBg || isBgBlack && forceColor) {
                        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
                    } else {
                        ctx.fillStyle = globalConfig.defaultFontColor;
                    }
                } else {
                    ctx.fillStyle = configs.fillStyle;
                }

                ctx.beginPath();
                ctx.fillText(text, textX + configs.paddingLeft, y + height + configs.paddingTop);
                ctx.closePath();
            }
        }
    }
};

export const BarIndicatorPlugin = {
    beforeUpdate: function (chart, easing) {
        if (!chart.options.forceHeight) return;
        let height = chart.options.forceHeight;
        if (chart.height != height) {
            chart.height = chart.canvas.height = height;
        }
    },
    beforeDatasetsDraw: function (chart, easing) {
        if (!chart.options.barIndicatorPlugin) return;
        var ctx = chart.chart.ctx;
        var chartArea = chart.chartArea;
        var yAxis = chart.scales[Object.keys(chart.scales)[1]];
        ctx.restore();
        ctx.save();
        for (var i = 0; i < chart.data.datasets.length; i++) {
            var dataset = chart.data.datasets[i];
            for (var j = 0; j < chart.data.labels.length && chart.getDatasetMeta(i).data.length > 0; j++) {
                var size = chart.getDatasetMeta(i).data[j]._model.height;
                var x = yAxis.right - (size / 2);
                var y = yAxis.getPixelForTick(j) - (size / 2);

                ctx.beginPath();
                ctx.fillStyle = (dataset.indicatorBackground && dataset.indicatorBackground[j]) ? dataset.indicatorBackground[j] : 'rgba(115, 24, 17, 1)';
                ctx.fillRect(x + (size / 3.3), y + 3, (size / 3), size - 6);
                ctx.closePath();
            }
        }
    },
    afterDatasetsDraw: function (chart, easing) {
        if (!chart.options.barIndicatorPlugin) return;
        var ctx = chart.chart.ctx;
        var chartArea = chart.chartArea;
        var yAxis = chart.scales[Object.keys(chart.scales)[1]];
        ctx.restore();
        ctx.save();
        for (var i = 0; i < chart.data.datasets.length; i++) {
            var dataset = chart.data.datasets[i];
            for (var j = 0; j < chart.data.labels.length && chart.getDatasetMeta(i).data.length; j++) {
                var datasetMeta = chart.getDatasetMeta(i);
                var model = datasetMeta.data[j]._model;
                var size = model.height;
                var x = yAxis.right - (size / 2);
                var y = yAxis.getPixelForTick(j) - (size / 2);

                ctx.fillStyle = (dataset.indicatorColor && dataset.indicatorColor[j]) ? dataset.indicatorColor[j] : 'rgba(208, 56, 46, 1)';
                ctx.beginPath();
                ctx.moveTo(x + (size / 3.3), y + 4);
                ctx.lineTo(x + (size / 1.5), y + (size / 2));
                ctx.lineTo(x + (size / 3.3), y + size - 4);
                ctx.fill();
            }
        }
    }
};

export const DoughnutLegendPlugin = {
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
            var helpers = Chart.helpers;
            var centerConfig = chart.config.options.elements.center;
            var globalConfig = Chart.defaults.global;
            var ctx = chart.chart.ctx;

            var textFontStyle = helpers.getValueOrDefault(centerConfig.textFontStyle, globalConfig.defaultFontStyle);
            var textFontFamily = helpers.getValueOrDefault(centerConfig.textFontFamily, globalConfig.defaultFontFamily);

            var dataLabelFontStyle = helpers.getValueOrDefault(centerConfig.dataLabelFontStyle, globalConfig.defaultFontStyle);
            var dataLabelFontFamily = helpers.getValueOrDefault(centerConfig.dataLabelFontFamily, globalConfig.defaultFontFamily);

            chart.center = {
                textFont: helpers.fontString(centerConfig.textFontSize, textFontStyle, textFontFamily),
                textFillStyle: helpers.getValueOrDefault(centerConfig.textFontColor, globalConfig.defaultFontColor),
                textBaseLine: helpers.getValueOrDefault(centerConfig.textBaseLine, 'middle'),

                dataLabelFontSize: centerConfig.dataLabelFontSize,
                dataLabelFillStyle: helpers.getValueOrDefault(centerConfig.dataLabelFontColor, globalConfig.defaultFontColor),
                dataLabelFont: helpers.fontString(centerConfig.dataLabelFontSize, dataLabelFontStyle, dataLabelFontFamily),
                dataLabelBaseLine: helpers.getValueOrDefault(centerConfig.dataLabelBaseLine, 'middle'),
                dataLabelTextAlign: helpers.getValueOrDefault(centerConfig.dataLabelTextAlign, 'center')
            };
        }
    },
    afterDraw: function (chart) {
        if (chart.center) {
            let ctx = chart.chart.ctx;
            ctx.save();

            let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            let centerY = chart.config.options.circumference === Math.PI
                ? (chart.chartArea.bottom - chart.chartArea.top) : (chart.chartArea.top + chart.chartArea.bottom) / 2;

            const radians = Chart.helpers.toRadians;
            const degrees = Chart.helpers.toDegrees;

            for (let index = 0; index < chart.data.datasets.length; index++) {
                const dataset = chart.data.datasets[index];
                if (dataset.dataLabel) {
                    for (let dataIndex = 0; dataIndex < dataset.dataLabel.length; dataIndex++) {
                        const datalabel = dataset.dataLabel[dataIndex];
                        let element = chart.getDatasetMeta(index).data[dataIndex];
                        let vm = element._model;
                        let textSize = (chart.center.dataLabelFontSize / 4) * datalabel.length;
                        let angle = vm.endAngle - vm.startAngle;
                        let factorY = 0, factorX = 0;
                        let isHalf = chart.options.circumference == Math.PI;

                        if (angle >= 0 && angle <= radians(80)) {
                            factorY = 0;
                            factorX = isHalf ? -textSize : textSize;
                        } else if (angle > radians(80) && angle < radians(180)) {
                            factorY = 5;
                            factorX = textSize;
                        } else if (angle >= radians(180) && angle < radians(270)) {
                            factorY = 5;
                            factorX = isHalf ? +textSize : -textSize;
                        } else if (angle < radians(320)) {
                            factorX = -textSize;
                            factorY = 0;
                        } else {
                            factorY = -5;
                            factorX = 0;
                        }

                        let point = {
                            x: vm.x + Math.cos(vm.endAngle) * vm.outerRadius,
                            y: vm.y + Math.sin(vm.endAngle) * vm.outerRadius
                        };
                        ctx.font = chart.center.dataLabelFont;
                        ctx.fillStyle = chart.center.dataLabelFillStyle;
                        ctx.textAlign = chart.center.dataLabelTextAlign;
                        ctx.textBaseline = chart.center.dataLabelBaseLine;
                        ctx.fillText(datalabel, point.x + factorX, point.y + factorY);
                    }
                } else if (dataset.center) {
                    ctx.font = chart.center.textFont;
                    ctx.fillStyle = dataset.centerTextColor || 'black';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = chart.center.textBaseLine;
                    ctx.fillText(dataset.center, centerX, centerY);
                    ctx.restore();
                    ctx.save();
                }
            }
            ctx.restore();
        }
    },
};

export const BreakLineTicksPlugin = {
    beforeInit: function (chart) {
        if (chart.options && chart.options.scales && chart.options.scales.yAxes && chart.options.scales.yAxes[0] && chart.options.scales.yAxes[0].ticks.maxWidth) {
            let ctx = chart.ctx;
            let tickFontSize = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontSize, Chart.defaults.global.defaultFontSize);
            let maxWidth = chart.options.scales.yAxes[0].ticks.maxWidth;
            let maxLineWidth = 0;
            chart.hasWrappedTicks = {
                labels: [],
                tickFontSize: tickFontSize,
                maxWidth: maxWidth
            };
            chart.config.data.labels.forEach(function (label, labelIndex) {
                let fontSize = tickFontSize;
                let text = label;
                let width = maxWidth;
                let lines = [];
                let line = '';
                let lineTest = '';
                let words = text.split(' ');
                let currentY = 0;
                for (var i = 0, len = words.length; i < len; i++) {
                    lineTest = line + words[i] + ' ';
                    let lineWidth = ctx.measureText(lineTest).width;
                    maxLineWidth = maxLineWidth < lineWidth ? lineWidth : maxLineWidth;
                    if (ctx.measureText(lineTest).width > width) {
                        currentY = lines.length * fontSize + fontSize - 5;
                        lines.push({ text: line, height: currentY, width: lineWidth });
                        line = words[i] + ' ';
                    } else {
                        line = lineTest;
                    }
                }
                if (line.length > 0) {
                    currentY = lines.length * fontSize + fontSize;
                    let lineWidth = ctx.measureText(lineTest).width;
                    lines.push({ text: line.trim(), height: currentY, width: lineWidth });
                    maxLineWidth = maxLineWidth < lineWidth ? lineWidth : maxLineWidth;
                }
                chart.hasWrappedTicks.labels.push(lines);
            });
            if(chart.hasWrappedTicks.labels.length > 0){
                let w = maxLineWidth + (maxWidth - maxLineWidth);
                chart.options = Chart.helpers.configMerge(chart.options, {
                    scales: {
                        yAxes: [{
                            ticks: {
                                display: true,
                                fontSize: 0,
                                padding: ((maxLineWidth + 10) < w) ? maxLineWidth + 15 : w + 30
                            }
                        }]
                    }
                });
            }
        }
    },
    afterDatasetDraw: function (chart) {
        if (chart.hasWrappedTicks && chart.hasWrappedTicks.labels.length) {
            let ctx = chart.ctx;
            ctx.restore();
                ctx.save();
                var tickFontSize = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontSize, Chart.defaults.global.defaultFontSize);
                var tickFontStyle = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontStyle, Chart.defaults.global.defaultFontStyle);
                var tickFontFamily = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].ticks.fontFamily, Chart.defaults.global.defaultFontFamily);
                var tickLabelFont = Chart.helpers.fontString(tickFontSize, tickFontStyle, tickFontFamily);
                ctx.font = tickLabelFont;
                ctx.textAlign = 'end';
                ctx.textBaseline = 'middle';
                var tickFontColor = Chart.helpers.getValueOrDefault(chart.options.scales.xAxes[0].fontColor, Chart.defaults.global.defaultFontColor);
                ctx.fillStyle = tickFontColor;

                var meta = chart.getDatasetMeta(0);
                var xScale = chart.scales[meta.xAxisID];
                var yScale = chart.scales[meta.yAxisID];

                chart.hasWrappedTicks.labels.forEach(function (label, labelIndex) {
                    let lines = label;
                    for (var i = 0, len = lines.length; i < len; i++) {
                        ctx.fillText(lines[i].text, xScale.getPixelForTick(0) - 5, -tickFontSize + lines[i].height + yScale.getPixelForTick(labelIndex));
                    }
                });
        }
    }
};

export const HorizontalBarLegendPlugin = {
    afterDatasetsDraw: function (chart, easing) {
        if (!chart.options.horizontalBarLabel || !chart.options.horizontalBarLabel.display) return;

        var ctx = chart.chart.ctx;
        var chartArea = chart.chartArea;
        var yAxis = chart.scales[Object.keys(chart.scales)[1]];
        let marginLeft = chart.options.horizontalBarLabel.marginLeft || 10;

        ctx.restore();
        ctx.save();
        for (var i = 0; i < chart.data.datasets.length; i++) {
            var dataset = chart.data.datasets[i];
            for (var j = 0; j < chart.data.labels.length; j++) {
                var datasetMeta = chart.getDatasetMeta(i);
                var model = datasetMeta.data[j]._model;
                var size = model.height;
                var x = yAxis.right - (size / 2);
                var y = yAxis.getPixelForTick(j) - (size / 2);

                ctx.fillStyle = 'rgba(128, 128, 128, 1)';

                if (chart.options.horizontalBarLabel.fontSize) {
                    let helpers = Chart.helpers;
                    let globalConfig = Chart.defaults.global;
                    let fontStyle = globalConfig.defaultFontStyle;
                    let fontFamily = globalConfig.defaultFontFamily;
                    let fontSize = helpers.getValueOrDefault(chart.options.horizontalBarLabel.fontSize, globalConfig.defaultFontSize);
                    let font =  helpers.fontString(fontSize, fontStyle, fontFamily);
                    ctx.font = font;
                }

                var label = model.label;
                ctx.fillText(label, x + marginLeft, model.y - (size / 2) - 5);

            }
        }
    }
};

export const HorizontalBarBackgroundPlugin = {
    beforeDraw: function (chart, easing) {
        let options = chart.config.options.horizontalBarBackground;
        if (options) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            //ctx.save();
            if (chart.config.data.datasets.length > 0) {
                var meta = chart.config.data.datasets[0]._meta[_.keys(chart.config.data.datasets[0]._meta)[0]];
                var data = chart.config.data.datasets[0].data;
                let xAxis = chart.scales[meta.xAxisID];

                for (var i = 0; i < meta.data.length; i++) {
                    var model = meta.data[i]._model;
                    //ctx.beginPath();
                    ctx.fillStyle = options.backgroundColor || 'rgba(0, 0, 0, 1)';
                    ctx.fillRect(xAxis.getPixelForTick(xAxis.zeroLineIndex), model.y - (model.height / 2), xAxis.width, model.height);
                    //ctx.closePath();
                }
            }
            //ctx.restore();
        }
    }
};

export const HorizontalBarDataLabelPlugin = {
    afterDatasetsDraw: function (chart, easing) {
        let options = chart.config.options.horizontalBarDataLabel;

        if (options && options.display) {

            var chartArea = chart.chartArea;
            var helpers = Chart.helpers;
            var globalConfig = Chart.defaults.global;
            var ctx = chart.chart.ctx;
            ctx.save();
            var fontStyle = helpers.getValueOrDefault(options.fontStyle, globalConfig.defaultFontStyle);
            var fontFamily = helpers.getValueOrDefault(options.fontFamily, globalConfig.defaultFontFamily);
            var fontSize = helpers.getValueOrDefault(options.fontSize, 10);

            let config = {
                font: helpers.fontString(fontSize, fontStyle, fontFamily),
                fillStyle: helpers.getValueOrDefault(options.fontColor, globalConfig.defaultFontColor),
                baseLine: helpers.getValueOrDefault(options.baseLine, 'middle'),
                formatter: helpers.getValueOrDefault(options.formatter, function (value) { return value; }),
                paddingLeft: helpers.getValueOrDefault(options.paddingLeft, 15),
                paddingTop: helpers.getValueOrDefault(options.paddingTop, 0),
                align: helpers.getValueOrDefault(options.align, 'start'),
                icon: options.icon
            };

            if (chart.config.data.datasets.length > 0) {
                var meta = chart.config.data.datasets[0]._meta[_.keys(chart.config.data.datasets[0]._meta)[0]];
                var data = chart.config.data.datasets[0].data;
                let xAxis = chart.scales[meta.xAxisID];

                for (var i = 0; i < meta.data.length; i++) {
                    var model = meta.data[i]._model;
                    let minX = xAxis.getPixelForTick(xAxis.zeroLineIndex);
                    let maxWidth = xAxis.getPixelForTick(xAxis.zeroLineIndex) + xAxis.width;
                    let text = config.formatter(data[i], i, chart.config.data.datasets[0]);
                    let x = 0;

                    ctx.font = config.font;
                    ctx.fillStyle = config.fillStyle;
                    ctx.textAlign = 'start';
                    ctx.textBaseline = config.baseLine;

                    if (config.align === 'end') {
                        x = maxWidth;
                    } else if (config.align === 'start') {
                        x = model.x;
                    } else if (config.align === 'middle') {
                        x = maxWidth / 2;
                    } else if (config.align === 'auto') {
                        let color = helpers.color(model.backgroundColor);
                        let isBgBlack = color.greyscale().luminosity() < 100;
                        let middle = maxWidth / 2;
                        let textSize = (text.length * fontSize);
                        let forceColor = false;

                        if ((model.x / 2) > (textSize + minX + config.paddingLeft)) {
                            x = model.x / 2;
                        } else if (model.x >= (textSize + minX + config.paddingLeft)) {
                            x = model.x - textSize;
                            forceColor = true;
                        } else {
                            x = model.x;
                        }

                        let haveBg = model.x > (x + textSize + minX + config.paddingLeft);

                        if (isBgBlack && haveBg || isBgBlack && forceColor) {
                            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
                        } else {
                            ctx.fillStyle = globalConfig.defaultFontColor;
                        }
                    }

                    ctx.fillText(text, x + config.paddingLeft, model.y + config.paddingTop);
                }
            }

            ctx.restore();
        }
    }
};

export const blueGradient = (canvas) => {
    let ctx = canvas.getContext('2d');
    let azulEscuro = ctx.createLinearGradient(0, canvas.clientHeight / 2, canvas.clientWidth, canvas.clientHeight / 2);
    azulEscuro.addColorStop(0.000, 'rgba(43, 70, 102, 1.000)');
    azulEscuro.addColorStop(0.8, 'rgba(105, 139, 178, 1.000)');
    return azulEscuro;
}

export const blueGradientVertical = (canvas) => {
    let ctx = canvas.getContext('2d');
    let azulEscuro = ctx.createLinearGradient(canvas.clientWidth / 2, canvas.clientHeight - 50, canvas.clientWidth / 2, 50);
    azulEscuro.addColorStop(0.000, 'rgba(43, 70, 102, 1.000)');
    azulEscuro.addColorStop(0.8, 'rgba(105, 139, 178, 1.000)');
    return azulEscuro;
}

export const makeGradient = (canvas, startColor, endColor) => {
    let ctx = canvas.getContext('2d');
    let grad = ctx.createLinearGradient(0, canvas.clientHeight / 2, canvas.clientWidth, canvas.clientHeight / 2);

    grad.addColorStop(0, startColor);
    grad.addColorStop(1, endColor);
    return grad;
};

//
//Função responsável por deixar os retangulos com bordas redondas
//
export const CustomRectangleDraw = function () {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var left, right, top, bottom, signX, signY, borderSkipped, radius;
    var borderWidth = vm.borderWidth;

    if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
    } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
        }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom]
    ];

    // Find first (starting) corner with fallback to 'bottom'
    var borders = ['bottom', 'left', 'top', 'right'];
    var startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
        startCorner = 0;
    }

    function cornerAt(index) {
        return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    var corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);
    var nextCornerId, width, height, x, y;
    for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        nextCornerId = i + 1;
        if (nextCornerId === 4) {
            nextCornerId = 0
        }

        cornerAt(nextCornerId);

        width = corners[2][0] - corners[1][0];
        height = corners[0][1] - corners[1][1];
        x = corners[1][0];
        y = corners[1][1];

        radius = this._chart.options.halfCornerRadius ? 20 : 30;

        if (this._chart.options.halfCornerRadius) {
            if (radius > width) {
                radius = width;
            }
            ctx.moveTo(x, y);
            ctx.lineTo(x + width, y);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y);
        } else if (this._chart.options.cornerRadius) {
            radius = this._chart.options.cornerRadius;
            if (radius > width) {
                radius = width;
            }

            ctx.moveTo(x + radius, y);
            // top right corner
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            // bottom right   corner
            ctx.lineTo(x + width, y + height);
            // bottom left corner
            ctx.lineTo(x, y + height);
            // top left   
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        } else if (!(this._chart.options.barRounded)) {
            ctx.moveTo(x, y);
            ctx.lineTo(x + width, y);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y);
        } else if (this._datasetIndex === 0) {
            if (radius > height) {
                radius = height;
            }
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        } else if (this._datasetIndex === 1) {
            if (radius > -height) {
                radius = -height;
            }
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y - radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y - radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        }
    }
    ctx.fill();
    if (borderWidth) {
        ctx.stroke();
    }
};

// Mostrar o label no topo da barra vertical
// topBarLabel:{
//    display: false
//    label: 'o label do elemente que irá aparecer no topo'
//    fontSize: 'tamanho da fonte'
// }
export const BarTopLabelPlugin = {

    afterDatasetsDraw: function (chart) {
        if (chart.config.options.topBarLabel && chart.config.options.topBarLabel.display) {
            var ctx = chart.ctx;
            ctx.textBaseline = 'top';
            chart.data.datasets.forEach(function (dataset, i) {
                
                if(dataset.label === chart.config.options.topBarLabel.label) {
                    var meta = chart.getDatasetMeta(i);
                    meta.data.forEach(function(element, index) {
                        ctx.save();
                        // Draw the text in black, with the specified font
                        ctx.fillStyle = 'rgb(0, 0, 0)';

                        
                        var fontSize = chart.config.options.topBarLabel.fontSize || 11;
                        var fontStyle = 'bold';
                        var fontFamily = 'Roboto';
                        ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                        // Just naively convert to string for now
                        var dataString = dataset.data[index].toString();

                        // Make sure alignment settings are correct
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        var padding = 5;
                        var position = element.tooltipPosition();
                        
                        if(chart.config.options.topBarLabel.rotate){
                            //Rotate the context and draw the text
                            ctx.translate(position.x, position.y - (fontSize / 2) - padding);
                            ctx.rotate(-Math.PI / 3);
                            ctx.fillText(dataString, 2, 2);
                        } else {
                            ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
                        }

                        ctx.restore();
                    });
                }
            });
        }
    }
};

/**
 * Initialize All Plugins
 * @param {*Cart} Chart 
 */
export default function (Chart) {
    Chart.pluginService.register(SecondBarPlugin);
    Chart.pluginService.register(BarIndicatorPlugin);
    Chart.pluginService.register(DoughnutLegendPlugin);
    Chart.pluginService.register(HorizontalBarLegendPlugin);
    Chart.pluginService.register(HorizontalBarBackgroundPlugin);
    Chart.pluginService.register(HorizontalBarDataLabelPlugin);
    Chart.pluginService.register(BreakLineTicksPlugin);
    Chart.pluginService.register(BarTopLabelPlugin);

    Chart.elements.Rectangle.prototype.draw = CustomRectangleDraw;
};