import PropTypes from 'prop-types';
import React from "react";
import { redirectClient } from '../helper/ClientHelper';

export default class LinkClientV2 extends React.Component {
    render() {
        let link = this.props.to;

        if (link.startsWith("/")) {
            link = link.substring(1);
        }

        return (
            <a href={redirectClient(link, this.props.queryParams)} className={this.props.className}>
                {this.props.children}
            </a>
        );
    }
}

LinkClientV2.propTypes = {
    to: PropTypes.string,
    queryParams: PropTypes.any,
    className: PropTypes.string,
}

LinkClientV2.defaultProps = {
    queryParams: undefined,
    className: null
}