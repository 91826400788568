import React, { Component } from 'react';
import HoldingTime from './HoldingTime';
import { request, requestRelatorioXLSX } from '../../../common/request';
import { url } from '../../../common/helpers.js';
import MDSpinner from "react-md-spinner";
import Lote from './Lote';
import $ from 'jquery';
import { alertBox } from '../../../common/NForm';
import { hasRole } from '../../../helper/SecurityHelper';
import styles from './style.scss';
import utils from '../../../utils/Utils';
import { withTranslation } from 'react-i18next';
import { replace } from 'lodash';

class Detalhe extends Component {
  constructor() {
    super();
    this.state = {
      widthContainerLote: '',
      detalhes: {},
      loadingPage: true,
      notScrolledYet: true,
      showErrorMessage: true,
    };
    this.erroDetalhamento = this.erroDetalhamento.bind(this);
    this.relatorioApontamento = this.relatorioApontamento.bind(this);
    this.transformSucessoresToString = this.transformSucessoresToString.bind(this);
  }

  transformSucessoresToString() {
    let { detalhes } = this.state;

    if (!detalhes.numerosSucessores) return "";

    let sucessores = "";
    sucessores += " | ";
    if (detalhes.numerosSucessores.length == 1) {
      sucessores += "Sucessor: " + detalhes.numerosSucessores[0];
    }
    else {
      sucessores += "Sucessores: ";

      for (const sucessor of detalhes.numerosSucessores) {
        sucessores += sucessor + ", "
      }

      sucessores = sucessores.substring(0, sucessores.lastIndexOf(","));
    }
    return sucessores;
  }

  favoritarLote(num, sku, product, idBatch, favorite, corLeadTime) {

    let idUsuarioStorage = localStorage.getItem('id');

    if (favorite != undefined) {

      if ($('#iconFav').hasClass('starGold')) {
        this.props.action.execute('post', `${this.props.endpoints.FAVORITO}/${false}`, 'favorites', {
          id: favorite.id,
          lote: {
            id: idBatch
          },
          usuario: {
            id: idUsuarioStorage
          },
          corleadtime: corLeadTime

        });
        $('#iconFav').removeClass('starGold');
      } else {
        this.props.action.execute('post', `${this.props.endpoints.FAVORITO} /${false}`, 'favorites', {
          id: favorite.id,
          lote: {
            id: idBatch
          },
          usuario: {
            id: idUsuarioStorage
          },
          corleadtime: corLeadTime,
        });
        $('#iconFav').addClass('starGold');
      }
    } else {
      this.props.action.execute('post', `${this.props.endpoints.FAVORITO}/${true}`, 'favorites', {
        lote: {
          id: idBatch
        },
        usuario: {
          id: idUsuarioStorage
        },
        corleadtime: corLeadTime,
      }

      );
      $('#iconFav').addClass('starGold');
    }

    var findNewFavorites = setInterval(() => this.props.buscarLotesFavoritos(), 700);
    setInterval(() => clearInterval(findNewFavorites), 750);
  }

  componentWillMount() {
    request(url(this.props.endpoints.LOTES + '/detalhamento/' + this.props.loteId), {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
    })
      .then(response => {
        if (response.status === 204) {
          throw this.props.t("errors.cannotDetailCurrentBatch");
        } else
          if (response.status === 200) {
            return response.json()
          }
      })
      .then((data) => {
        this.setState({
          detalhes: data,
          loadingPage: false
        });

      }).catch((err) => {
        alertBox(err, this.props.t("labels.error"), "error");
        this.setState({
          showErrorMessage: false,
          loadingPage: false,
          detalhes: {}
        })
      });
  }

  componentDidMount() {
    this.setState({
      widthContainerLote: $('#containerPA').width()
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.notScrolledYet) {
      let scrollBox = document.getElementById('scrollBox');

      if (scrollBox != undefined) {
        document.getElementById('scrollBox').scrollTop = scrollBox.scrollHeight;
        this.setState({ notScrolledYet: false });
      }
    }
  }

  relatorioApontamento(loteId) {
    this.setState({ loadingPage: true });

    requestRelatorioXLSX(
      this.props.endpoints.RELATORIO_APONTAMENTO + `/${loteId}/true`,
      this.props.t("labels.notesReport")
    )
      .catch(erro => {
        alertBox(erro.mensagem, null, 'error');
      }).finally(() => {
        this.setState({ loadingPage: false });
      });
  }

  erroDetalhamento() {
    this.state.showErrorMessage ?
      alertBox(this.props.t("errors.cannotGenerateDetails"), this.props.t("messages.contactAdmin"), 'warning') : null
    this.props.handleModal();
    this.setState({ showErrorMessage: true })
  }

  render() {
    let { detalhes } = this.state;
    let { handleModal, loteId, store, corLeadTime } = this.props;

    let favorites = store.favorites;
    let favorito = favorites.filter(f => f.lote.id == loteId && f.status)[0];
    if (this.state.loadingPage) {
      return (
        <div className={'loading-wrapper'}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>
      );
    } else if (!!detalhes.etapas && detalhes.etapas?.length > 0) {
      let numeroSecundario = detalhes.lote.numero_secundario != null ? detalhes.lote.numero_secundario : '-';
      let referenciaSecundaria = utils.getReferenciaOrdemLote() != '' ? `${utils.getReferenciaOrdemLote()}: ${numeroSecundario}` : '';

      return (
        <div className={styles.container_detail}>
          <div className={styles.containerTotal}>
            <div className={styles.header}>
              <div className={styles.title_detail}>
                <span> {this.props.t("labels.batchDetail", { primaryReference: utils.getReferenciaLoteOrdem() })} </span>
                <br />
                {detalhes.lote.produto.nome} SKU: {detalhes.lote.produto.sku} {utils.getReferenciaLoteOrdem()}: {detalhes.lote.numero} {referenciaSecundaria} {this.transformSucessoresToString()}
              </div>
              <div className={styles.icons}>
                <div style={{ width: '1em', marginRight: '0.8em' }}>
                  <img style={{ cursor: 'pointer' }}
                    src='../../assets/icones-detalhamento-lote/relatorio.svg'
                    onClick={() => hasRole(['ROLE_RELATORIO_APONTAMENTOS_GERAR_RELATORIO', 'ROLE_RELATORIO_APONTAMENTOS_VISUALIZAR']) && this.relatorioApontamento(detalhes.lote.id)}
                    title={this.props.t('labels.notesReport')}
                    width={15}
                    height={20} />
                </div>
                {favorito ?
                  favorito ?
                    <i id='iconFav' className={'material-icons starGold'} style={{ marginRight: '1em', cursor: 'pointer' }}
                      onClick={() => this.favoritarLote(detalhes.lote.numero, detalhes.lote.produto.sku, detalhes.lote.produto.id, loteId, favorito, corLeadTime)} title={this.props.t('labels.favorite')}>star</i>
                    :
                    <i id='iconFav' className='material-icons' style={{ marginRight: '1em', cursor: 'pointer', color: '#c9c9c9' }}
                      onClick={() => this.favoritarLote(detalhes.lote.numero, detalhes.lote.produto.sku, detalhes.lote.produto.id, loteId, favorito, corLeadTime)} title={this.props.t('labels.favorite')}>star</i>
                  :
                  <i id='iconFav' className='material-icons' style={{ marginRight: '1em', cursor: 'pointer', color: '#c9c9c9' }}
                    onClick={() => this.favoritarLote(detalhes.lote.numero, detalhes.lote.produto.sku, detalhes.lote.produto.id, loteId, favorito, corLeadTime)} title={this.props.t('labels.favorite')}>star</i>
                }
                <div style={{ marginTop: -34 }} >
                  <i style={{ color: '#fff' }} className="fa fa-close cursor-pointer" onClick={handleModal} />
                </div>
              </div>
            </div>

            <hr style={{ width: '98%', border: '0.1px solid #DFDFDF' }} />
            <div id='scrollBox' style={{ maxHeight: '36em', overflow: 'auto' }}>
              <div className={styles.scrollContainer} id={'scrollContainer'}>
                <div className={styles.containerEtapas} id={'containerEtapas'}>
                  <Lote detalhes={detalhes} />
                </div>
              </div>

              {
                detalhes.holding_time_computados.length !== 0 ?
                  <div className={styles.containerHoldingTime}>
                    <HoldingTime widthLote={this.state.widthContainerLote} detalhes={detalhes.holding_time_computados} />
                  </div>
                  :
                  null
              }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        this.erroDetalhamento()
      )
    }
  }
}

export default withTranslation("flowView")(Detalhe);