import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styles from './route.scss';
import ProductStage from './ProductStage';

@observer
export default class ProductStages extends Component {
  render() {
    let {product, session, stages, handleModal} = this.props;
    return (
      <div ref='metric' className={styles.product}>
        {
          stages.map(stage => {
            let routes = product.routes.filter(route => route.fk_id_stages == stage.id && route.fk_id_sessions == session && route.TimeMachine.dataFim == null).map(route => {
              if(route.com_equipamento) {
                route.equipment = stage.equipments.filter(eq => eq.id == route.fk_id_equipments)[0]?stage.equipments.filter(eq => eq.id == route.fk_id_equipments)[0].name:this.props.t("productStage.invalidEquipment");
              }
              return route;
            });
            return <ProductStage t={this.props.t} key={stage.id} routes={routes} handleModal={handleModal} />
          })
        }
      </div>
    );
  }
}