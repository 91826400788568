import React from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import ModalForm from '../../common/ModalForm';
import FieldsList from './FieldsList';
import { requestAll } from '../../common/request';
import MDSpinner from "react-md-spinner";
import { hasRole } from '../../helper/SecurityHelper';
import { respostaAreaProdutivas, requisicaoAreaProdutivas } from '../../common/mappers';
import { withTranslation } from 'react-i18next';

@observer
class FieldsPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      typeModal: '',
      loadingPage: true,
    }
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.buscarAreaProdutiva = this.buscarAreaProdutiva.bind(this);
  }

  componentDidMount() {
    this.buscarAreaProdutiva();
  }

  buscarAreaProdutiva() {
    requestAll([{
      url: this.endpoints.AREA_PRODUTIVA,
      modelo: 'fields',
      modificador: respostaAreaProdutivas
    }], this);
  }

  handleModal(f, t) {
    this.setState({
      modal: !this.state.modal,
      field: f,
      typeModal: t
    });
  }

  contentModal() {
    let fields = [
      { name: 'name', title: this.props.t('name'), label: this.props.t('name'), type: 'text', required: true }
    ];

    return <ModalForm url={this.endpoints.AREA_PRODUTIVA}
      model='fields'
      title={!this.state.field ? this.props.t('areaCreate') : this.props.t('areaUpdate')}
      action={this.action}
      endpoints={this.endpoints}
      utils={this.utils}
      field={this.state.field}
      fields={fields}
      handleModal={this.handleModal}
      showAlert={this.showAlert}
      aposSalvar={this.buscarAreaProdutiva}
      genero='F'
      disabledSave={((!hasRole('ROLE_AREAS_PRODUTIVAS_CRIAR') && this.state.typeModal === 'add') || (!hasRole('ROLE_AREAS_PRODUTIVAS_ATUALIZAR') && this.state.typeModal == 'up'))}
      modificador={requisicaoAreaProdutivas}
      tituloAlerta={this.props.t('productiveArea')}
      alertaMsg={this.props.t('messages.informedProductiveArea')} />;
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={{ width: 350 }} contentModal={this.contentModal}>
          <FieldsList ref='list' store={this.store} handleModal={this.handleModal} t={this.props.t} />
        </Container>
      );
    }
  }
}

export default withTranslation('fields')(FieldsPage);