import React, { Component } from 'react';
import Button from '../../common/Button';
import BatchCommentsList from './BatchCommentsList';
import { withTranslation } from 'react-i18next';
import { alertBox } from '../../common/NForm';
import { request } from '../../common/request';
import utils from '../../utils/Utils';
import { uuidv4 } from '../../common/helpers';
import moment from 'moment';
moment.locale('pt-br');
import lbStyles from './ListBatches.scss';
import './batchComments.scss';

class BatchComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comentario: null,
            cor: "#143055",
            lote: null,
            openComment: false,
            userName: null
        }

        this.comentariosLote = this.comentariosLote.bind(this);
        this.salvarComentarios = this.salvarComentarios.bind(this);
        this.removerComentarios = this.removerComentarios.bind(this);
        this.salvarCor = this.salvarCor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setOpenComment = this.setOpenComment.bind(this);
    }

    comentariosLote(obj) {
        this.props.activeModal('batchComments', obj);
    }

    componentWillMount() {
        this.setState({
            lote: this.props.lote,
            userName: localStorage.getItem('nome')
        });
    }

    modifyBatch(batch) {
        this.props.handleComments(batch);
    }

    salvarComentarios() {
        let { lote, comentario, userName } = this.state;
        let urlRequest = `${this.props.endpoints.COMENTARIO_LOTE}/${lote.fk_id_batches}`;
        let listaComentarios = {
            Cor: lote.comentarios !== undefined && lote.comentarios.length > 0 ? lote.comentarios[0].color : "#143055",
            Comentarios: []
        };

        if (comentario === null || comentario === '') {
            alertBox(this.props.t('messages.emptyComment'), '', 'warning');
            return;
        }

        if (lote.comentarios && lote.comentarios.length > 0) {
            lote.comentarios.map(m => {
                listaComentarios.Comentarios.push({ uuid: m.uuid, usuario: m.user, data: m.date, texto: m.text });
            });
        }

        let comentarioNew = {
            uuid: uuidv4(),
            usuario: userName,
            data: moment(),
            texto: comentario
        };

        listaComentarios.Comentarios.push(comentarioNew);

        let comentariosJson = JSON.stringify(listaComentarios);

        request(urlRequest, {
            method: 'put',
            body: comentariosJson
        }).then(response => {
            if (response.ok) {
                if (lote.comentarios === undefined) {
                    lote.comentarios = [];
                }

                lote.comentarios.push({
                    uuid: comentarioNew.uuid,
                    user: comentarioNew.usuario,
                    date: comentarioNew.data,
                    text: comentarioNew.texto,
                    color: listaComentarios.Cor
                });

                lote.comentarios = lote.comentarios.sort((a, b) => (moment(a.date) > moment(b.date)) ? -1 : 1);

                this.setState({
                    lote: lote,
                    comentario: '',
                    cor: listaComentarios.Cor,
                    openComment: false
                });

                this.modifyBatch(lote);

                alertBox(this.props.t('messages.commentAdded'), '', 'success');
            } else {
                this.props.handleModal();
            }
        });
    }

    removerComentarios(uuid) {
        let { lote, cor } = this.state;
        let urlRequest = `${this.props.endpoints.COMENTARIO_LOTE}/${lote.fk_id_batches}`;
        let listaComentarios = { Cor: cor, Comentarios: [] };

        if (lote.comentarios && lote.comentarios.length > 0) {
            lote.comentarios.map(m => {
                if (m.uuid !== uuid) {
                    listaComentarios.Comentarios.push({ uuid: m.uuid, usuario: m.user, data: m.date, texto: m.text });
                }
            });
        }

        let comentariosJson = JSON.stringify(listaComentarios);

        request(urlRequest, {
            method: 'put',
            body: comentariosJson
        })
            .then(response => {
                if (response.ok) {
                    lote.comentarios = lote.comentarios
                        .filter(f => f.uuid !== uuid)
                        .sort((a, b) => (moment(a.date) > moment(b.date)) ? -1 : 1);

                    if (lote.comentarios.length === 0) {
                        delete lote.comentarios;

                        this.setState({
                            cor: "#143055"
                        });
                    }

                    this.setState({
                        lote: lote,
                        comentario: '',
                        openComment: false
                    });

                    this.modifyBatch(lote);

                    alertBox(this.props.t('messages.commentRemoved'), '', 'success');
                } else {
                    this.props.handleModal();
                }
            });
    }

    salvarCor(cor) {
        let { lote } = this.state;
        let urlRequest = `${this.props.endpoints.COMENTARIO_LOTE}/${lote.fk_id_batches}`;
        let corHex = (cor === "transparent" ? "#143055" : cor);
        let listaComentarios = { Cor: corHex, Comentarios: [] };

        if (lote.comentarios && lote.comentarios.length > 0) {
            lote.comentarios.map(m => {
                listaComentarios.Comentarios.push({ uuid: m.uuid, usuario: m.user, data: m.date, texto: m.text });
            });
        }

        let comentariosJson = JSON.stringify(listaComentarios);

        request(urlRequest, {
            method: 'put',
            body: comentariosJson
        }).then(response => {
            if (response.ok) {
                lote.comentarios = lote.comentarios
                    .sort((a, b) => (moment(a.date) > moment(b.date)) ? -1 : 1)
                    .map(obj => ({ ...obj, color: corHex }));

                this.setState({
                    lote: lote,
                    comentario: '',
                    cor: corHex,
                    openComment: false
                });

                this.modifyBatch(lote);

                alertBox(this.props.t('messages.colorUpdated'), '', 'success');
            } else {
                this.props.handleModal();
            }
        });
    }

    handleChange(event) {
        this.setState({
            comentario: event.target.value
        });
    }

    setOpenComment(isOpen) {
        this.setState({
            openComment: isOpen,
            comentario: ''
        });
    }

    getInitials = (name) => {
        if (!name) {
            return '';
        }

        return name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    };

    render() {
        return (
            <div className={lbStyles.list_modal} style={{ paddingBottom: '5px', maxWidth: '550px', borderRadius: '10px' }}>
                <div>
                    <div>
                        <h3 style={{ fontWeight: 'bold' }}>
                            {this.props.t("labels.commentsOfBatch", { primaryReference: utils.getReferenciaDoLoteDaOrdem(), number: this.state.lote.num })}
                        </h3>
                        <Button button={{ type: 'buttonIcon', icon: 'fa fa-close', text: '', onClick: this.props.handleModal }} />
                    </div>
                </div>
                {
                    !this.state.openComment &&
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div className="chat-avatar-circle medium">
                            <span className="initials">{this.getInitials(localStorage.getItem('nome'))}</span>
                        </div>
                        <div className="chat-add-comment" onClick={() => this.setOpenComment(true)}>
                            {this.props.t('labels.addComment')}
                        </div>
                    </div>
                }
                {
                    this.state.openComment &&
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="chat-avatar-circle medium">
                            <span className="initials">{this.getInitials(localStorage.getItem('nome'))}</span>
                        </div>
                        <div className="chat-add-comment-open">
                            <textarea
                                autoFocus
                                className='chat-textarea'
                                name="comentario"
                                onChange={(event) => this.handleChange(event)}
                                value={this.state.comentario}>
                            </textarea>
                            <br />
                            <button
                                className='chat-btn save'
                                onClick={this.salvarComentarios}>{this.props.t('actions.publish')}</button>
                            <button
                                className='chat-btn cancel'
                                onClick={() => this.setOpenComment(false)}>{this.props.t('actions.cancel')}</button>
                        </div>
                    </div>
                }
                <br />
                <BatchCommentsList
                    lote={this.state.lote}
                    getInitials={this.getInitials}
                    userName={this.state.userName}
                    removerComentarios={this.removerComentarios}
                    salvarCor={this.salvarCor} />
            </div>
        );
    }
}

export default withTranslation("flowView")(BatchComments);