import React from 'react';
import Container from '../../common/Container';
import { uuidv4 } from '../../common/helpers';
import {
  Avatar, IconMenu, MenuItem,
  AutoComplete, Divider, Checkbox,
  RaisedButton, Card, CardHeader,
  CardText
} from 'material-ui';
import styles from './notes.scss';
import TextField from 'material-ui/TextField';
import { NDatePickerInput, NButton, NForm, NFormTitle, NContainer, NButtonGroup, NInputTime, NInputNumber, NLabel, alertBox } from '../../common/NForm';
import moment from 'moment';
import $ from 'jquery';
import update from 'immutability-helper';
import Notifier from '../../utils/Notifier';
import { withRouter } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { request, requestRelatorio, requestAll } from '../../common/request';
import stylesNform from '../../common/nform.scss';
import DatePicker from 'react-datepicker';
import { ListNotes } from './ListNotes';
import Util from '../../utils/Utils';
import _ from 'lodash';
import {
  respostaUnidadeIndustrial,
  respostaEquipamentos,
  respostaClassesEventos,
  respostaUltimosApontamentos
} from '../../common/mappers';
import MDSpinner from 'react-md-spinner';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

function adicionaSeCampoNaoNull(arr, campo, valor) {
  if (campo) {
    arr.push([campo, valor]);
  }
}

class NotesRenewExport extends Container {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalType: '',
      apontamentos: [],
      equipamentos: '',
      opcEquipamentos: [],
      equipamentoInformado: '',

      eventos: '',
      opcEventos: [],
      insEvento: '',
      apontEvento: '',
      labelEvento: this.props.t("event"),
      erroEVENTO: '',
      searchTextEvento: '',
      textEvento: '',

      dataInicialInformado: '',
      dataFinalInformado: '',
      insDataInicial: '',
      insHoraInicial: ' ',
      insDataFinal: '',
      insHoraFinal: ' ',
      insLote: '',
      insProduzido: '',
      insRejeitado: '',
      campo1: '',
      campo2: '',
      campo3: '',
      campo4: '',
      campoText1: '',
      campoText2: '',
      campoText3: '',
      campoText4: '',
      classeId: '',
      fimDeLote: {},
      labelLote: this.utils.getReferenciaLoteOrdem(),
      apontLote: '',
      erroEQUIPAMENTO: '',
      erroDATAINICIAL: '',
      erroHORAINICIAL: '',
      erroDATAFINAL: '',
      erroHORAFINAL: '',
      erroLOTE: '',
      itemApont: '',
      editObs1: '',
      editObs2: '',
      editObs3: '',
      editObs4: '',
      validar: false,
      erroMSG: '',
      disableInsert: false,
      loadingPage: true,
      camposPersonalizado: [],
      instant: false,
      equip_isBatch: true,
      ultimosApontamentos: [],
      opcoesUnidadeIndustrial: [],
      equipamentoUnidade: [],
      equipamentosDaUnidade: [],
      lotesEquipamento: [],
      unidadeIndustrialSelected: '',
      equipamento: '',
      lote: '',
      periodoReport: 7,
      dataInicioReport: '',
      dataFimReport: '',
      textEquipamento: '',
      equipment: '',
      normaisInconsistentes: true,
      rejeitados: false,
      botaoLoading: false,
      numLote: '',
      numClasse: {},
      avisoFimDeLote: [],
      modelo: null,
      tipoProducao: ''
    };

    this.editarObs = this.editarObs.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.clearState = this.clearState.bind(this);
    this.handleEvento = this.handleEvento.bind(this);
    this.handleLote = this.handleLote.bind(this);
    this.inserirApontamento = this.inserirApontamento.bind(this);
    this.validarApontamento = this.validarApontamento.bind(this);
    this.encerrarApontamento = this.encerrarApontamento.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.redirectFlowView = this.redirectFlowView.bind(this);
    this.redirectNotes = this.redirectNotes.bind(this);
    this.autoCompleteSet = this.autoCompleteSet.bind(this);
    this.handleEditCustom = this.handleEditCustom.bind(this);

    //funçoes integradas
    this.menuIconClicked = this.menuIconClicked.bind(this);
    this.handleEquipment = this.handleEquipment.bind(this);
    this._valiDateStart = this._valiDateStart.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this._valiDateEnd = this._valiDateEnd.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this._validateHourEnd = this._validateHourEnd.bind(this);
    this.remove = this.remove.bind(this);
    this.modalManut = this.modalManut.bind(this);
    this.modalApont = this.modalApont.bind(this);
    this.gerarRelatorioApontamento = this.gerarRelatorioApontamento.bind(this);
    this.radioButton = this.radioButton.bind(this);
    this.carregarCamposPersonalizados = this.carregarCamposPersonalizados.bind(this);
  }

  componentWillMount() {
    request(this.endpoints.EQUIPAMENTOS, {
      method: 'get'
    }, true)
      .then(function (response) {
        if (response.status == 200) return response.json();
      })
      .then((data) => {
        let opcEquipamentos = data.map((equipmento) => {
          return { value: equipmento.id, text: equipmento.tag + ' - ' + equipmento.nome };
        });

        this.setState({
          equipamentos: data,
          opcEquipamentos: opcEquipamentos
        });
      });

    request(`${this.endpoints.EVENTO}/classe`, {
      method: 'get'
    }, true)
      .then(function (response) {
        if (response.status == 200) return response.json();
      })
      .then((data) => {
        let opcEventos = data.map((evento) => {
          return { value: evento.codigo, text: evento.codigo + ' - ' + evento.nome };
        });

        opcEventos.sort((a, b) => a.value - b.value);

        this.setState({
          eventos: data,
          opcEventos: opcEventos
        });
      });

    requestAll([
      {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial,
        acao: true
      },
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      },
      {
        url: `${this.endpoints.CLASSES}/eventos`,
        modelo: 'type_events',
        modificador: respostaClassesEventos
      },
      // {
      //   url: `${this.endpoints.EVENTO}/classe`,
      //   modelo: 'eventos',
      //   destino: 'state',
      //   acao: true
      // },
      {
        url: `${this.endpoints.CONFIGURACAO_PARAMETROS + '?chave=AlertaFimDeLote'}`,
        modelo: 'avisoFimDeLote',
        destino: 'state',
        acao: true
      }
    ], this);
  }

  /* Funçoes integradas */
  menuIconClicked(e, value) {
    switch (value) {
      case 0:
        this.handleModal('relat_apont');
        break;
      case 1:
        this.handleModal('manut_apont');
        if (this.state.equipamentoInformado) {
          this.setState({
            textEquipamento: this.state.equipamentoInformado.nome,
            equipment: this.state.equipamentoInformado.id
          });
        }
        break;
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const { opcEventos } = this.state;
      const currentText = event.target.value;

      const filteredOptions = opcEventos.filter(option =>
        option.text.toLowerCase().includes(currentText.toLowerCase())
      );

      if (filteredOptions.length > 0) {
        const selectedString = filteredOptions[0].text;

        this.handleEvento(selectedString, opcEventos);

        const dataInicialElement = document.getElementById('dataInicial');

        if (dataInicialElement) {
          dataInicialElement.focus();
        }
      }
    }
  };

  handleEquipment(selectedString, collection) {
    let selectedItem = collection.find(item => item.text == selectedString);
    let equipamentId = selectedItem && selectedItem.value;
    let equipment = this.state.equipamentos.find(e => e.id == equipamentId);

    /* SELECT ULTIMOS 5 APONTAMENTOS */
    if (equipment !== undefined) {
      this.carregarCamposPersonalizados(equipment, this.state.apontEvento, 'camposPersonalizado');

      request(`${this.endpoints.APONTAMENTOS_EQUIPAMENTO}/${equipment.id}?limite=5&inicio=0&campo=DataInicio&ordem=DESC`, {
        method: 'get'
      }).then(response => {
        if (response.status == 200) return response.json();
      }).then(data => {
        this.setState({
          ultimosApontamentos: respostaUltimosApontamentos(data),
        });
      });

      this.setState({
        erroEQUIPAMENTO: '',
        equipamentoInformado: equipment,
        labelEquipment: equipment.name,
        equip_isBatch: equipment.batch,
        equipamentoText: selectedString
      });
    }
  };

  handleEvento(selectedString, collection) {
    this.setState({ searchTextEvento: selectedString, textEvento: '' });

    let selectedItem = collection.find(item => item.text == selectedString);
    let eventCode = selectedItem && selectedItem.value;
    let event = this.state.eventos.find(e => e.codigo == eventCode);

    this.carregarCamposPersonalizados(this.state.equipamentoInformado, event, 'camposPersonalizado');

    if (event != undefined) {
      this.setState({
        labelEvento: event.nome,
        apontEvento: event,
        numClasse: event.classe,
        erroEVENTO: '',
        eventoText: selectedString
      });
    } else {
      this.setState({
        labelEvento: this.props.t("event"),
        apontEvento: ''
      });
    }
  };

  carregarCamposPersonalizados(equipamento, evento, modelo, cb) {
    if (equipamento && evento) {
      request(`${this.endpoints.CAMPOS_PERSONALIZADOS}/por-equipamento-evento`, {
        method: 'POST',
        body: JSON.stringify({ item1: { id: equipamento.id }, item2: { id: evento.id } }),
      }).then(response => {
        if (response.status == 200) return response.json();
      }).then(data => {
        this.setState({
          [modelo]: data,
        }, () => {
          if (cb) { cb(); }
        });
      });
    }
  }

  //verifica se a data tem 10 digitos para que ele possa digitar a data normalmente
  _valiDateStart(date) {
    //se a quantidade de caracteres digitados for diferente de 10
    if (date.length !== 10) {
      $('#dataFinal').val('');
      $('#horaInicial').val('');

      this.setState({
        insDataInicial: ''
      });

      alertBox(this.props.t("messages.invalidStartDate"), this.props.t("startDate"), 'warning');
    }
    //se a quantidade de caracteres for igual a 10
    else if (date.length === 10) {

      //data digitada
      var dateMoment = moment(date, 'L');

      //data de hoje
      var dateNow = moment();

      //data selecionada é menor q a data atual
      var dataMenor = moment(dateMoment).isAfter(dateNow);

      //verifica se a dateMoment selecionada é valida
      if (!dateMoment.isValid()) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');

        this.setState({
          insDataInicial: ''
        });

        alertBox(this.props.t("messages.invalidStartDate"), this.props.t("startDate"), 'warning');
      }

      //verifica se a data selecionada é menor que a data de hoje
      if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');

        this.setState({
          insDataInicial: ''
        });

        alertBox(this.props.t("messages.futureStartDate"), this.props.t("startDate"), 'warning');
      }
    }
  }

  handleStart(date) {
    $('#dataFinal').val('');
    $('#horaFinal').val('');
    this.setState({
      insDataInicial: date,
      insDataFinal: '',
      insHoraFinal: ' '
    });
  }

  _valiDateEnd(date) {
    if (date.length !== 10) {
      $('#dataFinal').val();
      $('#horaFinal').val('');

      this.setState({
        insDataFinal: ''
      });

      alertBox(this.props.t("messages.invalidEndDateHour"), this.props.t("messages.invalidEndDate"), 'warning');
    } else if (date.length === 10) {
      // Data selecionada pelo usuario
      var dateMoment = moment(date, 'L');

      // Moment de hoje
      var dateNow = moment();

      //data selecionada é menor q a data atual
      var dataMenor = moment(dateMoment).isAfter(dateNow);

      //Data de incio selecionada pelo usuario formatada dia/mes/ano
      var dateStart = moment(this.state.insDataInicial).format('L');

      // gerar moment da data selecionada pelo usuario concatenada com o horario
      var dataInicio = moment(dateStart + ' ' + this.state.insHoraInicial, 'L LTS');

      // Data final selecionada pelo usuario concatenada ao horario
      var endDate = moment(date + ' ' + this.state.insHoraInicial, 'L LTS');

      if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaFinal').val('');

        this.setState({
          insDataFinal: '',
          insHoraFinal: ' '
        });

        alertBox(this.props.t("messages.futureEndDateHour"), this.props.t("messages.endDate"), 'warning');
      } else if (endDate.isBefore(dataInicio)) {
        $('#dataFinal').val('');
        $('#horaFinal').val('');

        this.setState({
          insDataFinal: '',
          insHoraFinal: ' '
        });

        alertBox(this.props.t("messages.endDateLessStartDate"), this.props.t("messages.invalidEndDateHour"), 'warning');
      }
    }
  }

  handleTime(end) {
    if (this.state.insDataInicial === '') {
      $('#dataFinal').val('');
      $('#horaFinal').val('');
      $('#dataInicial').focus();
      this.setState({
        insDataFinal: ''
      });

      alertBox(this.props.t("messages.requiredStartDate"), this.props.t("startDate"), 'warning');
    }

    if (end === 'end') this.props.validation('events', this.state.equipment, this.state.product);
    else this.setState({ insDataFinal: end });
  }

  _validateHourEnd(endTime) {
    //Data de incio selecionada pelo usuario formatada dia/mes/ano
    var dateStart = moment(this.state.insDataInicial).format('L');

    // gerar moment da data selecionada pelo usuario concatenada com o horario
    var dataInicio = moment(dateStart + ' ' + this.state.insHoraInicial, 'L LTS');

    //Data de fim selecionada pelo usuario formatada dia/mes/ano
    var dateEnd = moment(this.state.insDataFinal).format('L');

    // gerar moment da data selecionada pelo usuario concatenada com o horario
    var dataFim = moment(dateEnd + ' ' + endTime, 'L LTS');

    if (dataFim.isBefore(dataInicio)) {
      this.setState({
        insHoraFinal: ' '
      });

      if ($('#horaFinal').val() != '') {
        alertBox(this.props.t("messages.endDateLessStartDate"), this.props.t("messages.invalidEndDateHour"), 'warning');
        $('#horaFinal').val('');
        $('#horaFinal').focus();
      }
    }
  }

  remove(idx) {
    if (this.state.apontamentos.length > 1 && idx != 0) {
      this.setState({
        insDataInicial: moment(this.state.apontamentos[idx - 1].fim),
        insHoraInicial: moment(this.state.apontamentos[idx - 1].fim).format('HH:mm:ss')
      });
    } else {
      this.setState({
        insDataInicial: '',
        insHoraInicial: 0
      });
    }

    this.setState({
      apontamentos: this.state.apontamentos.slice(0, idx)
    });
  }

  setUnidadeIndustrialComum(nomeUnidade, allUnd) {
    let obj = allUnd.find(i => i.text === nomeUnidade);

    if (obj == '' || obj == undefined) {
      this.state.unidadeIndustrialSelected = '';
    } else {
      this.setState({
        unidadeIndustrialSelected: '',
        equipamento: '',
      });

      let unidadeIndustrialSelected = this.store.sessions.find(s => s.id === obj.value);
      let etapaSelected = unidadeIndustrialSelected.stages;
      let lotes = [];
      let equipamentos = [];

      for (let i = 0; i < etapaSelected.length; i++) {
        if (etapaSelected[i] !== undefined) {
          for (let j = 0; j < etapaSelected[i].equipments.length; j++) {
            if (etapaSelected[i].equipments[j] !== undefined) {
              equipamentos.push({ text: etapaSelected[i].equipments[j].tag + ' - ' + etapaSelected[i].equipments[j].name, value: etapaSelected[i].equipments[j].id });
            }
          }
        }
      }

      request(this.endpoints.LOTES + '/unidade-industrial/' + unidadeIndustrialSelected.id, {
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            for (let i = 0; i < data.length; i++) {
              lotes.push({ numero: data[i].numero, id: data[i].id, nomeProduto: data[i].produto.nome });
            }
            this.setState({
              lotesEquipamento: lotes
            });
          } else {
            this.setState({
              inconsistentesPeriodo: 0
            });
          }
        });

      this.setState({
        unidadeIndustrialSelected: obj,
        equipamentosDaUnidade: equipamentos,
        errors: {
          selecionar_unidade_industrial: undefined
        }
      });
    }
  }

  setEquipamento(nomeEquipamento, allEqp) {
    let obj = allEqp.find(i => i.text === nomeEquipamento);
    let lotes = [];
    if (obj !== undefined) {
      this.setState({
        equipamento: obj
      });
    } else {
      this.setState({
        equipamento: ''
      });
    }
  }

  setLote(e) {
    let obj = this.state.lotesEquipamento.find(l => l.numero == e.target.value);
    if (obj !== undefined) {
      this.setState({
        lote: obj
      });
    } else {
      this.setState({
        lote: ''
      });
    }
  }

  radioButton(event) {
    var valor = $(event.target).data('value');

    this.setState({
      periodoReport: valor
    });
  }

  gerarRelatorioApontamento() {
    let { unidadeIndustrialSelected, equipamento, lote, periodoReport, dataInicioReport, dataFimReport, normaisInconsistentes, rejeitados } = this.state;
    var error = false, reqRejeitado = '';

    if (unidadeIndustrialSelected === undefined) {
      this.setState({
        erroUnidadeIndustrial: true
      });
      error = true;
    } else {
      this.setState({
        erroUnidadeIndustrial: false
      });
    }

    if ($('#equipamento').val() !== '' && equipamento === '') {
      alertBox(this.props.t("messages.equipmentCurrentSession"), this.props.t("messages.invalidEquipment"), 'warning');
      error = true;
    }

    if ($('#lote').val() !== '' && lote === '') {
      alertBox(this.props.t("messages.batchCurrentSession", { batch: this.utils.getReferenciaUmLoteUmaOrdem() }), this.utils.getReferenciaLoteInvalidoOrdemInvalida(), 'warning');
      error = true;
    }

    if (periodoReport === 'Personalizar') {
      if (dataInicioReport) {
        dataInicioReport = dataInicioReport.format('DD-MM-YYYY');
      }

      if (dataFimReport) {
        dataFimReport = dataFimReport.format('DD-MM-YYYY');
      }

      if (dataInicioReport === '' || dataFimReport === '') {
        this.setState({
          erroDatas: true
        });
        error = true;
      }

      if (dataInicioReport === '' && dataFimReport !== '') {
        this.setState({
          erroDataInicial: true
        });
        error = true;
      }

      if (dataInicioReport !== '' && dataFimReport === '') {
        this.setState({
          erroDataFinal: true
        });
        error = true;
      }

      if (dataInicioReport !== '' && dataFimReport !== '') {
        if (moment(dataFimReport).isBefore(dataInicioReport)) {
          this.setState({
            erroDatas: false,
            erroDataFinal: true,
            mensagemErroData: this.props.t("messages.endDateLessStartDate")
          });
          error = true;
        } else {
          this.setState({
            erroDatas: false,
            erroDataInicial: false,
            erroDataFinal: false,
            mensagemErroData: ''
          });
        }
      }
    }

    if (lote === '' && lote === undefined) {
      if (periodo === '' || periodo === undefined) {
        this.setState({
          erroPeriodo: true
        });
        error = true;
      } else {
        this.setState({
          erroPeriodo: false
        });
      }
    }

    if (normaisInconsistentes === false && rejeitados === false) {
      this.setState({
        erroInformacoes: true
      });
      error = true;
    } else {
      this.setState({
        erroInformacoes: false
      });
    }

    let url = `${this.endpoints.RELATORIO_APONTAMENTO}`;

    if (unidadeIndustrialSelected !== undefined) {
      url += '?unidadeIndustrialId=' + unidadeIndustrialSelected.value; //Somente unidade selecionada
    }

    if ((unidadeIndustrialSelected !== '') && (equipamento !== '' && equipamento !== undefined)) {
      url += '&equipamentoId=' + equipamento.value;
    }

    if ((unidadeIndustrialSelected !== '') && (lote !== '' && lote !== undefined)) {
      url += '&loteId=' + lote.id;
    }

    if (periodoReport === 'Personalizar') {
      if (dataInicioReport !== null && dataFimReport !== null) {
        url += '&dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport;
      }
    } else {
      dataInicioReport = moment(moment()).add(-periodoReport, 'days').format('DD-MM-YYYY');
      dataFimReport = moment().format('DD-MM-YYYY');

      url += '&dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport;
    }

    if ((normaisInconsistentes === true) && (rejeitados === true)) {
      reqRejeitado = url + '&normal=' + !rejeitados;
    }

    if ((unidadeIndustrialSelected !== '') && (normaisInconsistentes === true)) {
      url += '&normal=' + normaisInconsistentes;
    } else if ((unidadeIndustrialSelected !== '') && (rejeitados === true)) {
      url += '&normal=' + !rejeitados;
    }

    if (error === false) {
      if (normaisInconsistentes === true) {
        requestRelatorio(url, this.props.t("reports.consistentNotes"));
      } else if (rejeitados === true) {
        requestRelatorio(url, this.props.t("reports.rejectedNotes"));
      }

      if (reqRejeitado !== '') {
        requestRelatorio(reqRejeitado, this.props.t("reports.rejectedNotes"));
      }

      this.setState({
        modal: false,
        erroUnidadeIndustrial: false,
        unidadeIndustrialSelected: undefined,
        equipamentosDaUnidade: [],
        equipamento: '',
        lote: '',
        periodoReport: 7,
        dataInicioReport: '',
        dataFimReport: '',
        normaisInconsistentes: true,
        rejeitados: false
      });
    }
  }

  modalApont() {
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);

    for (let i = 0; i < sessionOpts.length; i++) {
      if (this.state.opcoesUnidadeIndustrial[i] == undefined) {
        this.state.opcoesUnidadeIndustrial.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
      }
    }

    return (
      <div style={{ width: '490.94px' }}>
        <NForm width={{}}>
          <NFormTitle>{this.props.t("reports.notesReport")}</NFormTitle>
          <div style={{ height: '5em', display: 'flex', paddingTop: '-.5em', flexFlow: 'column', justifyContent: 'space-between' }}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-14px', width: '430px' }}
              ref='unidadeIndustrial'
              fullWidth={true}
              underlineStyle={this.state.erroUnidadeIndustrial ? { borderColor: 'red' } : ''}
              underlineFocusStyle={{ borderColor: '#000000' }}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("session")}
              title={this.props.t("session")}
              onUpdateInput={this.setUnidadeIndustrialComum.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              dataSource={this.state.opcoesUnidadeIndustrial}
              openOnFocus={true}
              id='unidadeIndustrial' />
            {this.state.erroUnidadeIndustrial ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '-10px' }} ref="validate">{this.props.t("validations.requiredField")}</div> : ''}
          </div>
          <AutoComplete
            onUpdateInput={this.setEquipamento}
            id={'equipamento'}
            fullWidth={true}
            textFieldStyle={{ marginTop: '-25px', width: '430px' }}
            filter={AutoComplete.fuzzyFilter}
            errorText={this.state.erroEquip}
            underlineFocusStyle={{ borderColor: '#000000' }}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("equipment")}
            title={this.props.t("equipment")}
            dataSource={this.state.equipamentosDaUnidade}
            openOnFocus={true} />
          <TextField
            fullWidth={true}
            underlineStyle={{ marginTop: '-5px', width: '430px' }}
            onKeyUp={(value) => this.setLote(value)} id={'lote'}
            underlineFocusStyle={{ borderColor: '#000000' }}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.state.lote !== '' ? this.state.lote.nomeProduto : `${this.utils.getReferenciaLoteOrdem()}`} title={this.utils.getReferenciaLoteOrdem()}
          />
          <h3 style={{ paddingTop: '1.5em', paddingBottom: '1.5em', fontSize: '.8em' }}> {this.props.t("selectInterval")} <span style={{ fontSize: '.7em' }} > ({this.props.t("days")}) </span> </h3>
          <div className="control-group" style={{ justifyContent: 'center' }}>
            <div style={{ textAlign: 'center' }}>
              <label>7</label>
              <label className="control control-radio">
                <input checked={this.state.periodoReport == 7} type="radio" id="radioSete" name="periodo" />
                <div data-value='7' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label>15</label>
              <label className="control control-radio">
                <input checked={this.state.periodoReport == 15} type="radio" name="periodo" />
                <div data-value='15' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label>30</label>
              <label className="control control-radio">
                <input checked={this.state.periodoReport == 30} type="radio" name="periodo" />
                <div data-value='30' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label>90</label>
              <label className="control control-radio">
                <input checked={this.state.periodoReport == 90} type="radio" name="periodo" />
                <div data-value='90' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label style={{ marginLeft: '-2em' }}>{this.props.t("customize}")}</label>
              <label className="control control-radio">
                <input checked={this.state.periodoReport == 'Personalizar'} type="radio" name="periodo" />
                <div data-value='Personalizar' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
          </div>
          {
            this.state.periodoReport == 'Personalizar'
              ?
              <div>
                <div style={{ display: 'flex', marginTop: '-1em', marginBottom: '1em' }}>
                  <div>
                    <NDatePickerInput
                      id="start_date"
                      label={this.props.t("start")}
                      value={this.state.dataInicioReport}
                      selected={moment(this.state.dataInicioReport)}
                      style={{ marginRight: '2em' }}
                      maxDate={moment()}
                      onChange={date => this.setState({ dataInicioReport: date, dataFimReport: '', erroDatas: false, erroDataInicial: false })}
                      onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: '', erroDatas: false, erroDataInicial: false })} />
                  </div>
                  <NDatePickerInput
                    id="end_date"
                    value={this.state.dataFimReport}
                    selected={moment(this.state.dataFimReport)}
                    minDate={this.state.dataInicioReport}
                    maxDate={moment()}
                    label={this.props.t("end")}
                    onChange={date => this.setState({ dataFimReport: date, erroDatas: false, erroDataFinal: false })}
                    onChangeRaw={(_, date) => this.setState({ dataFimReport: date, erroDatas: false, erroDataFinal: false })} />
                </div>
                <div style={{ display: 'flex', marginTop: '-7px' }}>
                  {this.state.erroDatas || this.state.erroDataInicial ? <div style={{ color: 'red', fontSize: '12px' }} ref="validate">{this.state.mensagemErroData}</div> : ''}
                  {this.state.erroDatas || this.state.erroDataFinal ? <div style={{ color: 'red', fontSize: '12px', marginLeft: '100px' }} ref="validate">{this.state.mensagemErroData}</div> : ''}
                </div>
              </div>
              :
              null
          }
          <h3 style={{ paddingTop: '1.5em', paddingBottom: '1.5em', fontSize: '.8em', justifyContent: 'space-between' }}>{this.props.t("reports.reportInformation")}</h3>
          <div>
            <Checkbox
              label={this.props.t("reports.consistentNotes")}
              checked={this.state.normaisInconsistentes}
              onCheck={((event, value) => { this.setState({ normaisInconsistentes: value }); })}
              style={{ paddingBottom: '1em' }}
              iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
            />
            <Checkbox
              label={this.props.t("reports.rejectedNotes")}
              checked={this.state.rejeitados}
              onCheck={((event, value) => { this.setState({ rejeitados: value }); })}
              style={{ paddingBottom: '1em' }}
              iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
            />
            {this.state.erroInformacoes ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '-7px' }} ref="validate">{this.props.t("selectOption")}</div> : ''}
          </div>
          <br />
          <NButtonGroup>
            <NButton onClick={this.gerarRelatorioApontamento} type="primary">{this.props.t("generate")}</NButton>
            <NButton
              onClick={() => this.setState({
                modal: false,
                unidadeIndustrialSelected: undefined,
                equipamentosDaUnidade: [],
                equipamento: '',
                lote: '',
                periodoReport: 7,
                dataInicioReport: '',
                dataFimReport: '',
                normaisInconsistentes: true,
                rejeitados: false,
                erroDatas: false,
                erroDataInicial: false,
                erroDataFinal: false,
                erroUnidadeIndustrial: false
              })}
            >
              {this.props.t("cancel")}
            </NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  getEquipments() {
    let sessions = JSON.parse(JSON.stringify(this.store.sessions));

    let equipments = sessions.reduce((p1, se) => p1.concat(
      se.stages.reduce((p2, st) => p2.concat(st.equipments.map(e => e)), [])
    ), []);

    return equipments;
  }

  handleNumeric(event) {
    document.getElementById('cod').value = Util.maskNumeric(event);
  }

  filterEquipments(opts) {
    let equipments = this.getEquipments();

    equipments = equipments.reduce((prev, el) => {
      prev[el.id] = true;
      return prev;
    }, {});

    return opts.filter(el => !!equipments[el.value]);
  }

  modalManut() {
    let { startDate, endDate, equipment, textEquipamento } = this.state;
    var erroCampo = false;
    equipment = equipment || this.state.equipamentoInformado;

    let handleEquipment = (obj) => {
      this.setState({
        equipment: obj.value,
        textEquipamento: obj.text
      });
      return true;
    };

    let redirect = () => {
      let inicio = document.getElementById('start_date').value;
      let fim = document.getElementById('end_date').value;

      if (!equipment) {
        erroCampo = true;
        this.setState({
          erroEquip: this.props.t("validations.requiredField")
        });
      } else {
        this.setState({
          erroEndDate: true
        });
      }

      if (moment(fim, 'L').isBefore(moment(inicio, 'L'))) {
        erroCampo = true;
        this.setState({
          erroEndDate: true
        });
      }

      if (!inicio) {
        erroCampo = true;
        this.setState({
          erroStartDate: true
        });
      } else {
        this.setState({
          erroStartDate: false
        });
      }

      if (!fim) {
        erroCampo = true;
        this.setState({
          erroEndDate: true
        });
      } else {
        this.setState({
          erroEndDate: false
        });
      }

      if ((moment(fim, 'L').isBefore(moment(inicio, 'L')))) {
        this.store.errors = this.props.t("messages.endDateLessStartDate");
        erroCampo = true;
      }

      if ((moment(fim, 'L').isAfter(moment()))) {
        this.store.errors = this.props.t("messages.futureEndDateHour");
        erroCampo = true;
      }

      if (!erroCampo && moment(fim, 'L').diff(moment(inicio, 'L'), 'days') <= 7) {
        this.props.history.push(`/apontamento/manutencao?id=${equipment}&dataInicio=${moment(inicio, 'L').format('DD-MM-YYYY')}&dataFim=${moment(fim, 'L').format('DD-MM-YYYY')}`);
      } else {
        this.state.errors = this.props.t("messages.maxInterval");
      }
    };

    return (
      <div className="rel_apont">
        <NForm>
          <NFormTitle>{this.props.t("notesMaintenance")}</NFormTitle>
          <AutoComplete
            onNewRequest={handleEquipment}
            style={{ marginTop: '-30px' }}
            underlineFocusStyle={{ borderColor: '#000000' }}
            filter={AutoComplete.fuzzyFilter}
            dataSource={this.state.opcEquipamentos}
            errorText={this.state.erroEquip}
            fullWidth={true}
            floatingLabelText={this.props.t("equipment")}
            onClick={() => this.setState({ textEquipamento: '', equipment: '' })}
            onUpdateInput={this.handleEquipment}
            label={this.props.t("equipment")}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
            openOnFocus={true}
            popoverProps={{ className: 'autocomplete' }} />
          <NContainer style={{ marginTop: '2em' }} direction="horizontal">
            <div className={stylesNform.ndatepicker}>
              <NLabel style={{ marginBottom: 0 }} text={this.props.t("from")}>
                <DatePicker className={this.state.erroStartDate ? stylesNform.require : ''}
                  value={startDate}
                  autoComplete={'off'}
                  onChange={date => this.setState({ startDate: date, endDate: null })}
                  selected={startDate}
                  maxDate={moment()}
                  weekdays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                  type='date'
                  id='start_date' />
                {this.state.erroStartDate ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{this.props.t("validations.requiredField")}</div> : ''}
              </NLabel>
            </div>
            <div className={stylesNform.ndatepicker}>
              <NLabel style={{ marginBottom: 0 }} text={this.props.t("to")}>
                <DatePicker className={this.state.erroEndDate ? stylesNform.require : ''}
                  value={endDate}
                  autoComplete={'off'}
                  onChange={date => this.setState({ endDate: date })}
                  selected={endDate}
                  minDate={startDate}
                  maxDate={moment(startDate).add(7, 'days').isSameOrAfter(moment()) ? moment() : moment(startDate).add(7, 'days')}
                  weekdays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                  type='date'
                  id='end_date' />
                {this.state.erroEndDate ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{this.props.t("validations.requiredField")}</div> : ''}
              </NLabel>
            </div>
          </NContainer>
          <br />
          <NButtonGroup>
            <NButton onClick={redirect} type="primary">{this.props.t("confirm")}</NButton>
            <NButton onClick={() => this.setState({ modal: false, startDate: '', endDate: '', equipment: 0, modalType: '' })}>{this.props.t("cancel")}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }
  /* Fim das funcoes integradas */

  handleModal(type, item, erro) {
    this.setState({
      modal: !this.state.modal,
      modalType: type,
      itemApont: item,
      erroMSG: erro,
      modelo: item || null,
    });

    if (type == 'visualizar_obs') {
      this.setState({
        modelo: item,
        editObs1: item.observacao,
        editObs2: item.observacao1,
        editObs3: item.observacao2,
        editObs4: item.observacao3
      });
    }

    if (type == 'editar_obs') {
      this.setState({
        modelo: item,
        editObs1: item.observacao,
        editObs2: item.observacao1,
        editObs3: item.observacao2,
        editObs4: item.observacao3
      });
    }
  }

  async handleLote() {
    this.setState({
      botaoLoading: true
    });

    await request(this.endpoints.LOTE_NUMERO + `/${this.state.insLote}?finalizado=true`)
      .then(function (response) {
        if (response.status == 200) return response.json();
      })
      .then((data) => {
        if (data) {
          this.setState({
            labelLote: data.produto.nome,
            apontLote: data,
            disableInsert: true,
            botaoLoading: false
          });
        } else {
          this.setState({
            disableInsert: false,
            botaoLoading: false,
            labelLote: this.utils.getReferenciaLoteOrdem(),
            apontLote: ''
          });

          alertBox(this.utils.getReferenciaLoteOrdemNaoEncontrada(), this.utils.getReferenciaLoteOrdem(), 'error');
        }
      });
  }

  errorMessage() {
    return (
      <div className="modal" style={{ width: '500px', height: '100%' }}>
        <div>
          <div style={{ textAlign: 'left' }} >
            {this.state.erroMSG}
          </div>
          <div>
            <i className="fa fa-close cursor-pointer" onClick={() => this.handleModal()} />
          </div>
        </div>
        <div style={{ paddingTop: '1em' }} >
          E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
        </div>
      </div>
    );
  }

  async inserirApontamento() {
    let erroCampo = false, erroProducao = false, eventoFimDeLote, somaProducao = 0;
    var novoApont = {}, novoEvento = {}, apontSuport = {}, apontExcluido = [];
    var inicio, fim;
    var campos = [];

    this.state.camposPersonalizado.forEach((campo, i) => {
      adicionaSeCampoNaoNull(campos, campo, this.state[`campo${i + 1}`]);
    });

    campos.forEach(([campo, valor], i) => {
      if (campo.obrigatorio && valor == '') {
        erroCampo = true;
        this.setState({
          [`erroCAMPO${i + 1}`]: this.props.t("validations.requiredField"),
          validar: false
        });
      } else {
        this.setState({
          [`erroCAMPO${i + 1}`]: null,
        });
      }
    });

    if (this.state.insDataInicial == '') {
      this.setState({
        erroDATAINICIAL: this.props.t("validations.requiredField"),
      });
      erroCampo = true;
    } else {
      this.setState({
        erroDATAINICIAL: '',
      });
      inicio = this.state.insDataInicial.format('L');
    }

    if (!this.state.apontEvento.instantaneo) {
      if (this.state.insDataFinal == '') {
        this.setState({
          erroDATAFINAL: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroDATAFINAL: '',
        });
        fim = this.state.insDataFinal.format('L');
      }
    } else {
      if (this.state.insDataInicial == '') {
        this.setState({
          erroDATAINICIAL: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroDATAINICIAL: '',
        });
        fim = this.state.insDataInicial.format('L');
      }
    }

    if (this.state.apontEvento.fluxo ? this.state.disableInsert && this.state.apontEvento.fluxo : !this.state.apontEvento.fluxo) {
      novoEvento['Evento'] = this.state.apontEvento;
      novoEvento['HoraInicial'] = this.state.insHoraInicial;

      if (!this.state.apontEvento.instantaneo) {
        novoEvento['HoraFinal'] = this.state.insHoraFinal;
      } else {
        novoEvento['HoraFinal'] = this.state.insHoraInicial;
      }

      this.state.apontEvento.fluxo ? novoEvento['Lote'] = this.state.insLote : null;

      if (this.state.equipamentoInformado == '') {
        this.setState({
          erroEQUIPAMENTO: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroEQUIPAMENTO: '',
        });
      }

      for (var i in novoEvento) {
        if (!novoEvento[i]) {
          this.setState({
            ['erro' + i.toUpperCase()]: this.props.t("validations.requiredField"),
            validar: false
          });
          erroCampo = true;
        }
      }

      if (!inicio) {
        this.setState({
          erroDATAINICIAL: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroDATAINICIAL: '',
        });
        novoEvento['DataInicial'] = moment(inicio, 'L');
      }

      if (!fim) {
        this.setState({
          erroDATAFINAL: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroDATAFINAL: '',
        });
        novoEvento['DataFinal'] = moment(fim, 'L');
      }

      eventoFimDeLote = this.state.eventos.find(e => e.classe_mobile.id == 12);

      if (erroProducao) {
        alertBox(this.props.t("messages.requiredProducedQuantity"), this.props.t("note"), 'warning');
      }

      if (!erroCampo) {
        novoApont['inicio'] = moment(novoEvento['DataInicial'].format('L') + ' ' + novoEvento['HoraInicial'], 'L LTS').format('YYYY-MM-DDTHH:mm:ss');
        novoApont['fim'] = moment(novoEvento['DataFinal'].format('L') + ' ' + novoEvento['HoraFinal'], 'L LTS').format('YYYY-MM-DDTHH:mm:ss');
        novoApont['evento'] = this.state.apontEvento;
        novoApont['equipamento'] = this.state.equipamentoInformado;
        this.state.apontEvento.fluxo
          ? novoApont['lote'] = this.state.apontLote
          : novoApont['lote'] = null;
        novoApont['observacao'] = this.state.campo1 || null;
        novoApont['observacao1'] = this.state.campo2 || null;
        novoApont['observacao2'] = this.state.campo3 || null;
        novoApont['observacao3'] = this.state.campo4 || null;
        novoApont['produzido_acumulado'] = parseInt(this.state.insProduzido, 10) ? parseInt(this.state.insProduzido, 10) : 0;
        novoApont['rejeitado_acumulado'] = parseInt(this.state.insRejeitado, 10) ? parseInt(this.state.insRejeitado, 10) : 0;
        novoApont['criado_usuario'] = {
          id: parseInt(localStorage.getItem('id')),
          nome: localStorage.getItem('login')
        };
        novoApont['atualizado_usuario'] = null;
        novoApont['excluido_usuario'] = null;
        novoApont['uuid'] = uuidv4();
        novoApont['tipo'] = 'Criar';
        novoApont['estado'] = 'Aceito';
        novoApont['sujo'] = true;
        novoApont['origem_apontamento'] = this.origemApontamento.WEB;
        novoApont['tipo_producao'] = this.state.tipoProducao;
        this.setState(update(this.state, { apontamentos: { $push: [novoApont] } }), () => {
          this.clearState();
        });

        let classeId = this.state.numClasse;
        let avisoFimDeLote = this.state.avisoFimDeLote;

        //Confere se o evento é fim de lote
        if (avisoFimDeLote.valor == 'On' && classeId == 12) {
          let equipamentoId = this.state.equipamentoInformado.id;
          let lote = this.state.numLote;

          request(`${this.endpoints.APONTAMENTO}/fim-de-lote?lote=${lote}&classeId=${classeId}&equipamentoId=${equipamentoId}`, {
            method: 'get',
          }, true)
            .then(function (response) {
              if (response.status == 200) return response.json();
            })
            .then((data) => {
              //Apontamentos já inseridos
              let apontamento = data;

              //Apontamentos a inserir
              let listaApontamentos = this.state.apontamentos;

              if (apontamento != null) {
                let dataApontamento = moment(apontamento.fim).format('L');
                let horaApontamento = moment(apontamento.fim).format('LT');

                alertBox(this.props.t("messages.stageAlreadyFinished", { batch: apontamento.lote.numero, day: dataApontamento, hour: horaApontamento }),
                  this.props.t("messages.duplicatedBatchEnd"),
                  'warning');
              } else if (listaApontamentos.length > 1) {
                alertBox(this.props.t("messages.batchEndAlreadyExists"),
                  this.props.t("messages.duplicatedBatchEnd"),
                  'warning');
              }
            });
        }
      }
    }
  }

  contentModal() {
    switch (this.state.modalType) {
      case 'relat_apont':
        return this.modalApont();
      case 'manut_apont':
        return this.modalManut();
      case 'editar_obs':
        return this.editarObs();
      case 'visualizar_obs':
        return this.visualizarObs();
      case 'erro':
        return this.errorMessage();
      case 'loading':
        return <div className="modal" id={styles.loading} style={{ width: '80px', height: '100%' }}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>;
    }
  }

  visualizarObs() {
    var campos = [];
    var modelo = this.state.modelo;

    adicionaSeCampoNaoNull(campos, modelo.campo, modelo.note);
    adicionaSeCampoNaoNull(campos, modelo.campo1, modelo.note1);
    adicionaSeCampoNaoNull(campos, modelo.campo2, modelo.note2);
    adicionaSeCampoNaoNull(campos, modelo.campo3, modelo.note3);

    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("noteObs")}</NFormTitle>
          <NContainer direction="vertical">
            {
              campos.map(([campo, observacao]) => {
                return (
                  <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                    <TextField width={{ width: '40%' }}
                      disabled={true}
                      fullWidth={true}
                      value={observacao || ''}
                      floatingLabelText={campo}
                      underlineFocusStyle={{ borderColor: '#000000' }}
                      floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9rem', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    />
                  </div>
                );
              })
            }
          </NContainer>
          <br />
          <NButtonGroup>
            <NButton onClick={() => this.setState({ modal: false })}>{this.props.t("close")}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  editarObs() {
    var item = this.state.itemApont, apontSuport = {};

    let onSave = () => {
      apontSuport = item;
      apontSuport['sujo'] = true;
      apontSuport['tipo'] = item.tipo == 'Criar' ? this.props.t("create") : this.props.t("edit");

      apontSuport.observacao = this.state.editObs1;
      apontSuport.observacao1 = this.state.editObs2;
      apontSuport.observacao2 = this.state.editObs3;
      apontSuport.observacao3 = this.state.editObs4;

      this.setState(update(this.state, { apontamentos: { $splice: [[item.index, 1, apontSuport]] } }));
      this.handleModal();
    };

    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("noteObs")}</NFormTitle>
          <NContainer direction="vertical">
            {
              this.state.camposPersonalizado.map((c, i) => {
                if (c.habilitado) {
                  if (c.tipo == 'numerico') {
                    if (!this.state['editObs' + (i + 1)] || parseInt(this.state['editObs' + (i + 1)], 10)) {
                      return (
                        <NInputNumber
                          id={'editObs' + (i + 1)}
                          label={c.nome}
                          value={this.state['editObs' + (i + 1)]}
                          fullWidth={true}
                          onChange={(event, value) => { this.setState({ ['editObs' + (i + 1)]: value }); }}
                          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                        />
                      );
                    } else {
                      return (
                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                          <i style={{ position: 'absolute', right: 5, top: 34, width: 20, height: 20, zIndex: 20, cursor: 'pointer' }} className='material-icons'
                            onClick={() => this.setState({ ['editObs' + (i + 1)]: '' })}>cancel</i>
                          <TextField
                            width={{ width: '40%' }}
                            disabled={true}
                            fullWidth={true}
                            value={this.state['editObs' + (i + 1)]}
                            floatingLabelText={c.nome}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                          />
                        </div>
                      );
                    }
                  } else if (c.tipo == 'alfanumerico') {
                    return (
                      <TextField
                        key={i}
                        errorText={this.state['erroCAMPO' + (i + 1)]}
                        id={'editObs' + (i + 1)}
                        fullWidth={true}
                        floatingLabelText={c.nome}
                        value={this.state['editObs' + (i + 1)]}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        onChange={(event, value) => { this.setState({ ['editObs' + (i + 1)]: value }); }}
                      />
                    );
                  } else if (c.tipo == 'selecionar') {
                    var op = c.opcoes ? c.opcoes.find(o => o.nome == this.state['editObs' + (i + 1)]) : {};

                    if ($('#campo' + (i + 1)).is(':focus') || (!this.state['editObs' + (i + 1)] || op)) {
                      let opcoes = c.opcoes ? c.opcoes.map(o => ({ value: o.nome, text: o.nome })) : [];

                      return (
                        <AutoComplete
                          key={`campo-${i}`}
                          id={'campo' + (i + 1)}
                          fullWidth={true}
                          style={{ maxHeight: '10em' }}
                          floatingLabelText={c.nome}
                          openOnFocus={true}
                          searchText={this.state['editObs' + (i + 1)] == null ? '' : this.state['editObs' + (i + 1)]}
                          underlineFocusStyle={{ borderColor: '#000000' }}
                          filter={AutoComplete.fuzzyFilter}
                          floatingLabelStyle={{
                            color: '#5B5B5B',
                            textTransform: 'uppercase',
                            textOverflow: 'ellipsis',
                            width: '130%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          }}
                          onUpdateInput={(event, value) => this.handleEditCustom((i + 1), event, opcoes)}
                          dataSource={opcoes}
                          onClick={() => { this.setState({ ['editObs' + (i + 1)]: '' }); }}
                          popoverProps={{ className: 'autocomplete' }} />
                      );
                    } else {
                      return (
                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                          <i style={{ position: 'absolute', right: 5, top: 34, width: 20, height: 20, zIndex: 20, cursor: 'pointer' }} className='material-icons'
                            onClick={() => this.setState({ ['editObs' + (i + 1)]: '' })}>cancel</i>
                          <TextField
                            width={{ width: '40%' }}
                            disabled={true}
                            fullWidth={true}
                            value={this.state['editObs' + (i + 1)]}
                            floatingLabelText={c.nome}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                          />
                        </div>
                      );
                    }
                  }
                }
              })
            }
          </NContainer>
          <br />
          <NButtonGroup>
            <NButton onClick={onSave} type="primary">{this.props.t("save")}</NButton>
            <NButton onClick={() => this.setState({ modal: false })}>{this.props.t("cancel")}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  validarApontamento() {
    let erroAPI = false;
    let tempApont, apoConsulta, apo;

    this.handleModal('loading');

    let apontRemoveTemp = this.state.apontamentos.filter(a => a.tipo == 'Excluir');
    let apontRemove = Util.ordenaApontamentos(apontRemoveTemp);

    let validEditTemp = this.state.apontamentos.filter(a => a.tipo !== 'Excluir');
    let validEdit = Util.ordenaApontamentos(validEditTemp);

    let validApont = apontRemove.concat(validEdit).map(a => ({
      apontamento: { ...a, produzido_acumulado: a.produzido_acumulado, rejeitado_acumulado: a.rejeitado_acumulado },
      tipo: a.tipo ? a.tipo : 'Editar'
    })).map(a => {
      if (a.tipo == 'Editar') {
        a.apontamento = {
          ...a.apontamento,
          atualizado_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      } else if (a.tipo == 'Criar') {

        a.apontamento = {
          ...a.apontamento,
          criado_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      } else if (a.tipo == 'Excluir') {
        a.apontamento = {
          ...a.apontamento,
          excluido_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      }
      return a;
    });

    request(this.endpoints.APONTAMENTOS_VALIDAR, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(validApont)
    })
      .then(function (response) {
        if (response.status == 200) {
          return response.json();
        } else {
          erroAPI = true;
        }
      })
      .then((data) => {
        if (erroAPI) {
          this.setState({
            modal: true,
            modalType: 'erro',
            erroMSG: this.props.t("messages.validationError")
          });
        } else {
          this.handleModal();
          apoConsulta = data.apontamentos.reduce((acc, a) => {
            acc[a.apontamento.uuid] = a;
            return acc;
          }, {});

          validApont = validApont.map(a => {
            apo = apoConsulta[a.apontamento.uuid];
            switch (apo.estado) {
              case 0:
                a.apontamento.estadoNext = 'Aceito';
                break;

              case 1:
                a.apontamento.estadoNext = 'Rejeitado';
                switch (apo.error) {
                  case 'rej4':
                    a.apontamento['descricao'] = this.props.t("validations.invalidInterval");
                    break;

                  case 'rej5':
                    a.apontamento['descricao'] = this.props.t("validations.intervalConflictSameEquipment");
                    break;

                  case 'rej6':
                    a.apontamento['descricao'] = this.props.t("validations.intervalConflictAnotherEquipment", { batch: this.utils.getReferenciaoLoteaOrdem() });
                    break;

                  case 'rej7':
                    a.apontamento['descricao'] = this.props.t("validations.multipleNotesSameInterval", { batch: this.utils.getReferenciaoLoteaOrdem().toLowerCase() });
                    break;

                  default:
                    a.apontamento['descricao'] = apo.descricao;
                    break;
                }
                break;

              case 2:
                a.apontamento.estadoNext = 'Inconsistente';
                a.apontamento['descricao'] = apo.descricao;
                break;
            }

            a.apontamento['descricao_oee'] = apo.descricao_oee;
            a.apontamento['classe'] = apo.apontamento.classe;
            a.apontamento['consistente_oee'] = apo.apontamento.consistente_oee;

            if (apo.estado_oee != 0 && apo.estado != 1) {
              a.apontamento.estadoNext = 'Inconsistente';
            }

            return a;
          });

          tempApont = validApont.map((a) => ({
            ...a.apontamento
          }));

          this.setState({
            apontamentos: tempApont,
            validar: true
          });
        }
      }).catch(e => {
        console.error(e);

        this.setState({
          modal: true,
          modalType: 'erro',
          erroMSG: this.props.t("messages.insertError")
        });
      });
  }

  encerrarApontamento() {
    let erroAPI = false;
    this.handleModal('loading');

    let apontRemoveTemp = this.state.apontamentos.filter(a => a.tipo == 'Excluir');
    let apontRemove = Util.ordenaApontamentos(apontRemoveTemp);

    let validEditTemp = this.state.apontamentos.filter(a => a.tipo !== 'Excluir');
    let validEdit = Util.ordenaApontamentos(validEditTemp);

    let encApont = apontRemove.concat(validEdit).map(a => ({
      apontamento: {
        ...a,
        produzido_acumulado: a.produzido_acumulado,
        rejeitado_acumulado: a.rejeitado_acumulado,
      },
      tipo: a.tipo ? a.tipo : 'Editar'
    })).map(a => {
      if (a.tipo == 'Editar') {
        a.apontamento = {
          ...a.apontamento,
          atualizado_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      } else if (a.tipo == 'Criar') {

        a.apontamento = {
          ...a.apontamento,
          criado_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      } else if (a.tipo == 'Excluir') {
        a.apontamento = {
          ...a.apontamento,
          excluido_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      }

      return a;
    });

    request(this.endpoints.APONTAMENTO_PROCESSAR, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(encApont)
    })
      .then(function (response) {
        if (!response.status == 200) {
          erroAPI = true;
        }

        return response.json();
      })
      .then((data) => {
        if (erroAPI) {
          this.setState({
            modal: true,
            modalType: 'erro',
            erroMSG: this.props.t("messages.insertError")
          });
        }

        if (data.ok == 'ok') {
          this.showAlert(this.props.t("messages.insertSuccess"), this.props.t("success"), 'success');
          setTimeout(function () {
            window.location.reload();
          }, 1500);
        }
      }).catch(e => {
        console.error(e);

        this.setState({
          modal: true,
          modalType: 'erro',
          erroMSG: this.props.t("messages.insertError")
        });
      });
  }

  redirectFlowView() {
    this.props.history.push('/flow-view');
  }

  dataInicioKeyChange(event) {
    var char = event.keyCode;
    var now = '';
    now = moment();

    if (event.target.value === '') {
      if (char === 9) {
        this.setState({
          insDataInicial: ''
        });
        this.setState({
          insDataInicial: now
        });
      }
    }
  }

  enterKeyCode(event) {
    var char = event.keyCode;
    if (char === 13) {
      if (this.state.apontamentos.length > 0) {
        $('#dataFinal').focus();
      } else {
        $('#dataInicial').focus();
      }
    }
  }

  dataFimKeyChange(event) {
    var char = event.keyCode;
    var now = '';
    now = moment();

    if (event.target.value === '') {
      if (char === 9) {
        this.setState({
          insDataFinal: ''
        });
        this.setState({
          insDataFinal: now
        });
      }
    }
  }

  redirectNotes() {
    this.props.history.push('/apontamento');
  }

  clearState() {
    this.setState({
      validar: false,
      disableInsert: false,
      insEvento: '',
      insDataInicial: !this.state.apontEvento.instantaneo ? this.state.insDataFinal : this.state.insDataInicial,
      insHoraInicial: !this.state.apontEvento.instantaneo ? this.state.insHoraFinal : this.state.insHoraInicial,
      insDataFinal: '',
      insHoraFinal: ' ',
      insLote: '',
      insProduzido: '',
      insRejeitado: '',
      campo1: '',
      campo2: '',
      campo3: '',
      campo4: '',
      campoText1: '',
      campoText2: '',
      campoText3: '',
      campoText4: '',
      labelEvento: this.props.t("event"),
      labelLote: this.utils.getReferenciaLoteOrdem(),
      apontEvento: '',
      apontLote: '',
      erroEVENTO: '',
      searchTextEvento: '',
      textEvento: '',
      erroDATAINICIAL: '',
      erroHORAINICIAL: '',
      erroDATAFINAL: '',
      erroHORAFINAL: '',
      erroLOTE: '',
      tipoProducao: ''
    },
      () => {
        setTimeout(() => {
          this.state.camposPersonalizado.map((c, i) => {
            $('#campo' + (i + 1)).val('');
            this.setState({
              ['campoText' + (i + 1)]: '',
              ['campo' + (i + 1)]: ''
            });
          });
          $('#cod').val('');
          $('#produzido').val('');
          $('#rejeitado').val('');
        }, 120);
      });
  }

  parseQueryString(query) {
    var vars = query.split('&');
    var query_string = {};

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (typeof query_string[pair[0]] === 'undefined') {
        query_string[pair[0]] = decodeURIComponent(pair[1]);
      } else if (typeof query_string[pair[0]] === 'string') {
        var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
        query_string[pair[0]] = arr;
      } else {
        query_string[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }

    return query_string;
  }

  showAlert(msg, title, type) {
    if (type == 'error') {
      Notifier.error(msg || 'Oops!', title || this.props.t("notification"));
      setTimeout(3000);
    }

    if (type == 'success') {
      Notifier.success(msg || 'Oops!', title || this.props.t("notification"));
      setTimeout(5000);
    }
  }

  checarUltimoCampo(event, campo) {
    var char = event.keyCode;
    if (char === 13 && campo == undefined) {
      this.inserirApontamento();
    }
  }

  onKeyDownAutoComplete(event, ref, i) {
    if (this[ref].refs.menu != undefined) {
      this[ref].refs.menu.setState({
        isKeyboardFocused: true
      });
    }
  }

  autoCompleteSet(i, text, opcoes) {
    const value = text;
    const opcoesList = opcoes.map(opcao => opcao.value);

    if (!opcoesList.includes(value)) {
      this.setState({
        ['campo' + (i + 1)]: '',
        ['campoText' + (i + 1)]: '',
        ['erroCAMPO' + (i + 1)]: ''
      });
    } else {
      this.setState({
        ['campo' + (i + 1)]: value,
        ['campoText' + (i + 1)]: value,
        ['erroCAMPO' + (i + 1)]: ''
      });
    }
  }

  handleEditCustom(i, event, opcoes) {
    const value = event;
    const opcoesList = opcoes.map(opcao => opcao.value);

    if (!opcoesList.includes(value)) {
      this.setState({ ['editObs' + (i)]: '' });
      this.setState({ ['editObsText' + (i)]: '' });
    } else {
      this.setState({ ['editObs' + (i)]: value });
      this.setState({ ['editObsText' + (i)]: value });
    }
  };

  render() {
    let { insDataInicial, insHoraInicial } = this.state;

    if (this.state.apontamentos.length > 0) {
      $('#dataInicial').css('color', '#b2b2b2');
      $('#dataInicial').css('background-color', 'transparent');
      $('#dataInicial').css('border-bottom', '2px dotted #9c9c9c');
    } else if (this.state.apontamentos.length <= 0) {
      $('#dataInicial').css('color', '#5B5B5B');
      $('#dataInicial').css('background-color', 'transparent');
      $('#dataInicial').css('border-bottom', '2px solid #e0e0e0');
    }

    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container modal={this.state.modal} styleModal={{ width: 400 }} contentModal={this.contentModal}>
          <div className='box_list'>
            <Card className='box_list_card'>
              <CardHeader className='box_header' title={this.props.t("title")}>
                <IconMenu
                  value={this.state.teep}
                  onChange={this.menuIconClicked.bind(this)}
                  style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                  iconButtonElement={<Avatar icon={<i className='material-icons'>extension</i>} color='#FFF' backgroundColor='#D84042' size={30} />}>
                  <MenuItem
                    className={hasRole('ROLE_MANUTENCAO_APONTAMENTOS_VISUALIZAR') ? styles.menuItem : 'menu_block'}
                    disabled={!hasRole('ROLE_MANUTENCAO_APONTAMENTOS_VISUALIZAR')}
                    value={1}
                    primaryText={this.props.t("notesMaintenance")}
                  />
                </IconMenu>
              </CardHeader>
              <CardText>
                <div className={`${styles.formContainer} row`}>
                  <div className={'col-sm-10 col-xs-12'} >
                    <div className="row">
                      <div className="col-sm-2">
                        <AutoComplete
                          className={styles.fontForm}
                          dataSource={this.state.opcEquipamentos}
                          fullWidth={true}
                          floatingLabelText={this.state.labelEquipment ? this.state.labelEquipment : this.props.t("equipment")}
                          underlineStyle={this.state.erroEQUIPAMENTO ? { borderColor: 'red' } : null}
                          errorText={this.state.erroEQUIPAMENTO ? this.props.t("validations.requiredField") : null}
                          underlineFocusStyle={{ borderColor: '#000000' }}
                          id={'equipment'}
                          name={'equipment'}
                          disabled={this.state.apontamentos.length > 0}
                          filter={AutoComplete.fuzzyFilter}
                          onUpdateInput={this.handleEquipment}
                          onClose={(event) => $('#cod').focus()}
                          ref={ref => this.autoComplete = ref}
                          onClick={() => {
                            this.setState({
                              equipamentoInformado: '',
                              labelEquipment: '',
                              equipamentoText: '',
                              erroEQUIPAMENTO: '',
                              camposPersonalizado: [],
                              ultimosApontamentos: []
                            });
                          }}
                          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                          openOnFocus={true}
                          popoverProps={{ className: 'autocomplete', tabIndex: 1 }}
                        />
                      </div>
                      <div className="col-sm-2">
                        <AutoComplete
                          id={'cod'}
                          name={'cod'}
                          className={styles.fontForm}
                          dataSource={this.state.opcEventos}
                          fullWidth={true}
                          floatingLabelText={this.state.labelEvento ? this.state.labelEvento : this.props.t("event")}
                          underlineStyle={this.state.erroEVENTO ? { borderColor: 'red' } : null}
                          searchText={this.state.searchTextEvento == '' ? this.state.textEvento : this.state.searchTextEvento}
                          errorText={this.state.erroEVENTO ? this.props.t("validations.requiredField") : null}
                          underlineFocusStyle={{ borderColor: '#000000' }}
                          filter={AutoComplete.fuzzyFilter}
                          onUpdateInput={this.handleEvento}
                          onKeyDown={this.handleKeyDown.bind(this)}
                          onClose={(event) => $('#dataInicial').focus()}
                          ref={ref => this.autoComplete = ref}
                          onClick={() => {
                            this.setState({
                              insEvento: '',
                              labelEvento: '',
                              erroEVENTO: '',
                              eventoText: '',
                              searchTextEvento: '',
                              textEvento: ''
                            });
                          }}
                          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                          openOnFocus={true}
                          popoverProps={{ className: 'autocomplete', tabIndex: 1 }}
                        />
                      </div>
                      <div className="col-sm-2">
                        <NDatePickerInput
                          require={this.state.erroDATAINICIAL}
                          id='dataInicial'
                          value={insDataInicial}
                          autoComplete={'off'}
                          fullWidth={true}
                          className={styles.fontForm}
                          floatingLabelStyle={{ color: '#5B5B5B', marginBottom: '0rem', textTransform: 'uppercase' }}
                          onKeyDown={event => this.dataInicioKeyChange(event)}
                          onChange={date => this.handleStart(date)}
                          onChangeRaw={(_, date) => this.handleStart(date)}
                          onBlur={event => this._valiDateStart(event.target.value)}
                          disabled={this.state.apontamentos.length > 0}
                          maxDate={moment()}
                          label={!this.state.apontEvento.instantaneo ? this.props.t("startDate") : this.props.t("date")}
                        />
                      </div>
                      <div className="col-sm-2">
                        <NInputTime
                          type='time'
                          fullWidth={true}
                          className={styles.fontForm}
                          errorText={this.state.erroHORAINICIAL} step={'1'}
                          disabled={this.state.apontamentos.length > 0}
                          onChange={(event, value) => { this.setState({ insHoraInicial: value }); }}
                          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', background: '#fff', textOverflow: 'ellipsis' }}
                          value={insHoraInicial}
                          id='horaInicial'
                          label={!this.state.apontEvento.instantaneo ? this.props.t("startHour") : this.props.t("hour")}
                        />
                      </div>
                      {
                        !this.state.apontEvento.instantaneo ?
                          <div className="col-sm-2">
                            <NDatePickerInput
                              require={this.state.erroDATAFINAL}
                              id='dataFinal'
                              style={{ marginRight: '2em' }}
                              value={this.state.insDataFinal}
                              selected={this.state.insDataFinal !== '' ? moment(this.state.insDataFinal) : (insDataInicial !== '' ? moment(insDataInicial) : moment())}
                              autoComplete={'off'}
                              fullWidth={true}
                              labelClassName={styles.nobreak}
                              className={styles.fontForm}
                              floatingLabelStyle={{ color: '#5B5B5B', marginBottom: '0rem', textTransform: 'uppercase', whiteSpace: 'nowrap' }}
                              onKeyDown={event => this.dataFimKeyChange(event)}
                              onSelect={date => this.handleTime(date)}
                              onChange={date => this.handleTime(date)}
                              onChangeRaw={(_, date) => this.handleTime(date)}
                              onBlur={event => this._valiDateEnd(event.target.value)}
                              minDate={moment(insDataInicial)}
                              maxDate={moment()}
                              label={this.props.t("endDate")}
                            />
                          </div>
                          : null
                      }
                      {
                        !this.state.apontEvento.instantaneo ?
                          <div className="col-sm-2">
                            <NInputTime
                              type='time'
                              errorText={this.state.erroHORAFINAL} step={'1'}
                              onBlur={value => this._validateHourEnd(value)}
                              className={styles.fontForm}
                              onChange={(event, value) => { this.setState({ insHoraFinal: value }); }}
                              fullWidth={true}
                              floatingLabelStyle={{ whiteSpace: 'nowrap', color: '#5B5B5B', textTransform: 'uppercase', background: '#fff', textOverflow: 'ellipsis' }}
                              value={this.state.insHoraFinal}
                              id='horaFinal'
                              label={this.props.t("endHour")}
                            />
                          </div>
                          : null
                      }
                      {
                        <div className="col-sm-2">
                          <TextField
                            id={'tipoProducao'}
                            name={'tipoProducao'}
                            floatingLabelText={this.props.t("productionType")}
                            value={this.state.tipoProducao}
                            onChange={(event, value) => { this.setState({ tipoProducao: value }) }}
                            fullWidth={true}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            className={styles.fontForm}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '100%' }}
                            floatingLabelFocusStyle={{ maxWidth: '100%' }}
                          />
                        </div>
                      }
                      <input type='hidden' id='equip_is_batch' value={this.state.equip_isBatch} />
                      <input type='hidden' id='label_cod' value={this.state.label} />
                      <input type='hidden' id='is_batch' value={this.state.is_batch} />
                      <input type='hidden' id='is_produced' value={this.state.is_produced} />
                      {
                        this.state.apontEvento.fluxo
                          ?
                          <div className="col-sm-2">
                            <TextField
                              errorText={this.state.erroLOTE}
                              floatingLabelText={this.state.labelLote}
                              value={this.state.insLote}
                              onChange={(event, value) => { this.setState({ insLote: value, numLote: value }); }}
                              underlineFocusStyle={{ borderColor: '#000000' }}
                              onBlur={this.handleLote}
                              fullWidth={true}
                              className={styles.fontForm}
                              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', whiteSpace: 'nowrap', maxWidth: '130%' }}
                              floatingLabelFocusStyle={{ maxWidth: '130%' }}
                            />
                          </div>
                          : null
                      }
                      {!this.state.equipamentoInformado.batch && this.state.apontEvento.fluxo
                        ?
                        <div className="col-sm-2">
                          <NInputNumber
                            id='produzido'
                            className={`${styles.fontForm}`}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                            label={this.props.t("produced")}
                            value={this.state.insProduzido}
                            fullWidth={true}
                            onChange={(event, value) => { this.setState({ insProduzido: value.toString().replace(/[\,\. ]/g, '') }); }}
                          />
                        </div>
                        : null
                      }
                      {!this.state.equipamentoInformado.batch && this.state.apontEvento.fluxo
                        ?
                        <div className="col-sm-2">
                          <NInputNumber
                            id='rejeitado'
                            className={styles.fontForm}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                            label={this.props.t("rejected")}
                            fullWidth={true}
                            value={this.state.insRejeitado}
                            onChange={(event, value) => { this.setState({ insRejeitado: value.replace(/[\,\. ]/g, '') }); }}
                          />
                        </div>
                        : null
                      }
                      {
                        this.state.camposPersonalizado.map((c, i) => {
                          var ret = c.nome;

                          if (ret.length > 26) {
                            ret = ret.substr(0, 26 - 3) + '...';
                          }

                          if (c.habilitado) {
                            if (c.tipo == 'numerico') {
                              return (
                                <div className='col-sm-3'>
                                  <NInputNumber
                                    key={i}
                                    id={'campo' + (i + 1)}
                                    errorText={this.state['erroCAMPO' + (i + 1)]}
                                    fullWidth={true}
                                    label={ret}
                                    value={this.state['campo' + (i + 1)]}
                                    onKeyDown={event => $('#campo' + (i + 2)).val() != undefined ? $('#campo' + (i + 1)).focus() : this.checarUltimoCampo(event, $('#campo' + (i + 2)).val())}
                                    floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                                    onChange={(event, value) => { this.setState({ ['campo' + (i + 1)]: value }); }}
                                  />
                                </div>
                              );
                            }

                            if (c.tipo == 'alfanumerico') {
                              return (
                                <div className='col-sm-3'>
                                  <TextField
                                    key={i}
                                    errorText={this.state['erroCAMPO' + (i + 1)]}
                                    id={'campo' + (i + 1)}
                                    fullWidth={true}
                                    floatingLabelText={ret}
                                    value={this.state['campo' + (i + 1)]}
                                    onKeyDown={event => $('#campo' + (i + 2)).val() != undefined ? $('#campo' + (i + 1)).focus() : this.checarUltimoCampo(event, $('#campo' + (i + 2)).val())}
                                    floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    onChange={(event, value) => { this.setState({ ['campo' + (i + 1)]: value }); }}
                                  />
                                </div>
                              );
                            }

                            if (c.tipo == 'selecionar') {
                              let opcoes = c.opcoes ? c.opcoes.map(o => ({ value: o.nome, text: o.nome })) : [];

                              return (
                                <div className='col-sm-3'>
                                  <AutoComplete
                                    fullWidth={true}
                                    key={i}
                                    id={'campo' + (i + 1)}
                                    errorText={this.state['erroCAMPO' + (i + 1)]}
                                    onKeyDown={(event) => this.onKeyDownAutoComplete(event, 'autoComplete' + i, i)}
                                    ref={ref => this['autoComplete' + i] = ref}
                                    floatingLabelText={ret}
                                    openOnFocus={true} value={this.state['campoText' + (i + 1)]}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    filter={AutoComplete.fuzzyFilter}
                                    searchText={this.state['campoText' + (i + 1)]}
                                    onClick={() => this.setState({ ['campoText' + (i + 1)]: '', ['campo' + (i + 1)]: '' })}
                                    floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', top: 38, whiteSpace: 'nowrap' }}
                                    onUpdateInput={text => this.autoCompleteSet(i, text, opcoes)}
                                    dataSource={opcoes}
                                    popoverProps={{ className: 'autocomplete' }}
                                  />
                                </div>
                              );
                            }
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className={`${styles.formButton} col-sm-2 col-xs-12 `} >
                    <div className={'row'}>
                      <div className={'col-xs-12'}>
                        {!this.state.botaoLoading ?
                          <RaisedButton id={'buttonInsert'} onClick={this.inserirApontamento} backgroundColor={(!hasRole('ROLE_APONTAMENTOS_CRIAR') || (!this.state.disableInsert && this.state.apontEvento.fluxo)) ? '#FFF' : '#2D4F7F'} buttonStyle={{ fontFamily: '' }}
                            disabled={!hasRole('ROLE_APONTAMENTOS_CRIAR') || !this.state.disableInsert && this.state.apontEvento.fluxo}
                            labelColor='#FFF' style={{ fontSize: '.7em', fontWeigth: 100, display: 'block' }} label={this.props.t("insert")} />
                          :
                          <button type="button" id='buttonLoading'
                            className={styles.botaoLoading}>
                            {this.props.t("validating")}
                            <br />
                            <img src='../../../assets/loading.gif' color='#2D4F7F' style={{ width: '70px' }} thickness={2} />
                          </button>
                        }
                      </div>
                      <div className={'col-xs-12'}>
                        <RaisedButton disabled={!hasRole('ROLE_APONTAMENTOS_CRIAR') || this.state.apontamentos.length <= 0} onClick={this.validarApontamento} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }}
                          style={{ fontSize: '.7em', fontWeigth: 100, display: 'block' }} label={this.props.t('validate')} />
                      </div>
                      <div className={'col-xs-12'}>
                        <RaisedButton disabled={!hasRole('ROLE_APONTAMENTOS_CRIAR') || this.state.apontamentos.length <= 0} onClick={this.encerrarApontamento} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }}
                          style={{ fontSize: '.7em', fontWeigth: 100, display: 'block' }} label={this.props.t('finish')} />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.equipamentoInformado ?
                  <ListNotes validar={this.state.validar}
                    lastNotes={this.state.ultimosApontamentos}
                    remove={this.remove}
                    handleModal={this.handleModal}
                    apontamentoExcluido={this.state.apontamentoExcluido}
                    apontamentoNovo={this.state.apontamentoNovo}
                    equipamento={this.state.equipamentoInformado}
                    apontamentos={this.state.apontamentos}
                    store={this.store}
                    t={this.props.t}
                  />
                  :
                  null
                }
              </CardText>
            </Card>
          </div>
        </Container>
      );
    }
  }
}

const NotesRenew = withTranslation('notes')(NotesRenewExport);

export default withRouter(({ history }) => (
  <NotesRenew history={history} />
));