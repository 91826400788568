import { serializeParams } from '../../utils';
import { request } from '../../common/request';

const BASE_URL = `${process.env.APIV2_URL}/produto`;
export class Product {

    constructor(options = {}) {
        this.id = undefined
        this.pi_pa = false
        this.estado = true

        Object.assign(this, {
            ativo: true
        }, options);

        this.politica_estoque = options?.politica_estoque || {}
        this.area_industrial = options?.area_industrial || {}
        this.predecessores = options?.predecessores || []
    }

    static find = async (id) => {
        const url = `${BASE_URL}/${id}`;

        return await request(url, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(
                data => new Product(data),
                error => error
            )
    }

    static all = async (params={}, lazy = false) => {
        const url = `${BASE_URL}/${lazy ? 'lazy' : 'performance'}?` + serializeParams(params);

        return await request(url, {
            method: 'GET'
        })
            .then(res => {
                if (res.status === 204) 
                    return [];

                return res.json();
            })
            .then(
                data => data.map(c => new Product(c)),
                error => error
            )
    }

    create = (data) => {
        return request(BASE_URL, {
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    update = (data) => {
        const url = `${BASE_URL}/${this.id}`;
        return request(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        })
    }

    // TODO: Criar model para Rota e passar esse método para lá
    destroyRoutes = () => {
        const url = `${process.env.APIV2_URL}/rota/produto/${this.id}`;
        return request(url, {
            method: 'DELETE'
        })
    }
}