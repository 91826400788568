import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Store from '../common/Store';

export default class App extends Component {

  constructor() {
    super();

    this.store = Store;

    this.state = {
      movideskLoaded: false
    };

    this.mutate = this.mutate.bind(this);
  }

  componentDidMount() {
    let observer = new MutationObserver(this.mutate);

    observer.observe(document.body, { childList: true });
  }

  mutate(mutations, observer) {
    mutations.forEach((mutation) => {
      for (var i = 0; i < mutation.addedNodes.length; i++) {
        if (mutation.addedNodes[i].id === 'md-app-widget') {
          let chatWrapper = document.getElementById("md-app-widget");

          if (chatWrapper) {
            this.store.movideskChat = chatWrapper;

            let shouldLoadMovidesk = localStorage.getItem('FeatureChat') === '1';

            if (shouldLoadMovidesk) {
              chatWrapper.style.visibility = "visible";
            }
          }

          observer.disconnect();
        }
      }
    });
  }

  render() {
    return (
      <MuiThemeProvider>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}
