import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './header.scss';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import LinkClientV2 from './LinkClientV2';
import MovideskTooltip from 'components/movidesk';
import { withTranslation } from 'react-i18next';

const urlsCadastro = [
  'cadastro', 'equipamento', 'produto', 'campo-personalizado', 'usuario', 'grupo-de-usuario',
  'etapa', 'area-produtiva', 'unidade-industrial', 'rota', 'predecessor', 'evento', 'lote',
  'manutencao', 'turno', 'opcoes-de-calculo', 'grupo-de-evento', 'grupo-de-equipamento'
];

class Header extends Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
  }

  logOut() {
    this.props.action.logout(this.props.endpoints.SGCA_DESLOGAR);
  }

  isUrlCadastro(url) {
    const data = urlsCadastro.filter((urlCadastro) => urlCadastro === url);
    return data && data.length > 0;
  }

  render() {
    let { title } = this.props;
    let iconePainelFluxo = '../assets/iconePainelFluxoCinza.png';
    let URL = window.location.href.split('/'),
      inArrayForm = [
        'cadastro',
        'parametro',
        'apontamento',
        'flow-view',
        'oee',
        'painel-de-fluxo'
      ];
    URL = URL[URL.length - 1];

    if (URL === 'painel-de-fluxo') {
      iconePainelFluxo = '../assets/iconePainelFluxoBranco.png';
    }

    return (
      <div>
        <div className={styles.d_none_tablet}>
          <header className={styles.box_menu}>
            <Link to='/home'>
              <img src='./../assets/logoWhite.svg' />
            </Link>
            <div className={styles.menu}>
              <Link to={'/cadastro'} className={this.isUrlCadastro(URL) ? styles.active : null}><i className='material-icons' title={this.props.t("titles.forms")}>assignment</i></Link>
              <Link to={'/parametro'} className={URL === 'parametro' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.parameters")}>equalizer</i></Link>
              <Link to={'/apontamento'} className={URL === 'apontamento' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.notes")}>screen_share</i></Link>
              <Link to={'/flow-view'} className={URL === 'flow-view' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.flowView")}>settings_ethernet</i></Link>
              <Link to={'/oee'} className={URL === 'oee' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.oee")}>multiline_chart</i></Link>
              <Link to={'/paineis'} className={URL === 'paineis' ? styles.svgLinkActive : styles.svgLink}><svg className={URL === 'paineis' ? styles.svgIconActive : styles.svgIcon} title={this.props.t("titles.panels")} /></Link>
              <Link to={'/relatorio'} className={URL === 'relatorio' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.reports")}>insert_drive_file</i></Link>
              <Link to={'/dados-coleta'} className={URL === 'dados-coleta' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.dataCollection")}>wifi</i></Link>
              <LinkClientV2 to={'gestao-ritmo'} className={styles.aGestaoRitmo}><svg className={styles.imgGestaoDeRitmo}></svg></LinkClientV2>
              {/* <LinkClientV2 to={'insight-hub'} className={styles.aGestaoRitmo}><svg className={styles.imgTaelor}></svg></LinkClientV2> */}
            </div>
            <div className={styles.menuRight}>
              <MovideskTooltip />
              <h1>{localStorage.getItem('nome').split(' ')[0]}</h1>
              <IconMenu
                iconButtonElement={
                  <IconButton>
                    <i className='material-icons'>more_vert</i>
                  </IconButton>
                }
              >
                <MenuItem>
                  <LinkClientV2 to={'developers/logs'}>{this.props.t("titles.developers")}</LinkClientV2>
                </MenuItem>
                {localStorage.getItem('AD') === 'false' ?
                  <MenuItem
                    primaryText={this.props.t('titles.changePassword')}
                    onClick={this.props.onChangePasswordClick}
                  />
                  : null}
                <MenuItem
                  primaryText={this.props.t('labels.exit')}
                  onClick={this.logOut}
                  leftIcon={
                    <i className='material-icons'>power_settings_new</i>
                  }
                />
              </IconMenu>
            </div>
          </header>
        </div>
        <div className={styles.d_block_tablet}>
          <header className={styles.box_menu}>
            <Link to='/home'>
              <img
                src='../assets/logoShort.png'
                className={styles.logo__tablet}
              />
            </Link>
            <div className={styles.menu}>
              <Link to={'/flow-view'} className={URL === 'flow-view' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.flowView")}>settings_ethernet</i></Link>
              <Link to={'/oee'} className={URL === 'oee' ? styles.active : null}><i className='material-icons' title={this.props.t("titles.oee")}>multiline_chart</i></Link>
              <Link to={'/paineis'} className={URL === 'paineis' ? styles.svgLinkActive : styles.svgLink}><svg className={URL === 'paineis' ? styles.svgIconActive : styles.svgIcon} title={this.props.t("titles.panels")} /></Link>
            </div>
            <IconMenu
              iconButtonElement={
                <IconButton>
                  <i className={`material-icons ${styles.icon}`}>more_vert</i>
                </IconButton>
              }
            >
              <MenuItem
                primaryText={this.props.t("actions.logout")}
                onClick={this.logOut}
                leftIcon={<i className='material-icons'>power_settings_new</i>}
              />
            </IconMenu>
          </header>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Header)
