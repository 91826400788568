import React from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import ModalForm from '../../common/ModalForm';
import DeleteItem from '../../common/DeleteItem';
import SessionsList from './SessionsList';
import LayoutContainer from '../../viewer/LayoutContainer';
import { alertBox, NInputNumber } from '../../common/NForm';
import RaisedButton from 'material-ui/RaisedButton';
import { request, requestAll } from '../../common/request';
import { TextField } from 'material-ui';
import { respostaUnidadeIndustrial, respostaEquipamentos, requisicaoUnidadeIndustrial, respostaAreaProdutivas, respostaEtapas, respostaTipoLotes } from '../../common/mappers';
import MaskFieldTime from '../../utils/MaskFieldTime';
import MDSpinner from "react-md-spinner";
import { hasRole } from '../../helper/SecurityHelper';
import styles from './Sessions.scss'
import { withTranslation } from 'react-i18next';

const { Mask } = MaskFieldTime;

@observer
class SessionsPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      meta: [],
      idMetaDiaria: '',
      mtbf: '',
      mttr: '',
      wip: '',
      taxaProducao: '',
      leadTime: '',
      uuid: '',
      loadingPage: true,
    };

    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.save = this.save.bind(this);
    this.parametrizarMetasDiarias = this.parametrizarMetasDiarias.bind(this);
    this.buscarUnidadeIndustrial = this.buscarUnidadeIndustrial.bind(this);
    this.buscarDependencias = this.buscarDependencias.bind(this);
    this.handleCampo = this.handleCampo.bind(this);
    this.validarHora = this.validarHora.bind(this);
  }

  componentDidMount() {
    this.buscarDependencias();
  }

  buscarUnidadeIndustrial() {
    requestAll([
      {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial
      }
    ], this);
  }

  buscarDependencias() {
    requestAll([
      {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial
      },
      {
        url: this.endpoints.ETAPA,
        modelo: 'stages',
        modificador: respostaEtapas,
        acao: true
      },
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      },
      {
        url: this.endpoints.AREA_PRODUTIVA,
        modelo: 'fields',
        modificador: respostaAreaProdutivas,
        acao: true
      },
      {
        url: this.endpoints.TIPO_LOTE,
        modelo: 'batchTypes',
        modificador: respostaTipoLotes,
        acao: true
      },
    ], this);
  }

  delete(id) {
    request(this.endpoints.UNIDADE_INDUSTRIAL + '/' + id, {
      method: 'delete'
    })
      .then(response => {
        if (response.status == 200 || response.status == 201) {
          this.handleModal();
          this.buscarUnidadeIndustrial();
          alertBox(this.props.t('messages.deleteSuccess'), this.props.t('industrialUnit'), 'success');
        } else {
          alertBox(this.props.t('messages.deleteError'), this.props.t('industrialUnit'), 'error');
        }
      })
      .catch(() => {
        alertBox(this.props.t('messages.deleteError'), this.props.t('industrialUnit'), 'error');
      });
  }

  save() {
    let { idMetaDiaria, mtbf, mttr, wip, taxaProducao, leadTime, uuid } = this.state;
    let unidadeIndustrialId = this.state.field.id;
    let metaCompleta = {};
    metaCompleta.idMetaDiaria = idMetaDiaria;
    metaCompleta.mtbf = mtbf !== '' ? mtbf : '000:00';
    metaCompleta.mttr = mttr !== '' ? mttr : '000:00';
    metaCompleta.wip = wip !== '' ? wip.replace(',', '.') : 0;
    metaCompleta.taxaProducao = taxaProducao !== '' ? taxaProducao.replace(',', '.') : 0;
    metaCompleta.leadTime = leadTime !== '' ? leadTime.replace(',', '.') : 0;
    metaCompleta.unidade_industrial = { id: unidadeIndustrialId };

    request(this.endpoints.META_DIARIA + '/' + (uuid ? uuid : unidadeIndustrialId), {
      method: uuid ? 'put' : 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metaCompleta)
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve();
        }
        return Promise.reject(response);
      })
      .then(data => {
        this.setState({
          modal: false,
          idMetaDiaria: '',
          mtbf: '000:00',
          mttr: '000:00',
          wip: '0',
          taxaProducao: '0',
          leadTime: '0',
          unidade_industrial: { id: unidadeIndustrialId }
        });
      })
      .then(() => {
        alertBox(this.props.t('messages.dailyGoalCreateSuccess'), this.props.t('dailyGoal'), 'success');
      })
      .catch(error => {
        alertBox(this.props.t('messages.dailyGoalCreateError'), this.props.t('dailyGoal'), 'error');
      });
  }

  handleCampo(value, campo) {
    var valor = value.replace(/[^0-9\.\,]/g, '');
    var resultado = valor;
    if (valor.length <= 6) {
      var arrString = valor.toString().replace('.', ',').split(',');
      if (arrString[0].length <= 3) {
        resultado = valor;
      } else {
        resultado = arrString[0].substring(0, 3) + ',' + arrString[0].substring(3, 4);
      }
      this.setState({
        [campo]: resultado
      });
    }
  }

  validarHora(campo, value) {
    let test = value.split(":");
    if (!(parseInt(test[1]) < 60)) {
      value = '000:00';
      alertBox(this.props.t('messages.minutesLessThan60', { field: campo.toUpperCase() }), this.props.t('equipment'), 'error');
      this.setState({
        [campo]: value
      });
    }
  }

  parametrizarMetasDiarias() {
    let { mtbf, mttr, wip, taxaProducao, leadTime } = this.state;
    return (
      <div style={{ width: '100%', textAlign: 'center' }} className="modal">
        <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}> {this.props.t('dailyGoals')}</div>
        <div style={{ textAlign: 'center' }}>
          <div className={'flex flex-just-between'}>
            <TextField onChange={(event, value) => { this.setState({ mtbf: Mask(value) }) }} style={{ width: '20%', backgroundColor: '#fff' }}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }} onBlur={(event) => this.validarHora('mtbf', event.target.value)}
              underlineFocusStyle={{ borderColor: '#000000' }} id='MTBF' className={(styles.mtbf)} floatingLabelText={this.props.t('mtbf')} value={mtbf} />
            <span style={{ width: '10%', fontSize: '.6em', textAlign: 'initial', paddingTop: '45px', display: 'inline-block' }} > {this.props.t('hours')} </span>

            <NInputNumber onChange={(event, value) => { this.handleCampo(value, 'wip') }} style={{ width: '20%', backgroundColor: '#fff' }}
              type="numeric" underlineFocusStyle={{ borderColor: '#000000' }} label={this.props.t('wip')} value={wip} />
            <span style={{ width: '10%', fontSize: '.6em', textAlign: 'initial', paddingTop: '45px', display: 'inline-block' }} > {this.utils.getReferenciaLotesOrdens()} </span>

            <NInputNumber onChange={(event, value) => { this.handleCampo(value, 'leadTime') }} style={{ width: '20%', backgroundColor: '#fff' }}
              type="numeric" underlineFocusStyle={{ borderColor: '#000000' }} label={this.props.t('leadTime')} value={leadTime} />
            <span style={{ width: '10%', fontSize: '.6em', textAlign: 'initial', paddingTop: '45px', display: 'inline-block' }} > {this.props.t('days')} </span>
          </div>

          <div className={'flex flex-just-between'}>
            <TextField onChange={(event, value) => { this.setState({ mttr: Mask(value) }) }} style={{ width: '20%', backgroundColor: '#fff' }}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }} onBlur={(event) => this.validarHora('mttr', event.target.value)}
              underlineFocusStyle={{ borderColor: '#000000' }} id='MTTR' className={(styles.mttr)} floatingLabelText={this.props.t('mttr')} value={mttr} />
            <span style={{ width: '10%', fontSize: '.6em', textAlign: 'initial', paddingTop: '45px', display: 'inline-block' }} > {this.props.t('hours')} </span>

            <NInputNumber onChange={(event, value) => { this.handleCampo(value, 'taxaProducao') }} style={{ width: '40%', backgroundColor: '#fff' }}
              type="numeric" underlineFocusStyle={{ borderColor: '#000000' }} label={this.props.t('productionRate')} value={taxaProducao} />
            <span style={{ width: '10%', fontSize: '.6em', textAlign: 'initial', paddingTop: '45px', display: 'inline-block' }} > {this.utils.getReferenciaLotesOrdens()} </span>

            <div style={{ width: '12%' }} />
          </div>
        </div>
        <div style={{ display: 'flex', paddingTop: '10px', justifyContent: 'flex-end', marginRight: '1em' }} >
          <RaisedButton backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} labelColor='#FFF' onClick={() => this.save()} label={this.props.t('save')}
            disabled={!hasRole('ROLE_UNIDADES_INDUSTRIAIS_ATUALIZAR')} />

          <RaisedButton onClick={() => { this.setState({ modal: false, idMetaDiaria: '', mtbf: '00:00:00', mttr: '00:00:00', wip: '0', taxaProducao: '0', leadTime: '0' }) }} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '1em' }} label={this.props.t('cancel')} />
        </div>
      </div>
    );
  }

  handleModal(f, t, edit) {
    if (t == 'par') {
      let unidadeIndustrialId = f.id

      request(this.endpoints.META_DIARIA + '/' + unidadeIndustrialId, {
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          if (data != null) {
            this.setState({
              idMetaDiaria: data.id,
              mtbf: data.mtbf,
              mttr: data.mttr,
              wip: data.wip.toString().replace('.', ','),
              taxaProducao: data.taxaProducao.toString().replace('.', ','),
              leadTime: data.leadTime.toString().replace('.', ','),
              uuid: data.uuid
            })
          }
        });
    }
    this.setState({
      modal: !this.state.modal,
      field: f,
      typeModal: t,
      edit: edit
    });
  }

  contentModal() {
    if (this.state.typeModal === 'layout' || this.state.edit) {
      return <LayoutContainer session={this.state.field} handleModal={this.handleModal} editor={this.state.edit} aposSalvar={this.buscarUnidadeIndustrial} />
    } else {
      let fieldsOpts = this.store.fields.map((f) => {
        return { value: f.id, text: f.name };
      });

      let fields = [
        { name: 'name', title: this.props.t('name'), label: this.props.t('name'), type: 'text', required: true },
        { name: 'fk_id_fields', label: this.props.t('area'), type: 'select', required: true, options: fieldsOpts, value: '' }
      ];

      let msgModal = this.props.t('messages.questionDeleteUnit');

      if (this.state.typeModal == 'del') {
        return <DeleteItem {...this.state} delete={this.delete} handleModal={this.handleModal} msgModal={msgModal} />;
      } else if (this.state.typeModal == 'par') {
        return this.parametrizarMetasDiarias();
      }

      return <ModalForm model='sessions' title={!this.state.field ? this.props.t('createUnit') : this.props.t('editUnit')}
        url={this.endpoints.UNIDADE_INDUSTRIAL}
        aposSalvar={this.buscarUnidadeIndustrial}
        genero='F' modificador={requisicaoUnidadeIndustrial}
        action={this.action}
        endpoints={this.endpoints}
        utils={this.utils}
        field={this.state.field}
        fields={fields}
        handleModal={this.handleModal}
        showAlert={this.showAlert}
        tituloAlerta={this.props.t('industrialUnit')} />;
    }
  }

  render() {
    let stl = { padding: '10px', width: 450 };

    if (this.state.typeModal === 'layout' || this.state.edit) {
      stl.backgroundColor = '#FFF';
      stl.minWidth = '90%'
    }

    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={stl} contentModal={this.contentModal}>
          <SessionsList ref='list' store={this.store} handleModal={this.handleModal} showAlert={this.showAlert} t={this.props.t} />
        </Container>
      );
    }
  }
}

export default withTranslation('sessions')(SessionsPage);