import React , {Component} from 'react'
import { Toolbar, ToolbarGroup} from 'material-ui/Toolbar';
import DropDownMenu from 'material-ui/DropDownMenu';
import { IF } from '../NForm'
import MenuItem from 'material-ui/MenuItem';
import styles from './abas.scss';


class AbaContent extends Component {
    constructor(props){
        super(props)
        this.state = {
            itensMapped: [],
            selected: props.selected
        }
        
        this.mapItens = this.mapItens.bind(this);
        this.setMapitens = this.setMapitens.bind(this);
        this.buildItems = this.buildItems.bind(this);
    }
    
    buildItems(item, index, items) {
        return <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={items.length - 1 === index ? item.title.split(" ")[0] : item.title}
                />;
    }

    mapItens = (itens) => itens.map((item, index, items) => this.buildItems(item, index, items)) || <MenuItem value={24081995} primaryText={'Carregando...'} />
    
    handleChange = (event, index, value) =>  {
        if (this.state.selected !== value) {
            if (this.props.validarRotas === undefined) {
                this.setState({ selected: value });
                this.props.customFunctionOnChange(value);
                return;
            }

            if (this.props.validarRotas() && this.props.etapaIntegradaValida) {
                this.setState({ selected: value });
                this.props.alertaApagarAlteracoesRotas(value);
            } else {
                this.props.errorMessages.forEach((msgParams) => {
                    if (msgParams) {
                        this.props.showAlert(msgParams[0], msgParams[1], msgParams[2]);
                    }
                })
            }
        }
    };

    setMapitens = () => this.setState({itensMapped: this.props.itens})

    componentDidMount() {
        this.setMapitens();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.itens != this.props.itens) {
            this.setMapitens()
        }

        if (prevProps.selected != this.props.selected){
            this.setState({ selected: this.props.selected }) 
        }
    }
   
    render() {
        let handleChange = (this.props.onChange ? this.props.onChange : this.handleChange)

        return (
            <li className={`${this.props.className || ""} abaContent`}>
                <Toolbar className={`${this.props.toolbarClassName || ""} toolBarTimeMachine`}>
                    <img src={this.props.icon}
                        className={`${this.props.iconClassName || ""} timeMachineCalendar`}
                    />

                    <ToolbarGroup firstChild={true} className='iconStyle'>
                        <DropDownMenu
                            value={this.state.selected || 24081995}
                            onChange={handleChange} 
                            className={`${this.props.downDownClassName} toolBarDropDown`}>
                            { this.mapItens(this.state.itensMapped) }
                        </DropDownMenu>
                    </ToolbarGroup>

                    <IF test={this.props.edit && this.props.predicate}>
                        <ToolbarGroup style={{height:30, width:30}}>
                            <i className={`${this.props.editIcon || ""} editIcon`} onClick={this.props.predicate} />
                        </ToolbarGroup>
                    </IF>
                </Toolbar>
            </li>
        )
    }
}

export default AbaContent