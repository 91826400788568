import React, { Component } from 'react';
import Grid from '../Grid/grid';
import Row from '../Grid/row';
import Container from '../Grid/container';
import ModalBackGround from './ModalBackGround'
import { IF } from '../NForm'
import { NFormTitle } from '../NForm';
import styles from './modal.scss';

class ModalContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ModalBackGround>
                <Container bgClassName={this.props.bgClassName}>
                    <NFormTitle>{this.props.title}</NFormTitle>
                    <IF test={this.props.splitScreen} >
                        <Row>
                            <Grid cols='4 4 4'>
                                {this.props.left}
                            </Grid>
                            <Grid cols='8 8 8'>
                                {this.props.right}
                            </Grid>
                        </Row>
                    </IF>
                    <IF test={!this.props.splitScreen}>
                        <Row>
                            <Grid cols='12 12 12'>
                                {this.props.children}
                            </Grid>
                        </Row>
                    </IF>
                </Container>
            </ModalBackGround>
        )
    }
}
export default ModalContainer