import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
export default class Header extends Component {
  render() {
    let { handleModal, session } = this.props;
    return (
      <div className="header_content">
        <div className="header_content__title">
          <h3>
            {session.name}
          </h3>
        </div>
        <div className="header_content__filter">
          <div style={{ float: 'right', cursor: 'pointer' }}>
            {!this.props.editor ? <i className='fa fa-close' onClick={handleModal} /> : null}
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}
