import React from 'react';

const IconWarningNotFound = ({ color }) => {
  return (
    <svg
      width='58'
      height='62'
      viewBox='0 0 58 62'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M46.4931 15.0642C48.9961 15.0642 51.0252 13.0351 51.0252 10.5321C51.0252 8.0291 48.9961 6 46.4931 6C43.99 6 41.9609 8.0291 41.9609 10.5321C41.9609 13.0351 43.99 15.0642 46.4931 15.0642Z'
        fill={color || '#D6D6D6'}
      />
      <path
        d='M52.3443 17.0403C50.45 17.0403 44.1019 17.0403 42.2376 17.0403L37.449 16.4805L40.0267 13.3836C40.7992 12.4554 40.6731 11.0765 39.7449 10.3038C38.8165 9.53131 37.4378 9.65758 36.6653 10.5856L31.506 16.7849C30.9927 17.4016 30.8584 18.2499 31.1561 18.9952C31.4538 19.7402 32.1358 20.2623 32.9326 20.3558L40.648 21.2579L40.649 58.9707C40.649 60.42 41.8238 61.5947 43.273 61.5947C44.7222 61.5947 45.8971 60.42 45.8971 58.9707V37.9963H47.0301V58.9707C47.0301 60.42 48.2049 61.5947 49.6541 61.5947C51.1033 61.5947 52.2782 60.42 52.2782 58.9707L52.2316 22.2279C52.2314 21.9783 52.4311 21.7747 52.6805 21.7702C52.9301 21.7657 53.1372 21.9616 53.1459 22.211L53.2266 38.2763C53.2327 39.4802 54.2104 40.452 55.4131 40.452C55.4167 40.452 55.4206 40.4518 55.4243 40.4518C56.632 40.4457 57.6059 39.4617 57.6 38.2541L57.5193 22.189C57.5048 19.35 55.1834 17.0403 52.3443 17.0403Z'
        fill={color || '#D6D6D6'}
      />
      <path d='M58 8.59473H56V10.5947H58V8.59473Z' fill={color || '#D6D6D6'} />
      <path
        d='M15.28 28.5882L14.8 26H4V59H6L6.625 34.963H13L14.5 38.2222H22V28.5882H15.28Z'
        fill={color || '#D6D6D6'}
      />
      <rect y='59' width='11' height='2' fill={color || '#D6D6D6'} />
      <rect x='56' width='2' height='7' fill={color || '#D6D6D6'} />
    </svg>
  );
};

export default IconWarningNotFound;
