import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FilterBox from '../../common/FilterBox';
import { hasRole } from '../../helper/SecurityHelper';

@observer
export default class SessionsList extends Component {
  constructor() {
    super();
    this.onFilter = this.onFilter.bind(this);
  }

  componentWillMount() {
    this.props.store.searchModel = 'sessions';
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }

  onFilter(val) {
    this.props.store.searchValue = val;
  }

  render() {
    let items = this.props.store.searchItems.map((item, k) => {
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td onClick={item.stages && item.stages.length > 0 ? () => this.props.handleModal(item, 'layout', false) : () => { }}>
            <span className={item.stages && item.stages.length > 0 ? 'primary' : ''}>{item.name}</span>
          </td>
          <td>{item ? item.nome_area : null}</td>
          <td>
            <div className='options'>
              <i className='material-icons' title={this.props.t('dailyGoals')} onClick={() => this.props.handleModal(item, 'par')}>poll</i>
              <i className='material-icons' title={this.props.t('edit')}
                onClick={() => hasRole('ROLE_UNIDADES_INDUSTRIAIS_ATUALIZAR') ? this.props.handleModal(item, 'up', true) : this.props.handleModal(item, 'layout', false)}>edit</i>
              <i className='material-icons' title={this.props.t('delete')} onClick={() => hasRole('ROLE_UNIDADES_INDUSTRIAIS_EXCLUIR') && this.props.handleModal(item, 'del')}>cancel</i>
            </div>
          </td>
        </tr>
      )
    });
    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_UNIDADES_INDUSTRIAIS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('industrialUnits')} />
            <CardText className='table_overflow'>
              <FilterBox onFilter={this.onFilter} />
              <table className='mui-table mui-table--bordered'>
                <thead>
                  <tr>
                    <td className='id'>#</td>
                    <td className='name'>{this.props.t('name')}</td>
                    <td className='areas'>{this.props.t('area')}</td>
                    <td className='actions'>{this.props.t('actions')}</td>
                  </tr>
                </thead>
                <tbody className='box_items'>
                  {items}
                </tbody>
              </table>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_UNIDADES_INDUSTRIAIS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('industrialUnits')} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t('noRecordsFound')}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}