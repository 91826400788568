import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Modal from 'common/CommonModal';
import Dropzone from '../../components/Dropzone';
import { requestFile } from '../../common/request';
import { APIEndpoints } from '../../common/Constants';
import { useFormik, Formik } from 'formik';
import { RaisedButton } from 'material-ui';
import i18n from '../../utils/i18n';
import '../../components/Dropzone/dropzone.scss';
import './uploadModal.scss';

const UPLOAD_DEFAULT = {
    files: []
};

const UploadModal = ({
    open,
    onCancel: cancel,
    onSuccess: success
}) => {
    const [show, setShow] = useState(false);
    const [isUploadingFile, setUploading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [response, setResponse] = useState(undefined);

    const {
        values,
        setValues,
        setFieldValue,
        handleReset,
        handleSubmit,
        submitForm
    } = useFormik({
        initialValues: { ...UPLOAD_DEFAULT },
        validationSchema: Yup.object().shape({
            files: Yup.array()
        }),
        onSubmit: (data) => {
            const fileUpload = {
                file: data.files[0]
            };

            const url = `${APIEndpoints.UPLOAD}/product`;

            const formData = new FormData();
            formData.append('file', fileUpload.file);

            setUploading(true);

            requestFile(url, {
                method: 'post',
                body: formData
            }).then(response => {
                return response.json();
            }).then(data => {
                if (data.status == "OK") {
                    setUploadSuccess(true);
                    setResponse(data);
                }
                else {
                    setErrors(data?.errors || [{ reason: i18n.t('messages.unknownError', { ns: 'common' }) }]);
                }
            }).catch((error) => {
                setErrors(error?.errors || [{ reason: i18n.t('messages.unknownError', { ns: 'common' }) }]);
            }).finally(() => {
                setUploading(false);
            });
        }
    });

    useEffect(() => {
        if (!open) {
            return;
        }

        setShow(open);
        setValues({ ...UPLOAD_DEFAULT });
    }, [
        open,
        setValues,
        setFieldValue,
        cancel
    ]);

    useEffect(() => {
        if (values.files.length > 0) {
            submitForm();
        } else {
            setErrors([]);
        }
    }, [values.files, submitForm]);

    const onCancel = () => {
        handleReset({});
        setErrors([]);
        setShow(false);
        setUploadSuccess(false);
        cancel();
    };

    const onSuccess = () => {
        handleReset({});
        setErrors([]);
        setShow(false);
        setUploadSuccess(false);
        success(response);
    };

    const renderDropzone = () => {
        return (
            <Dropzone
                loadingMessage={i18n.t('uploadModal.validatingFile', { ns: 'components' })}
                multiple={false}
                setFieldValue={setFieldValue}
                className={isUploadingFile ? "is-uploading-file" : ''}
                loading={isUploadingFile}
            />
        )
    };

    const renderErrors = () => {
        return (
            <div className="upload-errors">
                <div className="title">{i18n.t('uploadModal.errorsTitle', { ns: 'components' })}</div>
                <ul>
                    {
                        errors.map((error, index) => (
                            <li><i className="error-badge">{index + 1}</i>{error.detail || error.reason}</li>
                        ))
                    }
                </ul>
            </div>
        )
    };

    return (
        <Modal>
            <div style={{ width: '25%' }} className="modal">
                <div style={{ padding: '10px', fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
                    {i18n.t('uploadModal.productsUpload', { ns: 'components' })}
                </div>
                <Formik onSubmit={(event) => handleSubmit(event)}>
                    <>
                        {!uploadSuccess && renderDropzone()}

                        {errors.length > 0 && renderErrors()}

                        {uploadSuccess &&
                            <div className="upload-success">
                                <span class="material-icons"
                                    style={{ color: '#7ac142', display: 'flex', justifyContent: 'center', fontSize: '60px' }}>check_circle</span>
                                <div className="success-message">{i18n.t('uploadModal.uploadSuccess', { ns: 'components' })}</div>
                            </div>
                        }
                        <div style={{ paddingTop: '1em' }} className={'flex flex-just-end'}>
                            <RaisedButton
                                onClick={onCancel}
                                backgroundColor='#FFF'
                                buttonStyle={{ fontFamily: '' }}
                                style={{ fontWeight: 100 }}
                                label={i18n.t('labels.close', { ns: 'common' })} />
                        </div>
                    </>
                </Formik>
            </div>
        </Modal>
    );
};

export default UploadModal