import React from 'react';
import { Link } from 'react-router-dom';
import Container from './Container';
import styles from './home.scss';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import { Card } from 'material-ui';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import MDSpinner from 'react-md-spinner';
import moment from 'moment';
import { request } from './request';
import { hasRole } from '../helper/SecurityHelper';
import LinkClientV2 from './LinkClientV2';

moment.locale('pt-br');

import logoImg from '../assets/logo.svg';
import iconePaineis from '../assets/icones-paineis/iconePaineis.svg';
import iconePMP from '../assets/icones-paineis/icone_pmp.svg';
import iconeTaelor from '../assets/icones-paineis/icone_taelor.svg';
import iconeTextTaelor from '../assets/icones-paineis/text_taelor.svg';
import titleTaelor from '../assets/icones-paineis/titleTaelor.svg';
import { observer } from 'mobx-react';
import Store from '../common/Store';
import { withTranslation } from 'react-i18next';
import UpdatePasswordModal from 'components/UpdatePasswordModal';
import Modal from './CommonModal';
import { APIEndpoints, TAELOR_URL } from './Constants';
import { Mixpanel } from 'utils/Mixpanel';

@observer
class Home extends Container {
  constructor() {
    super();
    this.state = {
      loadingPage: true,
      permissoes: {},
      movideskLoaded: false,
      delightedLoaded: false,
      thirdPartyScriptsLoaded: false,
      isModalOpen: false,
      taelorToken: ''
    };
    this.store = Store;

    this.logOut = this.logOut.bind(this);
    this.showSurvey = this.showSurvey.bind(this);
    this.loadDelighted = this.loadDelighted.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.getNewTaelorToken = this.getNewTaelorToken.bind(this);
  }

  componentWillMount() {
    request(this.endpoints.SGCA_AUTENTICAR_CATEGORIA, {
      method: 'get'
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status === 401) {
            localStorage.clear();
            window.location = '/';
          }
        }
      })
      .then(data => {
        this.setState({
          loadingPage: false,
          permissoes: data.reduce((acc, p) => {
            acc[p.uuid] = true;
            return acc;
          }, {})
        });
      });
  }

  componentDidMount() {
    this.loadCurrentUser();

    let taelorToken = localStorage.getItem('taelor-token')

    if (taelorToken === 'undefined') {
      taelorToken = undefined;
    }

    // if (taelorToken && taelorToken !== '') {
    //   this.setState({ taelorToken: taelorToken })
    // }
    // else {
      this.getNewTaelorToken(taelorToken);
    // }

    this.loadDelighted();

    if (!window?.movideskLogin) {
      const scriptClient = document.createElement("script");
      scriptClient.src = "https://chat.movidesk.com/Scripts/chat-widget.min.js";
      scriptClient.async = true;
      scriptClient.onload = () => this.setState({ movideskLoaded: true });

      document.body.appendChild(scriptClient);
    }
  }

  loadDelighted() {
    if (!window['delighted']) {

      const delightedAttrs = ["survey", "reset", "config", "init", "set", "get", "event", "identify", "track", "page", "screen", "group", "alias"];

      for (var a = (window['delighted'] = []), i = 0; i < delightedAttrs.length; i++) {
        var attr = delightedAttrs[i];

        a[attr] = a[attr] ||
          (function (e) {
            return function () {
              a.push([e, Array.prototype.slice.call(arguments)]);
            };
          })(attr);
      }

      window['delighted'].SNIPPET_VERSION = "1.0.1";

      const script = document.createElement("script");
      script.type = "text/javascript"
      script.src = "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" + "M0nmrTDh7hwJ2KMI" + "/" + "delighted.js"
      script.async = true;
      script.onload = () => this.setState({ delightedLoaded: true });

      document.body.appendChild(script);
    }
  }

  logOut() {
    this.action.logout(this.endpoints.SGCA_DESLOGAR);
  }

  showSurvey() {
    let shouldLoadNPS = localStorage.getItem('FeatureNPS') === '1';

    if (shouldLoadNPS) {
      const tenant = localStorage.getItem('tenant');
      window['delighted'].survey({
        email: localStorage.getItem('email'),
        name: localStorage.getItem('nome'),
        createdAt: localStorage.getItem('primeiro_login'),
        properties: {
          userId: tenant + localStorage.getItem('id'),
          company: tenant,
          locale: localStorage.getItem('Language') || 'pt-BR'
        }
      });
    }
  }

  loadCurrentUser() {
    return request(APIEndpoints.SGCA_USUARIO + '/current', { method: 'get' })
      .then(response => {
        if (response.status === 200) return response.json();
      })
      .then(user => {
        Mixpanel.identify(user.uuid);
        Mixpanel.people.set({
          tenant: user?.tenant || '',
          email: user?.email || '',
          name: user?.nome || ''
        });
        this.store.currentUser = user;
      });
  }

  getNewTaelorToken(token) {
    let url = APIEndpoints.SGCA_AUTENTICAR_TAELOR;

    if (token && token !== "" && token !== "undefined") {
      url += `?token=${token}`;
    }

    request(url, { method: 'get' })
      .then(response => {
        if (response.status === 403) return "";
        if (response.status === 200) return response.text();
      })
      .then(token => { 
        localStorage.setItem('taelor-token', token); 
        this.setState({ taelorToken: token });
      });
  }

  render() {
    const { permissoes } = this.state;
    let shouldLoadMovidesk = localStorage.getItem('FeatureChat') === '1';

    if (this.store.movideskChat && shouldLoadMovidesk) {
      this.store.movideskChat.style.visibility = 'visible';
    }

    let hour = moment().format('HH');
    let saudation =
      hour > 12 && hour < 18
        ? this.props.t("messages.goodAfternoon")
        : hour >= 18
          ? this.props.t("messages.goodEvening")
          : this.props.t("messages.goodMorning");

    const { loadingPage } = this.state;

    if (loadingPage) {
      return (
        <div className={'loading-wrapper'}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>
      );
    } else {
      if (window['delighted']) {
        this.showSurvey();
      }

      return (
        <div className={styles.responsive_scroll}>
          <div className={styles.d_none_tablet}>
            <Row className={styles.appBar}>
              <Col
                md='9'
                xs='12'
                sm='12'
                md-offset='1'
                className={styles.appContent}
              >
                <h1 style={{ color: '#FFF' }}>
                  {saudation}, {localStorage.getItem('nome').split(' ')[0]}!
                </h1>
              </Col>
              <Col md='1'>
                <i
                  className='material-icons'
                  style={{ fontSize: 100, color: '#FFF' }}
                >
                  account_circle
                </i>
              </Col>
              <div
                style={{
                  marginLeft: '150px',
                  marginBottom: '57px',
                  marginRight: '-5px'
                }}
              >
                <IconMenu
                  iconButtonElement={
                    <IconButton>
                      <i className={styles.white + ' material-icons'}>
                        more_vert
                      </i>
                    </IconButton>
                  }
                >
                  <MenuItem>
                    <LinkClientV2 to={'developers/logs'}>{this.props.t("titles.developers")}</LinkClientV2>
                  </MenuItem>
                  {localStorage.getItem('AD') === 'false' ?
                    <MenuItem
                      primaryText={this.props.t('titles.changePassword')}
                      onClick={() => this.setState({ isModalOpen: true })}
                    />
                    : null}
                  <MenuItem
                    primaryText={this.props.t("actions.logout")}
                    onClick={this.logOut}
                    leftIcon={
                      <i className='material-icons'>power_settings_new</i>
                    }
                  />
                </IconMenu>
              </div>
            </Row>
          </div>
          <div className={`${styles.d_block_tablet} ${styles.appBar}`}>
            <div className={styles.card__flex__tablet}>
              <img
                src='./../assets/logoWhite.svg'
                className={`${styles.appBar__img} ${styles.appBar__img__tablet}`}
              />
              <img
                src='./../assets/logoShort.png'
                className={`${styles.appBar__img} ${styles.appBar__img__mobile}`}
              />

              <h5>
                {saudation}, {localStorage.getItem('nome').split(' ')[0]}!
              </h5>
            </div>
            <div style={{}}>
              <IconMenu
                iconButtonElement={
                  <IconButton>
                    <i className={styles.white + ' material-icons'}>
                      more_vert
                    </i>
                  </IconButton>
                }
              >
                <MenuItem
                  primaryText='Sair'
                  onClick={this.logOut}
                  leftIcon={
                    <i className='material-icons'>power_settings_new</i>
                  }
                />
              </IconMenu>
            </div>
          </div>
          <div className={`${styles.d_block_tablet}`}>
            <Row>
              <Col xs='12' sm='12' className={styles.card__flex}>
                <Link
                  to={
                    !permissoes['1b9758f8-1108-47a2-9d6e-29cf549ef406']
                      ? '#'
                      : '/flow-view'
                  }
                  className={
                    permissoes['1b9758f8-1108-47a2-9d6e-29cf549ef406']
                      ? styles.nav
                      : styles.disabled
                  }
                >
                  <Card
                    className={
                      (!permissoes['1b9758f8-1108-47a2-9d6e-29cf549ef406']
                        ? styles.disabled + ' '
                        : '') +
                      styles.option +
                      ' ' +
                      styles.mobile__card
                    }
                  >
                    <div className={styles.mobile__card__content}>
                      <div className={styles.mobile__card__icon}>
                        <i className='material-icons'>settings_ethernet</i>
                      </div>
                      <div className={styles.mobile__card__title}>
                        {this.props.t("titles.flowView")}
                      </div>
                    </div>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='12' className={styles.card__flex}>
                <Link
                  to={
                    !permissoes['de2186d6-f368-4803-b04c-f840f9f5c751']
                      ? '#'
                      : '/oee'
                  }
                  className={
                    permissoes['de2186d6-f368-4803-b04c-f840f9f5c751']
                      ? styles.nav
                      : styles.disabled
                  }
                >
                  <Card
                    className={
                      (!permissoes['de2186d6-f368-4803-b04c-f840f9f5c751']
                        ? styles.disabled + ' '
                        : '') +
                      styles.option +
                      ' ' +
                      styles.mobile__card
                    }
                  >
                    <div className={styles.mobile__card__content}>
                      <div className={styles.mobile__card__icon}>
                        <i className='material-icons'>multiline_chart</i>
                      </div>
                      <div className={styles.mobile__card__title}>{this.props.t("titles.oee")}</div>
                    </div>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='12' className={styles.card__flex}>
                <Link to={!permissoes['aa7db7f4-0d78-4570-a7c4-1b76be49f147'] ? '#' : '/paineis'} className={permissoes['aa7db7f4-0d78-4570-a7c4-1b76be49f147'] ? styles.nav : styles.disabled}>
                  <Card className={(!permissoes['aa7db7f4-0d78-4570-a7c4-1b76be49f147'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.mobile__card}>
                    <div className={styles.mobile__card__content}>
                      <div className={styles.mobile__card__icon}>
                        <img className={styles.iconPainel} src={iconePaineis} />
                      </div>
                      <div className={styles.mobile__card__title}>{this.props.t("titles.panels")}</div>
                    </div>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='12' className={styles.card__flex}>
                <Card className={(!hasRole('ROLE_GESTAO_RITMO_VISUALIZAR') ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.mobile__card}>
                  <LinkClientV2 to={'gestao-ritmo'}>
                    <div className={styles.mobile__card__content}>
                      <div className={styles.mobile__card__icon}>
                        <img src={iconePMP} className={styles.svg} />
                      </div>
                      <div className={styles.mobile__card__title}>{this.props.t("titles.rhythmProjection")}</div>
                    </div>
                  </LinkClientV2>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs='12' sm='12' className={styles.card__flex}>
                <Card className={(!hasRole('ROLE_INSIGHT_HUB_VISUALIZAR') ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.mobile__card}>
                  <a
                    href={`${TAELOR_URL}?token=${this.state.taelorToken}`} target="_blank"
                    className={!hasRole('ROLE_INSIGHT_HUB_VISUALIZAR') ? styles.disabled : styles.nav}>

                    <div className={styles.mobile__card__content}>
                      <div className={styles.mobile__card__icon}>
                        <div className={styles.icon} style={{ display: 'flex', flexDirection: 'column' }}>
                          <img
                            src={iconeTaelor}
                            className={styles.svg}
                            style={{ width: 60 }}
                          />
                          <img
                            src={iconeTextTaelor}
                            className={styles.svg}
                            style={{ width: 60 }}
                          />
                        </div>
                      </div>
                      <div className={styles.mobile__card__title}><img src={titleTaelor} className={styles.svg} /></div>
                    </div>
                    {/* 
                    <div className={styles.title}>
                      <img src={titleTaelor} className={styles.svg} />
                    </div>
                    <div className={styles.icon} style={{ display: 'flex', flexDirection: 'column' }}>
                      <img
                        src={iconeTaelor}
                        className={styles.svg}
                        style={{ width: 110 }}
                      />
                      <img
                        src={iconeTextTaelor}
                        className={styles.svg}
                        style={{ width: 110 }}
                      />
                    </div> */}
                  </a>
                </Card>
              </Col>
            </Row>
          </div>
          <Row>
            <Col
              md='12'
              className={`${styles.appQuestion} ${styles.d_none_tablet}`}
            >
              <h1>{this.props.t("messages.whatDoYouWant")}</h1>
            </Col>
          </Row>
          <div className={styles.d_none_tablet}>
            <Row className={`${styles.options}`}>
              <Col>
                <Row>
                  <Col md='6' style={{ padding: 0 }}>
                    <Link to={!permissoes['6a1702c4-f65f-43e6-8c53-7d48c4a3911e'] ? '#' : '/cadastro'} className={permissoes['6a1702c4-f65f-43e6-8c53-7d48c4a3911e'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['6a1702c4-f65f-43e6-8c53-7d48c4a3911e'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.oneQuarter}>
                        <div className={styles.title}>{this.props.t("titles.forms")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>assignment</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                  <Col md='6' style={{ padding: 0 }}>
                    <Link to={!permissoes['d41f2cb2-717b-43cd-bde6-14af7154fc60'] ? '#' : '/parametro'} className={permissoes['d41f2cb2-717b-43cd-bde6-14af7154fc60'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['d41f2cb2-717b-43cd-bde6-14af7154fc60'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.oneQuarter}>
                        <div className={styles.title}>{this.props.t("titles.parameters")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>equalizer</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Link to={!permissoes['064e48bf-a37d-4563-9cb6-7229af02f544'] ? '#' : '/apontamento'} className={permissoes['064e48bf-a37d-4563-9cb6-7229af02f544'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['064e48bf-a37d-4563-9cb6-7229af02f544'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.half}>
                        <div className={styles.title}>{this.props.t("titles.notes")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>screen_share</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Link to={!permissoes['1b9758f8-1108-47a2-9d6e-29cf549ef406'] ? '#' : '/flow-view'} className={permissoes['1b9758f8-1108-47a2-9d6e-29cf549ef406'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['1b9758f8-1108-47a2-9d6e-29cf549ef406'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.halfForIconLarge}>
                        <div className={styles.title}>{this.props.t("titles.flowView")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>settings_ethernet</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col md='6' style={{ padding: 0 }}>
                    <Link to={!permissoes['87e50c6e-8823-46fe-8a15-df400f9ea164'] ? '#' : '/dados-coleta'} className={permissoes['87e50c6e-8823-46fe-8a15-df400f9ea164'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['87e50c6e-8823-46fe-8a15-df400f9ea164'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.oneQuarter}>
                        <div className={styles.title}>{this.props.t("titles.dataCollection")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>wifi</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                  <Col md='6' style={{ padding: 0 }}>
                    <Link to={!permissoes['2e3b72ce-59e5-4749-a522-e5c367ee3b26'] ? '#' : '/relatorio'} className={permissoes['2e3b72ce-59e5-4749-a522-e5c367ee3b26'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['2e3b72ce-59e5-4749-a522-e5c367ee3b26'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.oneQuarter}>
                        <div className={styles.title}>{this.props.t("titles.reports")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>insert_drive_file</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Link to={!permissoes['de2186d6-f368-4803-b04c-f840f9f5c751'] ? '#' : '/oee'} className={permissoes['de2186d6-f368-4803-b04c-f840f9f5c751'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['de2186d6-f368-4803-b04c-f840f9f5c751'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.halfForIconLarge}>
                        <div className={styles.title}>{this.props.t("titles.oee")}</div>
                        <div className={styles.icon}>
                          <i className='material-icons'>multiline_chart</i>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col md='6' style={{ padding: 0 }}>
                    <Link to={!permissoes['aa7db7f4-0d78-4570-a7c4-1b76be49f147'] ? '#' : '/paineis'} className={permissoes['aa7db7f4-0d78-4570-a7c4-1b76be49f147'] ? styles.nav : styles.disabled}>
                      <Card className={(!permissoes['aa7db7f4-0d78-4570-a7c4-1b76be49f147'] ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.oneQuarter}>
                        <div className={styles.title}>{this.props.t("titles.panels")}</div>
                        <div className={styles.icon}>
                          <img src={iconePaineis} className={styles.svg} />
                        </div>
                      </Card>
                    </Link>
                  </Col>
                  <Col md='6' style={{ padding: 0 }}>
                    <Card className={(!hasRole('ROLE_GESTAO_RITMO_VISUALIZAR') ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.oneQuarter}>
                      <LinkClientV2
                        to={!hasRole('ROLE_GESTAO_RITMO_VISUALIZAR') ? '#' : 'gestao-ritmo'}
                        className={!hasRole('ROLE_GESTAO_RITMO_VISUALIZAR') ? styles.disabled : styles.nav}
                      >
                        <div className={styles.title}>{this.props.t("titles.rhythmProjection")}</div>
                        <div className={styles.icon}>
                          <img src={iconePMP} className={styles.svg} />
                        </div>
                      </LinkClientV2>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Card className={(!hasRole('ROLE_INSIGHT_HUB_VISUALIZAR') ? styles.disabled + ' ' : '') + styles.option + ' ' + styles.one}>
                  <a
                    href={`${TAELOR_URL}?token=${this.state.taelorToken}`} target="_blank"
                    className={!hasRole('ROLE_INSIGHT_HUB_VISUALIZAR') ? styles.disabled : styles.nav}>
                    <div className={styles.title}>
                      <img src={titleTaelor} className={styles.svg} />
                    </div>
                    <div className={styles.icon} style={{ display: 'flex', flexDirection: 'column' }}>
                      <img
                        src={iconeTaelor}
                        className={styles.svg}
                        style={{ width: 110 }}
                      />
                      <img
                        src={iconeTextTaelor}
                        className={styles.svg}
                        style={{ width: 110 }}
                      />
                    </div>
                  </a>
                  {/* <LinkClientV2
                    to={!hasRole('ROLE_INSIGHT_HUB_VISUALIZAR') ? '#' : 'insight-hub'}
                    className={!hasRole('ROLE_INSIGHT_HUB_VISUALIZAR') ? styles.disabled : styles.nav}>
                    <div className={styles.title}>
                      <img src={titleTaelor} className={styles.svg} />
                    </div>
                    <div className={styles.icon} style={{ display: 'flex', flexDirection: 'column' }}>
                      <img
                        src={iconeTaelor}
                        className={styles.svg}
                        style={{ width: 110 }}
                      />
                      <img
                        src={iconeTextTaelor}
                        className={styles.svg}
                        style={{ width: 110 }}
                      />
                    </div>
                  </LinkClientV2> */}
                </Card>
              </Col>
            </Row>
          </div>
          <Row className={`${styles.footer}  ${styles.d_none_tablet}`}>
            <Col md='2'>
              <a
                href='http://www.cogtive.com.br'
                target='_blank'
                title='Cogtive'
              >
                <img
                  src={logoImg}
                  height='30px'
                  className='cogtive-footer-logo'
                />
              </a>
            </Col>
          </Row>

          {this.state.isModalOpen || this.store.currentUser?.senhaExpirada ?
            <Modal className={'box_modal'}>
              <UpdatePasswordModal
                currentUser
                onSuccess={this.loadCurrentUser}
                closeModal={() => this.setState({ isModalOpen: false })} />
            </Modal>
            : null
          }

        </div>
      );
    }
  }
}

export default withTranslation("common")(Home);
