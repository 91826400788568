import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import './CustomCard.css';

export default class CustomCard extends Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    return (
      <div className={"custom-card " + (this.props.className || '')} style={this.props.style} >
        <div className="card-header" >
          {this.props.header}
          {
            this.props.tooltip ?
            (
              <i className="material-icons tooltip-wrapper" data-tip={this.props.tooltip} data-for={'card'} data-html={true}>help</i>
            ) : ''
          }
        </div>
        <div className={`card-content ${this.props.contentClassName || ''}`} style={this.props.contentStyle} id={this.props.id}>
          {this.props.children}
        </div>
        
        <ReactTooltip
          id='card'
          html={true} 
          effect={'solid'} />
      </div>
    );
  }
}