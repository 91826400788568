import React from 'react';
import AbstractComponent from '../AbstractComponent/AbstractComponent';
import { HorizontalBar } from 'react-chartjs-2';
import { blueGradient } from '../Helpers/ChartJsHelper';

const legendOpts = {
    display: false
};

class ParetoBarChart extends AbstractComponent {
    constructor(props) {
        super(props);
        this.state = { width: 0 };
    }
    
    formatOptions(data, height) {
        return {
            forceHeight: height,
            halfCornerRadius: true,
            animation: false,
            responsive: false,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    right: 75,
                    top: 10,
                    left: 0
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMin: 0
                    },
                    gridLines: {
                        display: false,
                        lineWidth: 0,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                }],
                yAxes: [
                    {
                        categoryPercentage: .6,
                        // barPercentage: .4,
                        scaleLabel: {
                            display: true
                        },
                        gridLines: {
                            display: false,
                            drawBorder: true
                        },
                        ticks: {
                            display: false
                        }
                    }
                ]
            },
            horizontalBarLabel: {
                display: true,
                marginLeft: 25,
                fontSize: 13
            },
            horizontalBarDataLabel: {
                display: true,
                fontSize: 12,
                paddingLeft: 5,
                align: 'start',
                formatter: (value, i, dataset) => (dataset.horas ? dataset.horas[i] + 'h' : '') + ' | ' + value + '%'
            },
            tooltips: {
                displayColors: false,
                mode: 'index',
                intersect: false,
                enabled: false
            },
            title: {
                position: 'top'
            }
        };
    }

    formatData(data, canvas) {
        let ctx = canvas.getContext('2d');
        let azulEscuro = ctx.createLinearGradient(0, canvas.clientHeight, canvas.clientWidth, canvas.clientHeight / 2);
        azulEscuro.addColorStop(0.000, 'rgba(43, 70, 102, 1.000)');
        azulEscuro.addColorStop(1, 'rgba(105, 139, 178, 1.000)');

        return {
            labels: this.pluck2Args(data, "codigoEvento", "evento"),
            datasets: [{
                backgroundColor: azulEscuro,
                hoverBackgroundColor: azulEscuro,
                borderWidth: 0,
                data: this.pluck(data, "porcentagem"),
                horas: this.pluck(data, "horas")
            }]
        }
    }

    componentDidMount() {
        this.setState({
            width: document.getElementById(this.props.conteinerId || 'card-pareto').clientWidth - 20
        });
    }

    render() {
        let _data = this.props.data || [];
        let data = (canvas) => this.formatData(_data, canvas);
        let height = _data.length * 50;
        let options = this.formatOptions(this.props.data, height);

        if(this.state.width > 0 && height > 0 && _data) {
            return (
                <HorizontalBar redraw={true} ref='chart' width={this.state.width} height={height} data={data} options={options} legend={legendOpts}></HorizontalBar>
            );
        } else {
            return (
                null
            );
        }
    }
}

export default ParetoBarChart;
