import React, { Component } from 'react';
import $ from 'jquery';

import styles from './style.scss';
import { withTranslation } from 'react-i18next';

class HoldingTime extends Component {
    componentDidUpdate() {
        $('#contentHoldingTime').css('width', this.props.widthLote, 'important');
    }

    render() {
        let {detalhes} = this.props;
        return (
            <div className={styles.contentHoldingTime} id={'contentHoldingTime'}>
                <div className={styles.titleHoldingTime}>
                    <span> {this.props.t("labels.holdingTime")} </span>
                </div>
                <hr style={{marginTop: '5px', border: '0.1px solid #DFDFDF'}}/>
                { detalhes !== undefined ?
                    <div className={styles.holdingTimes}>
                    {
                        detalhes.map((detalheHoldingTime, i) => {
                            return(<DetalheHoldingTime detalheHoldingTime={detalheHoldingTime} />);
                        })
                    }
                    </div>
                :
                    null
                }
                
            </div>
        );
    }
}

// TRUE = AO FINAL // FALSE = AO INICIO
class DetalheHoldingTimeExport extends React.Component {
    render() {
        let {detalheHoldingTime} = this.props;
        return (
            <div className={'flex flex-align-center'} style={{padding: '5px'}}>

                {
                this.props.detalheHoldingTime.cor === 'verde' ?
                    <div className={styles.timerBranco}><i className="material-icons" style={{ margin: '2px', fontSize: '22px'}}>timer</i></div>
                : this.props.detalheHoldingTime.cor === 'vermelho' ?
                    <div className={styles.timerVermelho}><i className="material-icons" style={{color: 'white', margin: '2px', fontSize: '22px'}}>timer</i></div>
                : this.props.detalheHoldingTime.cor === 'preto' ?
                    <div className={styles.timerPreto}><i className="material-icons" style={{color: 'white', margin: '2px', fontSize: '22px'}}>timer</i></div>
                :
                    null
                }
                
                <div className={styles.holdingTimeName}>{detalheHoldingTime.holdingTime.nome}</div> 
                <div className={styles.holdingTimeBox}>{detalheHoldingTime.holdingTime.posicao_etapa_inicio ? this.props.t("labels.end") : this.props.t("labels.start")} | <span style={{fontWeight: 'bold'}}>{detalheHoldingTime.holdingTime.etapa_inicio.nome}</span></div>
                <div style={{marginLeft: '2em', width: '50px'}}>{this.props.t("labels.to")}</div>
                <div className={styles.holdingTimeBox}>{detalheHoldingTime.holdingTime.posicao_etapa_fim ? this.props.t("labels.end") : this.props.t("labels.start")} | <span style={{fontWeight: 'bold'}}>{detalheHoldingTime.holdingTime.etapa_fim.nome}</span></div> 
                <div className={styles.holdingTimeBox}>{detalheHoldingTime.tempoReal}/{detalheHoldingTime.holdingTime.tempo_total}h</div>
            </div>            
        );
    }
}

export const DetalheHoldingTime = withTranslation("flowView")(DetalheHoldingTimeExport);

export default withTranslation("flowView")(HoldingTime);