import { observable, action, computed } from "mobx";

class RouteContainerStore {
    @observable atualizarRotas = false;

    @computed get AtualizarRotas(){
        return this.atualizarRotas;
    }

    @action
    setAtualizarRotas = atualizarRotas => {
        this.atualizarRotas = atualizarRotas;
    }
}

export default RouteContainerStore;
