import React from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import { alertBox } from '../../common/NForm';
import DeleteItem from '../../common/DeleteItem';
import PredecessoresList from './PredecessoresList';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Divider from 'material-ui/Divider';
import { request, requestAll } from '../../common/request';
import SemPermissao from '../../paginaErro/SemPermissao';
import styles from './predecessores.css';
import { respostaPredecessores, respostaLotes, respostaProdutos, requisicaoPredecessores } from '../../common/mappers';
import MDSpinner from "react-md-spinner";
import { withTranslation } from 'react-i18next';

@observer
class PredecessoresPage extends Container {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      field: '',
      typeModal: '',
      produto: '',
      produtoPredecessor1: '',
      produtoPredecessor2: '',
      produtoPredecessor3: '',
      produtoPredecessor4: '',
      produtoPredecessor5: '',
      produtoPredecessor6: '',
      produtoPredecessor7: '',
      produtoPredecessor8: '',
      produtoPredecessor9: '',
      produtoPredecessor10: '',
      produtoPredecessor11: '',
      produtoPredecessor12: '',
      produtoPredecessor13: '',
      produtoPredecessor14: '',
      produtoPredecessor15: '',
      produtoPredecessor16: '',
      produtoPredecessor17: '',
      produtoPredecessor18: '',
      produtoPredecessor19: '',
      produtoPredecessor20: '',
      labelProduto: '',
      labelPredecessor1: '',
      labelPredecessor2: '',
      labelPredecessor3: '',
      labelPredecessor4: '',
      labelPredecessor5: '',
      labelPredecessor6: '',
      labelPredecessor7: '',
      labelPredecessor8: '',
      labelPredecessor9: '',
      labelPredecessor10: '',
      labelPredecessor11: '',
      labelPredecessor12: '',
      labelPredecessor13: '',
      labelPredecessor14: '',
      labelPredecessor15: '',
      labelPredecessor16: '',
      labelPredecessor17: '',
      labelPredecessor18: '',
      labelPredecessor19: '',
      labelPredecessor20: '',
      lote: '',
      lotePredecessor1: '',
      lotePredecessor2: '',
      lotePredecessor3: '',
      lotePredecessor4: '',
      lotePredecessor5: '',
      lotePredecessor6: '',
      lotePredecessor7: '',
      lotePredecessor8: '',
      lotePredecessor9: '',
      lotePredecessor10: '',
      lotePredecessor11: '',
      lotePredecessor12: '',
      lotePredecessor13: '',
      lotePredecessor14: '',
      lotePredecessor15: '',
      lotePredecessor16: '',
      lotePredecessor17: '',
      lotePredecessor18: '',
      lotePredecessor19: '',
      lotePredecessor20: '',
      erroLOTE: '',
      erroLOTEPREDECESSOR1: '',
      erroLOTEPREDECESSOR2: '',
      erroLOTEPREDECESSOR3: '',
      erroLOTEPREDECESSOR4: '',
      erroLOTEPREDECESSOR5: '',
      erroLOTEPREDECESSOR6: '',
      erroLOTEPREDECESSOR7: '',
      erroLOTEPREDECESSOR8: '',
      erroLOTEPREDECESSOR9: '',
      erroLOTEPREDECESSOR10: '',
      erroLOTEPREDECESSOR11: '',
      erroLOTEPREDECESSOR12: '',
      erroLOTEPREDECESSOR13: '',
      erroLOTEPREDECESSOR14: '',
      erroLOTEPREDECESSOR15: '',
      erroLOTEPREDECESSOR16: '',
      erroLOTEPREDECESSOR17: '',
      erroLOTEPREDECESSOR18: '',
      erroLOTEPREDECESSOR19: '',
      erroLOTEPREDECESSOR20: '',
      autorizacao: true,
      loadingPage: true,
      disabledInsert: false,
      hasMore: true,
      searchText: '',
      searching: false,
      predecessoresPaginacao: [],
      paginationPage: 0,
      waitingRequest: false
    }

    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.reloadLink = this.reloadLink.bind(this);
    this.submit = this.submit.bind(this);
    this.buscarLinkPiPa = this.buscarLinkPiPa.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
  }

  componentWillMount() {
    this.buscarLinkPiPa();
  }

  setSearchText(textToFind, searching) {
    this.setState(() => {
      return {
        searchText: textToFind,
        searching: searching,
        paginationPage: 0,
        waitingRequest: true
      }
    })
  }

  handleLoadMore() {
    request(
      this.endpoints.LINK_PI_PA
      + `?limit=${this.paginacao.ITENS_POR_PAGINA}`
      + `&offset=${this.state.paginationPage * this.paginacao.ITENS_POR_PAGINA}`
      + `&pesquisa=${this.state.searchText}`,
      { method: 'get' },
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(data => {
        data = respostaPredecessores(data);
        let listaPredecessores = (this.state.searching && data.length > 0)
          ? data
          : [...this.state.predecessoresPaginacao, ...data];

        if (!data.length) {
          alertBox(this.props.t("alerts.emptySearch"), this.props.t("predecessor"), 'warning');
        }

        this.setState(state => {
          return {
            predecessoresPaginacao: listaPredecessores,
            hasMore: (data.length === this.paginacao.ITENS_POR_PAGINA),
            searching: false,
            paginationPage: state.paginationPage + 1,
            loadingPage: false,
            waitingRequest: false
          }
        });
      });
  }

  buscarLinkPiPa() {
    requestAll([
      {
        url: this.endpoints.LOTES,
        modelo: 'batches',
        modificador: respostaLotes,
        acao: true
      },
      {
        url: this.endpoints.PRODUTO,
        modelo: 'products',
        modificador: respostaProdutos,
        acao: true
      }
    ], this);
  }

  delete(id) {
    this.setState({
      disabledDelete: true
    });
    request(this.endpoints.LINK_PI_PA + '/' + id, {
      method: 'delete'
    })
      .then(response => {
        if (response.status === 200) {
          request(this.endpoints.APONTAMENTO + `/validar/lote/${this.state.field.id_lotePai}`, {
            method: 'get'
          });
          this.reloadLink();
          this.handleModal();
          alertBox(this.props.t("alerts.deleteSuccess"), this.props.t("alerts.predecessorLink"), 'success');
        } else {
          alertBox(this.props.t("alerts.deleteError"), this.props.t("alerts.predecessorLink"), 'error');
        }
      })
      .catch(() => {
        alertBox(this.props.t("alerts.deleteError"), this.props.t("alerts.predecessorLink"), 'error');
      })
  }

  reloadLink() {
    //Atualiza o state para não exibir/exibir o vínculo removido/adicionado
    this.setSearchText('', true);
    this.handleLoadMore();
  }

  clearState() {
    this.setState({
      field: '',
      typeModal: '',
      produto: '',
      produtoPredecessor1: '',
      produtoPredecessor2: '',
      produtoPredecessor3: '',
      labelProduto: '',
      labelPredecessor1: '',
      labelPredecessor2: '',
      labelPredecessor3: '',
      lote: '',
      lotePredecessor1: '',
      lotePredecessor2: '',
      lotePredecessor3: '',
      erroLOTE: '',
      erroLOTEPREDECESSOR1: '',
      erroLOTEPREDECESSOR2: '',
      erroLOTEPREDECESSOR3: '',
      disabledInsert: true,
      disabledDelete: false
    });
  }

  handleModal(f, t) {
    this.setState({
      modal: !this.state.modal,
      field: f,
      typeModal: t
    });

    if (t == 'add') {
      this.clearState();
    }
  }

  handleText(text, campo, textProduto, event) {
    let findLote, produto;

    if (text == "lote") {
      findLote = this.store.batches.find(l => l.num == event.target.value);
      findLote ?
        produto = this.store.products.find(p => p.id == findLote.fk_id_products)
        : null;

      if (produto) {
        this.setState({
          disabledInsert: produto.predecessores[0] ? false : true
        });
      } else {
        this.setState({
          disabledInsert: true
        });
      }

      this.setState({
        [text]: event.target.value,
        [campo]: produto ? ' - ' + produto.sku + ' - ' + produto.name : '',
        [textProduto]: produto ? produto : ''
      });
    } else {
      findLote = this.store.batches.find(l => l.num == event.target.value);

      if (event.target.value != '' && findLote) {
        if (this.store.products.find(p => {
          return p.predecessores != null ? p.predecessores.find(pred => {
            return (pred.fk_id_products == this.state.produto.id) && (pred.fk_id_pi == findLote.fk_id_products)
          }) : 0
        }) != undefined) {

          produto = this.store.products.find(p => p.id == findLote.fk_id_products);

          this.setState({
            [text]: event.target.value,
            [campo]: produto ? ' - ' + produto.sku + ' - ' + produto.name : '',
            [textProduto]: produto ? produto : ''
          });

        } else {
          localStorage.getItem('reference') == 'true'
            ? alertBox(this.props.t("alerts.batchLinkInconsistency", { num1: findLote.num, num2: this.state.lote }),
              this.props.t("alerts.predecessorLink"),
              'error')
            : alertBox(this.props.t("alerts.orderLinkInconsistency", { num1: findLote.num, num2: this.state.lote }),
              this.props.t("alerts.predecessorLink"),
              'error')
          this.setState({
            [text]: '',
            [campo]: '',
            [textProduto]: ''
          });
        }
      } else {
        this.setState({
          [text]: '',
          [campo]: '',
          [textProduto]: ''
        });
      }
    }
  }

  submit() {
    var erroCampo = false, erroDif = false, qtPredecessores, produtoPredecessores, lotesPredecessoresProduto, lotes, findLote, arrayLotePredecessores = [];
    var findLotePredecessor1,
      findLotePredecessor2,
      findLotePredecessor3,
      findLotePredecessor4,
      findLotePredecessor5,
      findLotePredecessor6,
      findLotePredecessor7,
      findLotePredecessor8,
      findLotePredecessor9,
      findLotePredecessor10,
      findLotePredecessor11,
      findLotePredecessor12,
      findLotePredecessor13,
      findLotePredecessor14,
      findLotePredecessor15,
      findLotePredecessor16,
      findLotePredecessor17,
      findLotePredecessor18,
      findLotePredecessor19,
      findLotePredecessor20;

    var data = {
      lote: this.state.lote || '',
      lotePredecessor1: this.state.lotePredecessor1 || '',
      lotePredecessor2: this.state.lotePredecessor2 || '',
      lotePredecessor3: this.state.lotePredecessor3 || '',
      lotePredecessor4: this.state.lotePredecessor4 || '',
      lotePredecessor5: this.state.lotePredecessor5 || '',
      lotePredecessor6: this.state.lotePredecessor6 || '',
      lotePredecessor7: this.state.lotePredecessor7 || '',
      lotePredecessor8: this.state.lotePredecessor8 || '',
      lotePredecessor9: this.state.lotePredecessor9 || '',
      lotePredecessor10: this.state.lotePredecessor10 || '',
      lotePredecessor11: this.state.lotePredecessor11 || '',
      lotePredecessor12: this.state.lotePredecessor12 || '',
      lotePredecessor13: this.state.lotePredecessor13 || '',
      lotePredecessor14: this.state.lotePredecessor14 || '',
      lotePredecessor15: this.state.lotePredecessor15 || '',
      lotePredecessor16: this.state.lotePredecessor16 || '',
      lotePredecessor17: this.state.lotePredecessor17 || '',
      lotePredecessor18: this.state.lotePredecessor18 || '',
      lotePredecessor19: this.state.lotePredecessor19 || '',
      lotePredecessor20: this.state.lotePredecessor20 || ''
    };

    produtoPredecessores = {
      'lotePredecessor1': this.state.produtoPredecessor1.id ? this.state.produtoPredecessor1.id : '',
      'lotePredecessor2': this.state.produtoPredecessor2.id ? this.state.produtoPredecessor2.id : '',
      'lotePredecessor3': this.state.produtoPredecessor3.id ? this.state.produtoPredecessor3.id : '',
      'lotePredecessor4': this.state.produtoPredecessor4.id ? this.state.produtoPredecessor4.id : '',
      'lotePredecessor5': this.state.produtoPredecessor5.id ? this.state.produtoPredecessor5.id : '',
      'lotePredecessor6': this.state.produtoPredecessor6.id ? this.state.produtoPredecessor6.id : '',
      'lotePredecessor7': this.state.produtoPredecessor7.id ? this.state.produtoPredecessor7.id : '',
      'lotePredecessor8': this.state.produtoPredecessor8.id ? this.state.produtoPredecessor8.id : '',
      'lotePredecessor9': this.state.produtoPredecessor9.id ? this.state.produtoPredecessor9.id : '',
      'lotePredecessor10': this.state.produtoPredecessor10.id ? this.state.produtoPredecessor10.id : '',
      'lotePredecessor11': this.state.produtoPredecessor11.id ? this.state.produtoPredecessor11.id : '',
      'lotePredecessor12': this.state.produtoPredecessor12.id ? this.state.produtoPredecessor12.id : '',
      'lotePredecessor13': this.state.produtoPredecessor13.id ? this.state.produtoPredecessor13.id : '',
      'lotePredecessor14': this.state.produtoPredecessor14.id ? this.state.produtoPredecessor14.id : '',
      'lotePredecessor15': this.state.produtoPredecessor15.id ? this.state.produtoPredecessor15.id : '',
      'lotePredecessor16': this.state.produtoPredecessor16.id ? this.state.produtoPredecessor16.id : '',
      'lotePredecessor17': this.state.produtoPredecessor17.id ? this.state.produtoPredecessor17.id : '',
      'lotePredecessor18': this.state.produtoPredecessor18.id ? this.state.produtoPredecessor18.id : '',
      'lotePredecessor19': this.state.produtoPredecessor19.id ? this.state.produtoPredecessor19.id : '',
      'lotePredecessor20': this.state.produtoPredecessor20.id ? this.state.produtoPredecessor20.id : ''
    };

    if (!data.lote) {
      this.setState({
        erroLOTE: this.props.t("requiredField")
      });
      erroCampo = true;
    }

    if (!erroCampo) {
      findLote = this.store.batches.find(l => l.num == this.state.lote);

      if (!findLote) {
        this.store.errors = localStorage.getItem('reference') == 'true'
          ? this.props.t("alerts.batchNotRegistered", { num: this.state.lote })
          : this.props.t("alerts.orderNotRegistered", { num: this.state.lote });

        erroCampo = true;
      }

      if ((!erroCampo) && (this.state.produto)) {
        if (this.store.predecessores.find(p => p.id_pa == findLote.id)) {
          this.store.errors = localStorage.getItem('reference') == 'true'
            ? this.props.t("alerts.batchAlreadyLinked", { num: findLote.num })
            : this.props.t("alerts.orderAlreadyLinked", { num: findLote.num });

          erroCampo = true;
        }
      }

      qtPredecessores = this.state.produto.predecessores.length;
      lotesPredecessoresProduto = this.state.produto.predecessores.map(p => { return p.fk_id_pi });

      Object.keys(data).forEach(function (element, i) {
        if (!data[element] && i <= qtPredecessores) {
          this.setState({
            ['erro' + element.toUpperCase()]: this.props.t("requiredField")
          });
          erroCampo = true;
        } else {
          if (data[element] && !this.store.batches.find(l => l.num == data[element])) {
            this.store.errors = localStorage.getItem('reference') == 'true'
              ? this.props.t("alerts.batchNotRegistered", { num: this.state.lote })
              : this.props.t("alerts.orderNotRegistered", { num: this.state.lote });

            erroCampo = true;
          }

          this.setState({
            ['erro' + element.toUpperCase()]: ''
          });
        }
      }, this);

      var lotes = [];

      lotes.push(data['lotePredecessor1']);
      lotes.push(data['lotePredecessor2']);
      lotes.push(data['lotePredecessor3']);
      lotes.push(data['lotePredecessor4']);
      lotes.push(data['lotePredecessor5']);
      lotes.push(data['lotePredecessor6']);
      lotes.push(data['lotePredecessor7']);
      lotes.push(data['lotePredecessor8']);
      lotes.push(data['lotePredecessor9']);
      lotes.push(data['lotePredecessor10']);
      lotes.push(data['lotePredecessor11']);
      lotes.push(data['lotePredecessor12']);
      lotes.push(data['lotePredecessor13']);
      lotes.push(data['lotePredecessor14']);
      lotes.push(data['lotePredecessor15']);
      lotes.push(data['lotePredecessor16']);
      lotes.push(data['lotePredecessor17']);
      lotes.push(data['lotePredecessor18']);
      lotes.push(data['lotePredecessor19']);
      lotes.push(data['lotePredecessor20']);

      var tofindDuplicates = arry => arry.filter((item, index) => lotes.indexOf(item) !== index);
      var fiteredLotes = lotes.filter(function (el) {
        return el !== "";
      });
      var lotesDuplicados = tofindDuplicates(fiteredLotes);

      if (lotesDuplicados.length > 0) {
        this.store.errors = localStorage.getItem('reference') == 'true'
          ? this.props.t("alerts.batchLinkErrorDuplicated", { num: lotesDuplicados[0] })
          : this.props.t("alerts.orderLinkErrorDuplicated", { num: lotesDuplicados[0] });

        erroCampo = true;
      }

      if (!erroCampo) {
        Object.keys(produtoPredecessores).forEach(function (element, i) {
          if (!lotesPredecessoresProduto.includes(produtoPredecessores[element]) && produtoPredecessores[element]) {
            arrayLotePredecessores.push(element);
            erroDif = true;
          }
        }, this);

        if (erroDif) {
          lotes = arrayLotePredecessores.map(l => { return this.state[l] });

          if (lotes.length == 1) {
            this.store.errors = localStorage.getItem('reference') == 'true'
              ? this.props.t("alerts.batchProductConflict", { num: lotes[0], num2: this.state.lote })
              : this.props.t("alerts.orderProductConflict", { num: lotes[0], num2: this.state.lote });
          } else {
            this.store.errors = localStorage.getItem('reference') == 'true'
              ? this.props.t("alerts.batchesProductsConflict", { num: lotes.map(l => { return (l) }).join(','), num2: this.state.lote })
              : this.props.t("alerts.ordersProductsConflict", { num: lotes.map(l => { return (l) }).join(','), num2: this.state.lote });
          }
        }

        if (!erroCampo && !erroDif) {
          this.setState({
            disabledInsert: true
          });

          data = [];

          findLotePredecessor1 = this.state.lotePredecessor1 ? this.store.batches.find(l => l.num == this.state.lotePredecessor1) : null;
          findLotePredecessor2 = this.state.lotePredecessor2 ? this.store.batches.find(l => l.num == this.state.lotePredecessor2) : null;
          findLotePredecessor3 = this.state.lotePredecessor3 ? this.store.batches.find(l => l.num == this.state.lotePredecessor3) : null;
          findLotePredecessor4 = this.state.lotePredecessor4 ? this.store.batches.find(l => l.num == this.state.lotePredecessor4) : null;
          findLotePredecessor5 = this.state.lotePredecessor5 ? this.store.batches.find(l => l.num == this.state.lotePredecessor5) : null;
          findLotePredecessor6 = this.state.lotePredecessor6 ? this.store.batches.find(l => l.num == this.state.lotePredecessor6) : null;
          findLotePredecessor7 = this.state.lotePredecessor7 ? this.store.batches.find(l => l.num == this.state.lotePredecessor7) : null;
          findLotePredecessor8 = this.state.lotePredecessor8 ? this.store.batches.find(l => l.num == this.state.lotePredecessor8) : null;
          findLotePredecessor9 = this.state.lotePredecessor9 ? this.store.batches.find(l => l.num == this.state.lotePredecessor9) : null;
          findLotePredecessor10 = this.state.lotePredecessor10 ? this.store.batches.find(l => l.num == this.state.lotePredecessor10) : null;
          findLotePredecessor11 = this.state.lotePredecessor11 ? this.store.batches.find(l => l.num == this.state.lotePredecessor11) : null;
          findLotePredecessor12 = this.state.lotePredecessor12 ? this.store.batches.find(l => l.num == this.state.lotePredecessor12) : null;
          findLotePredecessor13 = this.state.lotePredecessor13 ? this.store.batches.find(l => l.num == this.state.lotePredecessor13) : null;
          findLotePredecessor14 = this.state.lotePredecessor14 ? this.store.batches.find(l => l.num == this.state.lotePredecessor14) : null;
          findLotePredecessor15 = this.state.lotePredecessor15 ? this.store.batches.find(l => l.num == this.state.lotePredecessor15) : null;
          findLotePredecessor16 = this.state.lotePredecessor16 ? this.store.batches.find(l => l.num == this.state.lotePredecessor16) : null;
          findLotePredecessor17 = this.state.lotePredecessor17 ? this.store.batches.find(l => l.num == this.state.lotePredecessor17) : null;
          findLotePredecessor18 = this.state.lotePredecessor18 ? this.store.batches.find(l => l.num == this.state.lotePredecessor18) : null;
          findLotePredecessor19 = this.state.lotePredecessor19 ? this.store.batches.find(l => l.num == this.state.lotePredecessor19) : null;
          findLotePredecessor20 = this.state.lotePredecessor20 ? this.store.batches.find(l => l.num == this.state.lotePredecessor20) : null;

          data.push({
            fk_id_batches: findLotePredecessor1.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          });

          findLotePredecessor2 ? data.push({
            fk_id_batches: findLotePredecessor2.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor3 ? data.push({
            fk_id_batches: findLotePredecessor3.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor4 ? data.push({
            fk_id_batches: findLotePredecessor4.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor5 ? data.push({
            fk_id_batches: findLotePredecessor5.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor6 ? data.push({
            fk_id_batches: findLotePredecessor6.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor7 ? data.push({
            fk_id_batches: findLotePredecessor7.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor8 ? data.push({
            fk_id_batches: findLotePredecessor8.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor9 ? data.push({
            fk_id_batches: findLotePredecessor9.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor10 ? data.push({
            fk_id_batches: findLotePredecessor10.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor11 ? data.push({
            fk_id_batches: findLotePredecessor11.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor12 ? data.push({
            fk_id_batches: findLotePredecessor12.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor13 ? data.push({
            fk_id_batches: findLotePredecessor13.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor14 ? data.push({
            fk_id_batches: findLotePredecessor14.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor15 ? data.push({
            fk_id_batches: findLotePredecessor15.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor16 ? data.push({
            fk_id_batches: findLotePredecessor16.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor17 ? data.push({
            fk_id_batches: findLotePredecessor17.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor18 ? data.push({
            fk_id_batches: findLotePredecessor18.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor19 ? data.push({
            fk_id_batches: findLotePredecessor19.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          findLotePredecessor20 ? data.push({
            fk_id_batches: findLotePredecessor20.id,
            fk_pa_id: findLote.id,
            status: false,
            user_cad: this.store.getUser
          }) : null;

          request(this.endpoints.LINK_PI_PA, {
            method: 'post',
            body: JSON.stringify(requisicaoPredecessores(data))
          })
            .then(response => {
              if (response.status == 201) {
                request(this.endpoints.APONTAMENTO + `/validar/lote/${findLote.id}`, {
                  method: 'get'
                });
                this.reloadLink();
                this.handleModal();
                alertBox(this.props.t("alerts.createSuccess"), this.props.t("alerts.predecessorLink"), 'success');
              } else if (response.status == 404) {
                localStorage.getItem('reference') == 'true'
                  ? alertBox(this.props.t("alerts.batchAlreadyLinked", { num: findLote.num }),
                    this.props.t("alerts.predecessorLink"),
                    'error')
                  : alertBox(this.props.t("alerts.orderAlreadyLinked", { num: findLote.num }),
                    this.props.t("alerts.predecessorLink"),
                    'error');
              } else {
                alertBox(this.props.t("alerts.createError"), this.props.t("alerts.predecessorLink"), 'error');
              }

              this.setState({
                disabledInsert: false
              })
            })
            .catch(() => {
              alertBox(this.props.t("alerts.createError"), this.props.t("alerts.predecessorLink"), 'error');
              this.setState({
                disabledInsert: false
              })
            });
        }
      }
    }
  }

  contentModal() {
    let optionsPI = [];
    let optionsPA = [];

    if (this.state.typeModal == 'del') {
      return <DeleteItem field={{ id: this.state.field.id_lotePai, num: this.state.field.numero_lotePai }} delete={this.delete} handleModal={this.handleModal} />;
    }

    this.store.batches.map(b => {
      let prod = this.store.products.filter(p => p.id == b.fk_id_products)[0];

      if (prod && b.finished) {
        optionsPI.push({ value: b.id, text: b.num + ' - ' + prod.sku + ' ' + prod.name });
      }
      else if (prod && !b.finished) {
        optionsPA.push({ value: b.id, text: b.num + ' - ' + prod.sku + ' ' + prod.name });
      }
    });

    let isLote = localStorage.getItem('reference') == 'true';

    return (
      <div className={styles['modal-wrapper']}>
        <div style={{ width: '100%' }} className="modal">
          <span style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}> {isLote ? this.props.t("batchesLink") : this.props.t("ordersLink")}</span>
          <div className={styles.form}>
            <TextField
              style={{ width: '100%' }}
              underlineFocusStyle={{ borderColor: '#000000' }}
              floatingLabelStyle={{ color: '#5B5B5B', textOverflow: 'ellipsis', width: '26em' }}
              floatingLabelText={`${(isLote ? this.props.t("batch") : this.props.t("order"))} ${this.state.labelProduto}`}
              defaultValue={this.state.lote}
              errorText={this.state.erroLOTE}
              onChange={this.handleText.bind(this, 'lote', 'labelProduto', 'produto')}
            />
          </div>

          <Divider />
          <div className={styles.form}>
            {
              this.state.produto.predecessores ? this.state.produto.predecessores.map((p, i) => {
                return <TextField
                  style={{ width: '100%' }}
                  underlineFocusStyle={{ borderColor: '#000000' }}
                  floatingLabelStyle={{ color: '#5B5B5B', textOverflow: 'ellipsis', width: '26em' }}
                  floatingLabelText={
                    (this.state['lotePredecessor' + (i + 1)] == '')
                      ? `${isLote ? this.props.t("predecessorBatch") : this.props.t("predecessorOrder")} ` + (i + 1)
                      : `${isLote ? this.props.t("batch") : this.props.t("order")} ${this.state['labelPredecessor' + (i + 1)]}`
                  }
                  defaultValue={this.state['lotePredecessor' + (i + 1)]}
                  errorText={this.state['erroLOTEPREDECESSOR' + (i + 1)]}
                  onChange={this.handleText.bind(this, 'lotePredecessor' + (i + 1), 'labelPredecessor' + (i + 1), 'produtoPI' + (i + 1))}
                />
              }) : null
            }
          </div>

          <div style={{ display: 'flex', paddingTop: '1em', justifyContent: 'flex-end' }} >
            <RaisedButton onClick={this.submit} disabled={this.state.disabledInsert} disabledBackgroundColor={'#788291'} disabledLabelColor={'white'} id={'buttonSave'} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} labelColor='#FFF' label={this.props.t("save")} />
            <RaisedButton onClick={this.handleModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '1em' }} label={this.props.t("cancel")} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', textAlign: 'center', alignItems: 'center', height: '80%' }}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      if (this.state.autorizacao) {
        return (
          <Container showSideBar
            modal={this.state.modal}
            styleModal={{ width: 350 }}
            contentModal={this.contentModal}
          >
            <PredecessoresList
              setSearchText={this.setSearchText}
              waitingRequest={this.state.waitingRequest}
              ref='list'
              predecessoresPaginacao={this.state.predecessoresPaginacao}
              hasMore={this.state.hasMore}
              store={this.store}
              handleModal={this.handleModal}
              handleLoadMore={this.handleLoadMore}
              t={this.props.t}
            />
          </Container>
        );
      } else {
        return (
          <SemPermissao />
        );
      }
    }
  }
}

export default withTranslation('batchesLink')(PredecessoresPage);