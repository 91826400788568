import $ from "jquery";
import { AutoComplete } from "material-ui";
import Toggle from "material-ui/Toggle";
import { inject, observer } from "mobx-react";
import React from "react";
import MDSpinner from "react-md-spinner";
import { default as MultiSelect } from "components/AutoComplete";
import Calendario from "../../assets/Calendario.svg";
import Container from "../../common/Container";
import DeleteItem from "../../common/DeleteItem";
import InputTime from "../../common/InputTime";
import {
  mapearModal,
  requisicaoEquipamentos,
  respostaAreaProdutivas,
  respostaEquipamentos,
  respostaEventos,
  respostaTimeMachineSetup,
  respostaTurnos,
  respostaValorPadraoIndicadoresPersonalizados,
} from "../../common/mappers";
import ModalForm from "../../common/ModalForm";
import { alertBox, NMaskedTime } from "../../common/NForm";
import Quest from "../../common/Quest";
import { request, requestAll } from "../../common/request";
import MaskFieldTime from "../../utils/MaskFieldTime";
import TimeConversion from "../../utils/TimeConversion";
import CustomIndicatorForm from "../customIndicator/CustomIndicatorForm";
import EquipmentsList from "./EquipmentsList";

//Modais Genericos
import ModalContainer from "../../common/Modal/ModalContainer";
import ModalLeftContainer from "../../common/Modal/ModalLeftContainer";
import ModalRightContainer from "../../common/Modal/ModalRightContainer";
import { hasRole } from "../../helper/SecurityHelper";

const { THoursMinutes } = TimeConversion;
const updateStateAfterRequestAll = false;
const { MaskInterval, MaskHoraMinutoSegundo } = MaskFieldTime;

import { toJS } from "mobx";
import moment from "moment";
import utils from "utils/Utils";
import styles from "../../common/form.scss";
import FormModalVigenciasGenerico from "../../common/Modal/formsModal/formVigenciaGenerico";
import ModalItensGenerico from "../../common/Modal/ModalItensGenerico";
import FormSetup from "./FormSetup";
import { withTranslation } from "react-i18next";

const WISE4050 = "Wise4050";
const DIGIRAIL_OEE_EVENT = "DigiRailEventOEE";
const DIGIRAIL_OEE_COUNTER = "DigiRailCounterOEE";

@inject("store")
@observer
class EquipmentsPage extends Container {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      field: "",
      idEquipamento: 0,
      loadingPage: true,
      eventosSetup: [],
      grupoEquipamentos: [],
      equipments_events: [],
      ip: "",
      canais: [
        { value: 0, text: 0 },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      coletas: [
        { value: 1, text: props.t("form.iot.options.totalAmount") },
        { value: 2, text: props.t("form.iot.options.approvedAmount") },
        { value: 3, text: props.t("form.iot.options.rejectedAmount") },
      ],
      canal1: "",
      canal2: "",
      coleta1: "",
      coleta2: "",
      iotAtivo: false,
      produzido: -1,
      rejeitado: -1,
      total: -1,
      eventoText: "",
      eventoId: "",
      usuario: "",
      senha: "",
      tipoDispositivo: "",
      dispositivoId: "",
      multiplicador_produzido: 1,
      multiplicador_rejeitado: 1,
      producaoEventoText: "",
      producaoEventoId: 0,
      microParadaEventoText: "",
      microParadaEventoId: 0,
      state: true,
      estado: true,
      is_batch: false,
      is_checkout: 0,
      nome: "",
      tag: "",
      areas: [],
      turnos: [],
      tempo_espera: "",
      fifo: 5,
      equipamentos: [],
      espera: "00:00",
      tempo_tolerancia: "",
      tempo_micro_parada: "",
      meta_time_medium_setup: "",
      meta_time_performance_geral: "",
      meta_time_troca_lote: "",
      meta_time_performance_setup_completo: "",
      meta_time_mttr: "",
      meta_time_mtbf: "",
      meta_time_mttp: "",
      meta_time_mtbp: "",
      meta_time_mwt: "",
      meta_time_preventive_corretive: "",
      meta_oee_real_com_setup: 0,
      meta_oee_real_sem_setup: 0,
      meta_oee_effetive_com_setup: 0,
      meta_oee_effetive_sem_setup: 0,
      meta_oee_otimized_com_setup: 0,
      meta_oee_otimized_sem_setup: 0,
      meta_oee_teep_com_setup: 0,
      meta_oee_teep_sem_setup: 0,
      indicators: [],
      timeMachineDados: [],
      timeMachineDadosAba: [],
      abaAtiva: 0,
      objModal: {},
      events: [],
      eventos: [],
      ultimoTimeMachineAtivo: {},
      timeMachinesArray: [],
      equipamentoTimeMachine: {},
      vigenciaAtiva: {},
      msgDelete: "",
      carregandoInformacoes: false,
      informacoesCarregadas: false,
      customValidationMessage: undefined,
      possuiEventoSetup: true,
      isMultiple: false,
      multipleQuantity: 0,
      customGoal: false,
      customGoalValue: 0,
      searchTextProducaoEvento: "",
      searchTextMicroParadaEvento: "",
      requestMultiplier: false,
      moReset: false,
      moAppCollet: undefined,
      moMandatoryInEvents: [],
      hasLinkedEquipments: false,
      linkedEquipments: [],
    };

    //Injeção da store de setup
    this.storeTimeMachineSetup = this.props.store.setupStore;

    //Injeção da store do modalItens
    this.storeModalItens = this.props.store.modalItens;

    //Injeção da store de controle do modal de vigencias
    this.storeModalControlVigencias =
      this.props.store.modalControlVigenciasStore;

    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.fetchInformacoes = this.fetchInformacoes.bind(this);
    this.fetchEquipamento = this.fetchEquipamento.bind(this);
    this.onCheckIoT = this.onCheckIoT.bind(this);
    this.selectCanais = this.selectCanais.bind(this);
    this.mudarMandatoryMO = this.mudarMandatoryMO.bind(this);
    this.formIot = this.formIot.bind(this);
    this.formTecnical = this.formTecnical.bind(this);
    this.handleProducaoEvento = this.handleProducaoEvento.bind(this);
    this.handleMicroParadaEvento = this.handleMicroParadaEvento.bind(this);
    this.handleEquipamentos = this.handleEquipamentos.bind(this);
    this.stateColeta = this.stateColeta.bind(this);
    this.formDetalhes = this.formDetalhes.bind(this);
    this.handleAreas = this.handleAreas.bind(this);
    this.handleTurnos = this.handleTurnos.bind(this);
    this.formMetas = this.formMetas.bind(this);
    this.formOee = this.formOee.bind(this);
    this.formSetup = this.formSetup.bind(this);
    this.validarForm = this.validarForm.bind(this);
    this.blurEspera = this.blurEspera.bind(this);
    this.clearState = this.clearState.bind(this);
    this.camposForm = this.camposForm.bind(this);
    this.changeContentModal = this.changeContentModal.bind(this);
    this.editVigencia = this.editVigencia.bind(this);
    this.mascaraSetup = this.mascaraSetup.bind(this);
    this.mascara = this.mascara.bind(this);
    this.setStateEquipamento = this.setStateEquipamento.bind(this);
    this._fetchDefaultEvents = this._fetchDefaultEvents.bind(this);
    this.setStateObjModalAoInserirTimeMachine =
      this.setStateObjModalAoInserirTimeMachine.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
  }

  componentDidMount() {
    this.fetchEquipamento();
  }

  fetchEquipamento() {
    this.setState({ modal: false });
    const requests = [
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: "equipments",
        modificador: respostaEquipamentos,
      },
      {
        url: this.endpoints.EVENTO + "/classe",
        modelo: "events",
        modificador: respostaEventos,
      },
      {
        url: this.endpoints.EVENTO,
        modelo: "eventos",
        modificador: (data) => {
          this.setState({
            eventos: data,
          });
          return data;
        },
      },
    ];

    requestAll(requests, this, updateStateAfterRequestAll).then(() => {
      this.setState({ loadingPage: false });
    });
  }

  onOpenModal(equipamento, acao) {
    if (!this.state.informacoesCarregadas) {
      this.setState({
        modal: true,
        typeModal: "loading",
        carregandoInformacoes: true,
      });

      this.fetchInformacoes().then(() => {
        this.setState({
          loadingPage: false,
          carregandoInformacoes: false,
          informacoesCarregadas: true,
        });

        this.handleModal(equipamento, acao, true);
      });
    } else {
      this.handleModal(equipamento, acao);
    }
  }

  async _fetchDefaultEvents() {
    let events = [[]];
    let storeEvents = this.store.events.filter((e) => e.fk_id_table == 13);

    if (storeEvents.length > 0) {
      storeEvents.forEach((element) => {
        let event = {
          codigo: element.cod,
          nome: element.descr,
          id: element.id,
        };

        events[0].push({
          id: 0,
          evento: event,
          tempoDuracao: "00:00:00",
          vigencia: { id: 0 },
        });
      });
    }

    await this.setState({
      events: events,
    });
  }

  fetchInformacoes() {
    const requests = [
      {
        url: this.endpoints.AREA_PRODUTIVA,
        modelo: "fields",
        modificador: respostaAreaProdutivas,
        acao: true,
      },
      {
        url: this.endpoints.TURNO,
        modelo: "shifts",
        modificador: respostaTurnos,
        acao: true,
      },
      {
        url: this.endpoints.GRUPO_EQUIPAMENTO,
        modelo: "grupoEquipamentos",
        destino: "state",
        acao: true,
      },
      {
        url: this.endpoints.EVENTO + "/classe",
        modelo: "events",
        modificador: respostaEventos,
      },
    ];

    return requestAll(requests, this, updateStateAfterRequestAll);
  }

  delete(id) {
    let data = {};
    request(`${this.endpoints.EQUIPAMENTOS}/${id}`, {
      method: "delete",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          let EquipamentoExcluido = {};
          this.handleModal(
            typeof id == "number" ? (EquipamentoExcluido.id = id) : id,
          );
          this.fetchEquipamento();
          this.showAlert(
            this.props.t("messages.deleteSuccess"),
            this.props.t("title"),
            "sucess",
          );
        } else {
          return response.json();
        }
      })
      .then((dataError) => {
        if (dataError) {
          this.setState({
            typeModal: "Equipamento_Vinculado_UI",
            msgDelete: dataError.message,
          });
        }
      })
      .catch(() => {
        alertBox(
          this.props.t("errors.cannotDelete"),
          this.props.t("labels.error", { ns: "common" }),
          "error",
        );
      });
  }

  onDelete(equipament) {
    this.setState({
      msgDelete: this.props.t("messages.deleteConfirmation", { ns: "common" }),
    });
    this.handleModal(equipament, "del");
  }

  getIndicatorsEquipment(id) {
    const params = { method: "GET" };
    const pathUrl =
      id == undefined ? "/valores-padrao/ativos" : `/equipamento/${id}`;
    const url = this.endpoints.INDICADORES_PERSONALIZADOS + pathUrl;

    return new Promise((resolve, reject) => {
      request(url, params)
        .then((response) => {
          if (response.ok && response.status != 204) {
            resolve(response.json());
          }

          reject([]);
        })
        .then((error) => {
          reject([]);
        });
    });
  }

  handleModal(f, t, keepModalOpen = false) {
    if (t == "up") {
      var canal1 = "";
      var coleta1 = "";
      var canal2 = "";
      var coleta2 = "";
      let newCanais = [];
      let newColetas = [];

      if (f.equipamentoIot) {
        if (f.equipamentoIot.produzido != -1) {
          canal1 = f.equipamentoIot.produzido;
          coleta1 = 2;
        }

        if (f.equipamentoIot.rejeitado != -1) {
          if (canal1 != "" || canal1 == 0) {
            canal2 = f.equipamentoIot.rejeitado;
            coleta2 = 3;
          } else {
            canal1 = f.equipamentoIot.rejeitado;
            coleta1 = 3;
          }
        }

        if (f.equipamentoIot.total != -1) {
          if (canal1 != "") {
            canal2 = f.equipamentoIot.total;
            coleta2 = 1;
          } else {
            canal1 = f.equipamentoIot.total;
            coleta1 = 1;
          }
        }

        newCanais = this.state.canais.map((elm, index) => {
          if (elm.value == canal1) {
            return {
              value: elm.value,
              text: elm.text,
              disabled: "disabled",
            };
          }

          if (elm.value == canal2) {
            return {
              value: elm.value,
              text: elm.text,
              disabled: "disabled",
            };
          }

          return {
            value: elm.value,
            text: elm.text,
          };
        });

        newColetas = this.state.coletas.map((elm, index) => {
          if (elm.value == coleta1) {
            return {
              value: elm.value,
              text: elm.text,
              disabled: "disabled",
            };
          }

          if (elm.value == coleta2) {
            return {
              value: elm.value,
              text: elm.text,
              disabled: "disabled",
            };
          }

          return {
            value: elm.value,
            text: elm.text,
          };
        });
      }

      // insere na lista,
      // eventos que estão no cadastro mas não estão na lista do equipamento
      let events = Object.values(toJS(f.events));

      if (events && events.length > 0) {
        events.map((vigencia) => {
          this.store.events
            .filter((e) => e.fk_id_table == 13)
            .forEach((element) => {
              let event = {
                codigo: element.cod,
                nome: element.descr,
                id: element.id,
              };

              if (vigencia.filter((v) => v.evento.id == event.id).length == 0) {
                f.events[vigencia[0].vigencia.id].push({
                  id: 0,
                  evento: event,
                  tempoDuracao: "00:00:00",
                  vigencia: vigencia[0].vigencia,
                });
              }
            });
        });

        this.setState({
          // SETUP
          events: f.events,
        });
      } else {
        this._fetchDefaultEvents();
      }

      this.setState({
        //FORMULARIO DE DETALHES
        state: f.state,
        estado: f.estado,
        is_batch: f.is_batch,
        is_checkout: f.is_checkout,
        idEquipamento: f.id,
        nome: f.name,
        tag: f.tag,
        areas: f.fields
          ? f.fields.map((e) => ({ id: e.id, label: e.name }))
          : [],
        turnos: f.shifts
          ? f.shifts.map((e) => ({ id: e.id, label: e.name }))
          : [],
        espera: THoursMinutes(f.wait_time),
        fifo: f.fifo,
        isMultiple: f.isMultiple,
        moMandatoryInEvents: this.state.eventos
          .map((eventsMap) => {
            if (f.moMandatoryInEvents["$mobx"].values.includes(eventsMap.id)) {
              return eventsMap;
            }
          })
          .filter((e) => e !== undefined),
        moReset: f.zerarMO,
        moAppCollet: f.moAppCollet,
        hasLinkedEquipments: f.hasLinkedEquipments,
        linkedEquipments: f.linkedEquipments
          ? f.linkedEquipments.map((i) => {
              return {
                id: i,
                label:
                  this.store.equipments.find((e) => e.id === i)?.name || "---",
              };
            })
          : [],
        multipleQuantity: f.multipleQuantity,
        requestMultiplier: f.requestMultiplier,
        customGoal: f.customGoal,
        customGoalValue: f.customGoalValue,
        equipamentos: f.grupo_equipamentos
          ? f.grupo_equipamentos.map((e) => ({ id: e.id, label: e.name }))
          : [],
        //METAS
        meta_time_mttr: f.meta_time_mttr || "",
        meta_time_mtbf: f.meta_time_mtbf || "",
        meta_time_mwt: f.meta_time_mwt || "",
        meta_time_mttp: f.meta_time_mttp || "",
        meta_time_mtbp: f.meta_time_mtbp || "",
        //OEE
        meta_oee_real_com_setup: f.meta_oee_real_com_setup,
        meta_oee_real_sem_setup: f.meta_oee_real_sem_setup,
        meta_oee_effetive_com_setup: f.meta_oee_effetive_com_setup,
        meta_oee_effetive_sem_setup: f.meta_oee_effetive_sem_setup,
        meta_oee_otimized_com_setup: f.meta_oee_otimized_com_setup,
        meta_oee_otimized_sem_setup: f.meta_oee_otimized_sem_setup,
        meta_oee_teep_com_setup: f.meta_oee_teep_com_setup,
        meta_oee_teep_sem_setup: f.meta_oee_teep_sem_setup,
        //IOT
        iotAtivo: f.equipamentoIot ? f.equipamentoIot.iotAtivo : false,
        ip: f.equipamentoIot ? f.equipamentoIot.ip : "",
        tempo_tolerancia: f.equipamentoIot ? f.equipamentoIot.tolerancia : "",
        producaoEventoText: f.equipamentoIot
          ? f.equipamentoIot.producaoEvento.id != undefined
            ? f.equipamentoIot.producaoEvento.codigo +
              " - " +
              f.equipamentoIot.producaoEvento.nome
            : ""
          : "",
        producaoEventoId: f.equipamentoIot
          ? f.equipamentoIot.producaoEvento.id
          : 0,
        tempo_micro_parada: f.equipamentoIot
          ? f.equipamentoIot.microparada
          : "",
        microParadaEventoText: f.equipamentoIot
          ? f.equipamentoIot.microParadaEvento.id != undefined
            ? f.equipamentoIot.microParadaEvento.codigo +
              " - " +
              f.equipamentoIot.microParadaEvento.nome
            : ""
          : "",
        microParadaEventoId: f.equipamentoIot
          ? f.equipamentoIot.microParadaEvento.id
          : 0,
        produzido: f.equipamentoIot ? f.equipamentoIot.produzido : -1,
        multiplicador_produzido: f.equipamentoIot
          ? f.equipamentoIot.multiplicador_produzido
          : 1,
        rejeitado: f.equipamentoIot ? f.equipamentoIot.rejeitado : -1,
        multiplicador_rejeitado: f.equipamentoIot
          ? f.equipamentoIot.multiplicador_rejeitado
          : 1,
        total: f.equipamentoIot ? f.equipamentoIot.total : -1,
        usuario: f.equipamentoIot ? f.equipamentoIot.usuario : "",
        senha: f.equipamentoIot ? f.equipamentoIot.senha : "",
        dispositivoId: f.equipamentoIot ? f.equipamentoIot.dispositivoId : "",
        tipoDispositivo: f.equipamentoIot
          ? f.equipamentoIot.tipoDispositivo
          : "",
        //Canais de Coleta
        canais: f.equipamentoIot ? newCanais : this.state.canais,
        coletas: f.equipamentoIot ? newColetas : this.state.coletas,
        canal1: canal1,
        canal2: canal2,
        coleta1: coleta1,
        coleta2: coleta2,
      });
    } else {
      this._fetchDefaultEvents();
    }

    this.getIndicatorsEquipment(f.id).then((data) => {
      const customIndicators =
        respostaValorPadraoIndicadoresPersonalizados(data);
      this.setState({
        indicators: customIndicators,
      });
    });

    this.setState({
      modal: keepModalOpen || !this.state.modal,
      field: f,
      typeModal: t,
    });
  }

  mudarMandatoryMO() {
    this.setState({
      moAppCollet: document.getElementById("select-m.o").value,
    });
  }

  selectCanais(event, campo) {
    let { canais } = this.state;
    let newCanais = canais.map((elm, index) => {
      if (
        elm.value === parseInt($("#canal_entrada_1 option:selected").val()) ||
        elm.value === parseInt($("#canal_entrada_2 option:selected").val())
      ) {
        return {
          value: elm.value,
          text: elm.text,
          disabled: "disabled",
        };
      }
      return {
        value: elm.value,
        text: elm.text,
      };
    });

    if (this.state.coleta1 >= 0) {
      this.stateColeta(
        $("#coleta_entrada_1 option:selected").val(),
        $("#canal_entrada_1 option:selected").val(),
      );
    }

    if (this.state.coleta2 >= 0) {
      this.stateColeta(
        $("#coleta_entrada_2 option:selected").val(),
        $("#canal_entrada_2 option:selected").val(),
      );
    }

    this.setState({
      [campo]: event.target.value,
      canais: newCanais,
    });
  }

  stateColeta(campo, value) {
    switch (campo) {
      case "1":
        this.setState({
          total: value,
        });
        break;
      case "2":
        this.setState({
          produzido: value,
        });
        break;
      case "3":
        this.setState({
          rejeitado: value,
        });
        break;
      default:
        break;
    }
  }

  selectColeta(event, campo) {
    this.setState({
      total: -1,
      rejeitado: -1,
      produzido: -1,
    });

    let { coletas } = this.state;
    let newColetas = coletas.map((elm, index) => {
      if (
        elm.value === parseInt($("#coleta_entrada_1 option:selected").val()) ||
        elm.value === parseInt($("#coleta_entrada_2 option:selected").val())
      ) {
        return {
          value: elm.value,
          text: elm.text,
          disabled: "disabled",
        };
      }
      return {
        value: elm.value,
        text: elm.text,
      };
    });

    if (this.state.canal1 >= 0) {
      this.stateColeta(
        $("#coleta_entrada_1 option:selected").val(),
        $("#canal_entrada_1 option:selected").val(),
      );
    }

    if (this.state.canal2 >= 0) {
      this.stateColeta(
        $("#coleta_entrada_2 option:selected").val(),
        $("#canal_entrada_2 option:selected").val(),
      );
    }

    this.setState({
      [campo]: event.target.value,
      coletas: newColetas,
    });
  }

  onCheckIoT() {
    if (!this.state.iotAtivo) {
      this.setState({ iotAtivo: true });
    } else {
      this.setState({ iotAtivo: false });
    }
  }

  onKeyDown(e) {
    if (this.autoComplete.refs.menu != undefined) {
      this.autoComplete.refs.menu.setState({
        isKeyboardFocused: true,
      });
    }
  }

  handleProducaoEvento(text) {
    this.setState({
      searchTextProducaoEvento: text,
      producaoEventoText: "",
      producaoEventoId: "",
    });

    var nomeEvento = text.split(" - ");
    var codigo = nomeEvento[0].trim().toString();

    let evento = this.store.events.find((e) => e.cod === codigo);

    if (evento != undefined) {
      this.setState({
        producaoEventoText: text,
        producaoEventoId: evento.id,
      });
    }
  }

  handleMicroParadaEvento(text) {
    this.setState({
      searchTextMicroParadaEvento: text,
      microParadaEventoText: "",
      microParadaEventoId: "",
    });

    var nomeEvento = text.split(" - ");
    var codigo = nomeEvento[0].trim().toString();

    let evento = this.store.events.find((e) => e.cod === codigo);

    if (evento != undefined) {
      this.setState({
        microParadaEventoText: text,
        microParadaEventoId: evento.id,
      });
    }
  }

  handleAreas(options) {
    this.setState({ areas: options });
  }

  handleTurnos(options) {
    this.setState({ turnos: options });
  }

  handleEquipamentos(options) {
    this.setState({ equipamentos: options });
  }

  blurEspera(value) {
    let v = value.split(":");
    if (v[1] > 59) {
      this.setState({ espera: "00:00" });
      $("#espera").focus();
      alertBox(
        this.props.t("errors.waitTimeError"),
        this.props.t("form.details.labels.waitTime"),
        "error",
      );
    }
  }

  mascara(campoState, valor, idCampo) {
    this.setState({
      [campoState]: valor,
    });
  }

  mascaraSetup(vigenciaId, valor, idCampo, index) {
    let events = this.state.events;

    events[vigenciaId][index].tempoDuracao = valor;

    this.setState({
      events: events,
    });
  }

  changeContentModal(type, obj, vigencia) {
    if (type == "up") {
      this.setState({
        typeModal: type,
        abaAtiva: 3,
        objModal: obj,
        vigenciaAtiva: vigencia || {},
      });
    } else {
      this.setState({
        modal: true,
        typeModal: type,
        objModal: obj,
      });
    }
  }

  editVigencia(changeContentModal, dadosTimeMachine) {
    changeContentModal("editVigencia", dadosTimeMachine);
  }

  formDetalhes() {
    let fieldOpts = this.store.fields.map((f) => {
      return { id: f.id, label: f.name };
    });

    let shiftOpts = this.store.shifts.map((f) => {
      return { id: f.id, label: f.name };
    });

    let grupo_equipamento = this.state.grupoEquipamentos.map((f) => {
      return { id: f.id, label: f.nome };
    });

    let state = (
      <span style={{ height: 40 }}>
        <Toggle
          style={{ width: "auto" }}
          label={this.props.t("form.details.labels.status")}
          labelPosition="right"
          type="checkbox"
          thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
          trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
          ref={"ativo"}
          id={"ativo"}
          defaultToggled={this.state.state}
          onToggle={() =>
            !this.state.state
              ? this.setState({ state: true })
              : this.setState({ state: false })
          }
        />
      </span>
    );

    let batch = (
      <span style={{ height: 40, width: "50%" }}>
        <Toggle
          style={{ width: "50%" }}
          label={this.props.t("form.details.labels.batch")}
          labelPosition="right"
          type="checkbox"
          thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
          trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
          ref={"ativo"}
          id={"ativo"}
          defaultToggled={this.state.is_batch}
          onToggle={() =>
            !this.state.is_batch
              ? this.setState({ is_batch: true })
              : this.setState({ is_batch: false })
          }
        />
      </span>
    );

    let checkout = (
      <span style={{ height: 40, width: "50%" }}>
        <Toggle
          style={{ width: "50%" }}
          label={this.props.t("form.details.labels.checkout")}
          labelPosition="right"
          type="checkbox"
          thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
          trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
          ref={"ativo"}
          id={"ativo"}
          defaultToggled={this.state.is_checkout == 1 ? true : false}
          onToggle={() =>
            !this.state.is_checkout
              ? this.setState({ is_checkout: 1 })
              : this.setState({ is_checkout: 0 })
          }
        />
      </span>
    );

    let nome = (
      <span>
        <input
          tabIndex={27}
          ref={"nome"}
          onChange={(evento) => this.setState({ nome: evento.target.value })}
          required={true}
          className={
            this.state.nome.length > 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"nome"}
          type={"text"}
          value={this.state.nome}
          autoComplete="off"
        />
        <label htmlFor={"nome"}>
          {this.props.t("form.details.labels.name")}
        </label>
        <div
          ref="validate"
          className={
            this.state.nome.length > 0 ? "validation transparent" : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    let tag = (
      <span>
        <input
          tabIndex={28}
          ref={"tag"}
          onChange={(event) => {
            this.setState({ tag: event.target.value.trim() });
          }}
          required={true}
          className={
            this.state.tag.length > 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"tag"}
          type={"text"}
          value={this.state.tag}
          autoComplete="off"
        />
        <label htmlFor={"tag"}>{this.props.t("form.details.labels.tag")}</label>
        <div
          ref="validate"
          className={
            this.state.tag.length > 0 ? "validation transparent" : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    let area = (
      <span>
        <span style={{ display: "inline-flex", margin: 0, fontSize: "12px" }}>
          {this.props.t("form.details.labels.fields").toUpperCase()}
        </span>
        <input
          type="hidden"
          ref={"areas"}
          id={"areas"}
          value={this.state.areas}
          required={true}
        />
        <MultiSelect
          isMulti
          options={fieldOpts}
          allowSelectAll={false}
          onGetOptionLabel={(option) => option.label}
          onGetOptionValue={(option) => option.id}
          onSelectOption={this.handleAreas}
          value={this.state.areas}
        />
        <div
          ref="validate"
          className={
            this.state.areas.length > 0
              ? "validation transparent"
              : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    let turnos = (
      <span>
        <span style={{ display: "inline-flex", margin: 0, fontSize: "12px" }}>
          {this.props.t("form.details.labels.shifts").toUpperCase()}
        </span>
        <input
          type="hidden"
          ref={"turnos"}
          id={"turnos"}
          value={this.state.turnos}
          required={true}
        />
        <MultiSelect
          isMulti
          options={shiftOpts}
          allowSelectAll={false}
          onGetOptionLabel={(option) => option.label}
          onGetOptionValue={(option) => option.id}
          onSelectOption={this.handleTurnos}
          value={this.state.turnos}
        />
        <div
          ref="validate"
          className={
            this.state.turnos.length > 0
              ? "validation transparent"
              : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    let espera = (
      <InputTime
        tabIndex={31}
        ref={"espera"}
        spanStyle={{ marginTop: "1.75rem" }}
        required={true}
        styles={styles}
        id={"espera"}
        onChange={(e) =>
          this.setState({ espera: MaskInterval(e.target.value) })
        }
        onBlur={(e) => this.blurEspera(this.state.espera)}
        value={this.state.espera}
        max={15}
        min={7}
        label={this.props.t("form.details.labels.waitTime")}
      />
    );

    let fifo = (
      <span style={{ marginTop: "1.75rem" }}>
        <input
          tabIndex={32}
          ref={"fifo"}
          onChange={(event) => {
            this.setState({ fifo: event.target.value.replace(/[^0-9]/, "") });
          }}
          required={true}
          className={
            this.state.fifo >= 0 || this.state.fifo.length >= 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"fifo"}
          type={"text"}
          value={this.state.fifo}
          onKeyDown={(event) => {
            this.setState({ fifo: event.target.value.replace(/[^0-9]/, "") });
          }}
          autoComplete="off"
        />
        <label htmlFor={"fifo"}>
          {this.props.t("form.details.labels.fifo")}
        </label>
        <div
          ref="validate"
          className={
            this.state.fifo >= 0 || this.state.fifo.length >= 0
              ? "validation transparent"
              : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    let grupoEquipamentos = (
      <span>
        <span style={{ display: "inline-flex", margin: 0, fontSize: "12px" }}>
          {this.props.t("form.details.labels.equipmentGroups").toUpperCase()}
        </span>
        <input
          type="hidden"
          ref={"grupoEquipamentos"}
          id={"grupoEquipamentos"}
          value={this.state.equipamentos}
          required={false}
        />
        <MultiSelect
          isMulti
          options={grupo_equipamento}
          allowSelectAll={false}
          onGetOptionLabel={(option) => option.label}
          onGetOptionValue={(option) => option.id}
          onSelectOption={this.handleEquipamentos}
          value={this.state.equipamentos}
        />
      </span>
    );

    return (
      <div>
        <div style={{ width: "49%", marginRight: 10, float: "left" }}>
          {state}

          {nome}
          {area}
          {espera}
          {grupoEquipamentos}
        </div>
        <div style={{ width: "49%", float: "left" }}>
          <div style={{ whiteSpace: "nowrap" }}>
            {batch}
            {checkout}
          </div>
          {tag}
          {turnos}
          {fifo}
        </div>
      </div>
    );
  }

  formMetas() {
    let meta_time_mttr = (
      <NMaskedTime
        ref={"meta_time_mttr"}
        onChange={(event) =>
          this.mascara("meta_time_mttr", event.target.value, "meta_time_mttr")
        }
        onBlur={(event) =>
          this.mascara("meta_time_mttr", event.target.value, "meta_time_mttr")
        }
        className={styles.balloon}
        id={"meta_time_mttr"}
        value={this.state.meta_time_mttr}
        labelName={this.props.t("form.goals.labels.mttr")}
      />
    );

    let meta_time_mtbf = (
      <NMaskedTime
        ref={"meta_time_mtbf"}
        onChange={(event) =>
          this.mascara("meta_time_mtbf", event.target.value, "meta_time_mtbf")
        }
        onBlur={(event) =>
          this.mascara("meta_time_mtbf", event.target.value, "meta_time_mtbf")
        }
        className={styles.balloon}
        id={"meta_time_mtbf"}
        value={this.state.meta_time_mtbf}
        labelName={this.props.t("form.goals.labels.mtbf")}
      />
    );

    let meta_time_mwt = (
      <NMaskedTime
        ref={"meta_time_mwt"}
        onChange={(event) =>
          this.mascara("meta_time_mwt", event.target.value, "meta_time_mwt")
        }
        onBlur={(event) =>
          this.mascara("meta_time_mwt", event.target.value, "meta_time_mwt")
        }
        className={styles.balloon}
        id={"meta_time_mwt"}
        value={this.state.meta_time_mwt}
        labelName={this.props.t("form.goals.labels.mwt")}
      />
    );

    let meta_time_mttp = (
      <NMaskedTime
        ref={"meta_time_mttp"}
        onChange={(event) =>
          this.mascara("meta_time_mttp", event.target.value, "meta_time_mttp")
        }
        onBlur={(event) =>
          this.mascara("meta_time_mttp", event.target.value, "meta_time_mttp")
        }
        className={styles.balloon}
        id={"meta_time_mttp"}
        value={this.state.meta_time_mttp}
        labelName={this.props.t("form.goals.labels.mttp")}
      />
    );

    let meta_time_mtbp = (
      <NMaskedTime
        ref={"meta_time_mtbp"}
        onChange={(event) =>
          this.mascara("meta_time_mtbp", event.target.value, "meta_time_mtbp")
        }
        onBlur={(event) =>
          this.mascara("meta_time_mtbp", event.target.value, "meta_time_mtbp")
        }
        className={styles.balloon}
        id={"meta_time_mtbp"}
        value={this.state.meta_time_mtbp}
        labelName={this.props.t("form.goals.labels.mtbp")}
      />
    );

    let indicators = (
      <CustomIndicatorForm
        sizeChunk={2}
        beautifulDisplay={true}
        setStateMeta={this.mascara}
        customIndicators={this.state.indicators}
      />
    );

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "column",
            justifyContent: "space-between",
            marginBottom: 14,
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {meta_time_mttr}
            {meta_time_mwt}
            {meta_time_mttp}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {meta_time_mtbf}
            {meta_time_mtbp}
          </div>
        </div>
        <div>{indicators}</div>
      </div>
    );
  }

  formOee() {
    //Mask inputs type number with percentage
    $(':input[type="number"]');

    let meta_oee_real_com_setup = (
      <span>
        <input
          tabIndex={19}
          ref={"meta_oee_real_com_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({ meta_oee_real_com_setup: event.target.value })
              : this.state.meta_oee_real_com_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_real_com_setup"}
          type={"number"}
          value={this.state.meta_oee_real_com_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_real_com_setup"}>
          {this.props.t("form.oee.labels.realWithSetup")}
        </label>
      </span>
    );

    let meta_oee_real_sem_setup = (
      <span>
        <input
          tabIndex={20}
          ref={"meta_oee_real_sem_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({ meta_oee_real_sem_setup: event.target.value })
              : this.state.meta_oee_real_sem_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_real_sem_setup"}
          type={"number"}
          value={this.state.meta_oee_real_sem_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_real_sem_setup"}>
          {this.props.t("form.oee.labels.realWithoutSetup")}
        </label>
      </span>
    );

    let meta_oee_effetive_com_setup = (
      <span>
        <input
          tabIndex={21}
          ref={"meta_oee_effetive_com_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({
                  meta_oee_effetive_com_setup: event.target.value,
                })
              : this.state.meta_oee_effetive_com_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_effetive_com_setup"}
          type={"number"}
          value={this.state.meta_oee_effetive_com_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_effetive_com_setup"}>
          {this.props.t("form.oee.labels.effectiveWithSetup")}
        </label>
      </span>
    );

    let meta_oee_effetive_sem_setup = (
      <span>
        <input
          tabIndex={22}
          ref={"meta_oee_effetive_sem_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({
                  meta_oee_effetive_sem_setup: event.target.value,
                })
              : this.state.meta_oee_effetive_sem_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_effetive_sem_setup"}
          type={"number"}
          value={this.state.meta_oee_effetive_sem_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_effetive_sem_setup"}>
          {this.props.t("form.oee.labels.effectiveWithoutSetup")}
        </label>
      </span>
    );

    let meta_oee_otimized_com_setup = (
      <span>
        <input
          tabIndex={23}
          ref={"meta_oee_otimized_com_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({
                  meta_oee_otimized_com_setup: event.target.value,
                })
              : this.state.meta_oee_otimized_com_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_otimized_com_setup"}
          type={"number"}
          value={this.state.meta_oee_otimized_com_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_otimized_com_setup"}>
          {this.props.t("form.oee.labels.optimizedWithSetup")}
        </label>
      </span>
    );

    let meta_oee_otimized_sem_setup = (
      <span>
        <input
          tabIndex={24}
          ref={"meta_oee_otimized_sem_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({
                  meta_oee_otimized_sem_setup: event.target.value,
                })
              : this.state.meta_oee_otimized_sem_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_otimized_sem_setup"}
          type={"number"}
          value={this.state.meta_oee_otimized_sem_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_otimized_sem_setup"}>
          {this.props.t("form.oee.labels.optimizedWithoutSetup")}
        </label>
      </span>
    );

    let meta_oee_teep_com_setup = (
      <span>
        <input
          tabIndex={25}
          ref={"meta_oee_teep_com_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({ meta_oee_teep_com_setup: event.target.value })
              : this.state.meta_oee_teep_com_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_teep_com_setup"}
          type={"number"}
          value={this.state.meta_oee_teep_com_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_teep_com_setup"}>
          {this.props.t("form.oee.labels.teepWithSetup")}
        </label>
      </span>
    );

    let meta_oee_teep_sem_setup = (
      <span>
        <input
          tabIndex={26}
          ref={"meta_oee_teep_sem_setup"}
          onChange={(event) => {
            event.target.value.length <= 6
              ? this.setState({ meta_oee_teep_sem_setup: event.target.value })
              : this.state.meta_oee_teep_sem_setup;
          }}
          required={true}
          className={styles.balloon}
          step="0.01"
          min={"0"}
          id={"meta_oee_teep_sem_setup"}
          type={"number"}
          value={this.state.meta_oee_teep_sem_setup}
          autoComplete="off"
        />
        <label htmlFor={"meta_oee_teep_sem_setup"}>
          {this.props.t("form.oee.labels.teepWithoutSetup")}
        </label>
      </span>
    );

    return (
      <div>
        <div style={{ width: "49%", marginRight: 10, float: "left" }}>
          {meta_oee_real_com_setup}
          {meta_oee_effetive_com_setup}
          {meta_oee_otimized_com_setup}
          {meta_oee_teep_com_setup}
        </div>
        <div style={{ width: "49%", float: "left" }}>
          {meta_oee_real_sem_setup}
          {meta_oee_effetive_sem_setup}
          {meta_oee_otimized_sem_setup}
          {meta_oee_teep_sem_setup}
        </div>
      </div>
    );
  }

  formSetup() {
    return (
      <FormSetup
        stateEquipamento={this.state}
        editVigencia={this.editVigencia}
        changeContentModal={this.changeContentModal}
        vigenciaAtiva={
          this.state.typeModal == "add" ? { id: 0 } : this.state.vigenciaAtiva
        }
        storeEvents={this.store.events}
        mascaraSetup={this.mascaraSetup}
        t={this.props.t}
      />
    );
  }

  selectTipoDeDispositivo(event) {
    this.setState({ tipoDispositivo: event.target.value });
  }

  allTopics(clientName) {
    return [
      `CGTV/${clientName}/${this.state.dispositivoId}/events`,
      `CGTV/${clientName}/${this.state.dispositivoId}/command`,
      `CGTV/${clientName}/${this.state.dispositivoId}/config`,
      `CGTV/${clientName}/${this.state.dispositivoId}/command/ack`,
      `CGTV/${clientName}/${this.state.dispositivoId}/config/ack`,
    ];
  }

  formIot() {
    let eventos = this.store.events.map((e) => {
      return { value: e.id, text: e.cod + " - " + e.descr };
    });

    let canaisEntrada = this.state.canais.map((e) => {
      return (
        <option key={`canais-${e.value}`} value={e.value} disabled={e.disabled}>
          {e.text}
        </option>
      );
    });

    let topicos = [WISE4050, DIGIRAIL_OEE_EVENT, DIGIRAIL_OEE_COUNTER];

    let coletaEntrada = this.state.coletas.map((e) => {
      return (
        <option
          key={`coletas-${e.value}`}
          value={e.value}
          disabled={e.disabled}
        >
          {e.text}
        </option>
      );
    });

    let ativo = (
      <span style={{ height: 40 }}>
        <Toggle
          style={{ width: "auto" }}
          label={this.props.t("form.iot.labels.active")}
          labelPosition="right"
          type="checkbox"
          thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
          trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
          ref={"ativo"}
          id={"ativo"}
          defaultToggled={this.state.iotAtivo}
          onToggle={this.onCheckIoT}
        />
      </span>
    );

    let ip = (
      <span style={{ float: "left" }}>
        <input
          tabIndex={45}
          ref={"ip"}
          placeholder={"     0.0.0.0:0000"}
          onChange={(event) => {
            this.setState({ ip: event.target.value });
          }}
          required={this.state.iotAtivo}
          className={
            this.state.iotAtivo && this.state.ip.length == 0
              ? styles.balloon + " " + styles.required
              : styles.balloon
          }
          id={"ip"}
          type={"text"}
          value={this.state.ip}
          onKeyDown={(event) => {
            this.setState({ ip: event.target.value });
          }}
          autoComplete="off"
          min={7}
          pattern={"^([0-9]{1,3}.){3}[0-9]{1,3}$"}
        />
        <label htmlFor={"ip"} style={{ width: "70px" }}>
          {this.props.t("form.iot.labels.ip")}
        </label>
        {
          <div
            ref="validate"
            className={
              this.state.iotAtivo && this.state.ip.length == 0
                ? "validation"
                : "validation transparent"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let tempo_tolerancia = (
      <InputTime
        tabIndex={46}
        ref={"tempo_tolerancia"}
        onChange={(event) => {
          this.setState({
            tempo_tolerancia: MaskHoraMinutoSegundo(event.target.value),
          });
        }}
        required={this.state.iotAtivo}
        styles={styles}
        id={"tempo_tolerancia"}
        value={
          this.state.tempo_tolerancia == ""
            ? "00:00:00"
            : this.state.tempo_tolerancia
        }
        label={this.props.t("form.iot.labels.toleranceTime")}
      />
    );

    let evento_producao = (
      <div
        style={
          this.state.iotAtivo && this.state.producaoEventoId == 0
            ? { marginBottom: "-10px", marginLeft: "12px", marginTop: "-20px" }
            : { marginBottom: "18px", marginLeft: "12px", marginTop: "-20px" }
        }
      >
        <AutoComplete
          dataSource={eventos}
          fullWidth={true}
          tabIndex={48}
          floatingLabelText={this.props.t("form.iot.labels.productionEvent")}
          underlineFocusStyle={{ borderColor: "#000000" }}
          id={"evento_producao"}
          name={"evento_producao"}
          required={this.state.iotAtivo}
          filter={AutoComplete.fuzzyFilter}
          searchText={
            this.state.searchTextProducaoEvento == ""
              ? this.state.producaoEventoText
              : this.state.searchTextProducaoEvento
          }
          onUpdateInput={this.handleProducaoEvento}
          onClick={() =>
            this.setState({
              producaoEventoText: "",
              producaoEventoId: 0,
              searchTextProducaoEvento: "",
            })
          }
          onKeyDown={this.onKeyDown.bind(this)}
          ref={(ref) => (this.autoComplete = ref)}
          errorText={
            this.state.iotAtivo && this.state.producaoEventoId == 0
              ? this.props.t("errors.requiredField", { ns: "validation" })
              : null
          }
          floatingLabelFocusStyle={{ fontSize: ".9rem" }}
          floatingLabelStyle={{
            paddingLeft: "14px",
            color: "#5B5B5B",
            fontSize: ".9rem",
            fontFamily: "inherit",
            textTransform: "uppercase",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            top: "35px",
          }}
          openOnFocus={true}
          popoverProps={{ className: "autocomplete", tabIndex: 1 }}
        />
      </div>
    );

    let tempo_micro_parada = (
      <InputTime
        tabIndex={47}
        ref={"tempo_micro_parada"}
        onChange={(event) => {
          this.setState({
            tempo_micro_parada: MaskHoraMinutoSegundo(event.target.value),
          });
        }}
        required={this.state.iotAtivo}
        styles={styles}
        id={"tempo_micro_parada"}
        value={
          this.state.tempo_micro_parada == ""
            ? "00:00:00"
            : this.state.tempo_micro_parada
        }
        label={this.props.t("form.iot.labels.microStopTime")}
      />
    );

    let evento_micro_parada = (
      <div
        style={
          this.state.iotAtivo && this.state.microParadaEventoId == 0
            ? { marginBottom: "-10px", marginLeft: "12px", marginTop: "-30px" }
            : { marginBottom: "18px", marginLeft: "12px", marginTop: "-20px" }
        }
      >
        <AutoComplete
          dataSource={eventos}
          fullWidth={true}
          tabIndex={48}
          floatingLabelText={this.props.t("form.iot.labels.microStopEvent")}
          underlineFocusStyle={{ borderColor: "#000000" }}
          id={"evento_micro_parada"}
          name={"evento_micro_parada"}
          required={this.state.iotAtivo}
          filter={AutoComplete.fuzzyFilter}
          searchText={
            this.state.searchTextMicroParadaEvento == ""
              ? this.state.microParadaEventoText
              : this.state.searchTextMicroParadaEvento
          }
          onUpdateInput={this.handleMicroParadaEvento}
          onClick={() =>
            this.setState({
              microParadaEventoText: "",
              microParadaEventoId: 0,
              searchTextMicroParadaEvento: "",
            })
          }
          onKeyDown={this.onKeyDown.bind(this)}
          ref={(ref) => (this.autoComplete = ref)}
          errorText={
            this.state.iotAtivo && this.state.microParadaEventoId == 0
              ? this.props.t("errors.requiredField", { ns: "validation" })
              : null
          }
          floatingLabelFocusStyle={{ fontSize: ".9rem" }}
          floatingLabelStyle={{
            paddingLeft: "14px",
            color: "#5B5B5B",
            fontSize: ".9rem",
            fontFamily: "inherit",
            textTransform: "uppercase",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            top: "35px",
          }}
          openOnFocus={true}
          popoverProps={{ className: "autocomplete", tabIndex: 1 }}
        />
      </div>
    );

    let canal_entrada_1 = (
      <span className={styles.spanBalloonSelect + " " + styles.arrowSelect}>
        <select
          tabIndex={49}
          className={
            !this.state.iotAtivo || this.state.canal1 !== ""
              ? styles.balloonSelected
              : styles.balloonSelected + " " + styles.required
          }
          style={{ borderBottom: "1px solid #CCC", float: "left" }}
          ref={"canal_entrada_1"}
          id={"canal_entrada_1"}
          value={this.state.canal1}
          onChange={(event) => this.selectCanais(event, "canal1")}
          required={this.state.iotAtivo}
        >
          <option value="" disabled>
            {this.props.t("messages.chooseAnOption", { ns: "common" })}
          </option>
          {canaisEntrada}
        </select>
        <label htmlFor={"canal_entrada_1"}>
          {this.props.t("form.iot.labels.channelInputNumber")} 1
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.canal1 !== ""
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let coleta_entrada_1 = (
      <span className={styles.spanBalloonSelect + " " + styles.arrowSelect}>
        <select
          tabIndex={50}
          defaultValue=""
          className={
            !this.state.iotAtivo || this.state.coleta1 !== ""
              ? styles.balloonSelected
              : styles.balloonSelected + " " + styles.required
          }
          style={{ borderBottom: "1px solid #CCC" }}
          ref={"coleta_entrada_1"}
          id={"coleta_entrada_1"}
          value={this.state.coleta1}
          onChange={(event) => this.selectColeta(event, "coleta1")}
          required={this.state.iotAtivo}
        >
          <option value="" disabled>
            {this.props.t("messages.chooseAnOption", { ns: "common" })}
          </option>
          {coletaEntrada}
        </select>
        <label htmlFor={"coleta_entrada_1"}>
          {this.props.t("form.iot.labels.channelInputData")} 1
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.coleta1 !== ""
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let canal_entrada_2 = (
      <span className={styles.spanBalloonSelect + " " + styles.arrowSelect}>
        <select
          tabIndex={51}
          defaultValue=""
          className={
            !this.state.iotAtivo || this.state.canal2 !== ""
              ? styles.balloonSelected
              : styles.balloonSelected + " " + styles.required
          }
          style={{ borderBottom: "1px solid #CCC", float: "left" }}
          ref={"canal_entrada_2"}
          id={"canal_entrada_2"}
          value={this.state.canal2}
          onChange={(event) => this.selectCanais(event, "canal2")}
          required={this.state.iotAtivo}
        >
          <option value="" disabled>
            {this.props.t("messages.chooseAnOption", { ns: "common" })}
          </option>
          {canaisEntrada}
        </select>
        <label htmlFor={"canal_entrada_2"}>
          {this.props.t("form.iot.labels.channelInputNumber")} 2
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.canal2 !== ""
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let coleta_entrada_2 = (
      <span className={styles.spanBalloonSelect + " " + styles.arrowSelect}>
        <select
          tabIndex={52}
          defaultValue=""
          className={
            !this.state.iotAtivo || this.state.coleta2 !== ""
              ? styles.balloonSelected
              : styles.balloonSelected + " " + styles.required
          }
          style={{ borderBottom: "1px solid #CCC" }}
          ref={"coleta_entrada_2"}
          id={"coleta_entrada_2"}
          value={this.state.coleta2}
          onChange={(event) => this.selectColeta(event, "coleta2")}
          required={this.state.iotAtivo}
        >
          <option value="" disabled>
            {this.props.t("messages.chooseAnOption", { ns: "common" })}
          </option>
          {coletaEntrada}
        </select>
        <label htmlFor={"coleta_entrada_2"}>
          {this.props.t("form.iot.labels.channelInputData")} 2
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.coleta2 !== ""
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let usuario = (
      <span>
        <input
          tabIndex={53}
          ref={"usuario"}
          onChange={(event) => {
            this.setState({ usuario: event.target.value });
          }}
          required={this.state.iotAtivo}
          className={
            !this.state.iotAtivo || this.state.usuario.length > 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"usuario"}
          type={"text"}
          value={this.state.usuario}
          autoComplete="off"
          name="user"
          maxLength={15}
        />
        <label htmlFor={"usuario"} style={{ width: "70px" }}>
          {this.props.t("form.iot.labels.iotUsername")}
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.usuario.length > 0
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let senha = (
      <span>
        <input
          tabIndex={54}
          ref={"senha"}
          style={{ marginTop: "0.125rem" }}
          onChange={(event) => {
            this.setState({ senha: event.target.value });
          }}
          required={this.state.iotAtivo}
          className={
            !this.state.iotAtivo || this.state.senha.length > 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"senha"}
          type={"password"}
          value={this.state.senha}
          onKeyDown={(event) => {
            this.setState({ senha: event.target.value });
          }}
          autoComplete="off"
        />
        <label htmlFor={"senha"} style={{ width: "70px" }}>
          {this.props.t("form.iot.labels.iotPassword")}
        </label>
        {!this.state.iotAtivo || this.state.senha.length > 0 ? null : (
          <div ref="validate" className={"validation"}>
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        )}
      </span>
    );

    let tipoDispositivo = (
      <span className={styles.spanBalloonSelect + " " + styles.arrowSelect}>
        <select
          tabIndex={55}
          className={`${styles.balloon} ${this.state.iotAtivo && !this.state.tipoDispositivo ? styles.required : ""}`}
          style={{ borderBottom: "1px solid #CCC" }}
          ref={"tipoDispositivo"}
          id={"tipoDispositivo"}
          value={this.state.tipoDispositivo}
          onChange={(event) => this.selectTipoDeDispositivo(event)}
          required={this.state.iotAtivo}
        >
          <option value="" disabled></option>
          {topicos.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label htmlFor={"tipoDispositivo"}>
          {this.props.t("form.iot.labels.iotBrand")}
        </label>
      </span>
    );

    let dispositivoId =
      this.state.tipoDispositivo !== WISE4050 ? (
        <span>
          <input
            tabIndex={56}
            ref={"dispositivoId"}
            onChange={(event) => {
              this.setState({ dispositivoId: event.target.value });
            }}
            required={this.state.iotAtivo}
            className={
              !this.state.iotAtivo || this.state.dispositivoId?.length > 0
                ? styles.balloon
                : styles.balloon + " " + styles.required
            }
            id={"dispositivoId"}
            type={"text"}
            value={this.state.dispositivoId}
            autoComplete="off"
            maxLength={20}
          />
          <label htmlFor={"dispositivoId"} style={{ width: "70px" }}>
            {this.props.t("form.iot.labels.deviceId")}
          </label>
          {!this.state.iotAtivo ||
          this.state.dispositivoId?.length > 0 ? null : (
            <div ref="validate" className={"validation"}>
              {this.props.t("errors.requiredField", { ns: "validation" })}
            </div>
          )}
        </span>
      ) : null;

    let topicosInformativos =
      this.state.tipoDispositivo !== WISE4050 ? (
        <span>
          {this.props.t("form.iot.labels.topics")}
          <ul>
            {this.allTopics(utils.clientTenantName()).map((topic, index) => {
              return (
                <li style={{ float: "left" }} key={index}>
                  {topic}
                </li>
              );
            })}
          </ul>
        </span>
      ) : null;

    let multiplicador_produzido = (
      <span>
        <input
          style={{ float: "right" }}
          tabIndex={58}
          ref={"multiplicador_produzido"}
          onChange={(event) => {
            this.setState({ multiplicador_produzido: event.target.value });
          }}
          required={this.state.iotAtivo}
          className={
            !this.state.iotAtivo || this.state.multiplicador_produzido != null
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"multiplicador_produzido"}
          type={"number"}
          value={this.state.multiplicador_produzido}
          autoComplete="off"
          name="multiplicador_produzido"
          maxLength={5}
        />
        <label htmlFor={"multiplicador_produzido"} style={{ width: "70px" }}>
          {this.props.t("form.iot.labels.multiplier")} 1
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.multiplicador_produzido != null
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    let multiplicador_rejeitado = (
      <span>
        <input
          style={{ float: "right" }}
          tabIndex={59}
          ref={"multiplicador_rejeitado"}
          onChange={(event) => {
            this.setState({ multiplicador_rejeitado: event.target.value });
          }}
          required={this.state.iotAtivo}
          className={
            !this.state.iotAtivo || this.state.multiplicador_rejeitado != null
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"multiplicador_rejeitado"}
          type={"number"}
          value={this.state.multiplicador_rejeitado}
          autoComplete="off"
          name="multiplicador_rejeitado"
        />
        <label htmlFor={"multiplicador_rejeitado"} style={{ width: "70px" }}>
          {this.props.t("form.iot.labels.multiplier")} 2
        </label>
        {
          <div
            ref="validate"
            className={
              !this.state.iotAtivo || this.state.multiplicador_rejeitado != null
                ? "validation transparent"
                : "validation"
            }
          >
            {this.props.t("errors.requiredField", { ns: "validation" })}
          </div>
        }
      </span>
    );

    return (
      <div>
        <div style={{ width: "100%", float: "left" }}>{ativo}</div>
        <div style={{ width: "49%", marginRight: 10, float: "left" }}>
          {tempo_tolerancia}
          {tempo_micro_parada}
          {coleta_entrada_1}
          {coleta_entrada_2}
          {tipoDispositivo}
          {usuario}
          {dispositivoId}
          {topicosInformativos}
        </div>
        <div style={{ width: "49%", float: "left" }}>
          {evento_producao}
          {evento_micro_parada}
          <div style={{ display: "inline-flex" }}>
            {canal_entrada_1}
            {multiplicador_produzido}
          </div>
          <div style={{ display: "inline-flex", marginTop: "0.725rem" }}>
            {canal_entrada_2}
            {multiplicador_rejeitado}
          </div>
          <div style={{ marginTop: "5px" }}>
            {ip}
            {senha}
          </div>
        </div>
      </div>
    );
  }

  formTecnical() {
    let multiple = (
      <span style={{ height: 40 }}>
        <Toggle
          style={{ width: "auto" }}
          label={this.props.t("form.technical.labels.isMultiple")}
          labelPosition="right"
          type="checkbox"
          thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
          trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
          ref={"ativo"}
          id={"ativo"}
          defaultToggled={this.state.isMultiple}
          onToggle={() =>
            !this.state.isMultiple
              ? this.setState({ isMultiple: true })
              : this.setState({ isMultiple: false })
          }
        />
      </span>
    );

    let multipleQuantity = (
      <span style={{ width: "46.4%", float: "left" }}>
        <input
          ref={"quantidade"}
          onChange={(event) => {
            this.setState({
              multipleQuantity: event.target.value.replace(/[^0-9]/, ""),
            });
          }}
          required={true}
          className={
            this.state.multipleQuantity >= 0 ||
            this.state.multipleQuantity.length >= 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"quantidade"}
          type={"text"}
          value={this.state.multipleQuantity}
          onKeyDown={(event) => {
            this.setState({
              multipleQuantity: event.target.value.replace(/[^0-9]/, ""),
            });
          }}
          autoComplete="off"
        />
        <label htmlFor={"quantidade"}>
          {this.props.t("form.technical.labels.maxConcurrent")}
        </label>
        <div
          ref="validate"
          className={
            this.state.multipleQuantity > 0
              ? "validation transparent"
              : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    let customGoal = (
      <span style={{ height: 40 }}>
        <Toggle
          style={{ width: "auto", textAlign: "start" }}
          label={this.props.t("form.technical.labels.customGoal")}
          labelPosition="right"
          type="checkbox"
          thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
          trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
          ref={"activeCustomGoal"}
          id={"activeCustomGoal"}
          defaultToggled={this.state.customGoal}
          onToggle={() =>
            !this.state.customGoal
              ? this.setState({ customGoal: true, customGoalValue: 0 })
              : this.setState({ customGoal: false, customGoalValue: 0 })
          }
        />
      </span>
    );

    let customGoalValue = (
      <span style={{ width: "46.4%", float: "left" }}>
        <input
          ref={"goalValue"}
          onChange={(event) => {
            this.setState({
              customGoalValue: event.target.value.replace(/[^0-9]/, ""),
            });
          }}
          required={true}
          className={
            this.state.customGoalValue >= 0 ||
            this.state.multipleQuantity.length >= 0
              ? styles.balloon
              : styles.balloon + " " + styles.required
          }
          id={"goalValue"}
          type={"text"}
          value={this.state.customGoalValue}
          onKeyDown={(event) => {
            this.setState({
              customGoalValue: event.target.value.replace(/[^0-9]/, ""),
            });
          }}
          autoComplete="off"
        />
        <label htmlFor={"goalValue"}>
          {this.props.t("form.technical.labels.customGoalValue")}
        </label>
        <div
          ref="validate"
          className={
            this.state.customGoalValue > 0
              ? "validation transparent"
              : "validation"
          }
        >
          {this.props.t("errors.requiredField", { ns: "validation" })}
        </div>
      </span>
    );

    return (
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div style={{ width: "49%", float: "left", height: "min-content" }}>
          {multiple}
          {this.state.isMultiple && multipleQuantity}
          {customGoal}
          {this.state.customGoal && customGoalValue}
        </div>

        <div style={{ width: "49%", float: "left" }}>
          <span style={{ height: 40 }}>
            <Toggle
              style={{ width: "auto" }}
              label={this.props.t("form.technical.labels.conversionByNote")}
              labelPosition="right"
              type="checkbox"
              thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
              trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
              ref={"hasConversion"}
              id={"hasConversion"}
              defaultToggled={this.state.requestMultiplier}
              onToggle={() =>
                !this.state.requestMultiplier
                  ? this.setState({ requestMultiplier: true })
                  : this.setState({ requestMultiplier: false })
              }
            />
          </span>

          {this.state.moAppCollet != 0 && this.state.moAppCollet != -1 && (
            <span style={{ height: 40, bottom: "-6rem", marginLeft: 1 }}>
              <Toggle
                style={{ width: "auto" }}
                label={this.props.t("form.technical.labels.moResetEvent")}
                labelPosition="right"
                type="checkbox"
                thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
                trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
                onToggle={() => {
                  this.setState({
                    moReset: !this.state.moReset,
                  });
                }}
                defaultToggled={this.state.moReset}
              />
            </span>
          )}
        </div>

        <div
          style={{
            width: "49%",
            float: "left",
            display: "flex",
            flexDirection: "column",
            gap: "29px",
            marginTop: "2rem",
          }}
        >
          <>
            <div>
              <label
                style={{
                  display: "block",
                }}
                htmlFor="select-m.o"
              >
                {this.props.t("form.technical.labels.moColletApp")}
              </label>
              <select
                tabIndex={90}
                id="select-m.o"
                style={{ borderBottom: "1px solid #CCC", float: "left" }}
                className={styles.balloonSelected}
                onChange={() => this.mudarMandatoryMO()}
                value={this.state.moAppCollet}
              >
                <option
                  key={"notShowMO"}
                  value={0}
                  onClick={() =>
                    this.setState({
                      moAppCollet: 0,
                    })
                  }
                >
                  {this.props.t("form.technical.labels.moOptionDontShowField")}
                </option>
                <option
                  key={"notMandatoryMO"}
                  value={1}
                  onClick={() =>
                    this.setState({
                      moAppCollet: 1,
                    })
                  }
                >
                  {this.props.t("form.technical.labels.moDontMandatory")}
                </option>
                <option
                  key={"mandatoryMO"}
                  value={2}
                  onClick={() =>
                    this.setState({
                      moAppCollet: 2,
                    })
                  }
                >
                  {this.props.t("form.technical.labels.moMandatory")}
                </option>
              </select>
            </div>
          </>
          {this.state.moAppCollet == 2 && (
            <div>
              <span>
                <span
                  style={{
                    display: "inline-flex",
                    margin: 0,
                    fontSize: "12px",
                  }}
                >
                  {this.props.t("form.technical.labels.moMandatoryInEvents")}
                </span>
                <input
                  type="hidden"
                  ref={"moMandatoryInEvents"}
                  id={"moMandatoryInEvents"}
                  value={this.state.moMandatoryInEvents}
                  required={true}
                />
                <MultiSelect
                  isMulti
                  options={this.state.eventos}
                  allowSelectAll={false}
                  onGetOptionLabel={(option) => option.nome}
                  onGetOptionValue={(option) => option.id}
                  onSelectOption={(value) => {
                    this.setState({
                      moMandatoryInEvents: value,
                    });
                  }}
                  value={this.state.moMandatoryInEvents}
                />
                <div
                  ref="validate"
                  className={
                    this.state.moMandatoryInEvents.length > 0
                      ? "validation transparent"
                      : "validation"
                  }
                >
                  {this.props.t("errors.requiredField", { ns: "validation" })}
                </div>
              </span>
            </div>
          )}
          <span>
            <Toggle
              style={{ width: "auto" }}
              label={this.props.t("form.technical.labels.hasLinkedEquipments")}
              labelPosition="right"
              type="checkbox"
              thumbSwitchedStyle={{ backgroundColor: "#2D4F7F" }}
              trackSwitchedStyle={{ backgroundColor: "#3E7AD1" }}
              ref={"hasLinkedEquipment"}
              id={"hasLinkedEquipment"}
              defaultToggled={this.state.hasLinkedEquipments}
              onToggle={() =>
                !this.state.hasLinkedEquipments
                  ? this.setState({ hasLinkedEquipments: true })
                  : this.setState({ hasLinkedEquipments: false })
              }
            />
          </span>
          {this.state.hasLinkedEquipments && (
            <span>
              <span
                style={{ display: "inline-flex", margin: 0, fontSize: "12px" }}
              >
                {this.props
                  .t("form.technical.labels.linkEquipments")
                  .toUpperCase()}
              </span>
              <input
                type="hidden"
                ref={"linkedEquipments"}
                id={"linkedEquipments"}
                value={this.state.linkedEquipments}
                required={true}
              />
              <MultiSelect
                isMulti
                options={this.store.equipments.filter(e => e.id !== this.state.idEquipamento).map((equipment) => ({
                  label: equipment.name,
                  id: equipment.id,
                }))}
                allowSelectAll={false}
                onGetOptionLabel={(option) => option.label}
                onGetOptionValue={(option) => option.id}
                onSelectOption={(value, action) => {
                  if (
                    this.state.linkedEquipments.length === 2 &&
                    action === "select-option"
                  ) {
                    return;
                  }
                  this.setState({ linkedEquipments: value });
                }}
                value={this.state.linkedEquipments}
              />
              <div
                ref="validate"
                className={
                  !this.state.hasLinkedEquipments ||
                  (this.state.hasLinkedEquipments &&
                    this.state.linkedEquipments.length > 0)
                    ? "validation transparent"
                    : "validation"
                }
              >
                {this.props.t("errors.requiredField", { ns: "validation" })}
              </div>
            </span>
          )}
        </div>
      </div>
    );
  }

  validarForm() {
    var valido = false;
    valido =
      this.validationsForm(valido) &&
      this.iotFields(valido) &&
      this.linkedEquipmentValidation(valido);

    return valido;
  }

  linkedEquipmentValidation(valido) {
    if (
      this.state.hasLinkedEquipments &&
      this.state.linkedEquipments.length === 0
    )
      return false;

    valido = true;
    return valido;
  }

  iotFields(valido) {
    if (!this.state.iotAtivo) return true;

    if (
      this.state.ip != "" &&
      this.state.tempo_tolerancia != "" &&
      this.state.tempo_micro_parada != "" &&
      this.state.producaoEventoId != 0 &&
      this.state.microParadaEventoId != 0 &&
      this.state.usuario != "" &&
      this.state.senha &&
      (this.state.produzido != -1 ||
        this.state.rejeitado != -1 ||
        this.state.total != -1) &&
      this.state.tipoDispositivo != "" &&
      this.state.multiplicador_produzido != null &&
      this.state.multiplicador_rejeitado != null
    ) {
      valido = true;

      if (valido && this.state.tipoDispositivo !== WISE4050) {
        valido = this.state.dispositivoId != "";
      }
    }

    return valido;
  }

  validationsForm(valido) {
    if (
      this.state.nome != "" &&
      this.state.tag != "" &&
      this.state.areas.length != 0 &&
      this.state.turnos.length != 0 &&
      this.state.espera != "" &&
      this.state.fifo != "" &&
      (this.state.moAppCollet === 2
        ? this.state.moMandatoryInEvents.length > 0
        : true) &&
      (!this.state.isMultiple ||
        (this.state.isMultiple && this.state.multipleQuantity > 0)) &&
      (!this.state.customGoal ||
        (this.state.customGoal && this.state.customGoalValue > 0))
    ) {
      valido = true;
    }

    return valido;
  }

  clearState() {
    this.setState(
      {
        modal: !this.state.modal,
        field: "",
        idEquipamento: 0,
        events: [
          [
            {
              vigencia: { id: 0 },
              tempoDuracao: "00:00:00",
              evento: { id: 0, nome: "" },
            },
          ],
        ],
        equipments_events: [],
        ip: "",
        canais: [
          { value: 0, text: 0 },
          { value: 1, text: 1 },
          { value: 2, text: 2 },
          { value: 3, text: 3 },
        ],
        coletas: [
          { value: 1, text: this.props.t("form.iot.options.totalAmount") },
          { value: 2, text: this.props.t("form.iot.options.approvedAmount") },
          { value: 3, text: this.props.t("form.iot.options.rejectedAmount") },
        ],
        canal1: "",
        canal2: "",
        coleta1: "",
        coleta2: "",
        iotAtivo: false,
        produzido: -1,
        rejeitado: -1,
        total: -1,
        eventoText: "",
        eventoId: "",
        estado: true,
        state: true,
        is_batch: false,
        is_checkout: 0,
        nome: "",
        tag: "",
        areas: [],
        turnos: [],
        tempo_espera: "",
        fifo: 5,
        equipamentos: [],
        usuario: "",
        senha: "",
        dispositivoId: "",
        tipoDispositivo: "",
        multiplicador_produzido: 1,
        multiplicador_rejeitado: 1,
        moMandatoryInEvents: [],
        moReset: false,
        moAppCollet: undefined,
        producaoEventoText: "",
        producaoEventoId: 0,
        microParadaEventoText: "",
        microParadaEventoId: 0,
        espera: "00:00",
        tempo_tolerancia: "",
        tempo_micro_parada: "",
        meta_time_medium_setup: "",
        meta_time_performance_geral: "",
        meta_time_troca_lote: "",
        meta_time_performance_setup_completo: "",
        meta_time_mttr: "",
        meta_time_mtbf: "",
        meta_time_mwt: "",
        meta_time_mttp: "",
        meta_time_mtbp: "",
        meta_time_preventive_corretive: "",
        meta_oee_real_com_setup: 0,
        meta_oee_real_sem_setup: 0,
        meta_oee_effetive_com_setup: 0,
        meta_oee_effetive_sem_setup: 0,
        meta_oee_otimized_com_setup: 0,
        meta_oee_otimized_sem_setup: 0,
        meta_oee_teep_com_setup: 0,
        meta_oee_teep_sem_setup: 0,
        abaAtiva: 0,
        vigenciaAtiva: {},
        isMultiple: false,
        multipleQuantity: 0,
        customGoal: false,
        customGoalValue: 0,
        hasLinkedEquipments: false,
        linkedEquipments: [],
        searchTextProducaoEvento: "",
        searchTextMicroParadaEvento: "",
      },
      () => this.fetchEquipamento(),
    );
  }

  camposForm() {
    return {
      detalhes: [
        {
          title: this.props.t("form.details.title"),
          type: "custom",
          render: this.formDetalhes,
          validate: this.validarFormDetalhes,
          data: {
            id: this.state.idEquipamento,
            name: this.state.nome.trim(),
            is_batch: this.state.is_batch,
            is_checkout: this.state.is_checkout,
            tag: this.state.tag,
            state: this.state.state,
            wait_time: this.state.espera,
            fifo: this.state.fifo,
            shifts: this.state.turnos,
            grupo_equipamentos: this.state.equipamentos,
            fields: this.state.areas,
          },
        },
      ],
      metas: [
        {
          title: this.props.t("form.goals.title"),
          type: "custom",
          render: this.formMetas,
          validate: true,
          data: {
            meta_time_mttr: this.state.meta_time_mttr,
            meta_time_mtbf: this.state.meta_time_mtbf,
            meta_time_mwt: this.state.meta_time_mwt,
            meta_time_mttp: this.state.meta_time_mttp,
            meta_time_mtbp: this.state.meta_time_mtbp,
            indicadores: this.state.indicators,
          },
        },
      ],
      oee: [
        {
          title: this.props.t("form.oee.title"),
          type: "custom",
          render: this.formOee,
          validate: true,
          data: {
            meta_oee_real_com_setup: this.state.meta_oee_real_com_setup,
            meta_oee_real_sem_setup: this.state.meta_oee_real_sem_setup,
            meta_oee_effetive_com_setup: this.state.meta_oee_effetive_com_setup,
            meta_oee_effetive_sem_setup: this.state.meta_oee_effetive_sem_setup,
            meta_oee_otimized_com_setup: this.state.meta_oee_otimized_com_setup,
            meta_oee_otimized_sem_setup: this.state.meta_oee_otimized_sem_setup,
            meta_oee_teep_com_setup: this.state.meta_oee_teep_com_setup,
            meta_oee_teep_sem_setup: this.state.meta_oee_teep_sem_setup,
          },
        },
      ],
      setup: [
        {
          title: this.props.t("form.setup.title"),
          type: "custom",
          render: this.formSetup,
          validate: true,
          data: {
            eventos: this.state.events,
          },
        },
      ],
      iot: [
        {
          title: this.props.t("form.iot.title"),
          type: "custom",
          render: this.formIot,
          validate: this.validarForm,
          data: {
            ativo: this.state.iotAtivo,
            ip: this.state.ip.trim(),
            tempo_tolerancia: this.state.tempo_tolerancia,
            producaoEventoId: this.state.producaoEventoId,
            tempo_micro_parada: this.state.tempo_micro_parada,
            microParadaEventoId: this.state.microParadaEventoId,
            produzido: this.state.produzido,
            multiplicador_produzido: this.state.multiplicador_produzido,
            rejeitado: this.state.rejeitado,
            multiplicador_rejeitado: this.state.multiplicador_rejeitado,
            tipoDispositivo: this.state.tipoDispositivo,
            dispositivoId: this.state.dispositivoId,
            total: this.state.total,
            usuario: this.state.usuario.trim(),
            senha: this.state.senha,
          },
        },
      ],
      tecnico: [
        {
          title: this.props.t("form.technical.title"),
          type: "custom",
          render: this.formTecnical,
          validade: this.validarForm,
          data: {
            isMultiple: this.state.isMultiple,
            multipleQuantity: this.state.multipleQuantity,
            requestMultiplier: this.state.requestMultiplier,
            customGoal: this.state.customGoal,
            customGoalValue: this.state.customGoalValue,
            tipo_coleta_mo: {
              eventosIds: this.state.moMandatoryInEvents.map((e) => e.id),
              zerarMO: this.state.moReset,
              tipoColetaMO: (() => this.state.moAppCollet)(),
            },
            hasLinkedEquipments: this.state.hasLinkedEquipments,
            linkedEquipments: this.state.linkedEquipments,
          },
        },
      ],
    };
  }

  mapearArrayItens(itens) {
    return itens
      .sort((a, b) => moment(a.dataEfetivo) - moment(b.dataEfetivo))
      .map((item) => {
        let efetivo = moment(item.dataEfetivo).format("L");
        return {
          text: efetivo,
          ativo: item.ativo,
          dataCriacao: item.dataCriacao,
          dataEfetivo: item.dataEfetivo,
          dataFim: item.dataFim
            ? moment(item.dataFim).format("YYYY-MM-DD HH:mm:ss")
            : null,
          id: item.id,
          observacao: item.observacao,
          originalId: item.originalId,
          originalUuid: item.originalUuid,
          usuario: item.usuario,
          uuid: item.uuid,
          vigenciaAnterior: { indexAnterior: null, date: null },
          destinoId: item.destino_id ? item.destino_id : item.destinoId,
          vigenciaAtiva: item.dataFim == null ? true : false,
          novo: item.novo,
          showTooltip: item.showTooltip,
        };
      });
  }

  setStateEquipamento(novosEventos) {
    this.setState({
      events: novosEventos,
    });
  }

  setStateObjModalAoInserirTimeMachine(timeMachineInserido) {
    this.setState({
      objModal: timeMachineInserido,
    });
  }

  contentModal() {
    if (this.state.typeModal == "loading") {
      return (
        <div
          className="modal"
          id={styles.customLoading}
          style={{ width: "80px", height: "100%" }}
        >
          <MDSpinner singleColor="#2D4F7F" size={60} borderSize={7} />
        </div>
      );
    }

    if (this.state.typeModal == "Equipamento_Vinculado_UI") {
      const EquipamentoPossuiVinculoUI = (
        <div>
          <h2 className="bolded-text">{this.state.msgDelete}</h2>
        </div>
      );

      return (
        <Quest
          styleModal={{ width: "auto" }}
          question={EquipamentoPossuiVinculoUI}
          onlyOkButton={true}
          simText={"Ok"}
          handleModal={this.handleModal}
          action={this.handleModal}
        />
      );
    }

    if (this.state.typeModal == "del") {
      return (
        <DeleteItem
          {...this.state}
          delete={this.delete}
          handleModal={this.handleModal}
          msgModal={this.state.msgDelete}
        />
      );
    } else if (this.state.typeModal == "editVigencia") {
      //Normalizando meus dados para o molde do modalItens
      var arrayItens = mapearModal(this.state.objModal, "dataEfetivo");
      arrayItens = this.mapearArrayItens(this.state.objModal);

      //Iniciando tanto os atributos iniciais do ja settado
      this.storeTimeMachineSetup.setInitialAttributes(arrayItens);

      this.storeModalItens.setCustomArray(arrayItens);
      let vigenciaAtual = this.state.objModal.find(
        (equipamentoEvento) => equipamentoEvento.dataFim == null,
      );

      if (vigenciaAtual == null) {
        let maxId = Math.max(...this.state.objModal.map((item) => item.id), 0);
        vigenciaAtual = this.state.objModal.find((f) => f.id == maxId);
      }

      let tipoTimeMachine = "equipamento-evento";
      let referencia = "EquipamentoEventos";

      const right = (
        <ModalRightContainer>
          <FormModalVigenciasGenerico
            storeVigencia={this.storeTimeMachineSetup}
            data={this.state.objModal[0].dataEfetivo}
            ativa={!this.state.objModal[0].ativo}
            vigenciaAtual={vigenciaAtual}
            setStateEquipamento={this.setStateEquipamento}
            idEquipamento={this.state.idEquipamento}
            changeContentModal={this.changeContentModal}
            currentContent={this.state.field}
            endpoints={this.endpoints}
            fetchEquipamento={this.onSaveFetch}
            mapper={respostaTimeMachineSetup}
            tipoTimeMachine={tipoTimeMachine}
            tabelaBanco={referencia}
          />
        </ModalRightContainer>
      );

      const left = (
        <ModalLeftContainer
          icon={<img src={Calendario} />}
          title={this.props.t("timeMachine.terms")}
          add={true}
          containerClassName="modalLeftContainer"
          customAddFunction={() =>
            this.storeModalControlVigencias.addNewTimeMachine(
              this.storeModalItens,
              this.storeTimeMachineSetup,
              vigenciaAtual,
            )
          }
        >
          <ModalItensGenerico
            customFunctionOnChange={(item) =>
              this.storeModalControlVigencias.connectionModalRightLeft(
                item,
                this.storeTimeMachineSetup,
                this.storeModalItens,
              )
            }
            onDelete={this.storeModalControlVigencias.deleteTimeMachine}
            setStateObjModalAoInserirTimeMachine={
              this.setStateObjModalAoInserirTimeMachine
            }
            storeTimeMachine={this.storeTimeMachineSetup}
            storeModalCommon={this.props.store.modalCommon}
            storeLastAlertaVigencia={this.props.store.lastAlertaVigencia}
            currentContent={this.state.field}
            storeAlertaVigencia={this.props.store.alertaVigencia}
            tituloDeleteVigencia={this.props
              .t("timeMachine.ofEquipment", { ns: "routes" })
              .toLowerCase()}
            subTituloDeleteVigencia={this.props
              .t("timeMachine.theEquipment", { ns: "routes" })
              .toLowerCase()}
            gender={"o"}
            handleModal={this.handleModal}
            changeContentModal={this.changeContentModal}
            objModal={this.state.objModal}
            urlRequest={`/${tipoTimeMachine}/vigencia/excluir`}
            podeDeletarUltimaVigencia={false}
            getPreviousDate={this.storeModalControlVigencias.getPreviousDate}
            editable={false}
          />
        </ModalLeftContainer>
      );

      return (
        <ModalContainer
          title={this.props.t("timeMachine.termPeriods")}
          bgClassName="colorWhiteBG"
          splitScreen={true}
          right={right}
          left={left}
        />
      );
    }

    //débito técnico: Usuário que não possui evento de setup cadastrado, quebra o backend qnd salva o equipamento
    let possuiEventoSetup = this.store.events.filter((r) => r.classe.id == 13);
    if (!possuiEventoSetup.length) {
      const questionEventoSetup = (
        <div>
          <h1 className="bolded-text">
            {this.props.t("errors.setupNotCreated.title")}
          </h1>
          <h2>{this.props.t("errors.setupNotCreated.message")}</h2>
        </div>
      );
      return (
        <Quest
          styleModal={{ width: "auto" }}
          question={questionEventoSetup}
          onlyOkButton={true}
          simText={"Ok"}
          handleModal={this.handleModal}
          action={this.handleModal}
        />
      );
    }

    let modalSetupStyle = {
      height: 400,
      overflowY: "auto",
      paddingTop: 10,
    };

    if (!this.state.informacoesCarregadas) {
      return;
    }

    return (
      <ModalForm
        columns
        abas={["detalhes", "metas", "oee", "setup", "iot", "tecnico"]}
        model="equipments"
        genero="M"
        url={this.endpoints.EQUIPAMENTOS}
        tituloAlerta={this.props.t("title")}
        action={this.action}
        disabledSave={
          (!hasRole("ROLE_EQUIPAMENTOS_CRIAR") &&
            this.state.typeModal === "add") ||
          (!hasRole("ROLE_EQUIPAMENTOS_ATUALIZAR") &&
            this.state.typeModal == "up")
        }
        endpoints={this.endpoints}
        utils={this.utils}
        field={this.state.field}
        fields={this.camposForm()}
        initialSelectedIndex={this.state.abaAtiva}
        handleModal={this.handleModal}
        showAlert={this.showAlert}
        modificador={requisicaoEquipamentos}
        validate={this.validarForm()}
        clearState={this.clearState}
        typeform={this.props.t("title")}
        formContentStyle={modalSetupStyle}
      />
    );
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: "94vh" }}>
          <div className={"loading-wrapper"}>
            <MDSpinner singleColor="#2D4F7F" size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container
          showSideBar
          modal={this.state.modal}
          styleModal={{ width: this.state.carregandoInformacoes ? 0 : 700 }}
          contentModal={this.contentModal}
        >
          <EquipmentsList
            handleModal={this.handleModal}
            endpoints={this.endpoints}
            ref="list"
            store={this.store}
            utils={this.utils}
            onOpenModal={this.onOpenModal}
            onDelete={this.onDelete}
          />
        </Container>
      );
    }
  }
}

export default withTranslation("equipments")(EquipmentsPage);
