import React from 'react';
import { NFormTitle, NButtonGroup, NForm } from '../NForm';




export default props => (
    <div>
        <NFormTitle>{props.title}</NFormTitle>
        <center>{props.subtitle}</center>
        <br /><br />
        <NButtonGroup>
            {props.children}
        </NButtonGroup>
    </div>
)
