import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
moment.locale('pt-br');
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import listStyles from './list.scss';
import { alertBox } from '../../common/NForm';
import utils from '../../utils/Utils';
import { hasRole } from '../../helper/SecurityHelper';
import { request, requestAll } from '../../common/request';
import { withTranslation } from 'react-i18next';

@observer
class CompactLot extends Component {
  activeModal(type, favorite, corLeadTime) {
    let obj = this.props.obj;
    obj.type = type;
    if (obj.inconsistente) {
      alertBox(this.props.t("errors.cannotDetailInconsistent", { primaryReference: utils.getReferenciaoLoteaOrdem().toLowerCase() }), this.props.t("labels.inconsistentBatch", { primaryReference: utils.getReferenciaLoteOrdem() }), 'error');
    } else {
      this.props.handleModal('detailLote', obj, '', '', '', '', corLeadTime);
    }
  }

  toQuality(obj) {
    this.props.handleModal('toQuality', obj.fk_id_batches, '', obj);
  }

  leadTime(value) {
    return value.toString().replace('.0', '') + 'h';
  }

  buscarLotesFavoritos() {
    requestAll([
      {
        url: this.props.endpoints.FAVORITO,
        modelo: 'favorites'
      }
    ], this);
  }

  setLoteFavorito(idBatch, favorite, corLeadTime, adicionarLote) {
    let idUsuarioStorage = localStorage.getItem('id');
    favorite = favorite.find(f => f.lote.id == idBatch);

    let data = {
      id: favorite?.id,
      lote: {
        id: idBatch
      },
      usuario: {
        id: idUsuarioStorage
      },
      corleadtime: corLeadTime
    };

    request(`${this.props.endpoints.FAVORITO}/${adicionarLote}`, {
      method: 'post',
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {

          let mensagem = adicionarLote ?
            utils.getReferenciaLoteOrdem() == this.props.t("labels.batch", { ns: 'common' }) ? this.props.t("messages.batchAddedToFavorite") : this.props.t("messages.orderAddedToFavorite")
            :
            this.props.t("messages.deleteFavoriteSuccess", { primaryReference: utils.getReferenciaLoteOrdemExcluido() })
          alertBox(mensagem, this.props.t("labels.success", { ns: 'common' }), 'sucess');
        }
        else {
          return response.json();
        }
      })
      .catch(() => {
        alertBox(this.props.t("errors.cannotDelete", { primaryReference: utils.getReferenciaoLoteaOrdem() }), this.props.t("labels.error", { ns: 'common' }), 'error');
      })

    var findNewFavorites = setInterval(() => this.buscarLotesFavoritos(), 50);
    setInterval(() => clearInterval(findNewFavorites), 100);
  }

  render() {
    let { obj, type, greenHT, blackHT, referenciaSecundaria, equipment, stage, store, array } = this.props;
    let favorite = null, colorProgressBar;
    var loteHT, BackgroundLote;
    var { lead_time, tempo_ciclo } = obj;
    var leadTimeBadge = lead_time.cor != 'verde' && obj.type != 'process' && !obj.inconsistente;
    let leadtimes = store.flowViewLeadTimes;
    let temposCiclo = store.flowViewTemposCiclo;
    let favorites = this.props.favorites;
    let porcProgressBar, widthProgressBar;
    let hasComments = obj.comentarios !== undefined && obj.comentarios.length > 0;
    let colorBookmark = hasComments ? (obj.comentarios[0].color === "#fff" ? "#143055" : obj.comentarios[0].color) : "#143055";

    favorite = favorites.filter(f => f.lote.id == obj.fk_id_batches && f.status)[0];

    let isEmpty = function (str) {
      return (!str || str.length === 0);
    };

    BackgroundLote = {
      background: !obj.isSession ? '#bac0d6' : '',
      fontWeight: !obj.isSession ? 'bold' : '',
      color: '#626262'
    };

    if (type == 'process') {
      porcProgressBar = lead_time.porcentagem_processo;
      widthProgressBar = Math.min(porcProgressBar, 100);

      switch (lead_time.cor_processo) {
        case 'verde':
          colorProgressBar = listStyles.progress_bar_green;
          break;

        case 'amarelo':
          colorProgressBar = listStyles.progress_bar_yellow;
          break;

        case 'vermelho':
          colorProgressBar = listStyles.progress_bar_red;
          break;
      }
    }

    loteHT = this.props.holdingTimeCalculado != undefined ? this.props.holdingTimeCalculado.find(ht => ht.loteDestino.id == obj.fk_id_batches) : false;

    if (loteHT && loteHT.mostrar && !loteHT.loteDestino.inconsistente && loteHT.porcentagem >= parseFloat(greenHT.value) && loteHT.porcentagem < parseFloat(blackHT.value)) {
      BackgroundLote = {
        background: !obj.isSession && !leadtimes ? 'linear-gradient(to bottom, #5E2129 0%,#5E2129 93%,#000000 50%,white 50%,white 100%)' : '#5E2129',
        color: '#fff'
      };
    }

    if (loteHT && loteHT.mostrar && !loteHT.loteDestino.inconsistente && loteHT.porcentagem >= parseFloat(blackHT.value)) {
      BackgroundLote = {
        background: !obj.isSession && !leadtimes ? 'linear-gradient(to right, #000 0%,#000 93%,#000000 50%,#FFDDC1 50%,#FFDDC1 100%)' : '#000',
        color: '#fff'
      };
    }

    var corLTHelper = {
      amarelo: '#EC981B',
      vermelho: '#C13929',
      verde: ''
    }

    if (loteHT && loteHT.mostrar && !loteHT.loteDestino.inconsistente && loteHT.porcentagem >= parseFloat(blackHT.value)) {
      BackgroundLote = {
        background: '#000',
        color: '#fff'
      };
    }

    var corLTHelper = {
      amarelo: '#EC981B',
      vermelho: '#C13929',
      verde: ''
    }

    var numeroSecundario = obj.num_secundario != null ? obj.num_secundario : '-';

    return (
      <li>
        <ContextMenu id={'lot' + obj.fk_id_batches + '_' + obj.fk_id_stages + '_' + obj.cycle + '_' + obj.fk_id_equipments + '_' + this.props.tipo}>
          <MenuItem data={{ id: obj.fk_id_products }} onClick={() => this.activeModal(type, favorite, obj.lead_time.cor)}>
            {this.props.t("actions.detailLote")}
          </MenuItem>
          {
            (obj.type === 'process' || obj.type === 'warningProcess') ?
              null
              :
              hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                <MenuItem data={{ id: obj.fk_id_products }} onClick={() => this.props.handleModal('listLote', '', '', { array }, '', { equipment }, { stage })}>
                  {this.props.t("actions.detailFifo")}
                </MenuItem>
                :
                null
          }
          
          {hasRole(['ROLE_LOTE_FAVORITO_VISUALIZAR', 'ROLE_FLOW_VIEW_VISUALIZAR']) ?
            favorite ?
              <MenuItem onClick={() => this.setLoteFavorito(obj.fk_id_batches, this.props.favorites, null, false)}>
                {this.props.t("actions.removeFavorite", { primaryReference: utils.getReferenciaLoteOrdem().toLowerCase() })}
              </MenuItem>
              :
              <MenuItem onClick={() => this.setLoteFavorito(obj.fk_id_batches, this.props.favorites, null, true)}>
                {this.props.t("actions.addFavorite", { primaryReference: utils.getReferenciaLoteOrdem().toLowerCase() })}
              </MenuItem>
            :
            null
          }
          {
            (obj.type === 'process' || obj.type === 'warningProcess') ?
              null
              :
              hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                <MenuItem data={{ id: obj.fk_id_products }} onClick={() => this.props.handleModal('moveBatch', '', '', '', obj, { equipment }, { stage }, '', this.props.indexFifo)}>
                  {this.props.t("actions.moveToEquipment")}
                </MenuItem>
                :
                null
          }
          
          {
            hasRole(['ROLE_FLOW_VIEW_VISUALIZAR']) ?
              <MenuItem
                onClick={() => this.props.handleModal('batchComments', obj.fk_id_batches, '', '', obj)}>
            
                {this.props.t("actions.comments")}
              </MenuItem>
              :
              null
          }
        </ContextMenu>
        {
          (obj.type === 'process' || obj.type === 'warningProcess') ? <span className={listStyles.batch_process}><div /><div /></span> : null
        }
        <ContextMenuTrigger id={'lot' + obj.fk_id_batches + '_' + obj.fk_id_stages + '_' + obj.cycle + '_' + obj.fk_id_equipments + '_' + this.props.tipo}>
          <div style={{ marginLeft: '-13px', position: 'absolute', height: '1.4em', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {favorite !== undefined ? <i style={{ fontSize: '13px' }} className={'material-icons ' + listStyles.batch_compact_star}>star</i> : null}
            {/* {hasComments ? <i style={{ fontSize: '13px' }} className={'material-icons ' + listStyles.batch_compact_comment}>comment</i> : null} */}
          </div>

          <div style={{ height: '' }} className={(obj.type !== 'waiting' ? listStyles.batch_compact + ' ' + listStyles.batch_compact_process : listStyles.batch_compact)}>
            <div style={{ height: '100%' }}>
              <div className={(!obj.master || leadTimeBadge) ? listStyles.alternative : listStyles.normal} >
                {
                  hasComments ?
                    <div className={listStyles.tooltipBookmark} style={{ backgroundColor: colorBookmark }}>
                      <i className="fa fa-solid fa-bookmark" style={{ color: 'white', fontSize: '13px' }}></i>
                    </div>
                    : null
                }

                {
                  leadTimeBadge && obj.type !== 'warningWaiting' && obj.type !== 'warningProcess' ?
                    <div style={{ backgroundColor: corLTHelper[lead_time.cor] }} className={listStyles.list_badge}>
                      <i className='material-icons' style={{ color: '#E6E6E6', fontSize: '1.6em' }}>info</i>
                    </div>
                    : null
                }

                {
                  obj.inconsistente ?
                    <div className={listStyles.tooltipWarning}>
                      <i className="fa fa-chain-broken" style={{ color: 'white' }}></i>
                      <span className={listStyles.tooltipTextWarning}>
                        {obj.tp_error}
                      </span>
                    </div>
                    : null
                }

                {
                  //Não é equipamento principal
                  //Não é equipamento alternativo principal para ordem de qualidade
                  //Está em processamento
                  !obj.master
                    && !(obj.alternativo_principal && obj.tipo_lote == 2)
                    && (obj.type === 'warningProcess' || obj.type === 'process')
                    ? <div style={{ backgroundColor: '#BEDAF6' }} className={listStyles.list_badge}>
                      <i style={{ fontSize: '1.6em' }} className='material-icons'>call_split</i>
                    </div>
                    : null
                }

              </div>
              <div className={listStyles.batchTooltip}>
                <div className={listStyles.toolTipText}>
                  <div style={{ fontWeight: 600, color: '#fff' }}>{isEmpty(obj.tipo_producao) ? '' : obj.tipo_producao + ' |'} {!referenciaSecundaria ? obj.num : numeroSecundario} | {obj.sku}</div>
                  <div style={{ color: '#fff' }}>{obj.product}</div>
                </div>
                <div style={BackgroundLote} className={listStyles.flowViewBatch}>
                  <div style={{ fontWeight: 600 }}>{isEmpty(obj.tipo_producao) ? '' : obj.tipo_producao + ' |'} {!referenciaSecundaria ? obj.num : numeroSecundario} | {obj.sku}</div>
                  <div>{obj.product}</div>
                </div>
              </div>
              {
                leadtimes && obj.type !== 'warningWaiting' && obj.type !== 'warningProcess' && !obj.inconsistente ?
                  <div className={listStyles.lead_time}>
                    <div className={leadtimes ? listStyles.tooltip : 'hidden'}>
                      {lead_time.real_etapa + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("labels.stageLeadTime")}</span>
                    </div>
                    <div className={leadtimes ? listStyles.tooltip : 'hidden'}>
                      {lead_time.real_acumulado + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("labels.accumulatedLeadTime")}</span>
                    </div>
                  </div>
                  : null
              }
              {
                temposCiclo && obj.type !== 'warningWaiting' && obj.type !== 'warningProcess' && !obj.inconsistente ?
                  <div className={listStyles.lead_time}>
                    <div className={temposCiclo ? listStyles.tooltip : 'hidden'}>
                      {tempo_ciclo.tempo_padrao + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("actions.defaultTime")}</span>
                    </div>
                    <div className={temposCiclo ? listStyles.tooltip : 'hidden'}>
                      {tempo_ciclo.tempo_real + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("actions.defaultTimeOEE")}</span>
                    </div>
                  </div>
                  : null
              }
            </div>
            <div>
              {(obj.type === 'process') || (obj.type === 'warningProcess') ? <div className={listStyles.progress_bar} style={{ height: 3, padding: 0 }}><div className={colorProgressBar} style={{ width: porcProgressBar > 100 ? '100%' : 'calc(' + (widthProgressBar) + '%)', height: 3 }} /></div> : null}
            </div>
          </div>
        </ContextMenuTrigger>
        {
          (obj.type === 'waiting') ? <span className={listStyles.batch_waiting}><div /></span> : (obj.type === 'warningWaiting') ? <span className={listStyles.batch_warning}><div /></span> : null
        }
      </li>
    )
  }
}

export default withTranslation("flowView")(CompactLot);