import update from 'immutability-helper';
import _ from 'lodash';
import { Checkbox, TextField } from 'material-ui';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import defaultStyle from '../../common/button.scss';
import { alertBox, NButton, NButtonGroup, NForm, NFormGroup, NFormTitle, NSmall } from '../../common/NForm';
import { request } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import { default as MultiSelect } from 'components/AutoComplete';

const OPCAO_LISTA = 'selecionar';
const OPCAO_NUMERICO = 'numerico';
const OPCAO_ALFANUMERICO = 'alfanumerico';

function stopEvent(event) {
    event.stopPropagation();
    event.preventDefault();
}

function requisicao(method, data) {
    return {
        method: method,
        body: data,
    };
}

class CustomFieldsForm extends Component {
    constructor(props) {
        super(props);

        this.endpoints = props.endpoints;
        this.existeCampo = props.existeCampo;

        this.state = {
            modelo: props.modelo || {
                nome: '',
                equipamentos: [],
                eventos: [],
                obrigatorio: false,
                tipo: 'numerico',
                opcoes: [],
            },
            equipamentos: [],
            eventos: [],
            opcoesDesabilitado: props.modelo ? (props.modelo.tipo !== OPCAO_LISTA) : true,
            equipamentosDesabilitado: false,
            eventosDesabilitado: false,
            nomeInvalido: '',
            equipamentosInvalido: false,
            eventosInvalido: false,
            opcoesInvalido: false,
        };

        this.onEquipamentoChanged = this.onEquipamentoChanged.bind(this);
        this.onEventoChanged = this.onEventoChanged.bind(this);
        this.onNomeChanged = this.onNomeChanged.bind(this);
        this.onTipoChanged = this.onTipoChanged.bind(this);
        this.onOpcoesChanged = this.onOpcoesChanged.bind(this);
        this.onCampoObrigatorioChanged = this.onCampoObrigatorioChanged.bind(this);
        this.validar = this.validar.bind(this);
        this.ajustarModelo = this.ajustarModelo.bind(this);
        this.validarCampos = this.validarCampos.bind(this);
        this.existeOpcaoRepetida = this.existeOpcaoRepetida.bind(this);
    }

    opcaoTodos() {
        return [{ label: this.props.t('labels.all', { ns: 'common' }), value: -1 }];
    }

    onEquipamentoChanged(value) {
        if (value.some(e => e.value == -1)) {
            this.setState(update(this.state, {
                modelo: {
                    equipamentos: { $set: this.opcaoTodos() },
                },
                equipamentosDesabilitado: { $set: true },
            }));
        } else {
            this.setState(update(this.state, {
                modelo: {
                    equipamentos: { $set: value || [] },
                }
            }));
        }
    }

    onEventoChanged(value) {
        if (value.some(e => e.value == -1)) {
            this.setState(update(this.state, {
                modelo: {
                    eventos: { $set: this.opcaoTodos() },
                },
                eventosDesabilitado: { $set: true },
            }));
        } else {
            this.setState(update(this.state, {
                modelo: {
                    eventos: { $set: value || [] },
                }
            }));
        }
    }

    onOpcoesChanged(e) {
        this.setState(update(this.state, {
            modelo: {
                opcoes: { $set: e.target.value.split('\n') },
            }
        }));
    }

    onNomeChanged(e) {
        this.setState(update(this.state, {
            modelo: {
                nome: { $set: e.target.value },
            }
        }));
    }

    onCampoObrigatorioChanged(e, checked) {
        this.setState(update(this.state, {
            modelo: {
                obrigatorio: { $set: checked },
            }
        }));
    }

    onTipoChanged(e) {
        this.setState(update(this.state, {
            modelo: {
                tipo: { $set: e.target.value },
                opcoes: { $set: e.target.value !== OPCAO_LISTA ? [] : this.state.modelo.opcoes },
            },
            opcoesDesabilitado: { $set: e.target.value !== OPCAO_LISTA },
        }));
    }

    validarCampos(modelo) {
        let state = {};
        let valido = true;

        if (!modelo.nome) {
            state.nomeInvalido = { $set: this.props.t("messages.requiredField", { ns: "common" }) };
            valido = false;
        } else {
            modelo.nome = modelo.nome?.trim();
            if (this.existeCampo(modelo)) {
                state.nomeInvalido = { $set: this.props.t("messages.requiredField", { ns: "common" }) };
                valido = false;
                alertBox(this.props.t("errors.customFieldsExists"), this.props.t("title"), 'warning');
            } else {
                state.nomeInvalido = { $set: '' };
            }
        }

        if (modelo.equipamentos.length <= 0) {
            state.equipamentosInvalido = { $set: true };
            valido = false;
        } else {
            state.equipamentosInvalido = { $set: false };
        }

        if (modelo.eventos.length <= 0) {
            state.eventosInvalido = { $set: true };
            valido = false;
        } else {
            state.eventosInvalido = { $set: false };
        }

        if (modelo.tipo == OPCAO_LISTA && modelo.opcoes.length <= 0) {
            state.opcoesInvalido = { $set: true };
            valido = false;
        } else {
            state.opcoesInvalido = { $set: false };
        }

        if (this.existeOpcaoRepetida(modelo.opcoes)) {
            valido = true;
        }

        this.setState(update(this.state, state));

        if (valido) { return valido; }

        return false;
    }


    existeOpcaoRepetida(opcoes) {
        var opcoesCad = {};
        var len = opcoes.length;
        var existe = false;

        for (let i = 0; i < len; i++) {
            var opcao = opcoes[i];
            if (opcoesCad[opcao]) {
                alertBox(this.props.t("errors.optionSelected", { option: opcao }), this.props.t("title"), 'warning');
                existe = true;
            } else {
                opcoesCad[opcao] = true;
            }
        }

        return existe;
    }

    _chave = (equipamento, evento) => `${equipamento.id != undefined ? equipamento.id : equipamento.value}#${evento.id != undefined ? evento.id : evento.value}`;

    validar(campoPersonalizado) {
        const modeloAntigo = this.props.modelo || campoPersonalizado;

        if (this.validarCampos(campoPersonalizado)) {
            var verificarEquipamentoModelo = campoPersonalizado.equipamentos.filter(eq => eq.value == -1 || eq.value == 0);
            // A opção "TODOS" tem valor: -1 ou 0
            var verificarEventoModelo = campoPersonalizado.eventos.filter(ev => ev.value == -1 || ev.value == 0);
            var dado = {
                item1: verificarEquipamentoModelo ? verificarEquipamentoModelo.map(d => d.value = 0) : campoPersonalizado.equipamentos.filter(eq => eq.value !== -1).map(d => d.value),
                item2: verificarEventoModelo ? verificarEventoModelo.map(d => d.value = 0) : campoPersonalizado.eventos.filter(ev => ev.value !== -1).map(d => d.value),
            };

            request(`${this.endpoints.CAMPOS_PERSONALIZADOS}/existe-colisao/${modeloAntigo.id != undefined ? modeloAntigo.id : 0}`, requisicao('POST', JSON.stringify(dado)))
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        alertBox(this.props.t("errors.cannotPossibleCreate"), this.props.t("title"), 'error');
                        throw new Exception;
                    }
                }).then(camposPorEquipamentoEvento => {

                    var campoPadrao = [{ chave: '0#0', campos_personalizados: [] }];

                    let necessita_validar = true;

                    if (campoPersonalizado.id != undefined) {
                        let diff = {
                            equipamentos_selecionados: campoPersonalizado.equipamentos.map(eq => eq.value),
                            equipamentos_salvos: campoPersonalizado.por_equipamentos_eventos.map(pev => pev.equipamento.id),
                            eventos_selecionados: campoPersonalizado.eventos.map(eq => eq.value),
                            eventos_salvos: campoPersonalizado.por_equipamentos_eventos.map(pev => pev.evento != null ? pev.evento.id : 0)
                        };

                        necessita_validar = _.xor(diff.equipamentos_salvos, diff.equipamentos_selecionados).length > 0 ||
                            _.xor(diff.eventos_salvos, diff.eventos_selecionados).length > 0;
                    }

                    let campos = camposPorEquipamentoEvento.reduce((campos, campo_atual) => {
                        // remove campo atual caso tenha sido modificado
                        if (necessita_validar && campo_atual.campo_personalizado.id == campoPersonalizado.id) return campos;

                        let chave = this._chave(campo_atual.equipamento, campo_atual.evento);
                        let campo = campos.find(c => c.chave == chave);

                        if (campo) {
                            campo.campos_personalizados.push(campo_atual.campo_personalizado);
                        } else {
                            campos.push({
                                chave: chave,
                                campos_personalizados: [].concat(campo_atual.campo_personalizado)
                            });
                        }

                        return campos;
                    }, campoPadrao);

                    let chaves_validar = [];

                    let that = this;

                    campoPersonalizado.equipamentos.map(equipamento => {
                        campoPersonalizado.eventos.map(evento => {
                            chaves_validar.push({
                                chave: that._chave(equipamento, evento),
                                equipamento: equipamento.label,
                                evento: evento.label
                            });
                        });
                    });

                    const TODOS = 0;

                    let mensagens = chaves_validar.reduce((mensagens, chave_validacao) => {
                        let chave_validar = {
                            equipamento: chave_validacao.chave.split('#')[0],
                            evento: chave_validacao.chave.split('#')[1]
                        };

                        // "campos_validos" são todos os campos que já estão cadastrados (persistidos)
                        // para o equipamento/evento que está sendo validado (equipamento e evento informados no formulário)
                        let campos_validos = campos.filter(campo => {
                            let chave_campo = {
                                equipamento: campo.chave.split('#')[0],
                                evento: campo.chave.split('#')[1]
                            };

                            if ((chave_campo.equipamento == TODOS ||
                                chave_campo.equipamento == chave_validar.equipamento) &&
                                (chave_campo.evento == TODOS ||
                                    chave_campo.evento == chave_validar.evento)
                            ) return true;
                            if (chave_validar.evento == TODOS &&
                                chave_validar.equipamento != TODOS &&
                                chave_validar.equipamento == chave_campo.equipamento
                            ) return true;

                            if (chave_validar.equipamento == TODOS &&
                                chave_validar.evento != TODOS &&
                                chave_validar.evento == chave_campo.evento
                            ) return true;
                            if (chave_validar.equipamento == TODOS &&
                                chave_validar.evento == TODOS) return true;

                            return false;
                        });


                        let total_campos = [];

                        for (var campo_valido of campos_validos) {

                            for (var campo_personalizado of campo_valido.campos_personalizados) {

                                if (!total_campos.includes(campo_personalizado.id)) { total_campos.push(campo_personalizado.id); };
                            }
                        }

                        if (total_campos.length >= 4) {
                            mensagens.push(this.props.t("errors.equipmentHasThreeCustomFields", { equipment: chave_validacao.equipamento, event: chave_validacao.evento }));
                        }

                        return mensagens;
                    }, []);

                    //Se o validador for True quer dizer que os equipamentos selecionados ja possuem mais de 3 campos pesonalizados configurados.
                    if (necessita_validar && mensagens.length > 0) {
                        mensagens.map(mensagem => {
                            alertBox(mensagem, this.props.t("title"), 'error');
                        });
                    } else {
                        this.props.salvar({ ...campoPersonalizado });
                    }
                });
        }
    }

    ajustarModelo(modelo) {
        // remover espaços e opcao em branco.
        modelo.opcoes = modelo.opcoes.reduce((acc, opcao) => {
            opcao = opcao.trim();
            if (opcao) {
                acc.push(opcao);
            }

            return acc;
        }, []);

        return modelo;
    }

    _disableSaved() {
        return ((!hasRole('ROLE_CAMPOS_PERSONALIZADOS_CRIAR') && this.props.tipo === 'add')
            || (!hasRole('ROLE_CAMPOS_PERSONALIZADOS_ATUALIZAR') && this.props.tipo === 'edit'));
    }

    render() {
        const {
            modelo,
            equipamentosDesabilitado,
            eventosDesabilitado,
            opcoesDesabilitado,
            nomeInvalido,
            equipamentosInvalido,
            eventosInvalido,
            opcoesInvalido,
        } = this.state;

        const { equipamentos, eventos } = this.props;

        return (
            <NForm style={{ width: '370px', margin: '0 auto' }}>
                <NFormTitle>{this.props.t("title")}</NFormTitle>
                <form>
                    <NFormGroup>
                        <TextField
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                            floatingLabelText={this.props.t("form.customFields.labels.nameField")} onChange={this.onNomeChanged}
                            defaultValue={modelo.nome}
                            fullWidth
                            errorText={this.state.nomeInvalido}
                        />
                    </NFormGroup>
                    <NFormGroup>
                        <label style={{ fontSize: '0.8em' }}>{this.props.t("form.customFields.labels.equipment")}</label>
                        <MultiSelect
                            isMulti
                            options={equipamentos}
                            allowSelectAll={false}
                            onGetOptionLabel={(option) => option.label}
                            onGetOptionValue={(option) => option.value}
                            onSelectOption={this.onEquipamentoChanged.bind(this)}
                            value={modelo.equipamentos}
                            disabled={equipamentosDesabilitado}
                        />

                        <NSmall className="fg-error" disabled={equipamentosInvalido}>{this.props.t("messages.requiredField")}</NSmall>
                    </NFormGroup>
                    <NFormGroup>
                        <label style={{ fontSize: '0.8em' }}>{this.props.t("form.customFields.labels.events")}</label>
                        <MultiSelect
                            isMulti
                            options={eventos}
                            allowSelectAll={false}
                            onGetOptionLabel={(option) => option.label}
                            onGetOptionValue={(option) => option.value}
                            onSelectOption={this.onEventoChanged.bind(this)}
                            value={modelo.eventos}
                            disabled={eventosDesabilitado}
                        />
                        <NSmall className="fg-error" disabled={eventosInvalido}>{this.props.t("messages.requiredField", { ns: "common" })}</NSmall>
                    </NFormGroup>
                    <NFormGroup direction="inline">
                        <div className="d-align-center">
                            <label
                                htmlFor="campo_obrigatorio"
                                style={{ cursor: 'pointer' }}>{this.props.t("messages.requiredField", { ns: "common" })}
                            </label>
                        </div>
                        <div>
                            <Checkbox
                                id="campo_obrigatorio"
                                checked={modelo.obrigatorio}
                                onCheck={this.onCampoObrigatorioChanged}
                                iconStyle={{ fill: '#2b4d7c' }} />
                        </div>
                    </NFormGroup>
                    <NFormGroup direction="inline">
                        <label>{this.props.t("form.customFields.labels.type")}</label>
                        <div className="flex flex-dir-row flex-align-end" style={{ marginTop: -1 }}>
                            <div className="flex flex-dir-col flex-align-start" style={{ width: '30%', justifyContent: 'center' }}>
                                {
                                    [
                                        { label: this.props.t("format_type.numeric"), value: OPCAO_NUMERICO },
                                        { label: this.props.t("format_type.alphanumeric"), value: OPCAO_ALFANUMERICO },
                                        { label: this.props.t("format_type.list"), value: OPCAO_LISTA },
                                    ].map((radio, index) => {
                                        return <RadioTipoCampo
                                            key={index}
                                            tipo={modelo.tipo}
                                            handleClick={this.onTipoChanged}
                                            label={radio.label}
                                            value={radio.value}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                    </NFormGroup>
                    <NFormGroup disabled={opcoesDesabilitado}>
                        <NSmall disabled={true}>{this.props.t("messages.messageList")}</NSmall>
                        <textarea value={_.join(modelo.opcoes, '\n')} onChange={this.onOpcoesChanged} style={{ resize: 'none', borderColor: opcoesInvalido ? 'red' : '' }} rows={'6'} />
                        <NSmall className="fg-error" disabled={opcoesInvalido}>{this.props.t("messages.requiredField", { ns: "common" })}</NSmall>
                    </NFormGroup>
                    <NButtonGroup>
                        <NButton type="primary"
                            disabled={this._disableSaved()}
                            className={this._disableSaved() ? defaultStyle.button_ghost_disable : ''}
                            onClick={event => {
                                stopEvent(event);
                                var dado = this.ajustarModelo(modelo);
                                this.validar(dado);
                            }}>{this.props.t("labels.save", { ns: "common" })}</NButton>

                        <NButton
                            style={{ marginLeft: 10 }}
                            onClick={event => { stopEvent(event); this.props.handleModal(); }}>{this.props.t("labels.cancel", { ns: "common" })}</NButton>
                    </NButtonGroup>
                </form>
            </NForm>
        );
    }
}

class RadioTipoCampo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {
            tipo,
            handleClick,
            label,
            value
        } = this.props;

        return (
            <div className={'flex flex-dir-row flex-just-between flex-align-center'}>
                <label
                    className="control control-radio"
                    htmlFor={`radioSete-${value}`}
                    style={{
                        height: '1.2em',
                        width: 130,
                        textAlign: 'left',
                        paddingTop: 9
                    }}
                    onClick={handleClick}
                >
                    <span style={{
                        marginLeft: '2em',
                        width: 300
                    }}
                    >
                        {label}
                    </span>

                    <input type="radio" name="periodo" value={value} checked={tipo === value} id={`radioSete-${value}`} />
                    <div
                        style={{ top: '.2em' }}
                        className="control_indicator"
                        data-value={value}
                    ></div>
                </label>
            </div>
        );
    }
}

export default withTranslation("customFields")(CustomFieldsForm);