import React, { Component } from 'react';
import styles from './route.scss';
import listDrop from './listDrop.scss';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { withTranslation } from 'react-i18next';

class ModalListEquipments extends Component {
  render() {
    let {equipments, ComEquipamento, addRoute} = this.props;

    return(
      <div className={styles.modal_step_one_drag}>
        <h4 className="bold"><i className='material-icons'>settings</i> {ComEquipamento ? this.props.t("listEquipment.available") : this.props.t("listEquipment.stageWithoutEquipment")} </h4>
        <div className={styles.equipmentWrapper}>
          <ul>
            {
              equipments.map(equipment => <Equipment t={this.props.t} key={equipment.id} equipment={equipment} addRoute={addRoute}/>)
            }
          </ul>
        </div>
      </div>
    );
  }
}

class Equipment extends Component {
  constructor() {
    super();
    this._onDrag = this._onDrag.bind(this);
  }

  _onDrag(event) {
    event.dataTransfer.setData('equipment', event.target.id);
  }

  render() {
    let{ equipment } = this.props;
    var nomeTratado = equipment.name;

    if (nomeTratado.length > 125) {
      nomeTratado = nomeTratado.substr(0,125-3) + '...';
    }

    return (
      <ContextMenuTrigger id={`ctx-add-${equipment.id}`}>
        <ContextMenu id={`ctx-add-${equipment.id}`}>
          <MenuItem onClick={() => this.props.addRoute(equipment.id)}>{this.props.t("listEquipment.insert")}</MenuItem>
        </ContextMenu>
        <div id={equipment.id} onDragStart={this._onDrag} className={listDrop.equipments} draggable="true" title={nomeTratado.indexOf("...") != -1 ? equipment.name : null}>
          <i className="fa fa-arrows-alt"/>
          {nomeTratado}
        </div>
      </ContextMenuTrigger>
    );
  }
}

export default withTranslation('routes')(ModalListEquipments);