import React, { useEffect, useState } from "react";
import Container from "common/Container";
import MDSpinner from "react-md-spinner";
import { Card, CardHeader, CardText } from "material-ui/Card";
import FilterBox from "../../common/FilterBox";
import { useTranslation } from "react-i18next";
import LinkClientV2 from "common/LinkClientV2";

const APIV2 = process.env.APIV2_URL;

export const EquipmentsPanel = (props) => {
  const { t } = useTranslation("equipments");
  const [equipments, setEquipments] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("tokenApp")}`,
      "Access-Token": localStorage.getItem("token"),
    };

    const getEquipments = async () => {
      const response = await fetch(`${APIV2}/equipamento`, {
        method: "GET",
        headers,
        signal,
      });
      const json = await response.json();

      setEquipments(json);
      setLoading(false);
    };

    getEquipments();

    return () => {
      abortController.abort();
    };
  }, []);

  const onFilter = (value) => {
    setFilter(value);
  };

  if (loading) {
    <Container sectionStyle={{ height: "94vh" }}>
      <div className={"loading-wrapper"}>
        <MDSpinner singleColor="#2D4F7F" size={60} borderSize={7} />
      </div>
    </Container>;
  }

  return (
    <Container showSideBar>
      <div className="box_list">
        <Card className="box_list_card">
          <CardHeader className="box_header" title={t("dashboardTitle")} />
          <CardText className="table_overflow">
            <FilterBox onFilter={onFilter} />
            <table className="mui-table mui-table--bordered">
              <thead>
                <tr>
                  <td className="id">#</td>
                  <td className="name">{t("list.columns.name")}</td>
                  <td className="actions" style={{ width: "8%" }}>
                    {t("list.columns.action", {
                      ns: "common",
                      count: 2,
                    })}
                  </td>
                </tr>
              </thead>
              <tbody className="box_items">
                {equipments
                  .filter((item) => item.nome.toLowerCase().includes(filter))
                  .map((item, k) => (
                    <tr key={`item-${item.nome}`}>
                      <td>{k + 1}</td>
                      <td>{item.nome}</td>
                      <td>
                        <div className="options">
                          <LinkClientV2 to={`equipment-dashboard/${item.id}`}>
                            <i
                              className="material-icons"
                              title={t("list.actions.dashboard")}
                            >
                              tv
                            </i>
                          </LinkClientV2>
                          <LinkClientV2
                            to={`equipment-dashboard/${item.id}/simplified`}
                          >
                            <i
                              className="material-icons"
                              title={t("list.actions.simplifiedDashboard")}
                            >
                              tablet
                            </i>
                          </LinkClientV2>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </CardText>
        </Card>
      </div>
    </Container>
  );
};
