import React, { Component } from 'react'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import moment from 'moment';
import { IF, NInputTime, NDatePickerInput, NButtonGroup, NButton, alertBox } from '../../NForm'
import Grid from '../../Grid/grid';
import Row from '../../Grid/row';
import styles from './formModal.scss';
import defaultStyle from '../../button.scss';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { respostaRotas } from '../../../common/mappers'
// Alerta generico
import AlertContainer from '../../Alerta/alertContainer';
import AlertContent from '../../Alerta/alertContent';
// Alerta Especialista
import DeleteLastVigenciaRotas from '../../Alerta/customAlert/timeMachine/deleteLastVigenciaRota';
import { request } from '../../request';
import { withTranslation } from 'react-i18next';

@inject('store')
@observer
class FormModalVigenciasRota extends Component {
    constructor(props) {
        super(props);

        this.state = {
            botaoDesabilitado: false
        };

        this.routeContainerStore = this.props.store.routeContainerStore;
        this.rotaStore = this.props.store.timeMachineRotas
        this.modalItensStore = this.props.store.modalItens
        this.modalStore = this.props.store.modalCommon
        this.alertVigenciaRotas = this.props.store.alertLastVigenciaRotas;
        this.rotaStore.setHoraInicial(moment(this.rotaStore.Data).format('HH:mm:ss'), true);
        this._setObservacoes = this._setObservacoes.bind(this);
        this._getObservacoesValue = this._getObservacoesValue.bind(this);
        this._salvar = this._salvar.bind(this);
        this._resetNewItensId = this._resetNewItensId.bind(this);
        this._verificaVazio = this._verificaVazio.bind(this);
        this._getRotas = this._getRotas.bind(this);
        this.desabilitarBotao = this.desabilitarBotao.bind(this);
        this.habilitarBotao = this.habilitarBotao.bind(this);
    }

    componentDidUpdate() {
        when(
            () => this.modalItensStore.setCustomArray(this.rotaStore.ArrayItensCommon.slice()),
            () => this.modalItensStore.setTriggredAdd(true),
            () => this.modalItensStore.setTriggredAdd(false)
        )
    }

    _resetNewItensId(dataToSend) {
        return dataToSend.map(data => {
            return {
                originalId: data.originalId,
                originalUuid: data.originalUuid,
                dataEfetivo: moment(data.dataEfetivo),
                ativo: data.ativo,
                id: data.usuario == 0 ? 0 : data.id,
                destinoId: data.destinoId,
                Observacao: data.observacoes,
                novo: data.novo
            }
        })
    }

    _getRotas(rotasProdutosId) {
        if (rotasProdutosId === undefined) return [];

        return new Promise((resolve, reject) => {
            request(`${this.props.endpoints.TIMEMACHINE}/rotas/vigencias/${rotasProdutosId}`, {}, 'get')
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    resolve(respostaRotas(data));
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    _getVigenciaAtiva(originalId) {
        return request(`${this.props.endpoints.ROTA}/vigenciaAtiva/${originalId}`, {}, 'get')
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });
    }

    backToRoute() {
        this._getVigenciaAtiva(this.rotaStore.ArrayItensCommon[0].originalId)
            .then(vigencia => this._getRotas(vigencia.destinoId).then(rotas => {
                this.props.currentRoute.product.routes = rotas
                this.props.changeContentModal('isStepOne', this.props.currentRoute)
            }))
    }

    _setObservacoes() {
        this.refs.textAreaRef.focus();
    }

    _getObservacoesValue() {
        this.rotaStore.setObservacoes(this.refs.textAreaRef.value, true);
    }

    habilitarBotao() {
        this.setState({
            botaoDesabilitado: false
        })
    }

    desabilitarBotao() {
        this.setState({
            botaoDesabilitado: true
        })
    }

    _salvar(event) {
        this.desabilitarBotao()
        event.preventDefault();

        if (this.rotaStore.HoraInicial === '') {
            alertBox(this.props.t('timeMachine.messages.startHourRequired'), this.props.t('timeMachine.terms'), 'error');
            this.habilitarBotao();
            return;
        }

        this.rotaStore.saveServer(this.props.vigenciaAtual).then(() => {
            this.backToRoute();
            this.modalStore.setDisabled(false);
        }).catch((err) => {
            if (err.type == 'network' || err.type == 'invalidParam') {
                alertBox(this.props.t('timeMachine.messages.createError'), this.props.t('timeMachine.terms'), 'error');
            }
            this.backToRoute()
        }).finally(() => this.habilitarBotao())
    }

    _verificaVazio(event) {
        event.preventDefault();
        let stateArray = this._resetNewItensId(this.rotaStore.ArrayItensCommon);
        if (stateArray.filter(state => state.id != 0).length > 0) {
            this.backToRoute();
            return;
        }

        let alert = <AlertContainer>
            <AlertContent
                title={this.props.t('timeMachine.messages.deleteQuestion')}
                subtitle={this.props.t('timeMachine.messages.routeWillBeDeleted')}
            >
                <DeleteLastVigenciaRotas />
            </AlertContent>
        </AlertContainer>

        this.backToRoute();
        this.alertVigenciaRotas.setCustomYesFunction(
            () => {
                // Realizo como se fosse um dispatch para tomar a decisão
                return ['checkEmpty', this.rotaStore.ArrayItensCommon[0].originalId];
            }
        )
        this.modalStore.open(alert, [{ itemIndex: 0, commonState: this.rotaStore.ArrayItensCommon }])
    }

    render() {
        return (
            <div>
                <form ref={'formVigenciaRotas'}>
                    <Row>
                        <Grid cols="4 4 4">
                            <div className="labelVigenciaWrapper">
                                <label className="labelVigencia">
                                    {`${this.props.t('timeMachine.termStart')}:`.toUpperCase()}
                                    <IF test={this.rotaStore.showTooltip}>
                                        <div className={styles.tooltip}>
                                            <i className="material-icons">help</i>
                                            <div className={styles.tooltiptext}>
                                                {`${this.props.t('timeMachine.messages.newTermStartDate', { day: this.rotaStore.previousDate })}`}
                                            </div>
                                        </div>
                                    </IF>
                                </label>
                            </div>
                        </Grid>
                        <Grid cols="3 3 3">
                            <NDatePickerInput
                                id="data"
                                name='data'
                                label={this.props.t('timeMachine.date')}
                                value={moment(this.rotaStore.Data)}
                                onChange={date => this.rotaStore.setData(moment(date), true)}
                                onChangeRaw={(_, date) => this.rotaStore.setData(moment(date), true)} />
                        </Grid>
                        <Grid cols="3 3 3" style={{ marginBottom: -8 }}>
                            <NInputTime type='time'
                                fullWidth={true}
                                step={'1'}
                                name='hora'
                                onChange={(event, value) => this.rotaStore.setHoraInicial(value, true)}
                                floatingLabelStyle={{
                                    color: '#5B5B5B',
                                    textTransform: 'uppercase',
                                    background: '#fff', textOverflow: 'ellipsis',
                                    fontSize: 18
                                }}
                                value={this.rotaStore.HoraInicial}
                                id='hora'
                                label={this.props.t('timeMachine.hour')}
                                inputStyle={{ marginTop: 17 }} />
                        </Grid>
                        <Grid cols="2 2 2" />
                    </Row>
                    <Row>
                        <Grid cols="2 2 2">
                            <div className="labelRotaWrapper">
                                <label>
                                    {`${this.props.t('timeMachine.route')}:`.toUpperCase()}
                                </label>
                            </div>
                        </Grid>
                        <Grid cols="2 2 2">
                            <div className="radioWrapper">
                                <RadioButtonGroup name='status' defaultSelected={true} valueSelected={this.rotaStore.Ativo} style={{ display: 'flex' }} onChange={(e, value) => this.rotaStore.setAtivo(value, true)}>
                                    <RadioButton
                                        value={true}
                                        label={this.props.t('timeMachine.enabled').toUpperCase()}
                                        className="radioAtivo"
                                        iconStyle={{ fill: '#2D4F7F' }}
                                    />
                                    <RadioButton
                                        value={false}
                                        label={this.props.t('timeMachine.disabled').toUpperCase()}
                                        className="radioInativo"
                                        iconStyle={{ fill: '#2D4F7F' }}
                                    />
                                </RadioButtonGroup>
                            </div>
                        </Grid>
                        <Grid cols="7 7 7" />
                    </Row>
                    <Row>
                        <Grid cols="12 12 12">
                            <div className="labelRotaWrapper">
                                <label>
                                    {this.props.t('timeMachine.obs').toUpperCase()} <i className="fa fa-pencil" onClick={() => this._setObservacoes()}></i>
                                </label>
                            </div>
                        </Grid>
                    </Row>
                    <Row>
                        <Grid cols="12 12 12">
                            <textarea cols={49}
                                rows={6}
                                className={`TextArea`}
                                ref={"textAreaRef"}
                                value={this.rotaStore.Observacoes || ""}
                                onChange={() => this._getObservacoesValue()}
                                maxLength={150}
                                name='observacoes' />

                        </Grid>
                    </Row>
                    <Row>
                        <Grid cols="11 11 11">
                            <div className="buttonWrapper">
                                <NButtonGroup>
                                    <NButton
                                        onClick={event => this._salvar(event)}
                                        className={this.state.botaoDesabilitado ? defaultStyle.button_ghost_disable : ''}
                                        disabled={this.state.botaoDesabilitado}
                                        type="primary"
                                    >
                                        {this.props.t('timeMachine.save')}
                                    </NButton>
                                    <NButton onClick={e => this._verificaVazio(e)}>{this.props.t('timeMachine.cancel')}</NButton>
                                </NButtonGroup>
                            </div>
                        </Grid>
                    </Row>
                </form>
            </div>
        )
    }
}

export default withTranslation('routes')(FormModalVigenciasRota)