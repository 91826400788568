import React from "react";
import { RaisedButton } from "material-ui";
import moment from "moment";
import Util from "../../utils/Utils";
import Container from "../../common/Container";
import update from "immutability-helper";
import { requestRelatorioXLSX } from "../../common/request";
import {
  NForm,
  NFormTitle,
  NButtonGroup,
  NDatePicker,
  NContainer,
  alertBox
} from "../../common/NForm";
import i18n from 'utils/i18n';
import { AutoComplete } from '../../common/MaterialForm';

export default class ModalAuditTrail extends Container {
  constructor(props) {
    super(props);

    this.state = {
      dataInicio: undefined,
      dataFim: undefined,
      dataInicioText: "",
      dataFimText: "",
      maxDate: moment(),
      filtroSelected: undefined,
      erroFiltro: false,
      errors: {
        dataInicioObrigatoria: undefined,
        dataFimObrigatoria: undefined
      }
    }

    this.updateStateByName = this.updateStateByName.bind(this);
    this.validarDatas = this.validarDatas.bind(this);
    this.gerarRelatorioAuditTrail = this.gerarRelatorioAuditTrail.bind(this);
    this.updateDataInicio = this.updateDataInicio.bind(this);
    this.updateDataFim = this.updateDataFim.bind(this);
    this.limparData = this.limparData.bind(this);
  }

  async gerarRelatorioAuditTrail() {
    let { dataInicio, dataFim, filtroSelected } = this.state;

    let intervalo = dataFim.diff(dataInicio, 'days') + 1;

    if (intervalo > 31) {
      this.alertIntervalo("dataFim");
      return;
    }

    if (dataInicio) {
      dataInicio = dataInicio.format("DD-MM-YYYY") + " 00:00:00";
    }

    if (dataFim) {
      dataFim = dataFim.format("DD-MM-YYYY") + " 23:59:59";
    }

    if (!dataInicio || !dataFim) {
      const newState = {};

      if (!dataInicio) {
        newState.dataInicioObrigatoria = { $set: i18n.t('messages.requiredField', { ns: 'common' }) };
      }

      if (!dataFim) {
        newState.dataFimObrigatoria = { $set: i18n.t('messages.requiredField', { ns: 'common' }) };
      }

      this.setState(update(this.state, {
        errors: newState
      }));

      return;
    }

    if (filtroSelected === undefined || filtroSelected.value === '') {
      this.setState({ erroFiltro: true });

      return;
    }
    else {
      this.setState({ erroFiltro: false });
    }

    const url = `${this.endpoints.RELATORIO_AUDIT_TRAIL}/${dataInicio}/${dataFim}/${filtroSelected.value}`;

    this.props.openModal("loading_relatorio");

    await requestRelatorioXLSX(url, "Relatório Audit Trail").then(() => {
      this.props.closeModal();
    })
      .catch(() => {
        this.setState({
          dataInicio: "",
          dataFim: ""
        });

        this.props.setErrorMessage(i18n.t('errors.cannotGenerateReport', { ns: 'reports' }));
        this.props.openModal("erro");
      });

    this.setState({
      errors: {}
    });
  }

  alertDataMaiorQueAtual(campoData) {
    alertBox(i18n.t('errors.selectDateBeforeCurrentDate', { ns: 'reports' }), i18n.t('errors.invalidDate', { ns: 'reports' }), "warning");
    this.limparData(campoData);
  }

  alertIntervalo(campoData) {
    alertBox(i18n.t('errors.interval31Days', { ns: 'reports' }), i18n.t('errors.invalidDate', { ns: 'reports' }), "warning");
    this.limparData(campoData);
  }

  validarDatas() {
    let dataInicio = this.state.dataInicio;
    let dataFim = this.state.dataFim;

    if (!dataInicio || !dataFim) {
      return;
    }

    let hoje = moment();

    if (dataInicio.isAfter(hoje)) {
      this.alertDataMaiorQueAtual("dataInicio");
      return;
    }

    if (dataFim.isAfter(hoje)) {
      this.alertDataMaiorQueAtual("dataFim");
      return;
    }

    if (dataFim.isAfter(this.state.maxDate)) {
      alertBox(i18n.t('errors.periodLimited', { ns: 'reports' }), i18n.t('errors.invalidPeriod', { ns: 'reports' }), "warning");
      this.limparData("dataFim");
    }

    if (dataInicio.isAfter(dataFim)) {
      alertBox(i18n.t('errors.endDateMustBeGreaterThan', { ns: 'reports', startDate: dataFim }), i18n.t('errors.invalidDate', { ns: 'reports' }), "warning");
      this.limparData("dataFim");
    }
  }

  limparData(nomeCampo) {
    this.setState((_state, _props) => ({
      [nomeCampo]: undefined,
      [nomeCampo + "Text"]: "",
      [nomeCampo + i18n.t('labels.required', { ns: 'reports' })]: undefined,
    }));
  }

  async updateStateByName(property) {
    let { name, value } = property;

    this.setState({
      [name]: moment(value, "DD/MM/YYYY"),
      [name + "Text"]: value,
    });
  }

  updateDataInicio(date) {
    this.setState(update(this.state, {
      dataInicio: { $set: date },
      dataInicioText: { $set: moment(date, "DD/MM/YYYY") },
      dataFim: { $set: "" },
      maxDate: { $set: moment().isBefore(moment(date).add(3, "months")) ? moment() : moment(date).add(3, "months") },
      errors: { dataInicioObrigatoria: { $set: undefined } }
    }));
  }

  updateDataFim(date) {
    this.setState(update(this.state, {
      dataFim: { $set: date },
      dataFimText: { $set: moment(date, "DD/MM/YYYY") },
      errors: { dataFimObrigatoria: { $set: undefined } },
    }));
  }

  setFiltro(nomeFiltro, filtros) {
    let obj = filtros.find(i => i.text == nomeFiltro);

    this.setState({ filtroSelected: obj });
  }

  listarFiltros() {
    var opcoesFiltro = [
      { text: i18n.t('labels.eventRecords', { ns: 'common' }), value: 1 },
      { text: i18n.t('labels.equipment', { ns: 'common' }), value: 2 },
      { text: i18n.t('labels.events', { ns: 'common' }), value: 3 },
      { text: i18n.t('labels.holdingTime', { ns: 'common' }), value: 4 },
      { text: i18n.t('labels.orders', { ns: 'common' }), value: 5 },
      { text: i18n.t('labels.products', { ns: 'common' }), value: 6 },
      { text: i18n.t('labels.routes', { ns: 'common' }), value: 7 },
      { text: i18n.t('labels.industrialUnit', { ns: 'common' }), value: 8 },
      { text: i18n.t('labels.orderLinks', { ns: 'common' }), value: 9 }
    ];

    return opcoesFiltro;
  }

  render() {
    let opcoesFiltro = this.listarFiltros();

    return (
      <div>
        <NForm>
          <NFormTitle>{i18n.t('titles.auditTrailReport', { ns: 'reports' })}</NFormTitle>

          <div className={'flex flex-flow-col flex-just-between'} style={{ height: '5em' }}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-10px' }}
              onClick={() => this.setState({ filtroSelected: undefined })}
              underlineStyle={this.state.erroFiltro ? { borderColor: 'red' } : ''}
              fullWidth={true}
              underlineFocusStyle={{ borderColor: '#000000' }}
              floatingLabelStyle={{ color: '#5B5B5B' }}
              listStyle={{ width: '100%' }}
              floatingLabelText={i18n.t('labels.feature', { ns: 'common' }).toUpperCase()}
              title={i18n.t('labels.feature', { ns: 'common' }).toUpperCase()}
              onUpdateInput={this.setFiltro.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              dataSource={opcoesFiltro}
              openOnFocus={true}
              popoverProps={{ className: 'autocomplete' }} />
            {this.state.erroFiltro ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{i18n.t("errors.requiredField", { ns: 'validation' })}</div> : ''}
          </div>

          <NContainer direction="horizontal">
            <NDatePicker
              compact={true}
              required={this.state.errors.dataInicioObrigatoria}
              selected={this.state.dataInicio}
              label={i18n.t('labels.startDate', { ns: 'reports' }).toUpperCase()}
              autoComplete={"off"}
              maxDate={moment()}
              onChangeRaw={event => Util.formatDate(event, this.updateStateByName, "dataInicio", this.validarDatas)}
              onChange={this.updateDataInicio}
              value={this.state.dataInicioText}
            />

            <NDatePicker
              compact={true}
              required={this.state.errors.dataFimObrigatoria}
              selected={this.state.dataFim}
              minDate={this.state.dataInicio}
              maxDate={this.state.maxDate}
              label={i18n.t('labels.endDate', { ns: 'reports' }).toUpperCase()}
              autoComplete={"off"}
              onChangeRaw={event => Util.formatDate(event, this.updateStateByName, "dataFim", this.validarDatas)}
              onChange={this.updateDataFim}
              value={this.state.dataFimText}
            />
          </NContainer>

          <NButtonGroup style={{ paddingTop: 20 }}>
            <RaisedButton
              onClick={this.gerarRelatorioAuditTrail}
              backgroundColor="#2D4F7F"
              buttonStyle={{ fontFamily: "" }}
              style={{
                fontWeigth: 100,
                marginRight: "1em"
              }}
              labelColor="#FFF"
              label={i18n.t('labels.generate', { ns: 'common' })} />

            <RaisedButton
              onClick={this.props.closeModal}
              label={i18n.t('labels.cancel', { ns: 'common' })} />
          </NButtonGroup>
        </NForm>
      </div>
    );
  }
}