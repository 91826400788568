import React, { Component } from 'react'
import MDSpinner from "react-md-spinner";
import { Scrollbars } from 'react-custom-scrollbars';
import update from 'immutability-helper';
import Grid from '../Grid/grid';
import Row from '../Grid/row';
import ModalItem from './ModalItem';
import { IF } from '../NForm'
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';

@inject('store')
@observer
class ModalItens extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ModalItens: [],
        }

        this._mapItens = this._mapItens.bind(this)
        this._hadleOnchangeModalItens = this._hadleOnchangeModalItens.bind(this);
        this._handleSelect = this._handleSelect.bind(this);
        this._handleSelected = this._handleSelected.bind(this);
        this._createNew = this._createNew.bind(this);
        this.modalItensStore = this.props.store.modalItens;
        this.modalItensStore.previousItem = undefined;
        this.modalItensStore.currentItem = 0;
    }

    _mapItens(itens) {
        return itens.map(
            (item, index) => <ModalItem
                editable={this.props.editable}
                onChange={value => this._hadleOnchangeModalItens(value, index)}
                onDelete={() => this.props.onDelete(index)}
                keys={index}
                key={index}
                text={item.text || "Texto não configurado"}
                active={this.modalItensStore.currentItem == index}
                onClick={() => this._handleSelect(index, item, this.props.customFunctionOnChange)}
            />

        )
    }

    _hadleOnchangeModalItens(value, i) {
        this.setState(
            update(this.state, {
                ModalItem: { [i]: { props: { text: { $set: value } } } }
            })
        );
    }

    _handleSelect(index, item, customFunction) {
        this.modalItensStore.currentItem = index;
        if (customFunction) customFunction(item);
    }

    _handleSelected() {
        if (this.props.initialSelected) {
            let selectedItem = this.props.store.timeMachineRotas.arrayItensCommon.get(this.props.initialSelected);
            this.props.store.timeMachineRotas.previousDate = this.props.getPreviousDate(selectedItem);
            this.modalItensStore.currentItem = this.props.initialSelected;
        }
    }

    _createNew() {
        this.setState(
            update(this.state, {
                ModalItem: {
                    $set: this._mapItens(this.modalItensStore.CustomArray),
                }
            })
        );
    }

    componentDidMount() {
        this.setState({ ModalItem: this._mapItens(this.modalItensStore.CustomArray) })
        this._handleSelected()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.modalItensStore.previousItem != this.modalItensStore.currentItem) {
            this.setState({ ModalItem: this._mapItens(this.modalItensStore.CustomArray) })
            this.modalItensStore.previousItem = this.modalItensStore.currentItem;
        }

        when(
            () => this.modalItensStore.TriggredAdd,
            () => {
                this._createNew()
                this.modalItensStore.setTriggredAdd(false);
            }
        )
    }

    render() {
        return (
            <div className="marginItens">
                <Row>
                    <Grid cols="7 7 12">
                        <IF test={!this.state.ModalItem}>
                            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
                        </IF>
                        <IF test={this.state.ModalItem}>
                            <Scrollbars style={{ height: 400 }}>
                                {this.state.ModalItem}
                            </Scrollbars>
                        </IF>
                    </Grid>
                </Row>
            </div>

        )
    }
}

export default ModalItens