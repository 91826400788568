import React, { Component } from 'react';
import listStyles from './list.scss';
import Lot from './Lot';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
moment.locale('pt-br');

class ListPulmao extends Component {
  render() {
    let {listWaiting, listWarning, stage, handleModal} = this.props;
    
    var lista = listWaiting.filter(obj => obj.fk_id_stages == stage.id).map(obj => ({
      ...obj,
      inconsistente: false
    })).concat(listWarning.filter(obj => obj.fk_id_stages == stage.id).map(obj => ({
      ...obj,
      inconsistente: true
    })))
    .sort((a, b) => {
      return new Date(b.reference_time).getTime() - new Date(a.reference_time).getTime();
    });

    var componentesLote = lista.map((obj, i) => <Lot type="pulmao" className='pulmao' listOthers={lista} opQualidade={false} {...this.props} indexFifo={i} key={i} obj={obj}/>);

    return (
      <ul className={listStyles.listPulmao}>
        { componentesLote.length > 0 ?
            <div onClick={() => handleModal('listWaitingBatchStage','','', lista, '', stage)}>
              {this.props.t("labels.waiting")}
            </div>
          :
            null
        }
        {
          componentesLote
        }
      </ul>
    );
  }
}

export default withTranslation("flowView")(ListPulmao);