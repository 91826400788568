import React, { Component } from 'react';
import styles from './route.scss';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

class ListNameProduct extends Component {

  componentDidUpdate() {
    this.refs.list_names_products.scrollTop = this.props.scrollTop;
  }

  checkBrokenRoute(routes) {
    return routes.find(route => route.equipment == "Equipamento inexistente");
  }

  render() {
    let { session, stages, metrics, products, handleModal, removeRoute } = this.props;
    let list = [];
    let count = 0;

    products.map((product, i) => {
      let routes = product.routes.filter(route => route.fk_id_sessions == session);
      if (routes.length > 0) {
        list.push(
          <div key={'lp' + product.id} style={{ height: metrics.arrHeight && metrics.arrHeight[count] ? metrics.arrHeight[count] : 0 }}>
            <div style={{ borderRight: '1px solid #888'}}>
             {product.sku} - {product.name}
              <div className={styles.btn}>
                <i className='material-icons cursor-pointer' onClick={() => handleModal('isStepOne', { stages: stages, product: product, typeModal: 'up' })}>edit</i> 
                <i className='material-icons cursor-pointer' onClick={() => hasRole('ROLE_ROTAS_EXCLUIR') && handleModal('isRemoveRoute', { session, product })}>cancel</i>
              </div>
            </div>
          </div>
        );
        count++;
      }
    });

    return (
      <div>
        <div className={styles.title_product}>{this.props.t("listNameProduct.product")}</div>
        <div ref='list_names_products' className={styles.list_names_products}>
          {list}
        </div>
      </div>
    );
  }
}

export default withTranslation('routes')(ListNameProduct);