import React, { Component } from 'react';
import { observer } from 'mobx-react';
import eventStyles from '../notes/notes.scss';

import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import FilterBox from '../../common/FilterBox';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@observer
class GroupEventList extends Component {
  constructor(){
    super();
    this.onFilter = this.onFilter.bind(this);
  }

  componentWillMount() {
    this.props.store.searchModel = 'group_event';
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }
  
  onFilter(val) {
    this.props.store.searchValue = val;
  }

  render() {
    let items = this.props.store.searchItems.sort(function(a, b){
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    }).map((item, k) => {
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.name}</td>
          <td>
            <div className='options'>
              <i className='material-icons' title={this.props.t('list.actions.edit', { ns: 'common' })} onClick={() => this.props.handleModal(item, 'up')}>edit</i>
              <i className='material-icons' title={this.props.t('list.actions.delete', { ns: 'common' })} onClick={() => hasRole('ROLE_GRUPOS_EVENTOS_EXCLUIR') && this.props.handleModal(item, 'del')}>cancel</i>
            </div>
          </td>
        </tr>
      )
    });
    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_GRUPOS_EVENTOS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText className='table_overflow'>
              <FilterBox onFilter={this.onFilter}/>
              <table className='mui-table mui-table--bordered'>
                <thead>
                  <tr>
                    <td className='id'>#</td>
                    <td className='name'>{this.props.t("list.columns.name")}</td>
                    <td className='actions'>{this.props.t('list.columns.action', { ns: 'common', count: 2 })}</td>
                  </tr>
                </thead>
                <tbody className='box_items'>
                  {items}
                </tbody>
              </table>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_GRUPOS_EVENTOS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText>
              <div style={{marginTop: 30}}>
                <h1>{this.props.t('list.empty', { ns: 'common' })}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation("groupEvent")(GroupEventList);
