import React, { Component } from 'react';
import Constants from '../common/Constants'
import { request } from '../common/request';
import createBrowserHistory from 'history/createBrowserHistory';
import login from './login.scss';

const history = createBrowserHistory({ forceRefresh: true });

/**
 * Componente responsável por realizar o logout da aplicação que será invocado pelo Client 2.0.
 */
export default class Logout extends Component {

    /**
     * Construtor da classe.
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
    }

    /**
     * Inicializa o Componente.
     */
    componentWillMount() {
        const token = localStorage.getItem('token');

        if (!token) {
            history.push('/');
            return;
        }

        request(Constants.APIEndpoints.SGCA_DESLOGAR).then(() => {
            let index = localStorage.length;

            while (index--) {
                let key = localStorage.key(index);
                localStorage.removeItem(key);
            }

            history.push('/');
        });
    }

    /**
     * Retorna o template JSX do componente.
     */
    render() {
        return (
            <div className={login.loginContainer}></div>
        );
    }
}