import React, { Component } from 'react';
import {FormSelect} from '../../common/Form';
import styles from './route.scss';
import Bc from '../../common/breadcrumb.scss';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { withTranslation } from 'react-i18next';

class Breadcrumbs extends Component {
  constructor() {
    super();
    this.state = {
      plus: false,
      breadcrumbs: [],
      start: false
    };

    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.updateBreadcrumbs = this.updateBreadcrumbs.bind(this);
  }

  componentDidMount() {
    this.updateBreadcrumbs(this.props, this.props.arrStage.length > 0);
  }

  componentWillReceiveProps(nextProps) {
    this.updateBreadcrumbs(nextProps, false);
  }

  updateBreadcrumbs(props, start) {
    let { arrStage, currentStage, obj } = props;

    let breadcrumbs = arrStage.map((st, i) => {
      st.active = i==currentStage;
      st.name = obj.stages.filter(f => f.id == st.fk_id_stages)[0].name;
      return st;
    });

    this.setState({
      breadcrumbs: breadcrumbs,
      start: start
    });
  }

  onEnter() {
    this.setState({
      plus: true
    });
  }

  onLeave() {
    this.setState({
      plus: false
    });
  }

  render() {
    let {selectedStage, setArrStage, setNextStage, stagesOptions} = this.props;
    let {breadcrumbs, plus, start} = this.state;

    return(
      <div onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} className={styles.modal_breadcrumb}>
        <div className={Bc.breadcrumb+' '+Bc.flat}>
          { breadcrumbs.map((breadcrumb, i) => <Breadcrumb key={i} current={i} breadcrumb={breadcrumb} {...this.props} />) }
          { !breadcrumbs.length  || (breadcrumbs.length > 0 && !selectedStage && !start)?<a href="#"><FormSelect id='stage' value='' options={stagesOptions} onChange={setArrStage} novalidate="true"/></a>:null}
        </div>
        {
          plus&&breadcrumbs.length?<i className="fa fa-plus cursor-pointer" onClick={setNextStage} style={{marginLeft: 10}} />:<i className="fa fa-plus" style={{marginLeft: 10, opacity: 0}} />
        }
      </div>
    );
  }
}

class Breadcrumb extends Component {
  constructor() {
    super();
    this.state = {
      edit: false
    };
    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onEdit() {
    this.setState({
      edit: true
    });
  }
  onSave(event) {
    this.state.edit = false;
    this.props.setArrStage(event);
  }
  render() {
    let {breadcrumb, current, stagesOptions, setCurrentStage, onDragToStage, ondragendToTrash} = this.props;
    let {active, name, fk_id_stages} = breadcrumb;

    return (
      <a className={active?Bc.active:''} onClick={() => setCurrentStage(current)} data-idx={current} onDragStart={onDragToStage} onDragEnd={ondragendToTrash} draggable="true">
        <ContextMenuTrigger id={`ctx-id-${current}`}>
          <ContextMenu id={`ctx-id-${current}`}>
            <MenuItem onClick={event => {
                event.stopPropagation();
                this.props.remArrStage(current);
              }
            }>{this.props.t("breadcrumbs.removeRoute")}</MenuItem>
          </ContextMenu>
          <span style={{color: '#fff'}}>
            <i className="fa fa-arrows-alt" style={{marginRight: 5}}/>
            {
              this.state.edit
              ?<FormSelect id='stage' value={fk_id_stages} options={stagesOptions} onChange={this.onSave} novalidate="true"/>
              :name+' '
            }
          </span>
        </ContextMenuTrigger>
      </a>
    )
  }
}

export default withTranslation('routes')(Breadcrumbs);