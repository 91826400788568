import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Card, CardHeader, CardText } from 'material-ui/Card';
import { hasRole } from '../../helper/SecurityHelper';

import Form from '../../common/Form';
import Checkbox from 'material-ui/Checkbox';
import css from './maintenance.css';
import { withTranslation } from 'react-i18next';

@observer
class MaintenanceList extends Component {

  constructor() {
    super();
    this.state = {
      type: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({ type: event.target.value });
  }

  render() {
    let items = [];
    this.props.store.events.filter(_prod => !_prod.is_batch && _prod.fk_id_table == 3).map((item, k) => {
        items.push(
          <tr key={k}>
            <td>{k + 1}</td>
            <td>{item.cod}</td>
            <td>{item.descr}</td>
            <td>{item.classe.nome ? item.classe.nome : ''}</td>
            <td className={css.checkboxCenterAlign} style={{textAlign: 'center'}}>
              {this.state.type ? <Checkbox disabled={!hasRole('ROLE_MANUTENCAO_ATUALIZAR')} checked={item[this.state.type]} onCheck={() => this.props.onCheck(item, this.state.type, !item[this.state.type])} /> : ''}
            </td>
          </tr>
        )
    });

    let options = [
      { value: 'oee_mwt', text: this.props.t("list.labels.oee_mwt") },
      { value: 'oee_mttp_mtbp', text: this.props.t("list.labels.oee_mttp_mtbp") },
      { value: 'oee_mttr_mtbf', text: this.props.t("list.labels.oee_mttr_mtbf") }
    ];

    let fields = [
      { name: 'type', label: this.props.t("list.labels.type"), type: 'select', onChange: this.onChange, required: true, options: options, value: this.state.type }
    ];

    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText>
              <Form ref='form' columns={this.props.columns} fields={fields} buttons={[]}/>
              { this.state.type ?
                 <table className='mui-table mui-table--bordered'>
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>{this.props.t("list.columns.code")}</td>
                      <td>{this.props.t("list.columns.name")}</td>
                      <td>{this.props.t("list.columns.class")}</td>
                      <td style={{textAlign: 'center'}}>{this.state.type ? this.props.t("list.columns.consider") : ''}</td>
                    </tr>
                  </thead>
                  <tbody className='box_items'>
                    {items}
                  </tbody>
                </table> : null }
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t('list.empty', { ns: 'common' })}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation("maintenance")(MaintenanceList);