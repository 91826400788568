import React from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import DataCollectionList from './DataCollectionList';
import { request } from '../../common/request';
import { respostaDadosColeta } from '../../common/mappers';
import MDSpinner from "react-md-spinner";
import { withTranslation } from 'react-i18next';

@observer
class DataCollectionPage extends Container {
    constructor(props) {
        super(props);

        this.state = {
            dadosColeta: [],
            loadingPage: true
        }
    }

    componentDidMount() {
        request(this.endpoints.DADOS_COLETA, {
            method: 'get'
        })
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 401) {
                    this.setState({
                        loadingPage: false
                    });
                }
            }).then(data => {
                this.setState({
                    dadosColeta: respostaDadosColeta(data),
                    loadingPage: false
                })
            });
    }

    render() {
        if (this.state.loadingPage) {
            return (
                <Container sectionStyle={{ height: '94vh' }}>
                    <div className={'loading-wrapper'}>
                        <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
                    </div>
                </Container>
            );
        } else {
            return (
                <Container>
                    <DataCollectionList dadosColeta={this.state.dadosColeta} endpoints={this.endpoints} t={this.props.t} />
                </Container>
            );
        }
    }
}

export default withTranslation('dataCollection')(DataCollectionPage);