import React, { Component } from 'react'
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { IF, NForm } from '../NForm'
import Grid from '../Grid/grid';
import Row from '../Grid/row';

class ModalLeftContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.containerClassName}>
                <NForm>
                    <Row>
                        <Grid cols="9 9 9">
                            <Row>
                                <Grid cols="2 2 2">
                                    {this.props.icon}
                                </Grid>
                                <Grid cols="8 8 8">
                                    <div className="modalTitle">
                                        {this.props.title}
                                    </div>
                                </Grid>
                            </Row>
                        </Grid>
                        <IF test={this.props.add}>
                            <Grid cols="2 2 2">
                                <FloatingActionButton zDepth={0} mini={true} className={`add_modal time_machine_add`} onClick={() => { this.props.customAddFunction() }}>
                                    <ContentAdd />
                                </FloatingActionButton>
                            </Grid>
                        </IF>
                    </Row>
                    <Row >
                        <Grid cols="12 12 12">
                            {this.props.children}
                        </Grid>
                    </Row>
                </NForm>
            </div>
        )
    }
}

export default ModalLeftContainer