import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../common/Container';
import RoutesBox from './RoutesBox';
import ModalStepOne from './ModalStepOne';
import Quest from '../../common/Quest';
import $ from 'jquery';
import moment from 'moment';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { request, requestAll } from '../../common/request';
import MDSpinner from "react-md-spinner";
import { alertBox } from '../../common/NForm';
import ModalContainer from '../../common/Modal/ModalContainer';
import ModalLeftContainer from '../../common/Modal/ModalLeftContainer';
import ModalItens from '../../common/Modal/ModalItens';
import ModalRightContainer from '../../common/Modal/ModalRightContainer'
import FormModalVigenciasRota from '../../common/Modal/formsModal/formVigenciaRotas'
import Calendario from '../../assets/Calendario.svg';
import AlertContainer from '../../common/Alerta/alertContainer';
import AlertContent from '../../common/Alerta/alertContent';
import DeleteVigenciaRotas from '../../common/Alerta/customAlert/timeMachine/deleteVigenciaRotas';
import { when } from 'mobx';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';
import {
  respostaEquipamentos,
  respostaProdutos,
  respostaUnidadeIndustrial,
  mapearModal
} from '../../common/mappers';

@inject('store')
@observer
class RouteContainer extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      typeModal: null,
      hideModal: false,
      objModal: {},
      searchProduct: '',
      loadingPage: true,
      msg_erro: '',
      equipamentos: []
    };

    this.handleSession = this.handleSession.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.removeRoute = this.removeRoute.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.changeContentModal = this.changeContentModal.bind(this);
    this.setSearchProduct = this.setSearchProduct.bind(this);
    this.mapearArrayItens = this.mapearArrayItens.bind(this);
    this.connectionModalRightLetf = this.connectionModalRightLetf.bind(this);
    this.addNewTimeMachine = this.addNewTimeMachine.bind(this);
    this.createNewItem = this.createNewItem.bind(this);
    this.deleteTimeMachineRotas = this.deleteTimeMachineRotas.bind(this);
    this.handleNoFunctionTimeMachineRotas = this.handleNoFunctionTimeMachineRotas.bind(this)
    this.getPreviousDate = this.getPreviousDate.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.carregarRotas = this.carregarRotas.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      when(
        () => this.props.store.routeContainerStore.AtualizarRotas,
        () => this.carregarRotas()
      )
    }
  }

  carregarRotas() {
    requestAll([
      {
        url: this.endpoints.PRODUTO + '?incluirRotas=true',
        modelo: 'products',
        modificador: respostaProdutos,
        acao: true
      }
    ], this);
  }

  componentWillMount() {
    requestAll([
      {
        url: this.endpoints.PRODUTO + '?incluirRotas=true',
        modelo: 'products',
        modificador: respostaProdutos,
        acao: true
      }, {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial,
        acao: true
      }, {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      }, {
        url: this.endpoints.EQUIPAMENTOS,
        destino: 'state',
        modelo: 'equipamentos',
        modificador: respostaEquipamentos,
        acao: true
      }
    ], this);
  }

  setSearchProduct(value) {
    this.setState({
      searchProduct: value
    });
  }

  handleSession(event) {
    let session = this.store.sessions.filter((s) => s.id == event.target.value)[0];
    if (session) {
      localStorage.setItem('sessionId', session.id);
      this.setState({
        session
      });

      if (!this.state.loadingPage && $('#contentBox').hasClass('is_notLoaded')) {
        $('#contentBox').removeClass('is_notLoaded').addClass('is_loaded');
      }
    } else {
      this.setState({
        session: ''
      });
    }
  }

  handleModal(type, obj) {
    this.setSearchProduct("");

    if (type != undefined && obj != undefined) {
      this.setState({
        typeModal: type,
        objModal: obj
      });
    }

    this.setState({
      modal: !this.state.modal
    });
  }

  hideModal() {
    this.setState({
      hideModal: !this.state.hideModal
    })
  }

  changeContentModal(type, obj) {
    this.setState({
      typeModal: type,
      objModal: obj
    });
  }

  //Função que manipula os dois lados dos modais usando stores destintas
  connectionModalRightLetf(item) {
    this.props.store.timeMachineRotas.saveValues(true)
    this.props.store.timeMachineRotas.showValuesOnUpdate(item)
    this.props.store.modalItens.setCustomArray(this.props.store.timeMachineRotas.ArrayItensCommon)
    this.props.store.timeMachineRotas.previousDate = this.getPreviousDate(item);
  }

  getPreviousDate = (selectedItem) => {
    if (!selectedItem) return;

    let orderedDates = this.props.store.timeMachineRotas.ArrayItensCommon
      .sort((a, b) => moment(a.dataEfetivo).format() - moment(b.dataEfetivo).format())
      .map(item => moment(item.dataEfetivo).format());

    let previousDate = orderedDates.reverse().find(date => date < moment(selectedItem.dataEfetivo).format());
    this.props.store.timeMachineRotas.showTooltip = !!previousDate;

    if (!!previousDate) {
      return moment(previousDate).format('L');
    }

    return undefined;
  }

  // Necessário pois utiliza dois tipos de abrir modal o antigo e o novo
  handleNoFunctionTimeMachineRotas() {
    this.props.store.modalCommon.close()
    let data = this.props.store.timeMachineRotas.ArrayItensCommon;
    let indexUltimaVigencia;

    if (this.props.store.alertVigenciaRotas.Index >= 1) {
      indexUltimaVigencia = this.props.store.alertVigenciaRotas.Index - 1
      data[indexUltimaVigencia].dataFim = null;
    };

    data['current_route'] = this.state.objModal.current_route;

    this.handleModal('editVigencia', data);
  }

  deleteTimeMachineRotas(index) {
    if (this.props.store.timeMachineRotas.ArrayItensCommon.length == 1) {
      alertBox(this.props.t("container.messages.requiredTerm"), this.props.t("container.route"), 'warning');
      return;
    }

    // Realizar a construção via Factory
    var alert = <DeleteVigenciaRotas />
    var title = this.props.t("container.messages.deleteTermTitle");
    var subtitle = this.props.t("container.messages.deleteTermSubtitle");

    let alertContent = <AlertContainer>
      <AlertContent title={title} subtitle={subtitle}>
        {alert}
      </AlertContent>
    </AlertContainer>

    this.props.store.alertVigenciaRotas.setCustomNoFunction(this.handleNoFunctionTimeMachineRotas)
    this.props.store.alertVigenciaRotas.setIndex(index)
    this.handleModal('')
    this.props.store.modalCommon.open(alertContent, [{ itemIndex: index, commonState: this.props.store.timeMachineRotas.ArrayItensCommon }])
  }

  // Crio dicionario para eu saber qual vigencia anterior estou ligado em meu estado atual 
  createMapVigenciaAnterior(indexAnterior, date) {
    return {
      indexAnterior: indexAnterior,
      date: date
    }
  }

  // @Param itemAnterior é um dicionario para saber qual itens estão ligados para fazer a validação de choque de datas Ex: { indexAnterior: indexAnterior, date: date }
  // Ele é salvo no registro filho e não no pai, no final realizo uma verificação para popular os pais com os respectivos index no meu estado
  createNewItem(itemAnterior) {
    let now = new Date()
    return {
      text: moment().format('L'),
      ativo: true,
      dataCriacao: '',
      dataEfetivo: moment().format('YYYY-MM-DD HH:mm:ss'),
      dataFim: '',
      id: parseFloat(parseFloat(now.getMilliseconds() * Math.random()).toFixed(3)),
      observacao: '',
      originalId: this.props.store.timeMachineRotas.OriginalId,
      originalUuid: this.props.store.timeMachineRotas.originalUuid,
      usuario: 0,
      uuid: '',
      alterado: true,
      novo: true,
      vigenciaAnterior: itemAnterior
    }
  }

  addNewTimeMachine() {
    let oldItens = this.props.store.modalItens.CustomArray.slice()
    let newItem = this.createNewItem(this.createMapVigenciaAnterior((oldItens.length - 1), new Date()))
    oldItens.push(newItem);
    this.props.store.modalItens.setCustomArray(oldItens)
    this.props.store.timeMachineRotas.setArrayItensCommon(oldItens)
    this.props.store.modalItens.setTriggredAdd(true);
    this.connectionModalRightLetf(newItem);
    this.props.store.modalItens.previousItem = this.props.store.modalItens.currentItem;
    this.props.store.modalItens.currentItem = oldItens.findIndex(item => item == newItem);
  }

  mapearArrayItens(itens) {
    return itens.sort((a, b) => moment(a.dataEfetivo) - moment(b.dataEfetivo)).map(item => {
      let efetivo = moment(item.dataEfetivo).format('L')
      return (
        {
          text: efetivo,
          ativo: item.ativo,
          dataCriacao: item.dataCriacao,
          dataEfetivo: item.dataEfetivo,
          dataFim: item.dataFim ? moment(item.dataFim).format('YYYY-MM-DD HH:mm:ss') : null,
          id: item.id,
          observacao: item.observacao,
          originalId: item.originalId,
          originalUuid: item.originalUuid,
          usuario: item.usuario,
          uuid: item.uuid,
          vigenciaAnterior: { indexAnterior: null, date: null },
          destinoId: item.destino_id ? item.destino_id : item.destinoId,
          vigenciaAtiva: item.dataFim == null ? true : false
        }
      )
    }
    )
  }

  contentModal() {
    let session = this.store.sessions.find(s => s.id == this.store.getSession());
    let products = this.store.products.filter((p) => !!p);

    switch (this.state.typeModal) {
      case 'alerta_produto_lote':
        const alertaProdutoLote = (
          <div>
            <h2 className='bolded-text'>
              {this.state.msg_erro}
            </h2>
          </div>
        );

        return <Quest
          question={alertaProdutoLote}
          onlyOkButton={true}
          simText={'Ok'}
          handleModal={this.handleModal}
          action={this.handleModal}
        />;

      case 'isStepOne':
        let modalOneStore = this.props.store.modalStepOne;

        if (this.state.objModal.product) {
          modalOneStore.setProduct(products.find(x => x.id == this.state.objModal.product.id))
        } else {
          modalOneStore.setProduct(products)
        }

        modalOneStore.setEquipament(this.store.equipments)
        modalOneStore.setProductStage(this.state.objModal)

        var rotaProdutoId = 0;
        if (this.state.objModal.product) {

          var sessionId = localStorage.getItem('sessionId');
          var rotas = this.state.objModal.product.routes.filter(rota => rota.fk_id_sessions == sessionId);

          if (rotas) {
            rotaProdutoId = rotas[0].RotasProdutosId;
          }
        }

        modalOneStore.setSelectedRotasProdutos(rotaProdutoId);
        modalOneStore.renderNewValues();

        return <ModalStepOne showErro={this.showErro} showAlert={this.showAlert} equipamentos={this.state.equipamentos}
          session={session} stages={session.stages} products={products}
          equipments={this.store.equipments} handleModal={this.handleModal}
          hideModal={this.hideModal}
          changeContentModal={this.changeContentModal} action={this.action}
          endpoints={this.endpoints} obj={this.state.objModal} utils={this.utils}
          style={{ opacity: this.state.hideModal ? 0 : 1 }} />;
        break;
      case 'editVigencia':
        //Normalizando meus dados para o molde do modalItens
        var arrayItens = mapearModal(this.state.objModal, 'dataEfetivo');
        arrayItens = this.mapearArrayItens(this.state.objModal);

        //Iniciando tanto os atributos iniciais do ja settado
        this.props.store.timeMachineRotas.setInitialAttributes(arrayItens);
        this.props.store.modalItens.setCustomArray(arrayItens);

        let vigenciaAtual = this.state.objModal.find(vigencia => vigencia.dataFim == null);

        // Mapear rotas
        const right = <ModalRightContainer>
          <FormModalVigenciasRota
            data={this.state.objModal[0].dataEfetivo}
            ativa={!this.state.objModal[0].ativo}
            closeModal={this.handleModal}
            vigenciaAtual={vigenciaAtual}
            changeContentModal={this.changeContentModal}
            currentRoute={this.state.objModal.current_route}
            endpoints={this.endpoints}
          />
        </ModalRightContainer>

        const left = <ModalLeftContainer icon={<img src={Calendario} />} title={this.props.t('timeMachine.terms')} add={true} containerClassName="modalLeftContainer" customAddFunction={this.addNewTimeMachine}>
          <ModalItens customFunctionOnChange={item => this.connectionModalRightLetf(item)}
            initialSelected={this.props.store.timeMachineRotas.InitialItem}
            onDelete={this.deleteTimeMachineRotas}
            getPreviousDate={this.getPreviousDate}
            editable={false} />
        </ModalLeftContainer>

        return <ModalContainer
          title={this.props.t('timeMachine.termPeriods')}
          bgClassName="colorWhiteBG"
          splitScreen={true}
          right={right}
          left={left} />
        break;

      case 'isRemoveRoute':
        const question = (
          <div>
            <h2 className='bolded-text'>
              {this.props.t('container.messages.deleteQuestion')}
            </h2>
            <br />
            {this.state.objModal.product.sku} - {this.state.objModal.product.name}
          </div>
        );
        return <Quest question={question} handleModal={this.handleModal} action={() => this.removeRoute(this.state.objModal.product, this.state.objModal.session)} />;
        break;
    }
  }

  removeRoute(product, session) {
    var rotas = product.routes.filter(r => r.fk_id_sessions == session);
    request(`${this.endpoints.ROTA}/${rotas[0].RotasProdutosId}/produto/${product.id}`, {
      method: 'delete',
    }).then(res => {
      if (res.ok) {
        this.carregarRotas();
        alertBox(this.props.t("container.messages.deleteSuccess"), this.props.t("title"), 'success');
        this.handleModal();
      } else {
        return res.json();
      }
    })
      .then(dataError => {
        if (dataError) {
          this.setState({
            typeModal: 'alerta_produto_lote',
            msg_erro: dataError.message
          });
        }
      }).catch(err => {
        alertBox(this.props.t("container.messages.deleteError"), this.props.t("title"), 'error');
      })
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      let session = this.store.sessions.find(s => s.id == this.store.getSession());
      let products;
      if (session) {
        products = this.store.products.filter((p) => p.routes?.find(x => x?.fk_id_sessions == session.id));
        if (this.state.searchProduct != '') {
          let regex = new RegExp(this.state.searchProduct, 'i');

          products = products.filter((p) => p.routes?.find(x => x.fk_id_sessions == session.id && (regex.test(p.sku) || regex.test(p.name)))).sort(function (a, b) {
            if (a.sku > b.sku) {
              return 1;
            }
            if (a.sku < b.sku) {
              return -1;
            }
            return 0;
          });
          if (!this.state.loadingPage && $('#contentBox').hasClass('is_notLoaded')) {
            $('#contentBox').removeClass('is_notLoaded').addClass('is_loaded');
          }
        } else {
          products = products.filter((p) => p.routes?.find(x => x?.fk_id_sessions == session.id)).sort(function (a, b) {
            if (a.sku > b.sku) {
              return 1;
            }
            if (a.sku < b.sku) {
              return -1;
            }
            return 0;
          });
          if (!this.state.loadingPage && $('#contentBox').hasClass('is_notLoaded')) {
            $('#contentBox').removeClass('is_notLoaded').addClass('is_loaded');
          }
        }
      }

      return (
        <Container showSideBar modal={this.state.modal} contentModal={this.contentModal}>
          <div className='box_list'>
            <Card className='box_list_card is_notLoaded' id={'contentBox'} style={{ minWidth: '70%', maxWidth: '98%' }}>
              {session ?
                <FloatingActionButton disabled={!hasRole('ROLE_ROTAS_CRIAR')} className='box_add' onClick={() => this.handleModal('isStepOne', { stages: session.stages, typeModal: 'add' })}>
                  <ContentAdd />
                </FloatingActionButton>
                : null}
              <CardHeader className='box_header' title={this.props.t("title")} />
              <CardText>
                <RoutesBox ref="box" {...this.state} store={this.store} setSearchProduct={this.setSearchProduct} session={session ? session.id : ''} products={products} stages={session ? session.stages : ''} handleModal={this.handleModal} handleSession={this.handleSession} />
              </CardText>
            </Card>
          </div>
        </Container>
      );
    }
  }
}

export default withTranslation('routes')(RouteContainer);
