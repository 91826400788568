import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Card, CardHeader, CardText } from 'material-ui/Card';

import Form from '../../common/Form';
import Checkbox from 'material-ui/Checkbox';

import css from './availability.scss';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@observer
class AvailabilityList extends Component {

  constructor() {
    super();
    this.state = {
      type: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    this.setState({ type: event.target.value });
  }

  render() {
    let items = this.props.store.events.filter(_prod => !_prod.is_batch).map((item, k) => {
      let type = this.props.store.type_events.filter(t => t.id_table == item.fk_id_table)[0];
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.cod}</td>
          <td>{item.descr}</td>
          <td>{item.classe ? item.classe.nome : ''}</td>
          <td className={css.checkboxCenterAlign} style={{textAlign: 'center'}}>
            {this.state.type ? <Checkbox disabled={!hasRole('ROLE_OPCOES_CALCULO_ATUALIZAR')} checked={item[this.state.type]} onCheck={() => this.props.onCheck(item, this.state.type, !item[this.state.type])} /> : ''}
          </td>
        </tr>
      )
    });

    let options = [
      { value: 'oee_real', text: this.props.t("list.labels.oee_real") },
      { value: 'oee_effetive', text: this.props.t("list.labels.oee_effetive") },
      { value: 'oee_otimized', text: this.props.t("list.labels.oee_otimized") },
      { value: 'oee_teep', text: this.props.t("list.labels.oee_teep") },
    ];

    let fields = [
      { name: 'type', label: this.props.t("list.labels.type"), type: 'select', onChange: this.onChange, required: true, options: options, value: this.state.type }
    ];

    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText className='table_overflow'>
              <Form ref='form' columns={this.props.columns} fields={fields} buttons={[]}/>
                { this.state.type ? 
                  <table className='mui-table mui-table--bordered'>
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>{this.props.t("list.columns.code")}</td>
                        <td>{this.props.t("list.columns.name")}</td>
                        <td>{this.props.t("list.columns.class")}</td>
                        <td style={{textAlign: 'center'}}>{this.state.type ? this.props.t("list.columns.disconsider") : ''}</td>
                      </tr>
                    </thead>
                    <tbody className='box_items'>
                      {items}
                    </tbody>
                  </table> : null }
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t('list.empty', { ns: 'common' })}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation("availability")(AvailabilityList);