import React, { Component } from 'react';
import styles from './route.scss';
import ModalListEquipments from './ModalListEquipments';
import ModalOneDrop from './ModalOneDrop';
import Breadcrumbs from './Breadcrumbs';
import { FormAutoComplete } from '../../common/Form';
import trashImg from '../../assets/trash.png';
import _ from 'lodash';
import update from 'immutability-helper';
import { alertBox, IF, NButton } from '../../common/NForm';
import { requisicaoRotas, respostaProdutos, respostaTimeMachineAba } from '../../common/mappers';
import { request, requestAll } from '../../common/request';
import defaultStyle from '../../common/button.scss';
import $ from 'jquery';
import AbaContainer from '../../common/Abas/abaContainer';
import AbaContent from '../../common/Abas/abaContent'
import Calendario from '../../assets/Calendario.svg';
import { inject, observer } from 'mobx-react';
import { respostaRotas } from '../../common/mappers'
import MDSpinner from "react-md-spinner";
import AlertContainer from '../../common/Alerta/alertContainer';
import AlertContent from '../../common/Alerta/alertContent';
import { hasRole } from '../../helper/SecurityHelper';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';

@inject('store')
@observer
class ModalStepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipments: JSON.parse(JSON.stringify(props.equipments)),
      equipamentos: JSON.parse(JSON.stringify(props.equipamentos)),
      obj: props.obj,
      currentStage: 0,
      arrStage: [],
      selectedStage: '',
      sequenciaEtapa: [],
      etapaIntegrada: [],
      timeMachineDropDown: 0,
      timeMachineDados: [],
      timeMachineDadosAba: [],
      rotaProdutosAtivo: 0,
      isNew: false,
      formChanged: false,
      errorMessages: new Map(),
      etapaIntegradaValida: true,
      equipamentoErro: '',
      etapaErro: '',
      saving: false,
      showOnlyProductsOfSameArea: true
    };

    this.setProduct = this.setProduct.bind(this);
    this.addRoute = this.addRoute.bind(this);
    this.remRoute = this.remRoute.bind(this);
    this.saveRouteEquipment = this.saveRouteEquipment.bind(this);
    this.setArrStage = this.setArrStage.bind(this);
    this.remArrStage = this.remArrStage.bind(this);
    this.setNextStage = this.setNextStage.bind(this);
    this.setCurrentStage = this.setCurrentStage.bind(this);
    this.resetRoutes = this.resetRoutes.bind(this);
    this.saveRoutes = this.saveRoutes.bind(this);
    this._onDragToStage = this._onDragToStage.bind(this);
    this._onDragToEquipment = this._onDragToEquipment.bind(this);
    this._ondragendToTrash = this._ondragendToTrash.bind(this);
    this._onOverToTrash = this._onOverToTrash.bind(this);
    this._onDropToTrash = this._onDropToTrash.bind(this);
    this.onCheckEtapaIntegrada = this.onCheckEtapaIntegrada.bind(this);
    this._atualizaSequenciaEtapa = this._atualizaSequenciaEtapa.bind(this);
    this.updateEtapaIntegrada = this.updateEtapaIntegrada.bind(this);
    this.etapasIntegradasPorEtapa = this.etapasIntegradasPorEtapa.bind(this);
    this.indexEtapaIntegrada = this.indexEtapaIntegrada.bind(this);
    this.hasEtapaIntegradaEspecificaInvalida = this.hasEtapaIntegradaEspecificaInvalida.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.carregarProdutos = this.carregarProdutos.bind(this);
    this.getFilteredTimeMachineId = this.getFilteredTimeMachineId.bind(this);
    this.getSelectedTimeMachine = this.getSelectedTimeMachine.bind(this);
    this.customFunctionOnChange = this.customFunctionOnChange.bind(this);
    this.updateArrStage = this.updateArrStage.bind(this);
    this.alertaApagarAlteracoesRotas = this.alertaApagarAlteracoesRotas.bind(this);
    this.loadEtapas = this.loadEtapas.bind(this);
    this.validarSequenciaRotas = this.validarSequenciaRotas.bind(this);
    this.validarRotas = this.validarRotas.bind(this);
    this.onChangeTipoProducao = this.onChangeTipoProducao.bind(this);

    this.timeMachineRotasStore = this.props.store.timeMachineRotas;
    this.modalCommon = this.props.store.modalCommon;
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  mapearDicionarioRotas(rotas) {
    var dicionarioRotas = {};

    rotas.forEach(item => {
      if ((item.RotasProdutosId in dicionarioRotas)) {
        dicionarioRotas[item.RotasProdutosId].push(item);
      } else {
        dicionarioRotas[item.RotasProdutosId] = [item];
      }
    });

    return dicionarioRotas
  }

  getSelectedTimeMachine(valor) {
    return valor.ativo
  }

  filterTimeMachineArray(itens) {
    return itens.filter(this.getSelectedTimeMachine);
  }

  getRotas(rotasProdutosId) {
    if (rotasProdutosId === undefined) return [];

    return new Promise((resolve, reject) => {
      request(`${this.props.endpoints.TIMEMACHINE}/rotas/vigencias/${rotasProdutosId}`, {}, 'get')
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(data => {
          resolve(respostaRotas(data));
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  customFunctionOnChange(vigencias, vigenciaAtivaId) {
    let vigenciaAtiva = vigencias.filter(vigencia => vigencia.id == vigenciaAtivaId)[0];

    this.getRotas(vigenciaAtiva.destinoId).then(rotas => {
      let vigenciaSelecionadaId = vigencias.slice(-1)[0].id;
      this.props.store.timeMachineRotas.setTimeMachineSelected([vigenciaAtiva]);

      let modalParams = update(this.state, { obj: { product: { routes: { $set: rotas } } } });

      this.setState({
        obj: modalParams.obj,
        timeMachineDropDown: vigenciaSelecionadaId,
        currentStage: 0,
        rotaProdutosAtivo: vigenciaAtiva.destinoId
      }, () => {
        this.updateArrStage();
      })
    });
  }

  onChangeTipoProducao(ordem, value) {
    let { obj } = this.state;
    let ordemUnique = [...new Set(ordem)];

    ordemUnique.map(m => {
      var rota = obj.product.routes.filter(f => f.ordem == m);

      rota.map(r => r.TipoProducao = value);

      this.setState({
        obj: obj
      });
    });
  }

  updateArrStage() {
    let { obj, rotaProdutosAtivo } = this.state;
    let { session } = this.props;
    let arrStage = [];
    let stages = [];
    if (obj.product) {
      let routes = obj.product.routes.filter(route => route.fk_id_sessions == session.id && route.RotasProdutosId == rotaProdutosAtivo);

      if (routes.length > 0) {
        routes.map((route, i) => {
          if (stages[route.ordem]) return;
          if (route.fk_id_back == null || (routes[i - 1] && routes[i - 1].fk_id_front == route.fk_id_stages)) {
            arrStage.push(route);
          }
          stages[route.ordem] = true;
        });
      }
    }
    this.setState({
      arrStage: arrStage,
      selectedStage: arrStage.length ? arrStage[0].fk_id_stages : ''
    },
      this.loadEtapas
    )
  }

  getFilteredTimeMachineId(itens) {

    let item = this.filterTimeMachineArray(itens)
    if (!item.length > 0) {
      item = itens;
    }

    if (item[item.length - 1]) {

      let last_item = item[item.length - 1].id;

      this.setState({
        timeMachineDropDown: last_item
      });

      return last_item;
    } else {
      return 24081995;
    }

  }

  carregarTimeMachine() {
    return request(
      `${this.props.endpoints.TIMEMACHINE}/rotas/${this.props.store.modalStepOne.selectedRotasProdutos}`,
      { method: 'get' }
    )
      .then(response => {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then(data => {
        this.setState({
          timeMachineDados: data,
          timeMachineDadosAba: respostaTimeMachineAba(data)
        });

        this.exibirVigenciaAtiva();
      })
  }

  loadEtapas() {
    let etapas = this.state.arrStage.map(e => {
      return { etapa_id: e.fk_id_stages, ciclo: e.cycle }
    });

    let integrada = [];
    etapas.forEach(element => {
      integrada.push(this.etapasIntegradasPorEtapa(element.etapa_id, element.ciclo));
    });

    this.setState({
      sequenciaEtapa: etapas,
      etapaIntegrada: integrada
    });
  }

  componentDidMount() {
    if (!this.props.store.modalStepOne.selectedRotasProdutos) return;

    this.carregarTimeMachine().then(() => {
      this.updateArrStage();
    });
  }

  exibirVigenciaAtiva() {
    let vigencias = this.state.timeMachineDados;

    // vigencias é [undefined] ou um vetor vazio
    if (!vigencias || !vigencias.length) return;

    // encontra vigencia cuja data de fim é indeterminada (null)
    let vigenciaAtiva = vigencias.find((vigencia) => vigencia.dataFim == null);

    if (this.state.obj.product && this.props.store.modalStepOne.selectedRotasProdutos != vigenciaAtiva.RotasProdutosId) {
      // executa funcao de change para exibir dados correspondentes a rota da vigencia
      this.customFunctionOnChange(vigencias, vigenciaAtiva.id)
    }
  }

  etapasIntegradasPorEtapa(etapaId, ciclo) {
    var { obj } = this.state;
    var etapas = [], i = 0;
    obj.product.routes.forEach(element => {
      if (element.fk_id_stages == etapaId && element.cycle == ciclo && element.etapa_integrada) {
        element.etapa_integrada.forEach(e => {
          etapas.push(e);
        });
      }
    });
    return etapas;
  }

  _atualizaSequenciaEtapa() {
    let etapas = this.state.arrStage.map(e => {
      return { etapa_id: e.fk_id_stages, ciclo: e.cycle }
    });

    this.setState({
      sequenciaEtapa: etapas,
    });
  }

  _onDragToStage(event) {
    this.refs.trash.className = 'trash';
    event.dataTransfer.setData('stageRM', event.target.dataset.idx);
  }

  _onDragToEquipment(event) {
    this.refs.trash.className = 'trash';
    event.dataTransfer.setData('equipmentRM', event.target.dataset.idx);
  }

  _ondragendToTrash(event) {
    this.refs.trash.className = 'trash hidden';
  }

  _onOverToTrash(event) {
    event.preventDefault();
  }

  _onDropToTrash(event) {
    let idxStage = event.dataTransfer.getData('stageRM');
    let idxEquipment = event.dataTransfer.getData('equipmentRM');

    if (idxEquipment !== undefined && idxEquipment != '') {
      this.remRoute(idxEquipment);
    }

    if (idxStage !== undefined && idxStage != '') {
      this.remArrStage(idxStage);
    }

    this.refs.trash.className = 'trash hidden';
  }

  setProduct(opt) {
    let obj = {};
    obj.product = this.props.products.find((p) => p.id == opt.value);
    obj.stages = this.props.stages;
    this.props.store.modalStepOne.setProductStageToShow(obj);
    this.setState({
      obj: obj,
      isNew: true
    })
  }

  addRoute(equipment) {
    let { equipments, arrStage, currentStage, obj } = this.state;
    let equipmentSearch = equipments.filter(f => f.id == equipment)[0];

    let route = {
      oee: (equipmentSearch.meta_oee_effetive_sem_setup / 100) || 1,
      oee_padrao: equipmentSearch.meta_oee_effetive_sem_setup ? true : false,
      proc_time: 0,
      wait_time: equipmentSearch.wait_time || 0,
      wait_time_default: equipmentSearch.wait_time ? true : false,
      fk_id_equipments: parseInt(equipment),
      master: !obj.product.routes.find(route => route.fk_id_stages == parseInt(arrStage[currentStage].fk_id_stages)) ? true : false,
      fk_id_sessions: parseInt(arrStage[currentStage].fk_id_sessions),
      fk_id_stages: parseInt(arrStage[currentStage].fk_id_stages),
      fk_id_products: parseInt(obj.product.id),
      fk_id_back: arrStage[currentStage].fk_id_back,
      cycle: parseInt(arrStage[currentStage].cycle),
      quantity: 0,
      is_batch: equipmentSearch.is_batch,
      com_equipamento: obj.stages.find(s => s.id == parseInt(arrStage[currentStage].fk_id_stages)).ComEquipamento,
      ordem: parseInt(arrStage[currentStage].ordem),
      etapa_integrada: [],
      parallel: arrStage[currentStage].parallel,
      parallel_extended: arrStage[currentStage].parallel_extended,
      EtapaSimultaneo: arrStage[currentStage].EtapaSimultaneo,
      MasterType: "",
      QuantityConversion: arrStage[currentStage].QuantityConversion || 1.0,
      EtapaAtual: arrStage[currentStage].EtapaAtual,
      EtapaNaoLinear: arrStage[currentStage].EtapaNaoLinear,
      EtapaOpcional: arrStage[currentStage].EtapaOpcional,
      TipoProducao: arrStage[currentStage].TipoProducao
    };

    obj.product.routes.push(route);
    arrStage[currentStage] = route;
    this.setState({
      obj: obj,
      arrStage: arrStage,
      formChanged: true
    });
  }

  remRoute(idx) {
    let { obj } = this.state;

    obj.product.routes.map(route => {
      if (route.fk_id_equipments == idx) {
        route.fk_id_equipments = null;
      }
    });

    this.setState({
      obj,
      formChanged: true
    });
  }

  saveRouteEquipment(stage, equipment, cycle, type, value) {
    let routes = this.state.obj.product.routes.filter(route => route.fk_id_sessions == this.props.session.id && route.fk_id_stages == stage && route.cycle == cycle);

    if (type == this.props.t('equipment.main')) {
      routes.map(route => {
        route.MasterType = null;
        if (route.fk_id_equipments == equipment) {
          // de principal ele deve virar alternativo
          route.master = false;
          route.MasterType = null;
        }
      });
    } else if (type == this.props.t('equipment.preferential')) {
      routes.map(route => {
        route.master = false;
        if (route.fk_id_equipments == equipment) {
          //de preferencial ele deve virar principal
          route.master = true;
          route.MasterType = null;
        }
      });
    } else if (type == this.props.t('equipment.alternative')) {
      routes.map(route => {
        if (route.fk_id_equipments == equipment) {
          //de alternativo ele deve virar preferencial
          route.master = false;
          route.MasterType = 'p';
        }
      });
    } else if (type == 'wait_time_default' && value == true) {
      let _equipment = this.state.equipamentos.find(e => e.id == equipment);
      routes.map(route => {
        if (route.fk_id_equipments != equipment) return route;
        route.wait_time = _equipment.wait_time;
        route[type] = value;
      });
    } else if (type === 'oee_padrao' && value === true) {
      let _equipment = this.state.equipamentos.find(e => e.id == equipment);
      routes.map(route => {
        if (route.fk_id_equipments != equipment) return route;
        route.oee = _equipment.meta_oee_effetive_sem_setup && (_equipment.meta_oee_effetive_sem_setup / 100);
        route.oee_padrao = value;
      });
    } else if (type == 'QuantityConversion') {
      routes.map(route => {
        if (route.fk_id_equipments != equipment) return route;
        route.QuantityConversion = parseFloat(value);
      });
    } else {
      routes.map(route => {
        if (route.fk_id_equipments != equipment) return route;
        route[type] = value;
      });
    }

    let invalidForm = (value === '0' || value === '');

    this.setState((state, props) => ({
      obj: state.obj
    }));

    let newMessage = [this.props.t("stepOne.messages.requiredFields"), this.props.t("stepOne.messages.checkAllStages"), 'warning'];

    this.setState(update(this.state, {
      errorMessages: { blank: { $set: invalidForm ? newMessage : null } },
      formChanged: { $set: true }
    }));
  }

  resetRoutes() {
    this.state.obj.product.routes = [];
    this.setState({
      currentStage: 0,
      arrStage: [],
      selectedStage: '',
      formChanged: true
    });
  }

  AgruparRotasPorEtapa(rotas) {
    var etapas = {};

    for (let rota of rotas) {
      if (etapas[rota.fk_id_stages]) {
        etapas[rota.fk_id_stages].push(rota);
      } else {
        etapas[rota.fk_id_stages] = [rota];
      }
    }

    return etapas;
  }

  ProximaEtapa(etapa, etapaCiclo, rotas) {
    var rota = etapa[0];

    if (!rota.fk_id_front) return null;

    var etapaId = rota.fk_id_front;

    var ciclo = etapaCiclo[etapaId] || 1;

    etapaCiclo[etapaId] = (etapaCiclo[etapaId] || 1) + 1;

    return (!!rotas[etapaId]) ? rotas[etapaId].filter(r => r.cycle == ciclo) : null;
  }

  construirSequenciaRotas(rotas) {
    var sequencia = [];
    var etapa = null;
    var rota = null;

    var etapaCiclo = {};

    rotas = this.AgruparRotasPorEtapa(rotas);

    for (let etapaId in rotas) {
      let rota = rotas[etapaId];
      let rot = rota.filter(r => !r.fk_id_back);

      if (rot.length > 0) {
        etapa = rot;
        break;
      }
    }

    if (etapa !== null) {

      sequencia.push(etapa);

      rota = etapa[0];

      etapaCiclo[rota.fk_id_stages] = 2;

      // Construi a sequencia

      while (rota && !!rota.fk_id_front) {
        etapa = this.ProximaEtapa(etapa, etapaCiclo, rotas);

        if (etapa == null || etapa.length < 0) break;

        sequencia.push(etapa);

        rota = etapa[0];
      }
    }

    return sequencia;
  }

  etapaAnterior(etapa_atual, ciclo_atual, etapaId, ciclo, arr) {
    var index;
    arr.forEach((element, i) => {
      if (element.etapa_id == etapaId && element.ciclo == ciclo) {
        index = i;
      }
    });

    for (let i = 0; i < index; i++) {
      if (!(arr[i].etapa_id == etapa_atual && arr[i].ciclo == ciclo_atual)) {
        return { etapa_id: arr[i].etapa_id, ciclo: arr[i].ciclo }
      }
    }

    return { etapa_id: null, ciclo: null };
  }

  etapaProximo(etapaId, ciclo, arr) {
    var index;
    arr.forEach((element, i) => {
      if (element.etapa_id == etapaId && element.ciclo == ciclo) {
        index = i;
      }
    });

    for (let i = index; i < arr.length; i++) {
      if (!(arr[i].etapa_id == etapaId && arr[i].ciclo == ciclo)) {
        return { etapa_id: arr[i].etapa_id, ciclo: arr[i].ciclo }
      }
    }

    return { etapa_id: null, ciclo: null };
  }
  /**
   * Verifica se existe alguma etapa integrada que esteja com sequência inválida.
   * 
   * @param {*} etapaId 
   * @param {*} ciclo 
   */
  hasEtapaIntegradaEspecificaInvalida(etapaId, ciclo, etapaIntegradaId) {
    let erro = false;
    let { etapaIntegrada } = this.state;
    let indexEtapaAtual = this.indexEtapaIntegrada(etapaId, ciclo);

    let etapa_integrada = [];
    etapa_integrada.push(etapaIntegrada[this.indexEtapaIntegrada(etapaId, ciclo)]);

    let etapas = _.flattenDeep(etapa_integrada);
    etapas = _.sortBy(etapas, 'ordem');

    let mapaEtapas = _.uniq(_.map(etapas, 'etapa_atual'));
    for (let i = 0; i < mapaEtapas.length; i++) {
      let estapasAtuais = etapas.filter(atual => (atual.etapa_atual == mapaEtapas[i]));
      let mapaEquipamentos = _.uniq(_.map(estapasAtuais, 'equipamento_id'));

      for (let j = 0; j < mapaEquipamentos.length; j++) {
        if (this.ExisteEquipamentoIntegradoParaEstaRota(etapaIntegradaId)) {
          let etapasEquipamento = estapasAtuais.filter(equipamento =>
            (equipamento.equipamento_id == mapaEquipamentos[j] && equipamento.etapa_id == etapaIntegradaId));

          if (etapasEquipamento.length == 1
            && (etapasEquipamento[0].ordem != indexEtapaAtual + 1
              && etapasEquipamento[0].ordem != indexEtapaAtual - 1)) {
            erro = true;
            this.definirMensagemErroEtapaIntegradaSequenciaInvalida(etapasEquipamento, 0);
            break;
          } else {
            erro = this.hasEtapaIntegradaComSequenciaInvalida(etapasEquipamento, indexEtapaAtual);
            if (erro) {
              break;
            }
          }
        }
      }
    }

    return erro;
  }

  /**
   * Verifica se dentre as etapas integradas informadas existe alguma que está "quebrando"a sequência definida pela ordem.
   * 
   * @param {*} etapasEquipamento 
   * @param {*} indexEtapaAtual 
   */
  hasEtapaIntegradaComSequenciaInvalida(etapasEquipamento, indexEtapaAtual) {
    let sequenciamentoRotaInvalido = false;
    let indiceUltimoElemento = etapasEquipamento.length - 1;

    for (var i = 0; i < etapasEquipamento.length; i++) {
      let indiceProximoElemento = i + 1;
      let proximaOrdem = etapasEquipamento[i].ordem + 1;

      let etapaComSequenciaInvalida = i < indiceUltimoElemento
        && (indexEtapaAtual != proximaOrdem
          && etapasEquipamento[indiceProximoElemento].ordem != proximaOrdem)

      let etapaAtualComSequenciaInvalida = indexEtapaAtual == i
        && (etapasEquipamento[i].ordem != indexEtapaAtual + 1
          && etapasEquipamento[i].ordem != indexEtapaAtual - 1);

      if (etapaComSequenciaInvalida || etapaAtualComSequenciaInvalida) {
        this.definirMensagemErroEtapaIntegradaSequenciaInvalida(etapasEquipamento, i);
        sequenciamentoRotaInvalido = true;
        break;
      }
    }

    return sequenciamentoRotaInvalido;
  }

  /**
   * Define os valores para as mensagens de erro referentes a etapas integradas informadas com sequenciamento inválido.
   * 
   * @param {*} etapasEquipamento 
   * @param {*} index 
   */
  definirMensagemErroEtapaIntegradaSequenciaInvalida(etapasEquipamento, index) {
    this.setState((state, props) => ({
      etapaErro: props.stages.filter(e => e.id == etapasEquipamento[index].etapa_id)[0].name,
      equipamentoErro: props.equipments.filter(e => e.id == etapasEquipamento[index].equipamento_id)[0].name
    }));
  }

  validarSequenciaRotas() {
    let { sequenciaEtapa, obj } = this.state;
    let erro = false;
    var sessionId = localStorage.getItem('sessionId');
    return new Promise((resolve, reject) => {
      this.updateEtapaIntegrada().then(() => {
        var sequenciaRotas = this.construirSequenciaRotas(obj.product.routes.filter(f => f.fk_id_sessions == sessionId));

        for (let i = 0; i < sequenciaRotas.length; i++) {
          sequenciaRotas[i].map(e => {
            e['CicloAnterior'] = this.resolveCiclo(sequenciaRotas[i - 1]);
            e['CicloProximo'] = this.resolveCiclo(sequenciaRotas[i + 1]);
            e['ordem'] = i + 1;
            return e;
          });
        }

        var rotasFlatten = _.flattenDeep(sequenciaRotas);

        var rotas = [];

        rotasFlatten.forEach(element => {
          if (element.etapa_integrada && element.etapa_integrada.length > 0) {
            let etapas = element.etapa_integrada.sort((a, b) => {
              if (a.ordem > b.ordem) {
                return 1;
              }
              if (a.ordem < b.ordem) {
                return -1;
              }
              return 0;
            });

            element.etapa_integrada[0].ultimo = false;
            element.etapa_integrada[0].primeiro = false;

            let index;
            for (let i = 0; i < sequenciaEtapa.length; i++) {
              if (sequenciaEtapa[i].etapa_id == element.etapa_integrada[0].etapa_atual && sequenciaEtapa[i].ciclo == element.etapa_integrada[0].ciclo_atual) {
                index = i;
                break;
              }
            }
            var i = 0;
            for (i = 0; i < index; i++) {
              if (sequenciaEtapa[i].etapa_id == element.etapa_integrada[0].etapa_id && sequenciaEtapa[i].ciclo == element.etapa_integrada[0].ciclo) {
                element.etapa_integrada[0].primeiro = true;
              }
            }

            for (i = index; i < sequenciaEtapa.length; i++) {
              if (sequenciaEtapa[i].etapa_id == element.etapa_integrada[0].etapa_id && sequenciaEtapa[i].ciclo == element.etapa_integrada[0].ciclo) {
                element.etapa_integrada[0].ultimo = true;
              }
            }

            if (!this.hasEtapaIntegradaEspecificaInvalida(etapas[0].etapa_atual, etapas[0].ciclo_atual, element.etapa_integrada[0].etapa_id)) {
              var etapaAnterior = this.etapaAnterior(etapas[0].etapa_atual, etapas[0].ciclo_atual, etapas[0].etapa_id, etapas[0].ciclo, sequenciaEtapa);
              var etapaProximo = this.etapaProximo(etapas[etapas.length - 1].etapa_id, etapas[etapas.length - 1].ciclo, sequenciaEtapa);

              element['EtapaAnteriorReal'] = etapaAnterior.etapa_id;
              element['CicloAnteriorReal'] = etapaAnterior.ciclo;
              element['EtapaProximaReal'] = etapaProximo.etapa_id;
              element['CicloProximoReal'] = etapaProximo.ciclo;
            } else {
              erro = true;
            }

            rotas.push(element);
          } else {
            element['EtapaAnteriorReal'] = element.fk_id_back;
            element['CicloAnteriorReal'] = element.CicloAnterior;
            element['EtapaProximaReal'] = element.fk_id_front;
            element['CicloProximoReal'] = element.CicloProximo;
            rotas.push(element);
          }
        });

        let alertParams = [`${this.props.t("stepOne.messages.integratedStages1")} ${this.state.equipamentoErro} ${this.props.t("stepOne.messages.integratedStages2")} ${this.state.etapaErro}.`, this.props.t("stepOne.messages.checkStage"), 'error'];

        if (erro) {
          this.props.showAlert(alertParams[0], alertParams[1], alertParams[2]);
          $('#insertButton').removeClass(defaultStyle.button_ghost_disable);
          $('#insertButton').attr('disabled', 'false');
        }

        this.setState(update(this.state, {
          errorMessages: { sequentialStages: { $set: erro ? alertParams : null } },
          etapaIntegradaValida: { $set: !erro }
        }));

        resolve({ erro: erro, rotas: rotas });
      }).catch((err) => {
        reject(err);
      });
    })
  }

  ExisteEquipamentoIntegradoParaEstaRota(etapaIntegradaId) {
    let { obj } = this.state;

    let indexEtapaIntegradaNaRota = obj.product.routes.findIndex(e => e.fk_id_stages == etapaIntegradaId);

    return indexEtapaIntegradaNaRota >= 0;
  }

  resolveCiclo(objeto) {
    if (objeto) {
      return (objeto[0] && objeto[0].cycle) || null;
    }
    return null;
  }

  resolveData(arr, value) {
    return arr ? arr[value] : null;
  }

  carregarProdutos() {
    requestAll([
      {
        url: this.props.endpoints.PRODUTO + '?incluirRotas=true',
        modelo: 'products',
        modificador: respostaProdutos,
        acao: true
      }
    ], this);
  }

  validarRotas() {
    let { obj, arrStage } = this.state;
    let validacoesRota = { oeePadraoValido: true, rotasValidas: true };
    let errorLocation = { stage: '', equipment: '', order: '', error: '' };

    var stageWithoutEquip = false;
    var lastStage = arrStage.at(-1);
    var lastStageKey = lastStage.fk_id_stages + '_' + lastStage.ordem;

    arrStage.map(m => {
      if (m.ordem > obj.product.routes.length && m.com_equipamento) {
        stageWithoutEquip = true;
      }
    });

    if (stageWithoutEquip) {
      validacoesRota.rotasValidas = false;
      errorLocation = { stage: '', equipment: '', order: '', error: 'Stage with mandatory equipment' };
    }

    obj.product.routes.map(r => {
      const equipment = this.props.equipamentos.find(equip => equip.id === r.fk_id_equipments);
      const stage = this.props.stages.find(s => s.id == r.fk_id_stages);
      const routesStage = obj.product.routes.filter(f => f.fk_id_stages == r.fk_id_stages && f.ordem == r.ordem);
      const routesNoLinear = obj.product.routes.filter(f => f.EtapaNaoLinear == true);

      var nextStageOrder = r.ordem + 1;
      var previousStageOrder = r.ordem - 1;
      var currentStageKey = r.fk_id_stages + '_' + r.ordem;

      var equipExists = false;
      var isNoLinearZone = r.EtapaNaoLinear;
      var validNoLinearZone = false;
      var validExtendedStage = true;

      if (lastStageKey === currentStageKey && r.parallel_extended) {
        validExtendedStage = false;
      }

      routesStage.map(rs => {
        if (rs.fk_id_stages == r.fk_id_stages && rs.fk_id_equipments != null) {
          equipExists = true;
        }
      });

      routesNoLinear.map(rl => {
        if (isNoLinearZone && (rl.ordem == nextStageOrder || rl.ordem == previousStageOrder)) {
          validNoLinearZone = true;
        }
      });

      if (!validExtendedStage) {
        validacoesRota.rotasValidas = false;
        errorLocation = { stage: stage.name, equipment: '', order: r.ordem, error: 'Invalid Extended Stage' };
      }

      if (isNoLinearZone && !validNoLinearZone) {
        validacoesRota.rotasValidas = false;
        errorLocation = { stage: stage.name, equipment: '', order: r.ordem, error: 'Invalid No Linear Zone' };
      }

      if (equipment != null && r.oee <= 0) {
        validacoesRota.oeePadraoValido = false;
        errorLocation = { stage: stage.name, equipment: equipment.name, order: r.ordem, error: 'Invalid oee' };
      }

      if (r.com_equipamento) {
        if (!equipExists) {
          validacoesRota.rotasValidas = false;
          errorLocation = { stage: stage.name, equipment: '', order: r.ordem, error: 'Stage without equipment' };
        }

        if (equipment != null && !r.proc_time || r.proc_time <= 0) {
          validacoesRota.rotasValidas = false;
          errorLocation = { stage: stage.name, equipment: equipment.name, order: r.ordem, error: 'Invalid cicle time' };
        }

        if (equipment != null && equipment.is_batch === false) {
          if (!r.quantity || r.quantity <= 0) {
            validacoesRota.rotasValidas = false;
            errorLocation = { stage: stage.name, equipment: equipment.name, order: r.ordem, error: 'Invalid quantity' };
          };
        }

        r.wait_time = r.wait_time ? r.wait_time : 0;
      }
    });

    if (_.size(obj.product.routes) < this.state.arrStage.length) {
      validacoesRota.rotasValidas = false;
      const stage = _.difference(this.state.arrStage, obj.product.routes)[0];
      errorLocation = { stage: stage.name, equipment: null, error: "Stage without equipment" };
    }

    if (!validacoesRota.rotasValidas || !validacoesRota.oeePadraoValido) {
      let msgParams = { message: '', title: '' };

      if (!!errorLocation.error.length) {
        switch (errorLocation.error) {
          case 'Stage with mandatory equipment':
            msgParams.message = this.props.t("stepOne.messages.stageMandatoryEquipment");
            msgParams.title = this.props.t("stepOne.messages.checkAllStages");
            break;
          case 'Invalid Extended Stage':
            msgParams.message = this.props.t("stepOne.messages.invalidExtendedStageMsg");
            msgParams.title = `${this.props.t("stepOne.messages.stage")} ${errorLocation.order} (${errorLocation.stage}) ${this.props.t("stepOne.messages.invalidExtendedStageTitle")}`;
            break;
          case 'Invalid No Linear Zone':
            msgParams.message = this.props.t("stepOne.messages.invalidNoLinearZoneMsg");
            msgParams.title = `${this.props.t("stepOne.messages.stage")} ${errorLocation.order} (${errorLocation.stage}) ${this.props.t("stepOne.messages.invalidNoLinearZoneTitle")}`;
            break;
          case 'Invalid quantity':
            msgParams.message = `${this.props.t("stepOne.messages.invalidQuantityMsg1")} "${errorLocation.equipment}" ${this.props.t("stepOne.messages.invalidQuantityMsg2")}`;
            msgParams.title = `${this.props.t("stepOne.messages.checkStage")} ${errorLocation.order}: ${errorLocation.stage}`;
            break;
          case 'Invalid cicle time':
            msgParams.message = `${this.props.t("stepOne.messages.invalidCycleTimeMsg1")} "${errorLocation.equipment}" ${this.props.t("stepOne.messages.invalidCycleTimeMsg2")}`;
            msgParams.title = `${this.props.t("stepOne.messages.checkStage")} ${errorLocation.order}: ${errorLocation.stage}`;
            break;
          case 'Invalid oee':
            msgParams.message = `${this.props.t("stepOne.messages.invalidOeeMsg")} ${errorLocation.equipment}`;
            msgParams.title = `${this.props.t("stepOne.messages.checkStage")} ${errorLocation.order}: ${errorLocation.stage}`;
            break;
          case 'Stage without equipment':
            msgParams.message = `${this.props.t("stepOne.messages.stage")} ${errorLocation.order} (${errorLocation.stage}) ${this.props.t("stepOne.messages.stageWithoutEquipmentMsg")}`;
            msgParams.title = `${this.props.t("stepOne.messages.checkStage")} ${errorLocation.order}: ${errorLocation.stage}`;
            break;
          default:
            msgParams.message = this.props.t("stepOne.messages.requiredFields");
            msgParams.title = this.props.t("stepOne.messages.checkAllStages");
            break;
        }

        this.props.showAlert(msgParams.message, msgParams.title, 'warning');

        this.setState(update(this.state, {
          errorMessages: { blank: { $set: [msgParams.message, msgParams.title, 'warning'] } }
        }));
      }
    }

    return validacoesRota.rotasValidas && validacoesRota.oeePadraoValido;
  }

  saveRoutes(event, deleteHoldingTime, alteracaoVigencia = false) {
    let { session, endpoints, handleModal } = this.props;
    let { arrStage } = this.state;

    this.updateEtapaIntegrada().then(() => {

      if (!this.validarRotas()) return;

      $('#insertButton').attr('disabled', 'true');
      $('#insertButton').addClass(defaultStyle.button_ghost_disable);
      $('#insertButton').addClass(defaultStyle.round);

      arrStage.map((sta, i) => sta.fk_id_front = (++i) < arrStage.length ? arrStage[i].fk_id_stages : null);

      let routes = this.state.obj.product.routes.filter(route => route.fk_id_sessions == session.id);
      routes.map(rt => rt.fk_id_front = arrStage.filter(st => st.fk_id_stages == rt.fk_id_stages && st.cycle == rt.cycle)[0].fk_id_front);

      this.validarSequenciaRotas().then(({ erro, rotas }) => {
        if (!erro) {
          var method = 'post'
          var querysring = ''

          if (this.state.rotaProdutosAtivo) {
            method = 'put'
            querysring = `/${this.state.rotaProdutosAtivo}/${deleteHoldingTime ? deleteHoldingTime : false}`
          }

          if (!this.state.saving) {
            this.setState({ saving: true })

            let rotasFilter = rotas.filter(f => f.fk_id_equipments != null || f.com_equipamento == false);

            request(endpoints.ROTA + querysring, {
              method: method,
              body: JSON.stringify(requisicaoRotas(rotasFilter)),
            })
              .then(response => {
                if (response.status == 200) {

                  if (!alteracaoVigencia) {
                    handleModal('routes');
                    this.carregarProdutos();
                  }
                  alertBox(this.props.t("stepOne.messages.saveSuccess"), this.props.t("stepOne.messages.routes"), 'success');
                } else {
                  alertBox(this.props.t("stepOne.messages.saveError"), this.props.t("stepOne.messages.routes"), 'error');
                }
                this.setState({ saving: false })
                $('#insertButton').removeClass(defaultStyle.button_ghost_disable);
                $('#insertButton').attr('disabled', 'false');
              })
              .catch(() => {
                alertBox(this.props.t("stepOne.messages.saveError"), this.props.t("stepOne.messages.routes"), 'error');
                this.setState({ saving: false })
                $('#insertButton').removeClass(defaultStyle.button_ghost_disable);
                $('#insertButton').attr('disabled', 'false');
              });
          }
        }
      });
    });
  }

  setNextStage() {
    this.setState({
      currentStage: this.state.arrStage.length,
      selectedStage: ''
    });
  }

  setCurrentStage(idx) {
    let stage = this.state.arrStage[idx].fk_id_stages;
    this.updateEtapaIntegrada().then(() => {
      this.setState({
        currentStage: idx,
        selectedStage: stage
      });
    });
  }

  removeStage(arrStage, idx) {
    /*
     Eu garanto que tenho pelo menos 2 elementos.
     por que se for só um é executado resetRoutes().
    */
    idx = parseInt(idx);

    let el = arrStage[idx];

    if (idx >= arrStage.length - 1) {
      arrStage[idx - 1].fk_id_front = el.fk_id_front;
    } else if (idx <= 0) {
      arrStage[idx + 1].fk_id_back = el.fk_id_back;
    } else {
      arrStage[idx + 1].fk_id_back = el.fk_id_back;
      arrStage[idx - 1].fk_id_front = el.fk_id_front;
    }

    arrStage.splice(idx, 1);

    return arrStage;
  }

  remArrStage(indexEtapaRemovida) {
    let {
      obj,
      arrStage,
    } = this.state;
    let etapas = Object.assign([], arrStage);
    let quantidadeEtapas = etapas.length;
    let etapaParaExcluir = Object.assign({}, etapas[indexEtapaRemovida]);
    let proximaEtapa = etapaParaExcluir.ordem < quantidadeEtapas ? Object.assign({}, etapas[indexEtapaRemovida + 1]) : null;
    let etapaAnterior = etapaParaExcluir.ordem > 1 ? Object.assign({}, etapas[indexEtapaRemovida - 1]) : null;
    let novasRotasProduto = [];
    let rotasProduto = obj.product.routes;
    if (etapas.length === 1) {
      this.resetRoutes();
    }
    else {
      let ordemEtapa = 1;
      etapas.forEach(etapa => {
        // Se a etapa atual for a etapa que será removida, não é necessário alterar nada e retorna.
        if (etapa.ordem == etapaParaExcluir.ordem) return;
        rotasProduto.filter(rp => rp.ordem == etapa.ordem).map(rota => {
          let rotaProduto = Object.assign({}, rota);
          // 1º - Quando a exclusão for no início da lista (não existe etapa anterior):
          // Seta o fk_id_back do próximo item com valor [null]
          if (!etapaAnterior && proximaEtapa && rotaProduto.ordem == proximaEtapa.ordem) {
            rotaProduto.fk_id_back = null;
          }
          // 2º - Quando a exclusão for no meio da lista (existe etapa anterior e próxima etapa):
          // Ligar a etapa anterior à próxima etapa em duas etapas:
          //   [1] - Seta fk_id_front da etapa anterior com o id da próxima etapa;
          //   [2] - Seta fk_id_back da próxima etapa com o id da etapa enterior.
          if (etapaAnterior && proximaEtapa) {
            if (rotaProduto.ordem == etapaAnterior.ordem) {
              rotaProduto.fk_id_front = proximaEtapa.fk_id_stages;
            }
            else if (rotaProduto.ordem == proximaEtapa.ordem) {
              rotaProduto.fk_id_back = etapaAnterior.fk_id_stages;
            }
          }
          // 3º - Quando a exclusão for no final da lista (não existe próxima etapa):
          // Seta o fk_id_front da etapa anterior à etapa excluída com valor [null]
          if (etapaAnterior && !proximaEtapa && rotaProduto.ordem == etapaAnterior.ordem) {
            rotaProduto.fk_id_front = null;
          }
          // Seta a nova ordem das etapas
          rotaProduto.ordem = ordemEtapa;
          // Remove as etapas integradas à etapa excluída
          if (rotaProduto.etapa_integrada) {
            rotaProduto.etapa_integrada = rotaProduto.etapa_integrada.filter(etapa => (
              etapa.etapa_id != etapaParaExcluir.fk_id_stages
              && etapa.ciclo != etapaParaExcluir.cycle
            ))
          }
          // Adiciona a nova rota, recem configurada, no vetor de novas rotas
          // na ordem em que vão sendo criadas
          novasRotasProduto = [...novasRotasProduto, rotaProduto];
        }); // fim rotasProduto.map
        // Incrementa a ordem da etapa
        ordemEtapa++;
      }); // fim etapas.forEach

      obj.product.routes = novasRotasProduto.map(rota => {
        if (rota.fk_id_stages == etapaParaExcluir.fk_id_stages
          && rota.cycle > etapaParaExcluir.cycle)
          rota.cycle = Math.max(rota.cycle - 1, 1);

        return rota;
      });
      // Remove a etapa excluída da lista de etapas (arrStage)
      etapas.splice(indexEtapaRemovida, 1);
      etapas = etapas.map((etapa, index, listaEtapas) => {
        let proximaEtapaId = listaEtapas[index + 1] ? listaEtapas[index + 1].fk_id_stages : null;
        let etapaAnteriorId = listaEtapas[index - 1] ? listaEtapas[index - 1].fk_id_stages : null;
        etapa.ordem = index + 1;
        etapa.fk_id_front = proximaEtapaId;
        etapa.fk_id_back = etapaAnteriorId;
        return etapa;
      });
      etapas = etapas.map(st => {
        if (st.fk_id_stages == etapaParaExcluir.fk_id_stages
          && st.cycle > etapaParaExcluir.cycle)
          st.cycle = Math.max(st.cycle - 1, 1);

        return st;
      });
      this.setState({
        obj,
        currentStage: 0,
        arrStage: etapas,
        selectedStage: etapas[0].fk_id_stages,
        formChanged: true
      });
    } // fim else
    this.validarSequenciaRotas();
  }

  setArrStage(event) {
    let value = event.target.value;
    let { arrStage, currentStage, obj } = this.state;
    let etapaAtual = this.props.session.stages.find(f => f.id == parseInt(value));

    if (arrStage[currentStage]) {
      arrStage.map((st, i) => {
        if (i == currentStage) {
          st.cycle = arrStage.filter(sta => sta.fk_id_stages == parseInt(value)).length + 1;
          st.oee = 1;
          st.wait_time = 0;
          st.proc_time = 0;
          st.quantity = 0;
          st.QuantityConversion = 0
          return st.fk_id_stages = parseInt(value);
        }
        return st.fk_id_stages;
      });
    } else {
      let com_equipamento = obj.stages.find(s => s.id == parseInt(value)).ComEquipamento;

      arrStage.push({
        fk_id_sessions: parseInt(this.props.session.id),
        fk_id_stages: parseInt(value),
        fk_id_back: arrStage[arrStage.length - 1] ? parseInt(arrStage[arrStage.length - 1].fk_id_stages) : null,
        cycle: arrStage.filter(sta => sta.fk_id_stages == value).length + 1,
        ordem: arrStage.length > 0 ? parseInt(arrStage[currentStage - 1].ordem) + 1 : 1,
        com_equipamento: com_equipamento,
        EtapaAtual: etapaAtual,
        TipoProducao: null
      });

      if (!com_equipamento) {
        let route = {
          oee: 1,
          proc_time: 0,
          wait_time: 0,
          fk_id_equipments: null,
          master: false,
          fk_id_sessions: parseInt(arrStage[currentStage].fk_id_sessions),
          fk_id_stages: parseInt(arrStage[currentStage].fk_id_stages),
          fk_id_products: parseInt(obj.product.id),
          fk_id_back: arrStage[currentStage].fk_id_back,
          cycle: parseInt(arrStage[currentStage].cycle),
          quantity: 0,
          is_batch: false,
          com_equipamento: com_equipamento,
          ordem: parseInt(arrStage[currentStage - 1]) ? parseInt(arrStage[currentStage - 1].ordem) + 1 : 1,
          etapa_integrada: [],
          QuantityConversion: 1.0,
          EtapaAtual: etapaAtual,
          TipoProducao: null
        };
        obj.product.routes.push(route);

        this.setState({
          obj: obj,
          formChanged: true
        });
      }
    }

    let etapas = this.construirEtapas();

    let integrada = [];
    etapas.forEach(element => {
      integrada.push(this.etapasIntegradasPorEtapa(element.etapa_id, element.ciclo));
    });

    this.props.store.modalStepOne.setProductStage(obj);
    this.setState({
      selectedStage: value,
      sequenciaEtapa: etapas,
      etapaIntegrada: integrada,
      formChanged: true
    });
  }

  construirEtapas(etapas) {
    let novasEtapas = Object.assign([], (etapas ? etapas : this.state.arrStage));

    return novasEtapas.map(e => {
      return {
        etapa_id: e.fk_id_stages,
        ciclo: e.cycle
      };
    });
  }

  indexEtapaIntegrada(etapaId, ciclo) {
    var { sequenciaEtapa } = this.state;

    var index;
    for (let i = 0; i < sequenciaEtapa.length; i++) {

      if (sequenciaEtapa[i].etapa_id == etapaId && sequenciaEtapa[i].ciclo == ciclo) {
        index = i;
      }
    }
    return index;
  }

  onCheckEtapaIntegrada(etapaAtual, cicloAtual, etapaId, equipamentoId, ciclo, ordem, master, value) {
    let { etapaIntegrada, obj } = this.state;
    var etapa_integrada;
    var index = this.indexEtapaIntegrada(etapaAtual, cicloAtual);

    var rotas = obj.product.routes;
    var eqMaster = rotas.filter(rt => rt.fk_id_stages == etapaId && rt.cycle == ciclo && rt.master);
    if ((!(eqMaster.length > 0) || !master)) {

      if (value) {
        etapa_integrada = [{
          etapa_atual: etapaAtual,
          ciclo_atual: cicloAtual,
          etapa_id: etapaId,
          equipamento_id: equipamentoId,
          ciclo: ciclo,
          ordem: ordem,
          primeiro: false,
          ultimo: false
        }]

        this.setState(update(this.state, {
          etapaIntegrada: {
            [index]: { $push: etapa_integrada }
          },
          errorMessages: { alreadyHaveMainEquipment: { $set: null } },
          formChanged: { $set: true },
          etapaIntegradaValida: { $set: true }
        }), this.validarSequenciaRotas);

      } else {
        var indexEtapa;
        for (var i = 0; i < etapaIntegrada[index].length; i++) {
          if (etapaIntegrada[index][i].etapa_id == etapaId && etapaIntegrada[index][i].equipamento_id == equipamentoId && etapaIntegrada[index][i].ciclo == ciclo) {
            indexEtapa = i;
          }
        }
        this.setState(update(this.state, {
          etapaIntegrada: {
            [index]: { $splice: [[indexEtapa, 1]] }
          },
          errorMessages: { alreadyHaveMainEquipment: { $set: null } },
          formChanged: { $set: true },
          etapaIntegradaValida: { $set: true }

        }), this.validarSequenciaRotas);
      }
    } else {
      var etapaErro = this.props.stages.filter(e => e.id == eqMaster[0].fk_id_stages)[0].name;
      var equipamentoErro = this.props.equipments.filter(e => e.id == equipamentoId)[0].name;
      let alertParams = [`${this.props.t("stepOne.messages.equipment")} ${equipamentoErro} ${this.props.t("stepOne.messages.multipleMainMsg1")} ${etapaErro} ${this.props.t("stepOne.messages.multipleMainMsg2")}.`, this.props.t("stepOne.messages.checkStage"), 'error'];

      this.props.showAlert(alertParams[0], alertParams[1], alertParams[2]);
      $('#insertButton').removeClass(defaultStyle.button_ghost_disable);

      this.setState(update(this.state, {
        errorMessages: { alreadyHaveMainEquipment: { $set: alertParams } },
        formChanged: { $set: true },
        etapaIntegradaValida: { $set: false }
      }));
    }
  }

  resolveData(chave, valor) {
    if (valor) {
      return valor[chave]
    }
    return 0;
  }

  updateEtapaIntegrada() {
    let { arrStage, obj, currentStage, etapaIntegrada } = this.state;
    var rota = this.state.obj.product.routes;

    return new Promise((resolve, reject) => {
      for (let i = 0; i < rota.length; i++) {
        if (rota[i].fk_id_stages == arrStage[currentStage].fk_id_stages && rota[i].cycle == arrStage[currentStage].cycle) {
          if (rota[i].etapa_integrada = etapaIntegrada[this.indexEtapaIntegrada(arrStage[currentStage].fk_id_stages, arrStage[currentStage].cycle)]) {
            rota[i].etapa_integrada = etapaIntegrada[this.indexEtapaIntegrada(arrStage[currentStage].fk_id_stages, arrStage[currentStage].cycle)].filter(e => e.equipamento_id == rota[i].fk_id_equipments)
          }
        }
      }

      obj.product.routes = rota;

      this.setState({
        obj: obj
      }, () => {
        resolve(this.props.t("stepOne.messages.stageIntegratedSuccess"));
      });
    });
  }

  onToggle(event, campo) {
    let { obj, currentStage, arrStage } = this.state;

    var rotas = [];

    obj.product.routes.forEach(element => {
      if (element.fk_id_stages == arrStage[currentStage].fk_id_stages && element.cycle == arrStage[currentStage].cycle) {
        element[campo] = !element[campo];

        if (campo == "parallel" && !element[campo] && element["parallel_extended"]) {
          element["parallel_extended"] = false;
        }
      }
      rotas.push(element);
    });

    obj.product.routes = rotas;

    this.setState({
      obj: obj
    });

    this.setState(update(this.state, {
      arrStage: {
        [currentStage]: {
          [campo]: { $set: !arrStage[currentStage][campo] }
        }
      },
      formChanged: { $set: true }
    }));
  }

  EditVigenciaRotas(changeContentModal, dados) {
    dados['current_route'] = this.state.obj;

    changeContentModal('editVigencia', dados);
  }

  alertaApagarAlteracoesRotas(value) {
    if (!this.state.formChanged) {
      this.customFunctionOnChange(this.state.timeMachineDados, value);
      return;
    }

    this.setState({ formChanged: false });

    var title = this.props.t("stepOne.messages.changeQuestionTitle");
    var subtitle = this.props.t("stepOne.messages.changeQuestionSubtitle");

    let alertContent =
      <AlertContainer>
        <AlertContent title={title} subtitle={subtitle}>
          <NButton type="primary" onClick={() => {
            this.saveRoutes(undefined, undefined, true);
            this.customFunctionOnChange(this.state.timeMachineDados, value);
            this.props.hideModal();
            this.modalCommon.close();
          }}>
            Sim
          </NButton>
          <NButton onClick={() => {
            this.customFunctionOnChange(this.state.timeMachineDados, value);
            this.props.hideModal();
            this.modalCommon.close();
          }}>
            Não
          </NButton>
        </AlertContent>
      </AlertContainer>

    this.props.hideModal();
    this.modalCommon.open(alertContent);
  }

  render() {
    let { uniq } = this.props.utils;
    let { products, session, stages, handleModal, changeContentModal, hideModal, style } = this.props;
    let { arrStage, currentStage, obj, selectedStage, showOnlyProductsOfSameArea } = this.state;
    let stagesOptions = stages.map(stage => { return { value: stage.id, text: stage.name } });

    if (!obj.product) {
      let opts = products
        .filter(p => {
          for (var rota of p.routes) {
            if (rota.fk_id_sessions == session.id) return false;

            for (var typeBatch of session.batchTypes) {
              var result = rota.unidadeIndustrial.tiposLote.find(t => t.id == typeBatch.id);

              if (result) return false;
            }
          }

          if (showOnlyProductsOfSameArea && session.fk_id_fields !== p.fk_id_fields) {
            return false
          }

          return true;
        })
        .sort((a, b) => a.sku - b.sku)
        .map((product) => {
          return { value: product.id, text: product.sku + ' - ' + product.name };
        });
      return (
        <div className="modal">
          <div>
            <div>
              <h3>Nova Rota</h3>
            </div>
            <div>
              <i className="fa fa-close cursor-pointer" onClick={() => handleModal('')} />
            </div>
          </div>
          <div style={{ width: 500, display: "inline" }}>
            <FormAutoComplete label={this.props.t("stepOne.messages.product")} options={opts} onChange={this.setProduct} style={{ margin: "0 auto", display: "inline-flex", alignItems: "center", justifyContent: "center", width: "85%" }} />
            {
              showOnlyProductsOfSameArea ?
                <div data-tip={""} data-for={"filter"} data-html={true} style={{ width: "30px", height: "30px", marginLeft: "1rem", display: "inline-flex" }}>
                  <i className='fa fa-filter fa-lg cursor-pointer' style={{ fontSize: "2.2em", color: "#2D4F7F" }} onClick={() => this.setState({ showOnlyProductsOfSameArea: !this.state.showOnlyProductsOfSameArea })} />
                </div>
                :
                <div data-tip={""} data-for={"filter"} data-html={true} style={{ width: "30px", height: "30px", marginLeft: "1rem", display: "inline-flex" }}>
                  <i className='fa fa-filter fa-lg cursor-pointer' style={{ fontSize: "2.2em", color: "#7e7e7e" }} onClick={() => this.setState({ showOnlyProductsOfSameArea: !this.state.showOnlyProductsOfSameArea })} />
                </div>
            }
            <ReactTooltip
              effect={'solid'}
              getContent={() => showOnlyProductsOfSameArea ? this.props.t("stepOne.messages.filterSameArea") : this.props.t("stepOne.messages.filterAll")}
              id="filter"
            />
          </div>
        </div>
      );
    } else if (!this.state.isNew && this.state.rotaProdutosAtivo == 0) {
      return <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
    }

    let routes = obj.product.routes.filter(route => route.fk_id_sessions == session.id && route.fk_id_stages == selectedStage && arrStage[currentStage].cycle == route.cycle);
    let equipments = selectedStage ? uniq(stages.filter(f => f.id == selectedStage)[0].equipments) : [];
    let ComEquipamento = selectedStage ? stages.filter(f => f.id == parseInt(selectedStage))[0].ComEquipamento : true;

    return (
      <div style={this.props.style}>
        <IF test={this.state.timeMachineDadosAba.length}>
          <AbaContainer>
            <AbaContent icon={Calendario}
              edit={hasRole('ROLE_ROTAS_ATUALIZAR')}
              predicate={() => { this.EditVigenciaRotas(changeContentModal, this.state.timeMachineDados) }}
              editIcon="fa fa-pencil"
              customFunctionOnChange={(value) => this.customFunctionOnChange(this.state.timeMachineDados, value)}
              itens={this.state.timeMachineDadosAba}
              selected={this.state.timeMachineDropDown}
              alertaApagarAlteracoesRotas={this.alertaApagarAlteracoesRotas}
              showAlert={this.props.showAlert}
              errorMessages={this.state.errorMessages}
              validarRotas={this.validarRotas}
              etapaIntegradaValida={this.state.etapaIntegradaValida}>
            </AbaContent>
          </AbaContainer>
        </IF>
        <div className="modal">
          <div>
            <div>
              <h3>{this.props.t("stepOne.messages.editRoute")}</h3><br />
              <h3>{obj.product.sku} - {obj.product.name}</h3>
            </div>
            <div style={{ display: "inline-flex", width: "auto" }}>
              <i className="fa fa-close cursor-pointer" onClick={() => handleModal('')} />
            </div>
          </div>
          <div className={styles['step-one-outer-wrapper']}>
            <div className={styles['step-one-inner-wrapper']}>
              <Breadcrumbs
                {...this.state}
                ondragendToTrash={this._ondragendToTrash}
                onDragToStage={this._onDragToStage}
                setArrStage={this.setArrStage}
                remArrStage={this.remArrStage}
                setCurrentStage={this.setCurrentStage}
                setNextStage={this.setNextStage}
                stagesOptions={stagesOptions}
              />

              <div className={styles.modal_step_one_box}>
                {
                  selectedStage
                    ? <div className={styles.modal_step_one_drag_drop}>
                      <ModalListEquipments
                        addRoute={this.addRoute}
                        ComEquipamento={ComEquipamento}
                        key={'list_' + currentStage}
                        equipments={equipments.filter(equipment => !routes.map(route => parseInt(route.fk_id_equipments)).includes(parseInt(equipment.id)))}
                      />

                      <ModalOneDrop
                        {...this.state}
                        onToggle={this.onToggle}
                        onChangeTipoProducao={this.onChangeTipoProducao}
                        etapaIntegrada={this.state.etapaIntegrada}
                        onCheckEtapaIntegrada={this.onCheckEtapaIntegrada}
                        etapas={this.props.stages}
                        key={'drop_' + currentStage}
                        handleModal={handleModal}
                        hideModal={hideModal}
                        routes={routes}
                        addRoute={this.addRoute}
                        ondragendToTrash={this._ondragendToTrash}
                        onDragToEquipment={this._onDragToEquipment}
                        remRoute={this.remRoute}
                        resetRoutes={this.resetRoutes}
                        saveRouteEquipment={this.saveRouteEquipment}
                        saveRoutes={this.saveRoutes}
                        ComEquipamento={ComEquipamento}
                      />
                    </div>
                    : null
                }
                <div className="trash hidden" ref="trash">
                  <div style={{ bottom: '-6vh' }}>
                    <img src={trashImg} onDragOver={this._onOverToTrash} onDrop={this._onDropToTrash} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('routes')(ModalStepOne);