import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import CustomLegends from './../CustomLegends/CustomLegends';
import * as _ from 'lodash';
import moment from 'moment';
import NenhumaInformacao from '../../../utils/NenhumaInformacao'
import { withTranslation } from 'react-i18next';

const legendOpts = {
  display: false
};

const LSCColor = 'rgba(51, 160, 44, 1)';
const LICColor = 'rgba(102, 5, 5, 1)';

class RealMediaMovelMetaChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRef: null,
      width: 0
    };
  }

  componentDidMount() {
    if(this.refs.chart !== undefined) {
      this.setState({
        chartRef: this.refs.chart.chartInstance
      });
    }

    this.setState({
      width: document.getElementById(this.props.id).offsetWidth
    });
  }

  formatOptions(xLabel, yLabel, suggestedMax) {
    return {
      maintainAspectRatio: false,
        scales: { 
          yAxes: [{
            scaleLabel: {
              display: false,
              labelString: yLabel ? yLabel : this.props.t("labels.hours", { ns: 'common' })
            },
            ticks: {
              suggestedMin: 0,
              steps: 10,
              stepValue: 50,
              padding: 10,
              beginAtZero: true,
              callback: function (value, index, values) {
                return parseFloat(value).toFixed(1).replace('-', '');
              }
            },
            
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: false,
              labelString: xLabel ? xLabel : this.props.t("labels.date", { ns: 'common' })
            }
          }]
        },
        tooltips: {
          titleFontSize: 16,
          bodyFontSize: 14,
          displayColors: false,
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        }
      };
    }

    formatData(data) {
      if (!data) return {};
      let meta = data.meta ? data.meta : 0;
      let hasData = (data.dados && data.dados.length > 0);
      let mediaMovel = hasData ? _.map(data.dados, (data) => data.media_movel) : [];
      let real = hasData ? _.map(data.dados, (data) => data.real) : [];
      let LSC = hasData ? _.map(data.dados, (data) => data.lsc) : [];
      let LIC = hasData ? _.map(data.dados, (data) => data.lic) : [];
      let labels = hasData ? _.map(data.dados, (data) => moment(data.data).format('DD/MM')) : [];
      meta = _.map(labels, () => meta);

      let ctx = document.createElement('CANVAS').getContext('2d');
      let gradientStroke = ctx.createLinearGradient(0, 0, 1000, 0);
      gradientStroke.addColorStop(0, 'rgba(61, 85, 119, 1)');
      gradientStroke.addColorStop(1, 'rgba(95, 130, 177, 1)');

      return {
        labels: labels,
        datasets: [
          {
            label: this.props.t("labels.lsc"),
            fill: false,
            backgroundColor: LSCColor,
            borderColor: LSCColor,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBorderColor: LSCColor,
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: LSCColor,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointRadius: 0,
            pointHitRadius: 0,
            borderWidth: 0,
            data: LSC
          },
          {
            label: this.props.t("labels.lic"),
            fill: false,
            backgroundColor: LICColor,
            borderColor: LICColor,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBorderColor: LICColor,
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: LICColor,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointRadius: 0,
            pointHitRadius: 0,
            borderWidth: 0,
            data: LIC
          },
          {
            label: this.props.t("labels.movingAverage"),
            fill: false,
            backgroundColor: 'rgba(235, 231, 39, 1)',
            borderColor: 'rgba(235, 231, 39, 1)',
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(235, 231, 39, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverBackgroundColor: 'rgba(235, 231, 39, 1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointRadius: 0,
            borderWidth: 0,
            data: mediaMovel
          },
          {
            label: this.props.t("labels.goal"),
            fill: false,
            backgroundColor: '#E65100',
            borderColor: '#E65100',
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBorderColor: '#E65100',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverBackgroundColor: '#BF360C',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointRadius: 0,
            borderWidth: 0,
            data: meta
          },
          {
            label: this.props.t("labels.real"),
            fill: true,
            backgroundColor: gradientStroke,
            borderCapStyle: 'butt',
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(2, 27, 102)',
            pointBackgroundColor: 'rgb(2, 27, 102)',
            pointHoverBackgroundColor: 'rgb(2, 27, 102)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointRadius: 0,
            pointHitRadius: 0,
            borderWidth: 0,
            data: real
          }
        ]
      };
    }

  render() {

    let valoresZerados = this.props.data.dados.filter(r => r.media_movel != 0);
    
    var max = null;
    if(this.props.data && this.props.data.meta && this.props.data.dados) {
      max = [
        this.props.data.meta,
        _.maxBy(this.props.data.dados, (dado) => dado.media_movel).media_movel,
        _.maxBy(this.props.data.dados, (dado) => dado.real).real,
      ];
      max = (_.max(max) || 1) * 1.25;
    }
    let opts = this.formatOptions(this.props.xLabel, this.props.yLabel, max);
    let data = this.formatData(this.props.data);
    let _legends = CustomLegends(this.state.chartRef, [
      LSCColor,
      LICColor,
      'rgba(235, 231, 39, 1)',
      '#E65100',
      'rgb(97, 125, 159)'
    ], data);

    return (
      <div id={this.props.id} className="col-xs-12" >
        {
          valoresZerados && valoresZerados.length == 0
            ?
            <div id={'chartRealMediaMovel'}>
            <NenhumaInformacao
              ref='chart'
            />
            </div>
            :
            <div id={'chartRealMediaMovel'}>
              <div className="col-xs-12 flex-dir-row" style={{ position: 'relative' }}>
                <span style={{ left: '-10px', top: '60%', position: 'absolute', fontSize: '13px', fontWeight: 'bold', transform: 'rotate(270deg)', transformOrigin: 'left top 0' }}>{this.props.yLabel}</span>
                {
                  this.state.width > 0
                    ?
                    <Line width={this.state.width} height={230} ref='chart' data={data} legend={legendOpts} options={opts} />
                    :
                    null
                }
              </div>
              <div className="col-xs-12 row flex-just-between flex-dir-row">
                <span style={{ marginLeft: '45px', fontSize: '13px', fontWeight: 'bold' }}>{this.props.xLabel || this.props.t("labels.date", { ns: 'common' })}</span>
                <div className={'rmd-legend-container flex'} >
                  {_legends}
                </div>
              </div>
            </div> 
        }
      </div>
    );
  }
}

export default withTranslation("flowDashboard")(RealMediaMovelMetaChart);
