import React, { Component } from "react";
import {
  Toggle,
  IconButton,
  Checkbox,
  RaisedButton,
  SelectField,
  MenuItem,
} from "material-ui";
import CustomCard from "./CustomCard/CustomCard";
import * as _ from "lodash";
import "./index.css";
import SetupHalfDoughnutChart from "./SetupHalfDoughnutChart/SetupHalfDoughnutChart";
import ResultadoDoughnutChart from "./ResultadoDoughnutChart/ResultadoDoughnutChart";
import SetupBarChart from "./SetupBarChart/SetupBarChart";
import OEEComposicaoBarChart from "./OEEComposicaoBarChart/OEEComposicaoBarChart";
import DiarioChart from "./DiarioChart/DiarioChart";
import ControleEstatisticoOEEChart from "./ControleEstatisticoOEEChart/ControleEstatisticoOEEChart";
import ParetoBarChart from "./ParetoBarChart/ParetoBarChart";
import ManutencaoBarChart from "./ManutencaoBarChart/ManutencaoBarChart";
import { EventEmitter, Events } from "./events";
import { IndicatorColorsBgs, IndicatorColors } from "./constants";
import "./Helpers/ChartJsHelper";
import { Chart } from "react-chartjs-2";
import ChartHelpers from "./Helpers/ChartJsHelper";
import update from "immutability-helper";
import PainelLoader from "../PainelLoader";
import stylePainelLoader from "../PainelLoader.scss";
import { request } from "../../common/request";
import style from "./responsive.css";
import TimeLineChart from "./TimeLineChart";
import moment from "moment";
import { Paginacao } from "./../../common/Constants";
import Util from "../../utils/Utils";
import i18n from "utils/i18n";
import DashboardSession from "clients/DashboardSession";

ChartHelpers(Chart);

// Esse index.js é o components/PainelFluxo/PainelFluxo.js
// Eu movi todo o código para o index.js e ajustei os caminhos para ele
// O que estava em App.js tbm está aqui.
// Basicamente tudo está concentrado aqui.

const STYLES = {
  MAX_SCROLL_DIV_HEIGHT: "245px",
  MAX_DIV_HEIGHT: "300px",
  MAX_TWO_DIV_HEIGHT: "610px",
  MARGIN_LEFT: "10px",
  DEFAULT_MARGIN: "10px 0 0 0",
  MIN_WIDTH: "1205px",
  FLEX_ROW: {
    display: "flex",
    flexDirection: "row",
  },
};

const getStyles = () => {
  return {
    block: {
      width: "0px",
    },
    thumbOff: {
      backgroundColor: "#787878",
    },
    trackOff: {
      backgroundColor: "#BBBBBB",
    },
    thumbSwitched: {
      backgroundColor: "#274F7F",
    },
    trackSwitched: {
      backgroundColor: "#9EBDE8",
    },
    labelStyle: {
      color: "#787878",
      fontSize: "13px",
    },
  };
};

const defaultHeader = (
  title,
  toggleLabel,
  onToggle,
  oeeType,
  oee_selecionado,
  checkMostrarZerosOee,
  mostrarZerosOee,
  buttonsGranularidade,
) => {
  onToggle = onToggle ? onToggle : (t) => {};
  let styles = getStyles();

  return (
    <div
      style={{ width: "100%" }}
      className={"flex flex-dir-row flex-align-center"}
    >
      {title !== i18n.t("title", { ns: "oee" }) ? (
        <SelectField
          style={{ margin: "-12px 0px -2px 0px", height: 42, width: 156 }}
          underlineFocusStyle={{ borderColor: "#000000" }}
          labelStyle={{ color: "#5B5B5B", fontSize: "15px" }}
          selectedMenuItemStyle={{ fontWeight: "bold" }}
          value={oee_selecionado}
          onChange={(...props) => oeeType(props[2])}
        >
          <MenuItem
            value="oee_efetivo"
            primaryText={i18n.t("labels.oeeEffective", { ns: "oee" })}
          />
          <MenuItem
            value="oee_otimizado"
            primaryText={i18n.t("labels.oeeOptimized", { ns: "oee" })}
          />
          <MenuItem
            value="teep"
            primaryText={i18n.t("labels.oeeTeep", { ns: "oee" })}
          />
        </SelectField>
      ) : (
        title
      )}
      <span style={{ marginLeft: 15 }} />

      {buttonsGranularidade}

      <span className={"flex flex-2 flex-just-end"}>
        <span style={{ display: "inline-block" }}>
          {checkMostrarZerosOee && (
            <Checkbox
              checked={mostrarZerosOee}
              onCheck={checkMostrarZerosOee}
              label={i18n.t("labels.showZeros", { ns: "oee" })}
              style={{ marginRight: 5, borderRight: "1px solid #E6E6E6" }}
              iconStyle={{ fill: "#2B4D7C", marginRight: 5 }}
              labelStyle={{
                color: "rgb(120, 120, 120)",
                fontSize: "13px",
                width: "calc(100% - 20px)",
              }}
            />
          )}
        </span>
        <span style={{ display: "inline-block", marginLeft: 5 }}>
          <Toggle
            disabled={
              title === i18n.t("labels.oeeTeep", { ns: "oee" }) ? true : false
            }
            onToggle={onToggle}
            label={toggleLabel || i18n.t("labels.setup", { ns: "oee" })}
            labelPosition="right"
            defaultToggled={true}
            thumbStyle={styles.thumbOff}
            trackStyle={styles.trackOff}
            thumbSwitchedStyle={styles.thumbSwitched}
            trackSwitchedStyle={styles.trackSwitched}
            labelStyle={styles.labelStyle}
            style={styles.toggle}
            id={title.replace(" ", "-") + "_" + Math.random()}
          />
        </span>
      </span>
    </div>
  );
};

const resultadoOEE = (id, data) => {
  return (
    <ResultadoDoughnutChart
      componentID={id}
      data={data}
    ></ResultadoDoughnutChart>
  );
};

const composicaoOEE = (id, data) => {
  return (
    <OEEComposicaoBarChart componentID={id} data={data}></OEEComposicaoBarChart>
  );
};

const diarioOEE = (id, data, diferencaDias, mostrarZerosOee, granularidade) => {
  return (
    <DiarioChart
      componentID={id}
      data={data}
      diferencaDias={diferencaDias}
      exibirDisponibilidadeZerada={mostrarZerosOee}
      granularidade={granularidade}
    ></DiarioChart>
  );
};

const ControleEstatisticoOEE = (data) => {
  return (
    <ControleEstatisticoOEEChart data={data}></ControleEstatisticoOEEChart>
  );
};

const switchIconButton = (id) => {
  let onClick = () => {
    EventEmitter.dispatch(Events.SWITCH_CHART, { componentID: id });
  };
  return (
    <IconButton
      onClick={onClick}
      style={{ position: "absolute", top: 25, right: 0 }}
      iconStyle={{
        backgroundColor: "#EF6D15",
        borderRadius: "50%",
        color: "white",
      }}
      iconClassName="material-icons"
    >
      swap_horiz
    </IconButton>
  );
};

const manutencaoBarChart = (data) => {
  let formattedData = {
    labels: ["MTBF", "MTTR", "MTBP", "MTTP", "MWT "],
    data: [
      data ? data.mtbf.real : 0,
      data ? data.mttr.real : 0,
      data ? data.mtbp.real : 0,
      data ? data.mttp.real : 0,
      data ? data.mwt.real : 0,
    ],
    data2: [
      data ? data.mtbf.padrao : 0,
      data ? data.mttr.padrao : 0,
      data ? data.mtbp.padrao : 0,
      data ? data.mttp.padrao : 0,
      data ? data.mwt.padrao : 0,
    ],
    indicatorBackground: [
      data
        ? data.mtbf.cor
          ? IndicatorColorsBgs[data.mtbf.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mttr.cor
          ? IndicatorColorsBgs[data.mttr.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mtbp.cor
          ? IndicatorColorsBgs[data.mtbp.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mttp.cor
          ? IndicatorColorsBgs[data.mttp.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mwt.cor
          ? IndicatorColorsBgs[data.mwt.cor]
          : "transparent"
        : "transparent",
    ],
    indicatorColor: [
      data
        ? data.mtbf.cor
          ? IndicatorColors[data.mtbf.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mttr.cor
          ? IndicatorColors[data.mttr.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mtbp.cor
          ? IndicatorColors[data.mtbp.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mttp.cor
          ? IndicatorColors[data.mttp.cor]
          : "transparent"
        : "transparent",
      data
        ? data.mwt.cor
          ? IndicatorColors[data.mwt.cor]
          : "transparent"
        : "transparent",
    ],
  };

  return (
    <div>
      {data !== null ? (
        <ManutencaoBarChart
          data={formattedData}
          ticksColor={"#6A8BCC"}
          showTicks={true}
          barSize={0.6}
          horizontalBarLabel={false}
        />
      ) : null}
    </div>
  );
};

const setupBarChart = (data) => {
  let formattedData = {
    labels: _.map(data, (d) => d.titulo),
    data: _.map(data, (d) => d.real),
    data2: _.map(data, (d) => d.padrao),
    padraoProporcional: _.map(data, (d) => d.padraoProporcional),
    variasVigencias: _.map(data, (d) => d.variasVigencias),
    indicatorBackground: _.map(data, (d) => IndicatorColorsBgs[d.cor]),
    indicatorColor: _.map(data, (d) => IndicatorColors[d.cor]),
    frequency: _.map(data, (d) => d.frequencia),
    setupPerformance: _.map(data, (d) => d.porcentagem),
  };

  return (
    <SetupBarChart
      data={formattedData}
      horizontalBarLabel={true}
      displayTooltip={true}
    />
  );
};

class PainelOEE extends Component {
  constructor(props) {
    super(props);
    this.toggleSetup.bind(this);
    this.carregarParetoFiltro.bind(this);
    this.clearState.bind(this);
    this.paginacao = Paginacao;
    this.toggleTeep = this.toggleTeep.bind(this);
    this.oeeType = this.oeeType.bind(this);
    this.graficoOeeSelecionado = this.graficoOeeSelecionado.bind(this);
    this.carregarDados.bind(this);
    this.carregarDadosApontamentos = this.carregarDadosApontamentos.bind(this);
    this.callModalPareto = this.callModalPareto.bind(this);
    this.checkMostrarZerosOee = this.checkMostrarZerosOee.bind(this);
    this.checkMostrarZerosOeeSelecionado =
      this.checkMostrarZerosOeeSelecionado.bind(this);
    this.interval = undefined;
    this.interval60 = undefined;

    this.getDescriptionSetupTooltip.bind(this);
    this.customHeaderSetup.bind(this);
    this.customEmptyContentSetup.bind(this);
    this.getTurnoEquipamentoComposicaoUrl.bind(this);
    this.carregarSetupToggle.bind(this);
    this.checkMostrarZerosOee.bind(this);
    this.checkMostrarZerosOeeSelecionado.bind(this);

    this.state = {
      oee_selecionado: "oee_efetivo",
      mostrarZerosOee: false,
      mostrarZerosOeeSelecionado: false,
      filtro_pareto_tempo: 0,
      filtro_pareto_tipo_evento: 0,
      oee: {
        data: {
          resultado: {
            com_setup: null,
            sem_setup: null,
          },
          composicao: {
            com_setup: null,
            sem_setup: null,
          },
          diario: {
            com_setup: null,
            sem_setup: null,
          },
          controle: null,
        },
        loading: true,
        fail: true,
      },
      oee_efetivo: {
        data: {
          resultado: {
            com_setup: null,
            sem_setup: null,
          },
          composicao: {
            com_setup: null,
            sem_setup: null,
          },
          diario: {
            com_setup: null,
            sem_setup: null,
          },
          controle: null,
        },
        loading: true,
        fail: true,
      },
      oee_otimizado: {
        data: {
          resultado: {
            com_setup: null,
            sem_setup: null,
            teep: null,
          },
          composicao: {
            com_setup: null,
            sem_setup: null,
            teep: null,
          },
          diario: {
            com_setup: null,
            sem_setup: null,
            teep: null,
          },
          controle: null,
        },
        loading: true,
        fail: true,
      },
      teep: {
        data: {
          resultado: {
            com_setup: null,
            sem_setup: null,
          },
          composicao: {
            com_setup: null,
            sem_setup: null,
          },
          diario: {
            com_setup: null,
            sem_setup: null,
          },
          controle: null,
        },
        loading: true,
        fail: true,
      },
      oeeShow: "oee_efetivo",
      setup: {
        data: {
          resultado: null,
          eventos: null,
        },
        loading: true,
        fail: true,
        setupProporcional: true,
      },
      manutencao: {
        data: null,
        loading: true,
        fail: true,
      },
      corretiva_preventiva: {
        data: null,
        loading: true,
        fail: true,
      },
      pareto: {
        data: null,
        loading: true,
        fail: true,
      },
      timeline: {
        data: null,
        loading: true,
        fail: true,
        tooltip: "",
      },
      loaded: false,
      toogleTeep: false,
      apontamentos_timeline: {
        data: [],
        hasMoreNotes: true,
        firstRequest: true,
        paginationPage: 0,
      },
    };
  }

  dataParaString(date) {
    return !date || !date.format ? "" : date.format("DD-MM-YYYY HH:mm:ss");
  }

  clearState() {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          oee_selecionado: "oee_efetivo",
          mostrarZerosOee: false,
          mostrarZerosOeeSelecionado: false,
          oee: {
            data: {
              resultado: {
                com_setup: null,
                sem_setup: null,
              },
              composicao: {
                com_setup: null,
                sem_setup: null,
              },
              diario: {
                com_setup: null,
                sem_setup: null,
              },
              controle: null,
            },
            loading: true,
            fail: true,
          },
          oee_efetivo: {
            data: {
              resultado: {
                com_setup: null,
                sem_setup: null,
              },
              composicao: {
                com_setup: null,
                sem_setup: null,
              },
              diario: {
                com_setup: null,
                sem_setup: null,
              },
              controle: null,
            },
            loading: true,
            fail: true,
          },
          oee_otimizado: {
            data: {
              resultado: {
                com_setup: null,
                sem_setup: null,
              },
              composicao: {
                com_setup: null,
                sem_setup: null,
              },
              diario: {
                com_setup: null,
                sem_setup: null,
              },
              controle: null,
            },
            loading: true,
            fail: true,
          },
          teep: {
            data: {
              resultado: {
                com_setup: null,
                sem_setup: null,
              },
              composicao: {
                com_setup: null,
                sem_setup: null,
              },
              diario: {
                com_setup: null,
                sem_setup: null,
              },
              controle: null,
            },
            loading: true,
            fail: true,
          },
          setup: {
            data: {
              resultado: null,
              eventos: null,
            },
            loading: true,
            fail: true,
            setupProporcional: true,
          },
          manutencao: {
            data: null,
            loading: true,
            fail: true,
          },
          corretiva_preventiva: {
            data: null,
            loading: true,
            fail: true,
          },
          pareto: {
            data: null,
            loading: true,
            fail: true,
          },
          timeline: {
            data: null,
            loading: true,
            fail: true,
            tooltip: "",
          },
          toogleTeep: false,
          apontamentos_timeline: {
            data: [],
            hasMoreNotes: true,
            firstRequest: true,
            paginationPage: 0,
          },
        },
        () => {
          resolve("state limpa");
        },
      );
    });
  }

  getTurnoEquipamentoComposicaoUrl() {
    const { equipamento, turnos } = this.props;

    let turnoUrl = turnos
      .map((t) => {
        if (t !== 0) {
          return "turnos=" + t.id;
        }
      })
      .join("&");

    let equipamentoUrl = equipamento
      .map((e) => {
        return "equipamentos=" + e;
      })
      .join("&");

    let turnoPersonalizado = turnos.some((s) => s.id === 0);

    return { turnoUrl, equipamentoUrl, turnoPersonalizado };
  }

  carregarDados() {
    this.clearState().then(() => {
      try {
        let {
          autoRefresh,
          endpoints,
          equipamento,
          data_inicio,
          data_fim,
          turnos,
          inicio_turno,
          fim_turno,
          grupoEquipamentoEscolhido,
          granularidade,
          flagSetupProporcional,
          diferencaDias,
          modo,
        } = this.props;

        if (autoRefresh && modo != 6) {
          //Alguns clientes deixam a tela atualizando de um dia pro outro, caso não haja essa tratativa, as datas permanecerão constantes

          let finalAtual = moment();

          switch (modo) {
            case 1: //modo 1 = hoje
              data_inicio = moment();
              data_fim = finalAtual;
              break;
            case 2: //ontem
              data_inicio = moment().add(-1, "days");
              data_fim = moment().add(-1, "days");
              break;
            case 3: //ytd
              data_inicio = moment().clone().startOf("year");
              data_fim = finalAtual;
              break;
            case 4: //mtd
              data_inicio = moment().clone().startOf("month");
              data_fim = finalAtual;
              break;
            case 5: //15 dias
              data_inicio = moment().add(-15, "days");
              data_fim = finalAtual;
              break;
          }

          data_inicio = data_inicio.format("DD-MM-YYYY");
          data_fim = data_fim.format("DD-MM-YYYY");

          data_inicio = data_inicio + " 00:00:00";
          data_fim = data_fim + " 23:59:59";

          this.setState({
            data_inicio,
            data_fim,
          });
        }
        let mapaUrl = this.getTurnoEquipamentoComposicaoUrl();

        this.setState(
          update(this.state, {
            oee: { loading: { $set: true }, fail: { $set: false } },
            oee_efetivo: { loading: { $set: true }, fail: { $set: false } },
            oee_otimizado: { loading: { $set: true }, fail: { $set: false } },
            setup: { loading: { $set: true }, fail: { $set: false } },
            manutencao: { loading: { $set: true }, fail: { $set: false } },
            pareto: { loading: { $set: true }, fail: { $set: false } },
            timeline: { loading: { $set: true }, fail: { $set: false } },
            corretiva_preventiva: {
              loading: { $set: true },
              fail: { $set: false },
            },
            loaded: { $set: true },
          }),
          () => {
            Promise.all([
              this.buscarOEE(
                endpoints,
                mapaUrl.equipamentoUrl,
                mapaUrl.turnoUrl,
                mapaUrl.turnoPersonalizado,
                data_inicio,
                data_fim,
                inicio_turno,
                fim_turno,
                grupoEquipamentoEscolhido,
                granularidade,
                autoRefresh,
              ),
            ]).finally(() => {
              this.buscarManutencao(
                endpoints,
                mapaUrl.equipamentoUrl,
                mapaUrl.turnoUrl,
                mapaUrl.turnoPersonalizado,
                data_inicio,
                data_fim,
                inicio_turno,
                fim_turno,
                grupoEquipamentoEscolhido,
              );
              this.buscarPareto(
                endpoints,
                mapaUrl.equipamentoUrl,
                mapaUrl.turnoUrl,
                mapaUrl.turnoPersonalizado,
                data_inicio,
                data_fim,
                inicio_turno,
                fim_turno,
                this.state.filtro_pareto_tempo,
                this.state.filtro_pareto_tipo_evento,
              );
              this.buscarTimeline(
                endpoints,
                equipamento,
                data_inicio,
                data_fim,
              );
              this.buscarCorretivaPreventiva(
                endpoints,
                mapaUrl.equipamentoUrl,
                mapaUrl.turnoUrl,
                mapaUrl.turnoPersonalizado,
                data_inicio,
                data_fim,
                inicio_turno,
                fim_turno,
              );
              this.buscarSetup(
                endpoints,
                mapaUrl,
                data_inicio,
                data_fim,
                inicio_turno,
                fim_turno,
                grupoEquipamentoEscolhido,
                flagSetupProporcional,
              );
            });
          },
        );
      } catch (err) {
        console.log(err);
      }
    });
  }

  buscarOEE(
    endpoints,
    equipamento,
    turnos,
    turnoPersonalizado,
    data_inicio,
    data_fim,
    inicio_turno,
    fim_turno,
    grupoEquipamentoEscolhido,
    granularidade,
    autoRefresh = false,
  ) {
    var failState = {
      oee: {
        $set: {
          data: {
            resultado: null,
            composicao: null,
            diario: null,
          },
          loading: true,
          fail: true,
        },
      },
      oee_efetivo: {
        $set: {
          data: {
            resultado: null,
            composicao: null,
            diario: null,
          },
          loading: true,
          fail: true,
        },
      },
      oee_otimizado: {
        $set: {
          data: {
            resultado: null,
            composicao: null,
            diario: null,
          },
          loading: true,
          fail: true,
        },
      },
      teep: {
        $set: {
          data: {
            resultado: null,
            composicao: null,
            diario: null,
          },
        },
      },
    };

    return new Promise((resolve, reject) => {
      try {
        request(
          `${endpoints.OEE}/${data_inicio}/${data_fim}/${turnoPersonalizado}?${equipamento}&${turnos}&dataInicioTurno=${inicio_turno}&dataFimTurno=${fim_turno}&granularidade=${granularidade}&grupoEquipamentoUuid=${grupoEquipamentoEscolhido}`,
          {
            method: "get",
          },
        )
          .then((response) => {
            return response.json();
          })
          .then((dataOEE) => {
            this.setState(
              update(this.state, {
                oee: {
                  $set: {
                    data: {
                      resultado: {
                        com_setup: dataOEE.oee.com_setup.resultado,
                        sem_setup: dataOEE.oee.sem_setup.resultado,
                      },
                      composicao: {
                        com_setup: dataOEE.oee.com_setup.composicao,
                        sem_setup: dataOEE.oee.sem_setup.composicao,
                      },
                      diario: {
                        com_setup: dataOEE.oee.com_setup.diario,
                        sem_setup: dataOEE.oee.sem_setup.diario,
                      },
                    },
                    loading: false,
                    fail: false,
                  },
                },
                oee_efetivo: {
                  $set: {
                    data: {
                      resultado: {
                        com_setup: dataOEE.efetivo.com_setup.resultado,
                        sem_setup: dataOEE.efetivo.sem_setup.resultado,
                      },
                      composicao: {
                        com_setup: dataOEE.efetivo.com_setup.composicao,
                        sem_setup: dataOEE.efetivo.sem_setup.composicao,
                      },
                      diario: {
                        com_setup: dataOEE.efetivo.com_setup.diario,
                        sem_setup: dataOEE.efetivo.sem_setup.diario,
                      },
                    },
                    loading: false,
                    fail: false,
                  },
                },
                oee_otimizado: {
                  $set: {
                    data: {
                      resultado: {
                        com_setup: dataOEE.otimizado.com_setup.resultado,
                        sem_setup: dataOEE.otimizado.sem_setup.resultado,
                      },
                      composicao: {
                        com_setup: dataOEE.otimizado.com_setup.composicao,
                        sem_setup: dataOEE.otimizado.sem_setup.composicao,
                      },
                      diario: {
                        com_setup: dataOEE.otimizado.com_setup.diario,
                        sem_setup: dataOEE.otimizado.sem_setup.diario,
                      },
                    },
                    loading: false,
                    fail: false,
                  },
                },
                teep: {
                  $set: {
                    data: {
                      resultado: {
                        com_setup: dataOEE.teep.com_setup.resultado,
                        sem_setup: dataOEE.teep.sem_setup.resultado,
                      },
                      composicao: {
                        com_setup: dataOEE.teep.com_setup.composicao,
                        sem_setup: dataOEE.teep.sem_setup.composicao,
                      },
                      diario: {
                        com_setup: dataOEE.teep.com_setup.diario,
                        sem_setup: dataOEE.teep.sem_setup.diario,
                      },
                    },
                  },
                },
              }),
            );

            resolve();
          })
          .catch((err) => {
            console.log("OEE:", err);

            this.setState(update(this.state, failState));

            reject();
          });
      } catch (err) {
        console.log("OEE:", err);

        this.setState(update(this.state, failState));

        reject();
      }
    });
  }

  buscarSetup(
    endpoints,
    mapaUrl,
    data_inicio,
    data_fim,
    inicio_turno,
    fim_turno,
    grupoEquipamentoEscolhido,
    setupProporcional,
  ) {
    var failState = {
      setup: {
        $set: {
          data: { resultado: null, eventos: null },
          loading: true,
          fail: true,
          setupProporcional: true,
        },
      },
    };

    try {
      const { updateFlagSetupProporcional } = this.props;
      request(
        `${endpoints.OEE}/setup/${data_inicio}/${data_fim}/${mapaUrl.turnoPersonalizado}?${mapaUrl.equipamentoUrl}&${mapaUrl.turnoUrl}&dataInicioTurno=${inicio_turno}&dataFimTurno=${fim_turno}&grupoEquipamentoUuid=${grupoEquipamentoEscolhido}&setupProporcional=${setupProporcional}`,
        {
          method: "get",
        },
      )
        .then((req) => {
          if (updateFlagSetupProporcional) {
            updateFlagSetupProporcional(setupProporcional);
          }

          return req.json();
        })
        .then((data) => {
          var dados = {
            eventos: null,
            resultado: null,
          };

          if (data.eventos.length > 0) {
            dados = {
              resultado: data.grafico.performance_geral,
              eventos: data.eventos,
            };
          }

          this.setState(
            update(this.state, {
              setup: {
                $set: {
                  data: dados,
                  fail: false,
                  loading: false,
                  setupProporcional: setupProporcional,
                },
              },
            }),
          );
        })
        .catch((err) => {
          console.log("Setup:", err);

          this.setState(update(this.state, failState));
        });
    } catch (err) {
      console.log("Setup:", err);

      this.setState(update(this.state, failState));
    }
  }

  buscarManutencao(
    endpoints,
    equipamento,
    turnos,
    turnoPersonalizado,
    data_inicio,
    data_fim,
    inicio_turno,
    fim_turno,
    grupoEquipamentoEscolhido,
  ) {
    var failState = {
      manutencao: {
        $set: {
          data: null,
          loading: true,
          fail: true,
        },
      },
    };

    try {
      request(
        `${endpoints.OEE}/manutencao/${data_inicio}/${data_fim}/${turnoPersonalizado}?${equipamento}&${turnos}&dataInicioTurno=${inicio_turno}&dataFimTurno=${fim_turno}&grupoEquipamentoUuid=${grupoEquipamentoEscolhido}`,
        {
          method: "get",
        },
      )
        .then((req) => {
          return req.json();
        })
        .then((data) => {
          var info = false;
          Object.keys(data).forEach((elm) => {
            if (data[elm].real > 0) {
              info = true;
            }
          });

          if (info) {
            this.setState(
              update(this.state, {
                manutencao: {
                  $set: {
                    data: data,
                    loading: false,
                    fail: false,
                  },
                },
              }),
            );
          } else {
            this.setState(update(this.state, failState));
          }
        })
        .catch((err) => {
          console.log("Manutencao:", err);

          this.setState(update(this.state, failState));
        });
      this.setState({ loadingData: false });
    } catch (err) {
      console.log("Manutencao:", err);

      this.setState(update(this.state, failState));
    }
  }

  buscarTimeline(endpoints, equipamento, data_inicio, data_fim) {
    let tooltip = "";
    var failState = {
      timeline: {
        $set: {
          data: null,
          loading: true,
          fail: true,
          tooltip: "",
        },
      },
    };

    if (
      moment
        .duration(
          moment(data_fim, "DD-MM-YYYY HH:mm").diff(
            moment(data_inicio, "DD-MM-YYYY HH:mm"),
          ),
        )
        .asMonths() > 12
    ) {
      tooltip = i18n.t("messages.oneYearGraph", { ns: "oee" });
      failState = {
        timeline: {
          $set: {
            data: null,
            loading: true,
            fail: true,
            tooltip,
          },
        },
      };
    }

    try {
      request(
        `${endpoints.OEE}/timeline/${equipamento}/${data_inicio}/${data_fim}`,
        {
          method: "get",
        },
      )
        .then((req) => {
          if (!req.ok) throw req.json();
          return req.json();
        })
        .then((data) => {
          if (data.length > 0) {
            this.setState(
              update(this.state, {
                timeline: {
                  $set: {
                    data: data,
                    loading: false,
                    fail: false,
                    tooltip,
                  },
                },
              }),
            );
          } else {
            this.setState(update(this.state, failState));
          }
        })
        .catch((err) => {
          this.setState(update(this.state, failState));
        });
    } catch (err) {
      console.log("TimeLine:", err);

      this.setState(update(this.state, failState));
    }
  }

  buscarPareto(
    endpoints,
    equipamento,
    turnos,
    turnoPersonalizado,
    data_inicio,
    data_fim,
    inicio_turno,
    fim_turno,
    filtro_pareto_tempo = 0,
    filtro_pareto_tipo_evento = 0,
  ) {
    var failState = {
      pareto: {
        $set: {
          data: null,
          loading: true,
          fail: true,
        },
      },
    };

    try {
      request(
        `${endpoints.OEE}/pareto/${data_inicio}/${data_fim}/${turnoPersonalizado}?${equipamento}&${turnos}&dataInicioTurno=${inicio_turno}&dataFimTurno=${fim_turno}&tempoConsideradoPareto=${filtro_pareto_tempo}&tipoEventoConsiderado=${filtro_pareto_tipo_evento}`,
        {
          method: "get",
        },
      )
        .then((req) => {
          return req.json();
        })
        .then((data) => {
          if (data.length > 0) {
            this.setState(
              update(this.state, {
                pareto: {
                  $set: {
                    data: data,
                    loading: false,
                    fail: false,
                  },
                },
              }),
            );
          } else {
            this.setState(update(this.state, failState));
          }
        })
        .catch((err) => {
          console.log("Pareto:", err);

          this.setState(update(this.state, failState));
        });
    } catch (err) {
      console.log("Pareto:", err);

      this.setState(update(this.state, failState));
    }
  }

  buscarCorretivaPreventiva(
    endpoints,
    equipamento,
    turnos,
    turnoPersonalizado,
    data_inicio,
    data_fim,
    inicio_turno,
    fim_turno,
  ) {
    var failState = {
      corretiva_preventiva: {
        $set: {
          data: null,
          loading: true,
          fail: true,
        },
      },
    };

    try {
      request(
        `${endpoints.OEE}/corretiva-preventiva/${data_inicio}/${data_fim}/${turnoPersonalizado}?${equipamento}&${turnos}&dataInicioTurno=${inicio_turno}&dataFimTurno=${fim_turno}`,
        {
          method: "get",
        },
      )
        .then((req) => {
          return req.json();
        })
        .then((data) => {
          var info = false;
          if (data.corretiva > 0) {
            info = true;
          } else {
            data.dados.forEach((elm) => {
              if (elm.relacao > 0) {
                info = true;
              }
            });
          }

          if (info) {
            this.setState(
              update(this.state, {
                corretiva_preventiva: {
                  $set: {
                    data: data,
                    loading: false,
                    fail: false,
                  },
                },
              }),
            );
          } else {
            this.setState(update(this.state, failState));
          }
        })
        .catch((err) => {
          console.log("Corretiva Preventiva:", err);

          this.setState(update(this.state, failState));
        });
    } catch (err) {
      console.log("Pareto:", err);

      this.setState(update(this.state, failState));
    }
  }

  componentDidMount() {
    const { autoRefresh } = this.props;

    const {
      equipamento,
      data_inicio,
      data_fim,
      inicio_turno,
      fim_turno,
      grupoEquipamentoEscolhido,
      granularidade,
    } = this.props;

    if (autoRefresh) {
      this.interval = setInterval(() => this.carregarDados(), 900000);
      this.interval60 = setInterval(() => {
        DashboardSession.performKeepAlive("oee", {
          equipamento,
          data_inicio,
          data_fim,
          inicio_turno,
          fim_turno,
          grupoEquipamentoEscolhido,
          granularidade,
        });
      }, 1000 * 60);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      equipamento,
      data_inicio,
      data_fim,
      inicio_turno,
      fim_turno,
      grupoEquipamentoEscolhido,
      granularidade,
    } = this.props;

    if (nextProps.autoRefresh) {
      if (this.interval) {
        clearInterval(this.interval);
        clearInterval(this.interval60);
      }
      this.interval = setInterval(() => this.carregarDados(), 900000);
      this.interval60 = setInterval(() => {
        DashboardSession.performKeepAlive("oee", {
          equipamento,
          data_inicio,
          data_fim,
          inicio_turno,
          fim_turno,
          grupoEquipamentoEscolhido,
          granularidade,
        });
      }, 1000 * 60);
    } else {
      if (this.interval) {
        clearInterval(this.interval);
        clearInterval(this.interval60);
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      clearInterval(this.interval60);
    }
  }

  carregarSetupToggle(toggleSetupProporcional) {
    try {
      const {
        endpoints,
        data_inicio,
        data_fim,
        inicio_turno,
        fim_turno,
        grupoEquipamentoEscolhido,
      } = this.props;

      let mapaUrl = this.getTurnoEquipamentoComposicaoUrl();
      var clearState = {
        setup: {
          $set: {
            data: { resultado: null, eventos: null },
            loading: true,
            fail: false,
            setupProporcional: toggleSetupProporcional,
          },
        },
      };

      this.setState(update(this.state, clearState), () => {
        this.buscarSetup(
          endpoints,
          mapaUrl,
          data_inicio,
          data_fim,
          inicio_turno,
          fim_turno,
          grupoEquipamentoEscolhido,
          toggleSetupProporcional,
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  carregarParetoFiltro(filtro_tempo, filtro_tipo_evento) {
    try {
      const { endpoints, data_inicio, data_fim, inicio_turno, fim_turno } =
        this.props;
      let mapaUrl = this.getTurnoEquipamentoComposicaoUrl();

      var clearState = {
        pareto: {
          $set: {
            data: [],
            loading: true,
            fail: false,
          },
        },
      };

      this.setState(update(this.state, clearState), () => {
        this.buscarPareto(
          endpoints,
          mapaUrl.equipamentoUrl,
          mapaUrl.turnoUrl,
          mapaUrl.turnoPersonalizado,
          data_inicio,
          data_fim,
          inicio_turno,
          fim_turno,
          filtro_tempo,
          filtro_tipo_evento,
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  getDescriptionSetupTooltip(setup) {
    var description = "";
    var { eventos, resultado } = setup.data;

    if (eventos) {
      const proporcionalOuVigencias = eventos.filter(
        (evento) => evento.padraoProporcional || evento.variasVigencias,
      );
      if (proporcionalOuVigencias) {
        const setupsProporcionais = eventos.filter(
          (evento) => evento.padraoProporcional,
        );
        description =
          setupsProporcionais.length > 0
            ? i18n.t("messages.proportionalDefaultValue", { ns: "oee" })
            : "";
      }

      if (resultado.variasVigencias) {
        description += `<br> ${i18n.t("errors.moreThanOneRoute", { ns: "oee" })}`;
      }
    }

    return description.trim();
  }

  customEmptyContentSetup() {
    return (
      <div
        style={{ marginTop: "19%" }}
        className={stylePainelLoader["painel-loader__failure__wrapper"]}
      >
        <i
          className={`material-icons ${stylePainelLoader["painel-loader__failure__text"]}`}
        >
          warning
        </i>
        <div>
          <h3 className={stylePainelLoader["painel-loader__failure__text"]}>
            {i18n.t("messages.noData", { ns: "oee" })}
          </h3>
        </div>
      </div>
    );
  }

  customHeaderSetup(title, setup) {
    let styles = getStyles();
    const tooltip = this.getDescriptionSetupTooltip(setup);
    const { flagSetupProporcional } = this.props;

    return (
      <div
        style={{ width: "100%" }}
        className={"flex flex-dir-row flex-align-center"}
      >
        {title}
        {tooltip && (
          <i
            data-html={true}
            data-for={"card"}
            data-tip={tooltip}
            className="material-icons tooltip-wrapper"
          >
            help
          </i>
        )}
        <span className={"flex flex-1"}></span>
        <span className={"flex flex-2 flex-just-end"}>
          <span style={{ display: "inline-block" }}>
            <Toggle
              disabled={false}
              labelPosition="right"
              defaultToggled={true}
              label={i18n.t("labels.onlyFinished", { ns: "oee" })}
              style={styles.toggle}
              thumbStyle={styles.thumbOff}
              trackStyle={styles.trackOff}
              labelStyle={styles.labelStyle}
              thumbSwitchedStyle={styles.thumbSwitched}
              trackSwitchedStyle={styles.trackSwitched}
              id={title.replace(" ", "-") + "_" + Math.random()}
              onToggle={(event, toggle) => {
                console.log("Proporcional toggle: ", toggle);
                this.carregarSetupToggle(toggle);
              }}
            />
          </span>
        </span>
      </div>
    );
  }

  toggleSetup(id, toggle) {
    EventEmitter.dispatch(Events.TOGGLE_SETUP, {
      componentID: id,
      toggle: toggle,
    });
  }

  toggleTeep() {
    this.setState({
      toogleTeep: !this.state.toogleTeep,
    });
  }

  oeeType(type) {
    if (type === "teep") {
      let setupSwitch = document.querySelector("input[id^='OEE-']");

      if (setupSwitch && !setupSwitch.checked) {
        setupSwitch.click();
      }
    }

    this.setState({
      oee_selecionado: type,
    });
  }

  checkMostrarZerosOee() {
    this.setState({
      mostrarZerosOee: !this.state.mostrarZerosOee,
    });
  }

  checkMostrarZerosOeeSelecionado() {
    this.setState({
      mostrarZerosOeeSelecionado: !this.state.mostrarZerosOeeSelecionado,
    });
  }

  graficoOeeSelecionado() {
    let {
      oee_selecionado,
      oee_efetivo,
      oee_otimizado,
      teep,
      mostrarZerosOeeSelecionado,
    } = this.state;

    switch (oee_selecionado) {
      case "oee_efetivo":
        return (
          <div style={{ width: "100%" }}>
            <div
              className={`col col-xs-12 ${style.d_none_mobile}`}
              style={{ position: "relative" }}
            >
              <PainelLoader
                loading={oee_efetivo.loading}
                fail={oee_efetivo.fail}
                header={i18n.t("labels.oeeEffective", { ns: "oee" })}
              >
                <CustomCard
                  contentClassName={"flex"}
                  header={defaultHeader(
                    i18n.t("labels.oeeEffective", { ns: "oee" }),
                    null,
                    (event, toggle) => this.toggleSetup("oee_efetivo", toggle),
                    this.oeeType,
                    oee_selecionado,
                    this.checkMostrarZerosOeeSelecionado,
                    mostrarZerosOeeSelecionado,
                  )}
                >
                  <div>
                    {resultadoOEE("oee_efetivo", oee_efetivo.data.resultado)}
                  </div>
                  <div>
                    {composicaoOEE("oee_efetivo", oee_efetivo.data.composicao)}
                  </div>
                  <div className={`${style.d_none_tablet}`}>
                    {diarioOEE(
                      "oee_efetivo",
                      oee_efetivo.data.diario,
                      this.props.diferencaDias,
                      mostrarZerosOeeSelecionado,
                      this.props.granularidade,
                    )}
                  </div>
                </CustomCard>
              </PainelLoader>
            </div>
            <div
              className={`col col-xs-12 ${style.d_block_mobile} `}
              style={{ position: "relative" }}
            >
              <PainelLoader
                loading={oee_efetivo.loading}
                fail={oee_efetivo.fail}
                header={i18n.t("labels.oeeEffective", { ns: "oee" })}
              >
                <CustomCard
                  style={{ height: "auto" }}
                  header={defaultHeader(
                    i18n.t("labels.oeeEffective", { ns: "oee" }),
                    null,
                    (event, toggle) => this.toggleSetup("oee_efetivo", toggle),
                    this.oeeType,
                    oee_selecionado,
                  )}
                >
                  <div className={`${style.horizontal_align_flex}`}>
                    {resultadoOEE("oee_efetivo", oee_efetivo.data.resultado)}
                  </div>
                  <div className={`${style.horizontal_align_flex}`}>
                    {composicaoOEE("oee_efetivo", oee_efetivo.data.composicao)}
                  </div>
                </CustomCard>
              </PainelLoader>
            </div>
          </div>
        );

      case "oee_otimizado":
        return (
          <div style={{ width: "100%" }}>
            <div
              className={`col col-xs-12 ${style.d_none_mobile} `}
              style={{ position: "relative" }}
            >
              <PainelLoader
                loading={oee_otimizado.loading}
                fail={oee_otimizado.fail}
                header={i18n.t("labels.oeeOptimized", { ns: "oee" })}
              >
                <CustomCard
                  contentClassName={"flex"}
                  header={defaultHeader(
                    i18n.t("labels.oeeOptimized", { ns: "oee" }),
                    null,
                    (event, toggle) =>
                      this.toggleSetup("oee_otimizado", toggle),
                    this.oeeType,
                    oee_selecionado,
                    this.checkMostrarZerosOeeSelecionado,
                    mostrarZerosOeeSelecionado,
                  )}
                >
                  <div>
                    {resultadoOEE(
                      "oee_otimizado",
                      oee_otimizado.data.resultado,
                    )}
                  </div>
                  <div>
                    {composicaoOEE(
                      "oee_otimizado",
                      oee_otimizado.data.composicao,
                    )}
                  </div>
                  <div className={`${style.d_none_tablet}`}>
                    {diarioOEE(
                      "oee_otimizado",
                      oee_otimizado.data.diario,
                      this.props.diferencaDias,
                      mostrarZerosOeeSelecionado,
                      this.props.granularidade,
                    )}
                  </div>
                </CustomCard>
              </PainelLoader>
            </div>
            <div
              className={`col col-xs-12 ${style.d_block_mobile} `}
              style={{ position: "relative" }}
            >
              <PainelLoader
                loading={oee_otimizado.loading}
                fail={oee_otimizado.fail}
                header={i18n.t("labels.oeeOptimized", { ns: "oee" })}
              >
                <CustomCard
                  style={{ height: "auto" }}
                  header={defaultHeader(
                    i18n.t("labels.oeeOptimized", { ns: "oee" }),
                    null,
                    (event, toggle) =>
                      this.toggleSetup("oee_otimizado", toggle),
                    this.oeeType,
                    oee_selecionado,
                  )}
                >
                  <div className={`${style.horizontal_align_flex}`}>
                    {resultadoOEE(
                      "oee_otimizado",
                      oee_otimizado.data.resultado,
                    )}
                  </div>
                  <div className={`${style.horizontal_align_flex}`}>
                    {composicaoOEE(
                      "oee_otimizado",
                      oee_otimizado.data.composicao,
                    )}
                  </div>
                </CustomCard>
              </PainelLoader>
            </div>
          </div>
        );

      case "teep":
        let { resultadoTeep, composicaoTeep } = this.receberSetup(teep);
        return (
          <div style={{ width: "100%" }}>
            <div
              className={`col col-xs-12 ${style.d_none_mobile} `}
              style={{ position: "relative" }}
            >
              <PainelLoader
                loading={teep.loading}
                fail={teep.fail}
                header={i18n.t("labels.oeeTeep", { ns: "oee" })}
              >
                <CustomCard
                  contentClassName={"flex"}
                  header={defaultHeader(
                    i18n.t("labels.oeeTeep", { ns: "oee" }),
                    null,
                    (event, toggle) => this.toggleSetup("TEEP", toggle),
                    this.oeeType,
                    oee_selecionado,
                    this.checkMostrarZerosOeeSelecionado,
                    mostrarZerosOeeSelecionado,
                  )}
                >
                  <div>{resultadoOEE("teep", resultadoTeep)}</div>
                  <div>{composicaoOEE("teep", composicaoTeep)}</div>
                  <div className={`${style.d_none_tablet}`}>
                    {diarioOEE(
                      "TEEP",
                      teep.data.diario,
                      this.props.diferencaDias,
                      mostrarZerosOeeSelecionado,
                      this.props.granularidade,
                    )}
                  </div>
                </CustomCard>
              </PainelLoader>
            </div>
            <div
              className={`col col-xs-12 ${style.d_block_mobile} `}
              style={{ position: "relative" }}
            >
              <PainelLoader
                loading={teep.loading}
                fail={teep.fail}
                header={i18n.t("labels.oeeTeep", { ns: "oee" })}
              >
                <CustomCard
                  style={{ height: "auto" }}
                  header={defaultHeader(
                    i18n.t("labels.oeeTeep", { ns: "oee" }),
                    null,
                    (event, toggle) => this.toggleSetup("TEEP", toggle),
                    this.oeeType,
                    oee_selecionado,
                  )}
                >
                  <div className={`${style.horizontal_align_flex}`}>
                    {resultadoOEE("TEEP", teep.data.resultado)}
                  </div>
                  <div className={`${style.horizontal_align_flex}`}>
                    {composicaoOEE("TEEP", teep.data.composicao)}
                  </div>
                </CustomCard>
              </PainelLoader>
            </div>
          </div>
        );
    }
  }

  receberSetup(teep) {
    let resultadoTeep = teep.data.resultado;
    let composicaoTeep = teep.data.composicao;
    resultadoTeep.sem_setup = resultadoTeep.com_setup;
    composicaoTeep.sem_setup = composicaoTeep.com_setup;
    return { resultadoTeep, composicaoTeep };
  }

  elementHeaderRight(title, elementRight) {
    return (
      <div
        style={{ width: "100%" }}
        className={"flex flex-dir-row flex-align-center"}
      >
        {title}
        <span className={"flex flex-1"}></span>
        <span className={"flex flex-2 flex-just-end"}>
          <span style={{ display: "inline-block" }}>{elementRight}</span>
        </span>
      </div>
    );
  }

  carregarDadosApontamentos() {
    const {
      data_inicio,
      data_fim,
      equipamento,
      endpoints,
      openModalApontamento,
      openModal,
      closeModal,
    } = this.props;

    const { apontamentos_timeline } = this.state;

    if (apontamentos_timeline.firstRequest) {
      openModal("loading_apotamento_timeline");
    }

    request(
      endpoints.APONTAMENTOS_EQUIPAMENTO +
        `/${equipamento[0]}/${data_inicio}/${data_fim}/data-hora?limit=${
          this.paginacao.ITENS_POR_PAGINA_APONTAMENTOS_TIMELINE
        }&offset=${
          apontamentos_timeline.paginationPage *
          this.paginacao.ITENS_POR_PAGINA_APONTAMENTOS_TIMELINE
        }`,
      { method: "get" },
    )
      .then((response) => {
        if (response.status === 200) return response.json();
        return closeModal();
      })
      .then((data) => {
        const notes = [...apontamentos_timeline.data, ...data];
        let apontOrdenado = Util.ordenaApontamentos(notes);

        this.setState(
          {
            ...this.state,
            apontamentos_timeline: {
              firstRequest: false,
              data: apontOrdenado,
              hasMoreLotes:
                data.length ===
                this.paginacao.ITENS_POR_PAGINA_APONTAMENTOS_TIMELINE,
              paginationPage: apontamentos_timeline.paginationPage + 1,
            },
          },
          () => {
            openModalApontamento({
              ...this.state.apontamentos_timeline,
              carregarApontamentos: this.carregarDadosApontamentos,
            });
          },
        );
      });
  }

  callModalPareto() {
    const {
      data_inicio,
      data_fim,
      inicio_turno,
      fim_turno,
      openModalPareto,
      turnoPersonalizado,
    } = this.props;
    const { pareto } = this.state;

    let mapaUrl = this.getTurnoEquipamentoComposicaoUrl();

    openModalPareto(
      pareto.data,
      `${data_inicio}/${data_fim}/${turnoPersonalizado}?${mapaUrl.equipamentoUrl}&${mapaUrl.turnoUrl}&dataInicioTurno=${inicio_turno}&dataFimTurno=${fim_turno}`,
    );
  }

  customHeaderPareto() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span>Pareto</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "80%",
            justifyContent: "space-between",
          }}
        >
          <SelectField
            style={{ margin: "-4px 0px 0px 0px", height: 42, width: 200 }}
            underlineFocusStyle={{ borderColor: "#000000" }}
            labelStyle={{ color: "#5B5B5B", fontSize: "15px" }}
            selectedMenuItemStyle={{ fontWeight: "bold" }}
            value={this.state.filtro_pareto_tempo}
            onChange={(...props) => {
              this.carregarParetoFiltro(
                props[2],
                this.state.filtro_pareto_tipo_evento,
              );
              this.setState({ filtro_pareto_tempo: props[2] });
            }}
          >
            <MenuItem
              value={0}
              primaryText={i18n.t("labels.downTime", { ns: "oee" })}
            />
            <MenuItem
              value={1}
              primaryText={i18n.t("labels.totalTime", { ns: "oee" })}
            />
          </SelectField>

          <SelectField
            style={{ margin: "-4px 0px 0px 0px", height: 42, width: 200 }}
            underlineFocusStyle={{ borderColor: "#000000" }}
            labelStyle={{ color: "#5B5B5B", fontSize: "15px" }}
            selectedMenuItemStyle={{ fontWeight: "bold" }}
            value={this.state.filtro_pareto_tipo_evento}
            onChange={(...props) => {
              this.carregarParetoFiltro(
                this.state.filtro_pareto_tempo,
                props[2],
              );
              this.setState({ filtro_pareto_tipo_evento: props[2] });
            }}
          >
            <MenuItem
              value={0}
              primaryText={i18n.t("labels.allEvents", { ns: "oee" })}
            />
            <MenuItem
              value={1}
              primaryText={i18n.t("labels.considered", { ns: "oee" })}
            />
            <MenuItem
              value={2}
              primaryText={i18n.t("labels.disregarded", { ns: "oee" })}
            />
          </SelectField>

          <span style={{ display: "inline-block" }}>
            <div className="tooltip">
              <i
                className="material-icons cursor-pointer"
                onClick={() => this.callModalPareto()}
              >
                search
              </i>
              <div
                className="tooltiptext"
                style={{ width: "45px", left: "-45px" }}
              >
                Detalhar
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
  painelOEE() {
    let {
      oee,
      manutencao,
      pareto,
      timeline,
      oee_selecionado,
      setup,
      mostrarZerosOee,
    } = this.state;
    const { openModalPareto } = this.props;

    return (
      <div
        style={{ height: "100%", width: "100%" }}
        className={"flex flex-dir-row flex-wrap"}
      >
        <div className="panel panel_mobile col-no col-xs-12 col-md-12 col-lg-12 col-xl-8 flex flex-wrap flex-just-start">
          <div
            className={`col col-xs-12  ${style.d_none_mobile}`}
            style={{ position: "relative" }}
          >
            <PainelLoader
              loading={oee.loading}
              fail={oee.fail}
              header={i18n.t("title", { ns: "oee" })}
            >
              <CustomCard
                contentClassName={"flex"}
                header={defaultHeader(
                  i18n.t("title", { ns: "oee" }),
                  null,
                  (event, toggle) => this.toggleSetup("oee", toggle),
                  this.oeeType,
                  oee_selecionado,
                  this.checkMostrarZerosOee,
                  this.state.mostrarZerosOee,
                  this.props.buttonsGranularidade,
                )}
              >
                <div className={`${style.horizontal_align_flex}`}>
                  {resultadoOEE("oee", oee.data.resultado)}
                </div>
                <div className={`${style.horizontal_align_flex}`}>
                  {composicaoOEE("oee", oee.data.composicao)}
                </div>
                <div
                  style={{ marginLeft: STYLES.MARGIN_LEFT }}
                  className={`${style.d_none_tablet}`}
                >
                  <div className={`${style.d_none_tablet}`}>
                    {diarioOEE(
                      "oee",
                      oee.data.diario,
                      this.props.diferencaDias,
                      mostrarZerosOee,
                      this.props.granularidade,
                    )}
                  </div>
                </div>
              </CustomCard>
            </PainelLoader>
          </div>
          <div
            className={`col col-xs-12 ${style.d_block_mobile} `}
            style={{ position: "relative" }}
          >
            <PainelLoader
              loading={oee.loading}
              fail={oee.fail}
              header={i18n.t("title", { ns: "oee" })}
            >
              <CustomCard
                style={{ height: "auto" }}
                header={defaultHeader(
                  i18n.t("title", { ns: "oee" }),
                  null,
                  (event, toggle) => this.toggleSetup("oee", toggle),
                  this.oeeType,
                  oee_selecionado,
                  this.checkMostrarZerosOee,
                  this.state.mostrarZerosOee,
                )}
              >
                <div className={`${style.horizontal_align_flex}`}>
                  {resultadoOEE("oee", oee.data.resultado)}
                </div>
                <div className={`${style.horizontal_align_flex}`}>
                  {composicaoOEE("oee", oee.data.composicao)}
                </div>
              </CustomCard>
            </PainelLoader>
          </div>

          {this.graficoOeeSelecionado()}

          <div
            className={`col col-xs-12 col-sm-6 col-lg-7 col-xl-7 ${style.d_none_mobile}`}
            style={{ position: "relative" }}
          >
            <PainelLoader
              fail={setup.fail}
              loading={setup.loading}
              header={this.customHeaderSetup(
                i18n.t("labels.setup", { ns: "oee" }),
                setup,
              )}
            >
              <CustomCard
                style={{ flex: 1 }}
                contentStyle={{ padding: "10px 0" }}
                header={this.customHeaderSetup(
                  i18n.t("labels.setup", { ns: "oee" }),
                  setup,
                )}
              >
                {setup.data.resultado && (
                  <div
                    className="row"
                    style={{
                      height: "100%",
                      margin: 0,
                      padding: 0,
                      flexWrap: "initial",
                    }}
                  >
                    <div
                      className={"col-md-5"}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <SetupHalfDoughnutChart
                        data={setup.data.resultado}
                      ></SetupHalfDoughnutChart>
                    </div>
                    <div
                      className="setup-width-ajust graph_position col-md-7"
                      style={{
                        maxHeight: STYLES.MAX_SCROLL_DIV_HEIGHT,
                        overflowY: "auto",
                        position: "sticky",
                      }}
                      id="scroll-style"
                    >
                      {setupBarChart(setup.data.eventos)}
                    </div>
                  </div>
                )}

                {!setup.data.resultado && this.customEmptyContentSetup()}
              </CustomCard>
            </PainelLoader>
          </div>

          {setup.data.resultado ? (
            <div className={`col col-xs-12 ${style.d_block_mobile}`}>
              <CustomCard
                header={i18n.t("labels.setup", { ns: "oee" })}
                style={{ height: "550px" }}
              >
                <div className="row">
                  <div className="col col-xs-12 flex_center flex flex-just-center">
                    <SetupHalfDoughnutChart
                      data={setup.data.resultado}
                    ></SetupHalfDoughnutChart>
                  </div>
                  <div
                    className={` col-xs-12 setup-width-ajust custom_chart_height content_height ${style.content_height}`}
                    style={{ position: "relative" }}
                    id="scroll-style"
                  >
                    {setupBarChart(setup.data.eventos)}
                  </div>
                </div>
              </CustomCard>
            </div>
          ) : (
            <div />
          )}

          <div className={"col col-xs-12 col-sm-6 col-lg-5 col-xl-5"}>
            <PainelLoader
              loading={manutencao.loading}
              fail={manutencao.fail}
              header={i18n.t("labels.maintenance", { ns: "oee" })}
            >
              <CustomCard
                header={i18n.t("labels.maintenance", { ns: "oee" })}
                style={{ height: STYLES.MAX_DIV_HEIGHT }}
                contentStyle={{ minHeight: STYLES.MAX_SCROLL_DIV_HEIGHT }}
              >
                {manutencaoBarChart(manutencao.data)}
              </CustomCard>
            </PainelLoader>
          </div>
        </div>

        <div className="panel panel_mobile col-no col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 flex flex-wrap flex-just-start">
          <div className="col box-timeline col-xs-12 col-lg-6 col-xl-12">
            <PainelLoader
              loading={timeline.loading}
              fail={timeline.fail}
              header={i18n.t("labels.timeline", { ns: "oee" })}
            >
              <CustomCard
                id="card-timeline"
                className="card-timeline"
                header={this.elementHeaderRight(
                  i18n.t("labels.timeline", { ns: "oee" }),
                  timeline.data ? (
                    <img
                      className="cursor-pointer"
                      onClick={() => this.carregarDadosApontamentos()}
                      src={require("../../assets/icon-timeline-report-note.svg")}
                    />
                  ) : null,
                )}
                tooltip={timeline.tooltip}
              >
                <TimeLineChart {...{ STYLES }} data={timeline.data} />
              </CustomCard>
            </PainelLoader>
          </div>

          <div className="col col-xs-12 col-lg-6 col-xl-12">
            <PainelLoader
              loading={pareto.loading}
              fail={pareto.fail}
              header={this.customHeaderPareto()}
            >
              <CustomCard id="card-pareto" header={this.customHeaderPareto()}>
                <div
                  style={{
                    maxHeight: STYLES.MAX_SCROLL_DIV_HEIGHT,
                    overflowY: "scroll",
                  }}
                  id="scroll-style"
                >
                  <ParetoBarChart data={pareto.data}></ParetoBarChart>
                </div>
              </CustomCard>
            </PainelLoader>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loaded } = this.state;
    return <div id="painel_oee">{loaded ? this.painelOEE() : null}</div>;
  }
}

export default PainelOEE;

