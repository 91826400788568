import { AutoComplete } from 'common/MaterialForm';
import { NButton, NButtonGroup, NForm, NFormTitle } from 'common/NForm';
import { request, requestRelatorioXLSX } from 'common/request';
import { RaisedButton } from 'material-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import { APIEndpoints } from '../../common/Constants';
import { orderBy } from 'lodash';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';

const ModalRelatorioSensores = ({
    closeModal
}) => {
    const { t } = useTranslation('reports');
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState();
    const [equipment, setEquipment] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const [optFileExtension, setOptFileExtension] = useState([]);
    const [fileExtension, setFileExtension] = useState('');
    const [selectedRange, setSelectedRange] = useState(
        {
            start: moment().startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        });

    useEffect(() => {
        setLoading(true);

        setOptFileExtension(['PDF', 'XLSX']);

        Promise.all([
            loadEquipment(),
            loadEquipmentGroups(),
        ]).then((response) => {
            let result = orderBy(response[1], ['name'], 'asc');
            result = result.concat(orderBy(response[0], ['name'], 'asc'));

            setEquipment(result);
        }).finally(() => setLoading(false))
    }, []);

    const loadEquipment = useCallback(() => {
        return request(APIEndpoints.EQUIPAMENTOS, { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { return data.map(c => ({ id: c.id, name: c.nome, isGroup: false })) });
    }, [])

    const loadEquipmentGroups = useCallback(() => {
        return request(APIEndpoints.GRUPO_EQUIPAMENTO + '?comEquipamento=true', { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { if (data) return data.map(c => ({ id: c.id, uuid: c.uuid, name: c.nome, isGroup: true, equipamentos: c.equipamentos })) });
    }, [])

    const handleEquipmentSelect = (searchText) => {
        var group = equipment.filter(e => e.name.trim() == searchText.trim() && e.isGroup)[0];

        let equipmentIds = [];
        let groupId;

        if (group) {
            equipmentIds = group.equipamentos.map(e => e.id);
            groupId = group.uuid;
        } else {
            let eq = equipment.filter(e => e.name.trim() == searchText.trim() && !e.isGroup)[0];

            if (eq) {
                equipmentIds.push(eq.id);
            }
            groupId = 0;
        }

        setSelectedEquipment(equipmentIds);
    }

    const submit = () => {
        var erroCampo = false;

        if (!erroCampo) {
            setSending(true);

            let startDate = moment(selectedRange.start).format('YYYY-MM-DDTHH:mm:ss');
            let endDate = moment(selectedRange.end).format('YYYY-MM-DDTHH:mm:ss')

            let equipamentos = selectedEquipment.map((e, i) => {
                return `equipmentIds[${i}]=${e}`;
            }).join('&');

            let params = `?startDate=${startDate}&endDate=${endDate}&${equipamentos}&format=${fileExtension}`;

            return requestRelatorioXLSX(
                APIEndpoints.RELATORIO_SENSORES + params,
                t("titles.sensorsReport"),
                null,
                fileExtension)
                .then(() => handleCloseModal())
                .catch(error => {
                    console.log(error);
                    setError({
                        message: t("errors.cannotGenerateReport")
                    })
                })
                .finally(() => setSending(false));
        }
    }

    const handleSelectPeriod = (range) => {
        setSelectedRange(range);
    }

    const handleFileExtension = (type) => {
        setFileExtension(type.toLowerCase());
    }

    const handleCloseModal = () => {
        closeModal ? closeModal() : null;
    }

    const modalError = () => {
        return (
            <div className="modal" style={{ width: '500px', height: '100%' }}>
                <div>
                    <div style={{ textAlign: 'left', fontWeight: 'bold' }} >
                        {t("labels.warning", { ns: 'common' })}
                    </div>
                    <div>
                        <i className="fa fa-close cursor-pointer" onClick={handleCloseModal} />
                    </div>
                </div>
                <div style={{ textAlign: 'left' }} >
                    {error.message}
                </div>
                {
                    error.support ?
                        <div style={{ paddingTop: '1em' }} >
                            E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
                        </div>
                        :
                        null
                }
                <NButtonGroup>
                    <NButton style={{ backgroundColor: '#2D4F7F', color: 'white' }} onClick={handleCloseModal}>OK</NButton>
                </NButtonGroup>
            </div>
        )
    }

    return <>
        {loading ?
            <div className="report_sensores">
                <div className={'loading-wrapper'}>
                    <MDSpinner singleColor='#2D4F7F' size={60} />
                </div>
            </div>
            :
            error ?
                modalError()
                :
                <div className="report_sensores">
                    <NForm style={{ minWidth: '36em' }}>
                        <NFormTitle>{t("titles.sensorsReport")}</NFormTitle>
                        <div className={'flex flex-flow-col flex-just-between'} style={{ marginTop: '-2em' }}>
                            <span style={{ paddingTop: '1em' }}>
                                <AutoComplete
                                    textFieldStyle={{ marginTop: '-10px' }}
                                    fullWidth={true}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    floatingLabelStyle={{ color: '#5B5B5B' }}
                                    listStyle={{ width: '100%' }}
                                    floatingLabelText={t("labels.equipment")}
                                    title={t("labels.equipment")}
                                    onUpdateInput={handleEquipmentSelect}
                                    filter={AutoComplete.fuzzyFilter}
                                    dataSource={equipment}
                                    dataSourceConfig={{ value: 'id', text: 'name' }}
                                    openOnFocus={true}
                                    popoverProps={{ className: 'autocomplete' }} />
                            </span>
                        </div>

                        <div className={'flex flex-flow-col flex-just-between'}>
                            <span>
                                <AutoComplete
                                    textFieldStyle={{ marginTop: '-10px' }}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    floatingLabelStyle={{ color: '#5B5B5B' }}
                                    listStyle={{ width: '100%' }}
                                    floatingLabelText={t("labels.fileExtension")}
                                    title={t("labels.fileExtension")}
                                    onUpdateInput={handleFileExtension}
                                    filter={AutoComplete.fuzzyFilter}
                                    dataSource={optFileExtension}
                                    dataSourceConfig={{ value: 'name', text: 'name' }}
                                    openOnFocus={true}
                                    popoverProps={{ className: 'autocomplete', style: { height: '10px' } }} />
                            </span>
                        </div>

                        <h3 style={{ paddingTop: '1.5em', paddingBottom: '0.5em', fontSize: '.8em', textTransform: 'uppercase' }}>
                            <span>{t("labels.selectPeriod")}</span>
                        </h3>

                        <DateRangePicker onSelectRange={handleSelectPeriod} />

                        <div className={'flex flex-just-end'}>
                            <RaisedButton
                                onClick={submit}
                                disabled={selectedEquipment.length == 0 || fileExtension == '' || sending}
                                backgroundColor='#2D4F7F'
                                buttonStyle={{ fontFamily: '' }}
                                style={{ marginRight: '1em' }}
                                labelColor='#FFF'
                                label={t("labels.generate", { ns: 'common' })} />
                            <RaisedButton onClick={handleCloseModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} label={t("labels.cancel", { ns: 'common' })} />
                        </div>
                    </NForm>
                </div>

        }
    </>
}

export default ModalRelatorioSensores;