import { observable, action, computed, toJS } from "mobx";
import moment from 'moment';
import update from 'immutability-helper';
import { APIEndpoints } from '../../common/Constants';
import { request } from "../../common/request";
import Utils from "../Utils/Utils";
import { alertBox } from '../../common/NForm';
import i18n from '../../utils/i18n';

class Rotas extends Utils {
    //arrayItensCommon  ocar o nome para state
    @observable ativo = true;
    @observable novo = false;
    @observable data = '';
    @observable horaInicial = '';
    @observable id = 0
    @observable initialItem = 0;
    @observable observacoes = '';
    @observable originalUuid = '';
    @observable originalId = 0;
    @observable destinoId = 0;
    @observable arrayItensCommon = [];
    @observable timeMachineSelected = {};


    // Verifico se o estado antigo já foi alterado para que não altere novamente
    _checkIfStateChanged(oldState, indexAt) {
        // Realizo a verificação com condicional para evitar o undefined
        return oldState[indexAt].alterado ? true : false
    }

    _updateCurrentState(index, state, onChangeScrollbars) {
        let DataFimProcessada = index < (state.length - 1) ? state[index + 1].dataEfetivo : null

        // Verifico se a data final foi alterada
        let changedData = DataFimProcessada != null ? true : false

        let changed = !onChangeScrollbars || this._checkIfStateChanged(state, index)
        changed = changedData || changed

        let newState = update(state, {
            [index]: {
                text: { $set: moment(this.data).format('L') },
                ativo: { $set: this.ativo },
                dataEfetivo: { $set: this.data },
                observacao: { $set: this.observacoes },
                alterado: { $set: changed },
                ultimaCadastrada: { $set: state[index].vigenciaAtiva },
                destinoId: { $set: state[index].destinoId }
            }
        })

        this.setArrayItensCommon(newState);
    }

    _getFinishDate(pointer) {
        let arrayDataFim = this._createArrayEnd()
        return arrayDataFim[pointer]
            ? arrayDataFim[pointer].date
            : null
    }

    _resetNewItensId(dataToSend) {
        return dataToSend.map((data, index) => {
            let DataFimProcessada = index < (dataToSend.length - 1) ? dataToSend[index + 1].dataEfetivo : null;

            return {
                originalId: data.originalId,
                originalUuid: data.originalUuid,
                dataEfetivo: moment(data.dataEfetivo).format('YYYY-MM-DD HH:mm:ss'),
                dataFim: DataFimProcessada != null ? moment(DataFimProcessada).format('YYYY-MM-DD HH:mm:ss') : null,
                ativo: data.ativo,
                id: data.novo ? 0 : data.id,
                observacao: data.observacao,
                destino_id: data.destinoId
            }
        })
    }

    _submit(values, method, url, header) {
        return request(`${APIEndpoints.TIMEMACHINE}${url}`, {
            method: method,
            headers: header,
            body: JSON.stringify(values)
        }).then(response => {
            if (response.status === 200) {
                alertBox(i18n.t("stepOne.messages.termSaveSuccess", { ns: 'routes' }), i18n.t("labels.success", { ns: 'common' }), 'success');
                return response.json();
            }
        }).then(data => data);
    }

    _validateDataToSend() {
        var ok = []
        var state = this.arrayItensCommon;

        state.forEach((item, index) => {
            let currentItem = moment(item.dataEfetivo).format('YYYY-MM-DD HH:mm:ss')
            let prevItem = state[(index - 1)] ? moment(state[(index - 1)].dataEfetivo).format('YYYY-MM-DD HH:mm:ss') : null
            let nextItem = state[(index + 1)] ? moment(state[(index + 1)].dataEfetivo).format('YYYY-MM-DD HH:mm:ss') : null

            // Verifico se é o primeiro
            if (prevItem == null) {

                if (currentItem >= nextItem) {
                    super.showAlert('Data/hora informada é maior que a data/hora da proxima vigência.', 'Início de vigência inválido', 'error')
                    ok.push(false);
                }

                // Verifico se é o ultimo ou se o primeiro tem proximo
            } else if (nextItem == null) {

                if (currentItem <= prevItem) {
                    super.showAlert('Data/hora informada é menor que a data/hora da vigência anterior.', 'Início de vigência inválido', 'error')
                    ok.push(false);
                }

            } else {
                // Testo os demais casos
                if (currentItem >= nextItem) {
                    super.showAlert('Data/hora informada é maior que a data/hora da proxima vigência.', 'Início de vigência inválido', 'error')
                    ok.push(false);

                }
                if (currentItem <= prevItem) {
                    super.showAlert('Data/hora informada é menor que a data/hora da vigência anterior.', 'Início de vigência inválido', 'error')
                    ok.push(false);
                }
            }

        })

        return !ok.length
    }

    _validateData(saveState) {
        if (!saveState) return false;

        var state = this.arrayItensCommon;

        let indexItem = state.findIndex(item => item.id == this.id)
        let currentItem = moment(state[indexItem].dataEfetivo).format('YYYY-MM-DD HH:mm:ss')
        let prevItem = state[(indexItem - 1)] ? moment(state[(indexItem - 1)].dataEfetivo).format('YYYY-MM-DD HH:mm:ss') : null
        let nextItem = state[(indexItem + 1)] ? moment(state[(indexItem + 1)].dataEfetivo).format('YYYY-MM-DD HH:mm:ss') : null

        // Verifico se é o primeiro
        if (prevItem == null) {

            if (currentItem >= nextItem) {
                super.showAlert('Data/hora informada é maior que a data/hora da proxima vigência.', 'Início de vigência inválido', 'error')
            }

            // Verifico se é o ultimo ou se o primeiro tem proximo
        } else if (nextItem == null) {

            if (currentItem < prevItem) {
                super.showAlert('Data/hora informada é menor que a data/hora da vigência anterior.', 'Início de vigência inválido', 'error')
            }

        } else {
            // Testo os demais casos
            if (currentItem >= nextItem) {
                super.showAlert('Data/hora informada é maior que a data/hora da proxima vigência.', 'Início de vigência inválido', 'error')
            }
            if (currentItem <= prevItem) {
                super.showAlert('Data/hora informada é menor que a data/hora da vigência anterior.', 'Início de vigência inválido', 'error')
            }
        }
    }


    @action
    setInitialAttributes = itens => {
        let itemActive = itens.find((item, index) => item.ativo);

        if (!itemActive) {

            let hora = moment(itens[0].dataEfetivo).format('HH')
            let minuto = moment(itens[0].dataEfetivo).format('mm')
            let segundo = moment(itens[0].dataEfetivo).format('ss')

            this.setHoraInicial(hora + ':' + minuto + ':' + segundo)
            this.initialItem = itens[0]
            this.ativo = itens[0].ativo
            this.data = itens[0].dataEfetivo;
            this.observacoes = itens[0].observacao;
            this.id = itens[0].id;
            this.originalId = itens[0].originalId;
            this.destinoId = itens[0].destinoId;
            this.originalUuid = itens[0].originaluuid;

            this.setArrayItensCommon(itens)

            return;
        }

        this.ativo = itemActive.ativo;
        this.data = itemActive.dataEfetivo;
        this.observacoes = itemActive.observacao;
        this.id = itemActive.id;
        this.originalId = itemActive.originalId;
        this.destinoId = itemActive.destinoId;
        this.originalUuid = itemActive.originaluuid;

        //set pego o item selecionado para dar selected
        this.initialItem = itens.findIndex((item) => item.ativo);
        // Set o array que estou trabalhando entre as states

        this.setArrayItensCommon(itens)
    }


    @action
    saveValues = (onChangeScrollbars) => {
        let stateArray = this.arrayItensCommon.slice()
        let indexItem = stateArray.findIndex(item => item.id == this.id)

        this._updateCurrentState(indexItem, stateArray, onChangeScrollbars)
    }

    @action
    showValuesOnUpdate = item => {
        let hora = moment(item.dataEfetivo).format('HH')
        let minuto = moment(item.dataEfetivo).format('mm')
        let segundo = moment(item.dataEfetivo).format('ss')

        this.setHoraInicial(hora + ':' + minuto + ':' + segundo)
        this.setAtivo(item.ativo)
        this.setData(item.dataEfetivo)
        this.setObservacoes(item.observacao)
        this.setId(item.id)
    }

    @action
    saveServer(vigenciaAtual) {
        let ok = this._validateDataToSend();

        return new Promise((resolve, reject) => {
            //Filtro apenas os dados que ocorreram mudanças e mando para o server
            if (ok) {
                let rawData = toJS(this.arrayItensCommon);
                let data = this._resetNewItensId(rawData);

                this._submit(data,
                    'post',
                    `/rotas/vigencia/${vigenciaAtual.originalId}`,
                    { 'Content-Type': 'application/json' }
                ).then(() => {
                    resolve();
                }).catch((err) => {
                    reject({ type: 'network', error: err });
                })
            } else {
                reject({ type: 'validation' });
            }
        })
    }

    // Setters
    @action
    setObservacoes = (observacoes, saveState) => {
        this.observacoes = observacoes
        if (saveState) this.saveValues()
    }

    @action
    setAtivo = (ativo, saveState) => {
        this.ativo = ativo
        if (saveState) this.saveValues()
    }

    @action
    setData = (data, saveState) => {
        this.data = data
        if (saveState) this.saveValues()
        this._validateData(saveState)

    }

    @action
    setHoraInicial = (horaInicial, saveState) => {
        this.horaInicial = horaInicial
        let tempo = horaInicial.split(':')
        let hora = tempo[0] || '00'
        let minuto = tempo[1] || '00'
        let segundo = tempo[2] || '00'
        let newData = moment(this.data)
        newData.set({ h: hora, m: minuto, s: segundo })
        this.setData(newData)

        if (saveState) this.saveValues()
    }

    @action
    setArrayItensCommon = arrayItensCommon => {
        this.arrayItensCommon = arrayItensCommon
    }

    @action
    setId = id => {
        this.id = id
    };

    @action
    setOriginalId = originalId => {
        this.originalId = originalId;
    }

    @action
    setOriginaUuid = originalUuid => {
        this.originalUuid = originalUuid;
    }

    @action
    setTimeMachineSelected = timeMachineSelected => {
        this.timeMachineSelected = timeMachineSelected
    }

    // Getters
    @computed get Ativo() {
        return this.ativo;
    }

    @computed get Data() {
        return this.data;
    }

    @computed get HoraInicial() {
        return this.horaInicial;
    }

    @computed get InitialItem() {
        return this.initialItem;
    }

    @computed get ArrayItensCommon() {
        return this.arrayItensCommon;
    }

    @computed get Id() {
        return this.id;
    }

    @computed get Observacoes() {
        return this.observacoes;
    }
    @computed get OriginalUuid() {
        return this.originalUuid;
    }

    @computed get OriginalId() {
        return this.originalId;
    }

    @computed get TimeMachineSelected() {
        return this.timeMachineSelected;
    }
}

export default Rotas;