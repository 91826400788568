import React, { Component } from 'react';
import { Card, CardHeader, CardText, FloatingActionButton } from 'material-ui';
import { ActionSettingsBackupRestore, NotificationWifi } from 'material-ui/svg-icons';
import FilterBox from '../../common/FilterBox';
import PipefyTable from '../../common/Tables/PipefyTable';
import moment from 'moment';
import { request } from '../../common/request';
import { respostaDadosColeta } from '../../common/mappers';
import MDSpinner from "react-md-spinner";
import wifiOff from '../../assets/icons/wifi_off.svg';

moment.locale('pt-br');

export default class DataCollectionList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header: [
                {
                    title: '#',
                    key: 'id'
                },
                {
                    title: this.props.t('labels.equipmentTAG'),
                    key: 'equipment',
                    width: '200px'
                },
                {
                    title: this.props.t('labels.modeVersion'),
                    key: 'version'
                },
                {
                    title: this.props.t('labels.user'),
                    key: 'username'
                },
                {
                    title: this.props.t('labels.sessionTime'),
                    key: 'session_time'
                },
                {
                    title: this.props.t('labels.changeUserForm'),
                    key: 'switch_user_screen'
                },
                {
                    title: this.props.t('labels.lastSync'),
                    key: 'last_communication'
                },
                {
                    title: this.props.t('labels.lastEvent'),
                    key: 'last_note'
                }
            ],
            dadosColeta: this.props.dadosColeta,
            pesquisa: this.props.dadosColeta,
            loadingPage: false
        };

        this.onFilter = this.onFilter.bind(this);
    }

    onFilter(value) {
        let result;
        let searchValue = value.replace(/[^a-z0-9 ]/gi, '');

        let regex = new RegExp(searchValue, 'gi');
        result = this.state.dadosColeta.filter((p) => {
            return regex.test(p.equipamento.name) ||
                regex.test(p.equipamento.tag) ||
                regex.test(p.user.name)
        });[]

        var resultado = result.length != 0 ? result : this.state.dadosColeta;

        this.setState({
            pesquisa: resultado
        });
    }

    getStatusIcon = (date) => {
        if (moment(date).isAfter(moment().subtract(1, 'hours'))) {
            return <NotificationWifi color={'#14c900'} style={{ verticalAlign: 'middle', marginRight: '10px' }} />;
        }

        return (<img src={wifiOff} style={{ width: '24px', height: '24px', verticalAlign: 'middle', marginRight: '10px' }} />)
    }

    mapTableData = (data) => (
        data.map((data, i) => (
            {
                id: i + 1,
                equipamento: [this.getStatusIcon(data.last_communication), data.equipamento.name, <br />, <span style={{ marginLeft: '34px' }}>{data.equipamento.tag}</span>],
                version: [data.collect_mode, <br />, <span className={'color-mute'}>{data.version} {data.version_number ? '(' + (data.current_version ? '' : '*') + data.version_number + ')' : ''}</span>],
                usuario: data.user.name,
                session_time: data.session_time,
                switch_user_screen: data.switch_user_screen != null ? data.switch_user_screen ? this.props.t('labels.yes') : this.props.t('labels.no') : '-',
                last_communication: data.last_communication != '' ?
                    [<span title={moment(data.last_communication).format('L LT')}>{moment(data.last_communication).fromNow()}</span>] : '-',
                last_note: data.last_note != '' ? moment(data.last_note).format('L LT') : '-'
            }
        ))
    )

    atualizarDadosDeColeta() {
        this.setState({ loadingPage: true });

        request(this.props.endpoints.DADOS_COLETA, {
            method: 'get'
        })
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                }
            }).then(data => {
                this.setState({
                    pesquisa: respostaDadosColeta(data),
                    dadosColeta: respostaDadosColeta(data),
                    loadingPage: false
                })
            });
    }

    render() {
        return (
            <div className='box_list'>
                <Card className='box_list_card'>
                    <FloatingActionButton className={'floating_action'} backgroundColor={'#0C2340'} onClick={() => this.atualizarDadosDeColeta()}>
                        <ActionSettingsBackupRestore />
                    </FloatingActionButton>
                    <CardHeader className='box_header' title={this.props.t('labels.dataCollection')} />
                    <CardText className={!this.state.loadingPage ? 'table_overflow' : ''}>
                        <FilterBox onFilter={this.onFilter} />
                        {
                            this.state.loadingPage ?
                                <div className={'loading-wrapper'}>
                                    <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
                                </div>
                                :
                                <PipefyTable header={this.state.header} data={this.mapTableData(this.state.pesquisa)} />
                        }
                    </CardText>
                </Card>
            </div>
        );
    }
}
