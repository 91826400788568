import React, { useEffect, useState } from 'react';
import { TextField } from 'material-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'muicss/react';

import styles from './passwordField.scss';

const PasswordField = ({
    onChangePassword,
    onChangeConfirmPassword,
    passwordLabel,
    passwordConfirmLabel,
    defaultValue,
    usernameRule,
    onValidPassword,
    onInvalidPassword,
    bootstrap = false,
    strongPassword
}) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { t } = useTranslation('components');
    const [currentUsername, setCurrentUsername] = useState();
    const [validationRules, setValidationRules] = useState([]);

    useEffect(() => {
        setPassword(defaultValue || '');

        document.getElementById('password').value = defaultValue || '';
        document.getElementById('confirmPassword').value = defaultValue || '';

        if (strongPassword) {
            setValidationRules([
                {
                    rule: 'differentFromLogin',
                    isValid: (password, { username }) => {
                        return password !== '' && username !== password
                    },
                },
                {
                    rule: 'atLeastOneLowercase',
                    isValid: (password) => /(?=.*[a-z])/.test(password)
                },
                {
                    rule: 'atLeastOneUppercase',
                    isValid: (password) => /(?=.*[A-Z])/.test(password)
                },
                {
                    rule: 'atLeastOneDigit',
                    isValid: (password) => /(?=.*\d)/.test(password)
                },
                {
                    rule: 'passwordMinLength',
                    isValid: (password) => password.length >= 8
                },
                {
                    rule: 'passwordsMustMatch',
                    isValid: (password, { confirmPassword }) => password !== '' && confirmPassword !== '' && password === confirmPassword
                }
            ]);
        }
        else {
            setValidationRules([
                {
                    rule: 'passwordsMustMatch',
                    isValid: (password, { confirmPassword }) => password !== '' && confirmPassword !== '' && password === confirmPassword
                }
            ]);
        }
    }, [defaultValue, strongPassword]);

    useEffect(() => {
        if (usernameRule) {
            setCurrentUsername(usernameRule);
        }
    }, [usernameRule])

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);

        if (onChangePassword)
            onChangePassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);

        if (onChangeConfirmPassword)
            onChangeConfirmPassword(e.target.value);
    };

    useEffect(() => {
        if (validatePassword()) {
            onValidPassword && onValidPassword(password);
        } else {
            onInvalidPassword && onInvalidPassword(password);
        }
    }, [password, confirmPassword]);

    const validatePassword = () => {
        if (password.trim() === '') {
            return false
        }

        if (password !== confirmPassword) {
            return false;
        }

        return validationRules.every((result) => result.isValid(password, { currentUsername, confirmPassword }));
    };

    const renderValidationRules = () => {
        return (
            <ul className={styles.passwordRulesList}>
                {validationRules.map((result, index) => (
                    <li key={index} style={{ color: result.isValid(password, { username: currentUsername, confirmPassword }) ? 'green' : '#f44336' }}>
                        {t(`passwordField.rules.${result.rule}`)}
                    </li>
                ))}
            </ul>
        )
    }

    return <>
        {
            bootstrap ?
                <>
                    <Row>
                        <Col lg={6}>
                            <TextField
                                id='password'
                                style={{ width: '100%' }}
                                underlineFocusStyle={{ borderColor: '#000000' }}
                                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                                floatingLabelText={passwordLabel || t("passwordField.passwordLabel")}
                                onChange={handlePasswordChange}
                                defaultValue={defaultValue}
                                type='password'
                            />
                        </Col>
                        <Col lg={6}>
                            <TextField
                                id='confirmPassword'
                                style={{ width: '100%' }}
                                underlineFocusStyle={{ borderColor: '#000000' }}
                                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                                floatingLabelText={passwordConfirmLabel || t("passwordField.passwordConfirmLabel")}
                                onChange={handleConfirmPasswordChange}
                                type='password'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {renderValidationRules()}
                        </Col>
                    </Row>
                </>
                :
                <div>
                    <TextField
                        id='password'
                        style={{ width: '100%' }}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                        floatingLabelText={passwordLabel || t("passwordField.passwordLabel")}
                        onChange={handlePasswordChange}
                        defaultValue={defaultValue}
                        type='password'
                    />

                    <TextField
                        id='confirmPassword'
                        style={{ width: '100%' }}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                        floatingLabelText={passwordConfirmLabel || t("passwordField.passwordConfirmLabel")}
                        onChange={handleConfirmPasswordChange}
                        type='password'
                    />

                    {renderValidationRules()}
                </div>
        }
    </>

};

export default PasswordField;