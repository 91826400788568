import React, { Component } from 'react';
import Button from '../../common/Button';
import { AutoComplete } from 'material-ui';
import lbStyles from './ListBatches.scss';
import MDSpinner from "react-md-spinner";
import { alertBox } from '../../common/NForm';
import { NButton } from '../../common/NForm';
import { request } from '../../common/request';
import Utils from '../../utils/Utils';
import { withTranslation } from 'react-i18next';

class MoveBatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            etapasEquipamentos: [],
            opcaoSelecionadaText: '',
            tipoMovimento: '',
            lote: {},
            movimentacao: {},
            erroCampo: false,
            loading: true,
            etapas: [],
            tipoMovimentacao: '',
            numeroLote: ''
        }

        this.salvarMovimentacoes = this.salvarMovimentacoes.bind(this);
    }

    componentDidMount() {
        var { etapa, lote, endpoints } = this.props;

        let etapaId = etapa.stage == undefined ? etapa.id : etapa.stage.id;

        request(endpoints.ETAPA + '/rota?idEtapa=' + etapaId + '&idProduto=' + lote.fk_id_products + '&idLote=' + lote.fk_id_batches, {
            method: 'get'
        })
            .then(function (response) {
                if (response.status == 200) {
                    return response.json();
                }
            })
            .then((etapas) => {
                request(endpoints.EQUIPAMENTOS + '/rota?idEtapa=' + etapaId + '&idProduto=' + lote.fk_id_products + '&idLote=' + lote.fk_id_batches, {
                    method: 'get'
                })
                    .then(function (response) {
                        if (response.status == 200) {
                            return response.json();
                        }
                    })
                    .then((equipamentos) => {
                        let opcoes = [];

                        etapas.forEach(etapa => {
                            opcoes.push({ text: `${etapa.nome}`, value: 'etapa-' + etapa.id })
                            let equipamentosEtapa = _.map(equipamentos, function (equipamentoEtapa) {
                                if (equipamentoEtapa.etapa_id == etapa.id) {
                                    return equipamentoEtapa;
                                }
                            });
                            equipamentosEtapa.forEach(equipamento => {
                                if (equipamento) {
                                    opcoes.push({ text: `${equipamento.tag} - ${equipamento.nome}`, value: 'equipamento-' + equipamento.id });
                                }
                            });
                        });

                        this.setState({
                            etapasEquipamentos: opcoes,
                            lote: lote,
                            loading: false
                        })
                    });
            });
    }

    /*
        --TipoMovimento--
        1 - Mover para cima;
        2 - Mover para baixo;
        3 - Mover para entre equipamentos;
        4 - Mover de equipamento para etapa;
        5 - Mover de etapa para equipamento;
        6 - Mover de etapa para etapa;
    */
    handleOpcao(nomeOpcao, allOpcoes) {
        let obj = allOpcoes.find(i => i.text == nomeOpcao);
        if (obj == undefined) return;

        let loteContexto = this.state.lote;

        let objSplit = obj.value.split('-');
        let tipoMovimentacao = objSplit[0];
        let idReferencia = parseInt(objSplit[1]);

        let equipamentoPrincipalAnterior = loteContexto.master;

        var objetoMovimentacao = {
            excluir: false,
            lote_id: loteContexto.fk_id_batches,
            etapa_original_id: loteContexto.fk_id_stages,
            data_fim_original: loteContexto.end_time,
            data_fim_referencia: loteContexto.reference_time,
            equipamento_original_id: loteContexto.fk_id_equipments
        };

        // equipamentoPrincipalAnterior - TRUE => Fila de espera do equipamento
        // equipamentoPrincipalAnterior - FALSE => Fila de espera da etapa
        if (equipamentoPrincipalAnterior) {
            //Movimento dp equipamento para etapa
            if (tipoMovimentacao == 'etapa') {
                objetoMovimentacao.equipamento_referencia_id = loteContexto.fk_id_equipments;
                objetoMovimentacao.etapa_referencia_id = loteContexto.fk_id_stages;

                objetoMovimentacao.tipo_movimentacao = 4;
            }
            //Movimento do equipamento para outro equipamento
            else if (tipoMovimentacao == 'equipamento') {
                objetoMovimentacao.equipamento_referencia_id = idReferencia;
                objetoMovimentacao.etapa_referencia_id = loteContexto.fk_id_stages;

                objetoMovimentacao.tipo_movimentacao = 3;
            }
        } else {
            //Movimento da Etapa para etapa
            if (tipoMovimentacao == 'etapa') {
                objetoMovimentacao.equipamento_referencia_id = loteContexto.fk_id_equipments;
                objetoMovimentacao.etapa_referencia_id = idReferencia;

                objetoMovimentacao.tipo_movimentacao = 6;
            }
            //Movimento da etapa para equipamento
            else if (tipoMovimentacao == 'equipamento') {
                objetoMovimentacao.equipamento_referencia_id = idReferencia;
                objetoMovimentacao.etapa_referencia_id = loteContexto.fk_id_stages;

                objetoMovimentacao.tipo_movimentacao = 5;
            }
        }

        this.setState({
            movimentacao: [objetoMovimentacao],
            opcaoSelecionadaText: obj.text,
            tipoMovimentacao: tipoMovimentacao,
            numeroLote: loteContexto.num
        })

    }

    salvarMovimentacoes() {
        let { movimentacao, opcaoSelecionadaText, tipoMovimentacao, numeroLote } = this.state;
        let erroCampo = false;
        let textoMovimentacao = tipoMovimentacao == 'equipamento' ? `${this.props.t("labels.theEquipment")} ` + opcaoSelecionadaText : `${this.props.t("labels.theStage")} ` + opcaoSelecionadaText;

        if (opcaoSelecionadaText == '') {
            erroCampo = true;
            this.setState({
                erroCampo: erroCampo
            })
        } else {
            this.setState({
                erroCampo: false
            });

            request(this.props.endpoints.MOVIMENTACAO_LOTE, {
                method: 'post',
                body: JSON.stringify(movimentacao),
            }).then(response => {
                if (response.ok) {
                    alertBox(
                        this.props.t("messages.moveSuccess", {
                            primaryReference: Utils.getReferenciaoLoteaOrdem(),
                            batchNumber: numeroLote,
                            changedText: Utils.getReferenciaAlteradoAlterada(),
                            moveText: textoMovimentacao
                        }), Utils.getReferenciaLoteOrdem(), 'success');
                    this.props.handleSession();
                } else {
                    response.json().then((responseFail) => {
                        alertBox(responseFail.message, '', 'error');
                    });

                    this.props.handleModal();
                }
            });
        }
    }

    render() {
        var { handleModal, lote, posicaoFifo } = this.props;

        return (
            this.state.loading ?
                (
                    <div id={lbStyles.loading} style={{ width: '80px', height: '100%' }}>
                        <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
                    </div>
                )
                :
                (
                    <div className={lbStyles.list_modal}>
                        <div>
                            <div><h3>{this.props.t("labels.moveBatch", { primaryReference: Utils.getReferenciaLoteOrdem() })}</h3> <Button button={{ type: 'buttonIcon', icon: 'fa fa-close', text: '', onClick: handleModal }} /></div>
                        </div>
                        <div>
                            <div style={{ marginTop: 10 }} >
                                <div style={{ marginTop: '1em', overflow: 'hidden' }}>
                                    <div style={{ display: 'flex' }}>
                                        <span> <b style={{ fontWeight: 'bold' }}>{this.props.t("labels.product")}:</b> {`${lote.sku} - ${lote.product}`} </span>
                                    </div>
                                    {
                                        lote.num_secundario != '' && !!Utils.getReferenciaOrdemLote() ?
                                            <div style={{ display: 'flex' }}>
                                                <span> <b style={{ fontWeight: 'bold' }}>{Utils.getReferenciaOrdemLote()}:</b> {lote.num_secundario}</span>
                                            </div>
                                            :
                                            null
                                    }
                                    <div style={{ display: 'flex' }}>
                                        <span> <b style={{ fontWeight: 'bold' }}>{Utils.getReferenciaLoteOrdem()}:</b> {lote.num}</span>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <span> <b style={{ fontWeight: 'bold' }}>{this.props.t("labels.FIFO")}:</b> {posicaoFifo + 1}°</span>
                                    </div>
                                </div>
                            </div>
                            <AutoComplete
                                className={lbStyles.fontForm}
                                dataSource={this.state.etapasEquipamentos}
                                fullWidth={true}
                                floatingLabelText={this.props.t("labels.targetEquipmentStage")}
                                underlineFocusStyle={{ borderColor: '#000000' }}
                                underlineStyle={this.state.erroCampo ? { borderColor: 'red' } : ''}
                                id={'equipment'}
                                name={'equipment'}
                                filter={AutoComplete.fuzzyFilter}
                                onUpdateInput={this.handleOpcao.bind(this)}
                                ref={ref => this.autoComplete = ref}
                                onClick={() => {
                                    this.setState({
                                        opcaoSelecionadaText: ''
                                    });
                                }}
                                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                openOnFocus={true}
                                popoverProps={{ className: 'autocomplete', tabIndex: 1 }}
                            />
                            {this.state.erroCampo ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> : ''}
                        </div>
                        {
                            this.state.etapasEquipamentos.length != 0 ?
                                <div className={'flex flex-just-end'}>
                                    <div>
                                        <NButton
                                            type="button"
                                            onClick={this.salvarMovimentacoes}
                                            style={{ fontWeigth: 100, backgroundColor: '#2D4F7F', color: '#FFF' }}

                                        >{this.props.t("labels.save", { ns: 'common' })}</NButton>
                                    </div>
                                    <div><Button button={{ type: 'round', text: this.props.t("labels.cancel", { ns: 'common' }), onClick: this.props.handleModal }} /></div>
                                </div>
                                :
                                null
                        }
                    </div>
                )
        );
    }
}

export default withTranslation("flowView")(MoveBatch);
