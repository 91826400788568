import React from 'react';
import AbstractComponent from '../AbstractComponent/AbstractComponent';
import Bar from '../Bar/Bar';

class ManutencaoBarChart extends AbstractComponent {

	componentDidMount() {	}

	render() {
			let bars = [];
			this.props.data.indicatorBackground.forEach((val, i) => {
					let padrao = this.props.data.data[i];
					let real = this.props.data.data2[i];
					let label = this.props.data.labels[i];
					let indicatorBackground = val;
					let indicatorColor = this.props.data.indicatorColor[i];
							bars.push(<Bar
									key={i}
									style={{ position: 'absolute', top: (i == 0 ? 0 : (i * 50)), left: 0 }}
									ticksColor={this.props.ticksColor}
									label={label}
									padrao={padrao}
									real={real}
									fontStyle=''
									showTicks={true}
									fontSize={12}
									height={70}
									styleSecondbar={{display: true, formatter: (value) => value + 'h', paddingTop: -23, fontColor: 'rgba(140, 140, 140, 1)', overlap: false}}
									horizontalBarLabel={this.props.horizontalBarLabel}
									indicatorBackground={indicatorBackground}
									indicatorColor={indicatorColor} />);
			});

			return (
					<div style={{ position: 'relative' }}>
							{bars}
					</div>
			);
	}
}

export default ManutencaoBarChart;
