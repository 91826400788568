import { APIEndpoints } from '../common/Constants';

/**
 * Faz a request para armazenamento do acesso do usuário as funcionalidades do sistema
 * 
 * @param string Url 
 * @param string Parametros
 */
export const logAcesso = (nome, parametros = '') => {

    const body = {modulo : {nome: nome}, parametros}
  
    fetch( APIEndpoints.HISTORICO_ACESSO, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Access-Token': localStorage['token'],
            'Authorization': 'Bearer ' + localStorage['tokenApp']
        },
        body: JSON.stringify(body)
    });
}