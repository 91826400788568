import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MDSpinner from 'react-md-spinner';
import i18n from '../../utils/i18n';

import './dropzone.scss';

const Dropzone = (props) => {
    const { message, loading, loadingMessage, setFieldValue } = props;
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        setFiles([...files, ...acceptedFiles]);
    }, [files]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noKeyboard: true,
        ...props
    });

    const removeFile = file => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
    };

    useEffect(() => {
        setFieldValue("files", files);
    }, [setFieldValue, files]);

    const renderSelectedFiles = () => {
        return (
            <ul>
                {
                    files.map(file => (
                        <li key={file.name}
                            className="dropzone-remove justify-content-center align-items-center"
                            style={{ display: 'flex' }}>
                            <i className="material-icons attached-file-icon">attach_file</i>
                            <div className="file-name" style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>{file.name}</div>
                            <i className="material-icons remove-file-icon" onClick={() => removeFile(file)}>cancel</i>
                        </li>
                    ))
                }
            </ul>
        )
    };

    const renderUploadSpinner = () => {
        return (
            <div className="justify-content-center align-items-center flex-column">
                <div className="dropzone-loading justify-content-center align-items-center" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <MDSpinner singleColor='#2D4F7F' size={30} borderSize={7} />
                    </div>
                </div>
                <div className="dropzone-loading-message" style={{ display: 'flex', flexDirection: 'column' }}>
                    {loadingMessage}
                </div>
            </div>
        )
    };

    const renderDropzone = () => {
        return (
            <div {...getRootProps({
                className: "dropzone"
            })}
                className="flex-column" >
                <div><input {...getInputProps()} /></div>
                <div className="dropzone-icon justify-content-center align-items-center"
                    style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <span class="material-icons" style={{ display: 'flex', fontSize: '2.8rem', color: '#aaaaaa' }}>attach_file</span>
                </div>
                <div className="dropzone-message">{message || i18n.t('uploadModal.defaultMessage', { ns: 'components' })}</div>
            </div>
        )
    };

    return (
        <div className={`dropzone-container justify-content-center align-items-center ${props.className} ${files.length > 0 && 'has-files'} ${isDragActive ? 'is-dragging-over' : ''}`}>
            {
                loading ? renderUploadSpinner() : (files.length > 0 ? renderSelectedFiles() : renderDropzone())
            }
        </div>
    );
}

export default Dropzone