import React, { Component } from 'react';
import Button from '../../common/Button';
import lbStyles from './ListBatches.scss';
import { alertBox } from '../../common/NForm';
import ListWaitingBatchesStageList from './ListWaitingBatchesStageList';
import utils from '../../utils/Utils';
import { withTranslation } from 'react-i18next';

class ListWaitingBatchesStage extends Component {
    constructor(props) {
        super(props);

        this.detalhamentoLote = this.detalhamentoLote.bind(this);
    }
    
    detalhamentoLote(obj) {
        if(obj.inconsistente) {
          alertBox(this.props.t("errors.cannotDetailInconsistent", { primaryReference: utils.getReferenciaoLoteaOrdem().toLowerCase() }), utils.getReferenciaLoteInconsistenteOrdemInconsistente() , 'error');
        } else {
          this.props.activeModal('detailLote', obj);
        }
    }

    render() {
        var {handleModal, etapa, referenciaSecundaria, listaLotesEtapa} = this.props;
        var codProd = this.props.store.events.filter(e => e.fk_id_table == 2)[0];

        let lista = listaLotesEtapa
        .sort((a, b) => {
            return new Date(b.reference_time).getTime() - new Date(a.reference_time).getTime();
        });

        return(
            <div className={lbStyles.list_modal}>
                <div>
                    <div><h3>{this.props.t("labels.equipmentDetailStage")}</h3> <Button button={{ type: 'buttonIcon', icon: 'fa fa-close', text: '', onClick: handleModal }} /></div>
                </div>
                <div>
                    <ListWaitingBatchesStageList 
                        codProd = {codProd} 
                        store={this.props.store}
                        listaLotesEtapa = {lista}
                        handleSession={this.props.handleSession}
                        etapa = {etapa} 
                        handleModal = {handleModal}
                        endpoints={this.props.endpoints}
                        activeModal={this.props.activeModal}
                        detalhamentoLote={this.detalhamentoLote}
                        referenciaSecundaria={referenciaSecundaria}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation("flowView")(ListWaitingBatchesStage);
