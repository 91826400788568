import React, { Component } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import style from './styles.scss';
import $ from 'jquery';
import { observer, inject } from 'mobx-react';
import Modal from './CommonModal';
import UpdatePasswordModal from 'components/UpdatePasswordModal';
import Store from './Store';

@inject('modalStore')
@observer
export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChangePasswordOpen: false
    }

    this.store = Store;

    this.modalStore = this.props.modalStore;
  }

  componentDidMount() {
    this.modalStore.close()
  }

  render() {
    let { modal, styleModal, contentModal, children, sectionStyle } = this.props;
    var heightContainer = '94vh';
    var padding = '';
    var center = 'center';

    if ($('#sectionContent').height() > 906) {
      heightContainer = $('#sectionContent').height() + 'px';
      padding = '100px';
      center = '';
    }
    return (
      <div style={{ height: '100%' }}>
        {modal ? <div className={'box_modal'}><div style={styleModal ? styleModal : null}>{contentModal()}</div></div> : null}

        {this.modalStore.Isopen &&
          <div className={'box_modal'}>
            {this.modalStore.Component}
          </div>
        }

        <div className={modal || this.modalStore.Isopen ? 'box_body blur' : 'box_body'}>
          <Header {...this.props} update={this.update} onChangePasswordClick={() => this.setState({ isChangePasswordOpen: true })} />
          {this.props.showSideBar ? <Sidebar heightContainer={heightContainer} padding={padding} center={center} /> : null}
          <section style={sectionStyle || { heigth: heightContainer, float: 'left', marginTop: this.props.style ? this.props.style.marginTop != undefined ? this.props.style.marginTop : '1%' : '1%' }} id={'sectionContent'} className={this.props.showSideBar ? style.widthSection : style.widthSection100}>
            {children}
          </section>
        </div>

        {this.state.isChangePasswordOpen || this.store.currentUser?.senhaExpirada ?
          <Modal className={'box_modal'}>
            <UpdatePasswordModal
              currentUser
              // onSuccess={this.refreshUsuario}
              closeModal={() => this.setState({ isChangePasswordOpen: false })} />
          </Modal>
          : null
        }
      </div>
    );
  }
}
