import React, { Component } from 'react';
import {FormInput} from '../../common/Form';
import TimeConversion from '../../utils/TimeConversion';
import MaskFieldTime from '../../utils/MaskFieldTime';

const {TMinutes, THoursMinutes} = TimeConversion;
const {Mask} = MaskFieldTime;

export default class InputTime extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      isValid: false
    }
    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);
    this._isValid = this._isValid.bind(this);
  }

  componentWillMount() {
    this.setState({
      value: THoursMinutes(this.props.value)
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== undefined && nextProps.value !== null && nextProps.value > 0) {
      this.setState({
        value: THoursMinutes(nextProps.value)
      });
    }
  }

  _onBlur(event) {
    let v=event.target.value.split(":");

    if(v[1] > 59) {
     event.target.value = "";
     this.setState({
       value: ''
     });
    }

    this.props.onChange(this.props.stage, this.props.id, this.props.cycle, this.props.type, TMinutes(this.state.value, ':'));
  }

  _onChange(event) {
    this.setState({
      value: Mask(event.target.value)
    });
  }

  _isValid() {
    this.setState({
      isValid: this.state.value.length > 0
    });
  }

  render() {
    let {value} = this.state;
    let {disabled, required} = this.props;

    return <FormInput id='time' placeholder="HHH:MM" onChange={this._onChange} onBlur={this._onBlur} value={value} disabled={disabled} required={required} novalidate="true"/>;
  }
}