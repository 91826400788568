import React from 'react';
import Container from '../common/Container';
import { Link } from 'react-router-dom';
import styles from './hive.scss';
import LinkClientV2 from '../common/LinkClientV2';
import { withTranslation } from 'react-i18next';

class FormPage extends Container {
  constructor() {
    super();
  }

  render() {
    return (
      <Container>
        <div className={styles.hive_wrapper}>
          <div className={styles.field}>
            <div className={styles.hover}>
              <div className={styles.line + ' ' + styles.line_invisible}>
                <div className={styles.hex}></div>
                <div className={styles.hex}></div>
              </div>

              <div className={styles.line}>

                <LinkClientV2 to={'notificacao-evento'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>notifications</i>
                    <span>{this.props.t("titles.eventNotification")}</span>
                  </div>
                </LinkClientV2>

                <LinkClientV2 to={'grupo-apontamento-equipamento'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img style={{ height: '70px' }} src="../../assets/icone-permissao-por-equipamento.svg" />
                    <span>{this.props.t("titles.equipmentPermission")}</span>
                  </div>
                </LinkClientV2>

                <LinkClientV2 to={'estoque'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img style={{ height: '70px' }} src="../../assets/warehouse.svg" />
                    <span>{this.props.t("titles.stock")}</span>
                  </div>
                </LinkClientV2>

                <div className={styles.hex}></div>
              </div>

              <div className={styles.line}>

                <Link to={'/produto'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>eject</i>
                    <span>{this.props.t("titles.products")}</span>
                  </div>
                </Link>

                <Link to={'/campo-personalizado'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i style={{ marginLeft: '20px' }} className="material-icons">border_color</i>
                    <span>{this.props.t("titles.customFields")}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>
                <div className={styles.title + ' ' + styles.text_primary}>{this.props.t("titles.general")}</div>

                <Link to={'/equipamento'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>settings</i>
                    <span>{this.props.t("titles.equipment")}</span>
                  </div>
                </Link>

                <Link to={'/usuario'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>person</i>
                    <span>{this.props.t("titles.users")}</span>
                  </div>
                </Link>

                <Link to={'/grupo-de-usuario'}>
                  <div className={styles.hex + ' ' + styles.hex_primary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='fa fa-users'></i>
                    <span>{this.props.t("titles.userGroups")}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>

                <Link to={'/etapa'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>format_list_numbered</i>
                    <span>{this.props.t("titles.stages")}</span>
                  </div>
                </Link>

                <Link to={'/area-produtiva'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>view_module</i>
                    <span>{this.props.t("titles.fields")}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>
                <div className={styles.title + ' ' + styles.text_secondary}>{this.props.t("titles.flowView")}</div>

                <Link to={'/unidade-industrial'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>bookmark_border</i>
                    <span>{this.props.t("titles.industrialUnits")}</span>
                  </div>
                </Link>

                <Link to={'/rota'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>timeline</i>
                    <span>{this.props.t("titles.routes")}</span>
                  </div>
                </Link>

                <Link to={'/predecessor'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>link</i>
                    <span>{this.props.t("titles.batchLinks", { primaryReference: this.utils.getReferenciaLotesOrdens() })}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>

                <Link to={'/evento'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>flag</i>
                    <span>{this.props.t("titles.events")}</span>
                  </div>
                </Link>

                <Link to={'/lote'}>
                  <div className={styles.hex + ' ' + styles.hex_secondary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>widgets</i>
                    <span>{this.utils.getReferenciaLotesOrdens()}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>
                <div className={styles.title + ' ' + styles.text_tertiary}>{this.props.t("titles.oee")}</div>

                <Link to={'/manutencao'}>
                  <div className={styles.hex + ' ' + styles.hex_tertiary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img className={styles.img_icon} src='../assets/hammer.svg' />
                    <span>{this.props.t("titles.maintenance")}</span>
                  </div>
                </Link>

                <Link to={'/turno'}>
                  <div className={styles.hex + ' ' + styles.hex_tertiary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>wb_sunny</i>
                    <span>{this.props.t("titles.shifts")}</span>
                  </div>
                </Link>

                <Link to={'/opcoes-de-calculo'}>
                  <div className={styles.hex + ' ' + styles.hex_tertiary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className='material-icons'>playlist_add_check</i>
                    <span>{this.props.t("titles.availability")}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>

                <LinkClientV2 to={'/indicadores-personalizados'}>
                  <div className={styles.hex + ' ' + styles.hex_tertiary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img src="../../assets/icon-indicadores-personalizados.svg" />
                    <span>{this.props.t("titles.customIndicators")}</span>
                  </div>
                </LinkClientV2>

                <Link to={'/grupo-de-equipamento'}>
                  <div className={styles.hex + ' ' + styles.hex_tertiary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img src="../../assets/iconaee.svg" />
                    <span style={{ marginLeft: '-3.1em' }}>{this.props.t("titles.groupEquipment")}</span>
                  </div>
                </Link>

              </div>

              <div className={styles.line}>
                <div className={styles.title + ' ' + styles.text_quaternary}>{this.props.t("titles.devices")}</div>

                <Link to={'/grupo-de-evento'}>
                  <div className={styles.hex + ' ' + styles.hex_quaternary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <i className="material-icons">dashboard</i>
                    <span>{this.props.t("titles.groupEvent")}</span>
                  </div>
                </Link>

                <LinkClientV2 to={'/setup-guiado'}>
                  <div className={styles.hex + ' ' + styles.hex_quaternary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img src="../../assets/icon-setup-guiado.svg" />
                    <span>{this.props.t("titles.assistedEvent")}</span>
                  </div>
                </LinkClientV2>

                <LinkClientV2 to={'/agrupador-manutencao'}>
                  <div className={styles.hex + ' ' + styles.hex_tertiary}>
                    <div className={styles.hex_1}></div>
                    <div className={styles.hex_2}></div>
                    <img src="../../assets/icon-agrupador-manutencao.svg" />
                    <span>{this.props.t("titles.groupMaintenance")}</span>
                  </div>
                </LinkClientV2>
              </div>

            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withTranslation("common")(FormPage)