import React, { Component } from 'react';
import InputTime from './InputTime';
import { FormInput } from '../../common/Form';
import { alertBox } from '../../common/NForm';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import listDStyles from './listDrop.scss';
import { Checkbox, Paper } from 'material-ui';
import $ from 'jquery';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import ClickAwayListener from 'material-ui/internal/ClickAwayListener';

var equipamentosEtapa = [];

class RouteEquipment extends Component {
  render() {
    let { idx, onDragToEquipment, ondragendToTrash } = this.props;
    let { id, name, cycle, wait_time_default, wait_time, proc_time, QuantityConversion, oee, oee_padrao, quantity, master, stage, is_batch } = this.props.equipment;
    var nomeTratado = name;

    if (nomeTratado.length > 27) {
      nomeTratado = nomeTratado.substr(0, 24) + '...';
    }

    return (
      <li>
        <div style={{ height: '1.5em' }} className={'flex'}>
          <InputMaster {...this.props} id={id} value={master} cycle={cycle} stage={stage} type='master' onChange={this.props.onChange} />
          <ContextMenu id={`ctx-${idx}`}>
            <MenuItem onClick={() => this.props.remRoute(id)}>{this.props.t("equipment.remove")}</MenuItem>
          </ContextMenu>
          <ContextMenuTrigger id={`ctx-${idx}`}>
            <span className={listDStyles.titleMoveEquipment} data-idx={idx} onDragStart={onDragToEquipment} onDragEnd={ondragendToTrash} draggable='true' title={nomeTratado.indexOf('...') != -1 ? name : null}>
              <i className='fa fa-arrows-alt' /> {nomeTratado}
            </span>
          </ContextMenuTrigger>
        </div>
        <InputWait t={this.props.t} id={id} value={wait_time_default} cycle={cycle} stage={stage} type='wait_time_default' onChange={this.props.onChange} />
        <InputTime id={id} value={wait_time} cycle={cycle} stage={stage} type='wait_time' disabled={wait_time_default ? true : false} onChange={this.props.onChange} />
        <InputTime id={id} value={proc_time} cycle={cycle} stage={stage} type='proc_time' onChange={this.props.onChange} />
        <span style={{ width: '6rem', marginRight: '.8em' }}>
          <InputNumber id={id} value={quantity} cycle={cycle} stage={stage} min='1' type='quantity' onChange={this.props.onChange} disabled={is_batch ? 'disable' : ''} required={is_batch ? false : true} />
        </span>
        <span style={{ width: '6rem', marginRight: '.8em' }}>
          <NumberFormat id={id} className={listDStyles.balloon} fixedDecimalScale={true} decimalScale={2} allowNegative={false} isAllowed={({ floatValue }) => (floatValue <= 99999.99)} value={QuantityConversion} allowLeadingZeros={false} onBlur={(e) => this.props.onChange(stage, id, cycle, "QuantityConversion", e.target.value)} />
        </span>
        <span className={listDStyles.box_oee}>
          <span onClick={() => this.props.onChange(stage, id, cycle, 'oee_padrao', !oee_padrao)}>{oee_padrao ? this.props.t("equipment.default") : this.props.t("equipment.custom")}</span>
          <InputNumber id={id} value={oee} cycle={cycle} stage={stage} min='0' max='100' disabled={oee_padrao} type='oee' onChange={this.props.onChange} />
        </span>
      </li>
    );
  }
}

class InputNumber extends Component {
  constructor() {
    super();
  }

  onSave = () => {
    let value = this.props.type == 'oee' ?
      parseFloat(this.refs[this.props.type].refs[this.props.type].value.replace(',', '.')) / 100
      :
      this.props.type == 'quantity' ?
        this.refs[this.props.type].refs[this.props.type].value.replace(/[^0-9\,]/, '')
        :
        this.refs[this.props.type].refs[this.props.type].value;
    this.props.onChange(this.props.stage, this.props.id, this.props.cycle, this.props.type, value);
  }

  casasDecimais(num, precisao) {
    var casas = Math.pow(10, precisao);
    return Math.round(num * casas) / casas;
  };

  render() {
    let { value, min, max, disabled, type, required } = this.props;

    return <FormInput
      type='number'
      id={type}
      ref={type}
      value={(value !== undefined && value !== null && value > 0) ? type == 'oee' ? this.casasDecimais((value * 100), 2) : value : '0'}
      min={min}
      max={max || ''}
      novalidate={true}
      onBlur={this.onSave}
      disabled={disabled}
      required={required} />;
  }
}

class InputMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      master: this.props.value
    }

    this._onSave = this._onSave.bind(this);
    this.indexEtapaIntegrada = this.indexEtapaIntegrada.bind(this);
    this._ehMaster = this._ehMaster.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      master: nextProps.value
    });
  }

  _ehMaster(etapaId, ciclo, equipamentoId) {
    let { obj } = this.props;
    var rotas = obj.product.routes;
    var rota = rotas.filter(e => e.fk_id_stages == etapaId && e.cycle == ciclo && e.fk_id_equipments == equipamentoId)[0];

    return rota.master;
  }

  _onSave(ehEquipamentoPreferencial) {
    let { master } = this.state;
    let { etapaIntegrada, id, stage, cycle, equipments, etapas, obj, key } = this.props;
    let erro = false, idEquipamento;
    let etapasMaster = [];
    let estapasIntegradas = _.flattenDeep(etapaIntegrada);

    let estapasIntegradasEtapaAtual = _.filter(estapasIntegradas, function (etapaIntegrada) {
      return etapaIntegrada.etapa_atual === stage;
    });

    estapasIntegradasEtapaAtual.forEach(etapaIntegrada => {
      var etapasPrincipaisIntegradas = _.filter(obj.product.routes, function (etapa) {
        return etapaIntegrada.etapa_id === etapa.fk_id_stages && etapa.master;
      });

      if (!master && etapasPrincipaisIntegradas.length > 0) {
        etapasMaster.push(etapasPrincipaisIntegradas);
      }
    });

    etapaIntegrada.forEach(element => {
      if (element.length > 0) {
        element.forEach(el => {
          if (el.etapa_atual == stage && el.ciclo_atual == cycle) {
            var et = obj.product.routes.filter(rt => rt.fk_id_stages != el.etapa_atual && rt.cycle == el.ciclo && rt.master);

            if (et.length > 0 && el.equipamento_id === id && et.etapaAtual === el.etapa_atual) {
              etapasMaster.push(et);
            }
          }
          if (el.etapa_id == stage && el.ciclo == cycle) {
            if (this._ehMaster(el.etapa_atual, el.ciclo_atual, el.equipamento_id)) {
              idEquipamento = el.equipamento_id;
              erro = true;
            }
          }
        });
      }
    });

    if (erro) {
      let nomeEquipamento = equipments.filter(e => e.id == idEquipamento)[0].name;
      alertBox(`${this.props.t("equipment.messages.integratedIsMaster1")} ${nomeEquipamento} ${this.props.t("equipment.messages.integratedIsMaster2")}`, this.props.t("equipment.messages.checkStages"), 'error');
    }

    if (etapasMaster.length > 0 && !this.state.master) {
      var flatEtapas = _.flattenDeep(etapasMaster);
      erro = true;
      var nomeEtapas = flatEtapas.map(e => {
        return etapas.filter(et => et.id == e.fk_id_stages)[0].name;
      });
      alertBox(`${this.props.t("equipment.messages.alreadyHaveMaster1")} ${nomeEtapas.length > 1 ? this.props.t("equipment.messages.onStages") : this.props.t("equipment.messages.onStage")} ${nomeEtapas.join(',')} ${this.props.t("equipment.messages.alreadyHaveMaster2")}`, this.props.t("equipment.messages.checkStages"), 'error');
    }

    if (!erro) {
      const type = master ? this.props.t("equipment.main") : (ehEquipamentoPreferencial ? this.props.t("equipment.preferential") : this.props.t("equipment.alternative"));
      this.props.onChange(this.props.stage, this.props.id, this.props.cycle, type, !master);
    }
  }

  _handleOnDrawEtapas(elementId) {
    $('#' + elementId).next('div').toggle();
  }

  _nomeEtapa(etapaId, ciclo, arr) {
    var etapa = arr.filter(e => e.id == etapaId)[0];

    if (ciclo == 1) {
      return etapa.name;
    }

    return etapa.name + ` - ${this.props.t("equipment.cycle")} ` + ciclo;
  }

  indexEtapaIntegrada(etapaId, ciclo) {
    var { sequenciaEtapa } = this.props;
    var index;

    for (let i = 0; i < sequenciaEtapa.length; i++) {
      if (sequenciaEtapa[i].etapa_id == etapaId && sequenciaEtapa[i].ciclo == ciclo) {
        index = i;
      }
    }

    return index;
  }

  _valueCheck(etapaAtual, cicloAtual, etapaId, equipamentoId, ciclo) {
    var { etapaIntegrada } = this.props;
    var etapa = etapaIntegrada[this.indexEtapaIntegrada(etapaAtual, cicloAtual, etapaIntegrada)];
    var retorno = false;

    if (etapa) {
      for (var i = 0; i < etapa.length; i++) {
        if (etapa[i].etapa_atual == etapaAtual && etapa[i].ciclo_atual == cicloAtual &&
          etapa[i].equipamento_id == equipamentoId && etapa[i].etapa_id == etapaId && etapa[i].ciclo == ciclo) {
          retorno = true;
          break;
        }
      }
    }

    return retorno;
  }

  render() {
    equipamentosEtapa = this.props.obj.product.routes.filter(e => e.fk_id_stages == this.props.stage && e.cycle == this.props.cycle);
    const equipamentoPreferencial = equipamentosEtapa.find(x => x.MasterType === 'p' && !this.state.master && x.fk_id_equipments == this.props.id);

    let etapas = this.props.arrStage.map((e, i) => {
      if (!(e.fk_id_stages == this.props.stage && e.cycle == this.props.cycle) && e.com_equipamento) {
        return <Checkbox
          checked={this._valueCheck(this.props.stage, this.props.cycle, e.fk_id_stages, this.props.id, e.cycle)}
          onCheck={(event, value) => this.props.onCheckEtapaIntegrada(this.props.stage, this.props.cycle, e.fk_id_stages, this.props.id, e.cycle, i, this.state.master, value)}
          label={this._nomeEtapa(e.fk_id_stages, e.cycle, this.props.etapas)}
          iconStyle={{ fill: '#2b4d7c' }}
          key={i} />;
      } else {
        return <Checkbox
          disabled={true}
          label={this._nomeEtapa(e.fk_id_stages, e.cycle, this.props.etapas)}
          iconStyle={{ fill: '#2b4d7c' }}
          key={i} />
      }
    });

    return (
      <div className={'flex flex-align-center'}>
        <div
          className={this.state.master ? listDStyles.master : (equipamentoPreferencial ? listDStyles.preferencial : listDStyles.alternativo)}
          onClick={() => this._onSave(equipamentoPreferencial)}>
          {this.state.master ? this.props.t("equipment.main") : (equipamentoPreferencial ? this.props.t("equipment.preferential") : this.props.t("equipment.alternative"))}
        </div>
        <div style={{ position: 'relative' }}>
          <img
            id={'pp_' + this.props.id}
            style={{ margin: '0 .7em' }}
            onClick={() => this._handleOnDrawEtapas('pp_' + this.props.id)}
            width={20}
            height={20}
            src='../../assets/icons/etapas.svg' />
          <div className={listDStyles.pop_up}>
            <Paper
              zDepth={2}
              style={{ padding: '.7em' }}>
              {this.props.t('equipment.messages.chooseIntegratedStage')}
              <div style={{ marginTop: '1em' }}>
                {etapas}
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

class InputWait extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wait_time_default: this.props.value
    };
    this._onSave = this._onSave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      wait_time_default: nextProps.value
    });
  }

  _onSave() {
    this.props.onChange(this.props.stage, this.props.id, this.props.cycle, this.props.type, !this.state.wait_time_default);
  }

  render() {
    return <span onClick={this._onSave}>{this.state.wait_time_default ? this.props.t("equipment.default") : this.props.t("equipment.custom")}</span>;
  }
}

export default withTranslation('routes')(RouteEquipment);
