import { Router } from 'react-router';
import React, { Component } from 'react';
import fetchIntercept from 'fetch-intercept';
import { alertBox } from './common/NForm';
import createBrowserHistory from 'history/createBrowserHistory';
import './common/global.scss';
import './common/styles.scss';
import 'animate.css/animate.css';
import routes from './routes';
import i18n from 'utils/i18n';
import moment from 'moment';

const browserHistory = createBrowserHistory();

const unregister = fetchIntercept.register({
  response: function (response) {

    // Forbidden
    if (response.status === 403) {
      if (!response.url.includes("autorizar/taelor")) {
        browserHistory.push('/forbidden');
      }
    }

    // Unauthorized
    if (response.status === 401) {
      browserHistory.push('/');

      response.text().then(data => {
        if (data && data.trim().length !== 0) {
          alertBox(data, 'Atenção', 'error');
        }
      });
    }

    return response;
  }
});

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      movideskLoaded: false,
    }
  }

  setLanguage() {
    var language = localStorage.getItem('Language');

    if (language) {
      i18n.changeLanguage(language);
      moment.locale(language);
    }
  }

  componentDidMount() {
    this.setLanguage();
  }

  componentWillUnmount() {
    unregister();
  }

  render() {
    return (
      <Router history={browserHistory} >
        {routes}
      </Router>
    );
  }
};
