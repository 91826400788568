import React from 'react';
import AbstractComponent from '../AbstractComponent/AbstractComponent';
import { Doughnut } from 'react-chartjs-2';
import { makeGradient } from '../Helpers/ChartJsHelper';
import { Colors, TextColors } from '../constants';
import { withTranslation } from 'react-i18next';

const legendOpts = {
    display: false
};

class SetupHalfDoughnutChart extends AbstractComponent {

    constructor(props) {
        super(props);
        this.state = { matchScreen: window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').matches };
    }

    componentDidMount() {
        const handler = e => this.setState({matchScreen: e.matches});
        window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').addListener(handler);
    }

    /**
     * Override
     * @param {*string} key 
     */
    hasData(key) {
        return this.props.data && this.props.data[key];
    }

    /**
     * Override
     * @param {*string} key 
     */
    resolveData(key) {
        return this.hasData(key) ? this.props.data[key] : 0;
    }

    formatOptions(matchScreen) {
        return {
            responsive: false,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            layout: {
                padding: {
                    left: matchScreen ? 5 : 43,
                    right: matchScreen ? 36 : 50,
                    top: 20,
                    bottom: 50
                }
            },
            title: {
                display: true,
                text: this.props.t("charts.labels.generalPerformance"),
                position: 'bottom'
            },
            cutoutPercentage: 65,
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI,
            elements: {
                center: {
                    //textBaseLine: 'top',
                    textFontSize: 22,
                    textFontStyle: 'normal',
                    textFontColor: '#D02E20',
                    textFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

                    //dataLabelFontColor: '#D5D5D5',
                    //dataLabelTextAlign: 'center',
                    dataLabelBaseLine: 'bottom',
                    dataLabelFontSize: 16,
                    dataLabelFontStyle: 'normal',
                    dataLabelFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                }
            }
        };
    }

    formatData(data, canvas) {
        let oeeInPercent = data.oeeIn / 100;
        let oeeOutPercent = data.oeeOut / 100;
        let gradient = makeGradient(canvas, data.startColor, data.endColor);
        
        return {
            datasets: [
                {
                    label: [
                        "BG",
                        "OUT"
                    ],
                    data: [50 * oeeOutPercent, 50 * (1 - oeeOutPercent)],
                    dataLabel: [
                        data.oeeOut + '%'
                    ],
                    backgroundColor: [
                        "#E3E3E3",
                        "transparent"
                    ],
                    hoverBackgroundColor: [
                        "#E3E3E3",
                        "transparent"
                    ],
                    borderColor: "#fff",
                    hoverBorderColor: "#fff",
                    borderWidth: 0,
                    padding: 4
                },
                {
                    center: data.percent,
                    centerTextColor: data.textColor,
                    label: [
                        "BG 1",
                        "OUT 1"
                    ],
                    data: [50 * oeeInPercent, 50 * (1 - oeeInPercent)],
                    backgroundColor: [
                        gradient,
                        data.backgroundColor
                    ],
                    hoverBackgroundColor: [
                        gradient,
                        data.backgroundColor
                    ],
                    borderWidth: 0
                }
            ]
        };
    }

    render() {
        let color = this.hasData("cor") ? Colors[this.resolveData("cor")] : {};
        let textColor = TextColors[this.resolveData("cor")];
        let config = {
            percent: this.resolveData("real") + '%',
            oeeIn: Math.min(this.resolveData("real"), 100),
            oeeOut: this.resolveData("padrao"),
            startColor: color.startColor ? color.startColor : "transparent",
            endColor: color.endColor ? color.endColor : "transparent",
            backgroundColor: color.backgroundColor ? color.backgroundColor : "transparent",
            textColor: textColor ? textColor : 'transparent'
        };
        let data = (canvas) => this.formatData(config, canvas);
        let options = this.formatOptions(this.state.matchScreen);
        let size = this.state.matchScreen ? 180: 300;

        return (
            <Doughnut ref='chart' height={230} width={size} data={data} options={options} legend={legendOpts}></Doughnut>
        );
    }
}

export default withTranslation("oee")(SetupHalfDoughnutChart);
