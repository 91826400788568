import React, { Component } from 'react';
import moment from 'moment';
import Util from '../../utils/Utils';
import styles from './notes.scss';

export class ListNotes extends Component {

  constructor() {
    super();
    this.state = {
      listagem: []
    };
  }

  render() {
    let corBackground, corFont, listagem, corIcone, icone, iconeNext, nomeIcone, tooltipIcone, contItem = 0;
    let { validar } = this.props;

    if (this.props.lastNotes !== undefined) {
      var lastNotes = Util.ordenaApontamentos(this.props.lastNotes);
    }

    if (this.props.apontamentos !== this.state.apontamentos) {
      let listagemTemp = this.props.apontamentos.map((a, i) => ({ ...a, index: i }));
      listagem = Util.ordenaApontamentos(listagemTemp);
    }

    var lookUpPrev = {
      'Aceito': {
        color: 'green',
        className: 'fa fa-check-circle fa-lg',
        backgroundColor: false
      },

      'Rejeitado': {
        color: '#BF0617',
        className: 'fa fa-exclamation-circle fa-lg',
        backgroundColor: '#FBEFEF'
      },

      'Inconsistente': {
        color: '#F9D054',
        className: 'fa fa-exclamation-triangle fa-lg',
        backgroundColor: '#FDFAF0'
      },
    }

    var lookUpNext = {
      'Aceito': {
        color: 'green',
        className: 'fa fa-check-circle fa-lg',
        backgroundColor: false
      },

      'Rejeitado': {
        color: '#BF0617',
        className: 'fa fa-exclamation-circle fa-lg',
        nome: '',
        backgroundColor: '#FBEFEF'
      },

      'Inconsistente': {
        color: '#F9D054',
        className: 'fa fa-exclamation-triangle fa-lg',
        backgroundColor: '#FDFAF0'
      },
    }

    return (
      <div>
        <div style={{ marginTop: 0, fontSize: 14 }} className='table_reponsive'>
          <table className={`tableFontSize mui-table mui-table--bordered ${styles.notes_table}`}>
            <thead>
              <tr className="tr_list_notes">
                <td>#</td>
                <td>{Util.getReferenciaLoteOrdem()}</td>
                <td>{this.props.t("sku")}</td>
                <td>{this.props.t("product")}</td>
                <td>{this.props.t("event")}</td>
                <td>{this.props.t("start")}</td>
                <td>{this.props.t("end")}</td>
                <td>{this.props.t("productionType")}</td>
                {!this.props.equipamento.batch ? <td style={{ textAlign: 'right' }}>{this.props.t("produced")}</td> : null}
                {!this.props.equipamento.batch ? <td style={{ textAlign: 'right' }}>{this.props.t("rejected")}</td> : null}
                <td style={{ textAlign: 'center' }}>{this.props.t("obs")}</td>
                {!validar ? <td> </td> : <td style={{ textAlign: 'center' }}>{this.props.t("status")}</td>}
                <td>{this.props.t("action")}</td>
              </tr>
            </thead>
            <tbody className='box_items'>
              {
                lastNotes.map((e, k) => {
                  return (
                    <tr className="tr_list_notes" key={k} style={{ backgroundColor: '#ededed' }}>
                      <td className='borderBottom'></td>
                      <td className='borderBottom'>{e.num}</td>
                      <td className='borderBottom'>{e.sku}</td>
                      <td className='borderBottom' style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                        title={e.name} >{Util.maxStringLength(e.name, 15)}</td>
                      <td className='borderBottom' style={{
                        whiteSpace: 'nowrap'
                      }} title={e.cod + '-' + e.descr} >{Util.maxStringLength(e.cod + '-' + e.descr, 15)}</td>
                      <td className='borderBottom'>{moment(e.start_time).format('L LTS')}</td>
                      <td className='borderBottom'>{moment(e.end_time).format('L LTS')}</td>
                      <td className='borderBottom'>{e.tipo_producao}</td>
                      {!this.props.equipamento.batch
                        ?
                        <td className='borderBottom' style={{ textAlign: "right" }}>{e.ProduzidoAcumulado == 0 ? '' : e.ProduzidoAcumulado}</td>
                        :
                        null
                      }
                      {!this.props.equipamento.batch
                        ?
                        <td className='borderBottom' style={{ textAlign: "right" }}>{e.RejeitadoAcumulado == 0 ? '' : e.RejeitadoAcumulado}</td>
                        :
                        null
                      }
                      <td className='borderBottom' style={{ textAlign: 'center', color: corFont }}><i style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => this.props.handleModal('visualizar_obs', e)} className="material-icons iconFontSize">chat_bubble_outline</i></td>
                      <td className='borderBottom'></td>
                      <td className='borderBottom'></td>

                    </tr>
                  );
                })
              }
              {listagem != undefined ?
                listagem.map((a, i) => {
                  corFont = '#5B5B5B';
                  nomeIcone = '';
                  tooltipIcone = '';

                  let msgAfter = '';

                  a.classe_estrutura = a.classe_estrutura || { nome: '' };
                  a.consistente_oee_estrutura = a.consistente_oee_estrutura || { nome: '' };

                  // Antes
                  if (a.classe_estrutura.nome != "" && a.estado == 'Aceito') {
                    msgBefore = a.classe_estrutura.nome.toUpperCase();
                  }
                  else if (a.classe_estrutura.nome != "" && a.estado == 'Rejeitado') {
                    msgBefore = a.classe_estrutura.nome.toUpperCase();
                  }
                  else {
                    if ((a.classe_estrutura.nome != "" && a.classe_estrutura.nome) && (a.consistente_oee_estrutura.nome != "" && a.consistente_oee_estrutura.nome) && a.classe_estrutura.id != 27 && a.consistente_oee_estrutura.id != 27) {
                      msgBefore = (a.classe_estrutura.nome) + (' E ' + a.consistente_oee_estrutura.nome).toUpperCase();
                    }
                    else if ((a.descricao != "" && a.descricao) && a.classe_estrutura.id == 27 && a.consistente_oee_estrutura.id == 27) {
                      msgAfter = (a.descricao || this.props.t("consistentNote")).toUpperCase();
                    }
                    else {
                      if ((a.classe_estrutura.nome != "" && a.classe_estrutura.nome) && a.classe_estrutura.id != 27) {
                        msgBefore = a.classe_estrutura.nome.toUpperCase();
                      }

                      if ((a.consistente_oee_estrutura.nome != "" && a.consistente_oee_estrutura.nome) && a.consistente_oee_estrutura.id != 27) {
                        msgBefore = a.consistente_oee_estrutura.nome.toUpperCase();
                      }
                    }
                  }

                  // Depois

                  if (a.descricao && a.estadoNext == 'Aceito') {
                    msgAfter = (a.descricao || this.props.t("consistentNote")).toUpperCase();
                  }
                  else if (a.descricao && a.estadoNext == 'Rejeitado') {
                    msgAfter = (a.descricao || '').toUpperCase();
                  }
                  else {
                    if ((a.descricao != "" && a.descricao) && (a.descricao_oee != "" && a.descricao_oee) && a.classe != 27 && a.consistente_oee != 27) {
                      msgAfter = ((a.descricao || '') + (' e ' + a.descricao_oee) || '').toUpperCase();
                    }
                    else if (a.classe == 27 && a.consistente_oee == 27) {
                      msgAfter = (a.descricao || this.props.t("consistentNote")).toUpperCase();
                    }
                    else {
                      if (a.classe != 27) {
                        msgAfter = (a.descricao || '').toUpperCase();
                      }

                      if (a.consistente_oee != 27) {
                        msgAfter = (a.descricao_oee || '').toUpperCase();
                      }
                    }
                  }

                  if (a.tipo == 'Excluir') {
                    corFont = '#CCCFD0';
                  } else {
                    contItem++;
                  }
                  return (
                    <tr style={{ backgroundColor: a.tipo == 'Excluir' ? '#F5F8F9' : a.estadoNext ? lookUpPrev[a.estadoNext].backgroundColor : lookUpPrev[a.estado].backgroundColor }} key={i}>
                      {<td style={{ color: corFont }}>{contItem}</td>}
                      <td style={{ color: corFont }}>{a.lote ? a.lote.numero : a.numero_lote}</td>
                      <td style={{ color: corFont }}>{a.lote ? a.lote.numero ? a.lote.produto.sku : '' : ''}</td>
                      <td style={{ color: corFont }}>{a.lote ? a.lote.numero ? a.lote.produto.nome : '' : ''}</td>
                      <td style={{ color: corFont }}>{a.evento.codigo + ' - ' + a.evento.nome}</td>
                      <td style={{ color: corFont }}>{moment(a.inicio).format('L LTS')}</td>
                      <td style={{ color: corFont }}>{moment(a.fim).format('L LTS')}</td>
                      <td style={{ color: corFont }}>{a.tipo_producao ? a.tipo_producao : ''}</td>
                      {!this.props.equipamento.batch ? <td style={{ textAlign: 'center', color: corFont }} >{a.evento.fluxo ? a.produzido_acumulado : ''}</td> : null}
                      {!this.props.equipamento.batch ? <td style={{ textAlign: 'center', color: corFont }}>{a.evento.fluxo ? a.rejeitado_acumulado : ''}</td> : null}
                      {<td style={{ textAlign: 'center', color: corFont }}><i style={{ cursor: 'pointer' }} onClick={() => this.props.handleModal('editar_obs', a)} className="material-icons">chat_bubble_outline</i></td>}
                      {!validar ? <td> </td> : <td style={{ textAlign: 'center', color: corFont }}><i id='iconMA' style={{ color: a.estadoNext ? lookUpNext[a.estadoNext].color : lookUpPrev[a.estado].color }} title={msgAfter} className={a.estadoNext ? lookUpNext[a.estadoNext].className : lookUpPrev[a.estado].className}></i></td>}
                      {<td>
                        <div className='options'>
                          <i className='material-icons' onClick={() => this.props.remove(i)}>cancel</i>
                        </div>
                      </td>}
                    </tr>
                  );
                })
                :
                null
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
