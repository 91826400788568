import React from 'react';
import styles from './TextTag.css';

export default class TextTag extends React.Component {

	constructor(props) {
		super();
		this.state = {
			isKeyReleased: false,
			inputTag: '',
			tags: props.tags,
			labelTags: props.labelTags,
			limit: props.limit,
			style: props.style
		};

		this.onKeyDown = this.onKeyDown.bind(this);
    	this.onKeyUp = this.onKeyUp.bind(this);
    	this.deleteTag = this.deleteTag.bind(this);
		this.handleChangeInputTag = this.handleChangeInputTag.bind(this);
	}

	onKeyDown(event) {
		const { key } = event;
    	const trimmedInput = this.state.inputTag.trim();
    
    	if ((key === ';' || key === 'Enter') && trimmedInput.length && !this.state.tags.includes(trimmedInput))
		{

      		event.preventDefault();
			var value = [...this.props.tags, trimmedInput];
			if(this.state.limit !== undefined && value.length > this.state.limit) return;
			this.setState({tags: value});
      		this.props.onInput(value);
      		this.setState({inputTag: ''});
    	}

    	if (key === 'Backspace'
			&& !this.state.inputTag.length
			&& this.state.tags.length
			&& this.state.isKeyReleased)
		{
      		const tagsCopy = [...this.state.tags];
      		const poppedTag = tagsCopy.pop();

      		event.preventDefault();

			this.setState({tags: tagsCopy});
      		this.props.onInput(tagsCopy);
      		this.setState({inputTag: poppedTag});
    	}

    	this.setState({isKeyReleased: false});
	}

	onKeyUp() {
		this.setState({isKeyReleased: true});
	}

	handleChangeInputTag(event) {
		this.setState({inputTag: event.target.value});
	}

	deleteTag(index) {		
		var newTags = this.props.tags.filter((tag, i) => i !== index);
		this.setState({tags: newTags});
    	this.props.onInput(newTags);
	}

	render() {
		return <div className={styles.component} style={this.state.style}>
				<label style={{textTransform: 'uppercase'}}>
					{this.state.labelTags}
				</label>
				<div className={styles.container}>
					<input
						value={this.state.inputTag}
						id={this.state.inputTag}
						placeholder={this.props.placeHolder}
						onKeyDown={this.onKeyDown}
						onKeyUp={this.onKeyUp}
						onChange={this.handleChangeInputTag}
					/>
					{this.props.tags.map((tag, index) => (
							<div className={styles.tag}>
							<div className={styles.tag_button} onClick={() => this.deleteTag(index)}>
									<div className={styles.tab_button_div}>x</div>
							</div>
							<div className={styles.tag_value}>
									{tag}
							</div>
							</div>
					))}              
				</div>
		</div>;   
	}
}