import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
moment.locale('pt-br');
import './batchComments.scss';

class BatchCommentsCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            maxChars: 100
        }

        this.setExpanded = this.setExpanded.bind(this);
    }

    setExpanded() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        let { comentario, userName } = this.props;

        return (
            <div className="container-cards" onClick={() => (this.setExpanded())}>
                <div className={`card-comment ${this.state.expanded ? 'expanded' : ''}`}>
                    <div>
                        <div className="chat-avatar-circle small">
                            <span className="initials">{this.props.getInitials(comentario.user)}</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-data">
                            <div className="card-username">
                                {comentario.user}
                                <span className="timestamp">
                                    {moment(comentario.date).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
                            </div>

                            <div className="card-message">{comentario.text}</div>
                            {userName === comentario.user && <div style={{ marginTop: '5px' }}><span className="comment-remove" onClick={() => this.props.removerComentarios(comentario.uuid)}>{this.props.t('actions.remove')}</span></div>}
                            {!this.state.expanded && comentario.text.length > this.state.maxChars && <div className="modal-fade" />}
                        </div>
                        {
                            comentario.text.length > this.state.maxChars
                                ?
                                <div className="more-info">{this.state.expanded ? this.props.t('labels.lessInfo') : this.props.t('labels.moreInfo')}</div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation("flowView")(BatchCommentsCard);