import React, { Component } from 'react';
import Container from '../../common/Container';
import { uuidv4 } from '../../common/helpers';
import { Card, CardHeader } from 'material-ui/Card';
import styles from './mainNote.scss';
import TextField from 'material-ui/TextField';
import { AutoComplete } from 'material-ui';
import { NDatePickerInput, NButton, NForm, NFormTitle, NContainer, NButtonGroup, NInputTime, NInputNumber, alertBox } from '../../common/NForm';
import RaisedButton from 'material-ui/RaisedButton';
import Util from '../../utils/Utils';
import moment from 'moment';
import { some } from 'lodash';
import $ from 'jquery';
import update from 'immutability-helper';
import Quest from '../../common/Quest';
import Notifier from '../../utils/Notifier';
import { withRouter } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import Button from '../../common/Button';
import { request } from '../../common/request';
import { respostaEquipamentos } from '../../common/mappers';
import MDSpinner from 'react-md-spinner';
import FracionarModal from '../../common/Modal/apontamentos/fracionarApontamento';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

function adicionaSeCampoNaoNull(arr, campo, valor) {
  if (campo) {
    arr.push([campo, valor]);
  }
}

class MainNoteExport extends Container {
  constructor(props) {
    super(props);

    this.state = {
      indexAlterar: null,
      modal: false,
      modalType: '',
      apontamentos: [],
      equipamentos: '',
      equipamentoInformado: {},
      dataInicialInformado: '',
      dataFinalInformado: '',

      eventos: [],
      opcEventos: [],
      insEvento: '',
      apontEvento: {},
      labelEvento: this.props.t("event"),
      erroEVENTO: '',
      searchTextEvento: '',
      textEvento: '',

      insDataInicial: '',
      insHoraInicial: ' ',
      insDataFinal: '',
      insHoraFinal: ' ',
      insLote: '',
      insProduzido: '',
      insRejeitado: '',
      insUuid: undefined,
      insEstado: undefined,
      insClasseEstrutura: undefined,
      insTipo: undefined,
      campo1: '',
      campo2: '',
      campo3: '',
      campo4: '',
      campoText1: '',
      campoText2: '',
      campoText3: '',
      campoText4: '',
      labelLote: this.utils.getReferenciaLoteOrdem(),
      apontLote: {},
      erroDATAINICIAL: '',
      erroHORAINICIAL: '',
      erroDATAFINAL: '',
      erroHORAFINAL: '',
      erroLOTE: '',
      itemApont: {},
      editObs1: '',
      editObs2: '',
      editObs3: '',
      editObs4: '',
      validar: false,
      acaoBotaoAuxiliar: 'validar',
      erroMSG: '',
      disableInsert: false,
      loadingPage: true,
      camposPersonalizado: [],
      camposPersonalizadoSelecionado: [],
      disabledButton: false,
      listaTemporariaArray: [],
      tipoProducao: ''
    };

    this.baseState = {
      eventos: [],
      opcEventos: [],
      insEvento: '',
      apontEvento: {},
      labelEvento: this.props.t("event"),
      erroEVENTO: '',
      searchTextEvento: '',
      textEvento: '',

      insDataInicial: '',
      insHoraInicial: ' ',
      insDataFinal: '',
      insHoraFinal: ' ',
      insLote: '',
      insProduzido: '',
      insRejeitado: '',
      campo1: '',
      campo2: '',
      campo3: '',
      campo4: '',
      campoText1: '',
      campoText2: '',
      campoText3: '',
      campoText4: '',
      labelLote: this.utils.getReferenciaLoteOrdem(),
      apontLote: {},
      erroDATAINICIAL: '',
      erroHORAINICIAL: '',
      erroDATAFINAL: '',
      erroHORAFINAL: '',
      erroLOTE: '',
      tipoProducao: ''
    }

    this.editarObservacoes = this.editarObservacoes.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.clearState = this.clearState.bind(this);
    this.handleEvento = this.handleEvento.bind(this);
    this.handleLote = this.handleLote.bind(this);
    this.inserirApontamento = this.inserirApontamento.bind(this);
    this.deleteApontamento = this.deleteApontamento.bind(this);
    this.editarApontamento = this.editarApontamento.bind(this);
    this.validarApontamento = this.validarApontamento.bind(this);
    this.encerrarApontamento = this.encerrarApontamento.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.redirectFlowView = this.redirectFlowView.bind(this);
    this.redirectNotes = this.redirectNotes.bind(this);
    this.confirmarEncerramento = this.confirmarEncerramento.bind(this);
    this.carregarCamposPersonalizados = this.carregarCamposPersonalizados.bind(this);
    this.desabilitarBotao = this.desabilitarBotao.bind(this);
    this.criarNovoApontamento = this.criarNovoApontamento.bind(this);
    this.labelBotaoAuxiliar = this.labelBotaoAuxiliar.bind(this);
    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.splitApontamento = this.splitApontamento.bind(this);
    this._validateHourEnd = this._validateHourEnd.bind(this);
    this._valiDateEnd = this._valiDateEnd.bind(this);
    this._valiDateStart = this._valiDateStart.bind(this);
    this.acaoBotaoAuxiliar = this.acaoBotaoAuxiliar.bind(this);
    this.requestValidarApontamento = this.requestValidarApontamento.bind(this);
    this.handleEditObs = this.handleEditObs.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleTime = this.handleTime.bind(this);
  }

  componentWillMount() {
    request(this.endpoints.SGCA_AUTENTICAR + '/validar?modulo=apontamento/manutencao', {
      method: 'get'
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 300) {
          const paramId = this.parseQueryString('id');
          const paramDataFim = this.parseQueryString('dataFim');
          const paramDataInicio = this.parseQueryString('dataInicio');

          var apontamentoUnion, erroDate = false;

          if (moment(paramDataFim, 'DD/MM/YYYY').diff(moment(paramDataInicio, 'DD/MM/YYYY'), 'days') > 7) {
            this.props.history.push('/apontamento');
            erroDate = true;
          }

          if (!erroDate) {
            var requisicaoApontamentoNormal =
              request(this.endpoints.APONTAMENTOS_EQUIPAMENTO + `/${paramId}/${paramDataInicio}/${paramDataFim}`,
                {
                  method: 'get'
                }, true);

            var requisicaoApontamentoRejeitado =
              request(this.endpoints.APONTAMENTOS_EQUIPAMENTO + `/${paramId}/${paramDataInicio}/${paramDataFim}?rejeitado=true`,
                {
                  method: 'get'
                }, true);

            Promise.all([
              requisicaoApontamentoNormal,
              requisicaoApontamentoRejeitado
            ]).then(response => {
              const responseApontamentoNormal = response[0];
              if (responseApontamentoNormal.ok) {
                return Promise.all([responseApontamentoNormal.json(), response[1].json()]);
              }

              if (responseApontamentoNormal.status == 422) {
                responseApontamentoNormal.json().then((responseFail) => {
                  alertBox(responseFail.data.msg, responseFail.data.title, 'error');

                  this.setState({
                    apontamentos: [],
                    dataInicialInformado: moment(paramDataInicio, 'DD/MM/YYYY'),
                    dataFinalInformado: moment(paramDataFim, 'DD/MM/YYYY'),
                    loadingPage: false
                  });
                });
              }

            }).then(response => {
              if (!response) {
                this.redirectNotes();
              } else {

                apontamentoUnion = response[0].map((a) => ({
                  ...a,
                  estado: a.classe_estrutura.status ? 'Aceito' : 'Inconsistente',
                  sujo: false,
                })).concat(response[1].map((a) => ({
                  ...a,
                  estado: 'Rejeitado',
                  sujo: false
                })));

                this.setState({
                  apontamentos: apontamentoUnion,
                  dataInicialInformado: moment(paramDataInicio, 'DD/MM/YYYY'),
                  dataFinalInformado: moment(paramDataFim, 'DD/MM/YYYY'),
                  loadingPage: false
                });
              }
            });

            request(this.endpoints.EQUIPAMENTOS, {
              method: 'get'
            }, true)
              .then(function (response) {
                if (response.status == 200) return response.json();
              })
              .then((data) => {
                data = respostaEquipamentos(data);
                let findEquip = data.find(e => e.id == paramId);
                this.setState({
                  equipamentos: data,
                  equipamentoInformado: findEquip
                });
              });

            request(`${this.endpoints.EVENTO}/classe`, {
              method: 'get'
            }, true)
              .then(function (response) {
                if (response.status == 200) return response.json();
              })
              .then((data) => {
                let opcEventos = data.map((evento) => {
                  return { value: evento.codigo, text: evento.codigo + ' - ' + evento.nome };
                });

                opcEventos.sort((a, b) => a.value - b.value);

                this.setState({
                  eventos: data,
                  opcEventos: opcEventos
                });
              });
          }
        } else if (response.status == 401) {
          this.setState({
            loadingPage: false,
          });
        }
      });
  }

  carregarCamposPersonalizados(equipamento, evento, modelo, callback) {
    if (equipamento && evento) {
      request(`${this.endpoints.CAMPOS_PERSONALIZADOS}/por-equipamento-evento`, {
        method: 'POST',
        body: JSON.stringify({ item1: { id: equipamento.id }, item2: { id: evento.id } }),
      }).then(response => {
        if (response.status == 200) return response.json();
      }).then(data => {
        this.setState({
          [modelo]: data || []
        }, () => {
          if (callback) { callback(); }
        });
      }).catch(error => {
        this.setState({
          [modelo]: []
        });
      });
    }
  }

  handleModal(type, item, erro) {
    this.setState({
      modal: !this.state.modal,
      modalType: type,
      itemApont: item,
      erroMSG: erro
    });

    if (type == 'editar_obs') {
      this.carregarCamposPersonalizados(this.state.equipamentoInformado, item.evento, 'camposPersonalizadoSelecionado', () => {
        this.setState({
          editObs1: item.observacao || '',
          editObs2: item.observacao1 || '',
          editObs3: item.observacao2 || '',
          editObs4: item.observacao3 || '',
        });
      });
    }

    if (type == 'clear') {
      this.clearState();
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const { opcEventos } = this.state;
      const currentText = event.target.value;

      const filteredOptions = opcEventos.filter(option =>
        option.text.toLowerCase().includes(currentText.toLowerCase())
      );

      if (filteredOptions.length > 0) {
        const selectedString = filteredOptions[0].text;

        this.handleEvento(selectedString, opcEventos);

        const dataInicialElement = document.getElementById('dataInicial');

        if (dataInicialElement) {
          dataInicialElement.focus();
        }
      }
    }
  };

  handleEvento(selectedString, collection) {
    this.setState({ searchTextEvento: selectedString, textEvento: '' });

    let selectedItem = collection.find(item => item.text == selectedString);
    let eventCode = selectedItem && selectedItem.value;
    let event = this.state.eventos.find(e => e.codigo == eventCode);

    this.carregarCamposPersonalizados(this.state.equipamentoInformado, event, 'camposPersonalizado');

    if (event != undefined) {
      this.setState({
        labelEvento: event.nome,
        apontEvento: event,
        numClasse: event.classe,
        erroEVENTO: '',
        eventoText: selectedString,
        insProduzido: 0,
        insRejeitado: 0
      });
    } else {
      this.setState({
        labelEvento: this.props.t("event"),
        apontEvento: '',
        insProduzido: 0,
        insRejeitado: 0
      });
    }
  };

  async handleLote() {
    await request(this.endpoints.LOTE_NUMERO + `/${this.state.insLote}?finalizado=true`)
      .then(function (response) {
        if (response.status == 200) return response.json();
      })
      .then(data => {
        if (data) {
          this.setState({
            labelLote: data.produto.nome,
            apontLote: data,
            disableInsert: true
          });
        } else {
          this.setState({
            disableInsert: false,
            labelLote: this.utils.getReferenciaLoteOrdem()
          });
          alertBox(`${this.utils.getReferenciaLoteOrdemNaoEncontrada()}`, this.utils.getReferenciaLoteOrdem(), 'error');
        }
      });
  }

  errorMessage() {
    return (
      <div className="modal" style={{ width: '500px', height: '100%' }}>
        <div>
          <div style={{ textAlign: 'left' }} >
            {this.state.erroMSG}
          </div>
          <div>
            <i className="fa fa-close cursor-pointer" onClick={() => this.handleModal()} />
          </div>
        </div>
        <div style={{ paddingTop: '1em' }} >
          E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
        </div>
      </div>
    );
  }

  async inserirApontamento() {
    let erroCampo = false;
    let erroProducao = false;
    let eventoFimDeLote;
    let somaProducao = 0;

    let {
      insHoraInicial,
      insHoraFinal
    } = this.state;

    var novoApont = {};
    var novoEvento = {};
    var apontExcluido = [];
    var inicio, fim;
    var campos = [];

    let erroHoraInicial = !insHoraInicial.trim().length;
    let erroHoraFinal = this.state.apontEvento.instantaneo ? false : !insHoraFinal.trim().length;

    erroHoraInicial ? this.setState({ erroHORAINICIAL: this.props.t("validations.requiredField") }) : this.setState({ erroHORAINICIAL: '' });
    erroHoraFinal ? this.setState({ erroHORAFINAL: this.props.t("validations.requiredField") }) : this.setState({ erroHORAFINAL: '' });

    if (erroHoraInicial || erroHoraFinal) erroCampo = true;

    this.state.camposPersonalizado.forEach((campo, i) => {
      adicionaSeCampoNaoNull(campos, campo, this.state[`campo${i + 1}`]);
    });

    campos.forEach(([campo, valor], i) => {
      if (campo.obrigatorio && !valor) {
        erroCampo = true;

        this.setState({
          [`erroCAMPO${i + 1}`]: this.props.t("validations.requiredField"),
          validar: false,
          acaoBotaoAuxiliar: 'validar'
        });
      } else {
        this.setState({
          [`erroCAMPO${i + 1}`]: null,
        });
      }
    });

    inicio = document.getElementById('dataInicial').value;

    if (this.state.apontEvento.instantaneo) {
      fim = document.getElementById('dataInicial').value;
    } else {
      fim = document.getElementById('dataFinal').value;
    }

    if (this.state.apontEvento.fluxo ? this.state.disableInsert && this.state.apontEvento.fluxo : !this.state.apontEvento.fluxo) {
      novoEvento['Evento'] = this.state.apontEvento;
      novoEvento['HoraInicial'] = this.state.insHoraInicial;

      if (!this.state.apontEvento.instantaneo) {
        novoEvento['HoraFinal'] = this.state.insHoraFinal;
      } else {
        novoEvento['HoraFinal'] = this.state.insHoraInicial;
      }

      this.state.apontEvento.fluxo ? novoEvento['Lote'] = this.state.insLote : null;

      for (var i in novoEvento) {
        if (!novoEvento[i]) {
          this.setState({
            ['erro' + i.toUpperCase()]: this.props.t("validations.requiredField"),
            validar: false,
            acaoBotaoAuxiliar: 'validar'
          });
          erroCampo = true;
        } else {
          if (i.toUpperCase() === 'EVENTO') this.setState({ ['erro' + i.toUpperCase()]: '' });
        }
      }

      if (!inicio) {
        this.setState({
          erroDATAINICIAL: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroDATAINICIAL: '',
        });
        novoEvento['DataInicial'] = moment(inicio, 'L');
      }

      if (!fim) {
        this.setState({
          erroDATAFINAL: this.props.t("validations.requiredField"),
        });
        erroCampo = true;
      } else {
        this.setState({
          erroDATAFINAL: '',
        });
        novoEvento['DataFinal'] = moment(fim, 'L');
      }

      var dataInicialManut = this.state.dataInicialInformado;
      var dataFinalManut = this.state.dataFinalInformado;

      if (((moment(inicio, 'L').isBefore(dataInicialManut)) || (moment(fim, 'L').isAfter(dataFinalManut)))) {
        this.store.errors = this.props.t("messages.dateMaintenanceInterval");
        erroCampo = true;
      }

      if ((moment(novoEvento['DataFinal'].format('L') + ' ' + novoEvento['HoraFinal'], 'L LTS').isBefore(moment(novoEvento['DataInicial'].format('L') + ' ' + novoEvento['HoraInicial'], 'L LTS')))) {
        erroCampo = true;
      }

      eventoFimDeLote = this.state.eventos.find(e => e.classe_mobile.id == 12);

      if (!this.state.equipamentoInformado.is_batch && (this.state.apontEvento.id == eventoFimDeLote.id) && this.state.apontEvento.fluxo) {
        if (((parseInt(this.state.insProduzido, 10) ? parseInt(this.state.insProduzido, 10) : 0) + (parseInt(this.state.insRejeitado, 10) ? parseInt(this.state.insRejeitado, 10) : 0)) > 0) {
          somaProducao += ((parseInt(this.state.insProduzido, 10) ? parseInt(this.state.insProduzido, 10) : 0) + (parseInt(this.state.insRejeitado, 10) ? parseInt(this.state.insRejeitado, 10) : 0));
        }

        if (somaProducao == 0) {
          this.state.apontamentos.map(a => {
            if (a.lote && !a.tipo && a.tipo !== 'Excluir' && a.lote.id == this.state.apontLote.id) {
              somaProducao += a.produzido_acumulado + a.rejeitado_acumulado;
            }

            if (a.tipo && a.tipo == 'Excluir' && a.produzido_acumulado > 0) {
              apontExcluido.push(a.uuid);
            }
          });
        }

        if (somaProducao == 0) {
          await request(this.endpoints.APONTAMENTOS_LOTE_EQUIPAMENTO + `/${this.state.apontLote.id}/${this.state.equipamentoInformado.id}`)
            .then(function (response) {
              if (response.status == 200) return response.json();
            }).then(data => {
              data.map((a) => {
                if (!apontExcluido.includes(a.uuid)) { somaProducao += a.produzido_acumulado + a.rejeitado_acumulado; }
              });
            });
        }

        if (!(somaProducao > 0)) {
          erroProducao = true;
        }
      }

      if (erroProducao) {
        alertBox(this.props.t("messages.requiredProducedQuantity"), this.props.t("note"), 'warning');
      }

      if (!erroCampo) {
        novoApont['inicio'] = moment(novoEvento['DataInicial'].format('L') + ' ' + novoEvento['HoraInicial'], 'L LTS').format('YYYY-MM-DDTHH:mm:ss');
        novoApont['fim'] = moment(novoEvento['DataFinal'].format('L') + ' ' + novoEvento['HoraFinal'], 'L LTS').format('YYYY-MM-DDTHH:mm:ss');
        novoApont['evento'] = this.state.apontEvento;
        novoApont['equipamento'] = this.state.equipamentoInformado;

        this.state.apontEvento.fluxo
          ? novoApont['lote'] = this.state.apontLote
          : novoApont['lote'] = null;

        novoApont['observacao'] = this.state.campo1;
        novoApont['observacao1'] = this.state.campo2;
        novoApont['observacao2'] = this.state.campo3;
        novoApont['observacao3'] = this.state.campo4;
        novoApont["tipo_producao"] = this.state.tipoProducao;

        this.state.camposPersonalizado.map((campo, index) => {
          novoApont[`nome_campo${index ? index : ''}`] = campo.nome;
        });

        novoApont['produzido_acumulado'] = parseInt(this.state.insProduzido, 10) ? parseInt(this.state.insProduzido, 10) : 0;
        novoApont['rejeitado_acumulado'] = parseInt(this.state.insRejeitado, 10) ? parseInt(this.state.insRejeitado, 10) : 0;

        novoApont['criado_usuario'] = {
          id: parseInt(localStorage.getItem('id')),
          nome: localStorage.getItem('login')
        };

        novoApont['atualizado_usuario'] = null;
        novoApont['excluido_usuario'] = null;
        novoApont['uuid'] = this.state.insUuid ? this.state.insUuid : uuidv4();
        novoApont['tipo'] = this.state.insTipo ? this.state.insTipo : 'Criar';
        novoApont['estado'] = this.state.insEstado ? this.state.insEstado : 'Aceito';
        novoApont['sujo'] = true;
        novoApont['origem_apontamento'] = this.origemApontamento.MANUTENCAO_APONTAMENTO;
        novoApont['classe_estrutura'] = this.state.insClasseEstrutura;

        if (this.state.indexAlterar != null) {
          novoApont = {
            ...this.state.apontamentos[this.state.indexAlterar],
            ...novoApont
          }
          this.setState(update(this.state, { apontamentos: { [this.state.indexAlterar]: { $set: novoApont } } }));
        } else {
          this.setState(update(this.state, { apontamentos: { $push: [novoApont] } }));
        }

        this.clearState();
      }
    }
  }

  clearState() {
    this.setState({
      validar: false,
      acaoBotaoAuxiliar: 'validar',
      disableInsert: false,
      insEvento: '',
      insDataInicial: '',
      insHoraInicial: ' ',
      insDataFinal: '',
      insHoraFinal: ' ',
      insLote: '',
      insProduzido: '',
      insRejeitado: '',
      campo1: '',
      campo2: '',
      campo3: '',
      campo4: '',
      campoText1: '',
      campoText2: '',
      campoText3: '',
      campoText4: '',
      labelEvento: this.props.t("event"),
      labelLote: this.utils.getReferenciaLoteOrdem(),
      apontEvento: '',
      apontLote: '',
      erroEVENTO: '',
      erroDATAINICIAL: '',
      erroHORAINICIAL: '',
      erroDATAFINAL: '',
      erroHORAFINAL: '',
      erroLOTE: '',
      indexAlterar: null,
      insUuid: undefined,
      insEstado: undefined,
      insClasseEstrutura: undefined,
      insTipo: undefined,
      tipoProducao: ''
    },
      () => {
        setTimeout(() => {
          this.state.camposPersonalizado.map((c, i) => {
            $('#campo' + (i + 1)).val('');
            this.setState({
              ['campoText' + (i + 1)]: '',
              ['campo' + (i + 1)]: ''
            });
          });
          $('#cod').val('');
        }, 120);
      });
  }

  deleteApontamento() {
    var item = this.state.itemApont, apontamentoSuporte = {};

    apontamentoSuporte = item;
    apontamentoSuporte['sujo'] = item.tipo == 'Criar' ? false : true;
    apontamentoSuporte['tipo'] = 'Excluir';
    this.setState(update(this.state, { apontamentos: { $splice: [[item.index, 1, apontamentoSuporte]] } }));

    this.setState({
      validar: false,
      acaoBotaoAuxiliar: 'validar'
    });

    this.handleModal();
  }

  criarNovoApontamento(apontamentoAntigo, novoInicio, novoFim) {
    let novoApont = {};

    novoApont.inicio = novoInicio ? moment(novoInicio) : moment(apontamentoAntigo.inicio);
    novoApont.fim = novoFim ? moment(novoFim) : moment(apontamentoAntigo.fim);
    novoApont.inicio = novoApont.inicio.format('YYYY-MM-DDTHH:mm:ss');
    novoApont.fim = novoApont.fim.format('YYYY-MM-DDTHH:mm:ss');
    novoApont.evento = apontamentoAntigo.evento;
    novoApont.equipamento = this.state.equipamentoInformado;
    novoApont.lote = apontamentoAntigo.lote;
    novoApont.observacao = apontamentoAntigo.observacao;
    novoApont.observacao1 = apontamentoAntigo.observacao1;
    novoApont.observacao2 = apontamentoAntigo.observacao2;
    novoApont.observacao3 = apontamentoAntigo.observacao3;
    novoApont.produzido_acumulado = novoFim ? apontamentoAntigo.produzido_acumulado : 0;
    novoApont.rejeitado_acumulado = novoFim ? apontamentoAntigo.rejeitado_acumulado : 0;
    novoApont.produzido = novoFim ? apontamentoAntigo.produzido : 0;
    novoApont.rejeitado = novoFim ? apontamentoAntigo.rejeitado : 0;
    novoApont.uuid = uuidv4();
    novoApont.tipo = 'Criar';
    novoApont.estado = 'Aceito';
    novoApont.sujo = true;
    novoApont.origem_apontamento = this.origemApontamento.MANUTENCAO_APONTAMENTO;

    return novoApont;
  }

  editarApontamento(apontamento) {
    let apontamentoSuporte = apontamento;
    apontamentoSuporte.sujo = !(apontamentoSuporte.tipo == 'Criar');
    apontamentoSuporte.tipo = apontamentoSuporte.tipo == 'Criar' ? 'Criar' : 'Editar';

    this.setState(update(this.state, { apontamentos: { [apontamentoSuporte.index]: { $set: apontamentoSuporte } } }));

    this.setState({
      insDataInicial: moment(apontamentoSuporte.inicio),
      insDataFinal: moment(apontamentoSuporte.fim),
      insHoraInicial: moment(apontamentoSuporte.inicio).format('HH:mm:ss'),
      insHoraFinal: moment(apontamentoSuporte.fim).format('HH:mm:ss'),
      insLote: apontamentoSuporte.lote != null ? apontamentoSuporte.lote.numero : apontamentoSuporte.numero_lote,
      insEvento: apontamentoSuporte.evento.codigo,
      insProduzido: apontamentoSuporte.produzido_acumulado,
      insRejeitado: apontamentoSuporte.rejeitado_acumulado,
      campo1: apontamentoSuporte.observacao,
      campo2: apontamentoSuporte.observacao1,
      campo3: apontamentoSuporte.observacao2,
      campo4: apontamentoSuporte.observacao3,
      indexAlterar: apontamentoSuporte.index,
      acaoBotaoAuxiliar: 'cancelar',
      insUuid: apontamentoSuporte.uuid,
      insEstado: apontamentoSuporte.estado,
      insClasseEstrutura: apontamentoSuporte.classe_estrutura,
      insTipo: apontamentoSuporte.tipo,
      labelEvento: (apontamentoSuporte.evento && apontamentoSuporte.evento.codigo + ' - ' + apontamentoSuporte.evento.nome) || this.props.t("event").toUpperCase(),
      apontEvento: apontamentoSuporte.evento || "",
      tipoProducao: apontamentoSuporte.tipo_producao
    }, () => {
      this.carregarCamposPersonalizados(this.state.equipamentoInformado, apontamentoSuporte.evento, 'camposPersonalizado');
      this.state.insLote != null ? this.handleLote() : false;
    });
  }


  splitApontamento(apontamentoAntesSplit, apontamentoDepoisSplit) {
    var item = this.state.itemApont, apontamentoSuporte = {};

    apontamentoSuporte = item;
    apontamentoSuporte.sujo = item.tipo == 'Criar' ? false : true;
    apontamentoSuporte.tipo = 'Excluir';

    this.setState(update(this.state, { apontamentos: { $splice: [[item.index, 1, apontamentoSuporte]] } }), () => {
      this.setState(update(this.state, { apontamentos: { $push: [apontamentoAntesSplit, apontamentoDepoisSplit] } }), () => {
        Util.ordenaApontamentos(this.state.apontamentos);
      });
    });

    this.handleModal('clear');
  }

  contentModal() {
    switch (this.state.modalType) {
      case 'editar_obs':
        return this.editarObservacoes();

      case 'delete':
        return <Quest question={this.props.t("messages.sureDelete")} action={this.deleteApontamento} handleModal={this.handleModal} />;

      case 'erro':
        return this.errorMessage();

      case 'loading':
        return <div className="modal" id={styles.loading} style={{ width: '80px', height: '100%' }}>
          <MDSpinner singleColor="#2D4F7F" size={60} borderSize={7} />
        </div>;

      case 'confirmar_encerramento':
        return <Quest question={this.props.t("messages.sureUpdate")} action={this.encerrarApontamento}
          handleModal={this.handleModal} disabledButton={this.state.disabledButton} />;

      case 'fracionar':
        return (
          <FracionarModal
            apontamento={this.state.itemApont}
            criarApontamento={this.criarNovoApontamento}
            splitApontamento={this.splitApontamento}
            handleModal={this.handleModal}
          />
        );

      case 'enc_manut':
        return (
          <div className="modal" style={{ width: '500px', height: '100%' }}>
            <div>
              <div>{this.props.t("messages.redirectToFlowView")}</div>
              <div></div>
            </div>

            <div className={'flex flex-row flex-just-end'}>
              <Button button={{ type: 'round', className: 'button_primary', text: this.props.t("yes"), onClick: this.redirectFlowView }} />
              <Button button={{ type: 'round', text: this.props.t("no"), onClick: this.redirectNotes }} />
            </div>
          </div>
        );
    }
  }

  handleEditObs(text, index) {
    this.setState({ ['editObs' + (index)]: text });
  };

  handleStart(date) {
    $('#dataFinal').val('');
    $('#horaFinal').val('');
    this.setState({
      insDataInicial: date,
      insDataFinal: '',
      insHoraFinal: ' '
    });
  };

  editarObservacoes() {
    var item = this.state.itemApont, apontamentoSuporte = {};

    let onSave = () => {
      apontamentoSuporte = item;
      apontamentoSuporte.sujo = true;
      apontamentoSuporte.tipo = item.tipo == 'Criar' ? 'Criar' : 'Editar';

      apontamentoSuporte.observacao = this.state.editObs1;
      apontamentoSuporte.observacao1 = this.state.editObs2;
      apontamentoSuporte.observacao2 = this.state.editObs3;
      apontamentoSuporte.observacao3 = this.state.editObs4;

      this.setState(update(this.state, { apontamentos: { $splice: [[item.index, 1, apontamentoSuporte]] } }));
      this.handleModal();
    };

    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("noteObs")}</NFormTitle>

          <NContainer direction="vertical">
            {this.state.camposPersonalizadoSelecionado.map((c, i) => {
              if (c.habilitado) {
                if (c.tipo == 'numerico') {
                  if (!this.state['editObs' + (i + 1)] || parseInt(this.state['editObs' + (i + 1)], 10) !== NaN) {
                    return (
                      <NInputNumber key={`campo-${i}`}
                        id={'editObs' + (i + 1)}
                        label={c.nome}
                        value={this.state['editObs' + (i + 1)]}
                        fullWidth={true}
                        onChange={(_event, value) => this.setState({ ['editObs' + (i + 1)]: value })}
                        floatingLabelStyle={{
                          color: '#5B5B5B',
                          textTransform: 'uppercase',
                          textOverflow: 'ellipsis',
                          width: '130%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                        }}
                      />
                    );
                  } else {
                    return (
                      <div key={`campo-${i}`} style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                        <i className="material-icons"
                          style={{ position: 'absolute', right: 5, top: 34, width: 20, height: 20, zIndex: 20, cursor: 'pointer' }}
                          onClick={() => this.setState({ ['editObs' + (i + 1)]: '' })}>
                          cancel
                        </i>
                        <TextField width={{ width: '40%' }}
                          disabled={true}
                          fullWidth={true}
                          value={this.state['editObs' + (i + 1)]}
                          floatingLabelText={c.nome}
                          underlineFocusStyle={{ borderColor: '#000000' }}
                          floatingLabelStyle={{
                            color: '#5B5B5B',
                            textTransform: 'uppercase',
                            textOverflow: 'ellipsis',
                            width: '130%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          }}
                        />
                      </div>
                    );
                  }
                } else if (c.tipo == 'selecionar') {
                  var op = c.opcoes ? c.opcoes.find(o => o.nome == this.state['editObs' + (i + 1)]) : {};

                  if (!this.state['editObs' + (i + 1)] || op) {
                    let opcoes = c.opcoes ? c.opcoes.map(o => ({ value: o.nome, text: o.nome })) : [];

                    return (
                      <AutoComplete
                        key={`campo-${i}`}
                        id={'campo' + (i + 1)}
                        fullWidth={true}
                        style={{ maxHeight: '10em' }}
                        floatingLabelText={c.nome}
                        openOnFocus={true}
                        searchText={this.state['editObs' + (i + 1)] == null ? '' : this.state['editObs' + (i + 1)]}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        filter={AutoComplete.fuzzyFilter}
                        floatingLabelStyle={{
                          color: '#5B5B5B',
                          textTransform: 'uppercase',
                          textOverflow: 'ellipsis',
                          width: '130%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                        }}
                        onUpdateInput={(event, value) => this.handleEditCustom(i, event, opcoes)}
                        dataSource={opcoes}
                        onClick={() => { this.setState({ ['editObs' + (i + 1)]: '' }); }}
                        popoverProps={{ className: 'autocomplete' }} />
                    );
                  } else {
                    return (
                      <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                        <i style={{ position: 'absolute', right: 5, top: 34, width: 20, height: 20, zIndex: 20, cursor: 'pointer' }}
                          className="material-icons"
                          onClick={() => this.setState({ ['editObs' + (i + 1)]: '' })}>
                          cancel
                        </i>
                        <TextField key={`campo-${i}`}
                          width={{ width: '40%' }}
                          disabled={true}
                          fullWidth={true}
                          value={this.state['editObs' + (i + 1)]}
                          floatingLabelText={c.nome}
                          underlineFocusStyle={{ borderColor: '#000000' }}
                          floatingLabelStyle={{
                            color: '#5B5B5B',
                            textTransform: 'uppercase',
                            textOverflow: 'ellipsis',
                            width: '130%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          }} />
                      </div>
                    );
                  }
                } else {
                  return (
                    <TextField key={`campo-${i}`} width={{ width: '40%' }}
                      fullWidth={true} value={this.state['editObs' + (i + 1)]}
                      floatingLabelText={c.nome}
                      underlineFocusStyle={{ borderColor: '#000000' }}
                      floatingLabelStyle={{
                        color: '#5B5B5B',
                        fontSize: '.9rem',
                        textTransform: 'uppercase',
                        textOverflow: 'ellipsis',
                        width: '130%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                      onChange={(_event, value) => { this.setState({ ['editObs' + (i + 1)]: value }); }}
                    />
                  );
                }
              }
            })}
          </NContainer>
          <br />
          <NButtonGroup>
            <NButton disabled={!hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR')} onClick={onSave} type="primary">{this.props.t("save")}</NButton>
            <NButton onClick={() => this.setState({ modal: false })}>{this.props.t("cancel")}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  acaoBotaoAuxiliar() {
    switch (this.state.acaoBotaoAuxiliar) {
      case 'validar':
        return this.validarApontamento;

      case 'atualizar':
        return this.confirmarEncerramento;

      case 'cancelar':
        return this.cancelarEdicao;
    }
  }

  labelBotaoAuxiliar() {
    switch (this.state.acaoBotaoAuxiliar) {
      case 'validar':
        return this.props.t("validate");

      case 'atualizar':
        return this.props.t("update");

      case 'cancelar':
        return this.props.t("cancel");
    }
  }

  cancelarEdicao() {
    let apontamentoSuporte = this.state.apontamentos[this.state.indexAlterar];
    apontamentoSuporte.tipo = (apontamentoSuporte.tipo == 'Criar' || apontamentoSuporte.tipo == 'Excluir') ? 'Criar' : undefined;

    this.setState(update(this.state, { apontamentos: { $splice: [[this.state.indexAlterar, 1, apontamentoSuporte]] } }));

    this.clearState();
  }

  async validarApontamento() {
    this.state.apontamentos.filter(a => a.tipo == undefined && a.estado == 'Rejeitado').map(m => { m.tipo = 'Editar' });

    let apontRemoveTemp = this.state.apontamentos.filter(a => a.tipo == 'Excluir');
    let apontRemove = Util.ordenaApontamentos(apontRemoveTemp);

    let validEditTemp = this.state.apontamentos.filter(a => a.tipo !== 'Excluir');
    let validEdit = Util.ordenaApontamentos(validEditTemp);

    let validApont = apontRemove.concat(validEdit).filter(a => a.tipo).map(a => ({
      apontamento: { ...a, produzido_acumulado: a.produzido_acumulado, rejeitado_acumulado: a.rejeitado_acumulado },
      tipo: a.tipo
    })).map(a => {
      if (a.tipo == 'Editar') {
        a.apontamento = {
          ...a.apontamento,
          atualizado_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      } else if (a.tipo == 'Criar') {
        a.apontamento = {
          ...a.apontamento,
          criado_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      } else if (a.tipo == 'Excluir') {
        a.apontamento = {
          ...a.apontamento,
          excluido_usuario: {
            id: parseInt(localStorage['id']),
            username: localStorage['login']
          }
        };
      }

      return a;
    });

    validApont = Util.limparApontamentos(validApont);
    validApont = Util.ordenaApontamentos(validApont);

    var apontamentosHash = this.state.apontamentos.reduce((map, obj) => {
      map[obj.uuid] = obj;
      return map;
    }, {});

    this.handleModal('loading');

    await this.requestValidarApontamento(validApont).then((data) => {
      let hasRejectedNotes = false;
      let changedNotes = 0;

      data.forEach(a => {
        let apontamentoListado = apontamentosHash[a.apontamento.uuid];

        if (apontamentoListado) {
          let estado = Util.parseEstadoApontamento(a.estado);

          if (estado === apontamentoListado.estado &&
            a.apontamento.classe === apontamentoListado.classe &&
            a.apontamento.consistente_oee === apontamentoListado.consistente_oee) {
            return;
          }

          changedNotes += 1;

          switch (a.estado) {
            case 0:
              apontamentoListado.estadoNext = 'Aceito';
              apontamentoListado.descricao = a.descricao
              break;

            case 1:
              apontamentoListado.estadoNext = 'Rejeitado';
              switch (a.error) {
                case 'rej4':
                  apontamentoListado.descricao = this.props.t("validations.invalidInterval");
                  break;

                case 'rej5':
                  apontamentoListado.descricao = this.props.t("validations.intervalConflictSameEquipment");
                  break;

                case 'rej6':
                  apontamentoListado.descricao = this.props.t("validations.intervalConflictAnotherEquipment", { batch: this.utils.getReferenciaoLoteaOrdem() });
                  break;

                case 'rej7':
                  apontamentoListado.descricao = this.props.t("validations.multipleNotesSameInterval", { batch: this.utils.getReferenciaoLoteaOrdem().toLowerCase() });
                  break;

                default:
                  apontamentoListado.descricao = a.descricao;
                  break;
              }

              break;

            case 2:
              apontamentoListado.estadoNext = 'Inconsistente';
              apontamentoListado.descricao = a.descricao;
              break;
          }

          if (a.apontamento.classe !== apontamentoListado.classe) {
            apontamentoListado.classeChanged = true;
          }

          apontamentoListado.descricao_oee = a.descricao_oee;
          apontamentoListado.classe = a.apontamento.classe;
          apontamentoListado.consistente_oee = a.apontamento.consistente_oee;

          if (a.estado_oee != 0 && a.estado != 1) {
            apontamentoListado.estadoNext = 'Inconsistente';
          }

          if (apontamentoListado.estadoNext === 'Rejeitado' && apontamentoListado.tipo !== 'Excluir') {
            hasRejectedNotes = true;
          }

          return a;
        }
      })

      if (hasRejectedNotes) {
        this.showAlert(this.props.t("messages.mustReviewRejectedNotes"), this.props.t("warning"), 'error');
      }

      this.setState({
        apontamentos: Object.values(apontamentosHash),
        validar: true,
        acaoBotaoAuxiliar: 'atualizar'
      }),

        this.handleModal();
    }).catch((e) => {
      console.error(e);

      this.setState({
        modal: true,
        modalType: 'erro',
        erroMSG: this.props.t("messages.insertError")
      });
    });
  }

  requestValidarApontamento(validApont) {
    return request(this.endpoints.APONTAMENTOS_VALIDAR, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(validApont)
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        return data.apontamentos;
      });
  }

  desabilitarBotao() {
    this.setState({
      disabledButton: true
    });
  }

  encerrarApontamento() {
    this.desabilitarBotao();
    let erroAPI = false;
    let apontRej = false;

    let encerrarApontamentos = this.state.apontamentos.filter(a => a.tipo)
      .map(a => ({
        apontamento: { ...a, produzido_acumulado: a.produzido_acumulado, rejeitado_acumulado: a.rejeitado_acumulado },
        tipo: a.tipo
      }))
      .map(a => {
        if (a.tipo == 'Editar') {
          a.apontamento = {
            ...a.apontamento,
            atualizado_usuario: {
              id: parseInt(localStorage['id']),
              username: localStorage['login']
            }
          };
        } else if (a.tipo == 'Criar') {
          a.apontamento = {
            ...a.apontamento,
            criado_usuario: {
              id: parseInt(localStorage['id']),
              username: localStorage['login']
            }
          };
        } else if (a.tipo == 'Excluir') {
          a.apontamento = {
            ...a.apontamento,
            excluido_usuario: {
              id: parseInt(localStorage['id']),
              username: localStorage['login']
            }
          };
        }

        return a;
      });

    encerrarApontamentos = Util.limparApontamentos(encerrarApontamentos);

    this.state.apontamentos.map(a => {
      if (a.tipo) {
        if (a.tipo !== 'Excluir' && (a.estadoNext === 'Rejeitado')) {
          apontRej = a;
        }
      } else if (a.estadoNext === 'Rejeitado' || (a.estado === 'Rejeitado' && !a.estadoNext)) {
        apontRej = a;
      }
    });

    if (!apontRej) {
      request(this.endpoints.APONTAMENTO_PROCESSAR, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(encerrarApontamentos)
      })
        .then(function (response) {
          if (response.status == 200) {
            return response.json();
          }
          erroAPI = true;
        })
        .then((data) => {
          if (erroAPI) {
            this.setState({
              modal: true,
              modalType: 'erro',
              erroMSG: this.props.t("messages.updateError")
            });
          }

          this.showAlert(this.props.t("messages.maintenanceSuccess"), this.props.t("success"), 'success');
          this.redirectNotes();
        }).catch((e) => {
          console.error(e);

          this.setState({
            modal: true,
            modalType: 'erro',
            erroMSG: this.props.t("messages.insertError")
          });
        });
    } else {
      this.showAlert(this.props.t("messages.mustReviewRejectedNotes"), this.props.t("warning"), 'error');
    }

    this.setState = ({
      disabledButton: false,
    });
  }

  redirectFlowView() {
    this.props.history.push('/flow-view');
  }

  redirectNotes() {
    this.props.history.push('/apontamento');
  }

  parseQueryString(query) {
    var match = RegExp('[?&]' + query + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  showAlert(msg, title, type) {
    if (type == 'error') {
      Notifier.error(msg || 'Oops!', title || this.props.t("notification"));
      setTimeout(3000);
    }

    if (type == 'success') {
      Notifier.success(msg || 'Oops!', title || this.props.t("notification"));
      setTimeout(5000);
    }
  }

  confirmarEncerramento() {
    let apontRej = false;

    this.state.apontamentos.map((a) => {
      if (a.tipo) {
        if (a.tipo !== 'Excluir' && a.estadoNext === 'Rejeitado') {
          apontRej = a;
        }
      } else if (a.estadoNext === 'Rejeitado' || (a.estado === 'Rejeitado' && !a.estadoNext)) {
        apontRej = a;
      }
    });

    if (!apontRej) {
      this.handleModal('confirmar_encerramento');
    } else {
      this.showAlert(this.props.t("messages.mustReviewRejectedNotes"), this.props.t("warning"), 'error');
    }
  }

  onKeyDownAutoComplete(ref) {
    this.refs[ref].refs.menu.setState({
      isKeyboardFocused: true,
    });
  }

  _valiDateEnd(date) {
    if (date.length !== 10) {
      $('#dataFinal').val();
      $('#horaFinal').val('');

      this.setState({
        insDataFinal: ''
      });

      alertBox(this.props.t("messages.invalidEndDateHour"), this.props.t("messages.invalidEndDate"), 'warning');
    } else if (date.length === 10) {
      // Data selecionada pelo usuario
      var dateMoment = moment(date, 'L');

      // Moment de hoje
      var dateNow = moment();

      //data selecionada é menor q a data atual
      var dataMenor = moment(dateMoment).isAfter(dateNow);

      //Data de incio selecionada pelo usuario formatada dia/mes/ano
      var dateStart = moment(this.state.insDataInicial).format('L');

      // gerar moment da data selecionada pelo usuario concatenada com o horario
      var dataInicio = moment(dateStart + ' ' + this.state.insHoraInicial, 'L LTS');

      // Data final selecionada pelo usuario concatenada ao horario
      var endDate = moment(date + ' ' + this.state.insHoraInicial, 'L LTS');

      if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaFinal').val('');

        this.setState({
          insDataFinal: '',
          insHoraFinal: ' '
        });

        alertBox(this.props.t("messages.futureEndDateHour"), this.props.t("messages.endDate"), 'warning');
      } else if (endDate.isBefore(dataInicio)) {
        $('#dataFinal').val('');
        $('#horaFinal').val('');

        this.setState({
          insDataFinal: '',
          insHoraFinal: ' '
        });

        alertBox(this.props.t("messages.endDateLessStartDate"), this.props.t("messages.invalidEndDateHour"), 'warning');
      }
    }
  }

  //verifica se a data tem 10 digitos para que ele possa digitar a data normalmente
  _valiDateStart(date) {
    //se a quantidade de caracteres digitados for diferente de 10
    if (date.length !== 10) {
      $('#dataFinal').val('');
      $('#horaInicial').val('');

      this.setState({
        insDataInicial: ''
      });

      alertBox(this.props.t("messages.invalidStartDate"), this.props.t("startDate"), 'warning');
    }
    //se a quantidade de caracteres for igual a 10
    else if (date.length === 10) {

      //data digitada
      var dateMoment = moment(date, 'L');

      //data de hoje
      var dateNow = moment();

      //data selecionada é menor q a data atual
      var dataMenor = moment(dateMoment).isAfter(dateNow);

      //verifica se a dateMoment selecionada é valida
      if (!dateMoment.isValid()) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');

        this.setState({
          insDataInicial: ''
        });

        alertBox(this.props.t("messages.invalidStartDate"), this.props.t("startDate"), 'warning');
      }

      //verifica se a data selecionada é menor que a data de hoje
      if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');

        this.setState({
          insDataInicial: ''
        });

        alertBox(this.props.t("messages.futureStartDate"), this.props.t("startDate"), 'warning');
      }
    }
  }

  _validateHourEnd(endTime) {
    //Data de início selecionada pelo usuário
    var dateStart = moment(this.state.insDataInicial).format('L');

    // Gera Moment da data selecionada pelo usuário concatenada com o horário
    var dataInicio = moment(dateStart + ' ' + this.state.insHoraInicial, 'L LTS');

    //Data de fim selecionada pelo usuário
    var dateEnd = moment(this.state.insDataFinal).format('L');

    // gerar moment da data selecionada pelo usuario concatenada com o horario
    var dataFim = moment(dateEnd + ' ' + endTime, 'L LTS');

    if (dataFim.isBefore(dataInicio)) {
      this.setState({
        insHoraFinal: ' '
      });

      if ($('#horaFinal').val() != '') {
        alertBox(this.props.t("messages.endDateLessStartDate"), this.props.t("messages.invalidEndDateHour"), 'warning');
        $('#horaFinal').val('');
        $('#horaFinal').focus();
      }
    }
  }

  handleTime(end) {
    if (this.state.insDataInicial === '') {
      $('#dataFinal').val('');
      $('#horaFinal').val('');
      $('#dataInicial').focus();
      this.setState({
        insDataFinal: ''
      });

      alertBox(this.props.t("messages.requiredStartDate"), this.props.t("startDate"), 'warning');
    }

    if (end === 'end') this.props.validation('events', this.state.equipment, this.state.product);
    else this.setState({ insDataFinal: end });
  }

  onKeyDown(e) {
    this.autoComplete.refs.menu.setState({
      isKeyboardFocused: true,
    });
  }

  dataFimKeyChange(event) {
    var char = event.keyCode;
    var now = '';
    now = moment();

    if (event.target.value === '') {
      if (char === 9) {
        this.setState({
          insDataFinal: ''
        });
        this.setState({
          insDataFinal: now
        });
      }
    }
  }

  handleUpdateCustom = (i, event, opcoes) => {
    const value = event;
    const opcoesList = opcoes.map(opcao => opcao.value);

    if (!opcoesList.includes(value)) {
      this.setState({
        ['campo' + (i + 1)]: '',
        ['campoText' + (i + 1)]: '',
        ['erroCAMPO' + (i + 1)]: ''
      });
    } else {
      this.setState({
        ['campo' + (i + 1)]: value,
        ['campoText' + (i + 1)]: value,
        ['erroCAMPO' + (i + 1)]: ''
      });
    }
  };

  handleEditCustom = (i, event, opcoes) => {
    const value = event;
    const opcoesList = opcoes.map(opcao => opcao.value);

    if (!opcoesList.includes(value)) {
      this.setState({ ['editObs' + (i + 1)]: '' });
    } else {
      this.setState({ ['editObs' + (i + 1)]: value });
    }
  };

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor="#2D4F7F" size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container modal={this.state.modal} styleModal={{ width: this.state.modalType == 'editar_obs' ? '40%' : null }} contentModal={this.contentModal}>
          <div className="box_list">
            <Card className="box_list_card">
              <CardHeader className="box_header" title={this.props.t("notesMaintenance")} />
              <div className={`${styles.formContainer} row`}>
                <div className={'col-sm-10 col-xs-12'} >
                  <div className="row">
                    <div className="col-sm-2">
                      <TextField
                        value={this.state.equipamentoInformado.name}
                        disabled={true}
                        floatingLabelText={this.props.t("equipment")}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        fullWidth={true}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      />
                    </div>
                    <div className="col-sm-2">
                      <AutoComplete
                        id={'cod'}
                        name={'cod'}
                        className={styles.fontForm}
                        dataSource={this.state.opcEventos}
                        fullWidth={true}
                        floatingLabelText={this.state.labelEvento ? this.state.labelEvento : this.props.t("event")}
                        underlineStyle={this.state.erroEVENTO ? { borderColor: 'red' } : null}
                        searchText={this.state.searchTextEvento == '' ? this.state.textEvento : this.state.searchTextEvento}
                        errorText={this.state.erroEVENTO ? this.props.t("validations.requiredField") : null}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        filter={AutoComplete.fuzzyFilter}
                        onUpdateInput={this.handleEvento}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onClose={(event) => $('#dataInicial').focus()}
                        ref={ref => this.autoComplete = ref}
                        onClick={() => {
                          this.setState({
                            insEvento: '',
                            labelEvento: '',
                            erroEVENTO: '',
                            eventoText: '',
                            searchTextEvento: '',
                            textEvento: ''
                          });
                        }}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', whiteSpace: 'nowrap' }}
                        openOnFocus={true}
                        popoverProps={{ className: 'autocomplete', tabIndex: 1 }}
                      />
                    </div>
                    <div className="col-sm-2">
                      <NDatePickerInput
                        require={this.state.erroDATAINICIAL}
                        id="dataInicial"
                        value={this.state.insDataInicial}
                        className={styles.dataInicial}
                        fullWidth={true}
                        onChange={date => this.handleStart(date)}
                        onChangeRaw={(_, date) => this.handleStart(date)}
                        onBlur={event => this._valiDateStart(event.target.value)}
                        autoComplete={'off'}
                        floatingLabelStyle={{ color: '#5B5B5B', marginBottom: '0rem', textTransform: 'uppercase' }}
                        style={{ marginRight: '2em' }}
                        minDate={this.state.dataInicialInformado}
                        maxDate={this.state.insDataFinal || this.state.dataFinalInformado}
                        label={!this.state.apontEvento.instantaneo ? this.props.t("startDate") : this.props.t("date")}
                      />
                    </div>
                    <div className="col-sm-2">
                      <NInputTime
                        type="time"
                        errorText={this.state.erroHORAINICIAL}
                        className={styles.horaInicial}
                        fullWidth={true}
                        onChange={(_event, horaInicial) => this.setState({ insHoraInicial: horaInicial })}
                        step={'1'}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', background: '#fff', whiteSpace: 'nowrap' }}
                        value={this.state.insHoraInicial}
                        id="horaInicial"
                        label={!this.state.apontEvento.instantaneo ? this.props.t("startHour") : this.props.t("hour")}
                        require={true}
                      />
                    </div>
                    {
                      !this.state.apontEvento.instantaneo ?
                        <div className="col-sm-2">
                          <NDatePickerInput
                            require={this.state.erroDATAFINAL}
                            id="dataFinal"
                            value={this.state.insDataFinal}
                            className={styles.dataFinal}
                            fullWidth={true}
                            autoComplete={'off'}
                            floatingLabelStyle={{ color: '#5B5B5B', marginBottom: '0rem', textTransform: 'uppercase' }}
                            style={{ marginRight: '2em' }}
                            minDate={this.state.insDataInicial || this.state.dataInicialInformado}
                            maxDate={this.state.dataFinalInformado}
                            onBlur={event => this._valiDateEnd(event.target.value)}
                            onKeyDown={event => this.dataFimKeyChange(event)}
                            onSelect={date => this.handleTime(date)}
                            onChange={date => this.handleTime(date)}
                            onChangeRaw={(_, date) => this.handleTime(date)}
                            label={this.props.t("endDate")}
                          />
                        </div>
                        : null
                    }

                    {
                      !this.state.apontEvento.instantaneo ?
                        <div className="col-sm-2">
                          <NInputTime
                            type="time"
                            errorText={this.state.erroHORAFINAL}
                            className={styles.horaFinal}
                            fullWidth={true}
                            onChange={(_event, horaFinal) => this.setState({ insHoraFinal: horaFinal })}
                            step={'1'}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', width: '130%', overflow: 'hidden', background: '#fff', whiteSpace: 'nowrap' }}
                            value={this.state.insHoraFinal}
                            id="horaFinal"
                            label={this.props.t("endHour")}
                            onBlur={value => this._validateHourEnd(value)}
                            require={true}
                          />
                        </div>
                        : null
                    }

                    {
                      <div className="col-sm-2">
                        <TextField
                          id={"tipoProducao"}
                          floatingLabelText={this.props.t("productionType")}
                          value={this.state.tipoProducao}
                          onChange={(_event, value) => { this.setState({ tipoProducao: value }) }}
                          fullWidth={true}
                          underlineFocusStyle={{ borderColor: "#000000" }}
                          floatingLabelStyle={{ color: "#5B5B5B", textTransform: "uppercase", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "100%" }}
                          floatingLabelFocusStyle={{ maxWidth: "100%" }}
                        />
                      </div>
                    }

                    {
                      this.state.apontEvento.fluxo
                        ?
                        <div className="col-sm-2">
                          <TextField
                            errorText={this.state.erroLOTE}
                            floatingLabelText={this.state.labelLote} value={this.state.insLote}
                            onChange={(_event, value) => { this.setState({ insLote: value }); }}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            onBlur={this.handleLote}
                            fullWidth={true}
                            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '130%' }}
                            floatingLabelFocusStyle={{ maxWidth: '130%' }}
                          />
                        </div>
                        : null
                    }

                    {
                      !this.state.equipamentoInformado.is_batch && this.state.apontEvento.fluxo
                        ?
                        <div className="col-sm-2">
                          <NInputNumber
                            id="produzido"
                            fullWidth={true}
                            label={this.props.t("produced")}
                            value={this.state.insProduzido}
                            onChange={(_event, value) => { this.setState({ insProduzido: value.replace(/[\,\. ]/g, '') }); }}
                          />
                        </div>
                        : null
                    }

                    {
                      !this.state.equipamentoInformado.is_batch && this.state.apontEvento.fluxo
                        ?
                        <div className="col-sm-2">
                          <NInputNumber
                            id="rejeitado"
                            fullWidth={true}
                            label={this.props.t("rejected")}
                            value={this.state.insRejeitado}
                            onChange={(_event, value) => { this.setState({ insRejeitado: value.replace(/[\,\. ]/g, '') }); }}
                          />
                        </div>
                        : null
                    }

                    {
                      this.state.camposPersonalizado.map((c, i) => {
                        var ret = Util.maxStringLength(c.nome, 26);

                        if (c.habilitado) {
                          if (c.tipo == 'numerico') {
                            return (
                              <div className="col-sm-3">
                                <NInputNumber
                                  key={i}
                                  id={'campo' + (i + 1)}
                                  errorText={this.state['erroCAMPO' + (i + 1)]}
                                  label={ret}
                                  value={this.state['campo' + (i + 1)]}
                                  className={styles.observacao}
                                  fullWidth={true}
                                  floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                                  onChange={(_event, value) => { this.setState({ ['campo' + (i + 1)]: value }); }}
                                />
                              </div>
                            );
                          }

                          if (c.tipo == 'alfanumerico') {
                            return (
                              <div className="col-sm-3">
                                <TextField
                                  key={i}
                                  errorText={this.state['erroCAMPO' + (i + 1)]}
                                  id={'campo' + (i + 1)}
                                  fullWidth={true}
                                  floatingLabelText={ret}
                                  value={this.state['campo' + (i + 1)]}
                                  onKeyDown={event => $('#campo' + (i + 2)).val() != undefined ? $('#campo' + (i + 1)).focus() : null}
                                  floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                                  underlineFocusStyle={{ borderColor: '#000000' }}
                                  onChange={(_event, value) => { this.setState({ ['campo' + (i + 1)]: value }); }}
                                />
                              </div>
                            );
                          }

                          if (c.tipo == 'selecionar') {
                            let opcoes = c.opcoes ? c.opcoes.map(o => ({ value: o.nome, text: o.nome })) : [];

                            return (
                              <div className="col-sm-3" >
                                <AutoComplete
                                  key={i}
                                  id={'campo' + (i + 1)}
                                  errorText={this.state['erroCAMPO' + (i + 1)]}
                                  onKeyDown={() => this.onKeyDownAutoComplete('autoComplete' + i)}
                                  className={styles.observacao}
                                  ref={'autoComplete' + i}
                                  floatingLabelText={ret}
                                  openOnFocus={true}
                                  underlineFocusStyle={{ borderColor: '#000000' }}
                                  filter={AutoComplete.fuzzyFilter}
                                  searchText={this.state['campo' + (i + 1)] ? this.state['campo' + (i + 1)] : ''}
                                  onClick={() => this.setState({ ['campoText' + (i + 1)]: '', ['campo' + (i + 1)]: '' })}
                                  fullWidth={true}
                                  floatingLabelStyle={{ color: '#5B5B5B', top: 38, textTransform: 'uppercase' }}
                                  onUpdateInput={(event, value) => this.handleUpdateCustom(i, event, opcoes)}
                                  dataSource={opcoes}
                                  popoverProps={{ className: 'autocomplete' }}
                                />
                              </div>
                            );
                          }
                        }
                      })
                    }
                  </div>
                </div>

                <div className={`${styles.formButton} col-sm-2 col-xs-12 `} >
                  <div className="row">

                    {/* BOTÃO INSERIR */}
                    <div className="col-xs-12" style={{ marginBottom: '10px' }}>
                      <RaisedButton
                        id="buttonInsert"
                        onClick={this.inserirApontamento}
                        backgroundColor="#2D4F7F" buttonStyle={{ fontFamily: '' }}
                        disabled={!hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') || (!hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') && !this.state.disableInsert && this.state.apontEvento.fluxo)}
                        style={{
                          fontWeight: 100,
                          marginLeft: '2em',
                          fontSize: '.7em',
                          display: 'block'
                        }}
                        labelColor="#FFF"
                        label={this.props.t("insert")} />
                    </div>

                    {/* BOTÃO AUXILIAR - DINÂMICO */}
                    <div className="col-xs-12">
                      <RaisedButton
                        onClick={this.acaoBotaoAuxiliar()}
                        label={this.labelBotaoAuxiliar()}
                        disabled={!hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') || !some(this.state.apontamentos, a => a.tipo)}
                        backgroundColor="#FFF"
                        buttonStyle={{ fontFamily: '' }}
                        style={{
                          fontWeight: 100,
                          marginLeft: '2em',
                          fontSize: '.7em',
                          display: 'block'
                        }} />
                    </div>
                  </div>
                </div>

                {/* LISTA DE APONTAMENTOS */}
                <div className={`${styles.listTable} `}>
                  <ListEvents
                    validar={this.state.validar}
                    deleteApontamento={this.deleteApontamento}
                    editarApontamento={this.editarApontamento}
                    handleModal={this.handleModal}
                    apontamentoExcluido={this.state.apontamentoExcluido}
                    apontamentoNovo={this.state.apontamentoNovo}
                    equipamento={this.state.equipamentoInformado}
                    apontamentos={this.state.apontamentos}
                    store={this.store}
                    indexAlterar={this.state.indexAlterar}
                    dataInicialInformada={this.state.dataInicialInformado}
                    dataFinalInformada={this.state.dataFinalInformado}
                    showAlert={this.showAlert}
                    t={this.props.t}
                  />
                </div>
              </div>
            </Card>
          </div>
        </Container>
      );
    }
  }
}

class ListEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listagem: [],
    };
  }

  tooltipStatus(apontamento) {
    const { estado, estadoNext, descricao } = apontamento;

    if (descricao) {
      return descricao;
    }

    if (estadoNext) {
      return apontamento.classe_estrutura.nome.toUpperCase();
    } else if (estado === 'Aceito' || estado === 'Rejeitado') {
      return apontamento.classe_estrutura.nome.toUpperCase();
    } else {
      if (apontamento.classe_estrutura.id != 27 && apontamento.consistente_oee_estrutura.id != 27) {
        return (apontamento.classe_estrutura.nome + ' E ' + apontamento.consistente_oee_estrutura.nome).toUpperCase();

      } else if (apontamento.classe_estrutura.id == 27 && apontamento.consistente_oee_estrutura.id == 27) {
        return (apontamento.descricao || this.props.t("consistentNote")).toUpperCase();

      } else {
        if (apontamento.classe_estrutura.id != 27) {
          return apontamento.classe_estrutura.nome.toUpperCase();
        }

        if (apontamento.consistente_oee_estrutura.id != 27) {
          return apontamento.consistente_oee_estrutura.nome.toUpperCase();
        }
      }
    }
  }

  rowClassName(apontamento) {
    let className;

    switch (apontamento.tipo) {
      case 'Excluir':
        className = styles.trash_note;
        break;

      case 'Editar':
        className = styles.edit_note;
        break;

      default:
        className = '';
        break;
    }

    return className;
  }

  render() {
    let corFont;
    let apontamentos;
    let contItem = 0;
    let { validar } = this.props;

    if (this.props.apontamentos !== this.state.apontamentos) {
      let listagemTemp = Util.ordenaApontamentos(this.props.apontamentos);
      apontamentos = listagemTemp.map((a, i) => ({ ...a, index: i }));
    }

    var lookUpPrev = {
      'Aceito': {
        color: 'green',
        className: 'fa fa-check-circle fa-lg',
        backgroundColor: '#FFFFFF'
      },
      'Rejeitado': {
        color: '#BF0617',
        className: 'fa fa-exclamation-circle fa-lg',
        backgroundColor: '#FBEFEF'
      },
      'Inconsistente': {
        color: '#F9D054',
        className: 'fa fa-exclamation-triangle fa-lg',
        backgroundColor: '#FDFAF0'
      },
    };

    var lookUpNext = {
      'Aceito': {
        color: 'green',
        className: 'fa fa-check-circle fa-lg',
        backgroundColor: '#FFFFFF'
      },
      'Rejeitado': {
        color: '#BF0617',
        className: 'fa fa-exclamation-circle fa-lg',
        nome: '',
        backgroundColor: '#FBEFEF'
      },
      'Inconsistente': {
        color: '#F9D054',
        className: 'fa fa-exclamation-triangle fa-lg',
        backgroundColor: '#FDFAF0'
      },
    };

    return (
      <div>
        <div style={{ marginTop: 0 }} className="table_reponsive">
          <table className={`mui-table mui-table--bordered ${styles.notes_table}`}>
            <thead>
              <tr>
                <td className="borderBottom">#</td>
                <td className="borderBottom">{Util.getReferenciaLoteOrdem()}</td>
                <td className="borderBottom">{this.props.t("sku")}</td>
                <td className="borderBottom">{this.props.t("product")}</td>
                <td className="borderBottom">{this.props.t("event")}</td>
                <td className="borderBottom">{this.props.t("start")}</td>
                <td className="borderBottom">{this.props.t("end")}</td>
                <td className="borderBottom">{this.props.t("productionType")}</td>
                {this.props.equipamento.is_batch ? null : <td className="borderBottom center">{this.props.t("produced")}</td>}
                {this.props.equipamento.is_batch ? null : <td className="borderBottom center">{this.props.t("rejected")}</td>}
                <td className="borderBottom center">{this.props.t("obs")}</td>
                <td className="borderBottom center">{this.props.t("status")}</td>
                {validar ? <td className="borderBottom center">{this.props.t("nextState")}</td> : null}
                {this.props.indexAlterar === null ? <td className="borderBottom center">{this.props.t("action")}</td> : null}
              </tr>
            </thead>

            <tbody className="box_items">
              {apontamentos.map((a, i) => {
                corFont = '#5B5B5B';

                a.classe_estrutura = a.classe_estrutura || { nome: '' };
                a.consistente_oee_estrutura = a.consistente_oee_estrutura || { nome: '' };

                let msgBefore = (this.tooltipStatus(a));
                let msgAfter = (this.tooltipStatus(a));

                // Corrige a data final do apontamento para abranger o dia inteiro, até às 23h59
                let dataFinalManutencao = moment(this.props.dataFinalInformada).add('days', 1).add('seconds', -1);
                let dataInicialManutencao = moment(this.props.dataInicialInformada);
                let apontamentoDataInicio = moment(a.inicio);
                let apontamentoDataFim = moment(a.fim);
                let desabilitarEdicao = true;

                if (hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR')) {
                  desabilitarEdicao = (apontamentoDataInicio.isBefore(dataInicialManutencao) || apontamentoDataFim.isAfter(dataFinalManutencao));
                }

                let alertaApontamentoForaPeriodo = () => {
                  alertBox(this.props.t("messages.dateMaintenanceInterval"), this.props.t("notesMaintenance"), 'warning');
                };

                let actionIconsStyle = { marginLeft: 6 };

                if (desabilitarEdicao) actionIconsStyle.filter = 'opacity(0.5)';

                if (a.tipo == 'Excluir') {
                  corFont = '#fff';
                } else {
                  contItem++;
                }

                var language = navigator.language || navigator.userLanguage;

                return (
                  <tr key={i}
                    className={this.rowClassName(a)}
                    style={{ backgroundColor: a.tipo == 'Excluir' ? '#A5A5A5 !important' : (a.estadoNext ? lookUpPrev[a.estadoNext].backgroundColor : lookUpPrev[a.estado].backgroundColor) }}>

                    {a.tipo == 'Excluir' ? <td> </td>
                      : <td className="borderBottom" >
                        {contItem}
                      </td>
                    }

                    <td style={{ color: corFont }} className="borderBottom" >
                      {a.lote ? a.lote.numero : a.numero_lote}
                    </td>

                    <td style={{ color: corFont }} className="borderBottom" >
                      {a.lote ? a.lote.numero ? a.lote.produto ? a.lote.produto.sku : '' : '' : ''}
                    </td>

                    <td className="borderBottom"
                      style={{ whiteSpace: 'nowrap', color: corFont }}
                      title={a.lote ? a.lote.numero ? a.lote.produto ? a.lote.produto.nome : '' : '' : ''}>
                      {a.lote ? a.lote.numero ? a.lote.produto ? Util.maxStringLength(a.lote.produto.nome, 16) : '' : '' : ''}
                    </td>

                    <td className="borderBottom"
                      style={{ whiteSpace: 'nowrap', color: corFont }}
                      title={a.evento.codigo + ' - ' + a.evento.nome}>
                      {Util.maxStringLength(a.evento.codigo + ' - ' + a.evento.nome, 16)}
                    </td>

                    <td style={{ color: corFont }} className="borderBottom" >
                      {
                        language == 'pt-BR' ? moment(a.inicio).format('L HH:mm:ss') : moment(a.inicio).format('L hh:mm:ss A')
                      }
                    </td>

                    <td style={{ color: corFont }} className="borderBottom" >
                      {
                        language == 'pt-BR' ? moment(a.fim).format('L HH:mm:ss') : moment(a.fim).format('L hh:mm:ss A')
                      }
                    </td>

                    <td className="borderBottom"
                      style={{ whiteSpace: "nowrap", color: corFont }}
                      title={a.tipo_producao ? a.tipo_producao : ""}>
                      {Util.maxStringLength(a.tipo_producao ? a.tipo_producao : "", 16)}
                    </td>

                    {this.props.equipamento.is_batch ? null :
                      <td className="borderBottom center" style={{ color: corFont }}>
                        {a.evento.fluxo ? a.produzido_acumulado : ''}
                      </td>
                    }

                    {this.props.equipamento.is_batch ? null :
                      <td className="borderBottom center" style={{ color: corFont }}>
                        {a.evento.fluxo ? a.rejeitado_acumulado : ''}
                      </td>
                    }

                    {a.tipo == 'Excluir' ? <td className="borderBottom"> </td> :
                      <td className="borderBottom center" style={{ color: corFont }}>
                        <i style={{ cursor: (this.props.indexAlterar != null ? 'not-allowed' : 'pointer'), fontSize: 20 }}
                          onClick={() => {
                            if (this.props.indexAlterar != null) return;

                            this.props.handleModal('editar_obs', a);
                          }}
                          className="material-icons">
                          chat_bubble_outline
                        </i>
                      </td>
                    }

                    {a.tipo == 'Excluir' ? <td className="borderBottom"> </td> :
                      <td className="borderBottom center" style={{ color: corFont }}>
                        <i id="iconMA"
                          style={{ color: a.tipo == 'Criar' || a.tipo == 'Editar' ? '#5B5B5B' : lookUpPrev[a.estado].color }}
                          className={a.tipo == 'Criar' || a.tipo == 'Editar' ? 'material-icons' : lookUpPrev[a.estado].className}
                          title={msgBefore}>
                          {a.tipo == 'Criar' || a.tipo == 'Editar' ? 'remove' : ''}
                        </i>
                      </td>
                    }

                    {validar ? a.tipo == 'Excluir' ? <td className="borderBottom"> </td> :
                      <td className="borderBottom center" style={{ color: corFont }}>
                        <i id="iconMA"
                          style={{ color: a.estadoNext ? lookUpNext[a.estadoNext].color : lookUpPrev[a.estado].color }}
                          title={msgAfter}
                          className={a.estadoNext ? lookUpNext[a.estadoNext].className : lookUpPrev[a.estado].className}>
                        </i>
                      </td>
                      : null
                    }

                    {a.tipo == 'Excluir' || this.props.indexAlterar != null ? <td className="borderBottom"> </td> :
                      <td className="borderBottom center">
                        <div className="options acoes">
                          {/* icone Fracionar */}
                          <img style={{ filter: (desabilitarEdicao ? 'opacity(0.5)' : 'opacity(1)') }}
                            width={23}
                            height={23}
                            src='./../assets/split.svg'
                            onClick={() => {
                              desabilitarEdicao ? hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') && alertaApontamentoForaPeriodo() : this.props.handleModal('fracionar', a);
                            }} />

                          {/* icone Editar */}
                          <i style={actionIconsStyle}
                            className="material-icons"
                            onClick={() => {
                              desabilitarEdicao ? hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') && alertaApontamentoForaPeriodo() : this.props.editarApontamento(a);
                            }}>
                            edit
                          </i>

                          {/* icone Deletar */}
                          <i style={{ marginLeft: 6, opacity: hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') ? 1 : 0.5 }} className="material-icons" onClick={() => hasRole('ROLE_MANUTENCAO_APONTAMENTOS_ATUALIZAR') && this.props.handleModal('delete', a)}>cancel</i>
                        </div>
                      </td>
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const MainNote = withTranslation('notes')(MainNoteExport);

export default withRouter(({ history }) => (
  <MainNote history={history} />
));