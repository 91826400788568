import { request } from '../../common/request';

const BASE_URL = `${process.env.APIV2_URL}/unidade-industrial`;

export class UnidadeIndustrial {

  constructor(options = {}) {
  this.id = undefined;
  this.nome = undefined;
    
    Object.assign(this, {
      ativo: true
    }, options);       
  }  

  static all = async () => {
    const url = `${BASE_URL}/todos`;

    return await request(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.status === 204) 
          return [];
        return res.json();
      })
      .then(
        data => data.map(c => new UnidadeIndustrial(c)),
        error => error
      )
  }  
}