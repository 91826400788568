import React, { Component } from 'react';
import { Tabs, Tab, Toggle, RaisedButton } from 'material-ui';
import { hasRole } from '../../helper/SecurityHelper';
import { TextField, AutoComplete } from '../../common/MaterialForm';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import { Formik } from 'formik';
import { alertBox, NInputTelefone } from '../../common/NForm';
import Modal from 'common/CommonModal';
import PropTypes from 'prop-types';
import styles from './user.scss';
import { User } from 'clients/Users/User';
import Yup from 'utils/Yup';
import { UserGroup } from 'clients/Users/UserGroup';
import { UserGroupNotes } from 'clients/Users/UserGroupNotes';
import { Etapa } from 'clients/Etapa/Etapa';
import { Equipamento } from 'clients/Equipamento/Equipamento';
import { isEqual, isUndefined, filter, first, isNull } from 'lodash';
import { UnidadeIndustrial } from 'clients/UnidadeIndustrial/UnidadeIndustrial';
import { QRCodeSVG as QRCodeCanvas } from 'qrcode.react';
import { v4 as uuidv4 } from 'uuid';
import { default as MultiSelect } from 'components/AutoComplete';
import { withTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';

class UserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: new User(),
      userGroup: new UserGroup(),
      user_group_notes_datasource: [],
      grupo_values: undefined,
      grupo_apontamento_selecionado: undefined,
      text_grupo_apontamento_selecionado: undefined,
      unidade_industrial: undefined,
      etapa: undefined,
      equipamento: undefined,
      unidade_industrial_options: undefined,
      etapa_options: undefined,
      equipamento_options: undefined,
      isAD: localStorage.getItem('AD') == 'true',
      qrCodeValue: undefined,
      searchTextEventRule: '',
      senha: undefined,
      confirmarSenha: undefined,
      disabled: true,
      invalidPassword: true,
      passwordTypeChanged: false
    };

    this.onSuccess = this.props.onSuccess;
    this.closeModal = this.props.closeModal;
    this.actionButtons = this.actionButtons.bind(this);
    this.handlePasswordType = this.handlePasswordType.bind(this);
  }

  createSchemaValidation() {
    const validationSchema = Yup.object().shape({
      nome: Yup
        .string()
        .required()
        .trim(),
      username: Yup
        .string()
        .required()
        .trim(),
      email: Yup
        .string()
        .email('E-mail inválido')
        .required()
        .trim()
    });

    return validationSchema;
  }

  componentDidMount() {
    let promises = [
      this.props.user.uuid && User.find(this.props.user.uuid).then((data) => {
        this.setState({
          user: data,
          grupo_values: data.grupos ? data.grupos.map(d => ({
            value: d.uuid,
            label: d.nome,
          })) : [],
          grupo_apontamento_selecionado: data.grupo_apontamento ? ({
            value: data.grupo_apontamento.uuid,
            text: data.grupo_apontamento.nome,
          }) : {},
          text_grupo_apontamento_selecionado: data.grupo_apontamento ? data.grupo_apontamento.nome : undefined,
        });
      }),
      UserGroup.all().then((data) => {
        this.setState({
          userGroup: data ? data.map(d => ({ value: d.uuid, label: d.nome })) : []
        });
      }),
      UserGroupNotes.all().then((data) => {
        this.setState({
          user_group_notes_datasource: data ? data.map(d => ({ value: d.uuid, text: d.nome })) : []
        });
      }),
    ];

    Promise.all(promises)
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.loading != prevState.loading) {
      let promises = [
        UnidadeIndustrial.all().then((data) => {
          this.setState({ unidade_industrial_options: this.buildDataToOptions(data) });
        }),
        Etapa.all().then((data) => {
          this.setState({ etapa_options: this.buildDataToOptions(data) });
        }),
        Equipamento.all().then((data) => {
          this.setState({ equipamento_options: this.buildDataToOptions(data) });
        })
      ];

      Promise.all(promises)
        .finally(() => {
          const { user } = this.state;

          let unidadeIndustrial = [];
          let etapa = [];
          let equipamento = [];

          if (!isUndefined(user.configuracao)
            && !isNull(user.configuracao)
            && !isUndefined(user.configuracao.permissoes)
            && !isNull(user.configuracao.permissoes)) {

            unidadeIndustrial = this.transformidsToOptions(this.state.unidade_industrial_options, user.configuracao.permissoes.unidadesIndustriais);
            etapa = this.transformidsToOptions(this.state.etapa_options, user.configuracao.permissoes.etapas);
            equipamento = this.transformidsToOptions(this.state.equipamento_options, user.configuracao.permissoes.equipamentos);
          }

          this.setState({
            unidade_industrial: unidadeIndustrial,
            etapa: etapa,
            equipamento: equipamento,
            qrCodeValue: user.qrCodeValue
          });
        });
    }

    this.setQrCode();
  }

  buildDataToOptions(data) {
    if (data) { return data.map(d => ({ value: d.id, label: d.nome })); };

    return [];
  }

  transformidsToOptions(listOfoptions, listOfIds) {
    let { user } = this.state;
    let { configuracao } = user;
    let { permissoes } = configuracao;

    if (configuracao && permissoes) {
      let result = listOfIds.map(i => {
        let option = listOfoptions.find(f => f.value === i);
        return option;
      });
      return result;
    }

    return [];
  }

  actionButtons = (props) => {
    return (
      <div style={{ paddingTop: '1em' }} className={'flex flex-just-end'}>
        <RaisedButton
          onClick={props.handleSubmit}
          backgroundColor='#2D4F7F'
          buttonStyle={{ fontFamily: '' }}
          style={{ fontWeight: 100, marginRight: 10 }}
          labelColor='#FFF'
          label={this.props.t("labels.save", { ns: "common" })}
          disabled={
            (!hasRole('ROLE_USUARIOS_CRIAR') && !this.props.user.uuid)
            || (!hasRole('ROLE_USUARIOS_ATUALIZAR') && this.props.user.uuid)
          } />
        <RaisedButton
          onClick={this.props.closeModal}
          backgroundColor='#FFF'
          buttonStyle={{ fontFamily: '' }}
          style={{ fontWeight: 100 }}
          label={this.props.t("labels.cancel", { ns: "common" })} />
      </div>
    )
  }

  setGrupoApontamentoSelecionado(nomeEquipamento, grupoApontamentoDatasource) {
    this.setState({ searchTextEventRule: nomeEquipamento, grupo_apontamento_selecionado: {}, text_grupo_apontamento_selecionado: '' });

    const grupoSelecionado = first(filter(grupoApontamentoDatasource, (grupo) => {
      return isEqual(grupo.text, nomeEquipamento);
    }));

    let grupo_apontamento_selecionado = {};
    let text_grupo_apontamento_selecionado = '';

    if (!isUndefined(grupoSelecionado)) {
      grupo_apontamento_selecionado = grupoSelecionado;
      text_grupo_apontamento_selecionado = grupoSelecionado.text;
    }

    this.setState({
      grupo_apontamento_selecionado,
      text_grupo_apontamento_selecionado,
    });
  }

  submitModal(values) {
    const { user } = this.state;

    let method = undefined;

    if (user.uuid) {
      method = user.update;
    } else {
      method = user.create;
    }
    
    const mappedData = {
      id: values.id,
      uuid: values.uuid,
      nome: values.nome?.trim(),
      email: values.email?.trim(),
      username: values.username?.trim(),
      telefone: values.telefone,
      grupos: this.state.grupo_values ? this.state.grupo_values.map(d => ({ uuid: d.value })) : [],
      grupo_apontamento: { uuid: this.state.grupo_apontamento_selecionado ? this.state.grupo_apontamento_selecionado.value : undefined },
      configuracao: {
        permissoes: {
          unidadesIndustriais: this.buildIds(this.state.unidade_industrial),
          etapas: this.buildIds(this.state.etapa),
          equipamentos: this.buildIds(this.state.equipamento)
        }
      },
      status: values.status,
      senha_forte: values.senha_forte,
      senha: this.state.senha,
      qrCodeStatus: values.qrCodeStatus,
      qrCodeValue: this.state.qrCodeValue,
      senha_alterada: this.state.passwordTypeChanged
    };

    // Se não é usuário AD e a senha está inválida
    if (!this.state.isAD && this.state.invalidPassword) {
      // Se é cadastro
      if (!user.uuid) {
        return false;
      }

      // Se é alteração e o tipo de senha foi modificado
      if (user.uuid && this.state.passwordTypeChanged) {
        return false;
      }
    }

    method(mappedData)
      .then(response => {
        if (response.ok) {
          const msgTipoAcao = user.uuid ? this.props.t("messages.alterUserSuccess") : this.props.t("messages.saveUserSuccess");
          alertBox(msgTipoAcao, this.props.t("title"), 'success');

          this.onSuccess();
          this.closeModal();
        }
        else if (response.status == 409) {
          alertBox(this.props.t("errors.existingUser"), this.props.t("title"), 'error');
        }
        else {
          let message = this.props.t("errors.cannotSaveUser");

          response.json().then(res => {
            if (res.errors) {
              message = res.message;
            }

            alertBox(message, this.props.t("title"), 'error');
          });
        }
      })
      .catch(() => {
        if (user.uuid) {
          alertBox(this.props.t("errors.cannotEditUsar"), this.props.t("title"), 'error');
        }
        else {
          alertBox(this.props.t("errors.cannotSaveUser"), this.props.t("title"), 'error');
        }
      });
  }

  buildIds(data) {
    if (data) { return data.map(m => (m.value)); }

    return [];
  }

  handleSelectChange(value) {
    this.setState({ grupo_values: value });
  }

  handleSelectChangeUnidadeIndustrial(value) {
    this.setState({ unidade_industrial: value });
  }

  handleSelectChangeEtapa(value) {
    this.setState({ etapa: value });
  }

  handleSelectChangeEquipamento(value) {
    this.setState({ equipamento: value });
  }

  handlePasswordChange(value) {
    this.setState({ senha: value });
  }

  handleConfirmPasswordChange(value) {
    this.setState({ confirmarSenha: value });
  }

  handlePasswordType = (value, props) => {
    this.setState({ passwordTypeChanged: true });

    props.setFieldValue('senha_forte', value);
    props.setFieldValue('senha', '');
  };

  getDescriptionGrupoTooltip() {
    return this.props.t("messages.descriptionGroup")
  }

  setQrCode() {
    if (!this.state.qrCodeValue) {
      let qrCodeValue = uuidv4();

      let qrCode = <QRCodeCanvas
        value={qrCodeValue}
        level='Q'
        size={175} />

      this.setState({ qrCode: qrCode, qrCodeValue: qrCodeValue });
    }
  }

  downloadQrCode() {
    let img = document.getElementById('qrcodetestevalue');
    const svgserialized = new XMLSerializer().serializeToString(img);
    const base64Image = window.btoa(svgserialized);

    var link = document.createElement('a');
    link.href = `data:image/svg+xml;base64,${base64Image}`;
    link.download = `${this.state.user.username}_QrCode.svg`


    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  updateQtCode() {
    this.setState({ qrCodeValue: uuidv4() });
  }

  render() {
    const { loading, typeModal } = this.state;

    if (!this.props.open || loading) return null;

    return (
      <Modal className={'box_modal'}>

        <Formik
          initialValues={this.state.user}
          validateOnChange={true}
          validateOnBlur={false}
          validationSchema={this.createSchemaValidation()}
          onSubmit={(values, actions) => {
            this.submitModal(values);
          }}
        >
          {props => (
            <Tabs
              tabItemContainerStyle={{ background: '#F1F1F1' }}
              inkBarStyle={{ background: '#95999A' }}
              className='modal_tabs user-crud-form'
            >
              <Tab label={this.props.t("form.registration.title")} key='1'>
                <div className='modal'>
                  <div></div>
                  <Row>
                    <div style={{ marginLeft: '1rem', display: 'flex', gap: '20px' }}>
                      <Toggle
                        style={{ width: '' }}
                        name='ativo'
                        onToggle={(_event, value) => props.setFieldValue('status', value)}
                        id='ativo'
                        thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                        trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                        labelPosition="right"
                        label={this.props.t("labels.active", { ns: "common" })}
                        defaultToggled={props.values.status}
                      />
                      {!this.state.isAD && <Toggle
                        style={{ width: '' }}
                        name='senha_forte'
                        onToggle={(_event, value) => this.handlePasswordType(value, props)}
                        id='senha_forte'
                        thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                        trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                        labelPosition="right"
                        label={this.props.t("labels.strongPassword", { ns: "common" })}
                        defaultToggled={props.values.senha_forte}
                      />}
                    </div>
                  </Row>
                  <Row>
                    <Col lg={6} className={styles.form}>
                      <TextField
                        name="nome"
                        floatingLabelText={this.props.t("form.registration.labels.name")}
                        value={props.values.nome}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        errorText={props.errors.nome}
                      />
                    </Col>
                    <Col lg={6} className={styles.form}>
                      <TextField
                        name='username'
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                        floatingLabelText={this.props.t("form.registration.labels.login")}
                        onChange={props.handleChange}
                        defaultValue={props.values.username}
                        onBlur={props.handleBlur}
                        errorText={props.errors.username}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className={styles.form}>
                      <TextField
                        name='email'
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                        floatingLabelText={this.props.t("form.registration.labels.mail")}
                        onChange={props.handleChange}
                        defaultValue={props.values.email}
                        onBlur={props.handleBlur}
                        errorText={props.errors.email}
                      />
                    </Col>
                    <Col lg={6} className={styles.form}>
                      <NInputTelefone
                        id='telefone'
                        floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', fontSize: '.9em' }}
                        floatingLabelText={this.props.t("form.registration.labels.tel")}
                        type="text"
                        value={props.values.telefone}
                        onChange={props.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className={styles.form}>
                      <div style={{ paddingTop: '1.3em', fontSize: '.9em', marginBottom: '0.5rem' }}>
                        <h3> {this.props.t("form.registration.labels.userGroup")} </h3>
                        <MultiSelect
                          isMulti
                          options={this.state.userGroup}
                          allowSelectAll={false}
                          onGetOptionLabel={(option) => option.label}
                          onGetOptionValue={(option) => option.value}
                          onSelectOption={this.handleSelectChange.bind(this)}
                          value={this.state.grupo_values}
                          disabled={this.state.user.username === localStorage.getItem('login')}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className={styles.form}>
                      <div style={{ paddingTop: '1em', fontSize: '.9em', display: 'flex', flexDirection: 'column' }}>
                        <AutoComplete
                          fullWidth={true}
                          style={{ width: '80%' }}
                          title={this.props.t("form.registration.labels.ruleEditEvent")}
                          floatingLabelText={this.props.t("form.registration.labels.ruleEditEvent")}
                          listStyle={{ width: '100%' }}
                          textFieldStyle={{ marginTop: '-10px' }}
                          floatingLabelStyle={{ color: '#5B5B5B' }}
                          underlineFocusStyle={{ borderColor: '#000000' }}
                          filter={AutoComplete.fuzzyFilter}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                          dataSource={this.state.user_group_notes_datasource}
                          onUpdateInput={this.setGrupoApontamentoSelecionado.bind(this)}
                          value={this.state.text_grupo_apontamento_selecionado}
                          searchText={this.state.searchTextEventRule == '' ? this.state.text_grupo_apontamento_selecionado : this.state.searchTextEventRule}
                          onChange={(_event, value) => { this.setState({ text_grupo_apontamento_selecionado: value }); }}
                          onClick={() =>
                            this.setState({
                              grupo_apontamento_selecionado: {},
                              text_grupo_apontamento_selecionado: '',
                              searchTextEventRule: ''
                            })
                          }
                          openOnFocus={true}
                          popoverProps={{ className: 'autocomplete' }}
                        />
                        <i id="iconMA"
                          style={{ color: 'gray', zIndex: 2 }}
                          className={'fa fa-question-circle fa-lg'}
                          title={this.getDescriptionGrupoTooltip()}>
                        </i>
                      </div>
                    </Col>
                  </Row>
                  {((!this.state.isAD && !this.state.user.id) || this.state.passwordTypeChanged) &&

                    <PasswordField
                      defaultValue={props.values.senha}
                      onChangePassword={this.handlePasswordChange.bind(this)}
                      onChangeConfirmPassword={this.handleConfirmPasswordChange.bind(this)}
                      passwordLabel={this.props.t("form.registration.labels.password")}
                      passwordConfirmLabel={this.props.t("form.registration.labels.confirmPassword")}
                      usernameRule={props.values.username}
                      onValidPassword={() => this.setState({ invalidPassword: false })}
                      onInvalidPassword={() => this.setState({ invalidPassword: true })}
                      bootstrap
                      strongPassword={props.values.senha_forte}
                    />

                  }
                  {this.actionButtons(props)}
                </div>
              </Tab>
              <Tab label={this.props.t("form.restrictedField.title")} key='2'>
                <div className='modal'>
                  <div></div>
                  <Row>
                    <Col lg={12}>
                      <span>
                        {this.props.t("messages.descriptionTab1")} <br />
                        {this.props.t("messages.descriptionTab2")}
                      </span>
                      <br></br>
                      <br></br>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className={styles.form}>
                      <div style={{ paddingTop: '1.3em', fontSize: '.9em', marginBottom: '0.5rem' }}>
                        <h3> {this.props.t("form.restrictedField.labels.industrialUnit")} </h3>
                        <MultiSelect
                          isMulti
                          options={this.state.unidade_industrial_options}
                          allowSelectAll={false}
                          onGetOptionLabel={(option) => option.label}
                          onGetOptionValue={(option) => option.value}
                          onSelectOption={this.handleSelectChangeUnidadeIndustrial.bind(this)}
                          value={this.state.unidade_industrial}
                        />
                      </div>
                      <div style={{ paddingTop: '1.3em', fontSize: '.9em', marginBottom: '0.5rem' }}>
                        <h3> {this.props.t("form.restrictedField.labels.stage")} </h3>
                        <MultiSelect
                          isMulti
                          options={this.state.etapa_options}
                          allowSelectAll={false}
                          onGetOptionLabel={(option) => option.label}
                          onGetOptionValue={(option) => option.value}
                          onSelectOption={this.handleSelectChangeEtapa.bind(this)}
                          value={this.state.etapa}
                        />

                      </div>
                      <div style={{ paddingTop: '1.3em', fontSize: '.9em', marginBottom: '0.5rem' }}>
                        <h3> {this.props.t("form.restrictedField.labels.equipment")} </h3>
                        <MultiSelect
                          isMulti
                          options={this.state.equipamento_options}
                          allowSelectAll={false}
                          onGetOptionLabel={(option) => option.label}
                          onGetOptionValue={(option) => option.value}
                          onSelectOption={this.handleSelectChangeEquipamento.bind(this)}
                          value={this.state.equipamento}
                        />

                      </div>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Toggle
                          name='qrcode'
                          onToggle={(_event, value) => {
                            props.setFieldValue('qrCodeStatus', value);
                            this.setQrCode()
                          }}
                          id='qrcode'
                          thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                          trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                          labelPosition="right"
                          label={this.props.t("form.restrictedField.labels.qrCode")}
                          defaultToggled={props.values.qrCodeStatus}
                        />
                      </Row>
                      <Row>
                        <div style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                          {props.values.qrCodeStatus && (
                            <QRCodeCanvas
                              value={this.state.qrCodeValue}
                              level='Q'
                              size={175}
                              id='qrcodetestevalue'
                            />
                          )}
                        </div>
                      </Row>
                      <Row>
                        {props.values.qrCodeStatus && (
                          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80px' }}>
                              <div id='btnAtualizar' className="cursor-pointer" onClick={() => this.updateQtCode()}>
                                <i className='fa fa-refresh'></i>
                                <label className="cursor-pointer" htmlFor='btnAtualizar'> {this.props.t("form.restrictedField.labels.update")}</label>
                              </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80px' }}>
                              <div id='btnImprimir' className="cursor-pointer" onClick={() => this.downloadQrCode()}>
                                <i className='fa fa-print'></i>
                                <label className="cursor-pointer" htmlFor='btnImprimir'> {this.props.t("form.restrictedField.labels.print")}</label>
                              </div>
                            </div>
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {this.actionButtons(props)}
                </div>
              </Tab>
            </Tabs>
          )}
        </Formik>
      </Modal>
    );
  }
}

UserModal.propTypes = {
  user: PropTypes.instanceOf(User),
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  onSuccess: PropTypes.func
};

export default withTranslation('user')(UserModal)