import { observable, computed, action } from 'mobx';
import Dispatcher from './Dispatcher';
import createBrowserHistory from 'history/createBrowserHistory';
import moment from 'moment';
import fileDownload from 'react-file-download';
import i18n from 'utils/i18n';

const history = createBrowserHistory({ forceRefresh: true });

class Store {
  constructor() {
    this.dispatchToken = '';
    this.execute();
  }

  @observable batch = [];
  @observable batchDetail = [];
  @observable batches = [];
  @observable checkDates = [];
  @observable checkDatesBatch = [];
  @observable equipments = [];
  @observable events = [];
  @observable fields = [];
  @observable favorites = [];
  @observable products = [];
  @observable sessions = [];
  @observable setups = [];
  @observable shifts = [];
  @observable stages = [];
  @observable deviations = [];
  @observable type_events = [];
  @observable predecessores = [];
  @observable parameters = [];
  @observable reference = true;
  @observable users = [];
  @observable errors = '';
  @observable oee = [];
  @observable timeMachine = false;
  @observable avg_lead_time = [];
  @observable lastNotes = [];
  @observable group_event = [];
  @observable batchList = [];
  @observable group_users = [];
  @observable searchValue = '';
  @observable searchModel = '';
  @observable days_avg_lead = null;
  @observable sensores_data = null;
  @observable equipamentosOEE = [];
  @observable flowViewLeadTimes = false;
  @observable flowViewSensores = false;
  @observable flowViewTemposCiclo = false;
  @observable movideskChat = undefined;
  @observable currentUser = undefined;

  @computed get searchItems() {
    let result = this[this.searchModel].filter(p => {
      let regex = new RegExp(this.searchValue.trim().replace('[', ''), 'i');

      if (p.pis) {
        return (
          regex.test(p.numero_lote) || regex.test(p.sku_produto) ||
          (!p.pis[0] ? '' : regex.test(p.pis[0].sku_predecessor)) ||
          (!p.pis[0] ? '' : regex.test(p.pis[0].numero_predecessor)) ||
          (!p.pis[1] ? '' : regex.test(p.pis[1].sku_predecessor)) ||
          (!p.pis[1] ? '' : regex.test(p.pis[1].numero_predecessor)) ||
          (!p.pis[2] ? '' : regex.test(p.pis[2].sku_predecessor)) ||
          (!p.pis[2] ? '' : regex.test(p.pis[2].numero_predecessor))
        )
      } else {
        return (
          regex.test(p.name) ||
          regex.test(p.descr) ||
          regex.test(p.cod) ||
          regex.test(p.tag) ||
          regex.test(p.sku) ||
          regex.test(p.num) ||
          regex.test(p.nome) ||
          regex.test(p.email)
        );
      }
    });

    return result.length ? result : this[this.searchModel];
  }

  getSession() {
    return localStorage.getItem('sessionId');
  }

  setSession(sessionId) {
    localStorage.setItem('sessionId', sessionId)
    return this.getSession();
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    return localStorage.getItem('login');
  }

  getError() {
    return this.errors;
  }

  isUnique(data) {
    if (data.constraint == "dist_tb_user_email_unique")
      return i18n.t('messages.emailAlreadyUsed', { ns: 'common' });
    else if (data.code == '23505')
      return i18n.t('messages.recordAlreadyExists', { ns: 'common' });
    else
      return data.detail;
  }

  clearErrors() {
    this.errors = '';
  }

  execute() {
    this.dispatchToken = Dispatcher.register(action => {
      switch (action.model) {
        case 'login':
          Object.keys(action.data).forEach((key) => sessionStorage.setItem(key, action.data[key]));
          history.push('/');
          break;
        case 'errors':
          let getError = typeof action.data == 'string' ? JSON.parse(action.data) : action.data;
          this.errors = getError.original ? this.isUnique(getError.original) : getError.message || getError.error;
          break;
        case 'timeMachine':
          this.sessions = this.sessions.map((session) => {
            if (session.id == action.data.session.id) {
              session = action.data.session;
            }
            return session;
          });
          this.batches = action.data.batches;
          this.timeMachine = true;
          break;
        default:
          switch (action.type) {
            case 'post':
              if (action.model == 'notes' && action.data.message == 'success') history.push('/flow-view');
              if (action.model == 'notesRefresh' && action.data.message == 'success') history.push('/apontamento');
              if (action.model == 'batches' && !action.data.length) action.data instanceof Array ? this.batchList = action.data : this.batchList.splice(0, 0, action.data);
              else action.data instanceof Array ? this[action.model] = action.data : this[action.model].splice(0, 0, action.data);
              break;
            case 'put':
              if (this[action.model]) {
                this[action.model] = this[action.model].map((data) => {
                  if (data.id == action.data.id) {
                    data = action.data
                  }
                  return data;
                });
              }
              break;
            case 'delete':
              this[action.model] = this[action.model].filter((data) => {
                return data.id != action.data.id;
              });
              break;
            case 'get':
              this[action.model] = action.data;
              break;
            case 'csv':
              fileDownload('\ufeff' + action.data, `${i18n.t('titles.notesReport', { ns: 'reports' })} - ${moment().format('YYYY-MM-DD_HH-MM-ss')}.csv`);
              break;
            default:
              break;
          }
          break;
      }
    });
  }

  @action
  inserirEquipamentosOEE(data) {
    return this.equipamentosOEE = data;
  }
}

const appStore = new Store();

export default appStore;