import React, { Component } from 'react';
import { default as MultiSelect } from 'components/AutoComplete';
import defaultStyle from './form.scss';
import Toggle from 'material-ui/Toggle';
import AutoComplete from 'material-ui/AutoComplete';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Button from './Button';
import InputTime from './InputTime';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import i18n from 'utils/i18n';

export default class Form extends Component {
  constructor() {
    super();
    this.mapKey = this.mapKey.bind(this);
    this.searchFirstFocus = this.searchFirstFocus.bind(this);
  }

  searchFirstFocus() {
    return Object.values(this.refs).find((elem) => elem.props.type == 'autocomplete' || elem.props.type == 'text' || elem.props.type == 'number' || elem.props.type == 'date' && !elem.props.disabled);
  }

  componentDidMount() {
    let first = this.searchFirstFocus();
    if (first) document.getElementById(first.props.id);
  }

  mapKey(event, field) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      if (field.onFinished && event.keyCode !== 9) {
        if ((field.required && event.target.value !== '') || !field.required) {
          field.onFinished();
          document.getElementById(this.searchFirstFocus().props.id);
        }
      }
      else {
        let idx = false;
        let next = true;
        if (field.validateNext) next = field.validateNext(event);
        Object.keys(this.refs).map((f) => {
          if (idx && next) {
            if (!this.refs[f].props.readOnly && !this.refs[f].props.disabled) {
              document.getElementById(this.refs[f].props.id).focus();
              idx = false;
            }
          }
          if (this.refs[f].props.id == field.id && ((this.refs[f].props.required && event.target.value !== '') || !this.refs[f].props.required)) idx = true;
        });
        if (idx && event.keyCode == 9) document.getElementById(this.refs[0].props.id).focus();
      }
      event.preventDefault();
    }
  }

  render() {
    let styles = this.props.styles ? this.props.styles : defaultStyle;
    let { columns } = this.props;

    let fields = this.props.fields.map((field, idx) => {
      switch (field.type) {
        case 'multiple':
          return <FormMultipleSelect ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'radio':
          return <FormRadioButton ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'range':
          return <FormRange ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'select':
          return <FormSelect ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'toggle':
          return <ToggleButton ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'spent':
          return <InputTime ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'interval':
          return <InputTime ref={idx} key={idx} {...field} id={field.name} styles={styles} interval />;
        case 'autocomplete':
          return <FormAutoComplete ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'date':
          return <FormDate ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'NSelect':
          return <NFormSelect ref={idx} key={idx} {...field} id={field.name} styles={styles} />;
        case 'number':
          return <FormNumber ref={idx} key={idx} {...field} id={field.name} styles={styles} mapKey={this.mapKey} />;
        case 'custom':
          return <Custom {...field} />;
        default:
          return <FormInput ref={idx} key={idx} {...field} id={field.name} styles={styles} mapKey={this.mapKey} />;
      }
    });

    let component = '';
    if (columns && fields[0].props.type != 'custom') {
      component = { 0: [], 1: [] };
      let i = 0;

      fields.map(f => {
        component[i].push(f);
        i++; if (i > 1) i = 0;
      });

      component = (
        <div>
          <div style={{ width: '49%', float: 'left' }}>
            {component[0]}
          </div>
          <div style={{ width: '49%', float: 'left' }}>
            {component[1]}
          </div>
        </div>
      );
    }
    else {
      component = (
        <div style={this.props.formContentStyle ? this.props.formContentStyle : {}}>
          {fields}
        </div>
      );
    }

    return (
      <div>
        <form className={styles.row_form}>
          {component}
          <div className={styles.row_form_buttons} style={columns ? { width: '100%' } : {}}>
            {
              this.props.buttons.map((button, idx) => <Button key={idx} styles={styles} button={{ type: button.type, text: button.name, icon: button.icon, onClick: button.action, className: button.className, disabled: button.disabled }} />)
            }
          </div>
        </form>
      </div>
    );
  }
}

export class FormInput extends Component {
  constructor() {
    super();
    this.state = {
      value: ''
    };
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value || ''
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.value != nextState.value || this.props.required != nextState.required || this.props.disabled != nextState.disabled
  }

  _onChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  render() {
    let { id, label, type, readOnly, styles, tabIndex, onFinished, placeholder, disabled, required, novalidate, onChange, onBlur, onKeyDown, onKeyUp, mapKey, maxLength, classSpan, autoFocus } = this.props;
    let { value } = this.state;
    value = "" || value;

    if (!styles) styles = defaultStyle;

    return (
      <span className={classSpan} >
        <input
          tabIndex={tabIndex}
          ref={id}
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange ? (event) => onChange(event, this.refs) : this._onChange}
          onBlur={onBlur ? (event) => onBlur(event, this.refs) : () => { }}
          required={required}
          className={!required || (value && value.length > 0) || (value && value > 0) ? styles.balloon : styles.balloon + ' ' + styles.required}
          id={id} type={type || 'text'}
          value={readOnly ? ' ' : value}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={mapKey ? (event) => mapKey(event, this.props) : () => { }}
          onKeyUp={onKeyUp ? (event) => onKeyUp(event, this.refs) : () => { }}
          autoComplete='off'
          autoFocus={autoFocus || false}
          maxLength={maxLength}
          min={this.props.min}
          max={this.props.max}
          pattern={this.props.pattern}
          style={this.props.style}
          step={this.props.step}
        />
        {
          label
            ? <label htmlFor={id}>{label}</label>
            : null
        }
        {
          novalidate
            ? null
            : <div ref="validate" className={!required || (value && value.length > 0) || (value && value > 0) ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
        }
      </span>
    );
  }
}

export class FormDate extends Component {
  constructor(props) {
    super(props);

    var value = props.value || '';

    this.state = {
      value: value ? moment(value, 'DD-MM-YYYY') : value,
    };
    this._onDateChange = this._onDateChange.bind(this);
    this._valueDate = this._valueDate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || this.state.value
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.value != nextState.value || this.props.required != nextState.required || this.props.disabled != nextState.disabled
  }

  _onDateChange(event) {
    var v = event.target.value.replace(/\D/g, "")
    if (v.length < 8) {
      v = v.replace(/(\d{2})(\d)/, "$1/$2");
      v = v.replace(/(\d{2})(\d)/, "$1/$2");
      event.target.value = v;
      this.setState({
        value: ''
      });
    } else {
      v = v.replace(/(\d{2})(\d)/, "$1/$2");
      v = v.replace(/(\d{2})(\d)/, "$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1");
      event.target.value = v;
      this.setState({
        value: ''
      });
    }
    if (v.length === 11) {
      data = v.format(v, "DD/MM/YYYY");
      if (data.isValid()) {
        this.setState({
          value: data
        });
      }
    }
  }

  _valueDate(data) {
    this.setState({
      value: data
    });
  }

  render() {
    let { id, label, type, readOnly, styles, tabIndex, onFinished, placeholder, disabled, minDate, maxDate, required, novalidate, onChange, onBlur, onKeyDown, onKeyUp, mapKey, maxLength, classSpan } = this.props;
    let { value } = this.state;

    if (!styles) styles = defaultStyle;

    return (
      <span >
        <DatePicker
          tabIndex={tabIndex}
          ref={id}
          placeholder={placeholder ? placeholder : ''}
          weekdays={["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]}
          onChange={onChange ? (event) => onChange(event, this.refs) : this._valueDate}
          onChangeRaw={this._onDateChange}
          selected={value}
          openToDate={minDate}
          minDate={minDate}
          onBlur={onBlur ? (event) => onBlur(event, this.refs) : () => { }}
          required={required}
          className={!required || value != '' || value > 0 ? styles.balloon : styles.balloon + ' ' + styles.required}
          id={id} type={type || 'text'}
          disabled={disabled}
          readOnly={readOnly}
          maxDate={maxDate} />
        {
          label
            ? <label style={{ position: 'absolute', top: '-12px', textTransform: 'uppercase', fontSize: '.7rem' }} htmlFor={id}>{label}</label>
            : null
        }
        {
          novalidate
            ? null
            : <div ref="validate" className={!required || value != '' || value > 0 ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
        }
      </span>
    );
  }
}

class FormRadioButton extends Component {
  render() {
    let { desc, radios, styles, tabIndex } = this.props;

    return (
      <span className={styles.container_radio}>
        <h4>{desc}</h4>
        <ul className={styles.custom_radio}>
          {radios.map(function (col, j) {
            return (
              <li key={j}>
                <input tabIndex={tabIndex} type="radio" id={col.id + '-' + j} name={col.name} value={col.value} />
                <label htmlFor={col.id + '-' + j}>{col.label}</label>
                <div className={styles.check}><div className={styles.inside}></div></div>
              </li>
            );
          })}
        </ul>
        <div className={styles.clear}></div>
      </span>
    );
  }
}

export class FormSelect extends Component {
  constructor() {
    super();
    this.state = { value: '' }
    this._onChange = this._onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.state.value = nextProps.value;
  }

  componentWillMount() {
    this.state.value = this.props.value;
  }

  _onChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  render() {
    let { id, label, options, required, novalidate, styles, tabIndex, onChange, disabled, title } = this.props;
    let loopOptions = options.map((col) => <option key={col.value} disabled={col.disabled ? 'disabled' : ''} value={col.value}>{col.text}</option>);
    let value = this.state.value || '';
    value = '' + value;

    if (!styles) styles = defaultStyle;

    return (
      <span className={styles.spanBalloonSelect + ' ' + styles.arrowSelect}>
        <select tabIndex={tabIndex} className={!required || typeof value === 'object' || value.length > 0 ? styles.balloonSelected : styles.balloonSelect + ' ' + styles.required} ref={id} id={id} value={value ? typeof value === 'object' ? value.value : value : this.state.value} onChange={onChange ? onChange : this._onChange} required={required} disabled={disabled}>
          <option value="" disabled>{title ? label : ''}</option>
          {loopOptions}
        </select>
        {
          label
            ? <label htmlFor={id} >{label}</label>
            : null
        }
        {
          novalidate
            ? null
            : <div ref="validate" className={!required || typeof value === 'object' || value.length > 0 || value ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
        }
      </span>
    );
  }
}

export class FormMultipleSelect extends Component {
  constructor() {
    super();
    this.state = { options: [] }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ options: this.props.value ? this.props.value.map((v) => v.id) : [] })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.value ? nextProps.value.map((v) => v.id) : [] })
  }

  handleChange(options) {
    if (this.props.onChange) this.props.onChange(options)
    this.setState({ options: options });
  }

  render() {
    let { id, label, options, value, required, novalidate, styles, onGetOptionLabel, onGetOptionValue } = this.props;
    let loopOptions = options.map((col) => ({ value: col.value, label: col.label }));
    let valueState = this.state.options;

    if (!onGetOptionLabel) {
      onGetOptionLabel = (option) => option.label
    }

    if (!onGetOptionValue) {
      onGetOptionValue = (option) => option.value
    }

    if (!styles) styles = defaultStyle;

    return (
      <span>
        <input type='hidden' ref={id} id={id} value={valueState} required={required} />
        <MultiSelect
          isMulti
          options={loopOptions}
          allowSelectAll={false}
          onGetOptionLabel={onGetOptionLabel}
          onGetOptionValue={onGetOptionValue}
          onSelectOption={this.handleChange}
          value={valueState}
        />
        {
          novalidate
            ? null
            : <div ref="validate" className={!required || valueState.length > 0 ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
        }
      </span>
    );
  }
}

export class NFormSelect extends Component {
  constructor() {
    super();
    this.state = { options: '' }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ options: this.props.value ? this.props.value : '' })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.value ? nextProps.value : '' })
  }

  handleChange(options) {
    if (this.props.onChange) this.props.onChange(options)
    this.setState({ options: options });
  }

  render() {
    let { id, label, options, value, required, novalidate, styles, tabIndex } = this.props;
    let loopOptions = options.map((col) => <option key={col.value} value={col.value}>{col.text}</option>);
    let valueState = this.state.options;

    if (!styles) styles = defaultStyle;

    return (
      <span>
        <input type='hidden' ref={id} id={id} value={valueState} required={required} />
        <MultiSelect
          isMulti
          options={loopOptions}
          allowSelectAll={false}
          onGetOptionLabel={(option) => option.label}
          onGetOptionValue={(option) => option.id || option.value}
          onSelectOption={this.handleChange}
          value={valueState}
        />
        {
          novalidate
            ? null
            : <div ref="validate" className={!required || valueState ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
        }
      </span>
    );
  }
}

export class FormRange extends Component {
  constructor() {
    super();
    this.state = {
      valRange: 0,
      active: false,
      valueDiv: 0
    }
    this._onChange = this._onChange.bind(this)
    this._active = this._active.bind(this)
    this._onChangeDiv = this._onChangeDiv.bind(this)
    this._onValidDiv = this._onValidDiv.bind(this)
  }

  componentWillMount() {
    this.setState({
      valRange: this.props.value || 0,
      valueDiv: this.props.value || 0
    });
  }

  _onChange() {
    this.setState({
      valRange: this.refs[this.props.id].value,
      valueDiv: this.refs[this.props.id].value
    });
  }

  _onChangeDiv(e) {
    this.setState({
      valueDiv: e.target.value
    });
  }

  _onValidDiv() {
    let value = this.state.valueDiv;
    value = Number(value);

    if (value >= 0 && value >= this.props.min && value <= this.props.max) {
      this.setState({
        valRange: value,
        valueDiv: value
      });
    } else {
      value = this.props.value;

      this.setState({
        valRange: value,
        valueDiv: value
      });
    }

    this._active();
  }

  _active() {
    this.setState({
      active: !this.state.active
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      if (this.refs.valueDiv) {
        this.refs.valueDiv.select();
      }
    }
  }

  render() {
    let { id, min, max, save } = this.props;
    let { valRange, active, valueDiv } = this.state;

    return (
      <div className={defaultStyle.range} onMouseUp={save}>
        <div onClick={this._active}>
          {
            active
              ? <input ref="valueDiv" type="text" value={valueDiv} onChange={this._onChangeDiv} onBlur={this._onValidDiv} />
              : <div>{valRange}</div>
          }
        </div>

        <input type="range" id={id} ref={id} min={min} max={max} step="1" value={valRange} onChange={this._onChange} />
      </div>
    );
  }
}

class ToggleButton extends Component {
  constructor() {
    super();
    this.state = { status: true }
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentWillMount() {
    this.state.status = this.props.checked;
  }

  componentWillReceiveProps(nextProps) {
    this.state.status = nextProps.checked;
  }

  handleToggle() {
    this.setState({ status: !this.state.status });
    if (this.props.onChange) this.props.onChange();
  }

  render() {
    let { id, onChange, dataOn, dataOff, styles, label } = this.props;
    let { status } = this.state;
    styles = styles || defaultStyle;

    return (
      <span style={{ height: 40 }}>
        <Toggle style={{ width: 'auto' }} label={label} labelPosition='right' type="checkbox" data-isToggled={status} ref={id} defaultToggled={status} onToggle={this.handleToggle} />
      </span>
    );
  }
}

export class FormAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.id)
      document.getElementById(this.props.id).setAttribute('class', !this.props.required || this.props.value.length > 0 || this.props.value > 0 ? this.props.styles.balloon : this.props.styles.balloon + ' ' + this.props.styles.required);
  }

  handleChange(obj) {
    this.setState({ value: obj.value });
    if (this.props.onChange) this.props.onChange(obj);
  }

  render() {
    let { id, label, options, styles, onChange, required, onBlur, disabled, style } = this.props;
    const divError = <div style={{ lineHeight: '12px' }}>&nbsp;</div>;

    if (!styles) styles = defaultStyle;

    return (
      <span id={`span_${id}`}>
        <AutoComplete
          id={id}
          floatingLabelText={label}
          filter={AutoComplete.fuzzyFilter}
          dataSource={options}
          dataSourceConfig={{ text: 'text', value: 'value' }}
          style={{ width: '90%', ...style }}
          openOnFocus={true}
          fullWidth={true}
          onNewRequest={this.handleChange}
          disabled={this.props.disabled || false}
          onBlur={onBlur ? (event) => onBlur(event, this.refs) : this.handleBlur}
          required={required}
          errorText={required ? !this.state.value ? i18n.t('messages.requiredField', { ns: 'common' }) : divError : divError}
          errorStyle={{ bottom: '0 !important', fontSize: '10px' }}
          popoverProps={{ className: 'autocomplete' }}
        />
        <input type='hidden' ref={id} id={id} value={this.state.value} required={required} />
      </span>
    );
  }
}

export class FormNumber extends Component {
  constructor() {
    super();
    this.state = {
      value: 0
    };
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value == 0 ? 0 : this.props.value ? this.props.value : ''
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || ''
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.value != nextState.value || this.props.required != nextState.required || this.props.disabled != nextState.disabled
  }

  _onChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  render() {
    let { id, label, type, readOnly, styles, tabIndex, onFinished, placeholder, disabled, required, novalidate, onChange, onBlur, onKeyDown, onKeyUp, mapKey, maxLength, classSpan } = this.props;
    let { value } = this.state;

    if (!styles) styles = defaultStyle;

    return (
      <span className={classSpan} style={{ flexGrow: 2 }} >
        <input tabIndex={tabIndex}
          ref={id}
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange ? (event) => onChange(event, this.refs) : this._onChange}
          onBlur={onBlur ? (event) => onBlur(event, this.refs) : () => { }}
          required={required}
          className={!required || value.length > 0 || value >= 0 ? styles.balloon : styles.balloon + ' ' + styles.required}
          id={id} type={type || 'text'}
          value={readOnly ? ' ' : value}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={mapKey ? (event) => mapKey(event, this.props) : () => { }}
          onKeyUp={onKeyUp ? (event) => onKeyUp(event, this.refs) : () => { }}
          autoComplete='off'
          maxLength={maxLength}
          min={this.props.min}
          max={this.props.max}
          pattern={this.props.pattern}
          step={this.props.step} />
        {
          label
            ? <label htmlFor={id}>{label}</label>
            : null
        }
        {
          novalidate
            ? null
            : <div ref="validate" className={!required || value.length > 0 || value >= 0 ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
        }
      </span>
    );
  }
}

export class Custom extends Component {
  render() {
    return this.props.render();
  }
}
