import React from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardText } from 'material-ui/Card';

import moment from 'moment';
moment.locale('pt-br');

import style from './styles.scss';
import { FormInput } from '../../../../common/Form';
import Container from './../../../../common/Container';
import { requestRelatorioXLSX } from '../../../../common/request';
import LoadingRelatorio from './../../../../menuRelatorio/LoadingRelatorio';

import { request } from '../../../../common/request';
import update from "immutability-helper";
import _isEqual from 'lodash/isEqual';

var timerFiltrarItens;

@observer
export default class BoxList extends Container {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      showHelpFifo: false,
      loadingExport: false
    };

    this.onFilter = this.onFilter.bind(this);
    this.checkFifo = this.checkFifo.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.carregarOees = this.carregarOees.bind(this);
    this.carregarOeeEquipamento = this.carregarOeeEquipamento.bind(this);
  }

  async carregarOeeEquipamento(equipamento, endpoints, dataInicio, dataFim, turnoPersonalizado) {
    if(this.mounted) {
      return request(`${endpoints.OEE}/${dataInicio}/${dataFim}/${turnoPersonalizado}?equipamentos=${equipamento.id}&turnos=${[]}&dataInicioTurno=${0}&dataFimTurno=${0}`, {
        method: 'get',
      })
      .then(response => {
        if(response.status == 200){
          return response.json();
        }
      })
      .then(dataOEE => {
        let equipamentoOEE = {
          oeeComSetup: dataOEE.oee.com_setup.resultado,
          oeeEfetivoSetup: dataOEE.efetivo.com_setup.resultado
        };

        var index = this.state.items.findIndex(eqp => eqp.equipamento.id == equipamento.id);
        this.setState({
          items: update(
            this.state.items, {
              [index]: {
                oeeCarregado: {$set: true},
                oeeReal: {$set: equipamentoOEE.oeeComSetup},
                oeeEfetivo: {$set: equipamentoOEE.oeeEfetivoSetup}
              }
            }
          ) 
        });
      });
    }
  }

  async recursivaRequestOee(items, endpoints, dataInicio, dataFim, turnoPersonalizado) {
    if(items[0] == undefined) return;

    return await this.carregarOeeEquipamento(items[0].equipamento, endpoints, dataInicio, dataFim, turnoPersonalizado)
    .then(() => {
      this.recursivaRequestOee(items.slice(1,items.length), endpoints, dataInicio, dataFim, turnoPersonalizado );
    });
  }

  async carregarOees() {
    var turnoPersonalizado = false;
    let {dataInicio, dataFim } = this.props.filtro;
    
    await this.recursivaRequestOee(this.state.items, this.props.endpoints, dataInicio, dataFim, turnoPersonalizado);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentWillMount() {
    const { items } = this.props;
    this.setState({ items }, () => this.carregarOees());
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.items.length === nextProps.items.length) {
      return true;
    } else {
      return false;
    }
  }

  calculateDiffTime(startTime, endTime) {
    var startTime = moment(startTime);
    var endTime = moment(endTime);

    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes}`;
  }

  onFilter(search) {
    clearTimeout(timerFiltrarItens);

    let searchValue = search.replace(/[^a-z0-9 ]/gi, '');
    let regex = new RegExp(searchValue, 'i');

    timerFiltrarItens = setTimeout(() => {
      const items = this.props.items.filter((item) =>
        regex.test(item.equipamento.nome)
      );

      this.setState({ items }, () => this.carregarOees());
    }, 1000);

  }

  checkFifo(fifoPadrao, fifoReal) {
    const res = fifoReal - fifoPadrao;

    if (res <= 0) return;

    if (!this.state.showHelpFifo) this.setState({ showHelpFifo: true });

    return (
      <div className={style.pl_02}>
        (<span className={style.text_fifo_red}>{res}</span>)
      </div>
    );
  }

  /**
   * Exporta o resultado da pesquisa no formato *.xlsx
   */
  exportXLSX() {
    this.setState({ loadingExport: true });
    const { unidadeId, dataInicio, dataFim } = this.props.filtro;
    const url = `${this.endpoints.RELATORIO}/equipamento/oee-personalizado/${unidadeId}/${dataInicio}/${dataFim}`;

    requestRelatorioXLSX(url, 'Relatorio de OEE personalizado')
      .then(() => {
        this.setState({ loadingExport: false });
      }, erro => {
        this.setState({ loadingExport: false });
        alertBox(erro.mensagem, 'Não foi possível gerar o relatório', 'warning');
      }).catch(erro => {
        this.setState({ loadingExport: false });
        alertBox(erro.mensagem, 'Não foi possível gerar o relatório', 'warning');
      });
  }

  render() {
    const { items, showHelpFifo } = this.state;
    const { handleModal, filterMarketed } = this.props;

    var iconsOee = {
      verde: {
        color: 'green',
        className: 'fa fa-check-circle fa-lg ' + style.pr_05,
      },
      vermelho: {
        color: '#BF0617',
        className: 'fa fa-exclamation-circle fa-lg ' + style.pr_05,
      },
      amarelo: {
        color: '#F9D054',
        className: 'fa fa-exclamation-circle fa-lg ' + style.pr_05,
      },
    };

    return (
      <div style={{ width: '100%' }} className='box_list'>
        <Card className='box_list_card'>
          <CardHeader className='box_header' title='Monitor de Equipamentos' >
            <a title="Exportar"
              className="box_add" 
              onClick={() => this.exportXLSX()} 
              style={{ 
                cursor: 'pointer',
                top: '14px'
              }}
              >
              <img 
                alt="export"
                src='/assets/export-icon.svg' 
                style={{
                  width: '22px',
                  height: '20px'
                }} 
              />
            </a>
          </CardHeader>
          <CardText id='list_items_monitor' className='table_overflow' style={{ minHeight: '70vh', maxHeight: '70vh' }}>
            <div>
              <span style={{ width: 20 }} className='fa fa-search' />

              <FormInput
                id='search'
                placeholder='Pesquisar'
                style={{ background: 'transparent' }}
                onChange={(event) => this.onFilter(event.target.value)}
              />
            </div>

            <table
              className={'mui-table mui-table--bordered ' + style.table_list}
            >
              <thead>
                <tr className={style.row_title}>
                  <td className={style.text_center}>#</td>
                  <td>Equipamento</td>
                  <td>Oee Real | Meta</td>
                  <td>Oee Efetivo | Meta</td>
                  <td className={style.column_status}>Status 
                    <div className={`tooltip ${style.status_tooltip}`}>
                      <i onClick={() => handleModal('STATUS_FILTER')} className={`material-icons ${filterMarketed && style.icon_marked}`}>filter_list</i>
                      <div className={`tooltiptext ${style.status_tooltiptext}`}>
                        Filtrar
                      </div>
                    </div>
                  </td>
                  <td className={style.text_center}>
                    FIFO{' '}
                    {showHelpFifo ? (
                      <div className='tooltip'>
                        <i className='material-icons'>help</i>
                        <div className='tooltiptext' style={{ width: '170px' }}>
                          O número do centro representa a quantidade total de
                          lotes no FIFO e o número em destaque representa a
                          quantidade excedida no FIFO do padrão definido
                        </div>
                      </div>
                    ) : null}
                  </td>
                  <td className={style.text_center}>TMF</td>
                </tr>
              </thead>
              {
                items && items.length ? (
                  <tbody className='box_items'>
                    {
                      items.map((item, index) => {
                        return (
                        <tr key={index} className={style.row_value}>
                          <td className={style.text_center}>{index + 1}</td>
                          <td>
                            <div>
                              <span className={style.text_primary}>
                                {item.equipamento.nome}
                              </span>
                              <p className={style.text_secondary}>
                                {item.equipamento.etapa.nome}
                              </p>
                            </div>
                          </td>
                          <td>
                            {
                              item.oeeCarregado ?
                                <i
                                  style={{ color: iconsOee[item.oeeReal.cor].color }}
                                  className={iconsOee[item.oeeReal.cor].className}
                                ></i>
                              : 
                              `Carregando Oee...`
                            }
                              {item.oeeCarregado ? `${item.oeeReal.real.toLocaleString()}% |${' '}` : null }
                              {item.oeeCarregado ? `${item.oeeReal.padrao.toLocaleString()}%` : null}
                              
                          </td>
                          <td>
                            { item.oeeCarregado ?
                              <i
                                style={{ color: iconsOee[item.oeeEfetivo.cor].color }}
                                className={iconsOee[item.oeeEfetivo.cor].className}
                              ></i>
                              :
                              `Carregando Oee...`
                            }
                            {item.oeeCarregado ? `${item.oeeEfetivo.real.toLocaleString()}% |${' '}` : null }
                            {item.oeeCarregado ? `${item.oeeEfetivo.padrao.toLocaleString()}%` : null}
                          </td>
                          <td>
                            <div className='flex flex-align-center'>
                              <i className={`material-icons ${style.pr_05}`}>timer</i>
                              <div>
                                <span className={style.text_primary}>
                                  {item.ultimoApontamento
                                    ? `${item.ultimoApontamento.evento.codigo} - ${item.ultimoApontamento.evento.nome}`
                                    : '-'}
                                </span>
                                <p className={style.text_secondary}>
                                  {item.ultimoApontamento
                                    ? this.calculateDiffTime(
                                      item.ultimoApontamento.inicio,
                                      item.ultimoApontamento.fim
                                    )
                                    : '-'}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className={style.text_center}>
                            <div className='flex flex-just-center'>
                              {item.equipamento.fifo_real}{' '}
                              {this.checkFifo(
                                item.equipamento.fifo,
                                item.equipamento.fifo_real
                              )}
                            </div>
                          </td>
                          <td className={style.text_center}>
                            {parseFloat(item.tmf.valor.toFixed(2)).toLocaleString("pt-BR")}
                          </td>
                        </tr>
                      )})
                    }
                  </tbody>
                ) : (
                  <tr className={style.row_error}>
                    <td className="text-center" colSpan="100%"> 
                      <div className={style.box_error}>
                          <i className={`material-icons ${style.error_text}`}>warning</i>
                          <div style={{paddingLeft: '10px'}}>
                            <h3 className={style.error_text}>Nenhum item encontrado</h3>
                          </div>
                      </div>
                    </td>
                  </tr>
                 )
              }
            </table>
          </CardText>
        </Card>
        <LoadingRelatorio show={this.state.loadingExport} />
      </div>
    );
  }
}
