import React, { Component } from 'react';
import Container from '../common/Container';
import { withTranslation } from 'react-i18next';

class SemPermissao extends Component {

  render() {
    return (
      <Container >
        <div className={'loading-wrapper'}>
          <img style={{width: '10%', marginTop: '1em'}} src = '../../assets/padlock.svg'/>
            <h3 style={{marginTop: '1em'}}>{this.props.t("messages.noPermission")}</h3>
          <a href="/home" style={{marginTop: '1em', cursor: 'pointer', color: '#3E7AD1'}}> <i style={{color: '#3E7AD1'}} className="fa fa-home" ></i> Acessar a página inicial</a>
          <a href="javascript:window.history.go(-1)" style={{marginTop: '1em', cursor: 'pointer', color: '#3E7AD1' }}> <img style={{width: '1em'}} src = '../../assets/backArrow.svg'/> Voltar a página anterior</a>
        </div>
      </Container>
    );
  }
}

export default withTranslation("common")(SemPermissao);
