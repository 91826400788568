import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FilterBox from '../../common/FilterBox';
import Quest from '../../common/Quest';
import style from './product_list.css';
import { url } from '../../common/helpers.js';
import { alertBox } from '../../common/NForm.js';
import Store from '../../common/Store';
import { request } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import InfiniteScroll from 'react-infinite-scroller';
import MDSpinner from 'react-md-spinner';
import { Product } from 'clients/Products/Product';
import { withTranslation } from 'react-i18next';

function menorId(data) {
  let id = Infinity;
  let ht = null;
  let len = data.length;

  for (let i = 0; i < len; i++) {
    if (id > data[i].id) {
      id = data[i].id;
      ht = data[i];
    }
  }

  return ht;
}

@observer
class ProductsList extends Component {
  constructor() {
    super();
    this.onFilter = this.onFilter.bind(this);
    this.loader = this.loader.bind(this);

    this.state = {
      type: '',
      modal: false,
      makeSearch: null,
    };
  }

  componentWillMount() {
    this.props.store.searchModel = 'products';

    setTimeout(() => {
      this.setState({ loader: <h1> {this.props.t('list.empty', { ns: 'common' })}</h1> });
    }, 1000);
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }

  onFilter(val) {
    clearTimeout(this.state.makeSearch);

    // Remove todos os caracteres não imprimíveis da pesquisa
    let value = val.trim().replace(/[\t\n\r\f\v]/gi, '');
    this.props.setSearchText(value, true);

    this.setState({
      makeSearch: setTimeout(() => {
        this.props.carregarProdutos();
      }, 1000) // 1 segundos
    });
  }

  produtosRecursivos(arrayPredecessores) {
    var produtosPredecessores = [];

    arrayPredecessores.map(predecessor => {
      produtosPredecessores.push(predecessor.fk_id_pi);
      this.produtosRecursivos(predecessor.predecessores);
    });

    return produtosPredecessores;
  }

  requisicoesRecursivas(arrayPredecessores) {
    var requisicoes = [];
    const token = Store.getToken();

    arrayPredecessores.map(predecessor => {
      requisicoes.push(request(url(this.props.endpoints.ROTA_PRODUTO, { id: predecessor.fk_id_pi }), {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': token,
        }
      }));
      this.requisicoesRecursivas(predecessor.predecessores);
    });

    return requisicoes;
  }

  validaRotas(item, stages, holdingTimeFunc) {
    let produtosPredecessores = [];

    const { loading, endpoints } = this.props;
    const { id, type } = item;
    const token = Store.getToken();

    loading(true);
    let requisicaoPromise = [
      request(url(endpoints.ROTA_PRODUTO, { id: id }), {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': token,
        }
      }),
      request(url(endpoints.PRODUTOS_PIS, { id: id }), {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': token,
        }
      }),
      request(url(endpoints.HOLDING_TIMES, { id: id }), {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': token,
        }
      }),
      request(endpoints.ROTA + '/predecessores/' + id,
        {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token,
          }
        })
    ];

    Promise.all(requisicaoPromise).then(([rotasRes, prodPredecessorRes, htRes, ...rotasPredecessoras]) => {
      let success = (
        rotasRes.ok &&
        prodPredecessorRes.ok &&
        htRes.ok &&
        rotasPredecessoras.map(r => r.ok)
      );

      if (success) {
        return Promise.all([
          rotasRes.json(),
          prodPredecessorRes.json(),
          htRes.json()
        ].concat(rotasPredecessoras.map(p => p.json())));
      } else {
        return Promise.reject();
      }
    })
      .then(([rotas, prodPredecessor, hts, ...rotasPredecessoras]) => {
        if (rotas.length <= 0 || stages.length <= 0) {
          this.state.type = 'no_route';
          this.props.message(this.contentModal());
        } else {
          if (prodPredecessor.length > 0) {
            let prod = menorId(prodPredecessor);

            request(url(endpoints.ROTA_PRODUTO, { id: prod.id }), {
              method: 'get',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Access-Token': token,
              }
            })
              .then(res => {
                if (res.status == 200) {
                  return res.json();
                } else {
                  return Promise.reject();
                }
              })
              .then(predecessorRotas => {
                holdingTimeFunc({
                  id: id,
                  nome: item.name,
                  sku: item.sku,
                  holding_times: hts,
                  rotas: rotas,
                  rotasPredecessoras: rotasPredecessoras,
                  produtosPredecessores: prodPredecessor,
                  predecessor: {
                    id: prod.id,
                    nome: prod.nome,
                    sku: prod.sku,
                    rotas: predecessorRotas,
                  }
                }, stages);
              })
              .catch(e => {
                alertBox(this.props.t('errors.cannotLoadHoldingTime'), this.props.t('list.columns.holdingTime'), "error");
                loading(false);
              });
          }
          else {
            holdingTimeFunc({
              id: id,
              nome: item.name,
              sku: item.sku,
              holding_times: hts,
              rotas: rotas,
              rotasPredecessoras: rotasPredecessoras,
              produtosPredecessores: produtosPredecessores,
              predecessor: {}
            }, stages);
          }
        }
      })
      .catch(e => {
        alertBox(this.props.t('errors.cannotLoadHoldingTime'), this.props.t('list.columns.holdingTime'), "error");
        loading(false);
      });
  }

  contentModal() {
    const { type } = this.state;

    switch (type) {
      case 'no_route':
        {
          return <Quest handleModal={() => this.props.closeModal()} question={this.props.t('errors.no_route')} />;
        }
        break;
    }
  }

  hasHoldingTimePermissions() {
    return hasRole(['ROLE_HOLDING_TIME_CRIAR', 'ROLE_HOLDING_TIME_ATUALIZAR', 'ROLE_HOLDING_TIME_EXCLUIR', 'ROLE_HOLDING_TIME_VISUALIZAR']);
  }

  loader(useStyle = true) {
    return (
      <div key={0} className="loader">
        <MDSpinner singleColor='#2D4F7F' size={20} style={useStyle ? { marginTop: -5, marginRight: 10 } : {}} />
        {this.props.t('list.loading', { ns: 'common' })}
      </div>
    );
  }

  render() {
    const { carregarProdutos } = this.props;

    let loader = this.loader();

    let stages = this.props.store.stages.filter(s => !!s);

    let items = this.props.produtos.map((item, k) => {
      let area_industrial = this.props.store.fields.filter(f => f.id == item.fk_id_fields)[0];
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.name}</td>
          <td>{item.sku}</td>
          <td>{area_industrial.name || ''}</td>
          <td>{item.estado ? this.props.t('labels.active', { ns: 'common' }) : this.props.t('labels.inactive', { ns: 'common' })}</td>
          <td>{
            !this.hasHoldingTimePermissions() ? (
              <a>
                <i style={{ color: '#C6C6C6' }} className="material-icons">timer</i>
              </a>
            ) : (
              item.holding_times > 0 ? (
                <a href="javascript:void(0)"
                  onClick={() => this.validaRotas(item, stages, this.props.setHoldingTime)} className="hold_time__icon">
                  <i className="material-icons">timer</i>
                </a>
              ) : (
                <a href="javascript:void(0)"
                  onClick={() => this.validaRotas(item, stages, this.props.addHoldingTime)} className="hold_time__icon">
                  <i className="material-icons">remove</i>
                </a>
              )
            )
          }
          </td>
          <td>
            <div className='options'>
              <i className='material-icons' title={this.props.t('list.actions.edit', { ns: 'common' })} onClick={() => this.props.onOpenModal(item, 'up')}>edit</i>
              <i className='material-icons' title={this.props.t('list.actions.delete', { ns: 'common' })} onClick={() => hasRole('ROLE_PRODUTOS_EXCLUIR') && this.props.onOpenModal(item, 'del')}>cancel</i>
            </div>
          </td>
        </tr>
      )
    });

    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton
              disabled={!hasRole('ROLE_PRODUTOS_CRIAR')}
              className='box_add'
              onClick={() => this.props.onOpenModal(new Product(), 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <FloatingActionButton
              style={{ display: `${!hasRole('ROLE_PRODUTOS_CRIAR') ? 'none' : 'inline-block'}` }}
              className='box_upload'
              onClick={() => this.props.onOpenUpload(new Product())}>
              <i className='material-icons'>file_upload</i>
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('title')} />
            <CardText className='table_overflow'>
              <InfiniteScroll loadMore={carregarProdutos} hasMore={this.props.hasMoreProducts} initialLoad={false} useWindow={false} loader={loader} >
                <FilterBox
                  onFilter={this.onFilter}
                  waitingRequest={this.props.waitingRequest}
                  searchTextFilterBox={this.props.searchTextFilterBox}
                />
                <table className={'mui-table mui-table--bordered ' + style.table_list}>
                  <thead>
                    <tr>
                      <td className='id'>#</td>
                      <td className='name'>{this.props.t('list.columns.name')}</td>
                      <td className='code'>{this.props.t('list.columns.code')}</td>
                      <td className='areas'>{this.props.t('list.columns.fields')}</td>
                      <td className='status'>{this.props.t('list.columns.status')}</td>
                      <td className='holding_time'>{this.props.t('list.columns.holdingTime')}</td>
                      <td className='actions'>{this.props.t('list.columns.actions')}</td>
                    </tr>
                  </thead>
                  <tbody className='box_items'>
                    {items}
                  </tbody>
                </table>
              </InfiniteScroll>
            </CardText>
          </Card>
        </div>
      )
    } else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_PRODUTOS_CRIAR')} className='box_add' onClick={() => this.props.onOpenModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <FloatingActionButton
              style={{ display: `${!hasRole('ROLE_PRODUTOS_CRIAR') ? 'none' : 'inline-block'}` }}
              className='box_upload'
              onClick={() => this.props.onOpenUpload(new Product())}>
              <i className='material-icons'>file_upload</i>
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('title')} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                {this.state.loader}
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation('products')(ProductsList)