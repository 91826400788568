let numeric = '';
let produzido = '';
let rejeitado = '';

import moment from 'moment';
import i18n from './i18n';

class Utils {
  constructor() {
    this.referenciaPrincipal = () => localStorage.getItem('ReferenciaPrincipal');
    this.referenciaSecundaria = () => localStorage.getItem('ReferenciaSecundaria');
  }

  uniq(arr, prop = 'id') {
    return arr.filter((e, p, zarr) => zarr.map(a => a[prop]).indexOf(e[prop]) == p);
  }

  ordenaApontamentos(apontamentos) {
    return apontamentos.sort((a, b) => {
      if (moment(a.inicio).isAfter(b.inicio)) {
        return 1;
      }
      if (moment(a.inicio).isBefore(b.inicio)) {
        return -1;
      }
      if (moment(a.inicio).isSame(b.inicio)) {
        if (moment(a.fim).isAfter(b.fim)) {
          return 1;
        }
        if (moment(a.fim).isBefore(b.fim)) {
          return -1;
        }
        if (moment(a.fim).isSame(b.fim)) {
          if (a.evento != null && b.evento != null) {
            if (a.evento.classe > b.evento.classe) {
              return 1;
            }
            if (a.evento.classe < b.evento.classe) {
              return -1;
            }
          }
          return 0;
        }
        return 0;
      }
      return 0;
    });
  }

  // Remove campos que não precisam ser enviados na requisição
  limparApontamentos(apontamentos) {
    return apontamentos.map(a => ({
      apontamento: {
        uuid: a.apontamento.uuid,
        inicio: a.apontamento.inicio,
        fim: a.apontamento.fim,
        ciclo: a.apontamento.ciclo,
        rejeitado: a.apontamento.rejeitado,
        rejeitado_acumulado: a.apontamento.rejeitado_acumulado,
        status: a.apontamento.status,
        produzido: a.apontamento.produzido,
        produzido_acumulado: a.apontamento.produzido_acumulado,
        lote: a.apontamento.lote && a.apontamento.lote.id !== 0 ? {
          id: a.apontamento.lote.id,
          numero: a.apontamento.lote.numero,
          produto: this.filtrarPropriedades(a.apontamento.lote.produto, ['id', 'nome', 'sku']),
        } : a.apontamento.numero_lote ? {
          id: 0,
          numero: a.apontamento.numero_lote
        } : null,
        equipamento: {
          id: a.apontamento.equipamento.id,
          multiplo: a.apontamento.equipamento.multiplo
        },
        evento: this.filtrarPropriedades(a.apontamento.evento, ['id', 'nome', 'codigo', 'classe', 'fluxo']),
        classe: a.apontamento.classe,
        observacao: a.apontamento.observacao,
        observacao1: a.apontamento.observacao1,
        observacao2: a.apontamento.observacao2,
        observacao3: a.apontamento.observacao3,
        criado_usuario: this.filtrarPropriedades(a.apontamento.criado_usuario, ['id', 'username']),
        atualizado_usuario: this.filtrarPropriedades(a.apontamento.atualizado_usuario, ['id', 'username']),
        excluido_usuario: this.filtrarPropriedades(a.apontamento.excluido_usuario, ['id', 'username']),
        consistente_oee: a.apontamento.consistente_oee,
        classe_estrutura: a.apontamento.classe_estrutura,
        consistente_oee_estrutura: a.apontamento.consistente_oee_estrutura,
        origem_apontamento: a.apontamento.origem_apontamento,
        estado: a.apontamento.estado,
        tipo: a.apontamento.tipo,
        sujo: a.apontamento.sujo,
        tipo_producao: a.apontamento.tipo_producao,
        numero_lote: a.apontamento.lote != null ? a.apontamento.lote.numero : a.apontamento.numero_lote
      },
      tipo: a.tipo
    })
    );
  }

  parseEstadoApontamento(estado) {
    if (estado == 0)
      return 'Aceito';
    else if (estado == 1)
      return 'Rejeitado';
    else if (estado == 2)
      return 'Inconsistente';
    else {
      return estado
    }
  }

  filtrarPropriedades(entidade, propriedades) {
    let novoObjeto = {};

    if (!entidade) return null;

    if (!propriedades) propriedades = ['id'];

    propriedades.map(propriedade => {
      novoObjeto[propriedade] = entidade[propriedade];
    });

    return novoObjeto;
  }

  sortField(arr, field = 'name') {
    return arr.sort((a, b) => {
      let x = typeof a[field] == 'string' ? a[field].toLowerCase() : a[field];
      let y = typeof b[field] == 'string' ? b[field].toLowerCase() : b[field];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  arrIdentical(a1, a2) {
    let i = a1.length;
    if (i != a2.length) return false;
    while (i--) {
      if (a1[i] !== a2[i]) return false;
    }
    return true;
  }

  convertTime(time) {
    if (time) {
      let hours = Math.floor(time / 60);
      let minutes = time % 60;
      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;

      return hours + ':' + minutes + ' h';
    }
    else return '';
  }

  revertTime(time) {
    time = time.split(':');
    return (parseInt(time[0] * 60)) + parseInt(time[1]);
  }

  clearFields() {
    numeric = '';
    produzido = '';
    rejeitado = '';
  }

  maskNumeric(event) {
    if (!event.shiftKey && !event.altKey) {
      if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode === 8) || (event.keyCode === 46) || (event.keyCode === 17) || (event.keyCode === 67) || (event.keyCode === 86)) {
        numeric = event.target.value;
      } else {
        if ((event.keyCode === 108) || (event.keyCode === 110) || (event.keyCode === 194) || (event.keyCode === 188) || (event.keyCode === 190)) {
          numeric = event.target.value.replace(',', '.');
        }
      }
    }
    return numeric;
  }

  floatBlock(event, id) {
    if (!event.shiftKey && !event.altKey) {
      if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode === 8) || (event.keyCode === 46)) {
        if (id == 'produzido') { produzido = event.target.value; }
        else if (id == 'rejeitado') { rejeitado = event.target.value; }

      } else {
        if ((event.keyCode === 108) || (event.keyCode === 110) || (event.keyCode === 188)) {
          if (id == 'produzido') { produzido = event.target.value.replace(',', ''); }
          else if (id == 'rejeitado') { rejeitado = event.target.value.replace(',', ''); }
        }

        if ((event.keyCode === 194) || (event.keyCode === 190)) {
          if (id == 'produzido') { produzido = event.target.value.replace('.', ''); }
          else if (id == 'rejeitado') { rejeitado = event.target.value.replace('.', ''); }
        }
      }

      if (id == 'produzido') { return produzido; }
      else if (id == 'rejeitado') { return rejeitado; }
    }
  }

  maxStringLength(string, maxlength) {
    return string.length > maxlength ? string.substring(0, maxlength - 3) + '...' : string;
  }

  getURLParams(location) {
    const splittedLocation = location.split('?');

    if (splittedLocation[1] === undefined) {
      return {}
    }

    const urlParams = splittedLocation[1].split('&').map(keyValue => keyValue.split('=')).reduce(
      (params, [key, value]) => {
        params[key] = value;
        return params;
      }, {});

    return urlParams;
  }

  isIE() {
    return /*@cc_on!@*/false || !!document.documentMode;
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  zeroAEsquerda(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  getReferenciaLoteOrdemInformado() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchInformed', { ns: 'batches' }) : i18n.t('orderInformed', { ns: 'batches' });
  }

  getReferenciaoLoteaOrdem() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('theBatch', { ns: 'batches' }) : i18n.t('theOrder', { ns: 'batches' });
  }

  getReferenciaAlteradoAlterada() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('updatedMale', { ns: 'batches' }) : i18n.t('updatedFemale', { ns: 'batches' });
  }

  getReferenciaLoteOrdem() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batch', { ns: 'batches' }) : i18n.t('order', { ns: 'batches' });
  }

  getReferenciaOrdemLote() {
    return this.referenciaSecundaria() == 'Lote' ? i18n.t('batch', { ns: 'batches' }) : i18n.t('order', { ns: 'batches' });
  }

  getReferenciaLotesOrdens() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batches', { ns: 'batches' }) : i18n.t('orders', { ns: 'batches' });
  }

  getReferenciaLoteOrdemExcluido() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchDeleted', { ns: 'batches' }) : i18n.t('orderDeleted', { ns: 'batches' });
  }

  getReferenciaLotesOrdensAtrasadas() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('lateBatches', { ns: 'batches' }) : i18n.t('lateOrders', { ns: 'batches' });
  }

  getReferenciaLoteOrdemJaCadastrado() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchAlreadyExists', { ns: 'batches' }) : i18n.t('orderAlreadyExists', { ns: 'batches' });
  }

  getReferenciaLoteOrdemAlterado() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchUpdated', { ns: 'batches' }) : i18n.t('orderUpdated', { ns: 'batches' });
  }

  getReferenciaLoteOrdemCriado() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchCreated', { ns: 'batches' }) : i18n.t('orderCreated', { ns: 'batches' });
  }

  getReferenciaEsteLoteEstaOrdem() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('thisBatch', { ns: 'batches' }) : i18n.t('thisOrder', { ns: 'batches' });
  }

  getReferenciaLoteOrdemInformada() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('informedMale', { ns: 'batches' }) : i18n.t('informedFemale', { ns: 'batches' });
  }

  getReferenciaDosLotesDasOrdens() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('ofBatches', { ns: 'batches' }) : i18n.t('ofOrders', { ns: 'batches' });
  }

  getReferenciaLoteOrdemNaoEncontrada() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchNotFound', { ns: 'batches' }) : i18n.t('orderNotFound', { ns: 'batches' });
  }

  getReferenciaUmLoteUmaOrdem() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('oneBatch', { ns: 'batches' }) : i18n.t('oneOrder', { ns: 'batches' });
  }

  getReferenciaLoteInvalidoOrdemInvalida() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('invalidBatch', { ns: 'batches' }) : i18n.t('invalidOrder', { ns: 'batches' });
  }

  getReferenciaCadastradoCadastrada() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('registeredMale', { ns: 'batches' }) : i18n.t('registeredFemale', { ns: 'batches' });
  }

  getReferenciaLotePredecessorOrdemPredecessora() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('predecessorBatch', { ns: 'batches' }) : i18n.t('predecessorOrder', { ns: 'batches' });
  }

  getReferenciaLotesPredecessoresOrdensPredecessoras() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('predecessorBatches', { ns: 'batches' }) : i18n.t('predecessorOrders', { ns: 'batches' });
  }

  getReferenciaDoLoteDaOrdem() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('ofBatch', { ns: 'batches' }) : i18n.t('ofOrder', { ns: 'batches' });
  }

  getReferenciaDoLoteDaOrdemParametros() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('common.ofBatch', { ns: 'parameters' }) : i18n.t('common.ofOrder', { ns: 'parameters' });
  }

  getReferenciaLoteNaoFinalizadoOrdemNaoFinalizada() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('batchesNotFinished', { ns: 'batches' }) : i18n.t('ordersNotFinished', { ns: 'batches' });
  }

  getReferenciaNenhumLoteNenhumaOrdem() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('noBatch', { ns: 'batches' }) : i18n.t('noOrder', { ns: 'batches' });
  }

  getReferenciaLoteInconsistenteOrdemInconsistente() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('inconsistentBatch', { ns: 'batches' }) : i18n.t('inconsistentOrder', { ns: 'batches' });
  }

  getReferenciaLoteInconsistenteOrdemAtrasado() {
    return this.referenciaPrincipal() == 'Lote' ? i18n.t('lateBatches', { ns: 'batches' }) : i18n.t('lateOrders', { ns: 'batches' });
  }

  clientTenantName() {
    return localStorage.getItem('tenant');
  }

  async formatDate(event, updateState, propertyName, validateDate) {
    let value = event.target.value.replace(/\D/g, '');

    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');

    if (value.length >= 8) {
      value = value.replace(/(\d{4})(\d)/, '$1');
    }

    if (updateState) {
      await updateState({
        name: propertyName,
        value: value
      });
    }

    if (value.length === 10 && validateDate) {
      let newDate = moment(value, 'L');

      if (newDate.isValid()) {
        validateDate();
      }
    }
  }
}

export default new Utils();
