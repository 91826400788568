import React, { useEffect, useState } from 'react';
import listStyles from './list.scss';
import ListCompact from './ListCompact';
import moment from 'moment';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';
import { isEqual, uniqueId } from 'lodash';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

let hammerIcon = '../../assets/hammer.svg';
let maintenceIcon = '../../assets/maintence.svg';

function Equipment(props) {
  const [oee, setOee] = useState({});
  const [gargalo, setGargalo] = useState('');
  const [posicaoGargalo, setPosicaoGargalo] = useState(0);
  const [divEquipamentosHeight, setDivEquipamentosHeight] = useState({});

  useEffect(() => {
    const { equipment, gargalos } = props;
    const posicaoGargalo = _.findIndex(gargalos, function (g) { return g.idEquipamento === equipment.id; }) + 1;

    setPosicaoGargalo(posicaoGargalo);
  }, [props]);

  useEffect(() => {
    const divEquipamentos = document.getElementById(`equipment_card_${props.equipment.id}`);
    const height = divEquipamentos.offsetHeight;

    setDivEquipamentosHeight(prevState => {
      const newHeight = { [props.equipment.id]: height };

      if (isEqual(newHeight, prevState)) {
        return prevState;
      }

      return newHeight;
    });
  });

  const redirecionarOEE = (event, nomeEquipmento, idEquipment) => {
    event.preventDefault();

    var data = moment().utcOffset(0, true).format('YYYY-MM-DD');

    window.open(
      `/oee?equipamentoId=${idEquipment}&equipamentoNome=${nomeEquipmento}&modo=6&dataInicio=${data}T00:00:00Z&dataFim=${data}T23:59:59Z`,
      '_blank'
    );
  }

  let { equipment, isBlur, opacity, batches,arrAcitve, listWaiting, stage, listProcess, sensores, sensores_data, setup_flow_view } = props;
  let corProgressBar, backgroundProgressBar, heightProgress = '100%', marginTopProgress = 'none';
  let setupEquipamento = setup_flow_view.find(s => s.equipamentoId === equipment.id) || {};
  let porcentagemSetup = setupEquipamento ? setupEquipamento.porcentagem : 0;

  const title = equipment.ultimo_evento || '';

  let corOee = '#228453';
  let oeeProps = {};
  let oeeReal = '-';
  let oeePadrao = '-';
  let tempoCicloPadrao = 0;
  let tempoCicloReal = 0;

  let mostrarSensores = sensores === true;
  let sensoresEquipamento = [];
  let titleSensores = `${props.t("labels.lastUpdate")} \n`;
  let labelSensores = '';
  let countSensores = 0;
  let spanSensores = null;

  if (mostrarSensores) {
    sensoresEquipamento = sensores_data.filter(sensor => sensor.equipamento_id == equipment.id);

    if (sensoresEquipamento.length > 0) {
      sensoresEquipamento.map(sensor => {
        let dataFormatada = moment(sensor.timestamp).format('L LT');

        titleSensores += `${sensor.sensor_nome}: ${dataFormatada}\n`;
      });

      sensoresEquipamento.map(sensor => {
        if (countSensores <= 2) {
          labelSensores += countSensores === 0 ? `${sensor.valor} ${sensor.variavel}` : ` | ${sensor.valor} ${sensor.variavel}`;
        }

        countSensores++;
      });

      spanSensores = <span title={titleSensores} style={{ color: 'lightgray' }}>{labelSensores}</span>
    }
  }

  listWaiting.map(m => {
    tempoCicloPadrao += m.tempo_ciclo.tempo_padrao;
    tempoCicloReal += m.tempo_ciclo.tempo_real;
  });

  listProcess.map(m => {
    tempoCicloPadrao += m.tempo_ciclo.tempo_padrao;
    tempoCicloReal += m.tempo_ciclo.tempo_real;
  });

  tempoCicloPadrao = tempoCicloPadrao.toLocaleString("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 });
  tempoCicloReal = tempoCicloReal.toLocaleString("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 });

  switch (setupEquipamento.cor) {
    case 'verde':
      corProgressBar = '#228453';
      backgroundProgressBar = '#28666B';
      break;

    case 'amarelo':
      corProgressBar = '#efd679';
      backgroundProgressBar = '#9c9872';
      break;

    case 'vermelho':
      corProgressBar = '#a2090b';
      backgroundProgressBar = '#6c1f2f';
      break;
  }

  porcentagemSetup = Math.min(porcentagemSetup, 100);

  if (porcentagemSetup < 7) {
    heightProgress = '75%';
    marginTopProgress = '0.3em';
  }

  if (porcentagemSetup > 7 && porcentagemSetup < 15) {
    heightProgress = '80%';
    marginTopProgress = '0.2em';
  }

  if (porcentagemSetup == 0) {
    backgroundProgressBar = '#6c1f2f';
  }

  if (props.oee.resultado != undefined) {
    oeeProps = props.oee;
    oeeReal = (oeeProps.resultado == undefined ? '-' : oeeProps.resultado.real + '%');
    oeePadrao = (oeeProps.resultado == undefined ? '-' : oeeProps.resultado.padrao + '%');

    if (oeeProps.resultado != undefined) {
      if (oeeProps.resultado.cor == 'vermelho')
        corOee = "#F47474";
      else if (oeeProps.resultado.cor == 'amarelo')
        corOee = "#E2BD21";
    }
  }

  let equipmentCardHeight = divEquipamentosHeight[equipment.id];

  const uniqueMenuEquipament = uniqueId('equipament');

  return (
    <>
      <ContextMenu id={uniqueMenuEquipament}>
      <MenuItem data={{ id: uniqueMenuEquipament}} onClick={(event) => hasRole('ROLE_OEE_VISUALIZAR') && redirecionarOEE(event, equipment.name, equipment.id)} >
            {props.t("actions.detailOEE")}
        </MenuItem>
        {
              (hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) && props.listWaiting.length !== 0) ?
                <MenuItem onClick={() => props.handleModal('listLote', '', '', { array: listWaiting }, '', { equipment }, { stage })}>
                  {props.t("actions.detailFifo")}
                </MenuItem>
                :
                null
          }
      </ContextMenu>
    <div className={listStyles.equipment} style={{ opacity: arrAcitve !== undefined && arrAcitve.indexOf(parseInt(equipment.id)) !== -1 && isBlur ? 1 : opacity }}>
      <div style={{ opacity: opacity }}>
        <ListCompact {...props} type="waiting" array={listWaiting} processing="" />
      </div>
 
      <ContextMenuTrigger id={uniqueMenuEquipament}>
      <div
        id={`equipment_card_${equipment.id}`}
        style={{ cursor: 'pointer' }}
        >
        {
          equipment.situation == props.t("labels.production").toUpperCase() ?
            <span className={listStyles.equipmentIcon} style={{ ...(equipmentCardHeight !== undefined ? { height: `${equipmentCardHeight}px` } : null) }}>
              <i className='material-icons' style={{ fontSize: 15 }} title={title}>settings</i>
            </span>
            : equipment.situation == props.t("labels.maintenanceStoppage").toUpperCase() ?
              <span className={listStyles.equipmentIcon} style={{ ...(equipmentCardHeight !== undefined ? { height: `${equipmentCardHeight}px` } : null) }}>
                <img src={hammerIcon} style={{ marginLeft: '0.25em', marginTop: '0.6em' }} width={15} height={15} title={title} />
              </span>
              : equipment.situation == props.t("labels.downtime").toUpperCase() ?
                <span className={listStyles.equipmentIcon} style={{ ...(equipmentCardHeight !== undefined ? { height: `${equipmentCardHeight}px` } : null) }}>
                  <i className='material-icons' style={{ fontSize: 15 }} title={title}>pause</i>
                </span>
                : equipment.situation == props.t("labels.setup").toUpperCase() ?
                  <div
                    className={listStyles.equipmentIcon}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      overflow: 'hidden',
                      position: 'relative',
                      ...(equipmentCardHeight !== undefined ? { height: `${equipmentCardHeight}px` } : null),
                      backgroundColor: backgroundProgressBar
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: corProgressBar,
                        width: `${porcentagemSetup}%`,
                        height: '100%',
                        position: 'absolute',
                        zIndex: 0,
                      }}
                    ></div>
                    <div style={{ position: 'relative' }}>
                      <img
                        src={maintenceIcon}
                        style={{ marginLeft: '0.2em', zIndex: 1 }}
                        width={15}
                        height={15}
                        title={title}
                      />
                    </div>
                  </div>
                  : null
        }
        <span className={listStyles.equipmentName} title={equipment.name}>{equipment.name}</span>
        <span className={listStyles.equipmentOee}>
          {
            posicaoGargalo !== undefined && posicaoGargalo !== 0 ?
              <span title={`${props.t("labels.bottleneck")}: ` + posicaoGargalo} style={{ color: '#FFF' }}>{`G${posicaoGargalo} :: `}</span>
              : ''
          }
          <span style={{ marginTop: '2px' }}>
            <span title={`${props.t("labels.realOEE")}: ` + oeeReal} style={{ color: corOee }}>{oeeReal}</span>
            <span title={`${props.t("labels.goalOEE")}: ` + oeePadrao} style={{ color: 'lightgray' }}> | {oeePadrao}</span>
          </span>
          <span style={{ marginTop: '2px' }}>
            <span title={`${props.t("labels.totalDefaultTime")}: ` + tempoCicloPadrao + 'h'} style={{ color: 'lightgray' }}>{tempoCicloPadrao + 'h'}</span>
            <span title={`${props.t("labels.totalDefaultTimeOEE")}: ` + tempoCicloReal + 'h'} style={{ color: 'lightgray' }}>{' | ' + tempoCicloReal + 'h'}</span>
          </span>
          <span style={{ marginTop: '3px' }}>
            {spanSensores}
          </span>
        </span>
      </div>
      </ContextMenuTrigger>
      <div style={{ opacity: opacity }}>
        <ListCompact {...props} type="process" array={listProcess} processing={true} />
      </div>

    
    </div>
    </>
  );
}

export default withTranslation("flowView")(Equipment);