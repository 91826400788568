import React, { Component } from 'react';
import styles from './deleteItem.scss';
import Button from './Button';
import { withTranslation } from 'react-i18next';

class FieldDelete extends Component {
  render() {
    let { handleModal, msgModal, type, disabled } = this.props;
    let { id, name, num, uuid } = this.props.field;
    let userName = this.props.field.nome;
    let desc = this.props.field.descr;
    let deleteField;

    if (type === 'uuid') {
      deleteField = uuid;
    } else {
      deleteField = id;
    }

    return (
      <div className="modal" style={{ width: '500px', height: '100%' }}>
        <div>
          <div>
            <h3>{msgModal || this.props.t('messages.deleteConfirmation')}</h3>
          </div>
        </div>
        <div>
          <div className={styles.box}>
            <div>
              <h2><strong>{name || num || userName || desc}</strong></h2>
            </div>
            <div>
              <Button button={{ type: 'round', className: 'button_primary', text: this.props.t('labels.yes'), onClick: () => this.props.delete(deleteField), disabled: disabled }} />
              <Button button={{ type: 'round', text: this.props.t('labels.no'), onClick: handleModal }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(FieldDelete);
