import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import {
  NButton, NForm, NFormTitle,
  NButtonGroup, alertBox,
} from '../../common/NForm';
import { url } from '../../common/helpers';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Checkbox from 'material-ui/Checkbox';
import update from 'immutability-helper';
import { request } from '../../common/request';
import MDSpinner from 'react-md-spinner';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@observer
class GroupUsersPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      modalType: '',
      value: null,
      permitirTudo: false,
      permissionsChild: false,
      permissionsChild2: false,
      items: [],
      grupo: null,
      permissions: [],
      filter: '',
      loadingPermissoes: false,
      salvarButton: true,
      loadingPage: true
    };

    this.onFilter = this.onChangeFilter.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.salvarPermissoes = this.salvarPermissoes.bind(this);
  }

  carregarGrupoUsuarios(origem, nome) {
    return new Promise((resolve, reject) => {
      request(this.endpoints.SGCA_GRUPO + '?permissao=true', {
        method: 'get'
      })
        .then(function (res) {
          if (res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            this.setState({ loadingPage: false });
          }
        })
        .then(data => {
          if (data != null) {
            let items =
              data.map(value =>
                <MenuItem
                  key={value.uuid}
                  value={value.uuid}
                  primaryText={value.nome}
                  onClick={(e, val) => {
                    this.closeModal();

                    this.setState({
                      grupo: value
                    }, () => {
                      this.getPermissions();
                    });
                  }}
                />
              );

            if (origem == 'criar' && !!nome) {
              data.filter(f => f.nome == nome).map(value => this.setState({ grupo: value, value: value.uuid }));
            }

            if (origem == 'carregar' || origem == 'deletar') {
              this.setState({ grupo: data[0], value: data[0].uuid });
            }

            var grupo = this.state.grupo;
            var value = this.state.value;
            this.getPermissions();

            this.setState({
              items: items,
              grupo: grupo,
              value: value,
              loadingPage: false
            });
          }
          else {
            this.setState({
              items: [],
              grupo: null,
              value: null,
              loadingPage: false
            });
          }

          return resolve();
        });
    });
  }

  componentWillMount() {
    this.carregarGrupoUsuarios('carregar', '');
  }

  handleModal(e, value) {
    this.setState({
      modal: true,
      modalType: value
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      modalType: ''
    });
  }

  handleChange = (event, index, value) => this.setState({ value });

  onChangeFilter(value) {
    this.setState({
      filter: value
    });
  }

  getPermissions() {
    return new Promise((resolve, reject) => {
      const { grupo } = this.state;

      this.setState({
        loadingPermissoes: true,
      }, () => {
        request(url(this.endpoints.SGCA_PERMISSOES_GRUPO, { uuid: grupo.uuid }), {
          method: 'get'
        })
          .then(function (res) {
            if (res.status === 200) {
              return res.json();
            }
            return Promise.reject(res);
          })
          .then(data => {
            this.setState({
              salvarButton: true,
              acessaTudo: data.length === data.filter((permissao) => permissao.permite_acesso).length,
              configuraTudo: data.filter((permissao) => !permissao.somente_acesso).length === data.filter((permissao) => permissao.permite_configuracao).length,
              permissions: data.map((permissao, index) => ({
                index,
                uuid: permissao.uuid,
                moduloUuid: permissao.modulo_uuid,
                permiteAcesso: permissao.permite_acesso,
                somenteAcesso: permissao.somente_acesso,
                permiteConfiguracao: permissao.permite_configuracao,
                nome: `${permissao.categoria} - ${permissao.modulo}`,
              })),
              loadingPermissoes: false,
            }, () => {
              resolve(data);
            });
          })
          .catch(err => {
            this.setState({
              loadingPermissoes: false,
            }, () => {
              reject(err);
            });
          });
      });
    });
  }

  onSave(nome) {
    this.closeModal();

    const { grupo } = this.state;

    let method = 'post';

    let url = this.endpoints.SGCA_GRUPO;

    if (!!grupo) {
      method = 'put';
      url = `${url}/${grupo.uuid}`;
    }

    let acao = (method === 'post') ? this.props.t("messages.successCreate") : this.props.t("messages.sucessEdit");
    let acao2 = (method === 'post') ? 'criar' : 'alterar';
    let acaoMensagem = (method === 'post') ? this.props.t("errors.errorCreate") : this.props.t("errors.errorEdit")
    request(url, {
      method: method,
      body: JSON.stringify({
        ...grupo,
        nome: nome?.trim(),
      })
    })
      .then(res => {
        if ((method === 'post' && res.status === 201) || (method === 'put' && res.status === 200)) {
          alertBox(acao, this.props.t("title"), 'success');
          this.carregarGrupoUsuarios(acao2, nome);
        } else if (res.status == 409) {
          alertBox(this.props.t("errors.groupExists"), this.props.t("title"), 'error');
        }
      })
      .catch(() => {
        alertBox(acaoMensagem, this.props.t("title"), 'error');
      });
  }

  onDelete() {
    this.closeModal();

    const { grupo } = this.state;
    const url = `${this.endpoints.SGCA_GRUPO}/${grupo.uuid}`;

    request(url, {
      method: 'delete'
    })
      .then(function (res) {
        if (res.status === 200) {
          return Promise.resolve();
        }
        return Promise.reject(res);
      })
      .then(() => {
        this.carregarGrupoUsuarios('deletar', '').then(() => {
          alertBox(this.props.t("messages.successDelete"), this.props.t("title"), 'success');
        });
      })
      .catch(error => {
        alertBox(this.props.t("errors.errorDelete"), this.props.t("title"), 'error');
      });
  }

  contentModal() {
    const { grupo } = this.state;

    switch (this.state.modalType) {
      case 'cadastrarGrupoUsuario':
        this.state.grupo = null;
        return <RegGroupUsers onSave={this.onSave.bind(this)} grupo={{}} closeModal={this.closeModal} t={this.props.t} />;
      case 'editarGrupoUsuario':
        return <RegGroupUsers onSave={this.onSave.bind(this)} grupo={grupo} closeModal={this.closeModal} t={this.props.t} />;
      case 'deletarGrupoUsuario':
        return <DeleteGroupUsers onDelete={this.onDelete.bind(this)} grupo={grupo} closeModal={this.closeModal} t={this.props.t} />;
      case 'loading':
        return <div className="modal" id={styles.loading} style={{ width: '80px', height: '100%' }}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>;
    }
  }

  salvarPermissoes() {
    const { permissions, grupo } = this.state;

    let data = permissions.map(permissao => {
      let { uuid, permiteAcesso, permiteConfiguracao } = permissao;

      return {
        uuid,
        permite_acesso: permiteAcesso,
        permite_configuracao: permiteConfiguracao
      };
    });

    this.setState({
      salvarButton: false,
      loadingPermissoes: true,
    }, () => {
      request(url(this.endpoints.SGCA_PERMISSOES_GRUPO, { uuid: grupo.uuid }), {
        method: 'post',
        body: JSON.stringify(data)
      }).then((res) => {
        if (res.status === 201) {
          alertBox(this.props.t("messages.successUpdatePermissions"), this.props.t("title"), 'success');
          return Promise.resolve();
        }
        return Promise.reject(res);
      }).then(() => {
        this.getPermissions();
      }).catch(error => {
        alertBox(this.props.t("errors.errorUpdatePermissions"), this.props.t("title"), 'error');
        this.setState({ loadingPermissoes: false });
      });
    });
  }

  /**
   * Seleciona/Deseleciona as permissões disponíveis conforme a ação do Usuário.
   * 
   * @param {*} index 
   * @param {*} value 
   */
  onCheckedAcesso(index, value) {
    let permissoes = this.state.permissions;
    let permissao = permissoes[index];

    permissao.permiteAcesso = value != undefined && value != null ? value : !permissao.permiteAcesso;

    if (permissao.permiteAcesso) {
      let total = permissoes.filter((item) => item.moduloUuid == permissao.moduloUuid && item.permiteAcesso).length;

      permissoes.forEach(item => {
        if (item.uuid == permissao.moduloUuid && item.permiteAcesso && total === 0) {
          item.permiteAcesso = false;
        } else if (item.uuid == permissao.moduloUuid) {
          item.permiteAcesso = true;
        }
      });
    } else {
      permissoes.forEach(item => {
        if (item.moduloUuid == permissao.uuid) {
          item.permiteAcesso = false;
        }
      });
    }

    this.setState(update(this.state, {
      salvarButton: { $set: false },
      acessaTudo: { $set: permissoes.length === permissoes.filter(item => item.permiteAcesso).length },
      permissions: permissoes.map(item => ({
        permiteAcesso: { $set: item.permiteAcesso },
      }))
    }));

    if (!permissao.permiteAcesso) {
      setTimeout(() => {
        this.onCheckedConfiguracao(index, false);
      });
    }
  }

  /**
   * Seleciona/Deseleciona as permissões disponíveis conforme a ação do Usuário.
   * 
   * @param {*} index 
   * @param {*} value 
   */
  onCheckedConfiguracao(index, value) {
    let permissoes = this.state.permissions;
    let permissao = permissoes[index];

    permissao.permiteConfiguracao = value != undefined && value != null ? value : !permissao.permiteConfiguracao;

    if (permissao.permiteConfiguracao) {
      let total = permissoes.filter((item) => item.moduloUuid == permissao.moduloUuid && item.permiteConfiguracao).length;

      permissoes.forEach(item => {
        if (item.uuid == permissao.moduloUuid && item.permiteConfiguracao && total === 0) {
          item.permiteConfiguracao = false;
        } else if (item.uuid == permissao.moduloUuid) {
          item.permiteConfiguracao = true;
        }
      });
    } else {
      permissoes.forEach(item => {
        if (item.moduloUuid == permissao.uuid) {
          item.permiteConfiguracao = false;
        }
      });
    }

    this.setState(update(this.state, {
      salvarButton: { $set: false },
      configuraTudo: { $set: permissoes.filter(item => !item.somenteAcesso).length === permissoes.filter(item => item.permiteConfiguracao).length },
      permissions: permissoes.map(item => ({
        permiteConfiguracao: { $set: item.permiteConfiguracao },
      }))
    }));

    if (permissao.permiteConfiguracao) {
      setTimeout(() => {
        this.onCheckedAcesso(index, true);
      });
    }
  }

  /**
   * Toggle referente a opção de 'Acesso'.
   * 
   * @param {*} value 
   */
  onToggleAcessarTudo(value) {
    this.setState(update(this.state, {
      salvarButton: { $set: false },
      acessaTudo: { $set: value },
      permissions: this.state.permissions.map(() => ({
        permiteAcesso: { $set: value }
      }))
    }));

    if (!value) {
      setTimeout(() => {
        this.onToggleConfigurarTudo(value);
      });
    }
  }

  /**
   * Toggle referente a opção de 'Configuração'.
   * 
   * @param {*} value 
   */
  onToggleConfigurarTudo(value) {
    let permissoes = this.state.permissions.map(permissao => {
      let item = { ...permissao };
      item.permiteConfiguracao = item.somenteAcesso ? false : value;
      return item;
    });

    this.setState(update(this.state, {
      salvarButton: { $set: false },
      configuraTudo: { $set: value },
      permissions: permissoes.map(permissao => ({
        permiteConfiguracao: { $set: permissao.permiteConfiguracao }
      }))
    }));

    if (value) {
      setTimeout(() => {
        this.onToggleAcessarTudo(value);
      });
    }
  }

  render() {
    const { items, filter, permissions, loadingPermissoes } = this.state;

    let regex = new RegExp(filter, 'i');
    let filtrado = permissions.filter(l => regex.test(l.nome));

    if (filtrado.length <= 0) {
      filtrado = permissions;
    }

    const permissoes = filtrado.map((permissao, i) =>
      <tr key={permissao.uuid}>
        <td className='id'>{i + 1}</td>
        <td className='name'>{permissao.nome}</td>
        <td className='actions'>
          <Checkbox
            iconStyle={{ fill: '#2D4F7F' }}
            style={{ marginBottom: '0px' }}
            className='permissionsChild1'
            checked={permissao.permiteAcesso}
            onCheck={() => this.onCheckedAcesso(i)}
          />
        </td>
        <td className='actions'>
          <Checkbox
            iconStyle={{ fill: '#2D4F7F' }}
            style={{ marginBottom: '0px' }}
            className='permissionsChild2'
            checked={permissao.permiteConfiguracao}
            disabled={permissao.somenteAcesso}
            onCheck={() => this.onCheckedConfiguracao(i)}
          />
        </td>
      </tr>
    );

    return (
      <div>
        {this.state.loadingPage
          ?
          <Container sectionStyle={{ height: '94vh' }}>
            <div className={'loading-wrapper'}>
              <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
            </div>
          </Container>
          :
          <Container showSideBar modal={this.state.modal} styleModal={{ width: 350 }} contentModal={this.contentModal}>
            <div className='box_list'>
              <Card className='box_list_card'>
                <FloatingActionButton disabled={!hasRole(['ROLE_GRUPO_CRIAR', 'ROLE_GRUPO_USUARIO_CRIAR'])} className='box_add' onClick={() => this.handleModal(event, 'cadastrarGrupoUsuario')}>
                  <ContentAdd />
                </FloatingActionButton>
                <CardHeader className='box_header' title={this.props.t("title")} />
                <CardText className='table_overflow' style={{ overflowX: 'hidden' }}>
                  <div style={{ width: '100%', marginLeft: '-24px', height: '85px', boxShadow: '0px 9px 15px -13px rgba(0,0,0,0.75)' }}>
                    <div className={'flex flex-just-between flew-dir-row'} style={{ width: '450px', marginTop: '-25px', marginLeft: '25px' }}>
                      <SelectField
                        value={this.state.value}
                        underlineFocusStyle={{ borderColor: '#000000' }}
                        style={{ color: '#5B5B5B', width: '200px', fontSize: '1em' }}
                        onChange={this.handleChange}
                        floatingLabelText={this.props.t("form.labels.name")} id='selectGroupUsers'>
                        {items}
                      </SelectField>
                      {
                        this.state.value !== null && this.state.value !== '' ?
                          <div style={{ position: 'relative' }} id='optionsBtn'>
                            <div style={{ paddingTop: '40px', marginLeft: '-60px', position: 'absolute', zIndex: '9999999' }} id='optionsBtn'>
                              <OptionsBtn handleModal={this.handleModal} />
                            </div>
                          </div>
                          :
                          null
                      }
                      <div style={{ width: '200px', marginTop: '30px' }}>
                        <RaisedButton
                          onClick={this.salvarPermissoes}
                          backgroundColor='#2D4F7F'
                          style={{ fontWeigth: 100, marginRight: '1em' }}
                          labelColor='#FFF'
                          disabled={this.state.salvarButton || !hasRole(['ROLE_GRUPO_ATUALIZAR', 'ROLE_GRUPO_USUARIO_ATUALIZAR'])}
                          label={this.props.t("labels.save", { ns: "common" })} />
                      </div>
                    </div>
                  </div>
                  <table id='list_users' className='mui-table mui-table--bordered'>
                    <thead>
                      <tr>
                        <td className='id'>#</td>
                        <td className='name'>{this.props.t("list.columns.permissions")}</td>
                        <td className='blank'>
                          {
                            this.state.permissions.length > 0 && loadingPermissoes === false
                              ?
                              <Checkbox
                                label={this.props.t("list.columns.access")}
                                iconStyle={{ fill: '#2D4F7F' }}
                                labelStyle={{ color: '#5B5B5B' }}
                                style={{ marginBottom: '10px' }}
                                className='permissions'
                                checked={this.state.acessaTudo}
                                onCheck={() => { this.onToggleAcessarTudo(!this.state.acessaTudo); }}
                              />
                              :
                              null
                          }
                        </td>
                        <td className='blank'>
                          {
                            this.state.permissions.length > 0 && loadingPermissoes === false
                              ?
                              <Checkbox
                                label={this.props.t("list.columns.configure")}
                                iconStyle={{ fill: '#2D4F7F' }}
                                labelStyle={{ color: '#5B5B5B' }}
                                style={{ marginBottom: '10px' }}
                                className='permissions'
                                checked={this.state.configuraTudo}
                                onCheck={() => { this.onToggleConfigurarTudo(!this.state.configuraTudo); }}
                              />
                              :
                              null
                          }
                        </td>
                      </tr>
                    </thead>
                    <tbody className='box_items'>
                      {
                        loadingPermissoes ?
                          <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>
                              <MDSpinner singleColor='#2D4F7F' size={40} borderSize={5} />
                            </td>
                          </tr>
                          :
                          permissoes.length > 0
                            ?
                            permissoes
                            :
                            <tr>
                              <td colSpan={4} style={{ textAlign: 'center' }}>{this.props.t("labels.selectGroup")}</td>
                            </tr>
                      }
                    </tbody>
                  </table>
                </CardText>
              </Card>
            </div>
          </Container>
        }
      </div>
    );
  }
}

@observer
class RegGroupUsers extends Component {
  constructor(props) {
    super(props);

    const grupo = props.grupo || {};

    this.state = {
      nome: grupo.nome,
    };
  }

  onNomeChange(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  render() {
    const { nome } = this.state;

    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("title")}</NFormTitle>
          <div style={{ height: '5em', display: 'flex', paddingTop: '-.5em', flexFlow: 'column', justifyContent: 'space-between' }}>
            <TextField
              style={{ marginTop: '-16px', width: '300px' }}
              underlineFocusStyle={{ borderColor: '#000000' }}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("name")} title={this.props.t("name")} id='nome'
              onChange={this.onNomeChange.bind(this)}
              value={nome} required={true}
            />
          </div>
          <br />
          <NButtonGroup>
            <NButton type="primary" onClick={() => this.props.onSave(this.state.nome)}>{this.props.t("labels.save", { ns: "common" })}</NButton>
            <NButton onClick={this.props.closeModal}>{this.props.t("labels.cancel", { ns: "common" })}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }
}

@observer
class DeleteGroupUsers extends Component {
  constructor(props) {
    super(props);

    const grupo = props.grupo || {};

    this.state = {
      nome: grupo.nome,
    };
  }

  render() {
    const { nome } = this.state;
    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("messages.wantToDelete", { name: nome })}</NFormTitle>
          <NButtonGroup>
            <NButton type="primary" onClick={this.props.onDelete}>{this.props.t("list.actions.delete", { ns: "common" })}</NButton>
            <NButton onClick={this.props.closeModal}>{this.props.t("labels.cancel", { ns: 'common' })}</NButton>
          </NButtonGroup>
        </NForm>
      </div>
    );
  }
}

class OptionsBtn extends Component {
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
        {hasRole(['ROLE_GRUPO_ATUALIZAR', 'ROLE_GRUPO_USUARIO_ATUALIZAR']) &&
          <i className='fa fa-pencil' style={{ cursor: 'pointer', color: '#2D4F7F' }} onClick={() => this.props.handleModal(event, 'editarGrupoUsuario')} />
        }

        {hasRole(['ROLE_GRUPO_EXCLUIR', 'ROLE_GRUPO_USUARIO_EXCLUIR']) &&
          <i className='fa fa-times-circle' style={{ cursor: 'pointer', color: '#2D4F7F', marginLeft: '10px' }} onClick={() => this.props.handleModal(event, 'deletarGrupoUsuario')} />
        }
      </div>
    );
  }
}

export default withTranslation('groupUser')(GroupUsersPage);