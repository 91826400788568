import * as _ from 'lodash';
import AnnotationPlugin from 'chartjs-plugin-annotation/chartjs-plugin-annotation';

/**
 * 
 *  Options:
 *  dangerArea: {
 *     backgroundColor: 'rgba(251, 85, 85, 0.4)',
 *     dangerCallback: function(data){
 *       return data < 2; 
 *     },
 *   }
 */
export const DangerAreaPlugin = {
    beforeDraw: function (chart, easing) {
        if (chart.config.options.dangerArea && chart.config.options.dangerArea.backgroundColor && chart.config.options.dangerArea.dangerCallback) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            if (chart.config.data.datasets.length > 0) {
                var meta = chart.config.data.datasets[0]._meta[_.keys(chart.config.data.datasets[0]._meta)[0]];
                var data = chart.config.data.datasets[0].data;
                var callback = chart.config.options.dangerArea.dangerCallback;
                for (var i = 0; i < meta.data.length; i++) {
                    if (callback(data[i], i)) {
                        ctx.save();
                        var model = meta.data[i]._model;
                        ctx.fillStyle = chart.config.options.dangerArea.backgroundColor;
                        ctx.fillRect(model.x - model.width / 2, chartArea.top, model.width, chartArea.bottom - chartArea.top);
                        ctx.restore();
                    }
                }
            }
        }
    }
};

// customArea: {
//     backgroundColor: 'rgba(251, 85, 85, 0.4)',
//     barCount: 4
// }
export const CustomAreaPlugin = {
    beforeDraw: function (chart, easing) {
        if (chart.config.options.customArea && chart.config.options.customArea.backgroundColor && chart.config.options.customArea.barCount) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            if (chart.config.data.datasets.length > 0) {
                var meta = chart.config.data.datasets[0]._meta[_.keys(chart.config.data.datasets[0]._meta)[0]];
                var data = chart.config.data.datasets[0].data;
                var i = (meta.data.length - chart.config.options.customArea.barCount);
                ctx.save();
                var model = meta.data[i]._model;
                ctx.fillStyle = chart.config.options.customArea.backgroundColor;
                var ajust = model.width / 2;
                var width = model.width;
                var x = model.x;
                ctx.fillRect(x - ajust, chartArea.top, ((model.width + ajust) * chart.config.options.customArea.barCount) - (ajust + 2), chartArea.bottom - chartArea.top);
                ctx.restore();
            }
        }
    }
};

// customArea: {
//     backgroundColor: 'rgba(251, 85, 85, 0.4)',
//     barCount: 4
// }
export const CustomBgPlugin = {
    beforeDraw: function (chart, easing) {
        if (chart.config.options.dangerArea && chart.config.options.dangerArea.backgroundColor && chart.config.options.dangerArea.dangerCallback) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            if (chart.config.data.datasets.length > 0) {
                var meta = chart.config.data.datasets[0]._meta[_.keys(chart.config.data.datasets[0]._meta)[0]];
                var data = chart.config.data.datasets[0].data;
                var callback = chart.config.options.dangerArea.dangerCallback;
                for (var i = 0; i < meta.data.length; i++) {
                    if (i % 2 == 0) {
                        ctx.save();
                        var model = meta.data[i]._model;
                        ctx.fillStyle = 'rgba(243, 243, 243, 1)';
                        ctx.fillRect(model.x - model.width / 2, chartArea.top, model.width, chartArea.bottom - chartArea.top);
                        ctx.restore();
                    }
                }
            }
        }
    }
};

// Draw label inside the bars
// barLabel:{
//    backgroundColor: 'rgba(255, 255, 255, 1)',
//    display: false
// }
export const BarLabelPlugin = {
    afterDraw: function (chart, easing) {
        if (chart.config.options.barLabel && chart.config.options.barLabel.display) {
            var ctx = chart.ctx;
            ctx.save();
            ctx.textBaseline = 'bottom';
            chart.data.datasets.forEach(function (dataset) {
                if (dataset._meta[Object.keys(dataset._meta)[0]] && dataset._meta[Object.keys(dataset._meta)[0]].data && dataset._meta[Object.keys(dataset._meta)[0]].data.length > 0 && dataset._meta[Object.keys(dataset._meta)[0]].data[0]._datasetIndex !== 0) return;
                for (var i = 0; i < dataset.data.length; i++) {
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]],
                        data = meta.data[i],
                        model = data._model;

                    var fontSize = data._view.width / 10;
                    if (fontSize > 10) {
                        ctx.fillStyle = chart.config.options.backgroundColor ? chart.config.options.backgroundColor : 'black';
                        ctx.font = 'bold ' + (fontSize | 0) + 'pt Verdana';
                        ctx.textAlign = 'center';
                        var label = model.label;
                        ctx.fillText(label, model.x, model.y);//(meta.controller.chart.height / 2) - 12
                    }
                }
            });
            ctx.restore();
        }
    }
};

// Desenha os valores do eixo x no histograma
// barLabel:{
//    backgroundColor: 'rgba(255, 255, 255, 1)',
//    display: false
// }
export const HistogramaTicksPlugin = {
    afterDraw: function (chart, easing) {
        if (chart.config.options.histogramaTicks && chart.config.options.histogramaTicks.callback) {
            var ctx = chart.ctx;
            ctx.save();
            ctx.textBaseline = 'bottom';
            chart.data.datasets.forEach(function (dataset) {
                if (dataset._meta[Object.keys(dataset._meta)[0]] && dataset._meta[Object.keys(dataset._meta)[0]].data && dataset._meta[Object.keys(dataset._meta)[0]].data.length > 0 && dataset._meta[Object.keys(dataset._meta)[0]].data[0]._datasetIndex !== 0) return;
                for (var i = 0; i < dataset.data.length; i++) {
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]],
                        data = meta.data[i],
                        model = data._model;
                    ctx.fillStyle = '#848484';
                    ctx.font = '9pt Verdana';
                    ctx.textAlign = 'center';
                    var label = chart.config.options.histogramaTicks.callback(i);
                    ctx.fillText(label, model.x + 2, chart.chartArea.bottom + 17);//(meta.controller.chart.height / 2) - 12
                }
            });
            ctx.restore();
        }
    }
};

// customLegend:{
//     display: true,
//     labelTop: 'Espera',
//     labelBottom: 'Processo',
//     fontColor: 'rgba(245, 126, 23, 1)'
// }
export const CustomLegendPlugin = {
    beforeDatasetsDraw: function (chart, easing) {
        if (chart.config.options.customLegend && chart.config.options.customLegend.display) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            var yAxis = chart.scales[Object.keys(chart.scales)[1]];
            var x = 49;
            var y = yAxis.getPixelForTick(yAxis.zeroLineIndex);//((yAxis.bottom - yAxis.top) / 2) + yAxis.paddingTop;
            var xPadding = 40, yPadding = 10;
            ctx.save();
            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.moveTo(x, y);
            ctx.lineTo(chart.chartArea.right, y);
            ctx.stroke();
            ctx.restore();
        }
    },
    afterDraw: function (chart, easing) {
        if (chart.config.options.customLegend && chart.config.options.customLegend.display) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            var yAxis = chart.scales[Object.keys(chart.scales)[1]];
            var x = 30;
            var y = yAxis.getPixelForTick(yAxis.zeroLineIndex);
            var xPadding = 100,
                yPaddingTop = 15,
                yPaddingBottom = 10,
                width = 20,
                height = 10,
                tPaddingX = 10,
                tPaddingY = 10;

            var labelTop = chart.config.options.customLegend.labelTop ? chart.config.options.customLegend.labelTop : 'Label';
            var labelBottom = chart.config.options.customLegend.labelBottom ? chart.config.options.customLegend.labelBottom : 'Label';
            ctx.fillStyle = chart.config.options.customLegend.fontColor ? chart.config.options.customLegend.fontColor : 'rgba(245, 126, 23, 1)';
            ctx.font = '11pt Verdana';
            ctx.textAlign = 'end';
            ctx.textBaseline = 'middle';
            ctx.fillText(labelTop, x + xPadding, y - yPaddingTop);
            ctx.fillText(labelBottom, x + xPadding, y + yPaddingBottom);
            /**
             * Triangulo para cima
             */
            ctx.beginPath();
            ctx.moveTo(x + xPadding + tPaddingX, y - tPaddingY);
            ctx.lineTo(x + xPadding + tPaddingX + width, y - tPaddingY);
            ctx.lineTo(x + xPadding + tPaddingX + width / 2, y - tPaddingY - height);
            ctx.fill();
            /**
             * Triangulo para baixo
             */
            ctx.beginPath();
            ctx.moveTo(x + xPadding + tPaddingX, y + tPaddingY, 1, 1);
            ctx.lineTo(x + xPadding + tPaddingX + width, y + tPaddingY, 1, 1);
            ctx.lineTo(x + xPadding + tPaddingX + width / 2, y + tPaddingY + height, 1, 1);
            ctx.fill();
        }
    }
};

//
//Função responsável por deixar os retangulos com bordas redondas
//
export const CustomRectangleDraw = function () {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var left, right, top, bottom, signX, signY, borderSkipped, radius;
    var borderWidth = vm.borderWidth;

    if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
    } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
        }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom]
    ];

    // Find first (starting) corner with fallback to 'bottom'
    var borders = ['bottom', 'left', 'top', 'right'];
    var startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
        startCorner = 0;
    }

    function cornerAt(index) {
        return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    var corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);
    var nextCornerId, width, height, x, y;
    for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        nextCornerId = i + 1;
        if (nextCornerId === 4) {
            nextCornerId = 0
        }

        cornerAt(nextCornerId);

        width = corners[2][0] - corners[1][0];
        height = corners[0][1] - corners[1][1];
        x = corners[1][0];
        y = corners[1][1];

        radius = this._chart.options.halfCornerRadius ? 20 : 30;

        if (this._chart.options.halfCornerRadius) {
            if (radius > width) {
                radius = width;
            }
            ctx.moveTo(x, y);
            ctx.lineTo(x + width, y);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y);
        } else if (!(this._chart.options.barRounded)) {
            ctx.moveTo(x, y);
            ctx.lineTo(x + width, y);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y);
        } else if (this._datasetIndex === 0) {
            if (radius > height) {
                radius = height;
            }
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        } else if (this._datasetIndex === 1) {
            if (radius > -height) {
                radius = -height;
            }
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y - radius);
            ctx.lineTo(x + width, y + height);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x, y - radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        }
    }
    ctx.fill();
    if (borderWidth) {
        ctx.stroke();
    }
};

//
//Função responsável por ajustar o tamanho do segundo arco no OEE
//
export const CustomArcDraw = function () {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var sA = vm.startAngle;
    var eA = vm.endAngle;
    var dataset = this._chart.data.datasets[this._datasetIndex];
    var padding = dataset.padding || 0;

    ctx.beginPath();

    ctx.arc(vm.x, vm.y, vm.outerRadius - padding, sA, eA);
    ctx.arc(vm.x, vm.y, vm.innerRadius + padding, eA, sA, true);

    ctx.closePath();

    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = vm.borderWidth;

    ctx.fillStyle = vm.backgroundColor;

    ctx.fill();
    ctx.lineJoin = 'bevel';

    if (vm.borderWidth) {
        ctx.stroke();
    }
};


/**
 * Initialize All Plugins
 * @param {*Cart} Chart 
 */
export default function (Chart) {
    Chart.pluginService.register(CustomBgPlugin);
    Chart.pluginService.register(DangerAreaPlugin);
    Chart.pluginService.register(CustomAreaPlugin);
    Chart.pluginService.register(BarLabelPlugin);
    Chart.pluginService.register(HistogramaTicksPlugin);
    Chart.pluginService.register(CustomLegendPlugin);

    Chart.elements.Rectangle.prototype.draw = CustomRectangleDraw;
    Chart.elements.Arc.prototype.draw = CustomArcDraw;    
};