import React, { Component } from 'react';
import TimeConversion from '../utils/TimeConversion';
import MaskFieldTime from '../utils/MaskFieldTime';
import i18n from 'utils/i18n';

const { THoursMinutes } = TimeConversion;
const { Mask, MaskInterval } = MaskFieldTime;

export default class InputTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isValid: false
    }
    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);
    this._isValid = this._isValid.bind(this);
  }

  componentWillMount() {
    if (this.props.value !== undefined && this.props.value !== null) {
      this.setState({
        value: this.props.interval ? this.props.value : THoursMinutes(this.props.value)
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== undefined && nextProps.value !== null) {
      this.setState({
        value: nextProps.interval ? nextProps.value : THoursMinutes(nextProps.value)
      });
    }
  }

  _onBlur(event) {
    let v = event.target.value.split(":");
    if (v[1] > 59) {
      event.target.value = "";
      this.setState({
        value: ''
      });
    }
  }

  _onChange(event) {
    this.setState({
      value: this.props.interval ? MaskInterval(event.target.value) : Mask(event.target.value)
    });
  }

  _isValid() {
    this.setState({
      isValid: this.state.value.length > 0
    });
  }

  render() {
    let { id, label, readOnly, styles, tabIndex, onFinished, placeholder, disabled, required, onChange, onBlur, mapKey, maxLength, spanStyle, keyElement, labelStyle } = this.props;
    let { value } = this.state;

    let keySpan = keyElement || 0;

    return (
      <span
        key={`span-time-key-${keySpan}`}
        style={spanStyle}
      >
        <input tabIndex={tabIndex} ref={id}
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange ? (event) => onChange(event, this.refs) : this._onChange}
          onBlur={onBlur ? (event) => onBlur(event, this.refs) : () => { }}
          required={required}
          className={!required || value.length > 0 || value > 0 ? styles.balloon : styles.balloon + ' ' + styles.required}
          id={id} type='text'
          value={this.props.value ? this.props.value : readOnly ? ' ' : value}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={mapKey ? (event) => mapKey(event, this.props) : () => { }}
          autoComplete='off'
          maxLength={maxLength}
          min={this.props.min}
          max={this.props.max} />
        {
          label
            ? <label htmlFor={id} style={labelStyle || {}} >{label}</label>
            : null
        }
        <div ref="validate" className={!required || value.length > 0 || value > 0 ? 'validation transparent' : 'validation'}>{i18n.t('messages.requiredField', { ns: 'common' })}</div>
      </span>
    );
  }
}
