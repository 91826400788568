import React, { Component } from 'react';
import Button from '../../common/Button';
import lbStyles from './ListBatches.scss';
import FIFOIcon from '../../assets/FIFO.svg';
import $ from 'jquery';
import moment from 'moment';
import { alertBox } from '../../common/NForm';
import { Card } from 'material-ui/Card';
import { NButton } from '../../common/NForm';
import ListBatchesMobile from './ListBatchesMobile';
import utils from '../../utils/Utils';
import { request } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import { uniqBy, reduce, findIndex } from 'lodash';
import { withTranslation } from 'react-i18next';
import { APIEndpoints } from 'common/Constants';
import MDSpinner from 'react-md-spinner';
import fileDownload from 'react-file-download';

class ListBatchesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listaLotesEspera: [],
            lotesAlterados: [],
            movimentacoes: [],
            oadingAnaliseSequenciamentoOtimizado: false,
            mensagens: [],
            listaMelhorOrdem: {
                queue: []
            },
        }

        this.equipamentId = props.equipamento.id;

        this.equipamentId = props.listWaiting[0]['fk_id_equipments'];

        this.subirPosicao = this.subirPosicao.bind(this);
        this.descerPosicao = this.descerPosicao.bind(this);
        this.adicionarMovimentacao = this.adicionarMovimentacao.bind(this);
        this.removerEAdicionarMovimentacao = this.removerEAdicionarMovimentacao.bind(this);
        this.salvarMovimentacoes = this.salvarMovimentacoes.bind(this);
        this.fazerAnaliseSequenciamentoOtimizado = this.fazerAnaliseSequenciamentoOtimizado.bind(this);
        this.baixarAnaliseSequenciamentoOtimizado = this.baixarAnaliseSequenciamentoOtimizado.bind(this);
        this.calcularTempo = this.calcularTempo.bind(this);
        this.pad = this.pad.bind(this);
    }

    //Metodo utilizado para ordenar a lista de lotes de acordo com o tempo de referencia
    ordenarLotes(lotes, firstOrder) {
        if(firstOrder) {
            return this.ordenarLotes(this.ordenarLotes(lotes).map((l, i) => {
                var fimPosterior = new Date(l.reference_time);
                fimPosterior.setMilliseconds(0);
                // console.log(fimPosterior)
                return {
                    ...l,
                    reference_time: moment(new Date(fimPosterior.getTime() + (i))).format('YYYY-MM-DDTHH:mm:ss.SSS')
                };
            }));
        }
        return lotes.sort((a, b) => {
            if (moment(a.reference_time).isBefore(moment(b.reference_time))) {
                return -1;
            }
            else if (moment(a.reference_time).isAfter(moment(b.reference_time))) {
                return 1;
            }
            else {
                return 0;
            }
        });
    }

    componentDidMount() {
        let { listWaiting, listWarning, codProd } = this.props;

        let listaLotes = listWarning.filter(b => {
            if (codProd != undefined && b.fk_id_events !== codProd.id && b.master) {
                return b;
            }
        }).map(b => ({
            ...b,
            type: 'warning'
        })).concat(
            listWaiting.filter(b => {
                if (codProd != undefined && b.fk_id_events !== codProd.id && b.master) {
                    return b;
                }
            }).map(b => ({
                ...b,
                type: 'waiting'
            }))
        );
        listaLotes = uniqBy(listaLotes, 'num');

        this.setState({
            listaLotesEspera: this.ordenarLotes(listaLotes, true)
        });


        var container = $('.bodyTable > div > div:nth-child(2)');
        container.animate({
            scrollTop: container.children().eq(0).height()
        }, 0);


        var widthTable = container.children().eq(0).width();
        $('.bodyTable > div').css('background', '#fff');
        var headerTable = $('.bodyTable > div > div:nth-child(1)');
        headerTable.css('width', widthTable);
        headerTable.css('background', '#fff');
    }

    /*
        --TipoMovimento--
        1 - Mover para cima;
        2 - Mover para baixo;
        3 - Mover para entre equipamentos;
        4 - Mover de equipamento para lote;
        5 - Mover de etapa para equipamento;
    */
    criarObjetoMovimentacao(loteByIndex, fimReferencia, tipoMovimento) {
        var objetoMovimentacao = {
            excluir: false,
            lote_id: loteByIndex.fk_id_batches,
            etapa_original_id: loteByIndex.fk_id_stages,
            etapa_referencia_id: loteByIndex.fk_id_stages,
            data_fim_original: loteByIndex.end_time,
            data_fim_referencia: moment(fimReferencia).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            tipo_movimentacao: tipoMovimento
        };

        if (loteByIndex.fk_id_equipments != 0) {
            objetoMovimentacao.equipamento_original_id = loteByIndex.fk_id_equipments;
            objetoMovimentacao.equipamento_referencia_id = loteByIndex.fk_id_equipments;
        }

        return objetoMovimentacao;

    }

    buscarIndexMovimentacoesExistentes(movimentacao) {
        return findIndex(
            this.state.movimentacoes,
            (move) => {
                return move.lote_id == movimentacao.lote_id
            }
        );
    }

    atribuirMovimentoListaLotes(posicaoMovimento, movimentacao) {
        // Utilizo spreadoperator para gerar uma referencia ao array de lista de ltoes
        var listaLotes = [...this.state.listaLotesEspera];
        // Busco o lote movimentado
        var loteMovimentado = listaLotes[posicaoMovimento];
        // Altero o horario de referencia
        loteMovimentado.reference_time = movimentacao.data_fim_referencia;
        // Atribuo o valor ao array clonado da state
        listaLotes[posicaoMovimento] = loteMovimentado;

        return listaLotes;
    }

    adicionarMovimentacao(posicaoMovimento, movimentacao) {
        var listaLotesMovimentada = this.atribuirMovimentoListaLotes(posicaoMovimento, movimentacao);

        this.setState(state => {
            const movimentacoes = state.movimentacoes.concat(movimentacao);
            return {
                movimentacoes: movimentacoes,
                listaLotesEspera: this.ordenarLotes(listaLotesMovimentada)
            };
        });
    }

    //Garanto que só existirá um movimento por lote de acordo com a lista
    removerEAdicionarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao) {
        var listaLotesMovimentada = this.atribuirMovimentoListaLotes(posicaoMovimento, movimentacao);

        this.setState(state => {
            //Remove a movimentação existente
            const listaSemMovimentacao = state.movimentacoes.filter((item, index) => index !== indexMovimentacao);
            //Adiciona nova movimentaçao
            const movimentacoes = listaSemMovimentacao.concat(movimentacao);

            return {
                movimentacoes: movimentacoes,
                listaLotesEspera: this.ordenarLotes(listaLotesMovimentada)
            };
        })
    }

    verificarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao) {
        // -1 = a movimentaçao inexistente no array
        if (indexMovimentacao == -1) {
            this.adicionarMovimentacao(posicaoMovimento, movimentacao);
        } else {
            this.removerEAdicionarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao);
        }
    }

    subirPosicao(posicaoMovimento) {
        var listaLotes = [...this.state.listaLotesEspera];
        var loteByIndex = JSON.parse(JSON.stringify({...listaLotes[posicaoMovimento]}));
        var lotePosterior = JSON.parse(JSON.stringify({...listaLotes[posicaoMovimento + 1]}));

        if (lotePosterior != undefined) {
            var fimPosterior = new Date(lotePosterior.reference_time);
            var fimIndex = new Date(loteByIndex.reference_time);
            const referenceTimePosterior = lotePosterior.reference_time;
            const referenteTimeAtual = loteByIndex.reference_time;

            loteByIndex.reference_time = referenceTimePosterior;
            lotePosterior.reference_time = referenteTimeAtual;

            var movimentacao1 = this.criarObjetoMovimentacao(loteByIndex, fimPosterior, 1);
            var movimentacao2 = this.criarObjetoMovimentacao(lotePosterior, fimIndex, 2);

            // console.log(listaLotes, 'antes');
            // console.log(referenceTimePosterior, 'Posterior');
            // console.log(referenteTimeAtual, 'Atual');

            listaLotes[posicaoMovimento + 1].reference_time = `${referenteTimeAtual}`;
            listaLotes[posicaoMovimento].reference_time = `${referenceTimePosterior}`;
            
            // console.log(listaLotes, 'depois');

            this.setState(state => {

                const listaSemMovimentacao = state.movimentacoes.filter((item, index) => item.lote_id !== movimentacao1.lote_id && item.lote_id !== movimentacao2.lote_id);

                const movimentacoes = listaSemMovimentacao.concat(movimentacao1, movimentacao2);
                // console.log(movimentacoes, 'movimentacoes');
                this.state.lotesAlterados.push(loteByIndex.num);
                return {
                    movimentacoes,
                    listaLotesEspera: this.ordenarLotes(listaLotes)
                }
            })

            var mensagemLoteAlterado = this.props.t("messages.moveQueueSuccess", {
                primaryReference: utils.getReferenciaDoLoteDaOrdem(),
                batchNumber: loteByIndex.num,
                changedText: utils.getReferenciaAlteradoAlterada()
            });

            if (!this.state.mensagens.includes(mensagemLoteAlterado)) {
                this.state.mensagens.push(mensagemLoteAlterado);
            }
        }
    }

    descerPosicao(posicaoMovimento) {
        var listaLotes = this.state.listaLotesEspera;
        var loteByIndex = listaLotes[posicaoMovimento];
        var loteAnterior = listaLotes[posicaoMovimento - 1];
        

        if (loteAnterior != undefined) {
            
                var fimAnterior = new Date(loteAnterior.reference_time);
                var fimIndex = new Date(loteByIndex.reference_time);
                const referenceTimeAnterior = loteAnterior.reference_time;
                const referenteTimeAtual = loteByIndex.reference_time;
    
                loteByIndex.reference_time = referenceTimeAnterior;
                loteAnterior.reference_time = referenteTimeAtual;
    
                var movimentacao1 = this.criarObjetoMovimentacao(loteByIndex, fimAnterior, 2);
                var movimentacao2 = this.criarObjetoMovimentacao(loteAnterior, fimIndex, 1);
    
                // console.log(listaLotes, 'antes');
                // console.log(referenceTimeAnterior, 'Posterior');
                // console.log(referenteTimeAtual, 'Atual');
    
                listaLotes[posicaoMovimento - 1].reference_time = `${referenteTimeAtual}`;
                listaLotes[posicaoMovimento].reference_time = `${referenceTimeAnterior}`;
                
                // console.log(listaLotes, 'depois');

                this.setState(state => {

                    const listaSemMovimentacao = state.movimentacoes.filter((item, index) => item.lote_id !== movimentacao1.lote_id && item.lote_id !== movimentacao2.lote_id);
    
                    const movimentacoes = listaSemMovimentacao.concat(movimentacao1, movimentacao2);
                    // console.log(movimentacoes, 'movimentacoes');
                    this.state.lotesAlterados.push(loteByIndex.num);
                    return {
                        movimentacoes,
                        listaLotesEspera: this.ordenarLotes(listaLotes)
                    };
                });
    

            var mensagemLoteAlterado = this.props.t("messages.moveQueueSuccess", {
                primaryReference: utils.getReferenciaDoLoteDaOrdem(),
                batchNumber: loteByIndex.num,
                changedText: utils.getReferenciaAlteradoAlterada()
            });

            if (!this.state.mensagens.includes(mensagemLoteAlterado)) {
                this.state.mensagens.push(mensagemLoteAlterado);
            }
        
    }
    }
    salvarMovimentacoes() {
        if (this.state.listaMelhorOrdem.queue?.length !== 0) {

            if (window.confirm(this.props.t('labels.messageChangeSequenceOptimization'))) {
                request(APIEndpoints.SUGGESTION_SEQUENCE, {
                    method: 'post',
                    body: JSON.stringify(this.state.listaMelhorOrdem),
                }).then(response => {
                    if (response.ok) {
                        this.props.handleSession();
                        alertBox(this.props.t('labels.listUpdateSuccess'), '', 'success');
                    } else {
                        this.props.handleModal();
                    }

                });
            }

            return;
        }
        request(this.props.endpoints.MOVIMENTACAO_LOTE, {
            method: 'post',
            body: JSON.stringify(this.state.movimentacoes),
        })
            .then(response => {
                if (response.ok) {
                    this.props.handleSession();
                } else {
                    this.props.handleModal();
                }
            });

        var mensagem = reduce(this.state.mensagens, function (mensagem, msg) {
            return "\n" + mensagem + "\n" + msg;
        });

        alertBox(`${mensagem}`, '', 'success');
    }


    fazerAnaliseSequenciamentoOtimizado() {
        const id = this.equipamentId;
        const query = `equipmentId=${id}`;
        this.setState(() => {
            return {
                loadingAnaliseSequenciamentoOtimizado: true
            };
        });
        let listaLotes = this.state.listaLotes;
        fetch(`${APIEndpoints.SUGGESTION_SEQUENCE}?${query}`, {
            method: 'get',
            headers: {
                'Access-Token': localStorage['token'],
                'Authorization': 'Bearer ' + localStorage['tokenApp'],
                'Content-type': 'application/json'
            },
        }).then((response) => {
            response.json().then(data => {

                this.setState({
                    listaMelhorOrdem: data
                });
            });
        }).finally(() => {
            this.setState(() => {
                return {
                    loadingAnaliseSequenciamentoOtimizado: false
                };
            });

        });

    }

    baixarAnaliseSequenciamentoOtimizado({ equipment }) {
        if (this.state.loadingAnaliseSequenciamentoOtimizado) return false;

        this.setState(() => {
            return {
                loadingAnaliseSequenciamentoOtimizado: true
            };
        });

        fetch(`${APIEndpoints.SUGGESTION_SEQUENCE}/${equipment.id}/file`, {
            method: 'get',
            headers: {
                'Access-Token': localStorage['token'],
                'Authorization': 'Bearer ' + localStorage['tokenApp'],
                'Content-type': 'application/json'
            },
        }).then((response) => {
            response.text().then((data) => {

                fileDownload('\ufeff' + data, `Sequenciamento_otimizado_${moment().format('YYYY-MM-DD_HH-MM-ss')}.csv`);


            });

        }).finally(() => {
            this.setState(() => {
                return {
                    loadingAnaliseSequenciamentoOtimizado: false
                };
            });
        });
    }

    pad(numero) {
        return (numero < 10 ? '0' : '') + numero;
    }


    calcularTempo(segundos) {
        var horas = Math.floor(segundos / 3600);
        var minutos = Math.floor((segundos % 3600) / 60);
        var segundosRestantes = segundos % 60;

        return this.pad(horas) + ":" + this.pad(minutos) + ":" + this.pad(segundosRestantes);
    }


    render() {
        let { etapa, equipamento, listProcess, listWarning, codProd, referenciaSecundaria } = this.props;

        var listProcessOrder = listWarning.filter(b => {
            if (codProd != undefined && b.fk_id_events == codProd.id) {
                return b;
            }
        }).map(b => ({
            ...b,
            type: 'warning'
        })).concat(
            listProcess.map(b => ({
                ...b,
                type: 'process'
            }))
        ).sort((a, b) => {
            if (moment(a.end_time).isBefore(moment(b.end_time))) return -1;
            else if (moment(a.end_time).isAfter(moment(b.end_time))) return 1;
            else return 0;
        });

        let listaLotesEspera = _.uniqBy(this.state.listaLotesEspera, 'num');

        let getReferenciaSecundaria = utils.getReferenciaOrdemLote() != '' ? <td>{utils.getReferenciaOrdemLote()}</td> : null;

        const sugestaoDeSequenciaOtimizada = this.state.listaMelhorOrdem.queue?.length !== 0;

        let user = localStorage.getItem('login');
        let userCgtv = user && user.startsWith('cgtv') ? true : false;

        return (

            <div style={{ marginTop: 10 }} >
                {this.state.loadingAnaliseSequenciamentoOtimizado && <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    zIndex: '10',
                }}>
                    <div className={'loading-wrapper'}><MDSpinner singleColor='#2D4F7F' size={60} /></div></div>}

                {
                    hasRole(['ROLE_SUGESTAO_SEQUENCIAMENTO_VISUALIZAR', 'ROLE_SUGESTAO_SEQUENCIAMENTO_CRIAR'])
                        ?
                        <div style={{
                            display: 'flex',
                        }}>
                            {
                                this.state.listaMelhorOrdem.queue?.length !== 0 && userCgtv && <NButton
                                    type="button"
                                    onClick={() => this.baixarAnaliseSequenciamentoOtimizado(this.state.listaMelhorOrdem)}
                                    style={{ fontWeigth: 100, backgroundColor: '#FFF', color: '#5B5B5B', display: 'flex', marginRight: 'auto', alignItems: 'center', gap: '0.4rem', textTransform: "capitalize" }}>
                                    <img src='../../../assets/download-button.svg' alt='download' style={{
                                        width: '24px', height: '24px'
                                    }} />
                                    {this.props.t('labels.download')}
                                </NButton>
                            }
                            <NButton
                                type="button"
                                onClick={this.fazerAnaliseSequenciamentoOtimizado}
                                style={{ fontWeigth: 100, backgroundColor: '#2D4F7F', color: '#FFF', display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                                {this.props.t('labels.analysisSequence')}
                            </NButton>
                        </div>
                        :
                        null
                }

                <div >
                    <div style={{ marginTop: '1em' }} className='table_reponsive'>
                        <table style={{ fontSize: '16px' }} className='mui-table mui-table--bordered'>
                            <thead style={{ borderBottom: '1px solid #D6D6D6' }}>
                                <tr className={lbStyles.title}>
                                    <td> {this.props.t("labels.stage")} </td>
                                    <td> {this.props.t("labels.equipment")} </td>
                                    <td> {this.props.t("labels.FIFO")} </td>
                                    {/**
                                     * 
                                     * Informaçao oculta no momento
                                     * {sugestaoDeSequenciaOtimizada && <td> {this.props.t("labels.timeGain")} </td>}
                                     */}

                                </tr>
                            </thead>
                            <tbody className='box_items'>
                                <tr className={lbStyles.info_equip}>
                                    <td> {etapa.name} </td>
                                    <td> {equipamento.name} </td>
                                    <td>
                                        <div className={lbStyles.fifo}>
                                            <div style={{ background: '#D2E2F1', marginRight: '1em', color: '#EC971F' }}> {listaLotesEspera.length > equipamento.fifo ? equipamento.fifo : listaLotesEspera.length} <img src={FIFOIcon} width={15} height={15} /> </div>
                                            <div style={{ color: '#C9302C' }}> {listaLotesEspera.length > equipamento.fifo ? listaLotesEspera.length - equipamento.fifo : 0} <i style={{ color: '#C9302C', marginTop: -4 }} className="fa fa-arrow-up"></i> </div>
                                        </div>
                                    </td>
                                    {/** 
                                     *  Informaçao oculta no momento
                                     * {sugestaoDeSequenciaOtimizada && <td>{this.calcularTempo(this.state.listaMelhorOrdem.saveTime)}</td>}
                                    */}

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={`${lbStyles.d_none_mobile} bodyTable`}>
                        {(listaLotesEspera.length + listProcessOrder.length) <= 0
                            ?
                            <div style={{ background: '#fff', padding: '3em' }} >{this.props.t("messages.noBatchesInEquipmentQueue", { primaryReference: utils.getReferenciaLotesOrdens().toLowerCase() })}</div>
                            :
                            <Card style={{ margin: 0, boxShadow: 0 }} className='box_list_card'>
                                <div className='table_reponsive' >
                                    <table style={{ fontSize: '16px' }} className='mui-table mui-table--bordered'>
                                        <thead style={{ borderBottom: '1px solid #D6D6D6' }}>
                                            <tr>
                                                {!sugestaoDeSequenciaOtimizada && <td></td>}
                                                {!sugestaoDeSequenciaOtimizada && <td></td>}
                                                {sugestaoDeSequenciaOtimizada && <td style={{ textAlign: 'center' }}>Ordem sugerida</td>}
                                                <td style={{ textAlign: 'center' }}>#</td>
                                                <td style={{ textAlign: 'center' }}>{this.props.t("labels.SKU")}</td>
                                                <td>{this.props.t("labels.product")}</td>
                                                <td>{utils.getReferenciaLoteOrdem()}</td>
                                                {getReferenciaSecundaria}
                                                {!sugestaoDeSequenciaOtimizada && <td></td>}
                                            </tr>
                                        </thead>
                                        <tbody className='box_items'>
                                            {
                                                listaLotesEspera.map((e, i) => {
                                                    let numeroSecundario = getReferenciaSecundaria != null ? <td style={{ textAlign: 'left' }}>{e.num_secundario != null ? e.num_secundario : '-'}</td> : null;

                                                    if (e.type == 'waiting') {
                                                        return (
                                                            <tr id={'row_' + i} key={e.fk_id_batches} style={this.state.lotesAlterados.includes(e.num) ? { background: '#f5b96c' } : {}} className={lbStyles.option}>
                                                                {!sugestaoDeSequenciaOtimizada && (<>
                                                                    {
                                                                        (hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR'])) ?

                                                                            <td className={lbStyles.option}>
                                                                                <div className={"row"} style={{ transform: 'rotate(-90deg)', cursor: 'pointer' }} onClick={() => this.subirPosicao(i)}>
                                                                                    <span className={"material-icons"} >
                                                                                        navigate_next
                                                                                    </span>
                                                                                </div>
                                                                                <div className={"row"} style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={() => this.descerPosicao(i)}>
                                                                                    <span className={"material-icons"}>
                                                                                        navigate_next
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td></td>
                                                                    }
                                                                </>)}

                                                                {!sugestaoDeSequenciaOtimizada && <td></td>}
                                                                {(sugestaoDeSequenciaOtimizada) && <td className={lbStyles.row_table}><td style={{ background: i <= 14 ? '#3e7ad1' : 'transparent', textAlign: 'center' }} className={lbStyles.ordem}>
                                                                    {i <= 14 && <h3 className={lbStyles.nome_ordem}>{this.props.t("labels.queuePositionWithCount", { count: this.state.listaMelhorOrdem.queue.find(q => q.batch.number === e.num)?.position })}</h3>}
                                                                </td></td>}

                                                                <td className={lbStyles.row_table}>
                                                                    <td style={(equipamento.fifo >= (i + 1)) ? { background: '#EC971F', textAlign: 'center' } : { background: '#C9302C', textAlign: 'center' }} className={lbStyles.ordem}>
                                                                        <h3 className={lbStyles.nome_ordem}>{this.props.t('labels.queuePositionWithCount', { count: i + 1 })}</h3>
                                                                    </td>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className={lbStyles.option}>{e.sku}</td>
                                                                <td style={{ textAlign: 'left' }} className={lbStyles.option}>{e.product}</td>
                                                                <td style={{ textAlign: 'left' }} className={lbStyles.option}>{e.num}</td>
                                                                {numeroSecundario}
                                                                {!sugestaoDeSequenciaOtimizada && <td>
                                                                    <div>
                                                                        <img onClick={() => this.props.detalhamentoLote(e, e.lead_time.cor)} style={{ width: '1.5em', height: '1.5em', marginRight: '10px', cursor: 'pointer' }} src='../../../assets/icons/detail.svg' />
                                                                        {
                                                                            hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                                                                                <img onClick={() => this.props.activeModal('moveBatch', e, e, '', etapa, i)} style={{ width: '1.5em', height: '1.5em', cursor: 'pointer' }} src='../../../assets/icons/export.svg' />
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </td>}

                                                            </tr>
                                                        );
                                                    } else {
                                                        return (

                                                            <tr id={'row_' + i} key={e.fk_id_batches} style={this.state.lotesAlterados.includes(e.num) ? { background: '#f5b96c' } : {}} className={lbStyles.option}>
                                                                {
                                                                    hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                                                                        <td className={lbStyles.option}>
                                                                            <div className={"row"} style={{ transform: 'rotate(-90deg)', cursor: 'pointer' }} onClick={() => this.subirPosicao(i)}>
                                                                                <span className={"material-icons"} >
                                                                                    navigate_next
                                                                                </span>
                                                                            </div>
                                                                            <div className={"row"} style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={() => this.descerPosicao(i)}>
                                                                                <span className={"material-icons"}>
                                                                                    navigate_next
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        :
                                                                        <td></td>
                                                                }

                                                                <td style={{ textAlign: 'center', background: '#fff' }} className={lbStyles.tooltip}>
                                                                    <i style={{ fontSize: '1.2em' }} className="fa fa-chain-broken"></i>
                                                                    <span className={lbStyles.toolTipText}>{e.tp_error}</span>
                                                                </td>

                                                                <td className={lbStyles.row_table} >
                                                                    <td style={(equipamento.fifo >= (i + 1)) ? { background: '#EC971F', textAlign: 'center' } : { background: '#C9302C', textAlign: 'center' }} className={lbStyles.ordem}>
                                                                        <h3 className={lbStyles.nome_ordem}>{this.props.t("labels.queuePositionWithCount", { count: i + 1 })}</h3>
                                                                    </td>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className={lbStyles.option}>{e.sku}</td>
                                                                <td style={{ textAlign: 'left' }} className={lbStyles.option}>{e.product}</td>
                                                                <td style={{ textAlign: 'left' }} className={lbStyles.option}>{e.num}</td>
                                                                {numeroSecundario}
                                                                <td className={lbStyles.option}>
                                                                    <div>
                                                                        <img onClick={() => this.props.detalhamentoLote(e, e.lead_time.cor)} style={{ width: '1.5em', height: '1.5em', marginRight: '10px', cursor: 'pointer' }} src='../../../assets/icons/detail.svg' />
                                                                        {
                                                                            hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                                                                                <img onClick={() => this.props.activeModal('moveBatch', e, e, '', etapa, i)} style={{ width: '1.5em', height: '1.5em', cursor: 'pointer' }} src='../../../assets/icons/export.svg' />
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                }).reverse()
                                            }
                                            {
                                                listProcessOrder.map((e, i) => {
                                                    let numeroSecundario = getReferenciaSecundaria != null ? <td style={{ textAlign: 'left' }}>{e.num_secundario != null ? e.num_secundario : '-'}</td> : null;

                                                    if (e.type == 'warning') {
                                                        return (
                                                            <tr id={'row_' + i} key={e.fk_id_batches}>
                                                                {!sugestaoDeSequenciaOtimizada && <td style={{ textAlign: 'center' }}> </td>}
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <div style={{ background: '#fff' }} className={lbStyles.tooltip}>
                                                                        <i style={{ fontSize: '1.2em' }} className="fa fa-chain-broken"></i>
                                                                        <span className={lbStyles.toolTipText}>{e.tp_error}</span>
                                                                    </div>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className={lbStyles.row_table}>
                                                                    <div style={{ background: '#449D44' }} className={lbStyles.ordem}>
                                                                        <h3 className={lbStyles.nome_ordem}>{this.props.t("labels.processing")}</h3>
                                                                    </div>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>{e.sku}</td>
                                                                <td style={{ textAlign: 'left' }}>{e.product}</td>
                                                                <td style={{ textAlign: 'left' }}>{e.num}</td>
                                                                {numeroSecundario}
                                                                <td className={lbStyles.option}>
                                                                    <div>
                                                                        <img onClick={() => this.props.detalhamentoLote(e, e.lead_time.cor)} style={{ width: '1.5em', height: '1.5em', marginRight: '10px', cursor: 'pointer' }} src='../../../assets/icons/detail.svg' />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        return (
                                                            <tr id={'row_' + i} key={e.fk_id_batches} >
                                                                {!sugestaoDeSequenciaOtimizada && <td style={{ textAlign: 'center' }}> </td>}
                                                                <td style={{ textAlign: 'center' }}> </td>
                                                                <td style={{ textAlign: 'center' }} className={lbStyles.row_table}>
                                                                    <div style={{ background: '#449D44' }} className={lbStyles.ordem}>
                                                                        <h3 className={lbStyles.nome_ordem}>{this.props.t("labels.processing")}</h3>
                                                                    </div>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>{e.sku}</td>
                                                                <td style={{ textAlign: 'left' }}>{e.product}</td>
                                                                <td style={{ textAlign: 'left' }} >{e.num}</td>
                                                                {numeroSecundario}
                                                                <td className={lbStyles.option}>
                                                                    <div>
                                                                        <img onClick={() => this.props.detalhamentoLote(e, e.lead_time.cor)} style={{ width: '1.5em', height: '1.5em', marginRight: '10px', cursor: 'pointer' }} src='../../../assets/icons/detail.svg' />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                }).reverse()
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    (this.state.movimentacoes.length != 0 || sugestaoDeSequenciaOtimizada) ?
                                        <div className={'flex flex-just-end'}>
                                            <div>
                                                <NButton
                                                    type="button"
                                                    onClick={this.salvarMovimentacoes}
                                                    style={{ fontWeigth: 100, backgroundColor: '#2D4F7F', color: '#FFF' }}

                                                >{this.props.t("labels.save", { ns: 'common' })}</NButton>
                                            </div>
                                            <div><Button button={{ type: 'round', text: 'Cancelar', onClick: this.props.handleModal }} /></div>
                                        </div>
                                        :
                                        null
                                }
                            </Card>
                        }
                    </div>
                    <div className={lbStyles.d_block_mobile}>
                        <ListBatchesMobile listWaitingOrder={this.state.listaLotesEspera} listProcessOrder={listProcessOrder} store={this.props.store} referenciaSecundaria={referenciaSecundaria} />
                    </div>
                </div>
            </div >
        );
    }
}

export default withTranslation("flowView")(ListBatchesTable);
