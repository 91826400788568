import { serializeParams } from '../../utils';
import { request } from '../../common/request';

const BASE_URL = `${process.env.APIV2_URL}/usuario`;

export class User {

  constructor(options = {}) {
    this.id = undefined;
    this.nome = undefined;
    this.ativo = undefined;
    this.uuid = undefined;
    this.email = undefined;
    this.status = undefined;
    this.senha = "";
    this.qrCodeStatus = false;
    this.qrCodeValue = undefined;
    this.senha_forte = undefined;

    Object.assign(this, {
      ativo: true,
      status: true,
      senha_forte: true
    }, options);
  }

  static find = async (uuid) => {
    const url = `${BASE_URL}/${uuid}`;

    return await request(url, {
      method: 'GET'
    })
      .then(res => res.json())
      .then(
        data => new User(data),
        error => error
      )
  };

  static all = async (params = {}, lazy = false) => {
    const url = `${BASE_URL}/${lazy ? 'lazy' : 'performance'}?` + serializeParams(params);

    return await request(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.status === 204)
          return [];
        return res.json();
      })
      .then(
        data => data.map(c => new User(c)),
        error => error
      )
  };

  create = (data) => {
    return request(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  };

  update = (data) => {
    const url = `${BASE_URL}/${this.uuid}`;
    return request(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
  };
}