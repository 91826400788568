import React, { Component } from 'react';
import '../../../assets/styles/painel-fluxo/CustomCard.css';
import { IF } from '../../../common/NForm'
import FlexBoxGridCss from './../../../assets/styles/painel-fluxo/FlexboxGrid.scss';
import style from '../responsive.scss';
import Utils from '../../../utils/Utils';
import { withTranslation } from 'react-i18next';

class CustomCard extends Component {
  render() {
    return (
      <div id={this.props.id} className={'custom-card '+ style.ie} style={this.props.style}>
        <div className="card-header">
          <span style={{width: '50%', fontWeight: 'bold'}}>{this.props.header}</span> 
          <IF test={this.props.header === this.props.t("widgets.flowProcessRelation.title")}>
            <span style={{ float: 'right', fontStyle: 'italic', color: '#A5A5A5' }}> {this.props.t("widgets.flowProcessRelation.labels.inconsistentBatchesInPeriod", { primaryReference: Utils.getReferenciaLotesOrdens() })}: {this.props.inconsistentesPeriodo}</span>
          </IF>
          {this.props.subheader}
        </div>
        <div id='cardContent' className={'card-content'} style={{display: 'flex', alignItems: 'center'}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withTranslation("flowDashboard")(CustomCard);