import { request } from '../../common/request';

const BASE_URL = `${process.env.APIV2_URL}/dashboard-sessions`;

export default class DashboardSession {

  constructor(options = {}) {
    this.uuid = undefined;
    Object.assign(this, options);
  }

  static create = async (data = { origin, parameters: {} }) => {
    return request(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static keepAlive = async (uuid) => {
    const url = `${BASE_URL}/${uuid}/keep-alive`;

    return request(url, {
      method: 'POST'
    })
  }

  static performKeepAlive = async (origin, parameters = {}) => {
    const storageKey = `${origin}_session`;

    let sessionId = localStorage.getItem(storageKey);

    if (sessionId) {
      return this.keepAlive(sessionId)
        .catch(err => {
          if (err.status === 404) {
            this.create({ origin, parameters: JSON.stringify(parameters) })
              .then(res => res.json())
              .then((result) => localStorage.setItem(storageKey, result.uuid));
          }
        });
    } else {
      return this
        .create({ origin, parameters: JSON.stringify(parameters) })
        .then(res => res.json())
        .then((result) => localStorage.setItem(storageKey, result.uuid));
    }
  }
}
