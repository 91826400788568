import {
  isEqual, isEmpty, filter, findIndex,
} from 'lodash';

import React, {
  useState, useEffect, useCallback,
} from 'react';

import AsyncSelect from 'react-select/async';
import Select, { components as ReactSelectComponents } from 'react-select';

import './autoComplete.scss';
import { useTranslation } from 'react-i18next';

/**
 * All Option default value.
 */
const ALL_OPTIONS = { value: '*', label: 'TODOS' };

const AutoComplete = ({
  name,
  value,
  title,
  async,
  disabled = false,
  options = [],
  isMulti = false,
  classNameIsInvalid,
  allowSelectAll = false,
  placeholder,
  loadOptions = () => { },
  onGetOptionValue: getOptionValue = (option) => { },
  onGetOptionLabel: getOptionLabel = (option) => { },
  onSelectOption: selectOption = (value, action) => { },
  emptyMessage = 'autoComplete.empty',
  onGetActionType,
}) => {
  const { t } = useTranslation('components');
  const [selectedOption, setSelectedOption] = useState();

  /**
   * Init component.
   */
  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  /**
   * Custom implementation of select container component.
   *
   * @param state
   */
  const selectContainer = (state) => {
    const { children, ...props } = state;
    props.className += ` ${classNameIsInvalid} `;

    if (props.selectProps.menuIsOpen) {
      props.className += 'cgtv-auto-complete-focused';
    }

    return (
      <ReactSelectComponents.SelectContainer {...props}>
        {children}
      </ReactSelectComponents.SelectContainer>
    );
  };

  /**
   * Custom style to single value container.
   */
  const customStyle = {
    singleValue: (base, state) => ({
      ...base,
      color: 'none',
      display: state.selectProps.menuIsOpen ? 'none' : 'block',
    }),
  };

  /**
   * Verify that all itens are selected.
   */
  const isAllSelected = useCallback(() => {
    const optionsNotSelected = filter(options, (option) => {
      const index = findIndex(selectedOption, (optionSelected) => {
        const optionValue = getOptionValue(option);
        const selectedValue = getOptionValue(optionSelected);
        return isEqual(selectedValue, optionValue);
      });

      return index < 0;
    });

    return !isEmpty(options) && isEmpty(optionsNotSelected);
  }, [getOptionValue, options, selectedOption]);

  /**
   * Get options to component.
   */
  const getOptions = () => {
    let values = (allowSelectAll && !isEmpty(options) ? [ALL_OPTIONS, ...options] : [...options]);
    if (isAllSelected() && allowSelectAll) {
      values = [{ ...ALL_OPTIONS }];
    }

    return values;
  };

  /**
   * Get values to show on action select.
   */
  const getValue = () => {
    let values = selectedOption;
    if (isAllSelected() && allowSelectAll) {
      values = [{ ...ALL_OPTIONS }];
    }

    return values;
  };

  /**
   * Verify that action is select.
   *
   * @param action
   */
  const isSelectAction = (action) => isEqual(action, 'select-option');

  /**
   * Verify that action is remove.
   *
   * @param action
   */
  const isRemoveAction = (action) => isEqual(action, 'remove-value');

  /**
   * Verify that action is deselect.
   *
   * @param action
   */
  const isDeselectAction = (action) => isEqual(action, 'deselect-option');

  /**
   * Verify that option value is equals ALL_OPTIONS value.
   *
   * @param option
   */
  const isAllOption = (option) => option && isEqual(getOptionValue(option), ALL_OPTIONS.value);

  return (
    <>
      {async && (
        <AsyncSelect
          name={name}
          cacheOptions
          title={title}
          defaultOptions
          isDisabled={disabled}
          value={selectedOption}
          styles={customStyle}
          placeholder={placeholder || t('autoComplete.select')}
          noOptionsMessage={() => t(`${emptyMessage}`)}
          className="custom-select auto-complete single"
          classNamePrefix="cgtv-auto-complete"
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          components={{ SelectContainer: selectContainer }}
          loadOptions={(inputValue) => loadOptions(inputValue)}
          onChange={(option) => {
            selectOption(option);
            setSelectedOption(option);
          }}
        />
      )}

      {!async && !isMulti && (
        <Select
          name={name}
          title={title}
          options={options}
          styles={customStyle}
          value={selectedOption}
          isDisabled={disabled}
          placeholder={placeholder || t('autoComplete.select')}
          noOptionsMessage={() => t(`${emptyMessage}`)}
          className="custom-select auto-complete single"
          classNamePrefix="cgtv-auto-complete"
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          components={{ SelectContainer: selectContainer }}
          onChange={(option, actionMeta) => {
            selectOption(option, actionMeta.action);
            setSelectedOption(option);
          }}
        />
      )}

      {!async && isMulti && (
        <Select
          name={name}
          title={title}
          isMulti={true}
          styles={customStyle}
          options={getOptions()}
          value={getValue()}
          isDisabled={disabled}
          defaultValue={getValue()}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          classNamePrefix="cgtv-auto-complete"
          className="custom-select auto-complete"
          placeholder={placeholder || t('autoComplete.select')}
          noOptionsMessage={() => t(`${emptyMessage}`)}
          components={{ SelectContainer: selectContainer }}
          onChange={(newValue, actionMeta) => {
            const { action, option, removedValue } = actionMeta;
            const isAllDeselected = (isDeselectAction(action) && isAllOption(option));
            const isAllRemoved = (isRemoveAction(action) && isAllOption(removedValue));

            let data = [];
            if (isSelectAction(action) && isAllOption(option)) {
              data = options;
            } else if (isAllDeselected || isAllRemoved) {
              data = [];
            } else if (isDeselectAction(action) && isAllSelected()) {
              data = options.filter((item) => getOptionValue(item) !== getOptionValue(option));
            } else {
              data = newValue || [];
            }

            selectOption(data, action);
          }}
        />
      )}

    </>
  );
};

export default AutoComplete;

