import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from 'utils/i18n';

render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('content')
);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;

    render(
      <I18nextProvider i18n={i18n}>
        <NextApp />
      </I18nextProvider>,
      document.getElementById('content')
    );
  });
}
