import common from './common.json'
import components from './components.json'
import validation from './validation.json'
import * as modules from './modules';

export default { 
    common,
    components,
    validation,
    ...modules
}