import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import Bar from "../Bar/Bar";

class SetupBarChart extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      matchScreen: window.matchMedia(
        "(min-width: 1359px) and (max-width: 1855px)",
      ).matches,
    };
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matchScreen: e.matches });
    window
      .matchMedia("(min-width: 1359px) and (max-width: 1855px)")
      .addListener(handler);
  }

  render() {
    let bars = [];
    let self = this;

    this.props.data.indicatorBackground.forEach((val, i) => {
      let padrao = this.props.data.data[i];
      let real = this.props.data.data2[i];
      let padraoProporcional = this.props.data.padraoProporcional[i];
      let variasVigencias = this.props.data.variasVigencias[i];
      let label = this.props.data.labels[i];
      let indicatorBackground = val;
      let indicatorColor = this.props.data.indicatorColor[i];
      let backgroundColorSecondary =
        padraoProporcional || variasVigencias
          ? "rgba(255, 0, 0, 0.4)"
          : "rgba(150, 150, 150, 0.5)";

      if (this.props.data.indicatorBackground[i] != "transparent")
        bars.push(
          <Bar
            key={i}
            style={{
              height: 75,
              display: "grid",
              gap: 16,
              gridTemplateColumns: "300px 1fr",
            }}
            ticksColor={this.props.ticksColor}
            label={label}
            padrao={padrao}
            horizontalBarLabel={this.props.horizontalBarLabel}
            real={real}
            fontStyle="bold"
            showTicks={false}
            fontSize={14}
            frequency={this.props.data.frequency[i]}
            setupPerformance={this.props.data.setupPerformance[i]}
            width={this.state.matchScreen ? 270 : 300}
            height={this.state.matchScreen ? 75 : 80}
            styleSecondbar={{
              fontSize: 12,
              display: true,
              paddingLeft: this.state.matchScreen ? 0 : 5,
              paddingRight: this.state.matchScreen ? 0 : 18,
              overlap: false,
              fontColor: "#ffffff",
              backgroundColor: backgroundColorSecondary,
              formatter: (value) => value + "h",
            }}
            indicatorBackground={indicatorBackground}
            indicatorColor={indicatorColor}
            displayTooltip={this.props.displayTooltip}
          />,
        );
    });

    return (
      <div className="chartWrapper">
        <div className="chartAreaWrapper" id="style-4">
          {bars}
        </div>
      </div>
    );
  }
}

export default SetupBarChart;
