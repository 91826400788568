import React from 'react';
import { observer, inject } from 'mobx-react';
import { AutoComplete, Card, SelectField, MenuItem, RaisedButton, LinearProgress, Toggle } from 'material-ui';
import Container from '../common/Container';
import { NInputTime, NDatePickerInput, alertBox } from '../common/NForm';
import moment from 'moment';
import $ from 'jquery';
import { default as MultiSelect } from 'components/AutoComplete';
import Notifier from '../utils/Notifier';
import { request, requestAll } from '../common/request';
import MDSpinner from "react-md-spinner";
import PainelOEE from '../components/oee';
import { respostaEquipamentos, respostaUnidadeIndustrial, respostaTurnos } from '../common/mappers';
import Utils from '../utils/Utils';
import css from './oee.scss';
import ModalApontamentosTimeline from './components/ModalApontamentosTimeline';
import ModalPareto from './components/ModalPareto';
import { withTranslation } from 'react-i18next';
import { Mixpanel } from 'utils/Mixpanel';

var atualizarOee = 1;

@inject('store')
@observer
class OeeContainer extends Container {
  constructor(props) {
    super(props);

    this.state = {
      atualizarAutomaticamente: false,
      equipment: '',
      modo: '',
      start: '',
      end: '',
      teep: false,
      setup: { mark: 31.9, real: 50.2 },
      modal: false,
      modalType: '',
      unidadeIndustrial: [],
      equipamentos: [],
      turnos: [],
      periodoReport: '',
      unidadeReport: '',
      textEquipamento: '',
      equipamentoUnidade: [],
      agrupamentoReport: '',
      dataInicioReport: null,
      dataFimReport: null,
      dataUnidade: '',
      mesesUnidade: [],
      setupReport: true,
      oeeReport: true,
      classeReport: false,
      eventoReport: false,
      manutencaoReport: false,
      erroUnidade: '',
      equipamentoReport: [],
      equipmentReport: '',
      turnoReport: [],
      erroEquipamento: false,
      loadingPage: true,
      erroMSG: '',
      erro_inicio: false,
      erro_fim: false,
      erro_equipamento: '',
      erro_modo: '',
      horaInicioReport: 0,
      horaFimReport: 0,
      erroHoraInicio: '',
      erroHoraFim: '',
      horaInicioTurnoReport: 0,
      horaFimTurnoReport: 0,
      erroHoraInicioTurnoReport: '',
      erroHoraFimTurnoReport: '',
      horaInicio: '',
      horaFim: '',
      horaInicioTurno: 0,
      horaFimTurno: 0,
      erroHoraInicioTurno: '',
      erroHoraFimTurno: '',
      dataInicio: '',
      dataFim: '',
      turnoPersonalizado: [],
      erroDataInicioReport: false,
      erroDataFimReport: false,
      erroMesInicioReport: '',
      erroMesFimReport: '',
      erroHoraInicioReport: '',
      erroHoraFimReport: '',
      grupoEquipamentos: [],
      equipamentoEscolhido: '',
      equipamentoEscolhidoReport: [],
      textEquipamentoReport: '',
      erro_equipamentoReport: '',
      grupoEquipamentoEscolhido: 0,
      flagSetupProporcional: true,
      dataPrimeiroApontamento: [],
      gerar: false,
      diferencaDias: 0,
      granularidade: 1,
      exibirDisponibilidadeZerada: false,
      paretos: [],
      searchTextEquipamento: ''
    };

    this.disponibilidadeStore = this.props.store.disponibilidadeStore;
    this.store.oee = null;
    this.generateOee = this.generateOee.bind(this);
    this.handleField = this.handleField.bind(this);
    this.menuIconClicked = this.menuIconClicked.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.radioButton = this.radioButton.bind(this);
    this.handleUnidade = this.handleUnidade.bind(this);
    this.getEquipments = this.getEquipments.bind(this);
    this.radioAgrupamento = this.radioAgrupamento.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this._valiDateStart = this._valiDateStart.bind(this);
    this.filtrarDisponibilidade = this.filtrarDisponibilidade.bind(this);
    this.openModalApontamento = this.openModalApontamento.bind(this);
    this.updateFlagSetupProporcional = this.updateFlagSetupProporcional.bind(this);
    this.openModalPareto = this.openModalPareto.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.gerar && document.getElementById('gerarOee') != null) {
      if (atualizarOee == 1) {
        document.getElementById('gerarOee').click();
      }
      atualizarOee = 2;
    }
  }

  componentDidMount() {
    if (this.props.location.search != '') {
      let parametrosOee = Utils.getURLParams(this.props.location.search);

      if (Object.keys(parametrosOee).length != 0) {
        this.setState({
          equipment: parametrosOee.equipamentoId,
          equipamentoEscolhido: [parametrosOee.equipamentoId],
          modo: parseInt(parametrosOee.modo) || 6,
          textEquipamento: decodeURI(parametrosOee.equipamentoNome),
          gerar: true
        });

        if (parametrosOee.dataInicio || parametrosOee.dataFim) {
          var dataInicio = moment(parametrosOee.dataInicio, 'YYYY-MM-DD HH:mm:ss').utcOffset(0, true);
          var dataFim = moment(parametrosOee.dataFim, 'YYYY-MM-DD HH:mm:ss').utcOffset(0, true);

          this.setState({
            horaInicio: dataInicio.format('HH:mm:ss'),
            horaFim: dataFim.format('HH:mm:ss'),
            start: dataInicio,
            end: dataFim
          });
        }
      }
    }

    requestAll([
      {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial,
        acao: true
      },
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      },
      {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'unidadeIndustrial',
        modificador: respostaUnidadeIndustrial,
        destino: 'state',
        acao: true
      },
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipamentos',
        destino: 'state',
        acao: true
      },
      {
        url: this.endpoints.GRUPO_EQUIPAMENTO + '?comEquipamento=true',
        modelo: 'grupoEquipamentos',
        destino: 'state',
        acao: true
      },
      {
        url: this.endpoints.TURNO,
        modelo: 'turnos',
        modificador: respostaTurnos,
        destino: 'state',
        acao: true
      }
    ], this);
  }

  updateFlagSetupProporcional(flagSetupProporcional) {
    this.setState({
      flagSetupProporcional: flagSetupProporcional
    });
  }

  atualizarAutomatico() {
    if (!this.state.atualizarAutomaticamente) {
      this.setState({
        atualizarAutomaticamente: true
      });
    } else {
      this.setState({
        atualizarAutomaticamente: false
      });
    }
  }

  filtrarDisponibilidade() {
    this.setState({
      exibirDisponibilidadeZerada: !this.state.exibirDisponibilidadeZerada
    }, () => this.disponibilidadeStore.setExibirDisponibilidade(this.state.exibirDisponibilidadeZerada));
  }

  generateOee(detalhamentoGranularidade) {
    let { equipment, modo, start, end, horaInicio, horaFim, horaInicioTurno, horaFimTurno, turnoPersonalizado } = this.state;
    let erroCampo = false;
    let data_inicio, data_fim;

    if (this.oee.state) {
      this.oee.state.loadingData = true;
    }

    if (!equipment) {
      this.setState({
        erro_equipamento: this.props.t("errors.requiredField", { ns: 'validation' })
      });
      erroCampo = true;
    } else {
      this.setState({
        erro_equipamento: ''
      });
    }

    if (!modo) {
      this.setState({
        erro_modo: this.props.t("errors.requiredField", { ns: 'validation' })
      });
      erroCampo = true;
    } else {
      this.setState({
        erro_modo: ''
      });
    }

    if (equipment && modo) {
      switch (modo) {
        case 1:
          data_inicio = moment().format('DD-MM-YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 2:
          data_inicio = moment().subtract(1, 'day').format('DD-MM-YYYY') + ' 00:00:00';
          data_fim = moment().subtract(1, 'day').format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 3:
          data_inicio = '01-01-' + moment().format('YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 4:
          data_inicio = '01-' + moment().format('MM-YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 5:
          data_inicio = moment().subtract(15, 'day').format('DD-MM-YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 6:
          let erro = false;

          if (!start) {
            this.setState({
              erro_inicio: true
            });
            erro = true;
          } else {
            this.setState({
              erro_inicio: false
            });
          }

          if (!end) {
            this.setState({
              erro_fim: true
            });
            erro = true;
          } else {
            this.setState({
              erro_fim: false
            });
          }

          if (!horaInicio.trim() && !horaFim.trim()) {
            this.setState({
              horaInicio: '00:00:00',
              horaFim: '23:59:59',
              erroHoraFim: '',
              erroHoraInicio: ''
            })
            erro = false;

          } else if (!horaInicio.charAt(6) && !horaFim.charAt(6)) {
            this.setState({
              horaInicio: horaInicio + ':00',
              horaFim: horaFim + ':59',
              erroHoraFim: '',
              erroHoraInicio: ''
            })
            erro = false;

          } else if (!horaInicio.charAt(6)) {
            this.setState({
              erroHoraInicio: this.props.t("errors.requiredField", { ns: 'validation' })
            })
            erro = true;

          } else if (!horaFim.charAt(6)) {
            this.setState({
              horaFim: this.props.t("errors.requiredField", { ns: 'validation' })
            })
            erro = true;

          } else if (!horaInicio.trim()) {
            this.setState({
              erroHoraInicio: this.props.t("errors.requiredField", { ns: 'validation' })
            })
            erro = true;

          } else if (!horaFim.trim()) {
            this.setState({
              erroHoraFim: this.props.t("errors.requiredField", { ns: 'validation' })
            })
            erro = true;
          } else {
            this.setState({
              erroHoraFim: '',
              erroHoraInicio: ''
            })
          }

          if (horaInicio.trim() == '' && horaFim.trim() == '') {
            horaInicio = '00:00:00',
              horaFim = '23:59:59'
          }

          if (!horaInicio.charAt(6) && !horaFim.charAt(6)) {
            horaInicio = horaInicio + ':00',
              horaFim = horaFim + ':59';
          }

          data_inicio = start.format('DD-MM-YYYY') + ' ' + horaInicio;
          data_fim = end.format('DD-MM-YYYY') + ' ' + horaFim;

          if (erro) {
            return;
          }
          break;
      }

      if (turnoPersonalizado.map(c => c.id).includes(0)) {
        if (!horaInicioTurno) {
          this.setState({
            erroHoraInicioTurno: this.props.t("errors.requiredField", { ns: 'validation' })
          });
          erroCampo = true;
        } else {
          this.setState({
            erroHoraInicioTurno: ''
          });
        }

        if (!horaFimTurno) {
          this.setState({
            erroHoraFimTurno: this.props.t("errors.requiredField", { ns: 'validation' })
          });
          erroCampo = true;
        } else {
          this.setState({
            erroHoraFimTurno: ''
          });
        }
      }

      if (horaFim < horaInicio && (moment(this.state.start).startOf('day') >= moment(this.state.end).startOf('day'))) {
        alertBox(this.props.t("errors.endTimeLessThanStartTime"), this.props.t("labels.error", { ns: 'common' }), 'error');
        $('#horaFim').val('');
        $('#horaFim').focus();
        erroCampo = true;
      }

      if (moment(end).isBefore(moment(start))) {
        this.showAlert(this.props.t("errors.endTimeLessThanStartTime"), this.props.t("labels.error", { ns: 'common' }), 'warning');
        erroCampo = true;
      }
      if (!erroCampo) {
        var startDate = moment(data_inicio, 'DD-MM-YYYY HH:mm:ss');
        var endDate = moment(data_fim, 'DD-MM-YYYY HH:mm:ss');

        //A diff desconsidera o dia atual do start, por isso é necessario adicionar 1 dia à contagem
        var dateRange = endDate.diff(startDate, 'days') + 1;
        var granularidadeVerificar = 1;

        if (dateRange <= 31) {
          granularidadeVerificar = 1;
        } else if (dateRange <= 180) {
          granularidadeVerificar = 2;
        } else if (dateRange <= 365) {
          granularidadeVerificar = 3;
        } else {
          granularidadeVerificar = 5;
        }

        detalhamentoGranularidade ? '' : this.setState({ granularidade: granularidadeVerificar });

        this.setState({
          dataInicio: data_inicio,
          dataFim: data_fim,
          modo: modo,
          diferencaDias: dateRange
        }, () => this.oee.carregarDados());

        Mixpanel.track('GENERATE_OEE', {
          startDate: data_inicio,
          equipment,
          endDate: data_fim,
          dateRange
        })
      } else {
        this.oee.state.loadingData = false;
      }
    }
  }

  handleField(key, event) {
    let { state } = this;
    state[key] = event.target.value;
    this.setState(state);
  }

  handleDate(key, event) {
    let { state } = this;
    state[key] = event;
    this.setState(state);
  }

  handleModal(type) {
    this.setState({
      modal: !this.state.modal,
      modalType: type || '',
    });
  }

  openModal(type) {
    this.setState({
      modal: true,
      modalType: type
    });
  }

  closeModal(type) {
    this.setState({
      modal: false,
      modalType: type
    });
  }

  getEquipments(session) {

    let equipments = this.store.sessions.filter(e => e.id == session).reduce((p1, se) => p1.concat(
      se.stages.reduce((p2, st) => p2.concat(st.equipments.map(e => e)), [])
    ), []);

    return equipments;
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  setEquipamento(nomeEquipamento, allUnd) {
    this.setState({ searchTextEquipamento: nomeEquipamento, equipamentoEscolhido: [], textEquipamento: '' });

    this.sleep(500).then(() => {
      let { grupoEquipamentos, equipamentos } = this.state;
      var grupo = grupoEquipamentos.filter(e => e.nome == nomeEquipamento)[0];
      var equip = [], grupoId;

      if (!grupo) {
        let equipFilter = equipamentos.filter(e => e.nome == nomeEquipamento)[0];

        if (equipFilter) equip.push(equipFilter.id);
      } else {

        equip = grupo.equipamentos.map(e => e.id);
        grupoId = grupo.uuid;
      }

      let obj = allUnd.filter(i => i.text === nomeEquipamento)[0];

      if (obj !== undefined) {
        this.setState({
          equipamentoEscolhido: equip,
          equipment: obj.value,
          textEquipamento: obj.text,
          grupoEquipamentoEscolhido: grupoId
        });
      }
      this.handlePushState();
    });
  }

  setEquipamentoReport(nomeEquipamento, allUnd) {
    let { grupoEquipamentos, equipamentos } = this.state;
    var grupo = grupoEquipamentos.filter(e => e.nome == nomeEquipamento)[0];
    var equip = [], grupoId;

    if (!grupo) {
      let equipFilter = equipamentos.filter(e => e.nome == nomeEquipamento)[0];

      equip.push(equipFilter.id);
      grupoId = 0;
    } else {
      equip = grupo.equipamentos.map(e => e.id);
      grupoId = grupo.uuid;
    }

    let obj = allUnd.filter(i => i.text === nomeEquipamento)[0];

    if (obj !== undefined) {
      this.setState({
        equipamentoEscolhidoReport: equip,
        equipmentReport: obj.value,
        textEquipamentoReport: obj.text,
        grupoEquipamentoEscolhido: grupoId
      });
    }
  }

  setEquipamentoReport(nomeEquipamento, allUnd) {
    let { grupoEquipamentos, equipamentos } = this.state;
    var grupo = grupoEquipamentos.filter(e => e.nome == nomeEquipamento)[0];
    var equip = [], grupoId;

    if (!grupo) {
      let equipFilter = equipamentos.filter(e => e.nome == nomeEquipamento)[0];

      equip.push(equipFilter.id);
      grupoId = 0;
    } else {
      equip = grupo.equipamentos.map(e => e.id);
      grupoId = grupo.uuid;
    }

    let obj = allUnd.filter(i => i.text === nomeEquipamento)[0];

    if (obj !== undefined) {
      this.setState({
        equipamentoEscolhidoReport: equip,
        equipmentReport: obj.value,
        textEquipamentoReport: obj.text,
        grupoEquipamentoEscolhido: grupoId
      });
    } else {
      this.setState({
        textEquipamentoReport: ''
      });
    }
  }

  handleUnidade(event, index, value) {
    let equipment = this.getEquipments(value);
    var dataInicio, dataFim, valorData = [], dataSearch;

    equipment = equipment.map((f) => {
      return { value: f.id, text: f.name };
    });

    this.setState({
      unidadeReport: value,
      equipamentoUnidade: equipment
    });

    request(this.endpoints.UNIDADE_INDUSTRIAL + `/${value}/primeiro-e-ultimo/apontamento`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
      .then(function (response) {
        if (response.status == 200) return response.json();
      })
      .then((data) => {
        dataInicio = moment(`${moment(data.data_inicio, 'YYYY-MM-DD').year()}-${moment(data.data_inicio, 'YYYY-MM-DD').month() + 1}-01`, 'YYYY-MM-DD');
        dataFim = moment(data.data_fim);

        while (dataFim > dataInicio) {
          valorData.push({ data: dataInicio.format('01-MM-YYYY'), mes: dataInicio.format('MMMM/YY') });
          dataInicio.add(1, 'month');
        }

        dataSearch = valorData.map((d) => {
          return { value: d.data, text: d.mes };
        });

        this.setState({
          dataUnidade: data,
          mesesUnidade: dataSearch,
          equipamentoReport: []
        });
      });
  }

  handleComplete(complete, value, index) {
    let objValue = index.find(i => i.text == value);

    this.setState({
      [complete]: objValue
    });
  }

  openModalApontamento(apontamentosTimeline) {
    this.setState({
      modal: true,
      modalType: 'apontamentos-timeline',
      apontamentosTimeline
    });
  }

  openModalPareto(paretos, urlParams) {
    this.setState({
      modal: true,
      modalType: 'pareto',
      urlParams,
      paretos
    });
  }

  contentModal() {
    switch (this.state.modalType) {
      case 'report_oee':
        if (!this.state.periodoReport) {
          setTimeout(() => {
            document.getElementById('radioSete').checked = true;
          }, 0);
          this.state.periodoReport = 7;
        }
        return this.modalReport();
        break;

      case 'loading':
        return <div className="modal" id={css.loading} style={{ width: '80px', height: '100%' }}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>;

      case 'loading_apotamento_timeline':
        return <div className="modal" id={css.loading_apontamento_timeline} style={{ width: '80px', height: '100%' }}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>;

      case 'erro':
        return this.errorMessage();
        break;

      case 'apontamentos-timeline':
        return <ModalApontamentosTimeline closeModal={this.closeModal} apontamentosTimeline={this.state.apontamentosTimeline} />

      case 'pareto':
        return <ModalPareto urlParams={this.state} paretos={this.state.paretos} closeModal={this.closeModal} showAlert={this.showAlert} />;

      case 'loading_relatorio':
        return <div className="modal" id={css.loading_relatorio} style={{ width: '250px', height: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px', paddingTop: '10px' }}>
            <i className="material-icons" style={{ fontSize: '2.7em', paddingTop: '15px' }}>settings</i>
            <i className="material-icons" style={{ fontSize: '2.2em', transform: 'rotate(36deg)', position: 'relative', right: '10px', paddingTop: '3px' }}>settings</i>
          </div>
          <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
            <h3 style={{ fontSize: '1.2em', fontWeight: '600' }}>{this.props.t("labels.generatingReport")}</h3>
          </div>
          <LinearProgress mode="indeterminate" color='#2D4F7F' size={60} thickness={5} style={{ marginBottom: '5px', paddingBottom: '3px' }} />
        </div>;
        break;
    }
  }

  radioButton(event) {
    var valor = $(event.target).data('value');

    this.setState({
      periodoReport: valor
    });

    if (valor == 'Personalizar') {
      setTimeout(() => {
        document.getElementById('radioDiario').checked = true;
      }, 0);
      this.setState({
        agrupamentoReport: 'diario'
      });
    }
  }

  gerarRelatorio() {
    let { unidadeReport, equipamentoEscolhidoReport, turnoReport, periodoReport, agrupamentoReport,
      dataInicioReport, dataFimReport, setupReport, oeeReport, classeReport, eventoReport,
      manutencaoReport, horaInicioReport, horaFimReport, horaInicioTurnoReport, horaFimTurnoReport, grupoEquipamentoEscolhido } = this.state;
    var erroCampo = false, agrupamento;
    var data;
    let dataInicio, dataFim;

    agrupamento = periodoReport == 90 ? 'mensal' : 'diario';

    if (periodoReport == 'Personalizar') {
      if (!dataInicioReport) {
        this.setState({
          erroDataInicioReport: true
        });
        return;
      } else {
        this.setState({
          erroDataInicioReport: false
        });
      }

      if (!dataFimReport) {
        this.setState({
          erroDataFimReport: true
        });
        return;
      } else {
        this.setState({
          erroDataFimReport: false
        });
      }

      if (!horaInicioReport) {
        this.setState({
          erroHoraInicioReport: this.props.t("errors.requiredField", { ns: 'validation' })
        });
        return;
      } else {
        this.setState({
          erroHoraInicioReport: ''
        });
      }

      if (!horaFimReport) {
        this.setState({
          erroHoraFimReport: this.props.t("errors.requiredField", { ns: 'validation' })
        });
        return;
      } else {
        this.setState({
          erroHoraFimReport: ''
        });
      }

      if (agrupamentoReport == 'mensal') {
        dataInicio = dataInicioReport.value + ' ' + horaInicioReport;
        dataFim = moment(new Date(moment(dataFimReport.value, 'DD-MM-YYYY', true).year(), moment(dataFimReport.value, 'DD-MM-YYYY', true).month(), moment(dataFimReport.value, 'DD-MM-YYYY', true).daysInMonth())).format('DD-MM-YYYY') + ' ' + horaFimReport;
        agrupamento = 'mensal';
      } else {
        dataInicio = dataInicioReport.format('DD-MM-YYYY') + ' ' + horaInicioReport;
        dataFim = dataFimReport.format('DD-MM-YYYY') + ' ' + horaFimReport;
      }
    } else {
      dataInicio = moment().subtract(periodoReport, 'days').format('DD-MM-YYYY 00:00:00');
      dataFim = moment().format('DD-MM-YYYY 23:59:59');
    }

    if (equipamentoEscolhidoReport.length == 0) {
      this.setState({
        erro_equipamentoReport: this.props.t("errors.requiredField", { ns: 'validation' })
      });
      erroCampo = true;
    } else {
      this.setState({
        erro_equipamentoReport: ''
      });
    }

    if (moment(dataFim, 'DD-MM-YYYY HH:mm:ss').isBefore(moment(dataInicio, 'DD-MM-YYYY HH:mm:ss'))) {
      this.showAlert(this.props.t("errors.endTimeLessThanStartTime"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
      this.store.errors = '';
      erroCampo = true;
    }

    if (!oeeReport && !classeReport && !eventoReport && !manutencaoReport) {
      this.showAlert(this.props.t("errors.noOptionsForReport"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
      erroCampo = true;
    }

    if (turnoReport.includes(0)) {
      if (!horaInicioTurnoReport) {
        this.setState({
          erroHoraInicioTurnoReport: this.props.t("errors.requiredField", { ns: 'validation' })
        });
        erroCampo = true;
      } else {
        this.setState({
          erroHoraInicioTurnoReport: ''
        });
      }

      if (!horaFimTurnoReport) {
        this.setState({
          erroHoraFimTurnoReport: this.props.t("errors.requiredField", { ns: 'validation' })
        });
        erroCampo = true;
      } else {
        this.setState({
          erroHoraFimTurno: ''
        });
      }
    }

    if (!erroCampo) {
      data = {
        unidadeIndustrial: unidadeReport,
        equipamentos: equipamentoEscolhidoReport,
        turnos: turnoReport,
        oee: oeeReport,
        classes: classeReport,
        evento: eventoReport,
        manutencao: manutencaoReport,
        dataInicio: dataInicio,
        dataFim: dataFim,
        setup: setupReport,
        horaInicioReport: horaInicioTurnoReport || 0,
        horaFimReport: horaFimTurnoReport || 0
      };

      let equipamentos = data.equipamentos.map((e, i) => {
        return `equipamentos[${i}]=${e}`;
      }).join('&');

      let turnos = data.turnos.map((e, i) => {
        return `turnos=${e}`;
      }).join('&');

      let params = `/${data.dataInicio}/${data.dataFim}/${data.oee}/${data.evento}/${data.classes}/${data.manutencao}/${this.state.turnoReport.includes(0)}/${data.horaInicioReport}/${data.horaFimReport}?${equipamentos}&agrupamento=${agrupamento}&${turnos}&grupoEquipamentoUuid=${grupoEquipamentoEscolhido}`;

      const url = this.endpoints.RELATORIO_OEE + params;

      this.openModal('loading_relatorio');

      requestRelatorio(url, this.props.t("labels.reportName")).then(() => {
        this.setState({
          equipamentoEscolhidoReport: [],
          turnoReport: [],
          horaInicioReport: 0,
          horaFimReport: 0,
          horaInicioTurnoReport: 0,
          horaFimTurnoReport: 0,
          unidadeReport: '',
          classeReport: false,
          eventoReport: false,
          manutencaoReport: false,
          oeeReport: true,
          periodoReport: 7,
          modal: false,
          textEquipamentoReport: '',
          grupoEquipamentoEscolhido: 0,
          dataInicioReport: null,
          dataFimReport: null,
        });
      })
        .catch(() => {
          this.setState({
            modal: true,
            modalType: 'erro',
            erroMSG: this.props.t("errors.cannotGenerateOeeReport"),
            equipamentoEscolhidoReport: [],
            turnoReport: [],
            horaInicioReport: 0,
            horaFimReport: 0,
            horaInicioTurnoReport: 0,
            horaFimTurnoReport: 0,
            unidadeReport: '',
            classeReport: false,
            eventoReport: false,
            manutencaoReport: false,
            periodoReport: 7,
            textEquipamentoReport: '',
            grupoEquipamentoEscolhido: 0,
            dataInicioReport: null,
            dataFimReport: null,
          });
        });
    }
  }

  errorMessage() {
    return (
      <div className="modal" style={{ width: '500px', height: '100%' }}>
        <div>
          <div style={{ textAlign: 'left' }} >
            {this.state.erroMSG}
          </div>
          <div>
            <i className="fa fa-close cursor-pointer" onClick={() => this.handleModal()} />
          </div>
        </div>
        <div style={{ paddingTop: '1em' }} >
          {this.props.t("labels.email")}: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
        </div>
      </div>
    );
  }

  radioAgrupamento(event) {
    var valor = $(event.target).data('value');

    this.setState({
      agrupamentoReport: valor
    });
  }

  showAlert(msg, title, type) {
    if (type == 'error') {
      Notifier.error(msg || 'Oops!', title || this.props.t("labels.notification"));
      setTimeout(3000);
    }

    if (type == 'success') {
      Notifier.success(msg || 'Oops!', title || this.props.t("labels.notification"));
      setTimeout(5000);
    }

    if (type == 'alert') {
      Notifier.warning(msg || 'Oops!', title || this.props.t("labels.notification"));
      setTimeout(5000);
    }
  }

  menuIconClicked(event, value) {
    switch (value) {
      case 0:
        this.setState({
          modal: true,
          modalType: 'report_oee',
        });
    }
  }

  //verifica se a data tem 10 digitos para que ele possa digitar a data normalmente
  _valiDateStart(date) {

    //se a quantidade de caracteres digitados for diferente de 10
    if (date.length !== 10) {
      $('#dataFinal').val('');
      $('#horaInicial').val('');
      this.setState({
        start: '',
        end: ''
      });
      alertBox(this.props.t("errors.invalidStartDate"), this.props.t("labels.warning", { ns: "common" }), 'warning');

    } else if (date.length === 10) { // Se a quantidade de caracteres for igual a 10 

      //data digitada
      var dateMoment = moment(date, 'L');

      //data de hoje
      var dateNow = moment();

      //data selecionada é menor q a data atual
      var dataMenor = moment(dateMoment).isAfter(dateNow);
      var endDate = moment(this.state.end, 'L');

      //A diff desconsidera o dia atual do start, por isso é necessario adicionar 1 dia à contagem
      var dateRange = endDate.diff(dateMoment, 'days') + 1;

      //verifica se a dateMoment selecionada é valida
      if (!dateMoment.isValid()) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');
        this.setState({
          start: '',
          end: ''
        });
        alertBox(this.props.t("errors.invalidStartDate"), this.props.t("labels.warning", { ns: "common" }), 'warning');
      }

      //verifica se a data selecionada é menor que a data de hoje
      else if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');
        this.setState({
          start: '',
          end: ''
        });
        alertBox(this.props.t("errors.startDateInFuture"), this.props.t("labels.warning", { ns: "common" }), 'warning');
      }
      else {
        this.setState({
          diferencaDias: dateRange
        });
      }
    }
  }

  _valiDateEnd(date) {
    if (date.length !== 10) {
      $('#dataFinal').val();
      $('#horaFim').val('');
      this.setState({
        end: ''
      });
      alertBox(this.props.t("errors.invalidDate"), this.props.t("labels.warning", { ns: "common" }), 'warning');

    } else if (date.length === 10) {
      // Data selecionada pelo usuario
      var dateMoment = moment(date, 'L');

      // Moment de hoje
      var dateNow = moment();

      //data selecionada é maior q a data atual
      var dataNoFuturo = moment(dateMoment).isAfter(dateNow);

      //Data de incio selecionada pelo usuario formatada dia/mes/ano
      var dataInicioFormatada = moment(this.state.start).format('L');

      // gerar moment da data selecionada pelo usuario concatenada com o horario
      var dataInicio = moment(dataInicioFormatada + ' ' + this.state.horaInicio, 'L LTS');

      // Data final selecionada pelo usuario concatenada ao horario
      var endDate = moment(date + ' ' + this.state.horaInicio, 'L LTS');

      //A diff desconsidera o dia atual do start, por isso é necessario adicionar 1 dia à contagem
      var dateRange = endDate.diff(dataInicio, 'days') + 1;

      if (dataNoFuturo) {
        $('#dataFinal').val('');
        $('#horaFim').val('');
        this.setState({
          end: '',
          horaFim: ''
        });
        alertBox(this.props.t("errors.endDateInFuture"), this.props.t("labels.warning", { ns: "common" }), 'warning');
      } else if (endDate.isBefore(dataInicio)) {
        $('#dataFinal').val('');
        $('#horaFim').val('');
        this.setState({
          end: '',
          horaFim: ''
        });
        alertBox(this.props.t("errors.endTimeLessThanStartTime"), this.props.t("labels.warning", { ns: "common" }), 'warning');
      } else {
        this.setState({
          diferencaDias: dateRange
        })
      }
    }
  }

  handleTime(end) {
    if (this.state.insDataInicial === '') {
      $('#dataFinal').val('');
      $('#horaFim').val('');
      $('#dataInicial').focus();
      this.setState({
        start: '',
        horaInicio: ''
      });
      alertBox(this.props.t("errors.emptyStartDate"), this.props.t("labels.warning", { ns: "common" }), 'warning');
    } else this.setState({ end: end });
  }

  handlePushState() {
    this.sleep(500).then(() => {
      let url = '?';
      if (this.state.equipment) {
        url = url + '&equipamentoId=' + this.state.equipamentoEscolhido;
      }
      if (this.state.textEquipamento) {
        url = url + '&equipamentoNome=' + this.state.textEquipamento;
      }
      if (this.state.modo) {
        url = url + '&modo=' + this.state.modo;
      }

      if (this.state.modo == 6) {
        if (this.state.start) {
          url = url + '&dataInicio=' + moment(this.state.start.format('YYYY-MM-DD') + ' ' + this.state.horaInicio).utcOffset(0, true).format();
        }
        if (this.state.end) {
          url = url + '&dataFim=' + moment(this.state.end.format('YYYY-MM-DD') + ' ' + this.state.horaFim).utcOffset(0, true).format();
        }
      }

      window.history.pushState(undefined, undefined, url);
    });
  }

  render() {
    const { dataInicio, dataFim, modo, horaInicioTurno, horaFimTurno, turnoPersonalizado, equipamentoEscolhido } = this.state;

    let turnos = [...this.state.turnos, { id: 0, name: this.props.t("labels.customize", { ns: 'common' }) }]

    var detalhamentoGranularidade = true;

    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      let sessions = JSON.parse(JSON.stringify(this.store.sessions));
      let grupo_equipamento = this.state.grupoEquipamentos.map(g => {
        return { value: g.uuid, text: g.nome }
      });

      let equipments = grupo_equipamento.concat([].concat.apply([],
        [].concat.apply([],
          sessions.map(s =>
            s.stages.map(ss => ss.equipments)))).reduce((prev, elem) => {
              if (!prev.map(p => p.value).includes(elem.id)) prev.push({ value: elem.id, text: elem.name });
              return prev
            }, []).sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

      let verificacaoGranularidadeDiaria = this.state.diferencaDias <= 61;
      let verificacaoGranularidadeSemanal = this.state.diferencaDias <= 180;

      const buttonsGranularidade = (
        <div>
          <RaisedButton
            disabled={!verificacaoGranularidadeDiaria}
            onClick={() =>
              this.state.granularidade === 1 ? ''
                : this.setState({ granularidade: 1 },
                  () => this.generateOee(detalhamentoGranularidade))}
            backgroundColor={this.state.granularidade === 1 ? '#3D5D89' : '#FFF'}
            labelColor={this.state.granularidade === 1 ? '#FFF' : '#676767'}
            label={this.props.t("date.period.daily", { ns: "common" })}
            style={{ height: '20px', minWidth: '72px', marginLeft: '3px', marginRight: '3px', marginTop: '2px' }}
            buttonStyle={{ width: '72px' }}
            labelStyle={{ lineHeight: '22px', fontSize: '11px', padding: '0px' }}
          />
          <RaisedButton
            disabled={!verificacaoGranularidadeSemanal || this.state.turnoPersonalizado.length != 0}
            onClick={() =>
              this.state.granularidade === 2 ? ''
                : this.setState({ granularidade: 2 },
                  () => this.generateOee(detalhamentoGranularidade))}
            backgroundColor={this.state.granularidade === 2 && this.state.turnoPersonalizado.length === 0 ? '#3D5D89' : '#FFF'}
            labelColor={this.state.granularidade === 2 && this.state.turnoPersonalizado.length === 0 ? '#FFF' : '#676767'}
            label={this.props.t("date.period.weekly", { ns: "common" })}
            style={{ height: '20px', minWidth: '72px', marginLeft: '3px', marginRight: '3px', marginTop: '2px' }}
            buttonStyle={{ width: '72px' }}
            labelStyle={{ lineHeight: '22px', fontSize: '11px', padding: '0px' }}
          />
          <RaisedButton
            disabled={this.state.turnoPersonalizado.length != 0}
            onClick={() => this.state.granularidade === 3 ? ''
              : this.setState({
                granularidade: 3
              }, () => this.generateOee(detalhamentoGranularidade))}
            backgroundColor={this.state.granularidade === 3 ? '#3D5D89' : '#FFF'}
            labelColor={this.state.granularidade === 3 ? '#FFF' : '#676767'}
            label={this.props.t("date.period.monthly", { ns: "common" })}
            style={{ height: '20px', minWidth: '72px', marginLeft: '3px', marginRight: '3px', marginTop: '2px' }}
            buttonStyle={{ width: '72px' }}
            labelStyle={{ lineHeight: '22px', fontSize: '11px', padding: '0px' }}
          />
          <RaisedButton
            disabled={this.state.turnoPersonalizado.length != 0}
            onClick={() => this.state.granularidade === 5 ? ''
              : this.setState({
                granularidade: 5
              }, () => this.generateOee(detalhamentoGranularidade))}
            backgroundColor={this.state.granularidade === 5 ? '#3D5D89' : '#FFF'}
            labelColor={this.state.granularidade === 5 ? '#FFF' : '#676767'}
            label={this.props.t("date.period.anually", { ns: "common" })}
            style={{ height: '20px', minWidth: '72px', marginLeft: '3px', marginRight: '3px', marginTop: '2px' }}
            buttonStyle={{ width: '72px' }}
            labelStyle={{ lineHeight: '22px', fontSize: '11px', padding: '0px' }}
          />
        </div>
      );

      return (
        <Container modal={this.state.modal} contentModal={this.contentModal} sectionStyle={{ width: '100%' }}>
          <Card>
            <div className={css.input_container}>
              <div className={`${css.row_config_1}`}>
                <div className={css.equipament_input}>
                  <AutoComplete
                    textFieldStyle={{ marginTop: '-10px' }}
                    fullWidth={true}
                    errorText={this.state.erro_equipamento}
                    underlineStyle={this.state.erroUnidadeIndustrial ? { borderColor: 'red' } : undefined}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B' }}
                    listStyle={{ width: '100%' }}
                    floatingLabelText={this.props.t("labels.equipment")}
                    title={this.props.t("labels.equipment")}
                    searchText={this.state.searchTextEquipamento == '' ? this.state.textEquipamento : this.state.searchTextEquipamento}
                    onUpdateInput={this.setEquipamento.bind(this)}
                    filter={AutoComplete.fuzzyFilter}
                    dataSource={equipments !== [] ? equipments : null}
                    onClick={() => this.setState({ textEquipamento: '', equipamentoEscolhido: [], searchTextEquipamento: '' })}
                    openOnFocus={true}
                    popoverProps={{ className: 'autocomplete' }} />
                </div>
                <div className={css.periodo_input}>
                  <SelectField
                    style={{ margin: '-8px 0 0 5px' }}
                    hintText={this.props.t("labels.period")}
                    fullWidth={true}
                    errorText={this.state.erro_modo}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    underlineStyle={this.state.erroPeriodo ? { borderColor: 'red' } : undefined}
                    floatingLabelText={this.props.t("labels.period")}
                    floatingLabelStyle={{ color: '#5B5B5B' }}
                    value={this.state.modo}
                    onChange={(...props) => {
                      this.setState({
                        modo: props[2],
                        end: null,
                        diferencaDias: props[2] == 3 ? 360 : 0
                      });
                      this.handlePushState();
                    }}>
                    <MenuItem value={1} primaryText={this.props.t("date.calendar.today", { ns: "common" })} />
                    <MenuItem value={2} primaryText={this.props.t("date.calendar.yesterday", { ns: "common" })} />
                    <MenuItem value={3} primaryText={this.props.t("date.calendar.yearToDate", { ns: "common" })} />
                    <MenuItem value={4} primaryText={this.props.t("date.calendar.monthToDate", { ns: "common" })} />
                    <MenuItem value={5} primaryText={this.props.t("date.labels.daysWithCount_other", { ns: "common", count: 15 })} />
                    <MenuItem value={6} primaryText={this.props.t("labels.customize", { ns: "common" })} />
                  </SelectField>
                  {this.state.erroPeriodo ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '-1' }} ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> : ''}
                </div>
                {
                  this.state.modo == 6
                    ?
                    <div className={`${css.input_data} flex flex-wrap`} style={{ marginLeft: '10px' }}>
                      <NDatePickerInput className={css.data_inicio_input}
                        floatingLabelStyle={{ marginBottom: 0 }}
                        style={{ paddingTop: '1px', paddingLeft: '5px', width: '115px' }}
                        require={this.state.erro_inicio}
                        id='dataInicial'
                        value={this.state.start}
                        autoComplete={"off"}
                        onChange={date => { this.setState({ start: date, horaInicio: this.state.horaInicio || '00:00:00' }); this.handlePushState(); }}
                        onChangeRaw={(event, value) => { this.setState({ start: value, horaInicio: this.state.horaInicio || '00:00:00' }); this.handlePushState(); }}
                        onBlur={event => this._valiDateStart(event.target.value)} maxDate={moment()} label={this.props.t("labels.startDate")} />

                      <NInputTime
                        require={true}
                        className={`${css.input_time} ${css.hora_inicio_input}`}
                        type='time' id='horaInicial' label={this.props.t("labels.startTime")} step={1}
                        value={this.state.horaInicio}
                        errorText={this.state.erroHoraInicio}
                        errorStyle={{ bottom: '10px' }}
                        onChange={(event, value) => { this.setState({ horaInicio: value }); this.handlePushState(); }}
                        inputStyle={{ marginTop: '10px' }}
                        style={{
                          width: '115px',
                          marginRight: '5px',
                          height: '52px',
                          padding: '5px'
                        }}
                        floatingLabelStyle={{
                          color: '#5B5B5B',
                          background: '#fff',
                          textTransform: 'none',
                          top: '28px',
                          fontSize: '1.04em'
                        }}
                      />

                      <NDatePickerInput className={css.data_final_input}
                        floatingLabelStyle={{ marginBottom: 0 }}
                        style={{ paddingTop: '1px', paddingLeft: '5px', width: '115px' }}
                        require={this.state.erro_fim}
                        id='dataFinal'
                        value={this.state.end}
                        minDate={this.state.start}
                        autoComplete={"off"}
                        onChange={date => { this.handleTime(date); this.setState({ horaFim: this.state.horaFim || '23:59:59' }); this.handlePushState(); }}
                        onChangeRaw={(event, value) => { this.setState({ end: value, horaFim: this.state.horaFim || '23:59:59' }); this.handlePushState(); }}
                        onBlur={event => this._valiDateEnd(event.target.value)}
                        maxDate={moment()} label={this.props.t("labels.endDate")} />

                      <NInputTime
                        require={true}
                        className={`${css.input_time} ${css.hora_fim_input}`}
                        type='time'
                        id='horaFim'
                        label={this.props.t("labels.endTime")}
                        step={1}
                        value={this.state.horaFim}
                        errorText={this.state.erroHoraFim}
                        onChange={(event, value) => { this.setState({ horaFim: value }); this.handlePushState(); }}
                        errorStyle={{ bottom: '10px' }}
                        inputStyle={{ marginTop: '10px' }}
                        style={{
                          width: '115px',
                          marginRight: '5px',
                          height: '52px',
                          padding: '5px',
                        }}
                        floatingLabelStyle={{
                          color: '#5B5B5B',
                          background: '#fff',
                          textTransform: 'none',
                          top: '28px',
                          fontSize: '1.04em'
                        }}
                      />
                    </div>
                    : null
                }
                {
                  this.state.modo == 6
                    ?
                    <div className={css.turno_wrapper}>
                      <div className={`${css.input_turno} ${this.state.diferencaDias > 60 ? css.mensal : ''}`} style={{ marginTop: '13px' }}>
                        <div style={{ width: '100%' }}>
                          <MultiSelect
                            isMulti
                            value={this.state.turnoPersonalizado}
                            options={turnos}
                            allowSelectAll={false}
                            placeholder={this.props.t("labels.shift")}
                            onGetOptionLabel={(option) => option.name}
                            onGetOptionValue={(option) => option.id}
                            onSelectOption={(e) => this.setState({ turnoPersonalizado: e })}
                          />
                        </div>
                      </div>
                      {
                        this.state.turnoPersonalizado.map(c => c.id).includes(0)
                          ?
                          <div className={css.turno_personalizado_wrapper} style={{ display: 'flex', marginLeft: '20px' }}>
                            <NInputTime className={`${css.input_time} ${css.turno_hora_inicio}`} type='time' id='horaInicial' label='Hora Início' style={{ width: '80px', marginRight: '5px', height: '51px', padding: '5px' }}
                              value={this.state.horaInicioTurno} onChange={(event, value) => this.setState({ horaInicioTurno: value })}
                              floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'none', top: '28px' }} errorText={this.state.erroHoraInicioTurno} />

                            <NInputTime className={`${css.input_time} ${css.turno_hora_fim}`} type='time' id='horaFim' label='Hora Fim' style={{ width: '80px', marginRight: '5px', height: '51px', padding: '5px' }}
                              value={this.state.horaFimTurno} onChange={(event, value) => this.setState({ horaFimTurno: value })}
                              floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'none', top: '28px' }} errorText={this.state.erroHoraFimTurno} />
                          </div>
                          : null
                      }
                    </div>
                    : null
                }
                <div className={css.btn_generate}>
                  <RaisedButton onClick={() => this.generateOee()} backgroundColor='#2D4F7F' id={'gerarOee'}
                    disabled={(this.oee && this.oee.state?.loadingData) || (this.state.modo == 6 ? (this.state.end == '' && this.state.horaFim == 0) ? true : false : false)}
                    buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '1.5em', marginTop: '0.6em' }} labelColor='#FFF' label={this.props.t("labels.generate")} />
                </div>

              </div>
              <div className="flex-row flex flex-align-center flex-just-end">
                <div className={`${css.d_none_mobile} inline-flex ${css.action_buttons}`}>
                  <div>
                    <Toggle
                      label="Auto"
                      labelStyle={{ fontSize: '16px' }}
                      onToggle={() => this.atualizarAutomatico()}
                      toggled={this.state.atualizarAutomaticamente}
                      thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                      trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                      className={'flex'} />
                  </div>
                  <i className='material-icons' style={{ marginLeft: '20px', cursor: 'pointer' }} title={this.props.t("labels.refresh")}
                    onClick={() => this.generateOee()}>
                    refresh
                  </i>

                </div>
              </div>
            </div>
          </Card>
          {
            <div>
              <br />
              <PainelOEE
                openModalApontamento={this.openModalApontamento}
                openModal={this.openModal}
                closeModal={this.closeModal}
                endpoints={this.endpoints}
                ref={ref => (this.oee = ref)}
                equipamento={equipamentoEscolhido}
                modo={modo}
                data_inicio={dataInicio}
                data_fim={dataFim}
                diferencaDias={this.state.diferencaDias}
                granularidade={this.state.granularidade}
                turnos={turnoPersonalizado}
                inicio_turno={horaInicioTurno}
                fim_turno={horaFimTurno}
                flagSetupProporcional={this.state.flagSetupProporcional}
                updateFlagSetupProporcional={this.updateFlagSetupProporcional}
                grupoEquipamentoEscolhido={this.state.grupoEquipamentoEscolhido}
                openModalPareto={this.openModalPareto}
                buttonsGranularidade={buttonsGranularidade}
                autoRefresh={this.state.atualizarAutomaticamente}
              />
            </div>
          }
        </Container>
      );
    }
  }
}

export default withTranslation("oee")(OeeContainer);