import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styles from './route.scss';
import ProductStages from './ProductStages';

@observer
export default class ListStages extends Component {
  constructor() {
    super();
    this.onScroll = this.onScroll.bind(this);
  }

  styleProductsList() {
    let metrics = [];
    Object.keys(this.refs).map(r => {
      if(r != 'list_products'){
        //Pego o height da caixa e adiciono 1px por causa da borda
        metrics.push(this.refs[r].refs.metric.clientHeight + 1);
      }
    });
    
    this.props.styleProductsList(metrics);
  }

  componentDidMount() {
    this.styleProductsList();
  }

  componentDidUpdate() {
    this.styleProductsList();
  }

  onScroll() {
    this.props.handleScroll(this.refs.list_products.scrollTop);
  }

  render() {
    let { products, session, stages, handleModal } = this.props;
    let list = [];

    products.map((product, i) => {
      let routes = product.routes.filter(route => route.fk_id_sessions == session && (route.TimeMachine != undefined && route.TimeMachine.DataFim == null));
      if (routes.length > 0) {
        list.push(<ProductStages t={this.props.t} ref={product.id} key={product.id} product={product} session={session} stages={stages} handleModal={handleModal} />);
      }
    });
    
    return (
      <div ref="list_products" onScroll={this.onScroll} className={styles.list_products}>
        {list}
      </div>
    );
  }
}