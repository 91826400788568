import React, { useState } from 'react';
import MonthYearPicker from 'components/MonthYearPicker';
import moment from 'moment';
import './monthYearRangePicker.scss'

const MonthYearRangePicker = ({ startDate, endDate, onRangeChange, minDate, maxDate, disableAfterMaxDate  }) => {
  const [startMonth, setStartMonth] = useState(startDate || moment().subtract(1, 'month').startOf('month').toDate());
  const [endMonth, setEndMonth] = useState(endDate || moment().endOf('month').toDate());

  const handleStartMonthChange = (newDate) => {
    newDate = moment(newDate).startOf('month').toDate();

    if (maxDate && moment(newDate).isAfter(maxDate, 'month')) {
      newDate = moment(maxDate).toDate();
    }
    if (newDate > endMonth) {
      setEndMonth(newDate);
    }
    setStartMonth(newDate);
    onRangeChange({ start: newDate, end: endMonth });
  };

  const handleEndMonthChange = (newDate) => {
    newDate = moment(newDate).endOf('month').toDate();
    
    if (maxDate && moment(newDate).isAfter(maxDate, 'month')) {
      newDate = moment(maxDate).toDate();
    }
    if (newDate < startMonth) {
      setStartMonth(newDate);
    }
    setEndMonth(newDate);
    onRangeChange({ start: startMonth, end: newDate });
  };

  const disabledStartMonths = (date) => {
    return maxDate && moment(date).isAfter(maxDate, 'month');
  };

  const disabledEndMonths = (date) => {
    return maxDate && moment(date).isAfter(maxDate, 'month');
  };

  const disabledMonths = (date) => {
    const isAfterMaxDate = maxDate && date.isAfter(maxDate, 'month');
    const isAfterCurrentMonth = disableAfterMaxDate && date.isAfter(moment().endOf('month'));

    return isAfterMaxDate || isAfterCurrentMonth;
  };

  return (
    <div className='month-year-range-picker'>
      <MonthYearPicker label="Data início" value={startMonth} onChange={handleStartMonthChange} minDate={minDate} />
      <MonthYearPicker label="Data fim" value={endMonth} onChange={handleEndMonthChange} minDate={startMonth} maxDate={maxDate} disabledMonths={disabledMonths} />
    </div>
  );
};

export default MonthYearRangePicker;