import { observable, computed, action } from "mobx";

class Modal {

    @observable isopen = false;
    @observable isclose = true;
    @observable component = null;
    @observable values = [];
    @observable disabled = false;

    @action
    open = (content, values) => {
        this.setIsOpen(true);
        this.setIsClose(false);
        this.setComponent(content);
        this.setValues(values);
    }
    @action
    close = () => {
        this.setIsClose(true);
        this.setIsOpen(false);
        this.setComponent(null);
        this.setValues([]);
    }

    
    @action
    setIsOpen = isopen => {
        this.isopen = isopen;
    }
    @action
    setIsClose = isclose => {
        this.isclose = isclose;
    }
    @action
    setComponent = component => {
        this.component = component;
    }

    @action
    setValues = values => {
        this.values = values;
    }

    @action
    setDisabled = disabled => {
        this.disabled = disabled;
    }

    @computed get Isopen(){
        return this.isopen;
    }
    @computed get Isclose(){
        return this.isclose;
    }
    @computed get Component(){
        return this.component;
    }
    @computed get Values(){
        return this.values;
    }

    @computed get Disabled(){
        return this.disabled;
    }
    
}

export default Modal