import React, { Component } from 'react';
import { NButton } from '../../../NForm';
import { observer, inject } from 'mobx-react';


@inject('store')
@observer
class DeleteLastVigenciaRota extends Component{
    constructor(props){
        super(props);
        
        this.alertVigenciaStore = this.props.store.alertLastVigenciaRotas;
        this.modalStore = this.props.store.modalCommon;
        this.routeContainerStore = this.props.store.routeContainerStore;

        this.itemIndex = this.modalStore.Values.slice()[0].itemIndex;
        this.commonState = this.modalStore.Values.slice()[0].commonState.slice();


    }

    render(){
        return(
            <div>
                <NButton type="primary" onClick={() => {
                    this.alertVigenciaStore.Yes(this.commonState[this.itemIndex])
                    this.modalStore.close()
                    this.routeContainerStore.setAtualizarRotas(true)
                    }}>
                    Sim
                </NButton>
                <NButton onClick={() => this.alertVigenciaStore.CustomNoFunction()}>
                    Não
                </NButton>
            </div>
        )
    }
}

export default DeleteLastVigenciaRota