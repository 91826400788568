import React, { Component } from 'react';
import { Paper } from 'material-ui';
import moment from 'moment';

import styles from './style.scss';
import utils from '../../../utils/Utils';
import { withTranslation } from 'react-i18next';

class DetalheEquipamento extends Component {

  render() {
    let {detalhe} = this.props;
    let iconesClasses = [];

    for (var i = 0; i < 4; i++) {
      if(detalhe.classes !== null) {
        if(detalhe.classes[i] !== undefined) {
          switch (detalhe.classes[i])
          {
            case 3:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/manutencao.svg)'}} title={this.props.t("labels.maintenance")}></span>);
            break;
            case 13:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/setup.svg)'}} title={this.props.t("labels.setup")}></span>);
            break;
            case 124:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/reprocesso.svg)'}} title={this.props.t("labels.reprocess")}></span>);
            break;
            case 122:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/segregacao.svg)'}} title={this.props.t("labels.segregation")}></span>);
            break;
            case 120:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/interrupcaoLote.svg)'}} title={this.props.t("labels.batchInterruption", { primaryReference: utils.getReferenciaLoteOrdem().toLowerCase() })}></span>);
            break;
            case 30:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/equipamentoAlternativo.svg)'}} title={this.props.t("labels.alternativeEquipment")}></span>);
            break;
            case 7:
              iconesClasses.push(<span style={{background:'url(../../../../assets/icones-detalhamento-lote/parada.svg)'}} title={this.props.t("labels.stop")}></span>);
            break;
          }
          
        }else{
          iconesClasses.push(<span></span>);
        }
      }else{
        iconesClasses.push(<span></span>);
      }
    }

    let isEmpty = function(str) {
      return (!str || str.length === 0 );
    };

    return (
      <div style={{width: '14em'}}>
          {
            detalhe.equipamentos.map((equipamento, j) => {
              return(
              <div className={styles.infoEquipamentoNome}>
                {equipamento.nome}
                {
                  !isEmpty(detalhe.tipo_producao)
                  ? <label style={{marginTop: '5px', fontSize: '12px'}}>{isEmpty(detalhe.tipo_producao) ? '' : detalhe.tipo_producao}</label>
                  : null
                }
              </div>
              );
            })
          }
        <div className={styles.infoEquipamento}>
          {
          (detalhe.passou && detalhe.situacao !== 'Espera') || (detalhe.passou && detalhe.processo_real > 0)
          ?
            <div className={styles.badges}>
              {
                iconesClasses
              }
            </div>
          :
            <div className={styles.badges}>
              <Paper zDepth={2} style={{width: '100%', padding: '5px 13px 5px 13px', textAlign: 'center'}}>
                <i style={{fontSize: '10px'}}>{this.props.t("labels.advanceForecast")}</i>
                <br/>
                <i style={{fontSize: '14px'}}>{moment(detalhe.previsao_avanco).format('DD/MM/YYYY HH:mm')}</i>
              </Paper>
            </div>
          }
        </div>
        <table id={styles.table} className='mui-table mui-table--bordered'>
            <thead>
              <tr> 
                <td>{this.props.t("labels.phase")}</td>
                <td>{this.props.t("labels.real")}</td>
                <td>{this.props.t("labels.default")}</td>
              </tr>
            </thead>
          <tbody className='box_items'>
            <tr style={{backgroundColor: '#F3F3F3'}}>
              <td>{this.props.t("labels.wait")}:</td>
              {detalhe.espera_real == 0 && !detalhe.passou ? 
                <td>-</td>
                :
                <td style={detalhe.cor === 'vermelho' ? {color: '#B70909'} : null}>{detalhe.espera_real}h</td>
              }

              {
                  <td style={detalhe.cor === 'vermelho' ? {color: '#B70909'} : null}>{detalhe.espera_padrao}h</td> 
              }
            </tr>

            <tr style={{backgroundColor: '#DFDFDF'}}>
              <td>{this.props.t("labels.process")}: </td>
              {detalhe.processo_real == 0 && !detalhe.passou ? 
                <td>-</td>
                :
                <td style={detalhe.cor === 'vermelho' ? {color: '#B70909'} : null}>{detalhe.processo_real}h</td>
              }

              {
                <td style={detalhe.cor === 'vermelho' ? {color: '#B70909'} : null}>{detalhe.processo_padrao}h</td>
              }
            </tr>

            <tr style={{backgroundColor: '#F3F3F3'}}>
              <td>{this.props.t("labels.accumulated")}:</td>
              {detalhe.acumulado_real == 0 && !detalhe.passou ? 
                <td>-</td>
                :
                <td style={detalhe.cor === 'vermelho' ? {color: '#B70909'} : null}>{detalhe.acumulado_real}h</td>
              }

              {detalhe.acumulado_padrao == 0 && !detalhe.passou ? 
                <td>-</td>
                :
                <td style={detalhe.cor === 'vermelho' ? {color: '#B70909'} : null}>{detalhe.acumulado_padrao}h</td>
              }
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation("flowView")(DetalheEquipamento);