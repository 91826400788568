import React from 'react';
import IconArrowRight from '../../../assets/icons/IconArrowRight';
import styles from './style.scss';

const HorizontalBarParetoChart = ({ data, colorBar, showIconLegend, onClickBar }) => {
  const firstPercentage = data && data.length ? data[0].porcentagem : 1;

  return (
    <div>
      <div className={styles.chart_container} style={{overflow: 'hidden'}}>
        {data.map((item, i) => (
          <div key={i} className={`${styles.bar_container} ${item.ativo ? styles.bar_active : ''}`} onClick={() => onClickBar(item)}>
            <div className={styles.box_name}>
              <div className='tooltip'>
                <span className={styles.bar_name}>{item.evento}</span>
                <div
                  className='tooltiptext'
                  style={{ width: `${Math.min(item.evento.length * 6, 170)}px`, top: 'auto' }}
                >
                  {item.evento}
                </div>
              </div>
            </div>

            <div className={styles.bar_box}>
              <div
                style={{
                  width: `${i === 0 ? 70 : (item.porcentagem / firstPercentage) * 70}%`,
                  background: colorBar || 'rgb(43,70,102)',
                  background: `linear-gradient(23deg, ${ colorBar || '#2b4666'} 0%, #698bb2 100%)`
                }}
                className={styles.bar}
              />
              <div className={styles.bar_legend}>
                {item.horas}h | {item.porcentagem}%
                {showIconLegend ? (
                  <IconArrowRight
                    color={item.temEstratificacao ? '#5B5B5B' : '#a5a5a5'}
                  />
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalBarParetoChart;
