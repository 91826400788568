import Dispatcher from './Dispatcher';
import Store from './Store';
import 'whatwg-fetch';
import { request } from './request';
import createBrowserHistory from 'history/createBrowserHistory';

const history = createBrowserHistory({ forceRefresh: true });

export default class GenericActions {
  execute(method, target, model, obj, modifier) {

    modifier = modifier || (data => data);

    function status(response) {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.text());
      } else {
        return Promise.reject(response.text());
      }
    }

    let send = {
      method: method == 'csv' ? 'post' : method,
      headers: {
        'Accept': method == 'csv' ? 'text/csv' : 'application/json',
        'Content-Type': method == 'csv' ? 'text/csv' : 'application/json',
        'X-Access-Token': Store.getToken()
      }
    };

    if (method === 'post' || method === 'put') {
      send.body = JSON.stringify(obj);
    }

    request(target, send)
      .then(status)
      .then((data) => {
        if (model && method == 'get') {
          Dispatcher.dispatch({
            type: method,
            model: model,
            data: method == 'csv' ? data : modifier(JSON.parse(data))
          });
        }
      }).catch((err) => {
        if (typeof err === 'object' && err.then instanceof Function) {
          err.then((result) => {
            Dispatcher.dispatch({
              model: 'errors',
              data: result
            });
          });
        } else {
          Dispatcher.dispatch({
            model: 'errors',
            data: err
          });
        }
      });
  }

  receive(target, callback) {
    function status(response) {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.text());
      } else {
        return Promise.reject(response.text());
      }
    }

    let send = {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': Store.getToken()
      }
    };

    request(target, send)
      .then(status)
      .then((data) => {
        callback(JSON.parse(data));
      }).catch((err) => {
        if (typeof err === 'object' && err.then instanceof Function) {
          err.then((result) => {
            callback(modifier(result))
          });
        } else {
          callback(err);
        }
      });
  }

  logout(target) {

    request(target)
      .then(function (response) {
        if (response.status >= 200 && response.status <= 300) {
          let i = localStorage.length;
          while (i--) {
            var key = localStorage.key(i);
            localStorage.removeItem(key);
          }

          history.push('/');

          window.delighted?.reset();
        }
      });
  }
}
