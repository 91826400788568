import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem, SubMenu } from 'react-contextmenu';
import { Scrollbars } from 'react-custom-scrollbars';
import trashImg from '../assets/trash.png';
import Actions from '../common/Actions';
import Button from '../common/Button';
import { APIEndpoints } from '../common/Constants';
import Container from '../common/Container';
import { FormInput } from '../common/Form';
import { default as MultiSelect } from 'components/AutoComplete';
import { requisicaoUnidadeIndustrial, respostaEquipamentos, respostaEtapas, respostaProdutos } from '../common/mappers';
import { alertBox } from '../common/NForm';
import Quest from '../common/Quest';
import { request, requestAll, requestRelatorio } from '../common/request';
import Notifier from '../utils/Notifier';
import Header from './Header';
import styles from './layout.scss';
import Utils from '../utils/Utils';
import i18n from 'utils/i18n';

@observer
export default class LayoutContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      sessionFirst: this.props.session,
      session: '',
      equipment: '',
      stage: '',
      editor: false,
      target: '',
      modal: false,
      name: this.props.session.name,
      modifiedStages: [],
      modifiedEquipments: [],
      affectedRoutes: [],
      stages: [],
      batchTypes: []
    };

    this.checkModifiedEquipments = this.checkModifiedEquipments.bind(this);
    this.checkModifiedStages = this.checkModifiedStages.bind(this);
    this.checkProducts = this.checkProducts.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleSteps = this.handleSteps.bind(this);
    this.handleBatchTypes = this.handleBatchTypes.bind(this);
    this.findRemovedEquipments = this.findRemovedEquipments.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this._addEquipmentToStage = this._addEquipmentToStage.bind(this);
    this._removeEquipmentFromStage = this._removeEquipmentFromStage.bind(this);
    this._moveEquimentBetweenStages = this._moveEquimentBetweenStages.bind(this);
    this._onDragToListEquipment = this._onDragToListEquipment.bind(this);
    this._onDragToEquipment = this._onDragToEquipment.bind(this);
    this._onDragToTitleStage = this._onDragToTitleStage.bind(this);
    this._ondragendToTrash = this._ondragendToTrash.bind(this);
    this._onOverToListEquipment = this._onOverToListEquipment.bind(this);
    this._onOverToTrash = this._onOverToTrash.bind(this);
    this._onOverToTitleStage = this._onOverToTitleStage.bind(this);
    this._onDropToTrash = this._onDropToTrash.bind(this);
    this._onOverToStage = this._onOverToStage.bind(this);
    this._onDropToStage = this._onDropToStage.bind(this);
    this._onDropToEquipment = this._onDropToEquipment.bind(this);
    this._onDropToTitleStage = this._onDropToTitleStage.bind(this);
    this._onDropToListEquipment = this._onDropToListEquipment.bind(this);
    this._scrollBottomAfterDrop = this._scrollBottomAfterDrop.bind(this);
    this.setDiv = this.setDiv.bind(this);
    this.scrollBottom = this.scrollBottom.bind(this);
  }

  _onDragToListEquipment(event) {
    event.dataTransfer.setData('equipmentLT', event.target.getAttribute('data-idx'));
  }

  _addEquipmentToStage(equipment, stage, event) {
    const elem = event.target;
    let { session } = this.state;
    let isEquipment;

    let jaInserido = session.stages.some(s => {
      return s.equipments.filter((e) => equipment == e.id)[0] !== undefined;
    });

    if (jaInserido) {
      this.showAlert(i18n.t('messages.equipmentAlreadyConfigured', { ns: 'sessions' }), i18n.t('equipment', { ns: 'sessions' }), 'error');
      return;
    }

    isEquipment = this.validacaoEtapaPermiteEquipamento(session, stage, equipment, isEquipment);

    this.checarRotaDescerScroll(session, elem);
  }

  checarRotaDescerScroll(session, elem) {
    this.setState({
      session, elem
    }, () => {
      this._scrollBottomAfterDrop(elem);
    });
  }

  validacaoEtapaPermiteEquipamento(session, stage, equipment, isEquipment) {
    session.stages.map(s => {
      if (s.id == stage) {
        if (s.ComEquipamento == true) {
          let newEquipment = this.store.equipments.filter((e) => equipment == e.id).map(e => { return { id: e.id, name: e.name }; })[0];
          isEquipment = s.equipments.filter((e) => equipment == e.id)[0];

          if (isEquipment == undefined) {
            s.equipments.push(newEquipment);
          }
        } else {
          this.showAlert(i18n.t('messages.stageNotAllowEquipment', { ns: 'sessions' }), i18n.t('equipment', { ns: 'sessions' }), 'error');
        }
      }

      return s;
    });

    return isEquipment;
  }

  _moveEquimentBetweenStages(equipment, currentStage, chosenStage) {
    let { session } = this.state;
    let isEquipment;
    let equipmentMove, staIdx;
    let etapaNaoAceitaEquip = session.stages.find(s => s.id == chosenStage && s.ComEquipamento == false);

    if (etapaNaoAceitaEquip) {
      return this.showAlert(i18n.t('messages.stageNotAllowEquipment', { ns: 'sessions' }), i18n.t('equipment', { ns: 'sessions' }), 'error');
    };

    session.stages.map((s, k) => {
      if (currentStage != chosenStage && chosenStage != undefined) {
        if (s.id == chosenStage) staIdx = k;
        if (s.id == currentStage) {
          equipmentMove = s.equipments.filter(f => f.id == equipment)[0];
        }
      }

      return s;
    });

    isEquipment = staIdx ? session.stages[staIdx].equipments.filter((e) => equipment == e.id)[0] : '';

    if (equipmentMove && !isEquipment) {
      session.stages[staIdx].equipments.push(equipmentMove);

      session.stages.map((s, k) => {
        if (currentStage != chosenStage && chosenStage != undefined) {
          if (s.id == currentStage) {
            s.equipments = s.equipments.filter(f => f.id != equipment);
          }
        }

        return s;
      });
    }

    this.setState({ session });
  }

  _removeEquipmentFromStage(equipment, stage) {
    let { session } = this.state;

    session.stages.map(s => {
      if (s.id == stage) {
        s.equipments = s.equipments.filter(f => f.id != equipment);
      }

      return s;
    });

    this.setState({
      session
    }, () => {
      this.checkRoutes();
    });
  }

  _onOverToListEquipment(event) {
    event.preventDefault();
  }

  _onDragToTitleStage(event) {
    event.dataTransfer.setData('title', event.target.getAttribute('data-idx'));
  }

  _onOverToTitleStage(event) {
    event.preventDefault();
  }

  _onDragToEquipment(event) {
    this.refs.trash.className = 'trash';
    event.dataTransfer.setData('equipmentRM', event.target.getAttribute('data-idx') + ',' + event.target.getAttribute('data-stage'));
  }

  _ondragendToTrash(event) {
    this.refs.trash.className = 'trash hidden';
  }

  _onOverToTrash(event) {
    event.preventDefault();
  }

  _onDropToTrash(event) {
    let idxEquipment = event.dataTransfer.getData('equipmentRM');

    if (idxEquipment !== undefined && idxEquipment != '') {
      let split = idxEquipment.split(',');
      let equipment = split[0];
      let stage = split[1];

      this._removeEquipmentFromStage(equipment, stage);
    }

    this.refs.trash.className = 'trash hidden';
  }

  _onOverToStage(event) {
    event.preventDefault();
  }

  _onDropToStage(event) {
    let idxEquipment = event.dataTransfer.getData('equipmentRM');
    let stageDrop = event.target.getAttribute('data-stagedrop');

    if (idxEquipment !== undefined && idxEquipment != '') {
      let split = idxEquipment.split(',');
      let equipment = split[0];
      let currentStage = split[1];

      this._moveEquimentBetweenStages(equipment, currentStage, stageDrop);
    }

    this.refs.trash.className = 'trash hidden';
  }

  _onDropToEquipment(event) {
    if (!event.target) {
      return event.preventDefault();
    }

    let idxEquipment = event.dataTransfer.getData('equipmentRM');
    let equipmentDrop = event.target.getAttribute('data-idx');

    let { session } = this.state;

    if (equipmentDrop && idxEquipment !== undefined && idxEquipment != '') {
      let split = idxEquipment.split(',');
      let equipment = split[0];
      let stage = split[1];

      let stageIndex = _.findIndex(session.stages, e => e.id === parseInt(stage));
      let equipmentsStage = session.stages[stageIndex].equipments;
      equipmentsStage = _.sortBy(equipmentsStage, ['ordem'], ['asc']);

      let newEquipments = this.reorder(equipmentsStage, parseInt(equipmentDrop), parseInt(equipment)).map((e, i) => ({ ...e, ordem: i }));

      session.stages[stageIndex].equipments = newEquipments;

      this.setState({
        session
      });

      this.refs.trash.className = 'trash hidden';
    }
  }

  reorder(arr, start, end) {
    let newArr = _.cloneDeepWith(arr);
    let newIndex = _.findIndex(newArr, e => e.id === start);
    let oldIndex = _.findIndex(newArr, e => e.id === end);

    if (newIndex >= newArr.length) {
      var index = newIndex - newArr.length + 1;
      while (index--) {
        newArr.push(undefined);
      }
    }
    newArr.splice(newIndex, 0, newArr.splice(oldIndex, 1)[0]);

    return newArr;
  };

  _onDropToTitleStage(event) {
    let { session } = this.state;
    let idxTitle = event.dataTransfer.getData('title');
    let stageDrop = event.target.getAttribute('data-idx');

    if (idxTitle !== undefined && idxTitle != '') {
      let stages = session.stages.map(s => s.id);
      let sfrom = stages.indexOf(parseInt(idxTitle));
      let sto = stages.indexOf(parseInt(stageDrop));
      session.stages.splice(sto, 0, session.stages.splice(sfrom, 1)[0]);

      this.setState({
        session
      });
    }
  }

  _onDropToListEquipment(event) {
    let idxEquipment = event.dataTransfer.getData('equipmentLT');
    let stageDrop = event.target.getAttribute('data-stagedrop');

    if (idxEquipment !== undefined && idxEquipment != '') {
      this._addEquipmentToStage(idxEquipment, stageDrop, event);
    }
  }

  _scrollBottomAfterDrop(elem) {
    let targetClass = 'box_list';
    elem = this.setDiv(elem, targetClass);

    this.scrollBottom(elem);
  }

  scrollBottom(elem) {
    if (elem) {
      elem.scrollBy(0, elem.scrollHeight);
    }
  }

  setDiv(elem, targetClass) {
    if (elem && elem.tagName == 'BODY') return undefined;

    if (elem) {
      if (elem.className && elem.className.includes(targetClass)) {
        return elem;
      }
      else {
        return this.setDiv(elem.parentElement, targetClass);
      }
    }

    return elem;
  }

  componentWillMount() {
    this.state.editor = this.props.editor;
    this.state.session = JSON.parse(JSON.stringify(this.props.session));

    this.setState({
      stages: this.state.session.stages,
      batchTypes: this.state.session.batchTypes
    });
  }

  checkRoutes() {
    if (this.checkModifiedEquipments() || this.checkModifiedStages()) {
      this.setState({
        modal: true
      });
    }
  }

  downloadAffectedRoutesReport() {
    let stages = this.state.modifiedStages;
    let equipments = this.state.modifiedEquipments;
    let session = this.state.session.id;
    let url = `${session}`;
    let data = {
      etapas: stages,
      equipamentos: equipments
    };

    requestRelatorio(`${this.endpoints.RELATORIO_ROTAS_AFETADAS}/${url}`, i18n.t('reportAffectedRoutes', { ns: 'sessions' }), data)
      .then(() => {
        // todo
      })
      .catch(() => {
        alertBox(i18n.t('messages.reportRoutesError', { ns: 'sessions' }), i18n.t('reportAffectedRoutes', { ns: 'sessions' }), 'error');
      });
  }

  contentModal() {
    let rotasAfetadas = _.uniq(this.state.affectedRoutes);
    let routesMsg = rotasAfetadas.length > 1 ? i18n.t('routes', { ns: 'sessions' }) : i18n.t('route', { ns: 'sessions' });

    let question = (
      <div>
        <h3 style={{ fontSize: 18, textAlign: 'center', margin: '20px 0' }}>{i18n.t('messages.routesImpact', { ns: 'sessions', routesCount: rotasAfetadas.length, routes: routesMsg })}</h3>
        <p style={{ marginVertical: 10 }}>{i18n.t('messages.confirmRoutesImpact', { ns: 'sessions' })}</p>
        <br />
        <p style={{ marginBottom: 20 }}>{i18n.t('messages.downloadAffectedRoutes', { ns: 'sessions' })}</p>
        <a onClick={() => this.downloadAffectedRoutesReport()} style={{ cursor: 'pointer' }}>
          <img src='../assets/download-button.svg' height="50" width="50" />
        </a>
      </div>
    );

    return <Quest action={this.onSave} handleModal={this.closeModal} width={500} height={300} showClose={false} question={question} simText={i18n.t('save', { ns: 'sessions' })} naoText={i18n.t('cancel', { ns: 'sessions' })} />;
  }

  handleName(event) {
    let { session } = this.state;

    session.name = event.target.value;

    this.setState(session);
  }

  handleSteps(arr) {
    let { session } = this.state;
    let stages = [];

    arr.map((a) => {
      let find = false;

      session.stages.map((ss) => {
        if (ss.id == a.id) {
          find = true;
          stages.push(ss);
        }
      });

      if (!find) {
        let stage = this.store.stages.filter((s) => s.id == a.id)[0];
        stage.equipments = [];
        stages.push(stage);
      }
    });

    session.stages = stages;

    this.setState({ session: session, stages: arr });
  }

  handleBatchTypes(arr) {
    let { session } = this.state;
    let batchTypes = [];

    arr.map((a) => {
      let find = false;

      session.batchTypes.map((ss) => {
        if (ss.id == a.id) {
          find = true;
          batchTypes.push(ss);
        }
      });

      if (!find) {
        let batchType = this.store.batchTypes.filter((s) => s.id == a.id)[0];
        batchTypes.push(batchType);
      }
    });

    session.batchTypes = batchTypes;

    this.setState({ session: session, batchTypes: arr });
  }

  checkProducts() {
    this.state.products = this.store.products.filter((p) =>
      p.fk_id_fields == this.state.session.fk_id_fields &&
      p.routes.length &&
      p.routes.find(r => r.fk_id_sessions == this.state.session.id));
    return this.state.products == 0;
  }

  checkModifiedStages() {
    var etapaNova = {};
    var resultado = false;

    this.state.session.stages.forEach(elm => {
      etapaNova[elm.id] = true;
    });

    var produtos = this.store.products.filter(p => p.routes[0] && p.routes[0].fk_id_sessions == this.state.session.id);

    for (var i = 0; i < produtos.length; i++) {
      if (this.etapasProduto(produtos[i].routes, etapaNova)) {
        resultado = true;
      }
    }

    return resultado;
  }

  etapasProduto(rota, etapasNovo) {
    var resultado = false;

    rota.forEach(elm => {
      if (!etapasNovo[elm.fk_id_stages] && !this.state.modifiedStages.includes(elm.fk_id_stages)) {
        this.setState(prevState => ({
          modifiedStages: [
            ...prevState.modifiedStages,
            elm.fk_id_stages
          ],
          affectedRoutes: [
            ...prevState.affectedRoutes,
            elm.RotasProdutosId
          ]
        }));
        resultado = true;
      }
    });
    return resultado;
  }

  checkModifiedEquipments() {
    var etapaEquipamento = {};
    var equipamentos = {};
    var resultado = false;

    this.state.session.stages.forEach(etapa => {
      etapa.equipments.forEach(equipamento => {
        equipamentos[equipamento.id] = true;
      });
      etapaEquipamento[etapa.id] = equipamentos;
      equipamentos = {};
    });

    var produtos = this.store.products.filter(p => p.routes[0] && p.routes[0].fk_id_sessions == this.state.session.id);

    for (var i = 0; i < produtos.length; i++) {
      if (this.findRemovedEquipments(produtos[i].routes, etapaEquipamento)) {
        resultado = true;
      }
    }

    return resultado;
  }

  validBatchType() {
    const { session } = this.state;

    return session?.batchTypes.length > 0;
  }

  findRemovedEquipments(rota, etapasNovo) {
    var resultado = false;
    rota.forEach((elm, i) => {
      if (!(etapasNovo[elm.fk_id_stages] && etapasNovo[elm.fk_id_stages][elm.fk_id_equipments])) {
        if (elm.fk_id_equipments && !this.state.modifiedEquipments.includes(elm.fk_id_equipments)) {
          this.setState(prevState => ({
            modifiedEquipments: [
              ...prevState.modifiedEquipments,
              elm.fk_id_equipments
            ],
            affectedRoutes: [
              ...prevState.affectedRoutes,
              elm.RotasProdutosId
            ]
          }));
          resultado = true;
        }
      }
    });
    return resultado;
  }

  onChange() {
    this.setState({
      editor: !this.state.editor
    });
  }

  onSave() {
    const { name } = this.state;

    if (!this.validBatchType()) {
      return alertBox(i18n.t('messages.batchTypeRequired', { ns: 'sessions', batch: Utils.getReferenciaLoteOrdem() }), i18n.t('messages.saveError', { ns: 'sessions' }), "error");
    }

    var data = this.state.session;
    var etapas = data.stages.map((d, i) => {
      d.equipments = d.equipments.map((eq, j) => ({
        ...eq
      }));
      d['ordem'] = i;
      return d;
    }
    );
    data.stages = etapas;
    data.name = data.name.trim();
    if (name) {
      request(this.endpoints.UNIDADE_INDUSTRIAL + `/${this.state.session.id}`, {
        method: 'put',
        body: JSON.stringify(requisicaoUnidadeIndustrial(data))
      })
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.setState({
              editor: false,
              modal: false
            });
            this.props.aposSalvar();
            alertBox(i18n.t('messages.editSuccess', { ns: 'sessions' }), i18n.t('industrialUnit', { ns: 'sessions' }), 'success');
          } else {
            alertBox(i18n.t('messages.editError', { ns: 'sessions' }), i18n.t('industrialUnit', { ns: 'sessions' }), 'error');
          }
        })
        .catch(() => {
          alertBox(i18n.t('messages.editError', { ns: 'sessions' }), i18n.t('industrialUnit', { ns: 'sessions' }), 'error');
        });
    }
  }

  confirmModal() {
    this.onSave();
  }

  closeModal() {
    this.setState({
      modal: false,
      session: JSON.parse(JSON.stringify(this.state.sessionFirst)),
      affectedRoutes: [],
      modifiedEquipments: [],
      modifiedStages: []
    });
  }

  showAlert(msg, title, type) {
    if (type == 'error') {
      Notifier.error(msg || 'Oops!', title || i18n.t('notification', { ns: 'sessions' }));
      setTimeout(3000);
    }

    if (type == 'success') {
      Notifier.success(msg || 'Oops!', title || i18n.t('notification', { ns: 'sessions' }));
      setTimeout(5000);
    }
  }

  render() {
    let { editor, session, modal } = this.state;

    return (
      <div>
        {modal ? <div className={'box_modal'}><div>{this.contentModal()}</div></div> : null}
        <div className={modal ? 'blur' : ''} style={modal ? { zIndex: 0, position: 'static' } : null}>
          <Header store={this.store} {...this.state} styleModal={{ width: '40%' }} handleModal={this.props.handleModal} />
          {editor ? <Controls
            store={this.store}
            {...this.state}
            handleName={this.handleName}
            handleSteps={this.handleSteps}
            handleBatchTypes={this.handleBatchTypes}
            onChange={this.onChange} /> : null}
          {
            session ?
              <div>
                <div className={styles.box_list}>
                  <div className={styles.list_equipaments}>
                    {editor ? <EquipmentsList store={this.store} stages={session.stages} editor={editor} field={session.fk_id_fields} addToStage={this._addEquipmentToStage} dragStart={this._onDragToListEquipment} /> : null}
                  </div>
                  {editor ? <div style={{ marginRight: '12.5em' }} /> : null}
                  <div className={styles.list}>
                    <ListStages stages={session.stages} editor={this.state.editor} onOverToTitleStage={this._onOverToTitleStage} onDragToTitleStage={this._onDragToTitleStage} onDropToTitleStage={this._onDropToTitleStage} />

                    <ul className={styles.list_itens}>
                      {session.stages.map((v, i) => <StageItem key={i} {...v}
                        ComEquipamento={v.ComEquipamento}
                        session={session}
                        editor={this.state.editor}
                        onDropToListEquipment={this._onDropToListEquipment}
                        onOverToListEquipment={this._onOverToListEquipment}
                        onDropEquipment={this._onDropToEquipment}
                        removeFromStage={this._removeEquipmentFromStage}
                        moveBetweenStages={this._moveEquimentBetweenStages}
                        onDragOverStage={this._onOverToStage}
                        onDropStage={this._onDropToStage}
                        ondragendToTrash={this._ondragendToTrash}
                        onDragToEquipment={this._onDragToEquipment}
                        equipment={this.state.equipment} />)}
                    </ul>
                    <div className="trash hidden" ref="trash">
                      <div style={{ bottom: '1vh', left: '50%' }}>
                        <img src={trashImg} onDragOver={this._onOverToTrash} onDrop={this._onDropToTrash} />
                      </div>
                    </div>
                  </div>
                </div>
                {
                  editor ?
                    <div style={{ textAlign: 'right' }}>
                      <Button button={{ type: 'round', className: 'button_primary', text: i18n.t('save', { ns: 'sessions' }), onClick: () => this.confirmModal() }} />
                      <Button button={{ type: 'round', text: i18n.t('cancel', { ns: 'sessions' }), onClick: () => this.props.handleModal('', '', false) }} />
                    </div>
                    : null
                }
              </div>
              : null
          }
        </div>
      </div>
    );
  }
}

class ListStages extends Component {
  render() {
    let { styleWidth, stages } = this.props;

    return (
      <ul className={styles.list_titles} style={styleWidth}>
        {stages.map((v, i) => <StageTitle key={v.id} id={v.id} name={v.name} {...this.props} />)}
      </ul>
    );
  }
}

@observer
class Controls extends Component {
  render() {
    let { stages, batchTypes } = this.props.store;
    let stageOpts = stages.map((f) => { return { id: f.id, name: f.name, ComEquipamento: f.ComEquipamento }; });
    let batchTypesOpts = batchTypes.map((f) => { return { id: f.id, name: f.name } });

    let stagesMulti =
      (
        <span>
          <input type='hidden' ref={'stages'} id={'stages'} value={this.props.stages} required={true} />
          <MultiSelect
            isMulti
            options={stageOpts}
            allowSelectAll={false}
            onGetOptionLabel={(option) => option.name}
            onGetOptionValue={(option) => option.id}
            onSelectOption={this.props.handleSteps}
            value={this.props.stages}
          />
        </span>
      );

    let batchesMulti =
      (
        <span>
          <input type='hidden' ref={'batchTypes'} id={'batchTypes'} value={this.props.batchTypes} required={true} />
          <MultiSelect
            isMulti
            options={batchTypesOpts}
            allowSelectAll={false}
            onGetOptionLabel={(option) => option.name}
            onGetOptionValue={(option) => option.id}
            onSelectOption={this.props.handleBatchTypes}
            value={this.props.batchTypes}
          />
        </span>
      );

    return (
      <div style={{ width: '100%', justifyContent: 'flex-start' }} className={'flex'}>
        <div style={{ width: '350px', marginRight: '20px' }}>
          <div style={{}}>
            <div>{`${i18n.t('name', { ns: 'sessions' })}:`}</div>
            <div><FormInput
              required
              id='name'
              ref='name'
              onChange={this.props.handleName}
              value={this.props.session.name} /></div>
          </div>

          <div style={{}}>
            <div>{`${i18n.t('batchTypes', { ns: 'sessions', batch: Utils.getReferenciaLoteOrdem() })}:`}</div>
            {batchesMulti}
          </div>
        </div>

        <div style={{ minWidth: '350px' }}>
          <div>{`${i18n.t('stages', { ns: 'sessions' })}:`}</div>
          {stagesMulti}
        </div>
      </div>
    );
  }
}

@observer
class EquipmentsList extends Component {
  constructor() {
    super();
    this.state = {
      equipments: '',
      allEquipments: '',
      value: '',
    };
    this.action = new Actions();
    this.endpoints = APIEndpoints;
    this.filter = this.filter.bind(this);
  }

  componentWillMount() {
    requestAll([
      {
        url: this.endpoints.ETAPA,
        modelo: 'stages',
        modificador: respostaEtapas,
        acao: true
      },
      {
        url: this.endpoints.PRODUTO + '?incluirRotas=true',
        modelo: 'products',
        modificador: respostaProdutos,
        acao: true
      },
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      }
    ], this);

    this.state.equipments = this.props.store.equipments.filter((e) => e.fields.map((f) => f.id).includes(this.props.field)).map(e => { return { id: e.id, name: e.name }; });
    this.state.allEquipments = this.state.equipments;
  }

  filter(event) {
    let equipments = this.state.equipments;
    if (event.target.value === '') {
      equipments = this.state.allEquipments;
    }
    else {
      let regex = new RegExp(event.target.value, 'i');
      equipments = equipments.filter(e => regex.test(e.name));
    }
    this.setState({ equipments: equipments, value: event.target.value });
  }

  render() {
    return (
      <div className={styles.li_equipments}>
        <FormInput
          id='search'
          ref='search'
          onChange={this.filter}
          value={this.state.value}
          placeholder={i18n.t('searchEquipment', { ns: 'sessions' })} />

        <Scrollbars autoHeightMax='calc(100vh - 263px)' autoHeight className={styles.equipments_scrollbar}>
          <ul>
            {
              this.state.equipments.map((v, i) => {
                var nomeTratado = v.name;
                if (window.innerWidth < 800) {
                  if (nomeTratado.length > 14) {
                    nomeTratado = nomeTratado.substr(0, 14 - 3) + '...';
                  }
                } else {
                  if (!(nomeTratado.indexOf(' ') != -1) && nomeTratado.length > 20) {
                    nomeTratado = nomeTratado.substr(0, 20 - 3) + '...';
                  }

                  if (nomeTratado.indexOf(' ') != -1 && nomeTratado.length > 30) {
                    nomeTratado = nomeTratado.substr(0, 30 - 3) + '...';
                  }
                }

                var stages = this.props.stages;
                return (
                  <div>
                    {
                      document.body.classList.contains('ie') ?

                        <ContextMenu id={v.id.toString()}>
                          <SubMenu title={i18n.t('sendTo', { ns: 'sessions' })}>
                            {
                              stages.map((stage, index) => {
                                return (
                                  <MenuItem onClick={() => this.props.addToStage(v.id, stage.id)}>{stage.name}</MenuItem>
                                );
                              })
                            }
                          </SubMenu>
                        </ContextMenu>

                        : ''
                    }

                    <ContextMenuTrigger id={v.id.toString()}>
                      <li className={styles.equipments_add} id={v.id} key={i} data-idx={v.id} onDragStart={this.props.dragStart} draggable={this.props.editor} title={nomeTratado.indexOf('...') != -1 ? v.name : null}>
                        <i className="fa fa-arrows-alt" style={{ marginRight: 5, fontSize: '0.8em' }} />
                        {nomeTratado}
                      </li>
                    </ContextMenuTrigger>
                  </div>
                );
              }
              )
            }
          </ul>
        </Scrollbars>
      </div>
    );
  }
}

class StageItem extends Component {
  constructor() {
    super();
    this.state = {};
    this.dragOver = this.dragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  dragOver(event) {
    this.props.onDragOverStage(event);
    this.props.onOverToListEquipment(event);
  }

  onDrop(event) {
    this.props.onDropStage(event);
    this.props.onDropToListEquipment(event);
  }

  render() {
    let { id, name, equipments, onDragToEquipment, ondragendToTrash, onDropEquipment, moveBetweenStages, removeFromStage, ComEquipamento, session } = this.props;
    const equipamentosOrdenados = _.sortBy(equipments, ['ordem'], ['asc']);

    return (
      <li style={ComEquipamento == true ? null : { background: '#f0f0f0', display: 'block' }} data-tag={true} onDragOver={this.dragOver} onDrop={this.onDrop} data-stagedrop={id} id={id}>
        {
          ComEquipamento
            ?
            equipamentosOrdenados.map((e, i) => <Equipment
              key={i} editor={this.props.editor}
              equipment={e} stage={id}
              stages={session.stages}
              dragStart={onDragToEquipment}
              dragEnd={ondragendToTrash}
              removeFromStage={removeFromStage}
              moveBetweenStages={moveBetweenStages}
              onDropEquipment={onDropEquipment} />)
            :
            <h3 style={{ textAlign: 'center' }} > {i18n.t('stageWithoutEquipment', { ns: 'sessions' })} </h3>
        }
      </li>
    );
  }
}

class Equipment extends Component {
  render() {
    let { stage, stages, editor, dragStart, dragEnd, dragOver, onDropEquipment } = this.props;
    let { id, name } = this.props.equipment;
    var nomeTratado = name;

    if (window.innerWidth < 800) {
      if (nomeTratado.length > 15) {
        nomeTratado = nomeTratado.substr(0, 15 - 3) + '...';
      }
    } else
      if (nomeTratado.indexOf(' ') != -1 && nomeTratado.length > 30) {
        nomeTratado = nomeTratado.substr(0, 30 - 3) + '...';
      }

    return (
      <ContextMenuTrigger id={`ctx-${id}`}>
        {
          document.body.classList.contains('ie') ?
            <ContextMenu id={`ctx-${id}`}>
              <MenuItem onClick={() => this.props.removeFromStage(id, stage)}>{i18n.t('removeFromStage', { ns: 'sessions' })}</MenuItem>
              <SubMenu title={i18n.t('moveTo', { ns: 'sessions' })}>
                {
                  stages.map((s, index) => {
                    return (
                      <MenuItem disabled={stage == s.id} onClick={() => this.props.moveBetweenStages(id, stage, s.id)}>{s.name}</MenuItem>
                    );
                  })
                }
              </SubMenu>
            </ContextMenu>
            : ''
        }

        <div className={styles.equipments} id={id} data-stage={stage} data-idx={id} onDragStart={dragStart} onDragEnd={dragEnd} onDragOver={this.dragOver} onDrop={onDropEquipment} draggable={editor} title={nomeTratado.indexOf('...') != -1 ? name : null}>
          {editor ? <i className="fa fa-arrows-alt" style={{ marginRight: 5, fontSize: '0.8em', color: '#FFF' }} /> : null}
          {nomeTratado}
        </div>
      </ContextMenuTrigger>
    );
  }
}

class StageTitle extends Component {
  render() {
    let { id, editor, onDragToTitleStage, onOverToTitleStage, onDropToTitleStage, name } = this.props;

    return (
      <li id={id} data-idx={id} onDragStart={onDragToTitleStage} onDragOver={onOverToTitleStage} onDrop={onDropToTitleStage} draggable={editor}>
        {editor ? <i className="fa fa-arrows-alt" style={{ marginRight: 5, fontSize: '0.8em' }} /> : null}
        {name}
      </li>
    );
  }
}