import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import MaintenanceList from './MaintenanceList';
import { request, requestAll } from '../../common/request';
import { respostaEventos, requisicaoEventos } from '../../common/mappers';
import { alertBox } from '../../common/NForm';
import MDSpinner from "react-md-spinner";
import { withTranslation } from 'react-i18next';

@observer
class MaintenancePage extends Container {

  constructor() {
    super();
    this.state = {
      loadingPage: true,
    };
    this.onCheck = this.onCheck.bind(this);
    this.buscarEventos = this.buscarEventos.bind(this);
  }

  componentDidMount() {
    this.buscarEventos();
  }

  buscarEventos() {
    requestAll([
      {
        url: this.endpoints.MANUTENCAO ,
        modelo: 'events',
        modificador: respostaEventos
      }
    ], this);
  }

  onCheck(item, type, checked) {
    let obj = item;
    obj[type] = checked;
    delete obj.group_events;
    request(this.endpoints.MANUTENCAO, {
      method: 'put',
      body: JSON.stringify(requisicaoEventos(obj))
    })
    .then(response => {
      if(response.status == 200 || response.status == 201) {
        return response;
      } else {
        alertBox(this.props.t("errors.cannotEdit"), this.props.t("title"), 'error');
      }
    })
    .then(() => {
      this.buscarEventos();
    })
    .catch(() => {
      alertBox(this.props.t("errors.cannotEdit"), this.props.t("title"), 'error');
    });
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{height: '94vh'}}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar styleModal={{ width: 350 }}>
          <MaintenanceList ref='list' store={this.store} showAlert={this.showAlert} onCheck={this.onCheck} />
        </Container>
      );
    }
  }
}

export default withTranslation("maintenance")(MaintenancePage);