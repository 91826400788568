import React from 'react';
import App from './common/App';
import { Provider } from "mobx-react";
import Store from './store/stores';
import Cookies from 'universal-cookie';
import { hasRole } from './helper/SecurityHelper';
import { Switch, Redirect, Route } from 'react-router';

import Home from './common/Home';
import SemPermissao from './paginaErro/SemPermissao'
import PredecessoresPage from './forms/predecessores/PredecessoresPage';
import FlowViewerContainer from './viewer/viewer/FlowViewerContainer';
import Hive from './forms/Hive';
import AvailabilityPage from './forms/availability/AvailabilityPage';
import MaintenancePage from './forms/maintenance/MaintenancePage';
import RouteContainer from './viewer/route/RouteContainer';
import LoginContainer from './login/LoginContainer';
import BatchesPage from './forms/batches/BatchesPage';
import FieldsPage from './forms/fields/FieldsPage';
import StagesPage from './forms/stages/StagesPage';
import EventsPage from './forms/events/EventsPage';
import Parameters from './forms/parameters/Parameters';
import ProductsPage from './forms/products/ProductsPage';
import SessionsPage from './forms/sessions/SessionsPage';
import ShiftsPage from './forms/shifts/ShiftsPage';
import Usuario from './forms/users/UsersPage';
import EquipmentsPage from './forms/equipments/EquipmentsPage';
import OeeContainerPage from './oee/OeeContainer';
import GroupEventPage from './forms/groupEvent/GroupEventPage';
import GroupEquipamentPage from './forms/groupEquipament/GroupEquipamentPage';
import MainNotePage from './forms/mainNote/MainNote';
import GroupUsersPage from './forms/groupUsers/GroupUsersPage';
import PainelFluxoPage from './components/painel-fluxo/';
import CustomFields from './forms/customFields/CustomFields';
import NotesRenew from './forms/notes/NotesRenew';
import MenuRelatorio from './menuRelatorio/MenuRelatorio';
import DataCollectionPage from './forms/dataCollection/DataCollectionPage';
import MonitorEquipamento from './components/MonitorEquipamento';
import Logout from './login/Logout';
import { logAcesso } from './helper/HistoricoHelper';
import MenuPaineis from './menuPaineis/MenuPaineis';
import { EquipmentsPanel } from 'forms/equipments/EquipmentsPanel';

const cookie = new Cookies();

const PrivateRoute = ({ component: Component, roles, modulo, ...rest }) => (
  <Route {...rest} render={props => {
    const token = localStorage.getItem('token');

    if (!token) {
      return <Redirect to={{
        pathname: '/',
        state: {
          from: cookie.set('location', encodeURIComponent(window.location.pathname + window.location.search))
        }
      }} />
    }

    if (!hasRole(roles)) {
      return <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }} />
    }

    if (modulo) {
      logAcesso(modulo, props.location.search);
    }

    if (window.movideskLogin) {
      const userName = localStorage.getItem('nome');
      const userEmail = localStorage.getItem('email');

      movideskLogin({
        name: userName,
        email: userEmail
      });
    }

    return <Component {...props} />
  }} />
);

// Foi injetado um modal no provider pois tem lugares que usam a store antiga e ocasiona erro
export default (
  <App>
    <Provider store={Store} modalStore={Store.modalCommon}>
      <Switch>
        <Route exact path='/' component={LoginContainer} />
        <Route exact path='/cogtive' component={LoginContainer} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/forbidden' component={SemPermissao} />
        <PrivateRoute path='/home' component={Home} modulo='HOME'/>
        <PrivateRoute path='/apontamento/manutencao' component={MainNotePage} roles='ROLE_MANUTENCAO_APONTAMENTOS_VISUALIZAR' modulo='MANUTENCAO_APONTAMENTOS' />
        <PrivateRoute path='/apontamento' component={NotesRenew} roles='ROLE_APONTAMENTOS_VISUALIZAR' modulo='APONTAMENTOS' />
        <PrivateRoute path='/area-produtiva' component={FieldsPage} roles='ROLE_AREAS_PRODUTIVAS_VISUALIZAR' modulo='AREAS_PRODUTIVAS' />
        <PrivateRoute path='/cadastro' component={Hive} modulo='CADASTRO'
          roles={[
            'ROLE_PRODUTOS_VISUALIZAR', // Produtos
            'ROLE_CAMPOS_PERSONALIZADOS_VISUALIZAR', // Campos Personalizados
            'ROLE_EQUIPAMENTOS_VISUALIZAR', // Equipamentos
            'ROLE_USUARIOS_VISUALIZAR', // Usuários
            'ROLE_GRUPO_VISUALIZAR', // Grupo de Usuários
            'ROLE_GRUPO_USUARIO_VISUALIZAR', // Grupo de Usuários
            'ROLE_ETAPAS_VISUALIZAR', // Etapas
            'ROLE_AREAS_PRODUTIVAS_VISUALIZAR', // Áreas Produtivas
            'ROLE_UNIDADES_INDUSTRIAIS_VISUALIZAR', // Unidade Industrial
            'ROLE_ROTAS_VISUALIZAR', // Rotas
            'ROLE_LINK_PI_PA_VISUALIZAR', // Vínculo de Lotes
            'ROLE_EVENTOS_VISUALIZAR', // Eventos
            'ROLE_LOTES_VISUALIZAR', // Lotes
            'ROLE_MANUTENCAO_VISUALIZAR', // Manutenção
            'ROLE_TURNOS_VISUALIZAR', // Turnos
            'ROLE_OPCOES_CALCULO_VISUALIZAR', // Opções de Cálculo
            'ROLE_GRUPOS_EVENTOS_VISUALIZAR', // Agrupador de Eventos
            'ROLE_GRUPO_EQUIPAMENTOS_VISUALIZAR', // Agrupador de Equipamentos
            'ROLE_NOTIFICACAO_EVENTO_VISUALIZAR', // Notificação Evento
            'ROLE_SETUP_GUIADO_VISUALIZAR', // Setup Guiado
          ]} />
        <PrivateRoute path='/painel-fluxo' component={PainelFluxoPage} roles='ROLE_PAINEL_FLUXO_VISUALIZAR' modulo='PAINEL_FLUXO' />
        <PrivateRoute path='/evento' component={EventsPage} roles='ROLE_EVENTOS_VISUALIZAR' modulo='EVENTOS' />
        <PrivateRoute path='/equipamento' component={EquipmentsPage} roles='ROLE_EQUIPAMENTOS_VISUALIZAR' modulo='EQUIPAMENTOS' />
        <PrivateRoute path='/dashboard-equipamentos' component={EquipmentsPanel} roles='ROLE_EQUIPAMENTOS_VISUALIZAR' modulo='EQUIPAMENTOS' />
        <PrivateRoute path='/etapa' component={StagesPage} roles='ROLE_ETAPAS_VISUALIZAR' modulo='ETAPAS' />
        <PrivateRoute path='/flow-view' component={FlowViewerContainer} roles='ROLE_FLOW_VIEW_VISUALIZAR' modulo='FLOW_VIEW' />
        <PrivateRoute path='/grupo-de-evento' component={GroupEventPage} roles='ROLE_GRUPOS_EVENTOS_VISUALIZAR' modulo='EVENTOS' />
        <PrivateRoute path='/grupo-de-equipamento' component={GroupEquipamentPage} roles='ROLE_GRUPO_EQUIPAMENTOS_VISUALIZAR' modulo='GRUPO_EQUIPAMENTOS' />
        <PrivateRoute path='/grupo-de-usuario' component={GroupUsersPage} modulo='GRUPO_USUARIO'
          roles={[
            'ROLE_GRUPO_VISUALIZAR',
            'ROLE_GRUPO_USUARIO_VISUALIZAR'
          ]} />
        <PrivateRoute path='/lote' component={BatchesPage} roles='ROLE_LOTES_VISUALIZAR' modulo='LOTES' />
        <PrivateRoute path='/predecessor' component={PredecessoresPage} roles='ROLE_LINK_PI_PA_VISUALIZAR' modulo='LINK_PI_PA' />
        <PrivateRoute path='/manutencao' component={MaintenancePage} roles='ROLE_MANUTENCAO_VISUALIZAR' modulo='MANUTENCAO' />
        <PrivateRoute path='/monitor-equipamentos' component={MonitorEquipamento} roles='ROLE_MONITOR_EQUIPAMENTO_VISUALIZAR' modulo='MONITOR_EQUIPAMENTO' />
        <PrivateRoute path='/oee' component={OeeContainerPage} roles='ROLE_OEE_VISUALIZAR' modulo='OEE' />
        <PrivateRoute path='/opcoes-de-calculo' component={AvailabilityPage} roles='ROLE_OPCOES_CALCULO_VISUALIZAR' modulo='OPCOES_CALCULO' />
        <PrivateRoute path='/painel-de-fluxo' component={PainelFluxoPage} roles='ROLE_PAINEL_FLUXO_VISUALIZAR' modulo='PAINEL_FLUXO' />
        <PrivateRoute path='/parametro' component={Parameters} roles='ROLE_PARAMETROS_VISUALIZAR' modulo='PARAMETROS' />
        <PrivateRoute path='/produto' component={ProductsPage} roles='ROLE_PRODUTOS_VISUALIZAR' modulo='PRODUTOS' />
        <PrivateRoute path='/dados-coleta' component={DataCollectionPage} roles='ROLE_MONITOR_COLETA_VISUALIZAR' modulo='MONITOR_COLETA' />
        <PrivateRoute path='/rota' component={RouteContainer} roles='ROLE_ROTAS_VISUALIZAR' modulo='ROTAS' />
        <PrivateRoute path='/turno' component={ShiftsPage} roles='ROLE_TURNOS_VISUALIZAR' modulo='TURNOS' />
        <PrivateRoute path='/unidade-industrial' component={SessionsPage} roles='ROLE_UNIDADES_INDUSTRIAIS_VISUALIZAR' modulo='UNIDADES_INDUSTRIAIS' />
        <PrivateRoute path='/usuario' component={Usuario} roles='ROLE_USUARIOS_VISUALIZAR' modulo='USUARIOS' />
        <PrivateRoute path='/campo-personalizado' component={CustomFields} roles='ROLE_CAMPOS_PERSONALIZADOS_VISUALIZAR' modulo='CAMPOS_PERSONALIZADOS' />
        <PrivateRoute path='/relatorio' component={MenuRelatorio} roles={['ROLE_RELATORIO_VISUALIZAR', 'ROLE_RELATORIO_GERAR_RELATORIO']} modulo='RELATORIO' />
        <PrivateRoute path='/paineis' component={MenuPaineis} roles={['ROLE_PAINEIS_VISUALIZAR']} modulo='PAINEIS' />
      </Switch>
    </Provider>
  </App>
);
