import React, { Component } from 'react';
import { Paper } from 'material-ui';
import DetalheEquipamento from './DetalheEquipamento';
import $ from 'jquery';

import styles from './style.scss';
import { withTranslation } from 'react-i18next';

class ListarEtapa extends Component {

  constructor() {
    super();
    this.state = {
      heigthComEquipamento: '',
    };
  }

  componentDidMount() {
    var containerComEquipamento = $('#containerComEquipamento').height();
    this.setState({
      heigthComEquipamento: containerComEquipamento
    });
  }
  
  render() {
    let {detalhe} = this.props;
    
    let corLeadTime = '#A5A5A5';
    let iconeEtapa = './../assets/icon-stage-waiting.svg';

    if(detalhe !== undefined) {
      if(detalhe.com_equipamento) {
        
        if(detalhe.passou) {
            switch(detalhe.cor) {
              case 'verde': 
                corLeadTime = '#449D44';
              break;
              case 'vermelho': 
                corLeadTime = '#B70909';
              break;    
              case 'amarelo': 
                corLeadTime = '#EC971F';
              break;      
            }
        }

        switch(detalhe.situacao) {
          case 'None':
          case 'Espera':
            iconeEtapa = './../assets/icon-stage-waiting.svg';
            break;
          case 'Produzindo':
            iconeEtapa = './../assets/icon-stage-running.svg';
            break;
          case 'Finalizado':
            iconeEtapa = './../assets/icon-stage-finished.svg';
            break;
        }

        return (
          <div className={styles.containerEtapa}>
            <div>
              <Paper zDepth={2} id={'containerComEquipamento'}>
                <div className={styles.containerLeadTime}>
                  <div style={{width: '100%', background: corLeadTime}} className={styles.rupturaLeadTime}></div>
                </div>
                  <div className={styles.infoEtapa}>
                    <label>{detalhe.nome}</label>
                    <img style={{width: '24px', height: '24px'}} src={iconeEtapa} />
                  </div>
                <div style={{display: 'flex', marginTop: '5px'}}>
                  <DetalheEquipamento detalhe={detalhe} />
                </div>
              </Paper>
            </div>
          </div>
          );
      }else{
        return (<EtapaPulmao detalhe={detalhe} heigthContainer={this.state.heigthComEquipamento}/>);
      }
      
    }else{
      return null;
    }
  }  
}


class EtapaPulmaoExport extends React.Component {
  render() {
    return (
      <div className={styles.containerEtapa}>
        <div>
          <Paper zDepth={2} style={{height: this.props.heigthContainer}}>
            <div className={styles.containerLeadTime}> 
              <div style={{width: '100%', height: '8px'}}></div>
            </div>
            <div className={styles.infoEtapa}>
              {this.props.t("labels.lung")}
            </div>
            <div style={{display: 'flex', marginTop: '1em'}}>
              <div style={{width: '14em'}}>
                <div className={styles.infoEquipamento}>
                  <br/>
                  <br/>
                  <br/>
                  <div className={styles.badges}>
                    <Paper zDepth={2} style={{width: '100%', padding: '5px 13px 5px 13px', textAlign: 'center'}}>
                      <i style={{fontSize: '10px'}}>{this.props.t("labels.realWaitingTime")}</i>
                      <br/>
                      {
                        this.props.detalhe.processo_real == 0 
                        ? <i style={{fontSize: '14px', fontWeight: 'bold'}}>-</i>
                        : <i style={{fontSize: '14px', fontWeight: 'bold'}}>{this.props.detalhe.processo_real}h</i>
                      }                     
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export const EtapaPulmao = withTranslation("flowView")(EtapaPulmaoExport);

export default withTranslation("flowView")(ListarEtapa);