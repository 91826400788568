import React, { Component } from 'react';
import { 
    NButton, NForm, NFormTitle,
    NButtonGroup, NEditableBagde, NInput,
    NStepSelector, alertBox
} from '../../common/NForm';
import update from 'immutability-helper';
import { Scrollbars } from 'react-custom-scrollbars';
import style from './holding_time.scss';
import { request } from '../../common/request';
import 'whatwg-fetch';
import { fromHoursToSeconds } from '../../common/helpers';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

const DEFAULT_HT = {
    nome: 'Hold Time 1',
    tempo_total: '',
    ordem_origem: null,
    posicao_etapa_inicio: null,
    ordem_destino: null,
    posicao_etapa_fim: null,
    produto_destino: {},
    errors: {},
};

class HoldingTime extends Component {
    constructor(props) {
        super(props);
        this.state = props.holding_time;

        this.period_clicked = false;
        this.valid = false;
    }

    _handleAddHoldingTime() {
        this.setState(
            update(this.state, {
                holding_times: {
                    $push: [{
                        nome: `Hold Time ${this.state.holding_times.length+1}`,
                        tempo_total: '',
                        ordem_origem: null,
                        posicao_etapa_inicio: null,
                        ordem_destino: null,
                        posicao_etapa_fim: null,
                        errors: {},
                    }],
                }
            })
        );
    }

    _handleChangeHoldTimeName(value, i) {
        this.setState(
            update(this.state, {
                holding_times: {[i]: {nome: {$set: value}}}
            })
        );
    }

    _deleteHoldingtime(state, i) {
        const id = state.holding_times.length - 2;
        const ht = state.holding_times[id] || DEFAULT_HT;

        const deleted = state.holding_times[i];

        const { product: { stages } } = state;
        
        const newState = update(state, {
            holding_times: {$splice: [[i, 1]]},
            cur_ht: {$set: id},
            ordem_origem: {$set: ht.ordem_origem},
            posicao_etapa_inicio: {$set: ht.posicao_etapa_inicio},
            ordem_destino: {$set: ht.ordem_destino},
            posicao_etapa_fim: {$set: ht.posicao_etapa_fim},
        });

        if (deleted.id) {
            let data = [{
                id: deleted.id,
                produto_origem: {id: stages[deleted.ordem_origem].productId},
                produto_destino: {id: stages[deleted.ordem_destino].productId},
                excluido_usuario: {
                    id: parseInt(localStorage['id']),
                }
            }];
    
            return request(this.props.endpoints.HOLDING_TIME, {
                method: 'post',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-Access-Token': localStorage['token']
                },
                body: JSON.stringify(data),
            }).then(res => {
                if (res.status >= 200 && res.status < 300) {
                    alertBox(this.props.t("messages.deleteSuccess"), this.props.t("title"), 'success');
                    setTimeout(() => this.props.reloadProducts());
                } else {
                    alertBox(this.props.t("errors.deleteError"), this.props.t("title"), 'error');
                }
    
                return Promise.resolve(newState);
            });
        } else {
            return Promise.resolve(newState);
        }        
    }

    _confirmDelete(state, type, nome, index) {
        return (
            <NForm>
                <NFormTitle>{this.props.t("messages.confirmeDeleteLabel")}</NFormTitle>
                {this.props.t("messages.confirmeDeleteAction", {name: nome})}                
                <br /><br />
                <NButtonGroup>
                    <NButton type="primary" onClick={() => 
                        this.props.restoreHoldingTime(this._deleteHoldingtime(state, index), type)}
                    >{this.props.t("labels.yes", {ns: "common"})}</NButton>
                    <NButton onClick={() => 
                        this.props.restoreHoldingTime(state, type)}>{this.props.t("labels.no", {ns: "common"})}</NButton>
                </NButtonGroup>
            </NForm>
        );
    }

    _handleDeleteHoldTime(i) {
        this.props.message(
            this._confirmDelete(
                this.state,
                this.props.type,
                this.state.holding_times[i].nome,
                i
            )
        );
    }

    _handleSetStageEndPeriod(cur_ht, ht, value, disabled) {
        if(!disabled) {
            this.period_clicked = true;
            
            this.setState(
                update(
                    this.state,
                    {
                        holding_times: {
                            [cur_ht]: {
                                posicao_etapa_fim: {$set: value}
                            }
                        }
                    }
                )
            );
        }
    }

    _handleOnDrawEndStagePeriod(stage, i, ht, cur_ht, eh_mesmo_produto) {
        const disabled =
            ht.ordem_origem === i &&
            ht.posicao_etapa_inicio === false &&
            eh_mesmo_produto;

        return (
            <span className={style.step_selector}>
                { i == 0 ? <i className={`material-icons ${style.step_selector_icon}`}>play_circle_filled</i> : null }
                {stage.nome}
                <div className={style.floating_card}>
                    <span onClick={() => this._handleSetStageEndPeriod(cur_ht, ht, false, disabled)}>
                        <input
                            disabled={disabled}
                            value={0}
                            checked={ht.posicao_etapa_fim === false}
                            onChange={() => {}}
                            type="radio"
                        /> {this.props.t("form.holdingTime.labels.start")}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span onClick={() => this._handleSetStageEndPeriod(cur_ht, ht, true, false)}>
                        <input
                            value={1}
                            checked={ht.posicao_etapa_fim === true}
                            type="radio"
                            onChange={() => {}}
                        /> {this.props.t("form.holdingTime.labels.end")}
                    </span>
                </div>
            </span>
        );
    }

    _handleSetStageStartPeriod(cur_ht, i, ht, value, eh_mesmo_produto) {
        this.period_clicked = true;

        const newState = {
            holding_times: {
                [cur_ht]: {
                    posicao_etapa_inicio: {$set: value},
                }
            }
        };

        if ( eh_mesmo_produto ) {
            if ( i === ht.ordem_destino) {
                newState.holding_times[cur_ht].posicao_etapa_fim = {$set: null};
            }
        }

        this.setState(
            update(
                this.state,
                newState
            ),
        );
    }

    _handleOnDrawStartStagePeriod(stage, i, ht, cur_ht, eh_mesmo_produto) {
        return (
            <span className={style.step_selector}>
                { i == 0 ? <i className={`material-icons ${style.step_selector_icon}`}>play_circle_filled</i> : null }
                {stage.nome}
                <div className={style.floating_card}>
                    <span onClick={() => this._handleSetStageStartPeriod(cur_ht, i, ht, false, eh_mesmo_produto)}>
                        <input
                            value={0}
                            checked={ht.posicao_etapa_inicio === false}
                            onChange={() => {}}
                            type="radio"
                        /> {this.props.t("form.holdingTime.labels.start")}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span onClick={() => this._handleSetStageStartPeriod(cur_ht, i, ht, true, eh_mesmo_produto)}>
                        <input
                            value={1}
                            checked={ht.posicao_etapa_inicio === true}
                            type="radio"
                            onChange={() => {}}
                        /> {this.props.t("form.holdingTime.labels.end")}
                    </span>
                </div>
            </span>
        );
    }

    _handleSelectHT(e, i) {

        const ht = this.state.holding_times[i];

        this.setState({
            cur_ht: i,
            ordem_origem: ht.ordem_origem,
            posicao_etapa_inicio: ht.posicao_etapa_inicio,
            ordem_destino: ht.ordem_destino,
            posicao_etapa_fim: ht.posicao_etapa_fim,
        });
    }

    _calculateDisabled(ht) {
        const arr = [];
        const index = ht.ordem_origem;

        for (let i = 0; i < index; i++) {
            arr.push(true);
        }

        if (ht.posicao_etapa_inicio === true) {
            arr.push(true);
        }

        return arr;
    }

    _handleSetStartStage(cur_ht, i, productId, stageId, eh_mesmo_produto) {
        if (!this.period_clicked) {
            let produtoOrigem = this.state.holding_times[cur_ht].produto_origem ? this.state.holding_times[cur_ht].produto_origem : {id: ''};
            produtoOrigem.id = productId;

            const newState = {
                holding_times: {
                    [cur_ht]: {
                        ordem_origem: {$set: i},
                        produto_origem: {$set: produtoOrigem},
                        etapa_inicio: {$set: {id: stageId}}
                    }
                }
            };

            const ordem_destino = this.state.holding_times[cur_ht].ordem_destino;

            if (eh_mesmo_produto) {
                if (i > ordem_destino) {
                    newState.holding_times[cur_ht].ordem_destino = {$set: null};
                    newState.holding_times[cur_ht].posicao_etapa_fim = {$set: null};
                } else if ( i === ordem_destino) {
                    newState.holding_times[cur_ht].posicao_etapa_fim = {$set: null};
                }
            }

            this.setState(
                update(
                    this.state,
                    newState
                )
            );
        }
    }

    _handleSetEndStage(cur_ht, i, productId) {
        const ht = this.state.holding_times[cur_ht];

        if (!this.period_clicked) {
            this.setState(
                update(
                    this.state,
                    {
                        holding_times: {
                            [cur_ht]: {
                                produto_destino: {$set: {id: productId}},
                                ordem_destino: {$set: i},
                                posicao_etapa_fim : {$set: 
                                    i <= ht.ordem_origem && (!ht.posicao_etapa_fim) ?
                                        null : ht.posicao_etapa_fim
                                }
                            }
                        }
                    }
                )
            )
        }
    }

    validate(fnSucess, fnFailure) {
        const { state: { holding_times } } = this;
        let valid = true;
        let state = {
            holding_times: {}
        };

        holding_times.forEach((ht, i) => {

            let errors = {};

            if (ht.tempo_total === null || ht.tempo_total === '') {
                valid = false;
                errors.tempo_total = this.props.t("errors.totalTimeEmpty")
            }

            if (ht.ordem_origem === null) {
                valid = false;
                errors.ordem_origem = this.props.t("errors.selectStageStart");
            }

            if (ht.ordem_destino === null){
                valid = false;
                errors.ordem_destino = this.props.t("errors.selectStageEnd");
            }

            if (ht.posicao_etapa_inicio === null){
                valid = false;
                errors.posicao_etapa_inicio = this.props.t("errors.selectStageStartBeginningOrEnd");

            }

            if (ht.posicao_etapa_fim === null) {
                valid = false;
                errors.posicao_etapa_fim = this.props.t("errors.selectStageEndBeginningOrEnd");
            }

            state.holding_times[i] = {errors: {$set: errors}};
        });

        this.setState(update(this.state, state), () => {
            if (valid) {
                if(fnSucess) fnSucess();
            } else {
                if (fnFailure) fnFailure();
            }
        })
    }

    validarJaExisteHoldingTime(holding_times, fnSucess, fnFailure) {
        const jacadastrado = {};

        const sz = holding_times.length;
        let ht;
        let key;

        for(let i = 0; i < sz; i++) {
            ht = holding_times[i];
            key = `${ht.produto_origem.id}${ht.produto_destino.id}${ht.etapa_inicio.id}${ht.etapa_fim.id}${ht.ordem_origem}${ht.ordem_destino}${ht.posicao_etapa_inicio}${ht.posicao_etapa_fim}`;
            if (jacadastrado[key] === true) {

                alertBox(this.props.t("errors.alreadyExisting"), this.props.t("title"), 'warning');
                
                if (fnFailure) fnFailure();

                return;
            } else {
                jacadastrado[key] = true;
            }

            if (jacadastrado[ht.nome] === true) {
                
                alertBox(this.props.t("errors.alreadyExistingWithSameName"), this.props.t("title"), 'warning');
                
                if (fnFailure) fnFailure();

                return;
            } else {
                jacadastrado[ht.nome] = true;
            }
        }

        if (fnSucess) fnSucess();
    }

    formatHoldingTimeData(holding_times, stages, usuarioId, pi_stage_size, id) {

        return holding_times.map(ht => {
            delete ht.criado_em;
            delete ht.atualizado_em;
            delete ht.excluido_em;
            delete ht.criado_usuario;
            delete ht.exluido_usuario;

            if (ht.id) {
                return {
                    ...ht,
                    atualizado_usuario: {id: usuarioId},
                    tempo_total:  fromHoursToSeconds(ht.tempo_total),
                };
            } else {
                return {
                    ...ht,
                    criado_usuario: {id: usuarioId},
                    tempo_total:  fromHoursToSeconds(ht.tempo_total),
                    
                }
            }
        }).map(ht => {
            ht.etapa_inicio = ht.etapa_inicio;
            ht.etapa_fim = {id: stages[ht.ordem_destino].id};                
            ht.produto_origem = ht.produto_origem;
            ht.produto_destino = ht.produto_destino;
            ht.ordem_origem = ht.ordem_origem;
            ht.ordem_destino = ht.ordem_destino;
            
            return ht;
        });
    }

    nomeProduto(prod) {
        let produto;
        if(prod && prod.id) {
            produto = prod;
        }
        else {
            produto = this.props.product;
        }
        
        return produto.sku + ' - ' + produto.nome;
    }

    salvar() {

        this.validate(() => {

            const { state } = this;

            const { holding_times } = state;
            
            const { product: { stages, pi_stage_size, id } } = state;
    
            const usuarioId = parseInt(localStorage['id']);

            const data =
                this.formatHoldingTimeData(
                    holding_times, stages, usuarioId, pi_stage_size, id
                );
            
            this.validarJaExisteHoldingTime(data, () => {
                this.props.closeModal();
                
                request(this.props.endpoints.HOLDING_TIME, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-Access-Token': localStorage['token']
                    },
                    body: JSON.stringify(data),
                }).then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        alertBox(this.props.t("messages.success"), this.props.t("title"), 'success');
                        this.props.reloadProducts();
                    } else {
                        alertBox(this.props.t("errors.cannotPossibleSave"), this.props.t("title"), 'error');
                    }
                }).catch(res => {
                    alertBox(this.props.t("errors.cannotPossibleSave"), this.props.t("title"), 'error');
                });

            });
        }, () => 
            alertBox(this.props.t("errors.thereAreProblemsWithInput"), this.props.t("title"), 'warning')
        );
    }

    onTempoTotalChanged(e, cur_ht) {
        this.setState(update(this.state, {holding_times: {[cur_ht]: {tempo_total: {$set: e.target.value.replace(/\D/g, '')}}}}));
    }

    render() {
        this.period_clicked = false;
        var activePI;
        
        const { holding_times, product, cur_ht } = this.state;

        const ht = holding_times[cur_ht] || DEFAULT_HT;

        const eh_mesmo_produto = (ht.produto_origem || {}).id == product.id;
        
        const disabled = eh_mesmo_produto ? this._calculateDisabled(ht) : [];

        const hts =
            holding_times.map(
                (h, i) => <NEditableBagde
                    deletable={ hasRole('ROLE_HOLDING_TIME_EXCLUIR') }
                    editable={ hasRole('ROLE_HOLDING_TIME_ATUALIZAR') }
                    onChange={ value => this._handleChangeHoldTimeName(value, i) }
                    onDelete={ () => this._handleDeleteHoldTime(i)  }
                    key={`ht-${i}`}
                    text={h.nome}
                    active={cur_ht == i}
                    onClick={e => this._handleSelectHT(e, i)}
                />
            );

        return(
            <NForm className={style.form}>
                <NFormTitle>{this.props.t("title")}</NFormTitle>
                <div className={style.container}>
                    <div className={style.first_division}>
                        <div className={style.first_division__shadow_box}>
                            <h3 className={style.first_division__title_holder}>
                                <i className="material-icons">timer</i> {this.props.t("form.holdingTime.labels.holdingTimes")}
                                <span className={style.first_division__add_button}>
                                    {hasRole('ROLE_HOLDING_TIME_CRIAR') &&
                                        <a href="javascript:void(0)" onClick={this._handleAddHoldingTime.bind(this)}>
                                            <i className="material-icons">add_circle</i>
                                        </a>
                                    }
                                </span>
                            </h3>
                            <div className={style.hts}>
                                <Scrollbars style={{ width: 208, height: 400 }}>
                                    {hts}
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                    <div className={style.second_division}>
                        <Scrollbars>
                            <div className={style.form_fields}>
                                <label>{this.props.t("form.holdingTime.labels.product")}</label>
                                <div className={style.product_name}>{product.sku} - {product.nome}</div>
                            </div>
                            <div className={style.form_fields}>
                                <label>{this.props.t("form.holdingTime.labels.totalHoldingTime")}</label>
                                <div>
                                    <NInput
                                        className={style.form_fields__holding_time_total}
                                        value={ht.tempo_total}
                                        onChange={e => this.onTempoTotalChanged(e, cur_ht)}
                                    />
                                </div>
                            </div>
                            <div className={style.errors}>{ht.errors.tempo_total || ''}</div>
                            <div>
                                <div style={{background: '#f6f6f6', padding: '.3em', display: 'inline-block', minWidth: '98%'}} className={style.form_fields}>
                                    <div>
                                        <div>
                                            <label>{this.props.t("form.holdingTime.labels.stageStart")}</label>
                                        </div>
                                            {
                                                product.stagePredecessores.map(r => r.map((stage, i) => {
                                                    if(stage.length > 0) {
                                                        activePI = (ht.produto_origem && ht.produto_origem.id == stage[0].productId) ? ht.ordem_origem : null;

                                                        return <div style={{marginTop: '1em', marginBottom: '2em'}} >
                                                            <label> {this.props.t("form.holdingTime.labels.productName", {name: this.nomeProduto(product.produtosPredecessores[i])})} </label>
                                                            <NStepSelector 
                                                                active={activePI}
                                                                onChange={(e, i) => this._handleSetStartStage(cur_ht, i, stage[0].productId, stage[i].id, eh_mesmo_produto)}
                                                                steps={stage}
                                                                onDraw={(stage, i) => this._handleOnDrawStartStagePeriod(stage, i, ht, cur_ht, eh_mesmo_produto)}
                                                            />
                                                            <br />
                                                        </div>
                                                    } else {
                                                        return <div style={{marginTop: '1em', marginBottom: '2em'}}>
                                                            <label> {this.props.t("form.holdingTime.labels.productName", {name: this.nomeProduto(product.produtosPredecessores[i])})} </label>
                                                            <label> {this.props.t("form.holdingTime.labels.noRegisteredRoute")} </label>
                                                            <br /> 
                                                        </div>
                                                    }
                                                    
                                                }))
                                            }
                                        <div style={{marginTop: '1em', marginBottom: '1em'}}>
                                            <label> {this.props.t("form.holdingTime.labels.productName", {name: this.nomeProduto(product.id)})} </label>
                                            <NStepSelector 
                                                active={(ht.produto_origem && ht.produto_origem.id == product.id) && ht.ordem_origem}
                                                onChange={(e, i) => this._handleSetStartStage(cur_ht, i, product.id, product.stages[i].id, eh_mesmo_produto)}
                                                steps={product.stages}
                                                onDraw={(stage, i) => this._handleOnDrawStartStagePeriod(stage, i, ht, cur_ht, eh_mesmo_produto)}
                                            />
                                        </div>
                                    </div>
                                    <div className={style.errors}>{ht.errors.ordem_origem || ''}</div>
                                    <div className={style.errors}>{ht.errors.posicao_etapa_inicio || ''}</div>
                                    <br />
                                </div>
                                <div style={{background: '#f6f6f6', padding: '.3em', display: 'inline-block', minWidth: '98%'}}>
                                    <div className={style.form_fields}>
                                        <label>{this.props.t("form.holdingTime.labels.endStage")}</label>
                                        <div style={{marginTop: '1em'}}>
                                        <label> {this.props.t("form.holdingTime.labels.productName", {name: this.nomeProduto(product.id)})} </label>
                                            <NStepSelector 
                                                active={ht.ordem_destino}
                                                disabled={disabled}
                                                onChange={(e, i) => this._handleSetEndStage(cur_ht, i, product.id)}
                                                steps={product.stages}
                                                onDraw={(stage, i) => this._handleOnDrawEndStagePeriod(stage, i, ht, cur_ht, eh_mesmo_produto)}
                                            />
                                        </div>
                                    </div>
                                    <div className={style.errors}>{ht.errors.ordem_destino || ''}</div>
                                    <div className={style.errors}>{ht.errors.posicao_etapa_fim || ''}</div>
                                </div>
                            </div>
                            <div className={style.errors}>{ht.errors.holding_time_ja_cadastrado || ''}</div>
                            <br />
                            <div>
                                <NButtonGroup>
                                    <NButton 
                                        type="primary"
                                        onClick={() => this.salvar()}
                                        disabled={!hasRole(['ROLE_HOLDING_TIME_CRIAR', 'ROLE_HOLDING_TIME_ATUALIZAR'])}
                                    >{this.props.t("labels.save", {ns: "common"})}</NButton>
                                    <NButton onClick={() => this.props.closeModal()}>{this.props.t("labels.cancel", {ns: "common"})}</NButton>
                                </NButtonGroup>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </NForm>
        );
    }
}

export default withTranslation('holdingTime')(HoldingTime)