import React, { Component } from 'react';
import listStyles from './list.scss';

export default class ListTitles extends Component {
  render() {
    let {stages} = this.props;
    return (
      <ul className={listStyles.list_titles}>
        {stages.map((stage) => <li key={stage.id}>
          <span>{stage.name}</span>
        </li>)}
      </ul>
    );
  }
}
