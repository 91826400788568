import React from 'react';
import AbstractSetupComponent from '../AbstractSetupComponent/AbstractSetupComponent';
import { Chart, Bar } from 'react-chartjs-2';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import ChartHelpers from '../Helpers/ChartJsHelper';
import { blueGradientVertical } from '../Helpers/ChartJsHelper';
import { withTranslation } from 'react-i18next';

const legendOpts = {
    display: false,
};

@inject('store')
@observer
class DiarioChart extends AbstractSetupComponent {

    constructor(props) {
        super(props);
        this.state = { chartRef: null, matchScreen: window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').matches, toggle: true, displayedData: [], index: 1 };
        this.setExibirDisponibilidade = this.props.store.setExibirDisponibilidade;
    }

    componentWillMount() {
        ChartHelpers(Chart);
    }

    componentDidMount() {
        const handler = e => this.setState({ matchScreen: e.matches });
        window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').addListener(handler);
    }

    formatOption() {
        let diferencaDias = this.props.diferencaDias;
        let granularidade = this.props.granularidade;

        return {
            cornerRadius: 5,
            title: {
                display: true,
                text: this.props.t("charts.labels.detailed")
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'top',
                    font: {
                        weight: 'bold'
                    }
                }
            },
            responsive: false,
            animation: false,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 0,
                    bottom: 20
                }
            },
            scales: {
                xAxes: [
                    {
                        distribution: 'linear',
                        display: true,
                        time: {
                            unit: diferencaDias <= 1 ? 'hour' : 'day',
                            tooltipFormat: function () {
                                if (diferencaDias <= 1) {
                                    return 'HH:mm'
                                } else if (granularidade === 5) {
                                    return 'YYYY'
                                }
                                else {
                                    return diferencaDias > 60 ? 'MM/YYYY' : 'L';
                                }
                            },
                            displayFormats: {
                                day: 'L',
                                hour: 'HH:mm'
                            }
                        },
                        gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                        },
                        ticks: {
                            display: true,
                            source: 'auto',
                            callback: function (value, index, values) {
                                if (values.length === 0) return value;
                                if (index === 0) {
                                    let monthsComputed = [];

                                    for (let i = 0; i < values.length; i++) {
                                        let date = moment(values[i].value);

                                        let currMonth = parseInt(date.format('MM'));

                                        if (monthsComputed.indexOf(currMonth) === -1) {
                                            values[i].showMonth = true;
                                            monthsComputed.push(currMonth);
                                        }
                                    }
                                }

                                if (granularidade === 5) {
                                    return moment(value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY');
                                }

                                if (diferencaDias <= 1) {
                                    return moment(value, 'YYYY-MM-DDTHH:mm:ss').format('HH');
                                }

                                if (diferencaDias > 60) {
                                    if (values[index].showMonth) {
                                        return [
                                            moment(value, 'DD/MM/YYYY').format('DD'),
                                            moment(value, 'DD/MM/YYYY').format('MMM')
                                        ];
                                    }
                                } else {
                                    if (values[index].showMonth) {
                                        var data = moment(value, 'DD/MM/YYYY').set('date', 1);
                                        return moment(data).format('MMM');
                                    }
                                }

                                if (diferencaDias < 60) { return moment(value, 'DD/MM/YYYY').format('DD'); }
                            }
                        }
                    }
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: false,
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            stepSize: 5
                        }
                    }
                ]
            },
            tooltips: {
                position: 'nearest',
                titleFontSize: 12,
                bodyFontSize: 11,
                enabled: false,
                displayColors: false,
                mode: 'index',
                intersect: true,
                yAlign: 'bottom',
                callbacks: {
                    title: function (tooltipItems, data) {
                        let index = tooltipItems[0].index;
                        if (granularidade === 5) {
                            return moment(data.labels[index], 'YYYY-MM-DDTHH:mm:ss').format('YYYY');
                        } else if (diferencaDias <= 1) {
                            return moment(data.labels[index], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
                        } else {
                            return moment(data.labels[index], 'DD/MM/YYYY').format('L');
                        }
                    }
                },
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        tooltipEl.style.backgroundColor = '#FFFFFF';
                        tooltipEl.style.borderColor = '#000000';
                        tooltipEl.style.borderWidth = 'thin';
                        tooltipEl.style.borderStyle = 'solid';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);

                        var innerHtml = '<thead>';

                        titleLines.forEach(function (title) {
                            innerHtml += '<tr><th style="color: #fff; font-weight: bold;">' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function (body, i) {
                            var style = 'border-width: 2px; color: #fff;';
                            var span = '<span style="' + style + '">' + body + '%</span>';
                            innerHtml += '<tr><td>' + span + '</td></tr>';
                        });

                        innerHtml += '<span style="width: 100%; text-align: center; position: absolute; bottom: -10px; left: -2px; color: rgba(0,0,0,0.7);">▼</span>';
                        innerHtml += '</tbody>';

                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;

                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.minWidth = '110px';
                    tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.7)';
                    tooltipEl.style.borderWidth = '0';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 59 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 135 + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.borderRadius = '5px';
                    tooltipEl.style.lineHeight = '13px';
                }
            },
            topBarLabel: this.getTopBarStyle(),
            hover: {
                mode: 'nearest',
                intersect: true
            }
        };
    }

    getTopBarStyle() {
        let fontSize;
        let rotate = false;
        if (this.state.data && this.state.data.length > 20) {
            fontSize = this.state.data.length > 25 ? 9.5 : 11;
            rotate = true;
            if (!this.state.matchScreen) {
                fontSize += 2;
            }
        } else {
            fontSize = 12;
        }
        return {
            label: this.props.t("charts.labels.real"),
            display: true,
            fontSize,
            rotate
        };
    }

    filtrarData(data) {
        return data.disponibilidade !== 0;
    }

    formatData(data, exibirDisponibilidadeZerada, canvas) {
        let azulEscuro = canvas ? blueGradientVertical(canvas) : '#314d6d';

        var oee = null;

        if (data) {
            oee = this.props.store.disponibilidadeStore.ExibirDisponibilidadeZerada || exibirDisponibilidadeZerada
                ? data
                : data.filter(this.filtrarData);
        }

        let datas = this.formateDates(this.pluck(oee, 'data'));
        let real = this.pluckCustom(oee, 'real');
        let meta = this.pluckCustom(oee, 'meta');
        let disponibilidade = this.pluckCustom(oee, 'disponibilidade');
        let performance = this.pluckCustom(oee, 'performance');
        let qualidade = this.pluckCustom(oee, 'qualidade');
        let acumulado = this.pluckCustom(oee, 'acumulado');
        let LSC = this.pluckCustom(data, 'lsc');
        let LIC = this.pluckCustom(data, 'lic');

        return {
            labels: datas,
            datasets: [
                {
                    type: 'line',
                    label: this.props.t("charts.labels.lic"),
                    borderColor: '#e4b18f',
                    backgroundColor: '#e4b18f',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0,
                    borderWidth: 3,
                    data: LIC,
                    borderDash: [3, 5]
                },
                {
                    type: 'line',
                    label: this.props.t("charts.labels.lsc"),
                    borderColor: '#a5a5a5',
                    backgroundColor: '#a5a5a5',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0.3,
                    borderWidth: 3,
                    data: LSC,
                    borderDash: [3, 5]
                },
                {
                    type: 'line',
                    label: this.props.t("charts.labels.quality"),
                    borderColor: '#70ad47',
                    backgroundColor: '#70ad47',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0.3,
                    borderWidth: 2,
                    data: qualidade
                },
                {
                    type: 'line',
                    label: this.props.t("charts.labels.performance"),
                    borderColor: '#5b9bd5',
                    backgroundColor: '#5b9bd5',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0.3,
                    borderWidth: 2,
                    data: performance
                },
                {
                    type: 'line',
                    label: this.props.t("charts.labels.availability"),
                    borderColor: '#ffc000',
                    backgroundColor: '#ffc000',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0.3,
                    borderWidth: 2,
                    data: disponibilidade
                },
                {
                    type: 'line',
                    label: this.props.t("charts.labels.accumulated"),
                    borderColor: '#a5a5a5',
                    backgroundColor: '#a5a5a5',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0.3,
                    borderWidth: 2,
                    data: acumulado,
                    borderDash: [15, 3, 3, 3, 3, 3]
                },
                {
                    type: 'line',
                    label: this.props.t("charts.labels.goal"),
                    borderColor: '#f6be98',
                    backgroundColor: '#f6be98',
                    pointRadius: 0,
                    fill: false,
                    lineTension: 0.3,
                    borderWidth: 2,
                    data: meta,
                    borderDash: [20, 5]
                },
                {
                    label: this.props.t("charts.labels.real"),
                    data: real,
                    borderColor: azulEscuro,
                    backgroundColor: azulEscuro,
                    borderWidth: 2,
                    borderSkipped: false
                }
            ]
        };
    }

    pluckCustom(data, key) {
        const dData = this.state.displayedData;

        if (dData[key] === undefined || dData[key]) {
            return this.pluck(data, key);
        } else {
            return undefined;
        }
    }

    getLegendStyled(legend) {
        let baseStyle = {
            backgroundColor: legend.backgroundColor,
            marginRight: 3,
            marginLeft: 5,
            width: 22,
            height: 3,
        };

        if (legend.label == this.props.t("charts.labels.real")) {
            baseStyle = { ...baseStyle, height: 6, width: 15 };
        } else if (legend.label == this.props.t("charts.labels.goal") || legend.label == this.props.t("charts.labels.accumulated")) {
            baseStyle = { ...baseStyle, width: 15 };
        } else {
            baseStyle = { ...baseStyle, borderRadius: '5px' };
        }

        if (legend.label == this.props.t("charts.labels.lic") || legend.label == this.props.t("charts.labels.lsc")) {
            return <div style={{ marginRight: 2, marginLeft: 5, display: 'flex' }}>
                {[...Array(4)].map((e, i) => <div style={{ ...baseStyle, borderRadius: '50%', width: 3, marginRight: 1, marginLeft: 0 }}></div>)}
            </div>;
        }

        return <div style={baseStyle}></div>;
    }

    handleLabel(data) {
        switch (data) {
            case this.props.t("charts.labels.lic").toLowerCase():
                return 'lic';
            case this.props.t("charts.labels.lsc").toLowerCase():
                return 'lsc';
            case this.props.t("charts.labels.quality").toLowerCase():
                return 'qualidade';
            case this.props.t("charts.labels.performance").toLowerCase():
                return 'performance';
            case this.props.t("charts.labels.availability").toLowerCase():
                return 'disponibilidade';
            case this.props.t("charts.labels.accumulated").toLowerCase():
                return 'acumulado';
            case this.props.t("charts.labels.goal").toLowerCase():
                return 'meta';
            case this.props.t("charts.labels.real").toLowerCase():
                return 'real';
            default:
                return data;
        }
    }

    handleDisplayedData(data) {
        const dData = this.state.displayedData;
        var label = this.handleLabel(data);

        dData[label] = dData[label] !== undefined ? !dData[label] : false;

        this.setState({ displayedData: dData });
    }

    customLegend(data) {
        return (data.datasets || []).reverse().map((legend) =>
            <div
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => this.handleDisplayedData(legend.label.toLowerCase())} >
                {this.getLegendStyled(legend)}
                <span style={{ textDecoration: this.state.displayedData && this.state.displayedData[legend.label.toLowerCase()] === false ? 'line-through' : 'none' }}>
                    {legend.label}
                </span>
            </div>
        );
    }

    render() {
        let data = (canvas) => this.formatData(this.state.data, this.props.exibirDisponibilidadeZerada, canvas);
        let options = this.formatOption();

        return (
            <span>
                <Bar redraw={true} height={220} width={this.state.matchScreen ? 490 : 650} ref='chart' data={data} legend={legendOpts} options={options} />

                <div style={{ width: this.state.matchScreen ? 490 : 650, fontSize: 12, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {this.customLegend(data())}
                </div>
            </span>
        );
    }
}

export default withTranslation("oee")(DiarioChart);