import React from 'react';
import { LinearProgress } from 'material-ui';
import Container from '../../common/Container';
import styles from '../menuRelatorio.scss';
import { useTranslation } from 'react-i18next';

/**
 * Loading relatório component.
 * 
 * @param {*} show 
 */
const LoadingRelatorio = ({ show }) => {
  const { t } = useTranslation("reports");

  return (
    <div>
      { show && (
          <Container modal={true} contentModal={() => (
            <div className="modal" id={styles.loading_relatorio} style={{ width: '250px', height: '100%' }}>
              <div className={'flex flex-just-center'} style={{ paddingBottom: '20px', paddingTop: '10px' }}>
                <i className="material-icons" style={{ fontSize: '2.7em', paddingTop: '15px' }}>settings</i>
                <i className="material-icons" style={{ fontSize: '2.2em', transform: 'rotate(36deg)', position: 'relative', right: '10px', paddingTop: '3px' }}>settings</i>
              </div>
              <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                <h3 style={{ fontSize: '1.2em', fontWeight: '600' }}>{t("labels.generatingReport")}</h3>
              </div>
              <LinearProgress mode="indeterminate" color='#2D4F7F' size={60} style={{ marginBottom: '5px', paddingBottom: '3px' }} />
            </div>
          )} />
      )}
    </div>
  )
};

export default LoadingRelatorio;
