import React from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import DeleteItem from '../../common/DeleteItem';
import UsersList from './UsersList';
import { request } from '../../common/request';
import { withRouter } from 'react-router-dom';
import { alertBox } from '../../common/NForm';
import MDSpinner from "react-md-spinner";
import UserModal from './UserModal';
import { withTranslation } from 'react-i18next';
import UpdatePasswordModal from 'components/UpdatePasswordModal/UpdatePasswordModal';

const style = { width: 500, overflowY: 'auto' };

@observer
class UsersPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      loadingPage: true,
      usuarios: [],
      grupos_usuario: [],
      grupo_value: [],
      grupo_apontamentos: [],
      nome: '',
      email: '',
      telefone: '',
      username: '',
      senha: '',
      confirmarSenha: '',
      status: true,
      grupo_apontamento_selecionado: {},
      text_grupo_apontamento_selecionado: '',
      erroNOME: '',
      erroEMAIL: '',
      erroUSERNAME: '',
      erroSENHA: '',
      erroCONFIRMARSENHA: '',
      erroTELEFONE: '',
      AD: localStorage.getItem('AD') == 'true'
    };
    this.baseState = {
      grupo_value: [],
      nome: '',
      email: '',
      telefone: '',
      username: '',
      senha: '',
      confirmarSenha: '',
      status: true,
      grupo_apontamento_selecionado: {},
      text_grupo_apontamento_selecionado: '',
      erroNOME: '',
      erroEMAIL: '',
      erroUSERNAME: '',
      erroSENHA: '',
      erroCONFIRMARSENHA: '',
      erroTELEFONE: ''
    };

    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.validate = this.validate.bind(this);
    this.clearState = this.clearState.bind(this);
    this.refreshUsuario = this.refreshUsuario.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  refreshUsuario() {
    request(this.endpoints.SGCA_USUARIO, {
      method: 'GET'
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status == 401) {
        this.setState({
          loadingPage: false
        });
      }
    }).then(data => {
      this.setState({
        usuarios: data || [],
        loadingPage: false
      });
    });
  }

  componentWillMount() {
    this.refreshUsuario();
  }

  delete(uuid) {
    request(this.endpoints.SGCA_USUARIO + '/' + uuid, {
      method: 'delete'
    })
      .then(response => {
        if (response.status === 200) {
          return Promise.resolve();
        } else {
          return Promise.reject(response);
        }
      })
      .then(() => {
        alertBox(this.props.t("messages.deleteSuccess"), this.props.t("title"), 'success');
        this.refreshUsuario();
      })
      .catch(error => {
        alertBox(this.props.t("errors.deleteError"), this.props.t("title"), 'error');
      });
    this.clearState();
    this.handleModal();
  }

  clearState() {
    this.setState(this.baseState);
  }

  onOpenModal(user, typeModal) {

    this.handleModal(user, typeModal);
  }

  handleModal(f, type, keepModalOpen = false) {
    let state = {
      user: f,
      field: f,
      typeModal: type,
      style: style,
    }

    if (type === 'up' || type === 'add') {
      state.userModalOpen = true;
    }
    else {
      this.clearState();
      state.modal = keepModalOpen || !this.state.modal;
    }

    this.setState(state);
  }

  validate(fields) {
    let password = Object.keys(fields).filter((k) => fields[k].props.id === 'password')[0];
    let confirm = Object.keys(fields).filter((k) => fields[k].props.id === 'confirm')[0];

    if (fields[password].refs.password.value !== fields[confirm].refs.confirm.value) {
      return { status: false, message: this.props.t("errors.doNotMatch") };
    }
  }

  onSuccess() {
    this.refreshUsuario();
    this.clearState();
    this.handleModal();
  }

  alterarSenha() {
    let erro = false;
    var data = {
      senha: this.state.senha,
      confirmarSenha: this.state.confirmarSenha
    };

    if (!data.senha) {
      this.setState({
        erroSENHA: this.props.t("messages.requiredField")
      });
      erro = true;
    } else {
      this.setState({
        erroSENHA: ''
      });
    }

    if (!data.confirmarSenha) {
      this.setState({
        erroCONFIRMARSENHA: this.props.t("messages.requiredField")
      });
      erro = true;
    } else {
      this.setState({
        erroCONFIRMARSENHA: ''
      });
    }

    if (data.senha != data.confirmarSenha) {
      alertBox(this.props.t("errors.passwordDoNotMatch"), this.props.t("messages.checkConfirmPassword"), 'error');
      erro = true;
    }

    if (!erro) {
      request(this.endpoints.SGCA_USUARIO + '/senha/' + this.state.field.uuid, {
        method: 'put',
        body: JSON.stringify(data)
      })
        .then(response => {
          if (response.status === 200) {
            return Promise.resolve();
          }
          return Promise.reject(response);
        })
        .then(() => {
          alertBox(this.props.t("messages.alterPasswordSuccess"), this.props.t("title"), 'success');
          this.refreshUsuario();
        })
        .catch(error => {
          alertBox(this.props.t("errors.alterPasswordError"), this.props.t("title"), 'error');
        });
      this.clearState();
      this.handleModal();
    }
  }

  contentModal() {
    if (this.state.typeModal == 'del') {
      return <DeleteItem {...this.state} delete={this.delete} type='uuid' handleModal={this.handleModal} />;
    }

    if (this.state.typeModal == 'up_senha') {
      return <UpdatePasswordModal
        uuid={this.state.field.uuid}
        strongPassword={this.state.field.senha_forte}
        onSuccess={this.refreshUsuario}
        closeModal={this.handleModal}
      />
    }
  }

  closeModal() {
    this.setState({
      modal: false,
      product: undefined,
      userModalOpen: false
    });
  }
  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {

      return (
        <Container
          showSideBar
          blur={this.state.userModalOpen}
          modal={this.state.modal}
          styleModal={!this.state.modalType ? null : this.state.style}
          contentModal={this.contentModal}
        >

          <UsersList
            ref='list'
            usuarios={this.state.usuarios}
            onOpenModal={this.onOpenModal}
            showAlert={this.showAlert}
            AD={this.state.AD} />

          {this.state.userModalOpen &&
            <UserModal
              open={this.state.userModalOpen}
              closeModal={this.closeModal}
              user={this.state.user}
              store={this.store}
              onSuccess={this.onSuccess}
            >
            </UserModal>
          }
        </Container>
      );
    }
  }
}

export default withTranslation("user")(withRouter(UsersPage));