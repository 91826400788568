import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.API_MIXPANEL_TOKEN || '', { track_pageview: true });

export const Mixpanel = {
  identify: (id) => mixpanel.identify(id),
  alias: (id) => mixpanel.alias(id),
  track: (name, props) => mixpanel.track(name, props),
  people: {
    set: (props) => mixpanel.people.set(props),
  },
};
