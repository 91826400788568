import { observable, action, computed } from "mobx";

class ContainerStore {
    @observable atualizar = false;

    @computed get Atualizar(){
        return this.atualizar;
    }

    @action
    setAtualizar = atualizar => {
        this.atualizar = atualizar;
    }
}

export default ContainerStore;
