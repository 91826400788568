import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { blueGradient } from '../Helpers/ChartJsHelper';
import AbstractSetupComponent from '../AbstractSetupComponent/AbstractSetupComponent';
import { withTranslation } from 'react-i18next';

const legendOpts = {
    display: false
};

class OEEComposicaoBarChart extends AbstractSetupComponent {

    constructor(props) {
        super(props);
        this.state = { matchScreen: window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').matches, toggle: true };
    }

    componentDidMount() {
        const handler = e => this.setState({matchScreen: e.matches});
        window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').addListener(handler);
    }

    formatOptions() {
        return {
            halfCornerRadius: true,
            animation: false,
            responsive: false,
            maintainAspectRatio: true,
            title: {
                display: true,
                text: this.props.t("charts.labels.composition")
            },
            layout: {
                padding: {
                    right: 60,
                    top: 0,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMax: 100,
                        suggestedMin: 0
                    },
                    gridLines: {
                        display: false,
                        lineWidth: 0,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                }],
                yAxes: [
                    {
                        categoryPercentage: .3,
                        // barPercentage: .4,
                        scaleLabel: {
                            display: false,
                            labelString: `< ${this.props.t("charts.labels.hours")} >`
                        },
                        gridLines: {
                            display: false,
                            drawBorder: true
                        },
                        ticks: {
                            display: false,
                        }
                    }
                ]
            },
            horizontalBarLabel: {
                display: true,
                marginLeft: 25
            },
            horizontalBarBackground: {
                backgroundColor: '#E7F3FF'
            },
            horizontalBarDataLabel: {
                display: true,
                fontSize: 14,
                paddingLeft: 5,
                align: 'end',
                formatter: (value) => value + '%'
            },
            tooltips: {
                displayColors: false,
                mode: 'index',
                intersect: false
            },
        };
    }

    formatData(data, canvas) {
        let azulEscuro = blueGradient(canvas);
        return {
            labels: [this.props.t("charts.labels.availability"), this.props.t("charts.labels.performance"), this.props.t("charts.labels.quality")],
            datasets: [{
                backgroundColor: azulEscuro,
                hoverBackgroundColor: azulEscuro,
                borderWidth: 0,
                data: [
                    this.resolveData("disponibilidade"),
                    this.resolveData("performance"),
                    this.resolveData("qualidade")
                ]
            }]
        }
    }

    render() {
        let data = (canvas) => this.formatData(this.props.data, canvas);
        let options = this.formatOptions();

        return (
            <HorizontalBar height={210} width={this.state.matchScreen ? 200 : 270} ref='chart' data={data} options={options} legend={legendOpts}></HorizontalBar>
        );
    }
}

export default withTranslation("oee")(OEEComposicaoBarChart);
