import { observable , action, computed } from "mobx";

class ModalItens {
    // Melhorias => melhorias.triggredAdd = triggerOnChange
    @observable customArray = [];
    @observable triggredAdd = false;

    @action
    setCustomArray = customArray => this.customArray = customArray;
    @action
    setTriggredAdd = triggredAdd => this.triggredAdd = triggredAdd;
    @action
    setInitialValues = () => {
        this.customArray = []
        this.triggredAdd = false
    }


    @computed get CustomArray() {
        return this.customArray;
    }

    @computed get TriggredAdd() {
        return this.triggredAdd;
    }
}

export default ModalItens;