import { observable, computed, action } from "mobx";
import moment from 'moment';
import { APIEndpoints } from '../../../common/Constants';
import { request } from "../../../common/request";

class AlertVigencia {
    // Necessario pois como existem dois tipos de invocar modal
    // Posteriormente realizar uma classe para o submit

    @observable customNoFunction
    @observable customYesFunction

    _formatarTimeMachineInserido(item) {
        let efetivo = moment(item.dataEfetivo).format('L');

        return (
            {
                text: efetivo,
                ativo: item.ativo,
                dataCriacao: item.dataCriacao,
                dataEfetivo: item.dataEfetivo,
                dataFim: item.dataFim ? moment(item.dataFim).format('YYYY-MM-DD HH:mm:ss') : null,
                id: item.id,
                observacao: item.observacao,
                originalId: item.originalId,
                originalUuid: item.originalUuid,
                usuario: item.usuario,
                uuid: item.uuid,
                vigenciaAnterior: { indexAnterior: null, date: null },
                destinoId: item.destino_id ? item.destino_id : item.destinoId,
                vigenciaAtiva: item.dataFim == null,
                novo: item.novo
            }
        )
    }

    _formatarTimeMachine(timeMachineArray) {
        return timeMachineArray.map(item => {
            let efetivo = moment(item.dataEfetivo).format('L');

            return (
                {
                    text: efetivo,
                    ativo: item.ativo,
                    dataCriacao: item.dataCriacao,
                    dataEfetivo: item.dataEfetivo,
                    dataFim: item.dataFim ? moment(item.dataFim).format('YYYY-MM-DD HH:mm:ss') : null,
                    id: item.id,
                    observacao: item.observacao,
                    originalId: item.originalId,
                    originalUuid: item.originalUuid,
                    usuario: item.usuario,
                    uuid: item.uuid,
                    vigenciaAnterior: { indexAnterior: null, date: null },
                    destinoId: item.destino_id ? item.destino_id : item.destinoId,
                    vigenciaAtiva: item.dataFim == null,
                    novo: item.novo
                }
            )
        })
    }

    _submit(values, method, url, header, storeTimeMachine, novo, setStateObjModalAoInserirTimeMachine) {
        request(`${APIEndpoints.TIMEMACHINE}${url}`, {
            method: method,
            headers: header,
            body: JSON.stringify(values)
        }).then(response => {
            if (response.status === 200) return response.json()
        }).then(data => {
            if (novo) {
                let arrayTimeMachine = [];
                let dataFormatada = this._formatarTimeMachineInserido(data);
                arrayTimeMachine.push(dataFormatada);

                setStateObjModalAoInserirTimeMachine(arrayTimeMachine);
                storeTimeMachine.setArrayItensCommon(arrayTimeMachine);
            }
            else if (data.length >= 1) {
                let dataFormatada = this._formatarTimeMachine(data);

                setStateObjModalAoInserirTimeMachine(dataFormatada);
                storeTimeMachine.setArrayItensCommon(dataFormatada);
            }
        });
    }

    _resetNewItensId(dataToSend) {
        return dataToSend.map(data => {
            return {
                originalId: data.originalId,
                originalUuid: data.originalUuid,
                dataEfetivo: moment(data.dataEfetivo),
                ativo: data.ativo,
                id: !data.destinoId || data.novo ? 0 : data.id,
                destinoId: data.destinoId,
                Observacao: data.observacoes,
                novo: data.novo
            }
        })
    }

    @action
    Yes = (itemIndex, stateArray, urlRequest, storeTimeMachine, setStateObjModalAoInserirTimeMachine) => {
        let arrayResetado = this._resetNewItensId(stateArray)
        let item = stateArray[itemIndex]

        // Pega o item no arrayResetado para verificar se ja possui no banco se sim entao ele lanca um post para exclui-lo
        let itemVerificacao = arrayResetado[itemIndex]

        stateArray.splice(itemIndex, 1)

        if (itemVerificacao.id != 0) {
            if (stateArray.length == 1) {
                let novoTimeMachineResetado = this._resetNewItensId(stateArray);
                let novoTimeMachine = novoTimeMachineResetado[0];

                if (novoTimeMachine.novo) {
                    this._submit(novoTimeMachine, 'post', urlRequest, { 'Content-Type': 'application/json' }, storeTimeMachine, novoTimeMachine.novo, setStateObjModalAoInserirTimeMachine);
                } else {
                    this._submit(item, 'post', urlRequest, { 'Content-Type': 'application/json' }, storeTimeMachine, false, setStateObjModalAoInserirTimeMachine)
                }
            } else {
                this._submit(item, 'post', urlRequest, { 'Content-Type': 'application/json' }, storeTimeMachine, false, setStateObjModalAoInserirTimeMachine)
            }
        }

        return stateArray
    }

    @action
    No = () => this.customNoFunction();


    @action
    setCustomNoFunction = customNoFunction => {
        this.customNoFunction = customNoFunction;
    }

    @action
    setCustomYesFunction = customYesFunction => {
        this.customYesFunction = customYesFunction;
    }

    @computed get CustomNoFunction() {
        return this.customNoFunction;
    }

    @computed get CustomYesFunction() {
        return this.customYesFunction
    }

}

export default AlertVigencia