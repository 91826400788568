import React, { Component } from 'react'
import moment from 'moment';
import { IF, NInputTime, NDatePickerInput, NButtonGroup, NButton, alertBox } from '../../NForm'
import Grid from '../../Grid/grid';
import Row from '../../Grid/row';
import styles from './formModal.scss';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import defaultStyle from '../../button.scss';
import { request } from '../../request';
import { hasRole } from '../../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@inject('store')
@observer
class FormModalVigenciasGenerico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            horaRequire: false,
            dataRequire: false,
            error: false,
            botaoDesabilitado: false
        };

        this.storeVigencia = this.props.storeVigencia
        this.modalItensStore = this.props.store.modalItens
        this.modalStore = this.props.store.modalCommon
        this.storeVigencia.setHoraInicial(moment(this.storeVigencia.Data).format('HH:mm:ss'), true);
        this._setObservacoes = this._setObservacoes.bind(this);
        this._getObservacoesValue = this._getObservacoesValue.bind(this);
        this._salvar = this._salvar.bind(this);
        this._resetNewItensId = this._resetNewItensId.bind(this);
        this._verificaVazio = this._verificaVazio.bind(this);
        this._getVigencias = this._getVigencias.bind(this);
        this._disabledSave = this._disabledSave.bind(this);
        this.desabilitarBotao = this.desabilitarBotao.bind(this);
        this.habilitarBotao = this.habilitarBotao.bind(this);
    }

    componentDidUpdate() {
        when(
            () => this.modalItensStore.setCustomArray(this.storeVigencia.ArrayItensCommon.slice()),
            () => this.modalItensStore.setTriggredAdd(true),
            () => this.modalItensStore.setTriggredAdd(false)
        )
    }

    _disabledSave() {
        return (!hasRole('ROLE_EQUIPAMENTOS_CRIAR') || !hasRole('ROLE_EQUIPAMENTOS_ATUALIZAR'))
    }

    _resetNewItensId(dataToSend) {
        return dataToSend.map(data => {
            return {
                originalId: data.originalId,
                originalUuid: data.originalUuid,
                dataEfetivo: moment(data.dataEfetivo),
                ativo: data.ativo,
                id: data.usuario == 0 ? 0 : data.id,
                destinoId: data.destinoId,
                Observacao: data.observacoes,
                novo: data.novo
            }
        })
    }

    _getVigencias(destinoId) {
        if (destinoId === undefined) return [];

        return new Promise((resolve, reject) => {
            request(`${this.props.endpoints.TIMEMACHINE}/${this.props.tipoTimeMachine}/vigencias/${destinoId}`, {}, 'get')
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    resolve(this.props.mapper(data));
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    _getVigenciaAtiva(originalId) {
        return request(`${this.props.endpoints.TIMEMACHINE}/${this.props.tipoTimeMachine}/vigencia-ativa/${this.props.tabelaBanco}/${originalId}`, {}, 'get')
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });
    }

    backToModal() {
        this._getVigenciaAtiva(this.storeVigencia.ArrayItensCommon[0].originalId)
            .then(vigencia => {
                this.props.changeContentModal('up', this.props.currentContent, vigencia)
            }
            );
    }

    _setObservacoes() {
        this.refs.textAreaRef.focus();
    }

    _getObservacoesValue() {
        this.storeVigencia.setObservacoes(this.refs.textAreaRef.value, true);
    }

    _salvar(event) {
        this.desabilitarBotao()
        event.preventDefault();

        if (this.storeVigencia.HoraInicial === '') {
            this.habilitarBotao();
            return;
        }

        this.storeVigencia.saveServer(this.props.vigenciaAtual, this.props.tipoTimeMachine, this.props.setStateEquipamento, this.props.idEquipamento).then(() => {
            this.backToModal();
            this.modalStore.setDisabled(false);
        }).catch((err) => {
            if (err.type == 'network' || err.type == 'invalidParam') {
                alertBox(this.props.t('timeMachine.messages.createError'), this.props.t('timeMachine.terms'), 'error');
            }

            if (err.type != 'validation') {
                this.backToModal()
            }
        }).finally(() => this.habilitarBotao())
    }

    _verificaVazio(event) {
        event.preventDefault();
        let stateArray = this._resetNewItensId(this.storeVigencia.ArrayItensCommon);
        if (stateArray.filter(state => state.id != 0).length > 0) {
            request(`${this.props.endpoints.EVENTO}/tempo-padrao-equipamento/${this.props.idEquipamento}`, {
                method: 'get'
            }).then(response => {
                return response.json();
            }).then(data => {
                this.props.setStateEquipamento(_.groupBy(data, (e) => e.vigencia.id))
                this.backToModal();
            });

            return;
        }
    }

    habilitarBotao() {
        this.setState({
            botaoDesabilitado: false
        })
    }

    desabilitarBotao() {
        this.setState({
            botaoDesabilitado: true
        })
    }

    render() {
        return (
            <div>
                <form ref={'formVigencia'}>
                    <Row>
                        <Grid cols="4 4 4">
                            <div className="labelVigenciaWrapper">
                                <label className="labelVigencia">
                                    {`${this.props.t('timeMachine.termStart')}:`.toUpperCase()}
                                    <IF test={this.storeVigencia.showTooltip}>
                                        <div className={styles.tooltip}>
                                            <i className="material-icons">help</i>
                                            <div className={styles.tooltiptext}>
                                                {this.props.t('timeMachine.messages.newTermStartDate', { day: this.storeVigencia.previousDate })}
                                            </div>
                                        </div>
                                    </IF>
                                </label>
                            </div>
                        </Grid>
                        <Grid cols="3 3 3">
                            <NDatePickerInput
                                id={`data_${this.storeVigencia.id}`}
                                label={this.props.t('timeMachine.date')}
                                value={moment(this.storeVigencia.Data).isValid() ? moment(this.storeVigencia.Data) : ''}
                                onChange={date => this.storeVigencia.setData(moment(date), true)}
                                onChangeRaw={(_, date) => this.storeVigencia.setData(moment(date), true)}
                                name={`data_${this.storeVigencia.id}`}
                            />
                        </Grid>
                        <Grid cols="3 3 3" style={{ marginBottom: -8 }}>
                            <NInputTime type='time'
                                fullWidth={true}
                                step={'1'}
                                name={`hora_${this.storeVigencia.id}`}
                                onChange={(event, value) => this.storeVigencia.setHoraInicial(value, true)}
                                floatingLabelStyle={{
                                    color: '#5B5B5B',
                                    textTransform: 'uppercase',
                                    background: '#fff', textOverflow: 'ellipsis',
                                    fontSize: 18
                                }}
                                value={this.storeVigencia.HoraInicial}
                                id={`hora_${this.storeVigencia.id}`}
                                label={this.props.t('timeMachine.hour')}
                                inputStyle={{ marginTop: 17 }} />
                        </Grid>
                        <Grid cols="2 2 2" />
                    </Row>
                    <Row>
                        <Grid cols="12 12 12">
                            <div className="labelRotaWrapper">
                                <label>
                                    {this.props.t('timeMachine.obs').toUpperCase()} <i className="fa fa-pencil" onClick={() => this._setObservacoes()}></i>
                                </label>
                            </div>
                        </Grid>
                    </Row>
                    <Row>
                        <Grid cols="12 12 12">
                            <textarea cols={49}
                                rows={6}
                                className={`TextArea`}
                                ref={"textAreaRef"}
                                value={this.storeVigencia.Observacoes || ""}
                                onChange={() => this._getObservacoesValue()}
                                maxLength={150}
                                name='observacoes' />

                        </Grid>
                    </Row>
                    <Row>
                        <Grid cols="11 11 11">
                            <div className="buttonWrapper">
                                <NButtonGroup>
                                    <NButton
                                        disabled={this.state.botaoDesabilitado}
                                        className={this.state.botaoDesabilitado ? defaultStyle.button_ghost_disable : ''}
                                        type="primary"
                                        onClick={event => this._salvar(event)}
                                    >
                                        {this.props.t('timeMachine.save')}
                                    </NButton>
                                    <NButton onClick={e => this._verificaVazio(e)}>{this.props.t('timeMachine.cancel')}</NButton>
                                </NButtonGroup>
                            </div>
                        </Grid>
                    </Row>
                </form>
            </div>
        )
    }
}

export default withTranslation('routes')(FormModalVigenciasGenerico)
