import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { makeGradient } from '../Helpers/ChartJsHelper';
import AbstractSetupComponent from '../AbstractSetupComponent/AbstractSetupComponent';

import { Colors, TextColors } from '../constants';
import { withTranslation } from 'react-i18next';

const legendOpts = {
    display: false
};

class ResultadoDoughnutChart extends AbstractSetupComponent {

    constructor(props) {
        super(props);
        this.state = { matchScreen: window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').matches, toggle: true };
    }

    componentDidMount() {
        const handler = e => this.setState({matchScreen: e.matches});
        window.matchMedia('(min-width: 1359px) and (max-width: 1855px)').addListener(handler);
    }
    
    formatOptions(data, matchScreen) {
        return {
            responsive: false,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: this.props.t("charts.labels.result")
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            layout: {
                padding: {
                    left: matchScreen ? 20 : 50,
                    right: matchScreen ? 40 : 50,
                    top: -5,
                    bottom: 10
                }
            },
            cutoutPercentage: 65,
            elements: {
                center: {
                    //textBaseLine: 'top',
                    textFontSize: 24,
                    textFontStyle: 'normal',
                    textFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

                    //dataLabelFontColor: '#D02E20',
                    dataLabelFontSize: 16,
                    dataLabelFontStyle: 'normal',
                    dataLabelFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                }
            }
        };
    }

    formatData(data, canvas) {
        let oeeInPercent = data.oeeIn / 100;
        let oeeOutPercent = data.oeeOut / 100;
        let gradient = makeGradient(canvas, data.startColor, data.endColor);

        return {
            datasets: [
                {
                    label: [
                        "BG",
                        "OUT"
                    ],
                    data: [50 * oeeOutPercent, 50 * (1 - oeeOutPercent)],
                    dataLabel: [
                        data.oeeOut + '%'
                    ],
                    backgroundColor: [
                        "#E3E3E3",
                        "transparent"
                    ],
                    hoverBackgroundColor: [
                        "#E3E3E3",
                        "transparent"
                    ],
                    borderColor: "#fff",
                    hoverBorderColor: "#fff",
                    borderWidth: 0,
                    padding: 4
                },
                {
                    center: data.percent,
                    centerTextColor: data.textColor,
                    label: [
                        "BG 1",
                        "OUT 1"
                    ],
                    data: [50 * oeeInPercent, 50 * (1 - oeeInPercent)],
                    backgroundColor: [
                        gradient,
                        data.backgroundColor
                    ],
                    hoverBackgroundColor: [
                        gradient,
                        data.backgroundColor
                    ],
                    borderWidth: 0
                }
            ]
        };
    }

    render() {    
        let color = this.hasData("cor") ? Colors[this.resolveData("cor")] : {};
        let textColor = TextColors[this.resolveData("cor")];
        let config = {
            percent: this.resolveData("real") + '%',
            oeeIn: Math.min(this.resolveData("real"), 100), 
            oeeOut: Math.min(this.resolveData("padrao"), 100), 
            startColor: color.startColor ? color.startColor : "transparent",
            endColor: color.endColor ? color.endColor : "transparent",
            backgroundColor: color.backgroundColor ? color.backgroundColor : "transparent",
            textColor: this.hasData("cor") ?  textColor: "transparent"
        };
        let data = (canvas) => this.formatData(config, canvas);
        let options = this.formatOptions(config, this.state.matchScreen);
        let size = this.state.matchScreen ? 180: 240;

        return (
            <Doughnut width={size} height={size} ref='chart' data={data} options={options} legend={legendOpts}></Doughnut>
        );
    }
}

export default withTranslation("oee")(ResultadoDoughnutChart);
