import React from 'react';
import { observer } from 'mobx-react';
import {
  AutoComplete,
  Card,
  SelectField,
  MenuItem,
  RaisedButton,
  Toggle,
} from 'material-ui';
import MDSpinner from 'react-md-spinner';
import moment from 'moment';
moment.locale('pt-br');
import $ from 'jquery';
import Notifier from './../../utils/Notifier';
import Container from './../../common/Container';
import { request, requestAll } from './../../common/request';
import { NDatePickerInput, alertBox } from './../../common/NForm';
import { respostaUnidadeIndustrial, respostaMonitoresEquipamento, respostaEventos } from './../../common/mappers';
import Select from 'react-select';
import _ from 'lodash';
import css from './styles.scss';
import BoxList from './components/BoxList';
import i18n from 'utils/i18n';

var intervaloAtualizacao;
var atualizarMonitor = 1;

@observer
export default class MonitorEquipamento extends Container {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalType: 'STATUS_FILTER',
      filtroEventos: [],
      filtroEventosTemp: [],
      eventos: [],
      atualizarAutomaticamente: false,
      modo: '',
      start: '',
      end: '',
      monitores: [],
      unidadeIndustrial: [],
      erroUnidade: '',
      autorizacao: true,
      loadingPage: true,
      erro_inicio: false,
      erro_fim: false,
      erro_equipamento: '',
      erro_modo: '',
      erroHoraInicio: '',
      erroHoraFim: '',
      horaInicio: '',
      horaFim: '',
      dataInicio: '',
      dataFim: '',
      loadingExport: false,
      mostrarTabela: false,
      orientacaoScroll: true //True Descer - False Subir
    };

    this.buscarMonitores = this.buscarMonitores.bind(this);
    this.carregarDados = this.carregarDados.bind(this);
    this.handleField = this.handleField.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this._valiDateStart = this._valiDateStart.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleSelectEvento = this.handleSelectEvento.bind(this);
    this.handleFilterEvento = this.handleFilterEvento.bind(this);
    this.alternarAtualizacaoAutomatica = this.alternarAtualizacaoAutomatica.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.gerar && document.getElementById('buscarMonitorEquipamento') != null) {
      if (atualizarMonitor == 1) {
        document.getElementById('buscarMonitorEquipamento').click();
      }

      atualizarMonitor = 2;
    }

    let scroll = document.getElementById('list_items_monitor');
    if (scroll != null) {
      let hasVerticalScrollbar = scroll.scrollHeight > scroll.clientHeight;

      if (hasVerticalScrollbar && scroll.scrollHeight - scroll.offsetHeight > 10) {
        var interval;
        var suave = 1;
        // true = descer / false = subir
        if (this.state.atualizarAutomaticamente && this.state.interval == null) {
          var inter = setInterval(() => {
            let scroll = document.getElementById('list_items_monitor');

            if (scroll) {
              if (this.state.orientacaoScroll && scroll.scrollTop + scroll.offsetHeight >= scroll.scrollHeight) {
                this.setState({ orientacaoScroll: !this.state.orientacaoScroll }); // chegou ao fim
              }

              if (!this.state.orientacaoScroll && scroll.scrollTop == 0) {
                this.setState({ orientacaoScroll: !this.state.orientacaoScroll }); // chegou ao inicio
              }

              if (this.state.orientacaoScroll) scroll.scrollTop += suave;
              if (!this.state.orientacaoScroll) scroll.scrollTop -= suave;
              if (!this.state.atualizarAutomaticamente) {
                clearInterval(inter);
                this.setState({ interval: null });
              }
            }
          }, 40);
          this.setState({ interval: inter });
        }
      } else {
        clearInterval(interval);
      }
    }
  }

  componentDidMount() {
    requestAll(
      [
        {
          url: this.endpoints.UNIDADE_INDUSTRIAL,
          modelo: 'sessions',
          modificador: respostaUnidadeIndustrial,
          acao: true,
        },
        {
          url: this.endpoints.UNIDADE_INDUSTRIAL,
          modelo: 'unidadeIndustrial',
          modificador: respostaUnidadeIndustrial,
          destino: 'state',
          acao: true,
        },
        {
          url: this.endpoints.EVENTO_ORDENADOS_POR_CODIGO,
          modelo: 'events',
          modificador: respostaEventos,
          acao: true,
        },
      ],
      this
    );
  }

  alternarAtualizacaoAutomatica(event, value) {
    this.setState({
      atualizarAutomaticamente: value
    });

    if (value) {
      intervaloAtualizacao = setInterval(() => {
        this.buscarMonitores();
      }, 300000
      );
    } else {
      clearInterval(intervaloAtualizacao);
    }
  }

  carregarDados() {
    const { unidade, dataInicio, dataFim, filtroEventos } = this.state;
    let eventosId = '';

    filtroEventos.forEach(evento => {
      eventosId += `&eventosId=${evento.value}`;
    });

    request(
      `${this.endpoints.MONITOR_EQUIPAMENTO}/${unidade.id}/${dataInicio}/${dataFim}?${eventosId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          monitores: respostaMonitoresEquipamento(data),
          loading: false
        });
      });
  }

  buscarMonitores() {
    let { unidade, modo, start, end, horaInicio, horaFim } = this.state;
    let erroCampo = false;
    let data_inicio, data_fim;

    this.setState({
      mostrarTabela: this.state.mostrarTabela ? this.state.mostrarTabela : true,
      monitores: this.state.monitores ? this.state.monitores : [],
      filtroEventos: this.state.filtroEventos ? this.state.filtroEventos : [],
      filtroEventosTemp: this.state.filtroEventosTemp ? this.state.filtroEventosTemp : [],
      erro_modo: modo ? '' : i18n.t('messages.requiredField', { ns: 'common' }),
      erro_unidade: unidade ? '' : i18n.t('messages.requiredField', { ns: 'common' }),
    });

    erroCampo = !(modo && unidade);

    if (unidade && modo) {
      switch (modo) {
        case 1:
          data_inicio = moment().format('DD-MM-YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 2:
          data_inicio =
            moment().subtract(1, 'day').format('DD-MM-YYYY') + ' 00:00:00';
          data_fim =
            moment().subtract(1, 'day').format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 3:
          data_inicio = moment().weekday(0).format('DD-MM-YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 4:
          data_inicio = '01-' + moment().format('MM-YYYY') + ' 00:00:00';
          data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';
          break;

        case 5:
          this.setState({
            erro_inicio: !start,
            erro_fim: !end,
          });

          horaInicio = '00:00';
          horaFim = '23:59';

          data_inicio = start.format('DD-MM-YYYY') + ' ' + horaInicio + ':00';
          data_fim = end.format('DD-MM-YYYY') + ' ' + horaFim + ':59';
          break;
      }

      if (
        horaFim < horaInicio &&
        moment(this.state.start).format('DD/MM/YYYY') >=
        moment(this.state.end).format('DD/MM/YYYY')
      ) {
        alertBox(
          'Data informada é menor que a Data inicial.',
          'Data Final Inválida',
          'error'
        );
        erroCampo = true;
      }

      if (moment(end).isBefore(moment(start))) {
        this.showAlert(
          'Período informado inválido, pois data fim está menor que data inicio!',
          'Atenção!',
          'warning'
        );
        erroCampo = true;
      }
      if (!erroCampo) {
        this.setState(
          {
            dataInicio: data_inicio,
            dataFim: data_fim,
            filtroEventos: this.state.filtroEventos ? this.state.filtroEventos : [],
            filtroEventosTemp: this.state.filtroEventosTemp ? this.state.filtroEventosTemp : [],
            loading: true,
          },
          () => this.carregarDados()
        );
      }
    }
  }

  handleField(key, event) {
    let { state } = this;
    state[key] = event.target.value;
    this.setState(state);
  }

  handleDate(key, event) {
    let { state } = this;
    state[key] = event;
    this.setState(state);
  }

  setUnidadeIndustrial(nomeUnidade, allUnd) {
    let { unidadeIndustrial } = this.state;

    let obj = unidadeIndustrial.filter((i) => i.name === nomeUnidade)[0];

    if (obj) {
      this.setState({
        unidade: obj,
        textUnidade: obj.text,
        filtroEventos: [],
      });
    }
  }

  handleComplete(complete, value, index) {
    let objValue = index.find((i) => i.text == value);
    this.setState({
      [complete]: objValue,
    });
  }

  showAlert(msg, title, type) {
    if (type == 'error') {
      Notifier.error(msg || 'Oops!', title || 'Notificação');
      setTimeout(3000);
    }

    if (type == 'success') {
      Notifier.success(msg || 'Oops!', title || 'Notificação');
      setTimeout(5000);
    }

    if (type == 'alert') {
      Notifier.warning(msg || 'Oops!', title || 'Notificação');
      setTimeout(5000);
    }
  }

  _valiDateStart(date) {
    if (date.length !== 10) {
      $('#dataFinal').val('');
      $('#horaInicial').val('');
      this.setState({
        start: '',
        end: '',
      });
      alertBox('Data Inicial incorreta.', 'Data Inicial', 'warning');
    } else if (date.length === 10) {
      var dateMoment = moment(date, 'DD/MM/YYYY');
      var dataMenor = moment(dateMoment).isAfter(moment());

      if (!dateMoment.isValid()) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');
        this.setState({
          start: '',
          end: '',
        });
        alertBox('Data Inicial incorreta.', 'Data Inicial', 'warning');
      }

      if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaInicial').val('');
        this.setState({
          start: '',
          end: '',
        });
        alertBox('Data Inicial está no futuro.', 'Data Inicial', 'warning');
      }
    }
  }

  _valiDateEnd(date) {
    if (date.length !== 10) {
      $('#dataFinal').val();
      $('#horaFim').val('');
      this.setState({
        end: '',
      });
      alertBox(
        'Data/Hora Final informada é inválida.',
        'Data/Hora Final Inválida',
        'warning'
      );
    } else if (date.length === 10) {
      var dateMoment = moment(date, 'DD/MM/YYYY');
      var dateNow = moment();
      var dataMenor = moment(dateMoment).isAfter(dateNow);
      var dateStart = moment(this.state.start).format('DD/MM/YYYY');
      var dataInicio = moment(
        dateStart + ' ' + this.state.horaInicio,
        'DD/MM/YYYY HH:mm'
      );

      var endDate = moment(
        date + ' ' + this.state.horaInicio,
        'DD/MM/YYYY HH:mm'
      );

      var dateRange = endDate.diff(dataInicio, 'days');

      if (dataMenor) {
        $('#dataFinal').val('');
        $('#horaFim').val('');
        this.setState({
          end: '',
          horaFim: '',
        });
        alertBox('Data/Hora Final está no futuro.', 'Data Final', 'warning');
      } else if (endDate.isBefore(dataInicio)) {
        $('#dataFinal').val('');
        $('#horaFim').val('');
        this.setState({
          end: '',
          horaFim: '',
        });
        alertBox(
          'Data/Hora Final informada é menor que a Data/Hora inicial.',
          'Data/Hora Final Inválida',
          'warning'
        );
      } else {
        this.setState({
          diferencaDias: dateRange,
        });
      }
    }
  }

  handleTime(end) {
    if (this.state.insDataInicial === '') {
      $('#dataFinal').val('');
      $('#horaFim').val('');
      $('#dataInicial').focus();
      this.setState({
        start: '',
        horaInicio: '',
      });
      alertBox('Preencha a data inicial.', 'Data Inicial', 'warning');
    } else this.setState({ end: end });
  }

  handleModal(type) {
    this.setState({
      modal: true,
      modalType: type,
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      filtroEventosTemp: this.state.filtroEventos,
      modalType: '',
    });
  }

  handleSelectEvento(value) {
    this.setState({ filtroEventosTemp: value });
  }

  handleFilterEvento() {
    const { filtroEventosTemp } = this.state;
    this.setState({
      filtroEventos: filtroEventosTemp,
      monitores: [],
      loading: true
    }, () => {
      this.closeModal();
      this.carregarDados();
    });
  }

  contentModal() {
    const { modalType, filtroEventosTemp } = this.state;
    const opcoesEvento = this.store.events.map(evento => ({
      value: evento.id,
      label: `${evento.cod} - ${evento.descr}`,
    }));

    switch (modalType) {
      case 'STATUS_FILTER':
        return <div className={`modal ${css.box_modal_filter}`}>
          <div className={css.title_filter}>Filtro de Eventos</div>

          <div className={css.box_modal_input}>
            <h3> Eventos </h3>
            <Select
              name="grupoUsuario"
              placeholder=''
              noResultsText='Nenhum registro encontrado'
              multi
              onChange={(value) => this.handleSelectEvento(value)}
              value={filtroEventosTemp}
              options={opcoesEvento}
            />
          </div>

          <div className={`flex flex-just-end ${css.box_modal_button}`}>
            <RaisedButton onClick={this.handleFilterEvento} backgroundColor='#2D4F7F' className={css.modal_button} labelColor='#FFF' label={i18n.t('labels.apply', { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal} backgroundColor='#FFF' className={css.modal_button} style={{ marginLeft: '1em' }} label={i18n.t('labels.cancel', { ns: 'common' })} />
          </div>
        </div>;
    }
  }

  render() {
    const {
      monitores,
      unidadeIndustrial,
      loadingPage,
      loading,
      mostrarTabela,
      filtroEventos,
      modal
    } = this.state;

    const unidadeOptions = unidadeIndustrial.map((e) => ({
      ...e,
      value: e.id,
      text: e.name,
    }));

    if (loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className='loading-wrapper'>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container sectionStyle={{ width: '100%' }} modal={modal} contentModal={this.contentModal}>
          <Card>
            <div className={css.input_container}>
              <div className={css.row_config_1}>
                <div className={css.equipament_input}>
                  <AutoComplete
                    textFieldStyle={{ marginTop: '-10px' }}
                    fullWidth={true}
                    errorText={this.state.erro_unidade}
                    underlineStyle={
                      this.state.erro_unidade ? { borderColor: 'red' } : ''
                    }
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    floatingLabelStyle={{ color: '#5B5B5B' }}
                    listStyle={{ width: '100%' }}
                    floatingLabelText='Unidade Industrial'
                    title='Unidade Industrial'
                    onUpdateInput={this.setUnidadeIndustrial.bind(this)}
                    filter={AutoComplete.fuzzyFilter}
                    dataSource={unidadeOptions || []}
                    onClick={() =>
                      this.setState({
                        textUnidade: '',
                      })
                    }
                    openOnFocus={true}
                    popoverProps={{ className: 'autocomplete' }}
                  />
                </div>
                <div className={css.periodo_input}>
                  <SelectField
                    style={{ margin: '-8px 0 0 5px' }}
                    hintText='Período'
                    fullWidth={true}
                    errorText={this.state.erro_modo}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    underlineStyle={
                      this.state.erroPeriodo ? { borderColor: 'red' } : ''
                    }
                    floatingLabelText='Período'
                    title='Período'
                    floatingLabelStyle={{ color: '#5B5B5B' }}
                    value={this.state.modo}
                    onChange={(...props) =>
                      this.setState({
                        modo: props[2],
                        end: null,
                        start: null,
                        diferencaDias: props[2] === 3 ? 360 : 0,
                      })
                    }
                  >
                    <MenuItem value={1} primaryText='Hoje' />
                    <MenuItem value={2} primaryText='Ontem' />
                    <MenuItem value={3} primaryText='Esta semana' />
                    <MenuItem value={4} primaryText='Este mês' />
                    <MenuItem value={5} primaryText='Personalizar' />
                  </SelectField>
                  {this.state.erroPeriodo ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        lineHeight: '12px',
                        position: 'relative',
                        top: '-1',
                      }}
                      ref='validate'
                    >
                      {i18n.t('messages.requiredField', { ns: 'common' })}
                    </div>
                  ) : null}
                </div>
                {this.state.modo === 5 ? (
                  <div
                    className={`${css.input_data} flex flex-wrap`}
                    style={{ marginLeft: '10px', marginTop: '-7px' }}
                  >
                    <NDatePickerInput
                      className={css.data_inicio_input}
                      floatingLabelStyle={{ marginBottom: 0 }}
                      style={{
                        paddingTop: '0',
                        paddingLeft: '5px',
                        width: '115px',
                      }}
                      require={this.state.erro_inicio}
                      id='dataInicial'
                      value={this.state.start}
                      autoComplete={'off'}
                      onChange={(date) =>
                        this.setState({ start: date, end: '', horaFim: '' })
                      }
                      onChangeRaw={(event, value) =>
                        this.setState({ start: value })
                      }
                      onBlur={(event) =>
                        this._valiDateStart(event.target.value)
                      }
                      maxDate={moment()}
                      label='Data Inicial'
                    />

                    <NDatePickerInput
                      className={css.data_final_input}
                      floatingLabelStyle={{ marginBottom: 0 }}
                      style={{
                        paddingTop: '0',
                        paddingLeft: '5px',
                        width: '115px',
                      }}
                      require={this.state.erro_fim}
                      id='dataFinal'
                      value={this.state.end}
                      minDate={this.state.start}
                      autoComplete={'off'}
                      onChange={(date) => {
                        this.handleTime(date);
                        this.setState({ horaFim: '' });
                      }}
                      onChangeRaw={(event, value) =>
                        this.setState({ end: value })
                      }
                      onBlur={(event) => this._valiDateEnd(event.target.value)}
                      maxDate={moment(this.state.start).add(90, 'days').isSameOrAfter(moment()) ? moment() : moment(this.state.start).add(90, 'days')}
                      label='Data Final'
                    />
                  </div>
                ) : null}
                <div className={css.btn_generate}>
                  <RaisedButton
                    onClick={() => this.buscarMonitores()}
                    backgroundColor='#2D4F7F'
                    id={'buscarMonitorEquipamento'}
                    disabled={
                      this.state.modo == 6
                        ? this.state.end == '' && this.state.horaFim == 0
                          ? true
                          : false
                        : false
                    }
                    buttonStyle={{ fontFamily: '' }}
                    style={{ fontWeigth: 100, marginLeft: '1em' }}
                    labelColor='#FFF'
                    label='GERAR'
                  />
                </div>
              </div>
              <div className='flex-row flex flex-align-center flex-just-end'>
                <div
                  className={`${css.d_none_mobile} inline-flex ${css.action_buttons}`}
                >
                  <div>
                    <Toggle
                      label='Atualizar Automaticamente'
                      onToggle={this.alternarAtualizacaoAutomatica}
                      toggled={this.state.atualizarAutomaticamente}
                      thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                      trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                      className={'flex'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {loading ? (
            <div className={'loading-wrapper ' + css.loading_monitor}>
              <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
            </div>
          ) : (monitores.length || mostrarTabela) ? (
            <BoxList
              items={monitores}
              handleModal={this.handleModal}
              filterMarketed={filtroEventos && filtroEventos.length}
              filtro={{
                unidadeId: this.state.unidade.id,
                dataInicio: this.state.dataInicio,
                dataFim: this.state.dataFim
              }}
              endpoints={this.endpoints}
              store={this.store}
            />
          ) : null}
        </Container>
      );
    }
  }
}
