import equipments from './equipments.json'
import products from './products.json'
import holdingTime from './holdingTime.json'
import user from './user.json'
import groupUser from './groupUser.json'
import customFields from './customFields.json'
import routes from './routes.json'
import notes from './notes.json'
import events from './events.json'
import shifts from './shifts.json'
import parameters from './parameters.json'
import groupEquipment from './groupEquipment.json'
import maintenance from './maintenance.json'
import availability from './availability.json'
import groupEvent from './groupEvent.json'
import stages from './stages.json'
import batches from './batches.json'
import flowDashboard from './flowDashboard.json'
import flowView from './flowView.json'
import batchesLink from './batchesLink.json'
import oee from './oee.json'
import reports from './reports.json'
import fields from './fields.json'
import sessions from './sessions.json'
import dataCollection from './dataCollection.json'

export {
    equipments,
    products,
    holdingTime,
    user,
    groupUser,
    customFields,
    routes,
    notes,
    events,
    shifts,
    parameters,
    groupEquipment,
    maintenance,
    availability,
    groupEvent,
    stages,
    flowDashboard,
    flowView,
    batches,
    batchesLink,
    oee,
    reports,
    fields,
    sessions,
    dataCollection
}

export default {
    equipments,
    products,
    holdingTime,
    user,
    groupUser,
    customFields,
    routes,
    notes,
    events,
    shifts,
    parameters,
    groupEquipment,
    maintenance,
    availability,
    groupEvent,
    stages,
    flowDashboard,
    flowView,
    batches,
    batchesLink,
    oee,
    reports,
    fields,
    sessions,
    dataCollection
}