import React from 'react';
import { observable, action, computed } from "mobx";
import moment from 'moment';
import Utils from "../../Utils/Utils";
import { alertBox } from '../../../common/NForm';
import DeleteVigencia from "../../../common/Alerta/customAlert/timeMachine/Generico/deleteVigencia";
import AlertContainer from '../../../common/Alerta/alertContainer';
import AlertContent from '../../../common/Alerta/alertContent';
import DeleteLastVigencia from '../../../common/Alerta/customAlert/timeMachine/Generico/deleteLastVigencia';
import i18n from 'utils/i18n';

class ModalControlVigencias extends Utils {
  constructor(props) {
    super(props);
    this.handleNoFunctionTimeMachine = this.handleNoFunctionTimeMachine.bind(this);
  }

  //arrayItensCommon  ocar o nome para state
  @observable novaVigencia = {};

  // Crio dicionario para eu saber qual vigencia anterior estou ligado em meu estado atual 
  _createMapVigenciaAnterior(indexAnterior, date) {
    return {
      indexAnterior: indexAnterior,
      date: date
    }
  }

  // Necessário pois utiliza dois tipos de abrir modal o antigo e o novo
  handleNoFunctionTimeMachine(storeModalCommon, storeTimeMachine, stateObj, handleModal, dataPosition) {
    storeModalCommon.close()

    handleModal('editVigencia', storeTimeMachine.ArrayItensCommon);
  }

  // @Param itemAnterior é um dicionario para saber qual itens estão ligados para fazer a validação de choque de datas Ex: { indexAnterior: indexAnterior, date: date }
  // Ele é salvo no registro filho e não no pai, no final realizo uma verificação para popular os pais com os respectivos index no meu estado
  @action
  setNovaVigencia = (itemAnterior, vigenciaAtual) => {
    let now = new Date()
    let novaVigenciaObject = {
      text: moment().format('L'),
      ativo: true,
      dataCriacao: '',
      dataEfetivo: moment().format('YYYY-MM-DD HH:mm:ss'),
      dataFim: '',
      id: parseFloat(parseFloat(now.getMilliseconds() * Math.random()).toFixed(3)),
      observacao: '',
      originalId: vigenciaAtual.originalId,
      originalUuid: vigenciaAtual.originalUuid,
      usuario: 0,
      uuid: '',
      alterado: true,
      novo: true,
      vigenciaAnterior: itemAnterior,
      showTooltip: _.isEmpty(itemAnterior) ? false : true
    }

    this.novaVigencia = novaVigenciaObject
  }

  //Função que manipula os dois lados dos modais usando stores destintas
  @action
  connectionModalRightLeft(novaVigencia, storeToSaveValues, storeModalItens) {
    storeToSaveValues.saveValues(true)
    storeToSaveValues.showValuesOnUpdate(novaVigencia)
    storeModalItens.setCustomArray(storeToSaveValues.ArrayItensCommon)
    storeToSaveValues.previousDate = this.getPreviousDate(novaVigencia, storeToSaveValues)
  }

  //Metodo responsavel por pegar a ultima data de vigencia ativa
  @action
  getPreviousDate(selectedItem, storeToSaveValues) {
    let orderedDates = storeToSaveValues.ArrayItensCommon
      .sort((a, b) => moment(a.dataEfetivo).format() - moment(b.dataEfetivo).format())
      .map(item => moment(item.dataEfetivo).format())

    let previousDate = orderedDates.reverse().find(date => date < moment(selectedItem.dataEfetivo).format())
    storeToSaveValues.showTooltip = !!previousDate

    if (!!previousDate) {
      return moment(previousDate).format('L')
    }

    return undefined
  }

  // Index do qual sera deletado
  // StoreTimeMachine = Refere-se a store do time machine o qual está enviando o comando
  // StoreLastAlertaVigencia = Refere-se a Store de alerta de ultima vigencia ativa
  // StoreAlertaVigencia = Refere-se a Store de alerta de deleção de vigencia
  // StoreModalCommon = Refere-se a Store comum de controle de modal
  // TituloDeleteVigencia = Nome que aparecerá no alerta Ex.: Do Equipamento
  // SubtituloDeleteVigencia = Nome que aparecerá na mensagem de ultima vigencia Ex.: O Equipamento
  // Gender = Refere-se ao genero que será utilizado na palavra Inativad(o/a)
  // HandleModal = Refere-se ao metodo do construtor para abrir/fechar ou mudar o conteudo do modal
  // StateObjModal = Refere-se a state do metodo construtor
  // DataPosition = Posição da data no array
  // UrlRequest = Url do time machine a ser excluido
  @action
  deleteTimeMachine(
    index, storeTimeMachine, storeLastAlertaVigencia, storeAlertaVigencia, storeModalCommon,
    tituloDeleteVigencia, subtituloDeleteVigencia, gender, handleModal, changeContentModal, stateObjModal, dataPosition, urlRequest, podeDeletarUltimaVigencia, setStateObjModalAoInserirTimeMachine, currentContent) {

    // Realizar a construção via Factory
    var noFunction = () => this.store.modalControlVigenciasStore.handleNoFunctionTimeMachine(storeModalCommon, storeTimeMachine, stateObjModal, changeContentModal, dataPosition)
    storeAlertaVigencia.setCustomNoFunction(noFunction)

    var alert = <DeleteVigencia storeTimeMachine={storeTimeMachine} storeModalCommon={storeModalCommon} storeAlertaVigencia={storeAlertaVigencia} urlRequest={urlRequest} setStateObjModalAoInserirTimeMachine={setStateObjModalAoInserirTimeMachine} />
    var title = `${i18n.t('timeMachine.messages.deleteConfirm', { ns: 'routes' })} ${tituloDeleteVigencia}`;
    var subtitle = i18n.t("timeMachine.messages.deleteQuestion", { ns: 'routes' });

    if (storeTimeMachine.ArrayItensCommon.length == 1) {
      if (podeDeletarUltimaVigencia) {
        alert = <DeleteLastVigencia storeTimeMachine={storeTimeMachine} storeModalCommon={storeModalCommon} storeLastAlertaVigencia={storeLastAlertaVigencia} urlRequest={urlRequest} />
        title = i18n.t("timeMachine.messages.deleteQuestion", { ns: 'routes' })
        subtitle = `${subtituloDeleteVigencia} não possui outra vigência, ao excluir esta vigência ${subtituloDeleteVigencia.toLowerCase()} será inativad${gender}`
        storeLastAlertaVigencia.setCustomNoFunction(noFunction)
      } else {
        alertBox(i18n.t("timeMachine.messages.deleteLastTerm", { ns: 'routes' }), i18n.t("timeMachine.terms", { ns: 'routes' }), 'error')
      }
    }

    if (storeTimeMachine.ArrayItensCommon.length != 1) {
      let alertContent = <AlertContainer>
        <AlertContent title={title} subtitle={subtitle}>
          {alert}
        </AlertContent>
      </AlertContainer>

      handleModal(currentContent, '');
      storeModalCommon.open(alertContent, [{ itemIndex: index, commonState: storeTimeMachine.ArrayItensCommon }])
    }
  }

  // StoreModalItens = Refere-se a store comum de controle de itens do modal
  // StoreTimeMachine = Refere-se a store do time machine o qual está enviando a ação
  // VigenciaAtual = Refere-se ao objeto de vigencia atual
  @action
  addNewTimeMachine(storeModalItens, storeTimeMachine, vigenciaAtual) {
    let oldItens = storeModalItens.CustomArray.slice();
    let vigenciaAnterior = this._createMapVigenciaAnterior((oldItens.length - 1), new Date())

    this.setNovaVigencia(vigenciaAnterior, vigenciaAtual);
    let novaVigencia = this.NovaVigencia;

    oldItens.push(this.novaVigencia);

    storeModalItens.setCustomArray(oldItens);
    storeTimeMachine.setArrayItensCommon(oldItens);
    storeModalItens.setTriggredAdd(true);

    this.connectionModalRightLeft(novaVigencia, storeTimeMachine, storeModalItens);

    storeModalItens.previousItem = storeModalItens.currentItem;
    storeModalItens.currentItem = oldItens.findIndex(item => item == novaVigencia);
  }

  @computed get NovaVigencia() {
    return this.novaVigencia
  }
}

export default ModalControlVigencias;