import { observable, computed, action } from "mobx";

import Utils from "../../Utils/Utils";
import { APIEndpoints } from '../../../common/Constants';

class AlertLastVigenciaRotas extends Utils {
    @observable customNoFunction = undefined
    @observable customYesFunction = undefined


    _checkEmpty(produtoId){
        super.submit(produtoId, 'post', `${APIEndpoints.TIMEMACHINE}/rotas/vigencia/excluirRota/${produtoId}`, { 'Content-Type': 'application/json' })
    }

    @action
    Yes(item) {
        if (this.CustomYesFunction) {
            // Um array de comandos para tomar atitudes diferentes
            let dispatch = this.customYesFunction()
            switch (dispatch[0]) {
                case 'checkEmpty':
                    this._checkEmpty(dispatch[1])
                    break;
                default:
                    break;
            }
            return;
        }
        
        super.submit(item, 'post', `${APIEndpoints.TIMEMACHINE}/rotas/vigencia/excluirRota`, { 'Content-Type': 'application/json' })
    }

    @action
    No = () => {
        this.customNoFunction();
    }

    @action
    setCustomNoFunction = customNoFunction => {
        this.customNoFunction = customNoFunction;
    }
    @action
    setCustomYesFunction = customYesFunction => {
        this.customYesFunction = customYesFunction;
    }

    @computed get CustomNoFunction(){
        return this.customNoFunction;
    }
    @computed get CustomYesFunction(){
        return this.customYesFunction;
    }
}

export default AlertLastVigenciaRotas