import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TimeConversion from '../../utils/TimeConversion';
import styles from './route.scss';

const {THours} = TimeConversion;

@observer
export default class ProductStage extends Component {
  render() {
    let {routes} = this.props;
    let com_equipamento = routes.length > 0 ? routes[0].com_equipamento : true;

    return (
      com_equipamento ?
        <ul className={styles.list_equipments}>
          {
            routes.map((route, i) => {
              var nomeTratado = route.equipment;
              if (nomeTratado.length > 32) {
                nomeTratado = nomeTratado.substr(0,32-3) + '...';
              }

              return (
                <li key={i}>
                  <div>
                    {route.master?<i className="fa fa-star"/>:<i className="fa fa-star-o"/>}
                  </div>
                  <div className={styles.cycle}>
                    <i className="fa fa-history"/>
                    <span>{route.cycle}</span>
                  </div>
                  <div title={nomeTratado.indexOf("...") != -1 ? route.equipment : null}>
                    {nomeTratado}
                  </div>
                  <div>
                    <i className="fa fa-clock-o"/> {THours(route.wait_time)}
                  </div>
                  <div>
                    <i className="fa fa-clock-o"/> {THours(route.proc_time)}
                  </div>
                </li>
              );
            })
          }
        </ul>
      : <ul className={styles.list_without_equipments}>
          <li>
            <div>
              {this.props.t("productStage.stageWithoutEquipment")}
            </div>
          </li>
       </ul>
    );
  }
}