var APIAplicacao = process.env.API_APLICACAO_URL;
var APIV2 = process.env.APIV2_URL;
var CLIENTV2 = process.env.CLIENTV2_URL;
var TAELOR_URL = process.env.TAELOR_URL;

module.exports = {
    APIEndpoints: {
        APONTAMENTO: APIV2 + '/apontamento',
        APONTAMENTO_PROCESSAR: APIV2 + '/apontamento/processar',
        SUGGESTION_SEQUENCE: APIV2 + '/smart-setup',
        APONTAMENTOS_VALIDAR: APIV2 + '/apontamento/avaliar',
        APONTAMENTOS_EQUIPAMENTO: APIV2 + '/apontamento/equipamento',
        APONTAMENTOS_LOTE_EQUIPAMENTO: APIV2 + '/apontamento/buscar-por-lote-equipamento',
        AREA_PRODUTIVA: APIV2 + '/area-produtiva',
        CAMPOS_PERSONALIZADOS: APIV2 + '/campo-personalizado',
        CONFIGURACAO_COLETOR_TEMPO_PARADA: APIV2 + '/configuracao/tempo-parada',
        CONFIGURACAO_PARAMETROS: APIV2 + '/configuracao/parametros',
        CONFIGURACAO_PARAMETROS_TABLET: APIV2 + '/configuracao/parametros/tablet',
        CONFIGURACAO_TABLET_QT_PRODUZIDA_FIM_DE_ORDEM: APIV2 + '/configuracao/parametros/tablet-produzida-fim-de-ordem',
        CLASSES: APIV2 + '/classe',
        DADOS_COLETA: APIV2 + '/dados-coleta',
        ETAPA: APIV2 + '/etapa',
        EQUIPAMENTOS: APIV2 + '/equipamento',
        EVENTO: APIV2 + '/evento',
        EVENTO_ORDENADOS_POR_CODIGO: APIV2 + '/evento/ordenados-por-codigo',
        FAVORITO: APIV2 + '/lote/favorito',
        GRUPO_EQUIPAMENTO: APIV2 + '/grupo-de-equipamento',
        HISTORICO_ACESSO: APIV2 + '/historico-acesso',
        FLOWVIEW: APIV2 + '/flow-view',
        GARGALO: APIV2 + '/gargalo',
        GRUPO_EVENTO: APIV2 + '/grupo-de-evento',
        HOLDING_TIME: APIV2 + '/holding-time',
        HOLDING_TIME_CALCULADO: APIV2 + '/holding-time/lote/porcentagem',
        HOLDING_TIMES: APIV2 + '/holding-time/produto/{id}',
        LINK_PI_PA: APIV2 + '/link-pi-pa',
        LEAD_TIME: APIV2 + '/leadtime',
        LOTE_NUMERO: APIV2 + '/lote/numero',
        LOTES: APIV2 + '/lote',
        MANUTENCAO_OEE: APIV2 + '/oee/manutencao',
        MANUTENCAO: APIV2 + '/manutencao',
        MONITOR_EQUIPAMENTO: APIV2 + '/equipamento/monitor-equipamentos',
        MOVIMENTACAO_LOTE: APIV2 + '/movimentacao-lote-flow-view',
        META_DIARIA: APIV2 + '/parametrizar/meta-diaria',
        OEE: APIV2 + '/oee',
        OEE_SETUP_FLOW_VIEW: APIV2 + '/oee/setup/flow-view',
        OPCOES_CALCULO: APIV2 + '/opcoes-de-calculo',
        PARAMETROS: APIV2 + '/parametro',
        PERIODO_VISAO_TABLET: APIV2 + '/parametro/periodo-visao-tablet',
        PERIODO_TIMELINE_TABLET: APIV2 + '/parametro/periodo-timeline-tablet',
        PRODUTO: APIV2 + '/produto',
        PRODUTO_LAZY: APIV2 + '/produto/lazy',
        PRODUTO_PERFORMANCE: APIV2 + '/produto/performance',
        PRODUTOS_PIS: APIV2 + '/produto/{id}/pis',
        RELATORIO: APIV2 + '/relatorio',
        RELATORIO_APONTAMENTO: APIV2 + '/relatorio/apontamento',
        RELATORIO_DESVIO_HOLDING_TIME: APIV2 + '/relatorio/holding-time/desvio',
        RELATORIO_DESVIO_ROTA: APIV2 + '/relatorio/rota/desvio',
        RELATORIO_ROTAS_AFETADAS: APIV2 + '/relatorio/rota/afetadas-unidade-industrial',
        RELATORIO_GARGALO: APIV2 + '/relatorio/gargalo',
        RELATORIO_LOTES_INCONSISTENTES: APIV2 + '/relatorio/lote/inconsistentes',
        RELATORIO_OEE: APIV2 + '/relatorio/oee',
        RELATORIO_OEE_POR_ORDEM: APIV2 + '/relatorio/oee/ordem',
        RELATORIO_SETUP: APIV2 + '/relatorio/setup',
        RELATORIO_AUDIT_TRAIL: APIV2 + '/relatorio/audit-trail',
        RELATORIO_CADASTRO: APIV2 + '/relatorio/cadastro',
        RELATORIO_FLUXO: APIV2 + '/relatorio/fluxo',
        RELATORIO_GAPS: APIV2 + '/relatorio/apontamento/gaps',
        RELATORIO_ANOMALIAS: APIV2 + '/relatorio/equipamento/anomalias',
        RELATORIO_SENSORES: APIV2 + '/relatorio/sensor',
        ROTA_PRODUTO: APIV2 + '/rota/produto/{id}',
        ROTA: APIV2 + '/rota',
        SGCA_AUTENTICAR: APIV2 + '/autorizar',
        SGCA_AUTENTICAR_TAELOR: APIV2 + '/autorizar/taelor',
        SGCA_AUTENTICAR_CATEGORIA: APIV2 + '/autorizar/validar/categoria',
        SGCA_DESLOGAR: APIV2 + '/autorizar/deslogar',
        SGCA_GRUPO: APIV2 + '/grupo',
        SGCA_PERMISSOES: APIV2 + '/permissao',
        SGCA_PERMISSOES_GRUPO: APIV2 + '/permissao/grupo/{uuid}',
        SGCA_USUARIO: APIV2 + '/usuario',
        GRUPO_APONTAMENTO: APIV2 + '/grupo-apontamento',
        TURNO: APIV2 + '/turno',
        UNIDADE_INDUSTRIAL: APIV2 + '/unidade-industrial',
        FLOW_VIEW: APIV2 + '/flow-view',
        CONFIGURACAO_EQUIPAMENTO: APIV2 + '/configuracao/equipamento/limite',
        TIMEMACHINE: APIV2 + '/time-machine',
        VERSAO: APIV2 + '/configuracao/versao',
        INDICADORES_PERSONALIZADOS: APIV2 + '/indicador-personalizado',
        TIPO_LOTE: APIV2 + '/tipo-lote',
        UPLOAD: APIV2 + '/upload',
        COMENTARIO_LOTE: APIV2 + '/lote/comentar',
        RELATORIO_PARETO: APIV2 + '/relatorio/pareto',
        // API APLICAÇÃO
        ACTIVEDIRECTORY_VALIDAR: APIV2 + '/active-directory/validar',
        MOVIDESK_MODULO: APIV2 + '/kb/modulo/{modulo}',
        MOVIDESK_REDIRECT: APIV2 + '/kb/{id}'
    },

    // Chaves para origem do apontamento.
    // Estarão disponíveis globalmente via props no componente
    // Container, assim como APIEndpoints.
    // O primeiro item deve ter valor 0 (zero) e os subsequentes
    // devem ser sequenciais, para serem compatíveis com a Enum 
    // no core a aplicação. 
    OrigemApontamento: {
        WEB: 0,
        MANUTENCAO_APONTAMENTO: 1,
        TABLET_AUTO: 2,
        TABLET_SEMI_AUTO: 3
    },

    Paginacao: {
        ITENS_POR_PAGINA: 15,
        ITENS_POR_PAGINA_APONTAMENTOS_TIMELINE: 30
    },

    FormatoResultado: {
        INTEIRO: { id: 1, label: 'customIndicatorForm.integer' },
        HORAS: { id: 2, label: 'customIndicatorForm.hours' },
        MINUTOS: { id: 3, label: 'customIndicatorForm.minutes' },
        PORCENTAGEM: { id: 4, label: 'customIndicatorForm.percentage' },
        DECIMAL: { id: 5, label: 'customIndicatorForm.decimal' }
    },

    CLIENTV2,

    TAELOR_URL
};