import React, { Component } from 'react';
import styles from './ListBatchesMobile.css';
import utils from '../../utils/Utils';
import { withTranslation } from 'react-i18next';

class ListBatchesMobile extends Component {
  render() {
    let {referenciaSecundaria} = this.props;
    
    return(
      <div>
        { (this.props.listWaitingOrder.length + this.props.listProcessOrder.length) <= 0 ?
          <div style={{background: '#fff', padding: '3em'}} >{this.props.t("messages.noBatchesInEquipmentQueue", { primaryReference: utils.getReferenciaLotesOrdens() })}</div>
          :
          <div className={styles.list__container}>
              {
                this.props.listWaitingOrder.map((e,i) => {

                  let numeroSecundario = e.num_secundario != null ? e.num_secundario : '-';
                  let referenciaSecundariaFormatada = utils.getReferenciaOrdemLote() != '' ? <p><span className={styles.list__title}>{`${utils.getReferenciaOrdemLote()}:`}</span>{numeroSecundario}</p> : null;

                  if (e.type == 'waiting') {
                    return <div key={e.fk_id_batches}>
                      <div className={styles.list}>
                      <hr/>
                        <p>{this.props.t("labels.queuePositionWithCount", { count: i + 1 })}</p>
                        <p><span className={styles.list__title}>{this.props.t("labels.SKU")}:</span>{e.sku}</p>
                        <p><span className={styles.list__title}>{this.props.t("labels.product")}:</span>{e.product}</p>
                        <p><span className={styles.list__title}>{`${utils.getReferenciaLoteOrdem()}:`}</span>{e.num}</p>
                        {referenciaSecundariaFormatada}
                      </div>
                    </div>
                  } else {
                    return <div key={e.fk_id_batches} >
                      <div className={styles.list}>
                        <hr/>
                        <p>{this.props.t("labels.queuePositionWithCount", { count: i + 1 })}</p>
                        <p><span className={styles.list__title}>{this.props.t("labels.SKU")}:</span>{e.sku}</p>
                        <p><span className={styles.list__title}>{this.props.t("labels.product")}:</span>{e.product}</p>
                        <p><span className={styles.list__title}>{`${utils.getReferenciaLoteOrdem()}:`}</span>{e.num}</p>
                        {referenciaSecundariaFormatada}
                      </div>
                    </div>
                  }
                }).reverse()
              }

              {
                this.props.listProcessOrder.map((e,i) => {
                  let numeroSecundario = e.num_secundario != null ? e.num_secundario : '-';
                  let referenciaSecundariaFormatada = utils.getReferenciaOrdemLote() != '' ? <p><span className={styles.list__title}>{`${utils.getReferenciaOrdemLote()}:`}</span>{numeroSecundario}</p> : null;

                  if (e.type == 'warning') {
                    return <div key={e.fk_id_batches}>
                      <div className={styles.list}>
                      <hr/>
                        <p>
                          {this.props.t("labels.processing")}
                        </p>
                        <p><span className={styles.list__title}>{this.props.t("labels.SKU")}:</span>{e.sku}</p>
                        <p><span className={styles.list__title}>{this.props.t("labels.product")}:</span>{e.product}</p>
                        <p><span className={styles.list__title}>{`${utils.getReferenciaLoteOrdem()}:`}</span>{e.num} <i style={{marginLeft: '5px' ,fontSize: '1.2em'}} className="fa fa-chain-broken" title={e.tp_error}></i></p>
                        {referenciaSecundariaFormatada}
                      </div>
                    </div>
                  } else {
                    return <div key={e.fk_id_batches} >
                      <div className={styles.list}>
                        <hr/>
                        <p>
                          {this.props.t("labels.processing")}
                        </p>
                        <p><span className={styles.list__title}>{this.props.t("labels.SKU")}:</span>{e.sku}</p>
                        <p><span className={styles.list__title}>{this.props.t("labels.product")}:</span>{e.product}</p>
                        <p><span className={styles.list__title}>{`${utils.getReferenciaLoteOrdem()}:`}</span>{e.num}</p>
                        {referenciaSecundariaFormatada}
                      </div>
                    </div>
                  }
                }).reverse()
              }
          </div>
        }
      </div>
    )
  }
}

export default withTranslation("flowView")(ListBatchesMobile);
