import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import * as _ from 'lodash';
import NenhumaInformacao from '../../../utils/NenhumaInformacao';
import { withTranslation } from 'react-i18next';

const legendOpts = {
  display: false,
};

class FluxoProcessoChart extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      chartRef: null,
      yMax: 1,
      data: props.data,
      titulo: props.data.labels
    };
  }

  componentDidMount() {
    this.setState({
      chartRef: this.refs.chart.chartInstance
    });
  }

  formatOption(titulos, maxYTick, minYTick) {

    return {
      responsive: false,
      maintainAspectRatio: false,
      barRounded: true,
      widthRectangle: true,
      animation: {
        duration: 0
      },
      layout: {
        padding: {
          top: 20,
          bottom: 50,
          left: 10
        }
      },
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            display: false,
            padding: 80 
          },
          gridLines: {
            display: false,
            lineWidth: 0,
            drawBorder: false,
            drawOnChartArea: false
          },
          maxBarThickness: 105
        }],
        yAxes: [
          {
            id: 'y-axis-1',
            scaleLabel: {
              display: false,
              labelString: '< Horas >'
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            stacked: true,
            ticks: {
              padding: 80,
              callback: function (value, index, values) {
                return null;
              }
            }
          },
          {
            id: 'y-axis-2',
            position: 'left',
            padding: 80,
            ticks: {
              min: -minYTick,
              max: maxYTick,
              stepSize: (maxYTick > minYTick ? (maxYTick/5) : (minYTick/5)),
              beginAtZero: true,
              callback: function (value, index, values) {
                return parseFloat(value).toFixed(1).replace('-', '');
              }
            },
            gridLines: {
              display: false,
            }
          }
        ]
      },
      tooltips: {
        backgroundColor: 'black',
        titleFontSize: 16,
        bodyFontSize: 14,
        displayColors: false,
        mode: 'index',
        intersect: false,
        yAlign: 'top',
        callbacks: {
          label: function (tooltipItems, data) {
            let index = tooltipItems.datasetIndex;
            return data.datasets[index].label + ': ' + Math.abs(parseFloat(tooltipItems.yLabel));
          },
          title: function(tooltipItems, data){
            let index = tooltipItems[0].index;
            return titulos[index];
          }
        }
      },
      barLabel: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        display: true
      },
      customLegend:{
        display: true,
        labelTop: this.props.t("widgets.flowProcessRelation.labels.wait"),
        labelBottom: this.props.t("widgets.flowProcessRelation.labels.process"),
        fontColor: 'rgba(245, 126, 23, 1)'
      }
    };
  }

  formatData(data) {
    if (!data) return {};
    let legendaGrafico;
    let labels = (data && data.length) ? _.map(data, (d) => d.etapa) : [];
    this.state.titulo = labels;
    labels.length > 0 ? legendaGrafico = labels.map(r => {if(r.length >= 13){r = (`${r.slice(0,12)}...`)} return r}) : null;
    let espera = (data && data.length) ? _.map(data, (d) => d.espera) : [];
    let processo = (data && data.length) ? _.map(data, (d) => d.processo) : [];
    let max = (data && data.length) ? _.maxBy(data, (d) => (parseFloat(d.processo) + parseFloat(d.espera))) : null;
    let maxIndex = data && data.length > 0 ? data.indexOf(max) : -1;

    let ctx = document.createElement('CANVAS').getContext('2d');
    let azulClaro = ctx.createLinearGradient(0, 0, 0, 400);
    azulClaro.addColorStop(1, 'rgba(11, 178, 239, 1)');
    azulClaro.addColorStop(0, 'rgba(36, 122, 155, 1)');

    let azulEscuro = ctx.createLinearGradient(0, 0, 0, 400);
    azulEscuro.addColorStop(1, 'rgba(95, 130, 177, 1)');
    azulEscuro.addColorStop(0, 'rgba(61, 85, 119, 1)');

    let bg = _.map(labels, (label, key) => key === maxIndex ? azulClaro : azulEscuro);

    return { //dados das barras do gráfico
      labels: legendaGrafico,
      datasets: [
        {
          yAxisID: 'y-axis-1',
          type: 'bar',
          label: this.props.t("widgets.flowProcessRelation.labels.wait"),
          data: espera,
          backgroundColor: bg,
          hoverBackgroundColor: bg
        },
        {
          yAxisID: 'y-axis-1',
          type: 'bar',
          label: this.props.t("widgets.flowProcessRelation.labels.process"),
          backgroundColor: bg,
          hoverBackgroundColor: bg,
          data: _.map(processo, (p) => p * -1)
        }
      ]
    };
  }

  render() {
    if(this.props.data.length > 0){
      let maxY = _.maxBy(this.props.data, (d) => d.espera);
      let minY = _.maxBy(this.props.data, (d) => d.processo);
      let maxYTick = parseInt(maxY.espera/10) * 10 + 10;
      let minYTick = parseInt(minY.processo/10) * 10 + 10;
      
      if(maxY && minY) {
        maxY = maxY.espera;
        minY = minY.processo;
      }
    
      var data = this.formatData(this.props.data);
      var options = this.formatOption(this.state.titulo, maxYTick, minYTick);
      var width = 0;

      if(document.getElementById('cardContent').clientWidth >= data.labels.length * 170) {
        width = document.getElementById('cardContent').clientWidth - 50;
      } else {
        width = data.labels.length * 170;
      };
    }
      
    return (
      this.props.data.length > 0
        ?
        <div id={this.props.id} className={'flex flex-dir-col'}>
          <div className="chart-content col-xs-12" style={{ padding: '0' }}>
            <div className='col-xs-12 flex-dir-row' style={{ position: 'relative', overflowX: 'auto' }}>
              <span style={{ left: 5, right: 5, top: '60%', position: 'absolute', fontSize: '13px', fontWeight: 'bold', transform: 'rotate(270deg)', transformOrigin: 'left top 0', textTransform: 'capitalize' }}>
                {` < ${this.props.t("labels.hours", { ns: 'common' })} >`}
              </span>
              <div style={{ marginLeft: '5px', width: `${document.getElementById('cardContent').offsetWidth - 44}px` }}>
                <Bar redraw={true} height={245} width={width} ref='chart' data={data} legend={legendOpts} options={options} />
              </div>
            </div>
          </div>
        </div>
        :
        <NenhumaInformacao/>
    );
  }
}

export default withTranslation("flowDashboard")(FluxoProcessoChart);
