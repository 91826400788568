import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationPtBR from 'locales/pt-BR';
import translationEN from 'locales/en';

const resources = {
  "pt-BR": translationPtBR,
  en: translationEN
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: 'common',
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;