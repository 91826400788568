import React, { Component } from 'react';
import styles from './pipefyTable.scss';

export default class PipefyTableBody extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <tbody className={styles.pipefy_table_body}>
                {
                    this.props.data.map(row => (
                        <tr>
                            {
                                Object.keys(row).map(col => (
                                    <td>{ row[col] }</td>
                                ))
                            }
                        </tr>
                    ))
                }
            </tbody>
        );
    }
}