import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NMaskedTime, IF } from '../../common/NForm';
import { respostaTimeMachineAba } from '../../common/mappers';
import Calendario from '../../assets/Calendario.svg';
import AbaContainer from '../../common/Abas/abaContainer';
import AbaContent from '../../common/Abas/abaContent'
import styles from '../../common/form.scss';
import { toJS } from 'mobx';
import _ from 'lodash';

@inject('store')
@observer
export default class FormSetup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      current_events: [],
      vigenciaAtiva: {
        id: 0,
        dataInicio: new Date(),
        dataFim: null
      }
    }

    this.state.vigencias = [this.state.vigenciaAtiva];
    this.setVigencia = this.setVigencia.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  componentWillMount() {
    var events = Object.values(toJS(this.props.stateEquipamento.events));

    if (this.props.stateEquipamento.idEquipamento != 0) {
      var vigencias = events.map(eventos => eventos[0].vigencia);
      var vigenciaAtiva = vigencias.find(vigencia => vigencia.dataFim == null);

      if (vigenciaAtiva != null) {
        this.setState({
          events: events,
          vigencias: vigencias,
          vigenciaAtiva: vigenciaAtiva
        }, () => this.setVigencia(this.state.vigenciaAtiva && this.state.vigenciaAtiva.id));
      }
      else {
        this.setState({
          events: events,
          vigencias: vigencias
        });
      }
    }
    else {
      this.setState({
        current_events: events[0],
        vigenciaAtiva: events[0] && events[0][0] && events[0][0].vigencia
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stateEquipamento != this.props.stateEquipamento) {
      if (Object.keys(this.props.stateEquipamento.events).length > 0) {
        var vigencias = Object.values(toJS(this.props.stateEquipamento.events)).map(eventos => eventos[0].vigencia);

        var vigenciaAtivaId = _.isEmpty(this.props.vigenciaAtiva) ? this.state.vigenciaAtiva.id : this.props.vigenciaAtiva.id;
        vigenciaAtivaId = this.props.vigenciaAtiva != this.state.vigenciaAtiva ? this.state.vigenciaAtiva.id : this.props.vigenciaAtiva.id;

        let currentEventsEquipamento = this.props.stateEquipamento.events[vigenciaAtivaId] == undefined ? this.props.stateEquipamento.events[this.props.vigenciaAtiva.id] : this.props.stateEquipamento.events[vigenciaAtivaId];

        this.setState({
          current_events: currentEventsEquipamento,
          events: Object.values(toJS(this.props.stateEquipamento.events)),
          vigencias: vigencias
        })
      }
    }
  }

  setVigencia(vigencia) {
    let current_events = this.state.events.find(vigencias => vigencias[0].vigencia.id == vigencia);
    let stateEvents = current_events;

    // Filtrar eventos de setup (13) que ainda não foram persistidos
    this.props.storeEvents.filter(a => a.fk_id_table == 13).forEach(setupEvent => {
      let event = {
        codigo: setupEvent.cod,
        nome: setupEvent.descr,
        id: setupEvent.id
      }

      // insere os eventos "novos" na listagem
      if (stateEvents.filter(v => v.evento.id == event.id).length == 0) {
        current_events.push({
          id: 0,
          evento: event,
          tempoDuracao: '00:00:00',
          vigencia: stateEvents[0].vigencia
        });
      }
    });

    this.setState({
      current_events: current_events,
      vigenciaAtiva: current_events && current_events[0].vigencia
    });
  }

  _handleChange(event, equipamentoEvento, index) {
    let { mascaraSetup } = this.props;

    mascaraSetup(equipamentoEvento.vigencia.id, event.target.value, event.target.id, index)
  }

  render() {
    let { stateEquipamento, editVigencia, changeContentModal } = this.props;

    //Pega a vigência de maior Id
    var vigenciaId = this.state.vigenciaAtiva && this.state.vigenciaAtiva.id > 0 ? this.state.vigenciaAtiva.id : this.state.vigencias[this.state.vigencias.length - 1].id;

    let abaTimeMachine = (
      <IF test={stateEquipamento.idEquipamento != 0 && stateEquipamento.events.length != 0}>
        <AbaContainer ClassName={"abaContainer"}>
          <AbaContent
            className={styles.alignContainer}
            icon={Calendario}
            edit={true}
            predicate={() => editVigencia(changeContentModal, this.state.vigencias)}
            editIcon="fa fa-pencil"
            customFunctionOnChange={this.setVigencia}
            itens={respostaTimeMachineAba(this.state.vigencias)}
            selected={vigenciaId}
            alertaApagarAlteracoesTimeMachine={this.alertaApagarAlteracoesTimeMachine}
            showAlert={this.props.showAlert}
            errorMessages={stateEquipamento.errorMessages}>
          </AbaContent>
        </AbaContainer>
      </IF>
    );

    return (
      <div className={'flex flex-wrap'} >
        {
          abaTimeMachine
        }

        {
          this.state.current_events && this.state.current_events.length > 0 ?
            this.state.current_events.map((equipamentoEvento, i) => {
              return (
                <NMaskedTime
                  ref={equipamentoEvento.evento.id}
                  onChange={(e) => this._handleChange(e, equipamentoEvento, i)}
                  onBlur={(e) => this._handleChange(e, equipamentoEvento, i)}
                  className={styles.balloon}
                  id={equipamentoEvento.vigencia.id + '_' + equipamentoEvento.evento.id}
                  value={equipamentoEvento.tempoDuracao}
                  labelName={equipamentoEvento.evento.codigo + ' - ' + equipamentoEvento.evento.nome}
                />
              )
            })
            :
            <tr className={styles.row_error} style={{ paddingLeft: '36.5%', paddingTop: '25%' }}>
              <td className="text-center">
                <div className={styles.box_error}>
                  <i className={`material-icons ${styles.error_text}`}>warning</i>
                  <div>
                    <h3 className={styles.error_text}>{this.props.t('list.empty', { ns: 'common' })}</h3>
                  </div>
                </div>
              </td>
            </tr>
        }
      </div>
    );
  }
}
