import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

class AbstractComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { chartRef: null };
    }

    componentDidMount() {
        this.setState({
            chartRef: this.refs.chart.chart_instance
        });
    }

    hasData(key) {
        return this.props.data && this.state.data && this.state.data[key];
    }

    resolveData(key) {
        return this.hasData(key) ? this.state.data[key] : 0;
    }
    
    formateDates(datas) {
        return _.map(datas, (data) => moment(data).toDate());
    }

    getDataOrDefault(data){
        return data ? data : [];
    }

    pluck(data, key){
        return _(this.getDataOrDefault(data)).map((item) => item[key]).value();
    }

    pluck2Args(data, key1, key2){
        return _(this.getDataOrDefault(data)).map((item) => item[key1] + ' - ' +  item[key2] ).value();
    }
}

export default AbstractComponent;
