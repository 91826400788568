import React from 'react';

const IconArrowRight = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill={color || '#5B5B5B'}
      width='18px'
      height='18px'
    >
      <path d='M10 17l5-5-5-5v10z' />
      <path d='M0 24V0h24v24H0z' fill='none' />
    </svg>
  );
};

export default IconArrowRight;
