import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CardIndicatorCss from '../../../assets/styles/painel-fluxo/CardIndicator.scss';

import MDSpinner from "react-md-spinner";

class CardIndicator extends Component {

  getClassName(val) {
    return val === 0 ? 'none' : (val < 0 ? 'up' : 'down');
  }

  render() {
    return (
      <div className="custom-indicator-card" style={{ minHeight: '196px', position: 'relative' }}>
        <div className={'card-indicator-header'}>
          <span style={{textTransform: 'capitalize'}}>{this.props.header}</span>
        </div>
        {
          this.props.load?
            <div style={{position:'absolute', transform: 'translate(-50%, -50%)' , top: '50%', left: '50%', marginTop: '12.5px'}}>
              <MDSpinner singleColor='#2D4F7F' size={60} />
            </div>
          :
          (
            this.props.data.load !== '' ?
              <div className={'flex-dir-row flex-align-center flex-just-center'} style={{ height: '100%', width: '100%', color: '#B4B4B4', 
                      textAlign: 'center', marginTop: '25%', fontSize: '15px'}}>
                <i style={{ marginRight: '15px', color: 'rgba(0, 0, 0, .4)' }} className="material-icons">warning</i>
                <div>
                  <h3 style={{color: 'rgba(0, 0, 0, .4)'}}>{this.props.t("customIndicator.noData")}</h3>
                </div>
              </div>
            :
            <div className={'card-indicator-content flex-dir-row flex-align-center flex-just-center '} id={this.props.id} >
              <div className={'indicator'}>
                {this.props.data.total ? this.props.data.total : 0} 
                {this.props.data.tipo === 'horas' 
                  ? <span style={{marginTop: '15px', fontSize: '0.688em', color: 'rgba(0, 0, 0, 0.4)'}}>h</span> 
                  : null}
              </div>
  
              {
                this.props.data.indicador !== null ?
                  <div className={'right-indicator ' + this.getClassName(this.props.data.indicador)}>
                    {
                      this.props.data.tipo === 'horas' 
                      ?
                        <span className={'right-number '+ this.getClassName(this.props.data.indicador)}> 
                          { this.props.data.indicador > 0 
                            ? 
                              `${this.props.data.indicador}h` 
                            : 
                              this.props.data.indicador < 0 
                              ? 
                                `${this.props.data.indicador*-1}h` 
                              : 
                                <span style={{fontSize: '80px', color: 'rgba(0, 0, 0, 0.4)'}}>-</span> 
                          } 
                        </span>
                      :
                        <span className={'right-number '+ this.getClassName(this.props.data.indicador)}>
                          { this.props.data.indicador > 0 
                            ? 
                              this.props.data.indicador 
                            : 
                              this.props.data.indicador < 0 
                              ? 
                                this.props.data.indicador*-1 
                              : 
                                <span style={{fontSize: '80px', color: 'rgba(0, 0, 0, 0.4)'}}>-</span> 
                          }
                        </span>
                    }
                  </div>
                :
                  null
              }
            </div>
          )
        }        
        {
          !this.props.load?
            <div className={'footer'} id={this.props.idFooter}>
              {this.props.idFooter !== 'tempoMedioSetupFooter' ? <span>{this.props.data.percent ?  parseFloat(this.props.data.percent).toFixed(0) + '%' : <br />}</span> : null}
            </div>
          :
          null
          }
      </div>
    );
  }
}

export default withTranslation("components")(CardIndicator);