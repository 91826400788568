import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './ListSubtitle.css';

let iconeDesvioRota = "../../assets/desvio-rota.svg";
let iconeEquipamentoGargalo = "../../assets/equipamento-gargalo.svg";
let iconeEquipManutencao = "../../assets/equip-manutencao.svg";
let iconeEquipParado = "../../assets/equip-parado.svg";
let iconeEquipProducao = "../../assets/equip-producao.svg";
let iconeExtras = "../../assets/extras.svg";
let iconeFavorito = "../../assets/favorito.svg";
let iconeComentario = "../../assets/comentario.svg";
let iconeFifo = "../../assets/quantidade-lotes-fifo.svg";
let iconeHoldingTimeAlerta = "../../assets/holding-time-alerta.svg";
let iconeHoldingTimeExcedido = "../../assets/holding-time-excedido.svg";
let iconeInfoLote = "../../assets/info-lote.svg";
let iconeLeadTimeAlerta = "../../assets/lead-time-alerta.svg";
let iconeLeadTimeExcedido = "../../assets/lead-time-excedido.svg";
let iconeListagemLotesInconsistentes = "../../assets/listagem-lotes-inconsistentes.svg";
let iconeLoteInconsistente = "../../assets/lote-inconsistente.svg";
let iconeLoteOutraUnidadeIndustrial = "../../assets/lote-outra-unidade-industrial.svg";
let iconeProgressoLeadTimeAlerta = "../../assets/progresso-lead-time-alerta.svg";
let iconeProgressoLeadTimeExcedido = "../../assets/progresso-lead-time-excedido.svg";
let iconeProgressoLeadTimePrazo = "../../assets/progresso-lead-time-no-prazo.svg";
let iconeEquipamentoSetup = "../../assets/equipamento-setup.svg";

class ListSubtitle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listIcones: [
                {
                    src: iconeListagemLotesInconsistentes,
                    descricao: this.props.t("labels.subtitle.inconsistentBatchesList"),
                },
                {
                    src: iconeExtras,
                    descricao: this.props.t("labels.subtitle.extras"),
                },
                {
                    src: iconeFifo,
                    descricao: this.props.t("labels.subtitle.exceedingBatches"),
                },
                {
                    src: iconeLeadTimeExcedido,
                    descricao: this.props.t("labels.subtitle.exceededLeadTime"),
                },
                {
                    src: iconeLeadTimeAlerta,
                    descricao: this.props.t("labels.subtitle.warningLeadTime"),
                },
                {
                    src: iconeDesvioRota,
                    descricao: this.props.t("labels.subtitle.routeDeviation"),
                },
                {
                    src: iconeLoteInconsistente,
                    descricao: this.props.t("labels.subtitle.inconsistentBatch"),
                },
                {
                    src: iconeFavorito,
                    descricao: this.props.t("labels.subtitle.favoriteBatch"),
                },
                {
                    src: iconeComentario,
                    descricao: this.props.t("labels.subtitle.commentBatch"),
                },
                {
                    src: iconeEquipamentoSetup,
                    descricao: this.props.t("labels.subtitle.equipmentInSetup"),
                },
                {
                    src: iconeEquipManutencao,
                    descricao: this.props.t("labels.subtitle.equipmentUnderMaintenance"),
                },
                {
                    src: iconeEquipParado,
                    descricao: this.props.t("labels.subtitle.equipmentStopped"),
                },
                {
                    src: iconeEquipProducao,
                    descricao: this.props.t("labels.subtitle.equipmentProduction"),
                },
                {
                    src: iconeHoldingTimeExcedido,
                    descricao: this.props.t("labels.subtitle.batchWithExceededHoldingTime"),
                    largura: 100,
                    altura: 13,
                },
                {
                    src: iconeHoldingTimeAlerta,
                    descricao: this.props.t("labels.subtitle.batchWithWarningHoldingTime"),
                    largura: 100,
                    altura: 13,
                },
                {
                    src: iconeInfoLote,
                    descricao: this.props.t("labels.subtitle.batchInfo"),
                    largura: 100,
                    altura: 13,
                },
                {
                    src: iconeLoteOutraUnidadeIndustrial,
                    descricao: this.props.t("labels.subtitle.batchBelongsToAnotherUnit"),
                    largura: 100,
                    altura: 13,
                },
                {
                    src: iconeProgressoLeadTimeExcedido,
                    descricao: this.props.t("labels.subtitle.batchProgressWithExceededLeadTime"),
                    largura: 100,
                    altura: 4,
                },
                {
                    src: iconeProgressoLeadTimeAlerta,
                    descricao: this.props.t("labels.subtitle.batchProgressWithWarningLeadTime"),
                    largura: 100,
                    altura: 4,
                },
                {
                    src: iconeProgressoLeadTimePrazo,
                    descricao: this.props.t("labels.subtitle.batchProgressWithOnTimeLeadTime"),
                    largura: 100,
                    altura: 4,
                },
                {
                    src: iconeEquipamentoGargalo,
                    descricao: this.props.t("labels.subtitle.equipmentWithBottleneck"),
                    largura: 100,
                    altura: 20,
                },
            ]
        }

        this.criarIcone = this.criarIcone.bind(this);
    }

    criarIcone(icone, index) {
        let largura = 24;
        let altura = 24;

        return (
            <div className={styles.listItem} key={index}>
                <div>
                    <img style={{
                        width: icone.largura ? icone.largura : largura,
                        height: icone.altura ? icone.altura : altura,
                        marginLeft: 5
                    }}
                        src={icone.src}
                        alt={icone.descricao} />
                </div>
                <span>{icone.descricao}</span>
            </div>
        );
    }

    render() {
        let { handleModal } = this.props;

        return (
            <div style={{ boxShadow: '9px 9px 15px -13px rgba(0,0,0,0.75)' }} className={styles.container}>
                <div className={styles.header}>
                    <h3 className={styles.title}>{this.props.t("labels.subtitle.title")}</h3>

                    <div className={styles.closeIcon}>
                        <i className="fa fa-close" onClick={handleModal} />
                    </div>
                </div>

                <div className={styles.containerLista}>
                    {this.state.listIcones.map((icone, index) => this.criarIcone(icone, index))}
                </div>
            </div>
        );
    }
}

export default withTranslation("flowView")(ListSubtitle)