import React, { Component } from 'react';
import Form from './Form';
import { Tabs, Tab } from 'material-ui/Tabs';
import { request } from './request';
import { alertBox } from './NForm';
import { withTranslation } from 'react-i18next';

class ModalForm extends Component {
  constructor() {
    super();

    this.state = {
      isSending: false
    };

    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
    this.validation = this.validation.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  save(id) {
    let data = {};
    let { url, handleModal, aposSalvar, modificador, tituloAlerta, alertaMsg, genero, clearState } = this.props;
    let method = id
      ? 'put'
      : 'post';

    genero = (genero || '').toUpperCase();

    let abas = this.props.abas || ['form'];
    abas.map(a => {
      let fields = Object.keys(this.refs[a].refs).map(k => this.refs[a].refs[k]);
      if (fields.length <= 0) {
        data = Object.assign(data, { [a]: this.refs[a].props.fields[0].data });
      } else {
        fields.map(field => {
          let key = Object.keys(field.refs)[0];

          data[key] = field.props.type == 'multiple' ? field.state.options
            : (field.props.type == 'time' || field.props.type == 'spent') ? this.props.utils.revertTime(field.refs[key].value)
              : field.props.type == 'toggle' ? field.refs[key].state.switched
                : field.props.type == 'interval' ? field.refs[key].value ? field.refs[key].value : '00:00:000'
                  : field.props.type == 'date' ? field.state.value.format('YYYY-MM-DD')
                    : (field.refs[key].value);
        });
      }
    });

    if (id) {
      data.id = id;
    }

    var sendUrl = id ? url + '/' + id : url;

    let acao = (method === 'post') ? genero == 'F' ? this.props.t('labels.createdF') : this.props.t('labels.createdM') : genero == 'F' ? this.props.t('labels.editedF') : this.props.t('labels.editedM');
    let acao2 = (method === 'post') ? this.props.t('labels.create') : this.props.t('labels.edit');

    alertaMsg = alertaMsg || (genero == 'F' ? this.props.t('messages.alertInformedF', { alert: tituloAlerta.toLowerCase() }) : this.props.t('messages.alertInformedM', { alert: tituloAlerta.toLowerCase() }));

    this.setState({ isSending: true });

    request(sendUrl, {
      method: method,
      body: JSON.stringify(modificador(data))
    })
      .then(response => {
        if (response.ok) {
          clearState ? clearState() : handleModal();
          alertBox(this.props.t('messages.alertWithSuccess', { alert: tituloAlerta, action: acao }), tituloAlerta, 'success');
          aposSalvar && aposSalvar();

        } else if (response.status == 409) {
          alertBox(this.props.t('messages.unableAlreadyExists', { action: acao2, alert: alertaMsg }), tituloAlerta, 'error');

        } else if (response.status == 400) {
          return response.json();
        }
      })
      .then(dataError => {
        if (dataError) { alertBox(dataError.message, dataError.title, 'warning'); }
      })
      .catch(error => {
        this.alertBoxPadrao(acao2, alertaMsg, tituloAlerta);
      })
      .finally(() => { console.log('finally'); this.setState({ isSending: false }) });
  }

  alertBoxPadrao(acao2, alertaMsg, tituloAlerta) {
    alertBox(this.props.t('messages.unableTryAgain', { action: acao2, alert: alertaMsg }), tituloAlerta, 'error');
  }

  validate() {
    let valid = true;
    let abas = this.props.abas || ['form'];
    let fields;
    abas.map(a => {
      fields = Object.keys(this.refs[a].refs).map(k => this.refs[a].refs[k]);
      if (fields.length <= 0) {
        valid &= this.refs[a].props.fields[0].validate();
      } else {
        fields.map(field => {
          let id = field.props.id;
          field.refs[id].value = field.refs[id].value?.trim();
          let required = field.refs[id].required;
          let value = field.refs[id].value;
          let validate = field.refs.validate;

          if (required && validate) {
            if (value.length == 0) {
              valid = false;
              validate.className = 'validation';
            } else {
              validate.className = 'validation transparent';
            }
          }
        });
      }
    });

    if (this.props.validate) {
      let validProps = this.props.validate(fields);
      if (!validProps.status) {
        this.props.showAlert(validProps.message, this.props.t('messages.checkForm'), 'warning');
      }
      return valid && validProps.status;
    }
    else {
      return valid;
    }
  }

  validation() {
    if (this.validate(this.props.field)) {
      this.save(this.props.field.id);
    } else {
      this.props.showAlert(this.props.t('messages.allFieldsRequired'), this.props.t('messages.checkForm'), 'warning');
    }
  }

  validateForm() {
    if (this.props.validate) {
      this.save(this.props.field.id);
    } else {
      this.props.showAlert(this.props.t('messages.allFieldsRequired'), this.props.t('messages.checkForm'), 'warning');
    }
  }

  render() {
    let { field, fields, handleModal, utils, typeform, formContentStyle } = this.props;
    let { convertTime } = utils;
    let buttons = this.props.buttons || [
      { name: this.props.t('labels.save'), type: 'round', disabled: (this.props.disabledSave || this.state.isSending), action: typeform ? this.validateForm : this.validation },
      { name: this.props.t('labels.cancel'), type: 'round', action: typeform ? this.props.clearState : handleModal }
    ];
    let component = null;

    if (fields instanceof Array) {
      if (fields.length > 0) {
        if (field) {
          fields.map(f => {
            f.type == 'time' ? f.value = f.notRevert ? field[f.name] : convertTime(field[f.name])
              : f.type == 'toggle' ? f.checked = field[f.name]
                : f.value = field[f.name];
            return f;
          });
        }
        fields[fields.length - 1].onFinished = this.validation;

        component = (
          <div className='modal'>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>{this.props.title}</div>
            <Form ref='form' columns={this.props.columns} fields={fields} buttons={buttons} />
          </div>
        );
      }
      fields[fields.length - 1].onFinished = this.validation;

      component = (
        <div style={this.props.styles} className='modal'>
          <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>{this.props.title}</div>
          <Form ref='form' columns={this.props.columns} fields={fields} buttons={buttons} />
        </div>
      );
    }
    else {

      let tabs = Object.keys(fields).map((k, idx) => {

        if (field) {
          fields[k].map(f => {
            f.type == 'time' ? f.value = f.notRevert ? field[f.name] : convertTime(field[f.name])
              : f.type == 'toggle' ? f.checked = field[f.name]
                : f.value = field[f.name];
            return f;
          });
        }

        return (
          <Tab label={fields[k][0].title} key={idx}>
            <div className='modal'>
              <Form ref={k} columns={this.props.columns} fields={fields[k]} buttons={buttons} formContentStyle={k == 'setup' ? formContentStyle : {}} />
            </div>
          </Tab>
        );
      });

      component = (
        <Tabs tabItemContainerStyle={{ background: '#F1F1F1' }} inkBarStyle={{ background: '#95999A' }} initialSelectedIndex={this.props.initialSelectedIndex} className={'modal_tabs'}>
          {tabs}
        </Tabs>
      );
    }

    return component;
  }
}

export default withTranslation('common')(ModalForm);
