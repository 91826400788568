import React, { Component } from 'react';
import Button from '../../common/Button';
import { NButton } from '../../common/NForm';
import lbStyles from './ListBatches.scss';
import FIFOIcon from '../../assets/FIFO.svg';
import $ from 'jquery';
import moment from 'moment';
moment.locale('pt-br');
import { alertBox } from '../../common/NForm';
import { Card } from 'material-ui/Card';
import ListBatchesMobile from './ListBatchesMobile';
import utils from '../../utils/Utils';
import { request } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';
import { APIEndpoints } from 'common/Constants';

class ListWaitingBatchesStageList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mensagens: [],
            listaLotes: [],
            movimentacoes: [],
            lotesAlterados: [],
        }

    
        
        if(props.listaLotesEtapa.length > 0) {
        this.equipamentId = props.listaLotesEtapa[0]['fk_id_equipments'];
        }

        this.subirPosicao = this.subirPosicao.bind(this);
        this.descerPosicao = this.descerPosicao.bind(this);
        this.analiseSequenciamentoOtimiziado = false;
        this.adicionarMovimentacao = this.adicionarMovimentacao.bind(this);
        this.removerEAdicionarMovimentacao = this.removerEAdicionarMovimentacao.bind(this);
        this.salvarMovimentacoes = this.salvarMovimentacoes.bind(this);
        
    }

    //Metodo utilizado para ordenar a lista de lotes de acordo com o tempo de referencia
    ordenarLotes(lotes){
        return lotes.map(b => ({
            ...b,
            type: b.inconsistente ? 'warning' : 'waiting'
        }))
        .sort((a, b) => {
            return new Date(b.reference_time).getTime() - new Date(a.reference_time).getTime();
        });
        
    }

    componentDidMount() {
        this.setState({
            listaLotes:  this.ordenarLotes(this.props.listaLotesEtapa)
        });

        var container = $('.bodyTable > div > div:nth-child(2)');
        container.animate({
            scrollTop: container.children().eq(0).height()
        },0);
        
        var widthTable = container.children().eq(0).width();
        $('.bodyTable > div').css('background', '#fff');
        var headerTable = $('.bodyTable > div > div:nth-child(1)');
        headerTable.css('width', widthTable);
        headerTable.css('background', '#fff');
    }

     /*
        --TipoMovimento--
        1 - Mover para cima;
        2 - Mover para baixo;
        3 - Mover para entre equipamentos;
        4 - Mover de equipamento para lote;
        5 - Mover de etapa para equipamento;
    */
    criarObjetoMovimentacao(loteByIndex, fimReferencia, tipoMovimento){
        var objetoMovimentacao = {
            excluir: false,
            lote_id: loteByIndex.fk_id_batches,
            etapa_original_id: loteByIndex.fk_id_stages,
            etapa_referencia_id: loteByIndex.fk_id_stages,
            data_fim_original: loteByIndex.end_time,
            data_fim_referencia: moment(fimReferencia).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            tipo_movimentacao: tipoMovimento
        };

        if(loteByIndex.fk_id_equipments != 0){
            objetoMovimentacao.equipamento_original_id = loteByIndex.fk_id_equipments;
            objetoMovimentacao.equipamento_referencia_id = loteByIndex.fk_id_equipments;
        }

        return objetoMovimentacao;
        
    }

    buscarIndexMovimentacoesExistentes(movimentacao){
        return _.findIndex(
            this.state.movimentacoes, 
            (move) => { 
                return move.lote_id == movimentacao.lote_id 
            }
        );
    }

    atribuirMovimentoListaLotes(posicaoMovimento, movimentacao){
        // Utilizo spreadoperator para gerar uma referencia ao array de lista de ltoes
        var listaLotes = [...this.state.listaLotes];
        // Busco o lote movimentado
        var loteMovimentado = listaLotes[posicaoMovimento];
        // Altero o horario de referencia
        loteMovimentado.reference_time = movimentacao.data_fim_referencia;
        // Atribuo o valor ao array clonado da state
        listaLotes[posicaoMovimento] = loteMovimentado;
        
        return listaLotes;
    }

    adicionarMovimentacao(posicaoMovimento, movimentacao){
        var listaLotesMovimentada = this.atribuirMovimentoListaLotes(posicaoMovimento, movimentacao);

        this.setState(state => {
            const movimentacoes = state.movimentacoes.concat(movimentacao);
            return {
                movimentacoes: movimentacoes,
                listaLotes: this.ordenarLotes(listaLotesMovimentada)
            };
        });
    }

    //Garanto que só existirá um movimento por lote de acordo com a lista
    removerEAdicionarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao){
        var listaLotesMovimentada = this.atribuirMovimentoListaLotes(posicaoMovimento, movimentacao);

        this.setState(state => {
            //Remove a movimentação existente
            const listaSemMovimentacao = state.movimentacoes.filter((item, index) => index !== indexMovimentacao);
            //Adiciona nova movimentaçao
            const movimentacoes = listaSemMovimentacao.concat(movimentacao);

            return {
                movimentacoes: movimentacoes,
                listaLotes: this.ordenarLotes(listaLotesMovimentada)
            };
        })
    }

    verificarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao){
        // -1 = a movimentaçao inexistente no array
        if(indexMovimentacao == -1){
            this.adicionarMovimentacao(posicaoMovimento, movimentacao);
        } else {
            this.removerEAdicionarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao);
        }
    }

    subirPosicao(posicaoMovimento) {
        let listaLotes = this.state.listaLotes;
        let loteByIndex = listaLotes[posicaoMovimento];
        let loteAnterior = listaLotes[posicaoMovimento + 1];

        if (loteAnterior != undefined) {
            let fimAnterior = new Date(loteAnterior.reference_time);
            let fimAnteriorSomado1Milisegundo = new Date(fimAnterior.getTime() - 1);
            
            let movimentacao = this.criarObjetoMovimentacao(loteByIndex, fimAnteriorSomado1Milisegundo, 1);

            let indexMovimentacao = this.buscarIndexMovimentacoesExistentes(movimentacao);  
            
            this.verificarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao);
            let mensagemLoteAlterado = `A posição do ${utils.getReferenciaDoLoteDaOrdem()} ${loteByIndex.num} foi ${utils.getReferenciaAlteradoAlterada()} na fila!`;
            if (!this.state.mensagens.includes(mensagemLoteAlterado)) {
                this.state.mensagens.push(mensagemLoteAlterado);
            }

            this.state.lotesAlterados.push(loteByIndex.num);
        }
    }

    descerPosicao(posicaoMovimento) {
        let listaLotes = this.state.listaLotes;
        let loteByIndex = listaLotes[posicaoMovimento];
        let lotePosterior = listaLotes[posicaoMovimento - 1];

        if (lotePosterior != undefined) {
            let fimPosterior = new Date(lotePosterior.reference_time);
            let fimPosteriorSubtraido1Milisegundo = new Date(fimPosterior.getTime() + 1);
            
            let movimentacao = this.criarObjetoMovimentacao(loteByIndex, fimPosteriorSubtraido1Milisegundo, 2);

            let indexMovimentacao = this.buscarIndexMovimentacoesExistentes(movimentacao);
            
            this.verificarMovimentacao(posicaoMovimento, indexMovimentacao, movimentacao);

            let mensagemLoteAlterado = this.props.t("messages.moveQueueSuccess", { 
                primaryReference: utils.getReferenciaDoLoteDaOrdem(),
                batchNumber: loteByIndex.num,
                changedText: utils.getReferenciaAlteradoAlterada()
            });

            if (!this.state.mensagens.includes(mensagemLoteAlterado)) {
                this.state.mensagens.push(mensagemLoteAlterado);
            }
            this.state.lotesAlterados.push(loteByIndex.num);
        }
    }

    salvarMovimentacoes() {
        request(this.props.endpoints.MOVIMENTACAO_LOTE, {
            method: 'post',
            body: JSON.stringify(this.state.movimentacoes),
          })
          .then(response => {
            if (response.ok) {
                this.props.handleSession();
            }else{
                this.props.handleModal();
            }
        });

        var mensagem = _.reduce(this.state.mensagens, function(mensagem, msg) {
            return "\n" + mensagem + "\n" + msg;
        });

        alertBox(`${mensagem}`, '', 'success');
    }

    render() {
        
        let {etapa} = this.props;

        let getReferenciaSecundaria = utils.getReferenciaOrdemLote() != '' ? <td>{utils.getReferenciaOrdemLote()}</td> : null;

        return(
            <div style={{marginTop:10}} >
                <div >
                    <div style={{marginTop: '1em'}} className='table_reponsive'>
                        <table style={{fontSize: '16px'}} className='mui-table mui-table--bordered'>
                            <thead style={{borderBottom: '1px solid #D6D6D6'}}>
                                <tr className={lbStyles.title}>
                                    <td> {this.props.t("labels.stage")} </td>
                                </tr>
                            </thead>
                            <tbody className='box_items'>
                                <tr className={lbStyles.info_equip}>
                                    <td> {etapa.name} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
    
                    <div className={`${lbStyles.d_none_mobile} bodyTable`}>
                        {
                            (this.state.listaLotes.length ) <= 0
                            ? 
                                <div style={{background: '#fff', padding: '3em'}} >{this.props.t("messages.noBatchesInStageQueue", { primaryReference: utils.getReferenciaLotesOrdens().toLowerCase() })}</div>
                            :
                            <Card style={{margin: 0, boxShadow: 0}} className='box_list_card'>
                                <div className='table_reponsive' >
                                    <table style={{fontSize: '16px'}} className='mui-table mui-table--bordered'>
                                        <thead style={{borderBottom: '1px solid #D6D6D6'}}>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style={{textAlign: 'center'}}>#</td>
                                                <td style={{textAlign: 'center'}}>{this.props.t("labels.SKU")}</td>
                                                <td>{this.props.t("labels.product")}</td>
                                                <td>{utils.getReferenciaLoteOrdem()}</td>
                                                {getReferenciaSecundaria}
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody className='box_items'>
                                            {
                                                this.state.listaLotes.map((elemento, posicaoElemento) => {
                                                    let numeroSecundario = getReferenciaSecundaria != null ? <td style={{textAlign: 'left'}}>{elemento.num_secundario != null ? elemento.num_secundario : '-'}</td> : null;
                                                    let totalElementos = this.state.listaLotes.length;
                                                    let posicaoFila = totalElementos - posicaoElemento;
                                                    return (
                                                        <tr id={'row_' + posicaoElemento} key={elemento.fk_id_batches} style={this.state.lotesAlterados.includes(elemento.num) ? {background:'#f5b96c'} : {}} className={lbStyles.option}>
                                                            {
                                                                hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                                                                    <td className={lbStyles.option}>
                                                                        <div className={"row"} style={{transform: 'rotate(-90deg)', cursor: 'pointer'}} onClick={() => this.descerPosicao(posicaoElemento)}>
                                                                            <span className={"material-icons"} >
                                                                                navigate_next
                                                                            </span>
                                                                        </div>
                                                                        <div className={"row"} style={{transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={() => this.subirPosicao(posicaoElemento)}>
                                                                            <span className={"material-icons"}>
                                                                                navigate_next
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                :
                                                                    <td></td>
                                                            }
                                                            <td>
                                                                {elemento.inconsistente ?
                                                                    <div style={{background: '#fff'}} className={lbStyles.tooltip}>
                                                                        <i style={{fontSize: '1.2em'}} className="fa fa-chain-broken"></i>
                                                                        <span className={lbStyles.toolTipText}>{elemento.tp_error}</span> 
                                                                    </div>
                                                                :
                                                                    null
                                                                }
                                                            </td>
                                                            <td className={lbStyles.row_table}>
                                                                <td style={{background:'#EC971F', textAlign: 'center'}} className={lbStyles.ordem}>
                                                                    <h3 className={lbStyles.nome_ordem}>{this.props.t("labels.queuePositionWithCount", { count: posicaoFila })}</h3>
                                                                </td>
                                                            </td>
                                                            
                                                            <td style={{textAlign: 'center'}}>{elemento.sku}</td>
                                                            <td style={{textAlign: 'left'}}>{elemento.product}</td>
                                                            <td style={{textAlign: 'left'}}>{elemento.num}</td>
                                                            {numeroSecundario}
                                                            <td>
                                                                <div>
                                                                    <img onClick={() => this.props.detalhamentoLote(elemento, elemento.lead_time.cor)} style={{textAlign: 'center', width: '1.5em', height: '1.5em', marginRight: '10px', cursor: 'pointer'}} src='../../../assets/icons/detail.svg' /> 
                                                                    {
                                                                        hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                                                                            <img onClick={() => this.props.activeModal('moveBatch', elemento, elemento, '', etapa, posicaoElemento)} style={{width: '1.5em', height: '1.5em', cursor: 'pointer'}} src='../../../assets/icons/export.svg' /> 
                                                                        :
                                                                            null
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.state.movimentacoes.length != 0 ?
                                        <div className={'flex flex-just-end'}>
                                            <div>
                                                <NButton 
                                                    type="button"
                                                    onClick={this.salvarMovimentacoes}
                                                    style={{fontWeigth: 100, backgroundColor:'#2D4F7F', color:'#FFF'}} 
                                                    
                                                >{this.props.t("labels.save", { ns: 'common' })}</NButton>
                                            </div>
                                            <div><Button button={{ type: 'round', text: 'Cancelar', onClick: this.props.handleModal }} /></div>
                                        </div>
                                    :
                                        null
                                }
                            </Card>
                        }
                    </div>
                    <div className={lbStyles.d_block_mobile}>
                        <ListBatchesMobile listProcessOrder={[]} listWaitingOrder={this.state.listaLotes} store={this.props.store} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation("flowView")(ListWaitingBatchesStageList);
