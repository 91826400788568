import React from 'react';
import style from './style.css';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import MDSpinner from 'react-md-spinner';
import { useTranslation } from 'react-i18next';
import Utils from 'utils/Utils';

const ModalApontamentosTimeline = ({ apontamentosTimeline, closeModal }) => {
  const { t } = useTranslation('oee');
  const hasData = apontamentosTimeline.data && apontamentosTimeline.data.length;
  
  const hasValueField = hasData && apontamentosTimeline.data[0].nome_campo;
  const hasValueField1 = hasData && apontamentosTimeline.data.find(f=> f.nome_campo1 != null);
  const hasValueField2 = hasData && apontamentosTimeline.data.find(f=> f.nome_campo2 != null);
  const hasValueField3 = hasData && apontamentosTimeline.data.find(f=> f.nome_campo3 != null);

  const loader = (
    <div key={0} className="loader">
      <MDSpinner singleColor='#2D4F7F' size={20} style={{marginTop: -5}} />
      &nbsp;&nbsp;{t("labels.loading")}
    </div>
  );

  const renderValueColl = (value, condition) => {
    if (condition) {
      return renderValue(value);
    }

    return (<td/>);
  }

  const renderValue = (value) => {
    if (hasData && value) {
      return (<td className='center'>{value}</td>);
    }

    return (<td/>);
  }

  return (
    <div className={`${style.container} modal`}>
      <div>
        <div>
          <h2 className={style.title}>{t("labels.notesReport")}</h2>
        </div>

        <div>
          <i className='fa fa-close cursor-pointer' onClick={closeModal} />
        </div>
      </div>

      <div className={`${style.table_container} table_reponsive`}>
        <InfiniteScroll
          loadMore={apontamentosTimeline.carregarApontamentos}
          hasMore={apontamentosTimeline.hasMoreLotes}
          initialLoad={false}
          useWindow={false}
          loader={loader}
        >
          <table className='mui-table mui-table--bordered'>
            <thead>
              <tr className={style.row_header}>
                <td className='center'>{t("labels.startDate")}</td>
                <td className='center'>{t("labels.endDate")}</td>
                <td className='center'>{Utils.getReferenciaLoteOrdem()}</td>
                <td className='center'>{t("labels.SKU")}</td>
                <td>{t("labels.product")}</td>
                <td className='center'>{t("labels.producedAmount")}</td>
                <td className='center'>{t("labels.rejectedAmount")}</td>
                <td className='center'>{t("labels.eventCode")}</td>
                <td className='center'>{t("labels.event")}</td>
                <td className='center'>{t("labels.status")}</td>
                {renderValueColl(apontamentosTimeline.data[0].nome_campo, hasValueField)}
                {renderValueColl('Campo 2', hasValueField1)}
                {renderValueColl('Valor 2', hasValueField1)}
                {renderValueColl('Campo 3', hasValueField2)}
                {renderValueColl('Valor 3', hasValueField2)}
                {renderValueColl('Campo 4', hasValueField3)}
                {renderValueColl('Valor 4', hasValueField3)}
              </tr>
            </thead>

            <tbody className='box_items'>
              {apontamentosTimeline.data.map((a) => {
                return (
                  <tr className={style.row_item} key={a.uuid}>
                    {renderValue(moment(a.inicio).format('L LT'))}
                    {renderValue(moment(a.fim).format('L LT'))}                    
                    <td className='center'>{a.lote && a.lote.numero}</td>
                    <td className='center'>{a.lote && a.lote.produto.sku}</td>
                    <td>{a.lote && a.lote.produto.nome}</td>
                    <td className='center'>{a.produzido}</td>
                    <td className='center'>{a.rejeitado}</td>
                    <td className='center'>{a.evento.codigo}</td>
                    <td className='center'>{a.evento.nome}</td>
                    <td className='center'>{a.classe_estrutura.nome}</td>
                    {renderValue(a.observacao)}                    
                    {renderValue(a.nome_campo1)}
                    {renderValue(a.observacao1)}
                    {renderValue(a.nome_campo2)}
                    {renderValue(a.observacao2)}
                    {renderValue(a.nome_campo3)}
                    {renderValue(a.observacao3)}                    
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ModalApontamentosTimeline;
