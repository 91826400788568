import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styles from './parameters.scss';
import Container from '../../common/Container';
import { NInputPercentage, NInput, NInputTime, alertBox } from '../../common/NForm';
import Quest from '../../common/Quest';
import moment from 'moment';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { request, requestAll } from '../../common/request';
import Store from '../../common/Store';
import MDSpinner from "react-md-spinner";
import { hasRole } from '../../helper/SecurityHelper';
import { respostaParametros, repostaLeadTime, requisicaoParametros } from '../../common/mappers';
import utils from '../../utils/Utils';
import { Toggle } from 'material-ui';
import { withTranslation } from 'react-i18next';
import InputTime from 'common/InputTime';
import MaskFieldTime from '../../utils/MaskFieldTime';

const { MaskHoraMinutoSegundo } = MaskFieldTime;

@observer
export default class Parameters extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      errorType: '',
      loadingPage: true,
    };
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentWillMount() {
    requestAll([
      {
        url: this.endpoints.PARAMETROS,
        modelo: 'parameters',
        modificador: respostaParametros
      },
      {
        url: this.endpoints.LEAD_TIME,
        modelo: 'avg_lead_time',
        modificador: repostaLeadTime,
        acao: true
      }
    ], this);
  }

  handleModal(erro) {
    this.setState({
      modal: !this.state.modal,
      errorType: erro
    });
  }

  contentModal() {
    return <Quest question={this.state.errorType} handleModal={this.handleModal} action={this.toQuality} notAction={this.handleModal} />
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      let leadTimes = this.store.parameters.filter(p => p.type == 'leadtime');
      let holdingTime = this.store.parameters.filter(p => p.type == 'holdingTime');
      let metaOEE = this.store.parameters.filter(p => p.type == 'Oee');
      let periodoVisaoTablet = this.store.parameters.filter(p => p.type == 'periodoVisaoTablet');
      let periodoVisaoFlowView = this.store.parameters.filter(p => p.type == 'PeriodoVisaoFlowView');
      let painelManutencaoAguardando = this.store.parameters.filter(p => p.type == 'PainelManutencaoAguardando');
      let painelManutencaoEmAndamento = this.store.parameters.filter(p => p.type == 'PainelManutencaoEmAndamento');

      return (
        <Container modal={this.state.modal} contentModal={this.contentModal}>
          <ParametersList
            action={this.action}
            store={this.store}
            endpoints={this.endpoints}
            periodoVisaoFlowView={periodoVisaoFlowView}
            leadTimes={leadTimes}
            metaOEE={metaOEE}
            holdingTime={holdingTime}
            periodoVisaoTablet={periodoVisaoTablet}
            painelManutencaoAguardando={painelManutencaoAguardando}
            painelManutencaoEmAndamento={painelManutencaoEmAndamento}
            handleModal={this.handleModal}
            contentModal={this.contentModal}
          />
        </Container>
      );
    }
  }
}

class LeadTimeClassificationExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      green: 0,
      red: 0
    };

    this.handleValue = this.handleValue.bind(this);
  }

  componentDidMount() {
    this.setState({
      green: (parseInt(this.props.leadTimes.find(p => p.color == 'green').value * 100) + '%'),
      red: (parseInt(this.props.leadTimes.find(p => p.color == 'red').value * 100) + '%')
    });
  }

  componentWillReceiveProps(props) {

    this.setState({
      green: (parseInt(props.leadTimes.find(p => p.color == 'green').value * 100) + '%'),
      red: (parseInt(props.leadTimes.find(p => p.color == 'red').value * 100) + '%')
    });
  }

  handleValue(color, event) {
    var valGreen, valRed;

    valGreen = parseInt(this.state.green) ? parseInt(this.state.green) : 0;
    valRed = parseInt(this.state.red) ? parseInt(this.state.red) : 0;

    if ((valGreen > valRed) && color == 'green') {
      this.props.handleModal(this.props.t("errors.invalidValueRed"));
    } else if (valGreen > valRed) {
      this.props.handleModal(this.props.t("errors.invalidValueGreen"));
    } else {
      var data = {
        id: color == 'green' ? this.props.leadTimes.find(p => p.color == "green").id : this.props.leadTimes.find(p => p.color == "red").id,
        tipo: "leadtime",
        valor: color == 'green' ? (valGreen / 100) : (valRed / 100)
      };

      return new Promise((resolve, reject) => {
        request(this.props.endpoints.PARAMETROS, {
          method: 'put',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': Store.getToken()
          },
          body: JSON.stringify(requisicaoParametros(data)),
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            resolve();
          } else {
            reject();
          }
        }).catch(() => {
          reject();
        })
      });
    }
  }

  render() {
    let { leadTimes } = this.props;
    let { green, red } = this.state;

    if (!leadTimes.length) return <div className="fa fa-spin" />;

    return (
      <Card className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("leadTime.title", { primaryReference: utils.getReferenciaLoteOrdem().toLowerCase() })}
          textStyle={{
            paddingRight: '0',
            minWidth: '200px',
          }}
        />
        <CardText>
          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.green} />
            </div>
            <div>{this.props.t("common.upTo")}</div>
            <div style={{ width: '40%' }}>
              <NInputPercentage value={green} onChange={(val) => !this.props.disabled && this.setState({ green: val })} onBlur={(val) => !this.props.disabled && this.handleValue('green', val)} id='greenLT' />
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.yellow} />
            </div>
            <div>{this.props.t("common.between")}</div>
            <div className={styles.text}>
              {(parseInt(green) ? parseInt(green) : 0).toFixed(0)}% {this.props.t("labels.and", { ns: 'common' })} {(parseInt(red) ? parseInt(red) : 0).toFixed(0)}%
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.red} />
            </div>
            <div>{this.props.t("common.greaterThan")}</div>
            <div style={{ width: '40%' }}>
              <NInputPercentage onChange={(val) => !this.props.disabled && this.setState({ red: val })} value={red} onBlur={(val) => !this.props.disabled && this.handleValue('red', val)} id='red' name="red" />
            </div>
          </div>
        </CardText>
      </Card>
    );
  }
}

const LeadTimeClassification = withTranslation("parameters")(LeadTimeClassificationExport);

class PeriodoVisaoFlowViewExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valor: 'personalizar',
      quantidade: 90,
      quantidade_old: 90,
      loading: false,
      loaded: false,
    };
  }

  componentDidMount() {
    var valor = this.props.periodoVisaoFlowView[0].value;

    var data =
      valor == 1 || valor == 7 || valor == 30 ? valor.toString() : 'personalizar';

    this.setState({
      valor: data,
      quantidade: data == 'personalizar' ? valor.toString() : '90',
      quantidade_old: data == 'personalizar' ? valor.toString() : '90',
      loaded: true,
      loading: false,
    });
  }

  submit(valor) {
    var data = {
      id: this.props.periodoVisaoFlowView[0].id,
      tipo: "PeriodoVisaoFlowView",
      valor: Number(valor)
    };

    return new Promise((resolve, reject) => {
      request(this.props.endpoints.PARAMETROS, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': Store.getToken()
        },
        body: JSON.stringify(requisicaoParametros(data)),
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve();
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      })
    });
  }

  onQuantidadeBlur(e) {
    const { loading, quantidade, quantidade_old, valor } = this.state;

    if (quantidade > 90) {
      this.props.handleModal(this.props.t("errors.invalidRange", { days: 90 }));
      this.setState({ quantidade: quantidade_old, loading: false });
      return;
    } else if (quantidade == '') {
      this.setState({ quantidade: quantidade_old, loading: false });
      return;
    }

    if ((!loading) && valor == 'personalizar') {
      this.setState({
        loading: true,
      }, () => {
        this.submit(quantidade).then(() => {
          this.setState({ quantidade: quantidade, loading: false });
        }).catch(() => {
          this.props.handleModal(this.props.t("errors.cannotUpdate"));
          this.setState({ quantidade: quantidade_old, loading: false });
        });
      });
    }
  }

  onChange(e, value) {
    const { loading, valor } = this.state;

    if (!loading) {

      this.setState({
        loading: true
      }, () => {
        var data = value;

        if (value == 'personalizar') {
          data = this.state.quantidade;
        }

        this.submit(data).then(() => {
          this.setState({
            valor: value,
            loading: false,
          });
        }).catch(() => {
          this.props.handleModal(this.props.t("errors.cannotUpdate"));
          this.setState({
            valor: valor,
            loading: false,
          });
        });
      });
    }
  }

  onQuantidadeChange(e) {
    this.setState({
      quantidade: e.target.value
    });
  }

  onQuantidadeFocus(e) {
    this.state.quantidade_old = this.state.quantidade;
  }

  render() {
    let { periodoVisaoFlowView } = this.props;

    if (!periodoVisaoFlowView) return <div className="fa fa-spin" />;

    return (
      <Card className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("flowView.title")}
          textStyle={{
            paddingRight: '0',
            minWidth: '250px',
          }}
        />
        <CardText>
          <div style={{ position: 'relative' }}>
            <div>
              <RadioButtonGroup onChange={this.onChange.bind(this)} valueSelected={this.state.valor}>
                <RadioButton
                  value="1"
                  label={this.props.t("date.calendar.today", { ns: 'common' })}
                  style={{ padding: '5px 10px 5px 10px' }}
                />
                <RadioButton
                  value="7"
                  label={this.props.t("date.calendar.currentWeek", { ns: 'common' })}
                  style={{ padding: '5px 10px 5px 10px' }}
                />
                <RadioButton
                  value="30"
                  label={this.props.t("date.calendar.currentMonth", { ns: 'common' })}
                  style={{ padding: '5px 10px 5px 10px' }}
                />
                <RadioButton
                  value="personalizar"
                  label={this.props.t("common.last")}
                  style={{ padding: '5px 10px 5px 10px' }}
                />
              </RadioButtonGroup>
            </div>
            <div style={{ position: 'absolute', zIndex: '100', width: '67px', bottom: '9px', right: '50px' }}>
              <div className={'flex'}>
                <div>
                  <NInput
                    style={{ padding: '.4rem .4rem 0 .4rem' }}
                    maxLength="2"
                    value={this.state.quantidade}
                    className={styles.input_dias}
                    onChange={this.onQuantidadeChange.bind(this)}
                    onBlur={this.onQuantidadeBlur.bind(this)}
                    onFocus={this.onQuantidadeFocus.bind(this)}
                  />
                </div>
                <div className={'flex flex-align-end'} style={{
                  padding: '0 0 0 10px',
                  height: '24px',
                  color: '#000'
                }}
                >{this.props.t("common.days")}</div>
              </div>
            </div>
          </div>
        </CardText>
      </Card>
    );
  }
}

const PeriodoVisaoFlowView = withTranslation("parameters")(PeriodoVisaoFlowViewExport);

class ParametersListExport extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { leadTimes, holdingTime, periodoVisaoFlowView, action, endpoints, painelManutencaoAguardando, painelManutencaoEmAndamento } = this.props;
    return (
      <div className={styles.boxParameter}>
        <div className={styles.boxChildParameter} >
          <LeadTimeClassification disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} action={action} endpoints={endpoints} leadTimes={leadTimes} />
          <PeriodoVisaoTablet disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} periodoVisaoTablet={this.props.periodoVisaoTablet} endpoints={endpoints} />
          <MetaOEE disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} action={action} endpoints={endpoints} holdingTime={holdingTime} />
          <TipoGraficoDashEquipamento endpoints={endpoints} />
          <RampUP endpoints={endpoints} />
        </div>

        <div className={styles.boxChildParameter} >
        <PeriodoVisaoFlowView disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} action={action} endpoints={endpoints} periodoVisaoFlowView={periodoVisaoFlowView} />
          <PeriodoTimelineTablet 
            disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} 
            {...this.props} 
            action={action} 
            endpoints={endpoints} 
            painelStatus={painelManutencaoAguardando} 
            />
            <PainelManutencao disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} action={action} endpoints={endpoints} painelStatus={painelManutencaoAguardando} title={`${this.props.t("kanbanPanel.title")} - ${this.props.t("kanbanPanel.firstLane")}`} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <HoraInicialDiaProdutivo endpoints={endpoints} />
            <QuantidadeProduzidaNoFimDeOrdem endpoints={endpoints} />
          </div>
        </div>

        <div className={styles.boxChildParameter}>
          <HoldingTimeClassification disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} action={action} endpoints={endpoints} holdingTime={holdingTime} />
          <PersonalizacaoTecladoTablet disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} endpoints={endpoints} />
          <PainelManutencao disabled={!hasRole('ROLE_PARAMETROS_ATUALIZAR')} {...this.props} action={action} endpoints={endpoints} painelStatus={painelManutencaoEmAndamento} title={`${this.props.t("kanbanPanel.title")} - ${this.props.t("kanbanPanel.secondLane")}`} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <OrdemPorQRCode endpoints={endpoints} />
            <DadosSensores endpoints={endpoints} />
            <ShowCurrentUser endpoints={endpoints} />
            <DataLiberacaoPA endpoints={endpoints} />
          </div>
        </div>
      </div>
    );
  }
}

const ParametersList = withTranslation("parameters")(ParametersListExport);

class PersonalizacaoTecladoTabletExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tecla1: '',
      tecla2: '',
      tecla3: ''
    }

    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS_TABLET, {
      method: 'get',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      data.map((param) => {
        this.setState({
          [param.chave]: param.valor
        })
      })
    })
  }

  handleKeyUp(event) {
    const regex = /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\]^_`{|}~-ç]$/i;

    if (!regex.test(event.key)) return;

    const target = event.target;
    const value = event.key.toUpperCase();
    const name = target.name;

    this.setState({
      [name]: value
    });

    this.save(name, value);
    target.blur();
  }

  save(chave, valor) {
    let uri = `?chave=${chave}&valor=${encodeURIComponent(valor)}`;
    return new Promise((resolve, reject) => {
      request(this.props.endpoints.CONFIGURACAO_PARAMETROS + uri, {
        method: 'put',
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve();
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      })
    });
  }

  render() {
    return (
      <Card style={{ height: '224px' }} className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("tabletKeyboard.title")}
          textStyle={{
            paddingRight: '0',
            minWidth: '250px'
          }}
        />
        <CardText className={styles.ConfiguracaoTecladoTablet}>
          {
            [
              { title: this.props.t("tabletKeyboard.key", { keyNumber: 1 }), name: 'tecla1' },
              { title: this.props.t("tabletKeyboard.key", { keyNumber: 2 }), name: 'tecla2' },
              { title: this.props.t("tabletKeyboard.key", { keyNumber: 3 }), name: 'tecla3' }
            ].map((tecla) => {
              return <TeclaTablet
                key={tecla.name}
                title={tecla.title}
                name={tecla.name}
                disabled={this.props.disabled}
                value={this.state[tecla.name]}
                handleKeyUp={this.handleKeyUp}
              />
            })
          }
        </CardText>
      </Card>
    );
  }
}

const PersonalizacaoTecladoTablet = withTranslation("parameters")(PersonalizacaoTecladoTabletExport);

class QuantidadeProduzidaNoFimDeOrdemExport extends Component {
  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_TABLET_QT_PRODUZIDA_FIM_DE_ORDEM, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      this.setState({
        [data.chave]: !!parseInt(data.valor)
      })
    })
  }

  changeParameter(value) {
    const parameterValue = value ? "1" : "0";
    const url = `?chave=QtProduzidaNoFimDeOrdem&valor=${parameterValue}`
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        QtProduzidaNoFimDeOrdem: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    return (
      <Card style={{ width: '280px', height: "70px", display: "inline-flex" }} className={'box_list_card ' + styles.card}>
        <div style={{ display: "inline-flex", justifyContent: "flex-end", alignItems: "end" }}>
          <CardHeader
            title={this.props.t("producedAmount.title", { primaryReference: utils.getReferenciaLoteOrdem() })}
            textStyle={{
              paddingRight: '0',
              wordWrap: "break-word",
              maxWidth: "180px"
            }}
          />
          <div style={{ display: "flex", height: "40px", alignContent: "flex-start", justifyContent: "flex-start" }}>
            <Toggle
              style={{ width: '40px' }}
              name='ativo'
              onToggle={() => this.changeParameter(!this.state.QtProduzidaNoFimDeOrdem)}
              id='QtProduzidaNoFimDeOrdem'
              thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
              trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
              labelPosition="right"
              label=""
              defaultToggled={this.state?.QtProduzidaNoFimDeOrdem}
            />
          </div>
        </div>
      </Card>
    )
  };
}

const QuantidadeProduzidaNoFimDeOrdem = withTranslation("parameters")(QuantidadeProduzidaNoFimDeOrdemExport);

class OrdemPorQRCodeExport extends Component {
  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == 'ProducaoPorQRCodeTablet');

      if (param) {
        var isTrue = (param.valor.toLowerCase() === 'true');

        this.setState({
          [param.chave]: isTrue
        });
      }
    })
  };

  changeParameter(value) {
    const parameterValue = value ? "True" : "False";
    const url = `?chave=ProducaoPorQRCodeTablet&valor=${parameterValue}`
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        ProducaoPorQRCodeTablet: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    return (
      <Card style={{ width: '280px', height: "60px", display: "inline-flex" }} className={'box_list_card ' + styles.card}>
        <div style={{ display: "inline-flex", justifyContent: "flex-end", alignItems: "end" }}>
          <CardHeader
            title={this.props.t("orderByQrCodeTablet.title", { primaryReference: utils.getReferenciaLoteOrdem() })}
            textStyle={{
              paddingRight: '0',
              wordWrap: "break-word",
              maxWidth: "180px"
            }}
          />
          <div style={{ display: "flex", height: "40px", alignContent: "flex-start", justifyContent: "flex-start" }}>
            <Toggle
              style={{ width: '40px' }}
              name='ProducaoPorQRCodeTablet'
              onToggle={() => this.changeParameter(!this.state.ProducaoPorQRCodeTablet)}
              id='ProducaoPorQRCodeTablet'
              thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
              trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
              labelPosition="right"
              label=""
              defaultToggled={this.state?.ProducaoPorQRCodeTablet}
            />
          </div>
        </div>
      </Card>
    )
  };
}

const OrdemPorQRCode = withTranslation("parameters")(OrdemPorQRCodeExport);

class DadosSensoresExport extends Component {
  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == 'LeituraDeSensores');

      if (param) {
        var isTrue = (param.valor.toLowerCase() === 'true');

        this.setState({
          [param.chave]: isTrue
        });
      }
    })
  };

  changeParameter(value) {
    const parameterValue = value ? "True" : "False";
    const url = `?chave=LeituraDeSensores&valor=${parameterValue}`
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        LeituraDeSensores: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    return (
      <Card style={{ width: '280px', height: "60px", display: "inline-flex" }} className={'box_list_card ' + styles.card}>
        <div style={{ display: "inline-flex", justifyContent: "flex-end", alignItems: "end" }}>
          <CardHeader
            title={this.props.t("DadosSensores.title")}
            textStyle={{
              paddingRight: '0',
              wordWrap: "break-word",
              maxWidth: "180px"
            }}
          />
          <div style={{ display: "flex", height: "40px", alignContent: "flex-start", justifyContent: "flex-start" }}>
            <Toggle
              style={{ width: '40px' }}
              name='LeituraDeSensores'
              onToggle={() => this.changeParameter(!this.state.LeituraDeSensores)}
              id='LeituraDeSensores'
              thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
              trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
              labelPosition="right"
              label=""
              defaultToggled={this.state?.LeituraDeSensores}
            />
          </div>
        </div>
      </Card>
    )
  };
}

const DadosSensores = withTranslation("parameters")(DadosSensoresExport);

class TeclaTablet extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <p>{this.props.title}</p>
        <Card>
          <CardText>
            <NInput
              name={this.props.name}
              className={styles.inputClass}
              value={this.props.value}
              onKeyUp={e => !this.props.disabled && this.props.handleKeyUp(e)}
              onFocus={e => !this.props.disabled && e.target.select()}
              onClick={e => !this.props.disabled && e.target.select()}
            />
          </CardText>
        </Card>
      </div>
    );
  }
}

class HoldingTimeClassificationExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      green: 0,
      black: 0
    };

    this.handleValue = this.handleValue.bind(this);
  }

  componentDidMount() {
    this.setState({
      green: (parseInt(this.props.holdingTime.find(p => p.color == 'green').value * 100) + '%'),
      black: (parseInt(this.props.holdingTime.find(p => p.color == 'black').value * 100) + '%')
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      green: (parseInt(props.holdingTime.find(p => p.color == 'green').value * 100) + '%'),
      black: (parseInt(props.holdingTime.find(p => p.color == 'black').value * 100) + '%')
    });
  }

  handleValue(color, event) {
    var valGreen, valBlack;

    valGreen = parseInt(this.state.green) ? parseInt(this.state.green) : 0;
    valBlack = parseInt(this.state.black) ? parseInt(this.state.black) : 0;

    if ((valGreen > valBlack) && color == 'green') {
      this.props.handleModal(this.props.t("errors.invalidValueField", { field: this.props.t("common.greaterThan") }));

    } else if (valGreen > valBlack) {
      this.props.handleModal(this.props.t("errors.invalidValueField", { field: this.props.t("common.upTo") }));
    } else {
      return new Promise((resolve, reject) => {
        request(this.props.endpoints.PARAMETROS, {
          method: 'put',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': Store.getToken()
          },
          body: JSON.stringify({
            tipo: "holdingTime",
            id: color == 'green' ? this.props.holdingTime.find(p => p.color == "green").id : this.props.holdingTime.find(p => p.color == "black").id,
            valor: color == 'green' ? (valGreen / 100) : (valBlack / 100)
          }),
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            resolve();
          } else {
            reject();
          }
        }).catch(() => {
          reject();
        })
      });
    }
  }

  render() {
    let { holdingTime } = this.props;
    let { green, black } = this.state;

    if (!holdingTime.length) return <div className="fa fa-spin" />;

    return (
      <Card style={{ height: '224px' }} className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("holdingTime.title")}
          textStyle={{
            paddingRight: '0',
            minWidth: '200px'
          }}
        />
        <CardText>
          <div className={styles.options}>
            <div>
              <i className="material-icons">remove</i>
            </div>
            <div>{this.props.t("common.upTo")}</div>
            <div style={{ width: '40%' }}>
              <NInputPercentage value={green} onChange={(val) => !this.props.disabled && this.setState({ green: val })} onBlur={(val) => !this.props.disabled && this.handleValue('green', val)} id='greenHT' name="greenHT" />
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i style={{ color: '#5E2129' }} className={'fa fa-circle ' + styles.red} />
            </div>
            <div>{this.props.t("common.between")}</div>
            <div className={styles.text}>
              {(parseInt(green) ? parseInt(green) : 0).toFixed(0)}% {this.props.t("labels.and", { ns: 'common' })} {(parseInt(black) ? parseInt(black) : 0).toFixed(0)}%
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.black} />
            </div>
            <div>{this.props.t("common.greaterThan")}</div>
            <div style={{ width: '40%' }}>
              <NInputPercentage value={black} onChange={val => !this.props.disabled && this.setState({ black: val })} onBlur={(val) => !this.props.disabled && this.handleValue('black', val)} id='blackHT' name="blackHT" />
            </div>
          </div>
        </CardText>
      </Card>
    );
  }
}

const HoldingTimeClassification = withTranslation("parameters")(HoldingTimeClassificationExport);

class MetaOEEExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verde: 0,
      vermelho: 0
    };

    this.handleValue = this.handleValue.bind(this);
  }

  componentDidMount() {
    this.setState({
      verde: (parseInt(this.props.metaOEE.find(p => p.color == 'verde').value * 100) + '%'),
      vermelho: (parseInt(this.props.metaOEE.find(p => p.color == 'vermelho').value * 100) + '%')
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      verde: (parseInt(props.metaOEE.find(p => p.color == 'verde').value * 100) + '%'),
      vermelho: (parseInt(props.metaOEE.find(p => p.color == 'vermelho').value * 100) + '%')
    });
  }

  handleValue(color, event) {
    var valVerde, valVermelho;

    valVerde = parseInt(this.state.verde) ? parseInt(this.state.verde) : 0;
    valVermelho = parseInt(this.state.vermelho) ? parseInt(this.state.vermelho) : 0;

    if ((valVermelho > valVerde) && color == 'vermelho') {
      this.props.handleModal(this.props.t("errors.invalidValueField", { field: this.props.t("common.greaterThan") }));

    } else if (valVerde < valVermelho) {
      this.props.handleModal(this.props.t("errors.invalidValueField", { field: this.props.t("common.upTo") }));
    } else {
      var data = {
        id: color == 'verde' ? this.props.metaOEE.find(p => p.color == "verde").id : this.props.metaOEE.find(p => p.color == "vermelho").id,
        tipo: "Oee",
        valor: color == 'verde' ? (valVerde / 100) : (valVermelho / 100)
      }

      return new Promise((resolve, reject) => {
        request(this.props.endpoints.PARAMETROS, {
          method: 'put',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': Store.getToken()
          },
          body: JSON.stringify(requisicaoParametros(data)),
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            resolve();
          } else {
            reject();
          }
        }).catch(() => {
          reject();
        })
      });
    }
  }

  render() {
    let { metaOEE } = this.props;
    let { verde, vermelho } = this.state;

    if (!metaOEE.length) return <div className="fa fa-spin" />;

    return (
      <Card className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("oeeGoal.title")}
          textStyle={{
            paddingRight: '0',
            minWidth: '200px',
          }}
        />
        <CardText>
          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.red} />
            </div>
            <div>{this.props.t("common.upTo")}</div>
            <div style={{ width: '40%' }}>
              <NInputPercentage value={vermelho} onChange={(val) => !this.props.disabled && this.setState({ vermelho: val })} onBlur={(val) => !this.props.disabled && this.handleValue('vermelho', val)} id='vermelhoOee' name="vermelhoOee" />
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.yellow} />
            </div>
            <div>{this.props.t("common.between")}</div>
            <div className={styles.text}>
              {(parseInt(vermelho) ? parseInt(vermelho) : 0).toFixed(0)}% {this.props.t("labels.and", { ns: 'common' })} {(parseInt(verde) ? parseInt(verde) : 0).toFixed(0)}%
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.green} />
            </div>
            <div>{this.props.t("common.greaterThan")}</div>
            <div style={{ width: '40%' }}>
              <NInputPercentage value={verde} onChange={(val) => !this.props.disabled && this.setState({ verde: val })} onBlur={(val) => !this.props.disabled && this.handleValue('verde', val)} id='verdeOee' name="verdeOee" />
            </div>
          </div>
        </CardText>
      </Card>
    );
  }
}

const MetaOEE = withTranslation("parameters")(MetaOEEExport);

class PeriodoVisaoTabletExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: 'personalizar',
      quantidade: 90,
      quantidade_old: 90,
      loading: false,
      loaded: false,
    };
  }

  componentDidMount() {
    request(this.props.endpoints.PERIODO_VISAO_TABLET, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': Store.getToken()
      },
    }, true).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      var valor = data.valor;

      var data =
        valor == -1 || valor == 7 || valor == 15 || valor == 30 ? valor.toString() : 'personalizar';

      this.setState({
        valor: data,
        quantidade: data == 'personalizar' ? valor.toString() : '90',
        quantidade_old: data == 'personalizar' ? valor.toString() : '90',
        loaded: true,
        loading: false,
      });
    })
  }

  onChange(e, value) {
    const { loading, valor } = this.state;

    if (!loading) {

      this.setState({
        loading: true
      }, () => {
        var data = value;

        if (value == 'personalizar') {
          data = this.state.quantidade;
        }

        this.submit(data).then(() => {
          this.setState({
            valor: value,
            loading: false,
          });
        }).catch(() => {
          this.props.handleModal(this.props.t("errors.cannotUpdate"));
          this.setState({
            valor: valor,
            loading: false,
          });
        });
      });
    }
  }

  onQuantidadeChange(e) {
    this.setState({
      quantidade: e.target.value
    });
  }

  onQuantidadeBlur(e) {
    const { loading, quantidade, quantidade_old, valor } = this.state;

    if (quantidade > 90) {
      this.props.handleModal(this.props.t("errors.invalidRange", { days: 90 }));
      this.setState({ quantidade: quantidade_old, loading: false });
      return;
    }

    if ((!loading) && valor == 'personalizar') {
      this.setState({
        loading: true,
      }, () => {
        this.submit(quantidade).then(() => {
          this.setState({ quantidade: quantidade, loading: false });
        }).catch(() => {
          this.props.handleModal(this.props.t("errors.cannotUpdate"));
          this.setState({ quantidade: quantidade_old, loading: false });
        });
      });
    }
  }

  submit(valor) {
    var data = {
      id: this.props.periodoVisaoTablet.find(p => p.type == "periodoVisaoTablet").id,
      tipo: "periodoVisaoTablet",
      valor: Number(valor)
    };

    return new Promise((resolve, reject) => {
      request(this.props.endpoints.PARAMETROS, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': Store.getToken()
        },
        body: JSON.stringify(requisicaoParametros(data)),
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve();
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      })
    });
  }

  onQuantidadeFocus(e) {
    this.state.quantidade_old = this.state.quantidade;
  }

  render() {
    const { valor, quantidade, loaded } = this.state;

    if (loaded) {
      return (
        <Card className={'box_list_card ' + styles.card}>
          <CardHeader
            className={styles.header}
            title={this.props.t("tabletHistoryData.title")}
            textStyle={{
              paddingRight: '0',
              minWidth: '250px',
            }}
          />
          <CardText>
            <div style={{ position: 'relative' }}>
              <div>
                <RadioButtonGroup name="periodoVisao" onChange={(e, value) => !this.props.disabled && this.onChange(e, value)} valueSelected={valor}>
                  <RadioButton
                    value="-1"
                    label={this.props.t("common.currentDay")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="7"
                    label={this.props.t("common.lastDays", { days: 7 })}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="15"
                    label={this.props.t("common.lastDays", { days: 15 })}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="30"
                    label={this.props.t("common.lastDays", { days: 30 })}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="personalizar"
                    label={this.props.t("common.customize")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                </RadioButtonGroup>
              </div>
              <div style={{ position: 'absolute', zIndex: '100', width: '67px', bottom: '9px', right: '50px' }}>
                <div className={'flex'}>
                  <div>
                    <NInput
                      style={{ padding: '.4rem .4rem 0 .4rem' }}
                      maxLength="2"
                      value={quantidade}
                      className={styles.input_dias}
                      onChange={(e) => !this.props.disabled && this.onQuantidadeChange(e)}
                      onBlur={(e) => !this.props.disabled && this.onQuantidadeBlur(e)}
                      onFocus={(e) => !this.props.disabled && this.onQuantidadeFocus(e)}
                    />
                  </div>
                  <div className={'flex flex-align-end'} style={{
                    padding: '0 0 0 10px',
                    height: '24px',
                    color: '#000'
                  }}
                  >{this.props.t("common.days")}</div>
                </div>
              </div>
            </div>
          </CardText>
        </Card>
      );
    } else {
      return null;
    }
  }
}

class PeriodoTimelineTablet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: '0',
      loading: false,
      loaded: false,
    };
  }

  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': Store.getToken()
      },
    }, true).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == 'PeriodoVisaoTimelineTablet');
      this.setState({
        valor: param.valor,
        loaded: true,
        loading: false,
      });
    });
  }

  onChange(e, value) {
    const { loading, valor } = this.state;

    if (!loading) {

      this.setState({
        loading: true
      }, () => {
        var data = value;

        this.submit(data).then(() => {
          this.setState({
            valor: value,
            loading: false,
          });
        }).catch(() => {
          this.props.handleModal(this.props.t("errors.cannotUpdate"));
          this.setState({
            valor: valor,
            loading: false,
          });
        });
      });
    }
  }

  submit(valor) {    
    const url = `?chave=PeriodoVisaoTimelineTablet&valor=${Number(valor)}`;

    return new Promise((resolve, reject) => {
      request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Access-Token': Store.getToken()
        }
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve();
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      });
    });
  }

  render() {
    const { valor, quantidade, loaded } = this.state;

    if (loaded) {
      return (
        <Card style={{ height: '255px' }} className={'box_list_card ' + styles.card}>
          <CardHeader
            className={styles.header}
            title={this.props.t('parameterTimelineTablet.title')}
            textStyle={{
              paddingRight: '0',
              minWidth: '250px',
            }}
          />
          <CardText>
            <div style={{ position: 'relative' }}>
              <div>
                <RadioButtonGroup name="periodoVisao" onChange={(e, value) => !this.props.disabled && this.onChange(e, value)} valueSelected={valor}>
                  <RadioButton
                    value="0"
                    label={this.props.t("common.currentDay")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="1"
                    label={this.props.t("common.last24Hours")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="2"
                    label={this.props.t("common.last16Hours")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="3"
                    label={this.props.t("common.last12Hours")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                  <RadioButton
                    value="4"
                    label={this.props.t("common.last8Hours")}
                    style={{ padding: '5px 10px 5px 10px' }}
                  />
                </RadioButtonGroup>
              </div>
            </div>
          </CardText>
        </Card>
      );
    } else {
      return null;
    }
  }
}

const PeriodoVisaoTablet = withTranslation("parameters")(PeriodoVisaoTabletExport);

class PainelManutencaoExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vermelho: 1200,
      cinza: 600
    };

    this.handleValue = this.handleValue.bind(this);
  }

  componentDidMount() {
    this.setState({
      vermelho: (this.secondsToTime(this.props.painelStatus.find(p => p.color == 'vermelho').value)),
      cinza: (this.secondsToTime(this.props.painelStatus.find(p => p.color == 'cinza').value))
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      vermelho: (this.secondsToTime(props.painelStatus.find(p => p.color == 'vermelho').value)),
      cinza: (this.secondsToTime(props.painelStatus.find(p => p.color == 'cinza').value))
    });
  }

  secondsToTime(seconds) {
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  }

  timeToSeconds(time) {
    return moment.duration(time).asSeconds();
  }

  betweenValues() {
    var valVermelho, valCinza;

    valVermelho = this.timeToSeconds(this.state.vermelho);
    valCinza = this.timeToSeconds(this.state.cinza);

    return {
      vermelho: this.secondsToTime(valVermelho - 1),
      cinza: this.secondsToTime(valCinza + 1)
    }
  }

  handleValue(color, event) {
    var valVermelho, valCinza;
    var painelStatus = this.props.painelStatus.find(p => p.color == color);

    valVermelho = this.state.vermelho;
    valCinza = this.state.cinza;

    if ((valCinza > valVermelho) && color == 'cinza') {
      this.props.handleModal(this.props.t("errors.invalidValueField", { field: this.props.t("common.greaterThan") }));

    } else if (valVermelho < valCinza) {
      this.props.handleModal(this.props.t("errors.invalidValueField", { field: this.props.t("common.upTo") }));
    } else {
      var data = {
        id: painelStatus.id,
        tipo: painelStatus.type,
        valor: color == 'vermelho' ? this.timeToSeconds(valVermelho) : this.timeToSeconds(valCinza)
      }

      return new Promise((resolve, reject) => {
        request(this.props.endpoints.PARAMETROS, {
          method: 'put',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': Store.getToken()
          },
          body: JSON.stringify(requisicaoParametros(data)),
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            resolve();
          } else {
            reject();
          }
        }).catch(() => {
          reject();
        })
      });
    }
  }

  render() {
    let { painelStatus, title } = this.props;
    let { vermelho, cinza } = this.state;
    let inputStyle = { whiteSpace: 'nowrap', width: '100%', paddingBottom: '10px', height: 'unset' }

    if (!painelStatus.length) return <div className="fa fa-spin" />;

    return (
      <Card className={'box_list_card ' + styles.card} style={{
        height: "min-content"
      }}>
        <CardHeader
          className={styles.header}
          title={title}
          textStyle={{
            paddingRight: '0',
            minWidth: '200px',
          }}
        />
        <CardText>
          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.blackWithOpacity} />
            </div>
            <div>{this.props.t("common.upTo")}</div>
            <div className={styles.timeField} style={{ width: '40%' }}>
              <NInputTime
                type='time'
                onChange={(_event, val) => !this.props.disabled && this.setState({ cinza: val })}
                onBlur={(val) => !this.props.disabled && this.handleValue('cinza', val)}
                step={"1"}
                value={cinza}
                style={inputStyle}
              />
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.yellowWithOpacity} />
            </div>
            <div>{this.props.t("common.between")}</div>
            <div className={styles.text}>
              {this.betweenValues().cinza} {this.props.t("labels.and", { ns: 'common' })} {this.betweenValues().vermelho}
            </div>
          </div>

          <div className={styles.options}>
            <div>
              <i className={'fa fa-circle ' + styles.redWithOpacity} />
            </div>
            <div>{this.props.t("common.greaterThan")}</div>
            <div className={styles.timeField} style={{ width: '40%' }}>
              <NInputTime
                type='time'
                onChange={(_event, val) => !this.props.disabled && this.setState({ vermelho: val })}
                onBlur={(val) => !this.props.disabled && this.handleValue('vermelho', val)}
                step={"1"}
                value={vermelho}
                style={inputStyle}
              />
            </div>
          </div>
        </CardText>
      </Card>
    );
  }
}

const PainelManutencao = withTranslation("parameters")(PainelManutencaoExport);

class TipoGraficoDashEquipamentoExport extends Component {
  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == 'TipoGraficoDashEquipamento');

      if (param) {
        this.setState({
          [param.chave]: param.valor
        });
      }
    })
  };

  changeParameter(e, value) {
    const parameterValue = value;
    const url = `?chave=TipoGraficoDashEquipamento&valor=${parameterValue}`;

    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        TipoGraficoDashEquipamento: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    return (
      <Card className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("equipmentDashWidgetType.title")}
          textStyle={{
            paddingRight: '0',
            minWidth: '250px',
          }}
        />
        <CardText>
          <div style={{ position: 'relative' }}>
            <div>
              <RadioButtonGroup onChange={this.changeParameter.bind(this)} valueSelected={this.state?.TipoGraficoDashEquipamento}>
                <RadioButton
                  value="Velocidade"
                  label={this.props.t("equipmentDashWidgetType.speed")}
                  style={{ padding: '5px 10px 5px 10px' }}
                />
                <RadioButton
                  value="Quantidade"
                  label={this.props.t("equipmentDashWidgetType.amount")}
                  style={{ padding: '5px 10px 5px 10px' }}
                />
              </RadioButtonGroup>
            </div>
          </div>
        </CardText>
      </Card>
    )
  };
}

const TipoGraficoDashEquipamento = withTranslation("parameters")(TipoGraficoDashEquipamentoExport);

class HoraInicialDiaProdutivoExport extends Component {
  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == 'HoraInicialDiaProdutivo');

      if (param) {
        this.setState({
          [param.chave]: param.valor
        });
      }
    })
  };

  save(value) {
    if (value === undefined || value === null || value === "") {
      value = '00:00:00';
    }

    const url = `?chave=HoraInicialDiaProdutivo&valor=${value}`;

    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        HoraInicialDiaProdutivo: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    let inputStyle = { whiteSpace: 'nowrap', width: '100%', paddingBottom: '10px', height: 'unset' };

    return (
      <Card style={{ height: '165px' }} className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("startTimeProductiveDay.title")}
          subtitle={this.props.t("startTimeProductiveDay.subtitle")}
          subtitleStyle={{ marginTop: '5px' }}
          textStyle={{ paddingRight: '0', minWidth: '250px' }}
        />
        <CardText>
          <div className={styles.options}>
            <div>{this.props.t("common.startAt")}</div>
            <div className={styles.timeField} style={{ width: '40%' }}>
              <NInputTime
                type='time'
                onBlur={this.save.bind(this)}
                onChange={(_event, val) => this.setState({ HoraInicialDiaProdutivo: val })}
                step={"1"}
                value={this.state?.HoraInicialDiaProdutivo}
                style={inputStyle}
              />
            </div>
          </div>
        </CardText>
      </Card>
    )
  };
}

const HoraInicialDiaProdutivo = withTranslation("parameters")(HoraInicialDiaProdutivoExport);

class RampUPExport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      RampUP_PERCENT: '',
      RampUP_HOUR: '',
    };
  }


  componentDidMount() {

    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let parameters = data.filter(f => f.chave == 'RampUP_PERCENT' || f.chave == 'RampUP_HOUR');

      if (parameters) {
        for (var param of parameters) {
          this.setState({
            [param.chave]: param.valor
          });
        }
      }
    })
  };

  save(field, obj) {

    let { value } = obj.currentTarget;

    if (obj === undefined || obj === null || obj === "") {
      value = '%';
    }

    const url = `?chave=${field}&valor=${value}`;

    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        RampUP: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    return (
      <Card className={'box_list_card ' + styles.card}>
        <CardHeader
          className={styles.header}
          title={this.props.t("rampUpSetup.title")}
          subtitleStyle={{ marginTop: '5px' }}
          textStyle={{ paddingRight: '0', minWidth: '250px' }}
        />
        <CardText>
          <div className={styles.options} style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
            <div style={{ display: 'flex', height: '61px', paddingLeft: '10px' }}>
              <div style={{ width: '65%' }}>{this.props.t("rampUpSetup.rampUPPercent")}</div>
              <NInputPercentage
                value={this.state.RampUP_PERCENT}
                onChange={(_event, val) => this.setState({ RampUP_PERCENT: val })}
                onBlur={this.save.bind(this, 'RampUP_PERCENT')}
                id='RamUPValue'
                name="RamUPValue" />
            </div>
            <div style={{ display: 'flex', height: '61px', paddingLeft: '10px' }}>
              <div style={{ width: '65%' }}>{this.props.t("rampUpSetup.rampUPTime")}</div>
              <InputTime
                ref={'RampUP_HOUR'}
                onChange={(event) => {
                  this.setState({ RampUP_HOUR: MaskHoraMinutoSegundo(event.target.value) });
                }}
                onBlur={this.save.bind(this, 'RampUP_HOUR')}
                styles={styles}
                id={'RampUP_HOUR'}
                value={this.state.RampUP_HOUR == '' ? '00:00:00' : this.state.RampUP_HOUR}
              />
            </div>
          </div>
        </CardText>
      </Card>
    )
  };
}

const RampUP = withTranslation("parameters")(RampUPExport);

class ShowCurrentUserExport extends Component {

  VISUALIZA_USUARIO_CORRENTE = 'VisualizaUsuarioCorrente';

  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == this.VISUALIZA_USUARIO_CORRENTE);

      if (param) {
        var isTrue = (param.valor.toLowerCase() === 'true');

        this.setState({
          [param.chave]: isTrue
        });
      }
    })
  };

  changeParameter(value) {
    const parameterValue = value ? "True" : "False";
    const url = `?chave=${this.VISUALIZA_USUARIO_CORRENTE}&valor=${parameterValue}`
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        VisualizaUsuarioCorrente: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    return (
      <Card style={{ width: '280px', height: "60px", display: "inline-flex" }} className={'box_list_card ' + styles.card}>
        <div style={{ display: "inline-flex", justifyContent: "flex-end", alignItems: "end" }}>
          <CardHeader
            title={this.props.t("ShowCurrentUser.title")}
            textStyle={{
              paddingRight: '0',
              wordWrap: "break-word",
              maxWidth: "180px"
            }}
          />
          <div style={{ display: "flex", height: "40px", alignContent: "flex-start", justifyContent: "flex-start" }}>
            <Toggle
              style={{ width: '40px' }}
              name='VisualizaUsuarioCorrente'
              onToggle={() => this.changeParameter(!this.state.VisualizaUsuarioCorrente)}
              id='VisualizaUsuarioCorrente'
              thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
              trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
              labelPosition="right"
              label=""
              defaultToggled={this.state?.VisualizaUsuarioCorrente}
            />
          </div>
        </div>
      </Card>
    )
  };
}

const ShowCurrentUser = withTranslation("parameters")(ShowCurrentUserExport);

class DataLiberacaoPAExport extends Component {

  DATA_LIBERACAO_PA = 'DataLiberacaoPA';

  componentDidMount() {
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS, {
      method: 'GET',
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
    }).then(data => {
      let param = data.find(f => f.chave == this.DATA_LIBERACAO_PA);

      if (param) {
        var isTrue = (param.valor.toLowerCase() === 'true');

        this.setState({
          [param.chave]: isTrue
        });
      }
    })
  };

  changeParameter(value) {
    const parameterValue = value ? "True" : "False";
    const url = `?chave=${this.DATA_LIBERACAO_PA}&valor=${parameterValue}`
    request(this.props.endpoints.CONFIGURACAO_PARAMETROS + url, {
      method: 'PUT'
    }).then(() => {
      this.setState({
        DataLiberacaoPA: value
      })
      alertBox(this.props.t("messages.success"), this.props.t("labels.success", { ns: 'common' }), 'success')
    }).catch((e) => {

      alertBox(this.props.t("messages.cannotUpdate"), this.props.t("labels.error", { ns: 'common' }), 'error')
    })
  }

  render() {
    let labelParametro = this.props.t("dataLiberacaoPA.title", { primaryReference: utils.getReferenciaDoLoteDaOrdemParametros() });

    return (
      <Card style={{ width: '280px', height: "60px", display: "inline-flex" }} className={'box_list_card ' + styles.card}>
        <div style={{ display: "inline-flex", justifyContent: "flex-end", alignItems: "end" }}>
          <CardHeader
            title={labelParametro}
            textStyle={{
              paddingRight: '0',
              wordWrap: "break-word",
              maxWidth: "180px"
            }}
          />
          <div style={{ display: "flex", height: "40px", alignContent: "flex-start", justifyContent: "flex-start" }}>
            <Toggle
              style={{ width: '40px' }}
              name='DataLiberacaoPA'
              onToggle={() => this.changeParameter(!this.state.DataLiberacaoPA)}
              id='DataLiberacaoPA'
              thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
              trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
              labelPosition="right"
              label=""
              defaultToggled={this.state?.DataLiberacaoPA}
            />
          </div>
        </div>
      </Card>
    )
  };
}

const DataLiberacaoPA = withTranslation("parameters")(DataLiberacaoPAExport);