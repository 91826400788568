import { UserGroup } from 'clients/Users/UserGroup';
import TextTag from 'common/Components/TextTag/TextTag';
import { MenuItem, RaisedButton, SelectField, TextField, Toggle } from 'material-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import Container from '../../common/Container';
import DeleteItem from '../../common/DeleteItem';
import { requisicaoEventos, respostaClassesEventos, respostaEventos, respostaGrupoEventos } from '../../common/mappers';
import { alertBox } from '../../common/NForm';
import { request, requestAll } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import Util from '../../utils/Utils';
import styles from './events.css';
import EventsList from './EventsList';
import { default as MultiSelect } from 'components/AutoComplete';

@observer
class EventsPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      loadingPage: true,
      nome: '',
      codigo: '',
      classe: '',
      grupoEvento: [],
      instantaneo: false,
      producao: false,
      erroNome: '',
      erroCodigo: '',
      erroClasse: '',
      id: '',
      permissaoQrCode: false,
      tags: [],
      user_Group: [],
      userGroup: []
    };

    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleNumeric = this.handleNumeric.bind(this);
    this.formEvento = this.formEvento.bind(this);
    this.handleText = this.handleText.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectGroupChange = this.handleSelectGroupChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.buscarDependencias = this.buscarDependencias.bind(this);
    this.buscarEventos = this.buscarEventos.bind(this);
    this.setStateTags = this.setStateTags.bind(this);
    this.clearStates = this.clearStates.bind(this);
  }

  componentWillMount() {
    this.buscarDependencias();
  }

  buscarEventos() {
    requestAll([
      {
        url: this.endpoints.EVENTO,
        modelo: 'events',
        modificador: respostaEventos
      }
    ], this);
  }

  clearStates(modalStatus) {
    this.setState(
      {
        modal: modalStatus,
        nome: '',
        codigo: '',
        classe: '',
        grupoEvento: [],
        instantaneo: '',
        producao: false,
        erroNome: '',
        erroCodigo: '',
        erroClasse: '',
        tags: [],
        userGroup: [],
        permissaoQrCode: false
      });
  }

  buscarDependencias() {
    requestAll([
      {
        url: this.endpoints.EVENTO,
        modelo: 'events',
        modificador: respostaEventos
      },
      {
        url: `${this.endpoints.CLASSES}/eventos`,
        modelo: 'type_events',
        modificador: respostaClassesEventos
      },
      {
        url: this.endpoints.GRUPO_EVENTO,
        modelo: 'group_event',
        modificador: respostaGrupoEventos,
        acao: true
      }
    ], this);

    UserGroup.all().then((data) => {
      this.setState({
        user_Group: data ? data.map(d => ({ uuid: d.uuid, nome: d.nome })) : []
      });
    });
  }

  setStateTags(value) {
    this.setState({ tags: value });
  }

  delete(id) {
    const msgError = this.props.t("errors.cannotDelete");

    request(this.endpoints.EVENTO + '/' + id, {
      method: 'delete'
    })
      .then(response => {
        switch (response.status) {
          case 200:
          case 201:
            this.clearStates(true);
            this.buscarEventos();
            alertBox(this.props.t("messages.deleteSuccess"), this.props.t("title"), 'success');

            break;
          case 400:
            return response.json();

          default:
            break;
        }
      })
      .then(errorObject => {
        if (errorObject) {
          let msg = errorObject.data.msg || msgError;
          alertBox(msg, errorObject.data.title, 'warning');
        }
      })
      .catch(() => {
        alertBox(msgError, this.props.t("title"), 'error');
      }).finally(() => {
        this.handleModal();
      });

  }

  handleModal(f, t) {
    this.setState({
      modal: !this.state.modal,
      typeModal: t,
      field: f
    });

    if (f !== undefined) {
      this.setState({
        nome: f.descr,
        codigo: f.cod,
        classe: f.fk_id_table,
        producao: f.is_batch,
        instantaneo: f.instant,
        permissaoQrCode: f.pemissionQrCode,
        tags: f.tags ? f.tags : [],
        userGroup: f.grupo_permissao_evento ? f.grupo_permissao_evento.map(i => {
          return { uuid: i.uuid, nome: i.nome };
        }) : [],
        id: f.id,
        field: f
      });
    }

    if (t == 'up') {
      this.setState({
        grupoEvento: f.group_events.map(g => {
          return { value: g.id, label: g.name };
        })
      });
    }
  }

  onSubmit() {
    var {
      nome,
      codigo,
      classe,
      grupoEvento,
      instantaneo,
      producao,
      typeModal,
      permissaoQrCode,
      tags,
      userGroup
    } = this.state;

    if (!nome) {
      this.setState({
        erroNome: this.props.t("messages.requiredField", { ns: 'common' })
      });
      return;
    } else {
      this.setState({
        erroNome: ''
      });
    }

    if (!codigo) {
      this.setState({
        erroCodigo: this.props.t("messages.requiredField", { ns: 'common' })
      });
      return;
    } else {
      this.setState({
        erroCodigo: ''
      });
    }

    if (!classe) {
      this.setState({
        erroClasse: this.props.t("messages.requiredField", { ns: 'common' })
      });
      return;
    } else {
      this.setState({
        erroCodigo: ''
      });
    }

    var data = {
      cod: codigo?.trim(),
      descr: nome?.trim(),
      fk_id_table: classe,
      group_events: grupoEvento.map(g => g.value),
      is_batch: producao,
      instant: instantaneo,
      pemissionQrCode: permissaoQrCode,
      tags,
      userGroup,
    };

    console.log(data);

    if (typeModal == 'up') {
      request(this.endpoints.EVENTO + '/' + this.state.id, {
        method: 'PUT',
        body: JSON.stringify(requisicaoEventos(data))
      })
        .then(response => {
          if (response.status == 200) {
            alertBox(this.props.t("messages.editSuccess"), this.props.t("title"), 'success');
            return response;
          } else {
            alertBox(this.props.t("errors.cannotEdit"), this.props.t("title"), 'error');
          }
        })
        .then(() => {
          this.buscarEventos();
          this.clearStates(false);
        })
        .catch(() => {
          alertBox(this.props.t("errors.cannotEdit"), this.props.t("title"), 'error');
        });
    } else {
      request(this.endpoints.EVENTO, {
        method: 'post',
        body: JSON.stringify(requisicaoEventos(data))
      })
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            alertBox(this.props.t("messages.createSuccess"), this.props.t("title"), 'success');
            return response;
          } else if (response.status == 409) {
            alertBox(this.props.t("errors.alreadyExists"), this.props.t("title"), 'error');
          }
          else {
            alertBox(this.props.t("errors.cannotCreate"), this.props.t("title"), 'error');
          }
        })
        .then(() => {
          this.buscarEventos();
          this.clearStates(false);
        })
        .catch(() => {
          alertBox(this.props.t("errors.cannotCreate"), this.props.t("title"), 'error');
        });
    }
  }

  handleSelectChange(value) {
    this.setState({ grupoEvento: value });
  }

  handleSelectGroupChange(value) {
    this.setState({ userGroup: value });
  }

  handleText(text, event) {
    this.setState({
      [text]: event.target.value
    });
  }

  handleNumeric(event) {
    document.getElementById('cod').value = Util.maskNumeric(event);
  }

  formEvento() {
    var {
      nome,
      codigo,
      classe,
      grupoEvento,
      instantaneo,
      producao,
      erroNome,
      erroCodigo,
      erroClasse,
      permissaoQrCode,
      userGroup,
      user_Group } = this.state;

    var grupo_eventos = this.store.group_event.map((f) => {
      return { value: f.id, label: f.name };
    });

    return <div style={{ width: '100%' }} className="modal">
      <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}> {this.state.typeModal == 'up' ? this.props.t("form.titleUpdate") : this.props.t("form.titleCreate")}</div>
      <div className={styles.form}>
        <TextField
          ref='nome'
          onBlur={this.handleText.bind(this, 'nome')}
          defaultValue={nome}
          errorText={erroNome}
          fullWidth={true}
          underlineFocusStyle={{ borderColor: '#000000' }}
          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
          floatingLabelText={this.props.t("form.labels.name")} />

        <TextField
          id='cod'
          errorText={erroCodigo}
          defaultValue={codigo}
          onBlur={this.handleText.bind(this, 'codigo')}
          fullWidth={true}
          maxLength={5}
          underlineFocusStyle={{ borderColor: '#000000' }}
          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
          floatingLabelText={this.props.t("form.labels.code")} />

        <SelectField
          ref='tipo'
          errorText={erroClasse}
          fullWidth={true}
          value={classe}
          underlineFocusStyle={{ borderColor: '#000000' }}
          floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
          floatingLabelText={this.props.t("form.labels.class")}
          onChange={(event, index, value) => { this.setState({ classe: value }); }}>
          {
            this.store.type_events.map(c => <MenuItem value={c.id_table} primaryText={c.descr} />)
          }
        </SelectField>

        <div style={{ paddingTop: '1.2em' }}>
          <Toggle
            ref='ativo' id='ativo'
            thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
            trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
            labelPosition="right"
            label={this.props.t("form.labels.flowEvent")}
            onToggle={(event, value) => this.setState({ producao: value })}
            defaultToggled={producao} />
        </div>
        <Toggle
          thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
          trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
          labelPosition="right"
          label={this.props.t("form.labels.instantaneousEvent")}
          onToggle={(event, value) => this.setState({ instantaneo: value })}
          defaultToggled={instantaneo}
        />

        <div style={{ paddingTop: '1em', fontSize: '.9em' }}>
          <h3 style={{ textTransform: 'uppercase' }}>{this.props.t("form.labels.eventGroup")}</h3>
          <MultiSelect
            isMulti
            value={grupoEvento}
            options={grupo_eventos}
            allowSelectAll={false}
            onGetOptionLabel={(option) => option.label}
            onGetOptionValue={(option) => option.value}
            onSelectOption={this.handleSelectChange}
          />
        </div>
        <div style={{ paddingTop: '1em' }}>
          <Toggle
            thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
            trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
            labelPosition="right"
            label={this.props.t("form.labels.qrCodePermission")}
            onToggle={(event, value) => this.setState({ permissaoQrCode: value })}
            defaultToggled={permissaoQrCode}
          />
        </div>

        {permissaoQrCode &&
          <div style={{ paddingTop: '1em', fontSize: '.9em' }}>
            <h3 style={{ textTransform: 'uppercase' }}>{this.props.t("form.labels.permissionGroup")}</h3>
            <MultiSelect
              isMulti
              value={userGroup}
              options={user_Group}
              allowSelectAll={false}
              onGetOptionLabel={(option) => option.nome}
              onGetOptionValue={(option) => option.uuid}
              onSelectOption={this.handleSelectGroupChange}
            />
          </div>
        }

        <TextTag
          placeHolder={this.props.t("form.labels.tagsPlaceholder")}
          tags={this.state.tags}
          onInput={this.setStateTags}
          labelTags={this.props.t("form.labels.tags")}
        />
      </div>

      <div className={'flex flex-just-end'} >
        <RaisedButton onClick={this.onSubmit} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} labelColor='#FFF' label={this.props.t("labels.save", { ns: 'common' })}
          disabled={((!hasRole('ROLE_EVENTOS_CRIAR') && this.state.typeModal === 'add') || (!hasRole('ROLE_EVENTOS_ATUALIZAR') && this.state.typeModal == 'up'))} />

        <RaisedButton onClick={() => this.clearStates(false)} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '1em' }} label={this.props.t("labels.cancel", { ns: 'common' })} />
      </div>
    </div>;
  }

  contentModal() {
    let { typeModal } = this.state;

    switch (typeModal) {
      case 'del':
        return <DeleteItem {...this.state} delete={this.delete} handleModal={this.handleModal} />;

      default:
        return this.formEvento();
    }
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={{ width: 350 }} contentModal={this.contentModal}>
          <EventsList ref='list' store={this.store} handleModal={this.handleModal} showAlert={this.showAlert} />
        </Container>
      );
    }
  }
}

export default withTranslation("events")(EventsPage);