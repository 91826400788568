import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import { observer } from 'mobx-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import Container from '../../common/Container';
import DeleteItem from '../../common/DeleteItem';
import { requisicaoTurnos, respostaTurnos } from '../../common/mappers';
import { alertBox, NInputTime } from '../../common/NForm';
import { request, requestAll } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import styles from './shifts.css';
import ShiftsList from './ShiftsList';

const style = { width: 500 };

@observer
class ShiftsPage extends Container {
  constructor() {
    super();
    this.state = {
      name: '',
      start_time: '',
      end_time: '',
      dia_start: '',
      dia_end: '',
      modal: false,
      field: '',
      loadingPage: true,
      erroNAME: '',
      erroSTART_TIME: '',
      erroEND_TIME: '',
      erroDIA_START: '',
      erroDIA_END: ''
    };

    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.buscarTurnos = this.buscarTurnos.bind(this);
  }

  componentDidMount() {
    this.buscarTurnos();
  }

  buscarTurnos() {
    requestAll([
      {
        url: this.endpoints.TURNO,
        modelo: 'shifts',
        modificador: respostaTurnos
      }
    ], this);
  }

  handleSubmit() {
    let erro = false;
    let { start_time, end_time } = this.state;

    let startSplit = start_time.split(':');
    let endSplit = end_time.split(':');
    let start_time_custom = start_time;
    let end_time_custom = end_time;

    if (startSplit.length == 3) {
      startSplit[2] = '00';
      start_time_custom = startSplit.join(':');
    }

    if (endSplit.length == 3) {
      endSplit[2] = '00';
      end_time_custom = endSplit.join(':');
    }

    let data = {
      name: this.state.name.trim(),
      start_time: start_time_custom,
      end_time: end_time_custom,
      dia_start: this.state.dia_start,
      dia_end: this.state.dia_end
    };

    Object.keys(data).forEach(function (element) {
      if (!data[element]) {
        this.setState({
          ['erro' + element.toUpperCase()]: this.props.t("messages.requiredField", { ns: 'common' })
        });

        erro = true;
      } else {
        this.setState({
          ['erro' + element.toUpperCase()]: ''
        });
      }
    }, this);

    if (!erro) {
      if (this.state.typeModal == 'up') {
        request(this.endpoints.TURNO + '/' + this.state.field.id, {
          method: 'put',
          body: JSON.stringify(requisicaoTurnos(data))
        })
          .then(response => {
            if (response.status == 200 || response.status == 201) {
              this.clearState();
              this.handleModal();
              this.buscarTurnos();
              alertBox(this.props.t("messages.editSuccess"), this.props.t("title"), 'success');
            } else {
              alertBox(this.props.t("errors.cannotEdit"), this.props.t("title"), 'error');
            }
          })
          .catch(() => {
            alertBox(this.props.t("errors.cannotEdit"), this.props.t("title"), 'error');
          });
      } else {
        request(this.endpoints.TURNO, {
          method: 'post',
          body: JSON.stringify(requisicaoTurnos(data))
        })
          .then(response => {
            if (response.status == 200 || response.status == 201) {
              this.clearState();
              this.handleModal();
              this.buscarTurnos();
              alertBox(this.props.t("messages.createSuccess"), this.props.t("title"), 'success');
            } else {
              alertBox(this.props.t("errors.cannotCreate"), this.props.t("title"), 'error');
            }
          })
          .catch(() => {
            alertBox(this.props.t("errors.cannotCreate"), this.props.t("title"), 'error');
          });
      }
    }
  }

  clearState() {
    this.setState({
      name: '',
      start_time: '',
      end_time: '',
      dia_start: '',
      dia_end: ''
    });
  }

  delete(id) {
    request(this.endpoints.TURNO + '/' + id, {
      method: 'delete'
    })
      .then(response => {
        if (response.status == 200 || response.status == 201) {
          this.clearState();
          this.handleModal();
          this.buscarTurnos();
          alertBox(this.props.t("messages.deleteSuccess"), this.props.t("title"), 'success');
        } else {
          alertBox(this.props.t("errors.cannotDelete"), this.props.t("title"), 'error');
        }
      })
      .catch(() => {
        alertBox(this.props.t("errors.cannotDelete"), this.props.t("title"), 'error');
      });
  }

  handleModal(field, type) {
    this.setState({
      modal: !this.state.modal,
      field: field,
      typeModal: type
    });

    if (type == 'up') {
      this.setState({
        name: field.name,
        start_time: field.start_time,
        end_time: field.end_time,
        dia_start: field.dia_start,
        dia_end: field.dia_end
      });
    } else {
      this.clearState();
    }
  }

  handleSelect(select, event, index, value) {
    this.setState({
      [select]: value
    });
  }

  handleText(text, event) {
    this.setState({
      [text]: event.target.value
    });
  }

  handleTime(text, event) {
    this.setState({
      [text]: event.target.value
    });
  }

  contentModal() {

    let dias = [
      {
        text: null,
        value: null
      },
      {
        text: this.props.t("date.weekdays.monday", { ns: 'common' }),
        value: 'SEG'
      },
      {
        text: this.props.t("date.weekdays.tuesday", { ns: 'common' }),
        value: 'TER'
      },
      {
        text: this.props.t("date.weekdays.wednesday", { ns: 'common' }),
        value: 'QUA'
      },
      {
        text: this.props.t("date.weekdays.thursday", { ns: 'common' }),
        value: 'QUI'
      },
      {
        text: this.props.t("date.weekdays.friday", { ns: 'common' }),
        value: 'SEX'
      },
      {
        text: this.props.t("date.weekdays.saturday", { ns: 'common' }),
        value: 'SAB'
      },
      {
        text: this.props.t("date.weekdays.sunday", { ns: 'common' }),
        value: 'DOM'
      }
    ];

    let fieldsOpts = dias.map((f) => {
      return { value: f.value, text: f.text };
    });

    if (this.state.typeModal == 'del') {
      return <DeleteItem {...this.state} delete={this.delete} handleModal={this.handleModal} />;
    }

    return (
      <div style={{ width: '100%' }} className="modal">
        <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>
          {this.state.typeModal == 'up' ? this.props.t("form.titleUpdate") : this.props.t("form.titleCreate")}
        </div>
        <div className={styles.form}>
          <TextField
            ref='name'
            underlineFocusStyle={{ borderColor: '#000000' }}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("form.labels.name")}
            defaultValue={this.state.name}
            errorText={this.state.erroNAME}
            onBlur={this.handleText.bind(this, 'name')} />

          <NInputTime
            id='start_time'
            floatingLabelText={this.props.t("form.labels.startTime")}
            type={'time'}
            value={this.state.start_time}
            errorText={this.state.erroSTART_TIME}
            onChange={this.handleTime.bind(this, 'start_time')} />

          <NInputTime
            id='end_time'
            floatingLabelText={this.props.t("form.labels.endTime")}
            type={'time'}
            value={this.state.end_time}
            errorText={this.state.erroEND_TIME}
            onChange={this.handleTime.bind(this, 'end_time')} />

          <SelectField
            ref='dia_start'
            value={this.state.dia_start}
            underlineFocusStyle={{ borderColor: '#000000' }}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("form.labels.startDay")}
            errorText={this.state.erroDIA_START}
            onChange={this.handleSelect.bind(this, 'dia_start')}>
            {
              fieldsOpts.map(e => {
                return <MenuItem value={e.value} primaryText={e.text} />;
              })
            }
          </SelectField>

          <SelectField
            ref='dia_end'
            value={this.state.dia_end}
            underlineFocusStyle={{ borderColor: '#000000' }}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("form.labels.endDay")}
            errorText={this.state.erroDIA_END}
            onChange={this.handleSelect.bind(this, 'dia_end')}>
            {
              fieldsOpts.map(e => {
                return <MenuItem value={e.value} primaryText={e.text} />;
              })
            }
          </SelectField>
        </div>
        <div className={'flex flex-just-end'} style={{ paddingTop: '1em' }} >
          <RaisedButton
            onClick={this.handleSubmit.bind(this)}
            backgroundColor='#2D4F7F'
            buttonStyle={{ fontFamily: '' }}
            style={{ marginRight: '10px' }}
            labelColor='#FFF'
            label={this.props.t("labels.save", { ns: 'common' })}
            disabled={((!hasRole('ROLE_TURNOS_CRIAR') && this.state.typeModal === 'add') || (!hasRole('ROLE_TURNOS_ATUALIZAR') && this.state.typeModal == 'up'))}
          />
          <RaisedButton
            onClick={this.handleModal}
            backgroundColor='#FFF'
            buttonStyle={{ fontFamily: '' }}
            label={this.props.t("labels.cancel", { ns: 'common' })}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={{ width: 350 }} contentModal={this.contentModal}>
          <ShiftsList ref='list' store={this.store} handleModal={this.handleModal} showAlert={this.showAlert} />
        </Container>
      );
    }
  }
}

export default withTranslation("shifts")(ShiftsPage)