import React, { Component } from 'react';
import style from './PainelLoader.scss';
import MDSpinner from "react-md-spinner";
import { withTranslation } from 'react-i18next';

class PainelLoader extends Component {
    fail() {
        return (
            <div className={style['painel-loader__failure__wrapper']}>
                <i className={`material-icons ${style['painel-loader__failure__text']}`}>warning</i>
                <div>
                    <h3 className={style['painel-loader__failure__text']}>{this.props.t("painelLoader.empty")}</h3>
                </div>
            </div>
        );
    }

    loading() {
        return (
            <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                <MDSpinner singleColor='#2D4F7F' size={60} thickness={7} />
            </div>
        );
    }

    render() {
        const { fail, loading, header } = this.props;
        return (
            <div className={style['painel-loader__wrapper']}>
                {
                    loading ?
                        (
                            <div className={style['painel-loader__loader']}>
                                <div className="card-header">
                                    {header}
                                </div>
                                <div className={style['painel-loader__circular']}>
                                    {
                                        fail ? this.fail() : this.loading()
                                    }
                                </div>
                            </div>
                        )
                        : null
                }
                {this.props.children}
            </div>
        )
    }
}

export default withTranslation("components")(PainelLoader);