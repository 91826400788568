class TimeConversion {

  THours(value) {
    if (value == undefined || value == null || value == 0) return 0;
    let v = (value / 60).toString().split('.').map((a, i) => i < 1 ? parseInt(a) : (('0.'+a)*60).toFixed(0));
    if(v[0] <= 0) {
      v = v[1]+'m'
    } else {
      v = v[0]+'h'+(v[1]?v[1]+'m':'');
    }
    return v;
  }

  TMinutes(value, splitter) {
    value = value.split(splitter);
    return parseInt(value[0]*60) + parseInt(value[1]);
  }

  THoursMinutes(value) {
    let v = (value / 60).toString().split('.').map((a, i) => i < 1 ? parseInt(a) : (('0.'+a)*60).toFixed(0));
    return (v[0]>99?v[0]:v[0]>9?'0'+v[0]:v[0]>0?'00'+v[0]:'000')+':'+(v[1]?v[1]>9?v[1]:'0'+v[1]:'00');
  }

};

export default new TimeConversion();
