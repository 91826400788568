import React, { Component } from 'react';
import { NButton } from '../../../../NForm';
import { observer, inject } from 'mobx-react';


@inject('store')
@observer
class DeleteLastVigencia extends Component{
    constructor(props){
        super(props);
        
        this.alertVigenciaStore = this.props.storeLastAlertaVigencia;
        this.modalStore = this.props.storeModalCommon;
        this.containerStore = this.props.store.containerStore;

        this.itemIndex = this.modalStore.Values.slice()[0].itemIndex;
        this.commonState = this.modalStore.Values.slice()[0].commonState.slice();

    }

    render(){
        return(
            <div>
                <NButton type="primary" onClick={() => {
                    this.alertVigenciaStore.Yes(this.commonState[this.itemIndex], this.props.urlRequest, this.props.callBackFunction)
                    this.modalStore.close()
                    this.containerStore.setAtualizar(true)
                    }}>
                    Sim
                </NButton>
                <NButton onClick={() => this.alertVigenciaStore.CustomNoFunction()}>
                    Não
                </NButton>
            </div>
        )
    }
}

export default DeleteLastVigencia