import React, { Component } from 'react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FilterBox from '../../common/FilterBox';
import update from 'immutability-helper';
import { dictValuesToArray } from '../../common/helpers';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

function quantidade(dado, total) {
  if (dado && dado.length > 0) {
    if (dado[0].value !== -1) {
      return dado.length;
    } else {
      return total;
    }
  }

  return 0;
}

class CustomFieldsList extends Component {

  constructor(props) {
    super(props);
    this.endpoints = props.endpoints;
    this.handleModal = props.handleModal;
    this.carregarCamposPersonalizados = props.carregarCamposPersonalizados;
    this.excluir = props.excluir;

    this.state = {
      campos_personalizados: [],
      filtro: '',
      equipamentos: [],
      eventos: [],
    }

    this.filter = this.filter.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.formatarRespostaModelo = this.formatarRespostaModelo.bind(this);
    this.formatarRespostaEquipamentoEvento = this.formatarRespostaEquipamentoEvento.bind(this);    
    this.opcaoTodos = this.opcaoTodos.bind(this);

  }

  FORMATO_TIPO = {
    'numerico': this.props.t("format_type.numeric"),
    'alfanumerico': this.props.t("format_type.alphanumeric"),
    'selecionar': this.props.t("format_type.list"),
  };

  opcaoTodos() {
    return {label: this.props.t("values.all"), value: -1};
  }

  onFilter(valor) {
    this.setState(update(this.state, {filtro: {$set: valor}}));
  }

  filter(campos_personalizados, valor) {
    return campos_personalizados.filter(campo => {
      
      let equipamentos = [];
      let eventos = [];

      if (!campo) return false;

      campo.por_equipamentos_eventos.filter(por_equipamento_evento => {

        let equipamento = por_equipamento_evento.equipamento;
        let evento = por_equipamento_evento.evento;

        if (equipamento && equipamento.nome && equipamento.nome.toLowerCase().includes(valor)) {
          equipamentos.push(equipamento);
        }
        
        if (evento && evento.nome && evento.nome.toLowerCase().includes(valor)) {
          eventos.push(evento);
        }
      })

      if (equipamentos.length > 0 || eventos.length > 0 ||
         (campo && campo.nome && campo.nome.toLowerCase().includes(valor))
        ) return true;
    })
  }

  onExcluirClicked(campo, i, event) {
    this.excluir(campo);
  }

  onEditarClicked(campo, i, event) {
    this.handleModal({...campo}, 'edit');
  }

  formatarRespostaModelo(campos) {
    return campos.map(campo => {
      var [equipamentos, eventos] =
        this.formatarRespostaEquipamentoEvento(campo.por_equipamentos_eventos);

      return {
        ...campo,
        equipamentos: equipamentos,
        eventos: eventos,
        opcoes: campo.opcoes.map(opcao => opcao.nome),
      };
    });
  }

  formatarRespostaEquipamentoEvento(equipamento_evento) {
    let equipamentos = {};
    let eventos = {};

    for(let i in equipamento_evento) {
      let equip_ev = equipamento_evento[i];
      let equip = equip_ev.equipamento;
      let event = equip_ev.evento;
      
      if (equip.id != 0) {
        equipamentos[equip.id] = {label: equip.nome, value: equip.id};
      } else {
        equipamentos["-1"] = this.opcaoTodos();
      }

      if (event) {
        eventos[event.id] = {label: event.nome, value: event.id};
      } else {
        eventos["-1"] = this.opcaoTodos();
      }
    }

    return [dictValuesToArray(equipamentos), dictValuesToArray(eventos)];
  }

  render() {
    const { filtro } = this.state;
    const { campos_personalizados, equipamentos, eventos } = this.props;

    const itens = this.formatarRespostaModelo(this.filter(campos_personalizados, filtro)).map((campo, i) => (
      <tr key={`campo#${campo.id}`}>
        <td>{i+1}</td>
        <td>{campo.nome}</td>
        <td>{quantidade(campo.equipamentos, equipamentos.length)}</td>
        <td>{quantidade(campo.eventos, eventos.length)}</td>
        <td>{this.FORMATO_TIPO[campo.tipo]}</td>
        <td>{campo.obrigatorio ? this.props.t("labels.yes", {ns: "common"}) : this.props.t("labels.no", {ns: "common"})}</td>
        <td>
          {
            !campo.sistema ?
              <div className='options'>
                <i className='material-icons' title={this.props.t("list.actions.edit", {ns: "common"})} onClick={this.onEditarClicked.bind(this, campo, i)}>edit</i>
                <i className='material-icons' title={this.props.t("list.actions.delete", {ns: "common"})} onClick={hasRole('ROLE_CAMPOS_PERSONALIZADOS_EXCLUIR') && this.onExcluirClicked.bind(this, campo, i)}>cancel</i>
              </div>
            :
              null
          }
        </td>
      </tr>
    ));

    return (
      <div className='box_list'>
        <Card className='box_list_card'>
              <FloatingActionButton disabled={!hasRole('ROLE_CAMPOS_PERSONALIZADOS_CRIAR')} className='box_add' onClick={() => this.props.handleModal(null, 'add')}>
                <ContentAdd />
              </FloatingActionButton>
          <CardHeader className='box_header' title={this.props.t("title")} />
          <CardText className='table_overflow'>
            {
              campos_personalizados.length <= 0 ? (
                <div style={{marginTop: 30}}>
                <h1>{this.props.t("list.empty", {ns: "common"})}</h1>
              </div>
              ) : (
                <span>
                  <FilterBox onFilter={this.onFilter}/>
                  <table className='mui-table mui-table--bordered' >
                    <thead>
                      <tr>
                        <td className="id">#</td>
                        <td className="nome">{this.props.t("list.columns.nameField")}</td>
                        <td className="quantidade_equipamentos">{this.props.t("list.columns.amountEquipments")}</td>
                        <td className="quantidade_eventos">{this.props.t("list.columns.amountEvents")}</td>
                        <td className="tipo">{this.props.t("list.columns.type")}</td>
                        <td className="obrigatorio">{this.props.t("list.columns.required")}</td>
                        <td className="acoes">{this.props.t("list.columns.actions")}</td>
                      </tr>
                    </thead>
                    <tbody className='box_items'>
                      {itens}
                    </tbody>
                  </table>
                </span>
              )
            }
          </CardText>
        </Card>
      </div>
    )
  }
}

export default withTranslation('customFields')(CustomFieldsList);