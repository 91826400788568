import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { APIEndpoints } from '../common/Constants';
import Actions from '../common/Actions';
import Store from '../common/Store';
import LoginForm from './LoginForm';
import styles from './login.scss';
import 'whatwg-fetch';
import { alertBox } from '../common/NForm';
import { withRouter } from 'react-router-dom';
import { requestNotSession, request } from '../common/request';
import Cookies from 'universal-cookie';
import i18n from 'utils/i18n';
import moment from 'moment';
import Utils from 'utils/Utils';

const tokenApp = 'f0cde4b4d0c34231917bc4042f2fd9d8';
const cookie = new Cookies();

@observer
class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.action = new Actions();
    this.store = Store;
    this.onSubmit = this.onSubmit.bind(this);
    this.setLoading = this.setLoading.bind(this);

    this.state = {
      error: '',
      showError: false,
      loading: false,
      isCogtiveLogin: props.history.location.pathname === '/cogtive',
      activeDirectory: {
        url: undefined
      },
      versao: '',
      language: 'pt-BR'
    };
  }

  setLoading(value) {
    this.setState({
      loading: value
    });
  }

  pushHistory() {
    let backTo = cookie.get('location');

    if (backTo == undefined) {
      this.props.history.push('/home')
    } else {
      cookie.remove('location');
      location.href = decodeURIComponent(backTo);
    }
  }

  setlocalStorage(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('login', data.usuario.username);
    localStorage.setItem('email', data.usuario.email);
    localStorage.setItem('nome', data.usuario.nome);
    localStorage.setItem('id', data.usuario.id);
    localStorage.setItem('telefone', data.usuario.telefone);
    localStorage.setItem('profile_picture_url', data.usuario.profile_picture_url);
    localStorage.setItem('primeiro_login', data.usuario.primeiro_login);
    localStorage.setItem('tenant', data.usuario.tenant);
    localStorage.setItem('tokenApp', tokenApp);

    let roles = JSON.stringify(data.roles);
    localStorage.setItem('roles', roles);
  }

  getActiveDirectory() {
    let urlParams = Utils.getURLParams(window.location.href);

    var validarUrl = APIEndpoints.ACTIVEDIRECTORY_VALIDAR;

    if (urlParams.redirectUrl) {
      validarUrl += `?redirectUrl=${urlParams.redirectUrl}`;
    }

    if (this.state.isCogtiveLogin) {
      validarUrl += '?cogtive=true';
    }

    requestNotSession(validarUrl, {
      method: 'get'
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(data => {
        var url = null;

        if (data != null) {
          url = data.url;
          localStorage.setItem('AD', true);
        } else {
          localStorage.setItem('AD', false);
        }

        this.setState({
          activeDirectory: {
            url: url
          }
        });
      });
  }

  getUser(token) {
    requestNotSession(APIEndpoints.SGCA_AUTENTICAR + '/info', {
      method: 'get'
    }, token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        alertBox(i18n.t('messages.userWithoutPermission', { ns: 'common' }), i18n.t('labels.warning', { ns: 'common' }), 'error');
      })
      .then(data => {
        if (data != null) {
          if (!data.roles || data.roles.length === 0) {
            alertBox(i18n.t('messages.featuresPermission', { ns: 'common' }), i18n.t('labels.permission', { ns: 'common' }), 'error');
            return;
          }
          this.setlocalStorage(data);
          this.inicializarParametros().then(() => this.pushHistory());
        }
      });
  }

  componentWillMount() {
    const params = Utils.getURLParams(window.location.href);

    let storedToken = localStorage['token']

    if (storedToken) {
      if (params["redirectUrl"]) {
        window.location.href = params["redirectUrl"] + `?Token=${btoa(storedToken)}`
      } else {
        this.pushHistory();
      }
    } else {

      if (!params["Token"]) {
        this.getActiveDirectory();
      } else {
        var decoded = new Buffer(params["Token"], 'base64').toString('ascii');
        if (decoded !== 'false') {
          this.getUser(decoded);
        } else {
          alertBox(i18n.t('messages.userWithoutPermission', { ns: 'common' }), i18n.t('labels.warning', { ns: 'common' }), 'error');
        }
      }
    }
  }

  componentDidMount() {
    requestNotSession(APIEndpoints.VERSAO, {
      method: 'get'
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          return { versao: 'unversioned' }
        }
      })
      .then(data => {
        this.setState({
          versao: `b.${data.versao}`
        });
      }).then(() => this.obterVersao());

    var language = navigator.language || navigator.userLanguage;

    if (language) {
      if (language !== 'pt-BR') {
        language = 'en-US'
      }

      i18n.changeLanguage(language);

      this.setState({ language });
    }
  }

  obterVersao() {
    fetch('../current-version.txt')
      .then(response => {
        if (response.status === 200) {
          return response.text()
        } else {
          return 'unversioned'
        }
      })
      .then(text => {
        let versao = `${this.state.versao} | f.${text}`;
        this.setState({ versao: versao });
      });
  }

  inicializarParametros() {
    return request(APIEndpoints.CONFIGURACAO_PARAMETROS, {
      method: 'get'
    }).then(response => {
      if (response.status === 200) {
        return response.json()
      }
    }).then(data => {
      if (data != null) {
        data.map(d => {
          localStorage.setItem(d.chave, d.valor);

          if (d.chave === 'Language') {
            i18n.changeLanguage(d.valor);
            moment.locale(d.valor);
          }
        });
      }
    });
  }

  onSubmit() {
    this.setLoading(true);

    let data = {};
    let fields = this.refs.login.refs.form.refs;
    Object.keys(fields).map((i) => {
      let key = Object.keys(fields[i].refs)[0];
      data[key] = (fields[i].refs[key].value);
    });

    data = {
      username: data.login,
      senha: data.password
    };

    fetch(APIEndpoints.SGCA_AUTENTICAR, {
      method: 'post',
      headers: {
        'Authorization': 'Bearer ' + tokenApp,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status === 200 || response.status === 400) {
        return response.json();
      }

      return Promise.reject(response); 
    }).then(data => {
      this.setLoading(false);
      if (data?.status === "ERROR") {
        alertBox(i18n.t(`messages.${data.code}`, { ns: 'common' }), i18n.t('labels.warning', { ns: 'common' }), 'error');
      } else {
        if (data && data.status !== "ERROR") {
          if (!data.roles || data.roles.length === 0) {
            alertBox(i18n.t('messages.featuresPermission', { ns: 'common' }), i18n.t('labels.permission', { ns: 'common' }), 'error');
            return;
          }
          this.setlocalStorage(data);

          const params = Utils.getURLParams(window.location.href);

          if (params['redirectUrl']) {
            return window.location.href = params["redirectUrl"] + `?Token=${btoa(data.token)}`
          }

          this.inicializarParametros().then(() => this.pushHistory());
        }
      }
    })
    .catch(error => {
        alertBox(i18n.t('messages.cannotAccessNow', { ns: 'common' }), i18n.t('labels.warning', { ns: 'common' }), 'error');
    });
  }

  render() {
    if (this.store.movideskChat) {
      this.store.movideskChat.style.visibility = "hidden";
    }

    let styleContainer = styles.loginContainer + ' ' + styles[this.state.language]

    return (
      <div className={styleContainer}>
        <LoginForm
          {...this.state}
          ref='login'
          loading={this.state.loading}
          onSubmit={this.onSubmit}
          styles={styles}
          store={this.store}
          activeDirectory={this.state.activeDirectory} />
        <span
          style={{ position: 'absolute', bottom: 15, left: 15, color: '#4e4e4e', fontSize: '0.8em' }}
        >{this.state.versao}</span>
      </div>
    );
  }
}

export default withRouter(({ history }) => (
  <LoginContainer history={history} activeDirectory={'null'} />
))
