import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './dateRangePicker.scss'
import { NDatePickerInput } from 'common/NForm';

const DateRangePicker = ({
    onSelectRange
}) => {
    const { t } = useTranslation('common');

    const [selectedPeriod, setSelectedPeriod] = useState('today');
    const [selectedRange, setSelectedRange] = useState({
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate()
    });

    const AVAILABLE_OPTIONS = [
        'today',
        'yesterday',
        'currentWeek',
        'currentMonth',
        'custom'
    ]

    useEffect(() => {
        if (onSelectRange) {
            onSelectRange(selectedRange);
        }
    }, [selectedRange])

    const handlePeriodChanged = (e) => {
        let value = e.currentTarget.value;

        let range = {
            start: moment().startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        }

        switch (value) {
            case 'today':
                range.start = moment().startOf('day').toDate();
                break;
            case 'yesterday':
                range.start = moment().subtract(1, 'days').startOf('day').toDate();
                range.end = moment().subtract(1, 'days').endOf('day').toDate()
                break;
            case 'currentWeek':
                range.start = moment().startOf('week').toDate();
                break;
            case 'currentMonth':
                range.start = moment().startOf('month').toDate();
                break;
        }

        setSelectedPeriod(value);

        setSelectedRange(range);

        return range;
    }

    const handleStartDate = (date) => {
        let range = { ...selectedRange }

        range.start = moment(date).startOf('day').toDate();

        setSelectedRange(range);
    }

    const handleEndDate = (date) => {
        let range = {
            start: selectedRange.start,
            end: moment(date).endOf('day').toDate()
        }

        setSelectedRange(range);
    }

    return (
        <div className="date-range-picker">
            <div className="control-group">
                {
                    AVAILABLE_OPTIONS.map(option => (
                        <div className="control-option">
                            <label className="control control-radio" htmlFor={`date-range-picker-${option}`}>
                                <input checked={selectedPeriod === option} value={option} type="radio" id={`date-range-picker-${option}`} name="periodo" onChange={handlePeriodChanged} />
                                <div className="control_indicator"></div>
                            </label>
                            <label htmlFor={`date-range-picker-${option}`}>{t(`date.calendar.${option}`)}</label>
                        </div>
                    ))
                }
                {
                    selectedPeriod === 'custom' ?

                        <div className="control-custom-date">
                            <NDatePickerInput
                                className="custom-date-input"
                                id="start_date"
                                label={t("labels.startDate")}
                                value={moment(selectedRange.start)}
                                maxDate={moment()}
                                onChange={handleStartDate}
                                onChangeRaw={(_, date) => { handleStartDate(date) }}
                                autoComplete="off" />

                            <NDatePickerInput
                                className="custom-date-input"
                                id="end_date"
                                value={moment(selectedRange.end)}
                                minDate={selectedRange.start ? selectedRange.start : null}
                                maxDate={selectedRange.start && moment().diff(moment(selectedRange.start), 'days') > 60 ? moment(selectedRange.start).add(60, 'days') : moment()}
                                label={t("labels.endDate")}
                                onChange={date => handleEndDate(date)}
                                onChangeRaw={(_, date) => handleEndDate(date)}
                                autoComplete="off" />

                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default DateRangePicker;
