import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NButton } from '../../../../NForm';
import { hasRole } from '../../../../../helper/SecurityHelper';
import defaultStyle from '../../../../button.scss';

@inject('store')
@observer
class DeleteVigencia extends Component {
    constructor(props){
        super(props);
        
        this.alertVigenciaStore = this.props.storeAlertaVigencia;
        this.storeModalCommon = this.props.storeModalCommon;
        this.modalItensStore = this.props.store.modalItens;

        
        // Item que será excluido
        this.indexExclusao = this.storeModalCommon.Values.slice()[0].itemIndex;
        this.vigenciaExclusao = this.storeModalCommon.Values.slice()[0].commonState[this.indexExclusao];
        
        // Estado comum entre meus componentes de timeMachineRotas
        this.commonState = this.storeModalCommon.Values.slice()[0].commonState.slice();
        
    }

    _disableSalvar(){
        return (!hasRole('ROLE_EQUIPAMENTOS_CRIAR') || !hasRole('ROLE_EQUIPAMENTOS_ATUALIZAR'))
    }
    
    render(){
        //URL passada do componente pai o qual chama o delete vigencia concatenado com o Id da vigencia a ser excluida
        let urlExclusao = this.props.urlRequest + '/' + this.vigenciaExclusao.id;
        
        return(
            <div>
                <NButton disabled={this._disableSalvar()} className={this._disableSalvar() ? defaultStyle.button_ghost_disable : ''} type="primary" onClick={() => {
                    let newState = this.alertVigenciaStore.Yes(this.indexExclusao, this.commonState, urlExclusao, this.props.storeTimeMachine, this.props.setStateObjModalAoInserirTimeMachine)

                    this.modalItensStore.setCustomArray(newState);
                    this.props.storeTimeMachine.setArrayItensCommon(newState);
                    this.modalItensStore.setTriggredAdd(true);
                    this.alertVigenciaStore.CustomNoFunction();

                }}>
                    Sim
                </NButton>
                <NButton onClick={() => this.alertVigenciaStore.No()}>
                    Não
                </NButton>
            </div>
        )
    }
}


export default DeleteVigencia