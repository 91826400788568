import React, { Component } from 'react';
import { Tabs, Tab, Toggle, MenuItem, RaisedButton } from 'material-ui';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { FieldArray, Formik } from 'formik';
import { TextField, SelectField, AutoComplete, CurrencyInput } from '../../common/MaterialForm';
import { hasRole } from '../../helper/SecurityHelper';
import { StockCurve } from '../../clients';
import { Product } from '../../clients/Products/Product';
import { requisicaoProdutos, respostaProdutosLazy } from '../../common/mappers';
import { alertBox, NButton, NButtonGroup, NForm, NFormTitle } from '../../common/NForm';
import styles from './product.scss';
import Modal from 'common/CommonModal';
import Yup from 'utils/Yup';
import { withTranslation } from 'react-i18next';
import { default as MultiSelect } from 'components/AutoComplete';

const validationSchema = Yup.object().shape({
    id: Yup.number(),
    nome: Yup.string().required().trim(),
    sku: Yup.string().required().trim().max(30),
    area_industrial: Yup.object().shape({
        id: Yup.number().required()
    }),
    estado: Yup.bool(),
    politica_estoque: Yup.object().shape({
        tamanho_lote: Yup.number().nullable(true),
        demanda_mensal: Yup.number().nullable(true),
        politica_cobertura: Yup.number().nullable(true),
        valor_custo: Yup.number().nullable(true),
        valor_venda: Yup.number().nullable(true)
    })
});

class ProductModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stockCurves: [],
            loading: true,
            product: new Product(),
            typeModal: undefined,
            listPredecessor: []
        };

        this.onSuccess = this.props.onSuccess;
        this.closeModal = this.props.closeModal;
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        let promises = [
            StockCurve.all().then((result) => {
                this.setState({ stockCurves: result });
            }),

            Product.all({}, true).then(data => {
                const produtos = respostaProdutosLazy(data);

                let lista = [];

                produtos.forEach((produto) => {
                    lista.push({
                        value: produto.id,
                        text: `${produto.sku} - ${produto.name}`
                    });
                });

                this.setState({ allProducts: lista });
            })
        ];

        if (this.props.product.id) {
            promises.push(
                Product.find(this.props.product.id)
                    .then(data => (this.setState({ product: data })))
            );
        }

        if (this.props.product.predecessores !== null
            && this.props.product.predecessores !== undefined
            && this.props.product.predecessores.length > 0) {
            var predecessors = this.props.product.predecessores.map((p) => {
                return { value: p.fk_id_pi, text: `${p.sku} - ${p.nome}` };
            });

            this.setState({ listPredecessor: predecessors });
        }

        Promise.all(promises)
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    handleSelect(event, value, setFieldValue) {
        const { name } = event.target;

        setFieldValue(name, value);
    }

    handleSelectChange(value) {
        this.setState({ listPredecessor: value });
    }

    handleAutoComplete(value, values, name, destroyFlag, setFieldValue) {
        const objValue = values.find(i => i.text == value);

        if (objValue) {
            setFieldValue(name, objValue.value);
            setFieldValue(destroyFlag, false);
        } else {
            setFieldValue(destroyFlag, true);
        }
    }

    validarMudancaArea(values) {
        const { product } = this.state;

        if (product.area_industrial.id != values.area_industrial.id && product.id) {
            this.setState(update(this.state, {
                typeModal: { $set: 'change_area' },
            }));
            return;
        }

        this.submit(values);
    }

    actionButtons = (handleSubmit) => (
        <div style={{ paddingTop: '1em' }} className={'flex flex-just-end'}>
            <RaisedButton
                onClick={handleSubmit}
                backgroundColor='#2D4F7F'
                buttonStyle={{ fontFamily: '' }}
                style={{ fontWeight: 100, marginRight: 10 }}
                labelColor='#FFF'
                label={this.props.t("labels.save", { ns: "common" })}
                disabled={((!hasRole('ROLE_PRODUTOS_CRIAR') && !this.props.product.id) || (!hasRole('ROLE_PRODUTOS_ATUALIZAR') && this.props.product.id))} />
            <RaisedButton
                onClick={this.props.closeModal}
                backgroundColor='#FFF'
                buttonStyle={{ fontFamily: '' }}
                style={{ fontWeight: 100 }}
                label={this.props.t("labels.cancel", { ns: "common" })} />
        </div>
    )

    submit(values) {
        const { product } = this.props;

        var predecessores = [];

        let method = undefined;

        if (product.id) {
            method = product.update;
        } else {
            method = this.state.product.create;
        }

        const mappedData = requisicaoProdutos(values);

        this.state.listPredecessor.map((p) => {
            var produto = { id: p.value, name: p.text };
            predecessores.push(produto);
        });

        mappedData.predecessores = predecessores;
        mappedData.possui_predecessor = predecessores.length > 0;

        method(mappedData)
            .then(response => {
                if (response.ok && response.status !== 204) {

                    const msgTipoAcao = product.id ? this.props.t('messages.editSuccess') : this.props.t('messages.insertSuccess');
                    alertBox(msgTipoAcao, this.props.t("title"), 'success');

                    this.onSuccess();
                    this.closeModal();
                } else if (response.status == 409) {
                    alertBox(this.props.t("errors.alreadyExistingProduct"), this.props.t("title"), 'error');
                } else if (response.status == 204) {
                    alertBox(this.props.t("errors.unableLink"), this.props.t("title"), 'error');
                } else {
                    let message = this.props.t("errors.couldNotCreate");

                    response.json().then(res => {
                        if (res.errors) {
                            message = res.message;
                        }

                        alertBox(message, this.props.t("title"), 'error');
                    });
                }
            })
            .catch(() => {
                alertBox(this.props.t("errors.couldNotCreate"), this.props.t("title"), 'error');
            });
    }

    apagarRotasProduto() {
        const { product } = this.props;

        product.destroyRoutes()
            .then(res => {
                if (res.status >= 200 && res.status <= 300) {
                    this.submit();
                }

                if (res.status == 400) {
                    alertBox(this.props.t('errors.registeredBatches'), this.props.t('title'), 'error');
                }
            })
            .catch(_err => {
                alertBox(this.props.t("errors.couldNotPerformAction"), this.props.t('title'), 'error');
                this.closeModal();
            });
    }

    renderChangeAreaConfirmation() {
        // TODO: Essas rotas deveriam ser apagadas no back-end.
        return (
            <NForm>
                <NFormTitle>{this.props.t("form.details.labels.deleteRoutes")}</NFormTitle>
                {this.props.t("form.details.labels.editArea")}
                <br /><br />
                {this.props.t("form.details.labels.executeAction")}
                <br /><br />

                <NButtonGroup>
                    <NButton type="primary" onClick={() => this.apagarRotasProduto()}>{this.props.t('labels.yes', { ns: 'common' })}</NButton>
                    <NButton onClick={() => this.setState({ typeModal: undefined })}>{this.props.t('labels.no', { ns: 'common' })}</NButton>
                </NButtonGroup>
            </NForm>
        );
    }

    filterPredecessores(except = []) {
        const { allProducts } = this.state;

        return allProducts.filter(p =>
            !except.includes(p.value)
        ).sort((a, b) => a.text - b.text).map((product) => {
            return { value: product.value, text: product.text };
        }).sort(this.compareProduct);
    }

    compareProduct(a, b) {
        if (a.text < b.text) {
            return -1;
        }
        if (a.text > b.text) {
            return 1;
        }
        return 0;
    }

    render() {
        const { stockCurves, loading, typeModal, listPredecessor } = this.state;
        const { fields } = this.props.store;

        if (!this.props.open || loading) return null;

        return (
            <Modal className={'box_modal'}>
                {typeModal === 'change_area' ?
                    this.renderChangeAreaConfirmation() :

                    <Formik
                        initialValues={this.state.product}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            this.validarMudancaArea(values);
                        }}
                    >
                        {props => (
                            <Tabs tabItemContainerStyle={{ background: '#F1F1F1' }} inkBarStyle={{ background: '#95999A' }} className="modal_tabs product-crud-form">
                                <Tab label={this.props.t("form.details.title")} key="1">
                                    <div className='modal'>
                                        <div></div>
                                        <Row>
                                            <Col lg={12} className={styles.form}>
                                                <Toggle
                                                    style={{ width: '' }}
                                                    name="estado"
                                                    onToggle={(_event, value) => props.setFieldValue('estado', value)}
                                                    id='estado'
                                                    thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                                                    trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                                                    labelPosition="right"
                                                    label={this.props.t("labels.active", { ns: "common" })}
                                                    defaultToggled={props.values.estado} />

                                                <TextField
                                                    name="nome"
                                                    style={{ width: '100%' }}
                                                    floatingLabelText={this.props.t("form.details.labels.name")}
                                                    value={props.values.nome}
                                                    onChange={props.handleChange}
                                                    errorText={props.errors.nome}
                                                    onBlur={props.handleBlur} />

                                                <TextField
                                                    name="sku"
                                                    floatingLabelText={this.props.t("form.details.labels.code")}
                                                    value={props.values.sku}
                                                    errorText={props.errors.sku}
                                                    onChange={props.handleChange}
                                                />

                                                <SelectField
                                                    id="area_industrial"
                                                    name="area_industrial"
                                                    floatingLabelText={this.props.t("form.details.labels.field")}
                                                    value={props.values.area_industrial.id}
                                                    errorText={props.errors.area_industrial?.id}
                                                    onChange={(_event, _index, value) => props.setFieldValue('area_industrial.id', value)}
                                                >
                                                    {
                                                        fields.map(e => {
                                                            return <MenuItem key={`area-${e.id}`} value={e.id} primaryText={e.name} />;
                                                        })
                                                    }
                                                </SelectField>
                                            </Col>
                                        </Row>
                                        {this.actionButtons(props.handleSubmit)}
                                    </div>
                                </Tab>

                                <Tab label={this.props.t("form.details.labels.predecessors")} key="2">
                                    <div className='modal' style={{ maxWidth: '580px' }}>
                                        <div></div>
                                        <Row>
                                            <Col lg={12} className={styles.form}>
                                                <label style={{ fontSize: '11.5px', textTransform: 'uppercase', marginBottom: '10px' }}>{this.props.t('titles.products', { ns: 'common' })}</label>
                                                <MultiSelect
                                                    isMulti
                                                    value={listPredecessor}
                                                    options={this.filterPredecessores([props.values.id])}
                                                    allowSelectAll={false}
                                                    onGetOptionLabel={(option) => option.text}
                                                    onGetOptionValue={(option) => option.value}
                                                    onSelectOption={this.handleSelectChange}
                                                />
                                            </Col>
                                        </Row>
                                        {this.actionButtons(props.handleSubmit)}
                                    </div>
                                </Tab>

                                <Tab label={this.props.t("form.stockPolice.title")} key="3">
                                    <div className='modal'>
                                        <div></div>
                                        <Row>
                                            <Col lg={6} className={styles.form}>
                                                <TextField
                                                    name={'politica_estoque.tamanho_lote'}
                                                    floatingLabelText={this.props.t("form.stockPolice.labels.batchSize")}
                                                    onChange={props.handleChange}
                                                    value={props.values.politica_estoque?.tamanho_lote}
                                                    onlyNumbers />

                                                <TextField
                                                    name={'politica_estoque.demanda_mensal'}
                                                    floatingLabelText={this.props.t("form.stockPolice.labels.averageDemand")}
                                                    onChange={props.handleChange}
                                                    value={props.values.politica_estoque?.demanda_mensal}
                                                    onlyNumbers />

                                                <TextField
                                                    name={'politica_estoque.politica_cobertura'}
                                                    floatingLabelText={this.props.t("form.stockPolice.labels.coveragePolice")}
                                                    onChange={props.handleChange}
                                                    value={props.values.politica_estoque?.politica_cobertura}
                                                    onlyNumbers />
                                            </Col>
                                            <Col lg={6} className={styles.form}>
                                                <SelectField
                                                    name="politica_estoque.curva"
                                                    value={props.values.politica_estoque.curva}
                                                    floatingLabelText={this.props.t("form.stockPolice.labels.curve")}
                                                    onChange={(_event, _index, value) => props.setFieldValue('politica_estoque.curva', value)}
                                                >
                                                    {
                                                        stockCurves.map(e => {
                                                            return <MenuItem key={e.id} value={e.id} primaryText={e.name} />;
                                                        })
                                                    }
                                                </SelectField>

                                                <CurrencyInput
                                                    name="politica_estoque.valor_custo"
                                                    floatingLabelText={this.props.t("form.stockPolice.labels.costValue")}
                                                    value={props.values.politica_estoque?.valor_custo}
                                                    onValueChange={(values) => props.setFieldValue('politica_estoque.valor_custo', Number(values.value))}
                                                />

                                                <CurrencyInput
                                                    name="politica_estoque.valor_venda"
                                                    floatingLabelText={this.props.t("form.stockPolice.labels.saleValue")}
                                                    value={props.values.politica_estoque?.valor_venda}
                                                    onValueChange={(values) => props.setFieldValue('politica_estoque.valor_venda', Number(values.value))}
                                                />
                                            </Col>
                                        </Row>
                                        {this.actionButtons(props.handleSubmit)}
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                    </Formik>
                }
            </Modal>
        );
    };
}

ProductModal.propTypes = {
    product: PropTypes.instanceOf(Product),
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    onSuccess: PropTypes.func
};

export default withTranslation('products')(ProductModal)