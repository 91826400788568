import React, { Component } from 'react';
import listStyles from './list.scss';
import CompactLot from './CompactLot';

export default class ListCompact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lots: [],
      array: [],
      qtdLotsView: 0
    }

    this.calculateBatches = this.calculateBatches.bind(this);
  }

  calculateBatches() {
    const { equipment, array, type } = this.props;
    const qtdLotsView = equipment.fifo;
    let lots = [];

    array.map((obj, i) => {
      if (i < qtdLotsView && type != 'process')
      {
        lots.push(<CompactLot {...this.props} array={array} indexFifo={i} key={i} obj={obj} />);
      }
      
      if (type == 'process')
      {
        lots.push(<CompactLot {...this.props} indexFifo={i} key={i} obj={obj} />);
      }
    });

    return lots.reverse();
  }

  render() {
    const { equipment, type, stage, handleModal, array } = this.props;
    const backgroundRup = '#E6E6E6', colorRup = '#626262';

    return (
      <div>
        {
          array.length > equipment.fifo && type != 'process' ?
            <div style={{ cursor: 'pointer' }} onClick={() => handleModal('listLote', '', '', { array }, '', { equipment }, { stage })} className={listStyles.plus_batch}>
              <div>
                <div style={{ background: backgroundRup, color: colorRup }}> + {array.length - equipment.fifo}</div>
                <div />
              </div>

            </div>
            : null
        }
        {
          array.length > equipment.fifo && type != 'process' ?
            <span className={listStyles.batch_waiting}><div /></span>
            :
            null
        }
        <div className="scrollbars">
          <ul>
            {this.calculateBatches()}
          </ul>
        </div>
      </div>
    );
  }
}