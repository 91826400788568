import React, { Component } from 'react';
import '../../../assets/styles/painel-fluxo/CustomLegends.scss';

class LegendItem extends Component {

    constructor(props) {
        super(props);
        this.state = { hidden: false };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        let index = this.props.index;
        let ci = this.props.chart;
        if(!ci.canvas) return;
        let meta = ci.getDatasetMeta(index);
        let hidden = meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
        this.setState({ hidden: hidden });
        ci.update();
    }

    render() {
        let label = this.props.label;
        let backgroundColor = !this.state.hidden ? this.props.background : '#95989A';
        return (
            <div onClick={this.handleClick} className={'chart-legend'}>
                <span style={{ backgroundColor: backgroundColor }} className={'chart-legend-label-before'}></span>&nbsp;&nbsp;
                <span className={'chart-legend-label-text'}> {label} </span>
            </div>
        );
    }
}

const CustomLegends = function (chart, bgs, data) {
    if (!chart && !data) return '';
    let legends = [];
    let bg = null;
    if(!data) data = chart.data;
    if(!data.datasets) return null;
    for (let i = 0; i < data.datasets.length; i++) {
        if(bgs) bg = bgs[i];
        else bg = data.datasets[i].backgroundColor;
        legends.push(<LegendItem
            key={i}
            chart={chart}
            label={data.datasets[i].label}
            background={bg}
            index={i}></LegendItem>);
    }
    return legends;
};

export default CustomLegends;