import React, { Component } from 'react';
import { observer } from 'mobx-react';
import listStyles from './list.scss';
import ListEquipments from './ListEquipments';
import ListPulmao from './ListPulmao';
import ListOthers from './ListOthers';

@observer
export default class StageList extends Component {
  render() {
    let { stage, listWaiting, parameters } = this.props;
    var greenHT, blackHT, greenLT, redLT;

    greenHT = parameters.find(p => p.type == 'holdingTime' && p.color == 'green');
    blackHT = parameters.find(p => p.type == 'holdingTime' && p.color == 'black');
    greenLT = parameters.find(p => p.type == 'leadtime' && p.color == 'green');
    redLT = parameters.find(p => p.type == 'leadtime' && p.color == 'red');

    var listFixed = listWaiting.filter(bp => !bp.master && bp.com_equipamento && parseInt(bp.fk_id_stages) == stage.id) || [];

    listWaiting = listWaiting.filter(bp => bp.master || !bp.com_equipamento);

    return (
      <div style={!stage.ComEquipamento ? { background: '#F5F5F5' } : null} className={listStyles.stage} data-id={'stages'}>
        <ListOthers {...this.props} greenHT={greenHT} blackHT={blackHT} greenLT={greenLT} redLT={redLT} listOthers={listFixed} setShow={this.setShow} stage={stage} />
        {
          !stage.ComEquipamento ?
            <ListPulmao {...this.props} greenHT={greenHT} blackHT={blackHT} greenLT={greenLT} redLT={redLT} listWaiting={listWaiting} />
            :
            <ListEquipments {...this.props} equipments={stage.equipments} listWaiting={listWaiting} />
        }
      </div>
    );
  }
}