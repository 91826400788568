import React from 'react';
import { EventEmitter, Events } from './../events';
import AbstractComponent from '../AbstractComponent/AbstractComponent';

class AbstractSetupComponent extends AbstractComponent {

    constructor(props) {
        super(props);
        this.state = {
            chartRef: null,
            toggle: true,
            data: null
        };
        EventEmitter.subscribe(Events.TOGGLE_SETUP, (data) => this.toggleData(data));
    }

    componentDidMount() {
        let state = EventEmitter.getState(this.props.componentID);
        this.setState({
            chartRef: this.refs.chart.chart_instance,
            data: state && state.data ? state.data : null,
            toggle: state && state.toggle ? state.toggle : true
        });
    }

    componentWillUnmount() {
        EventEmitter.storeState(this.props.componentID, this.state);
    }

    toggleData(component) {
        if (component.componentID === this.props.componentID) {
            this.setState((prevState) => {
                let toggle = !prevState.toggle;
                let data = toggle ? this.props.data.com_setup : this.props.data.sem_setup;
                return {
                    data: data,
                    toggle: toggle
                };
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            let toggle = this.state.toggle;
            this.setState({
                toggle: toggle,
                data: toggle ? nextProps.data.com_setup : nextProps.data.sem_setup
            });
        }
    }
}

export default AbstractSetupComponent;
