import React from 'react'
import PropTypes from 'prop-types'

import { default as MaterialTextField } from 'material-ui/TextField';

export class TextField extends MaterialTextField {

    handleChange(event, value) {
        if (!this.props.onChange) return;

        let changedValue = value;
        // @ts-ignore
        if (this.props.onlyNumbers && value !== '') {
            changedValue = parseInt(value.replace(/\D/g, ''));
        }

        // @ts-ignore
        return this.props.onChange(event, changedValue);
    }

    render() {
        // @ts-ignore
        const { onChange, onlyNumbers, type, ...rest } = this.props;

        return (
            <MaterialTextField
                type={onlyNumbers ? 'number' : type}
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                onChange={this.handleChange.bind(this)}
                {...rest}
            />
        )
    }
}

TextField.propTypes = {
    onlyNumbers: PropTypes.bool
}