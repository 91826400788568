import * as Yup from 'yup';

Yup.setLocale({
    mixed: {
        default: 'Valor inválido',
        notType: 'Valor inválido',
        required: 'Campo de preenchimento obrigatório',
    },
    number: {
        integer: 'Valor inválido.',
        min: 'Campo ser maior ou igual a ${min}',
        max: 'Campo ser menor ou igual a ${max}',
    },
    string: {
        url: 'Url inválida',
        email: 'Email inválido',
        min: 'Campo deve ser maior ou igual a ${min}',
        max: 'Campo deve ser menor ou igual a ${max}',
        length: 'Campo deve ser igual a ${length}',
    },
    array: {
        min: 'Campo de preenchimento obrigatório',
        max: 'Campo ser menor ou igual a ${max}',
    },
});

export default Yup;
