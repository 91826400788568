import React, { Component } from 'react';
import IconWarningNotFound from '../../../assets/icons/IconWarningNotFound';
import { request } from '../../../common/request';
import HorizontalBarParetoChart from '../../../components/oee/HorizontalBarParetoChart';
import style from './style.scss';
import { APIEndpoints } from '../../../common/Constants';
import { CircularProgress, MenuItem, SelectField } from 'material-ui';
import moment from "moment";
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { withTranslation } from 'react-i18next';

class ModalPareto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pareto: [],
      paretoCamposPersonalizados: [],
      paretoOpcoes: [],
      loadingCamposPersonalizados: false,
      loadingOpcoes: false,
      filtro_pareto_tempo: 0,
      filtro_pareto_tipo_evento: 0
    };

    this.buscarParetoCamposPersonalizados = this.buscarParetoCamposPersonalizados.bind(
      this
    );
    this.buscarParetoOpcoesCamposPersonalizados = this.buscarParetoOpcoesCamposPersonalizados.bind(
      this
    );
    this.carregarParetoFiltro = this.carregarParetoFiltro.bind(this);
  }

  componentDidMount() {
    this.setState({
      pareto: this.props.paretos,
    });
  }

  buscarParetoCamposPersonalizados(item) {
    let parametrosPareto = this.parametrosPareto(item);

    if (!item.temEstratificacao) {
      this.props.showAlert(
        this.props.t("errors.noCustomFields"),
        this.props.t("labels.warning", { ns: 'common' }),
        'alert'
      );

      this.setState({
        paretoCamposPersonalizados: [],
        paretoOpcoes: [],
      });

      return;
    }

    let newPareto = this.state.pareto.map((e) => {
      if (e.evento === item.evento) {
        return { ...e, ativo: true };
      }
      return { ...e, ativo: false };
    });

    this.setState({
      loadingCamposPersonalizados: true,
      paretoCamposPersonalizados: [],
      paretoOpcoes: [],
      pareto: newPareto,
    });

    request(
      `${APIEndpoints.OEE}/pareto/campos-personalizados/`, {
      method: "POST",
      body: JSON.stringify(parametrosPareto)
    }
    )
      .then((response) => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then((data) => {
        this.setState({
          paretoCamposPersonalizados: data.dados,
          loadingCamposPersonalizados: false,
        });
      });
  }

  buscarParetoOpcoesCamposPersonalizados(item) {
    let parametrosPareto = this.parametrosPareto(item);
    let elem = this.render().props.className;
    elem = document.getElementsByClassName(elem).item(0).lastElementChild;

    if (!item.temEstratificacao) {
      this.props.showAlert(
        this.props.t("errors.noOptionsCustomField"),
        this.props.t("labels.warning", { ns: 'common' }),
        'alert'
      );
      return;
    }

    let newParetoCamposPersonalizado = this.state.paretoCamposPersonalizados.map(
      (e) => {
        if (e.evento === item.evento) {
          return { ...e, ativo: true };
        }
        return { ...e, ativo: false };
      }
    );

    this.setState({
      loadingOpcoes: true,
      paretoOpcoes: [],
      paretoCamposPersonalizados: newParetoCamposPersonalizado,
      showInputOption: item.tipoCampo === 'alfanumerico',
    });

    request(
      `${APIEndpoints.OEE}/pareto/opcoes-campos-personalizados/`, {
      method: "POST",
      body: JSON.stringify(parametrosPareto)
    }
    )
      .then((response) => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then((data) => {
        this.setState({
          paretoOpcoes: data.dados,
          loadingOpcoes: false,
        }, () => elem.scrollBy(elem.scrollWidth, 0));
      });
  }

  parametrosPareto(item) {
    let dataInicioFormatada = moment(this.props.urlParams.dataInicio, 'DD-MM-YYYY HH:mm:ss');
    let dataFimFormata = moment(this.props.urlParams.dataFim, 'DD-MM-YYYY HH:mm:ss');

    let turnoIds = this.props.urlParams.turnoPersonalizado.length > 0 ? this.props.urlParams.turnoPersonalizado.map(item => item.id) : [];

    let parametrosPareto = {
      eventoId: item.eventoId,
      campoPersonalizado: item.evento,
      dataInicio: this.props.urlParams.dataInicio ? dataInicioFormatada.format('YYYY-MM-DD HH:mm:ss') : this.props.urlParams.dataInicio,
      dataFim: this.props.urlParams.dataFim ? dataFimFormata.format('YYYY-MM-DD HH:mm:ss') : this.props.urlParams.dataFim,
      equipamentos: this.props.urlParams.equipamentoEscolhido,
      turnoIds: turnoIds,
      dataInicioTurno: '00:00:00',
      dataFimTurno: '00:00:00',
      turnoPersonalizado: false
    };

    return parametrosPareto;
  }

  carregarParetoFiltro(filtro_pareto_tempo, filtro_pareto_tipo_evento) {
    var parametros = {
      dataInicio: this.props.urlParams.dataInicio,
      dataFim: this.props.urlParams.dataFim,
      equipamentos: this.props.urlParams.equipamentoEscolhido,
      turnoIds: this.props.urlParams.turnoPersonalizado,
      dataInicioTurno: this.props.urlParams.horaInicio,
      dataFimTurno: this.props.urlParams.horaFim,
      turnoPersonalizado: this.props.urlParams.turnoPersonalizado.length >= 1
    };

    request(`${APIEndpoints.OEE}/pareto/${parametros.dataInicio}/${parametros.dataFim}/${parametros.turnoPersonalizado}?equipamentos=${parametros.equipamentos}&${parametros.turnoIds}&dataInicioTurno=${parametros.dataInicioTurno}&dataFimTurno=${parametros.dataFimTurno}&tempoConsideradoPareto=${filtro_pareto_tempo}&tipoEventoConsiderado=${filtro_pareto_tipo_evento}`, {
      method: 'get',
    })
      .then(req => {
        return req.json();
      })
      .then(data => {
        this.setState({ pareto: data });
      })
      .catch(err => {
        console.log('Pareto:', err);
      });
  }

  printGraph() {
    const input = document.getElementById('chart-pareto');
    htmlToImage
      .toCanvas(input, { backgroundColor: '#fff' })
      .then((canvas) => {

        var pdf = new jsPDF({
          orientation: 'l', // landscape
          unit: 'pt', // points, pixels won't work properly
          format: [canvas.width, canvas.height] // set needed dimensions for any element
        });
        pdf.addImage(canvas, 'JPEG', 0, 0, canvas.width, canvas.height);
        pdf.save('pareto.pdf');
      });
  }

  render() {
    const {
      pareto,
      paretoCamposPersonalizados,
      paretoOpcoes,
      loadingCamposPersonalizados,
      loadingOpcoes,
    } = this.state;
    const { closeModal } = this.props;

    return (
      <div className={`${style.container} modal`}>
        <div>
          <div>
            <h2 className={style.title}>{this.props.t("labels.paretoStratification")}</h2>
          </div>
          <div style={{ width: '4%', display: 'flex', justifyContent: 'space-evenly' }}>
            <i className='fa fa-print cursor-pointer' onClick={this.printGraph} />
            <i className='fa fa-close cursor-pointer' onClick={closeModal} />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SelectField
            style={{ margin: '-25px 0px 0px 10px', height: 42, width: 200 }}
            underlineFocusStyle={{ borderColor: '#000000' }}
            labelStyle={{ color: '#5B5B5B', fontSize: '15px' }}
            selectedMenuItemStyle={{ fontWeight: 'bold' }}
            value={this.state.filtro_pareto_tempo}
            onChange={(...props) => {
              this.carregarParetoFiltro(props[2], this.state.filtro_pareto_tipo_evento);
              this.setState({ filtro_pareto_tempo: props[2] });
            }}
          >
            <MenuItem value={0} primaryText={this.props.t("labels.downTime", { ns: 'oee' })} />
            <MenuItem value={1} primaryText={this.props.t("labels.totalTime", { ns: 'oee' })} />
          </SelectField>

          <SelectField
            style={{ margin: '-25px 0px 0px 10px', height: 42, width: 200 }}
            underlineFocusStyle={{ borderColor: '#000000' }}
            labelStyle={{ color: '#5B5B5B', fontSize: '15px' }}
            selectedMenuItemStyle={{ fontWeight: 'bold' }}
            value={this.state.filtro_pareto_tipo_evento}
            onChange={(...props) => {
              this.carregarParetoFiltro(this.state.filtro_pareto_tempo, props[2]);
              this.setState({ filtro_pareto_tipo_evento: props[2] });
            }}
          >
            <MenuItem value={0} primaryText={this.props.t("labels.allEvents", { ns: 'oee' })} />
            <MenuItem value={1} primaryText={this.props.t("labels.considered", { ns: 'oee' })} />
            <MenuItem value={2} primaryText={this.props.t("labels.disregarded", { ns: 'oee' })} />
          </SelectField>


        </div>

        <div className={style.container_chart} id='chart-pareto'>
          <div className={style.container_evento}>
            <h2 className={style.title_section}>{this.props.t("labels.events")}</h2>

            <div className={style.box_chart}>
              <HorizontalBarParetoChart
                onClickBar={this.buscarParetoCamposPersonalizados}
                data={pareto}
                showIconLegend={true}
              />
            </div>
          </div>

          {!loadingCamposPersonalizados &&
            paretoCamposPersonalizados &&
            paretoCamposPersonalizados.length ? (
            <div className={style.container_campo_opcoes}>
              <div className={style.container_campos_personalizados}>
                <h2 className={style.title_section}>{this.props.t("labels.customFields")}</h2>

                <div className={`${style.box_chart} ${style.border_left}`}>
                  <HorizontalBarParetoChart
                    onClickBar={this.buscarParetoOpcoesCamposPersonalizados}
                    data={paretoCamposPersonalizados}
                    showIconLegend={true}
                    colorBar='#6181A6'
                  />
                </div>
              </div>

              {!loadingOpcoes && paretoOpcoes && paretoOpcoes.length ? (
                <div className={style.container_opcoes}>
                  <h2 className={style.title_section}>{this.props.t("labels.options")}</h2>


                  <div className={`${style.box_chart} ${style.border_left}`}>
                    <HorizontalBarParetoChart
                      data={paretoOpcoes}
                      colorBar='#6391C9'
                    />
                  </div>
                </div>
              ) : loadingOpcoes ? (
                <div className={style.box_loading}>
                  <CircularProgress color='#2D4F7F' size={60} thickness={7} />
                </div>
              ) : (
                <div className={style.alert_container}>
                  <h2 className={style.title_section}>{this.props.t("labels.options")}</h2>

                  <div className={`${style.box_alert} ${style.border_left}`}>
                    <IconWarningNotFound />
                    <span>{this.props.t("messages.selectCustomField")}</span>
                  </div>
                </div>
              )}
            </div>
          ) : loadingCamposPersonalizados ? (
            <div className={style.box_loading}>
              <CircularProgress color='#2D4F7F' size={60} thickness={7} />
            </div>
          ) : (
            <div className={style.alert_container} style={{ minWidth: '650px' }}>
              <h2 className={style.title_section}>{this.props.t("labels.customFields")}</h2>

              <div className={`${style.box_alert} ${style.border_left}`}>
                <IconWarningNotFound />
                <span>
                  {this.props.t("errors.customFieldNotFound")}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation("oee")(ModalPareto);
