import React, { Component } from 'react';
import createBrowserHistory from 'history/createBrowserHistory';
import Notifier from '../utils/Notifier';
import Utils from '../utils/Utils';
import { observer } from 'mobx-react';
import { APIEndpoints, Paginacao, OrigemApontamento } from '../common/Constants';
import Actions from '../common/Actions';
import Store from '../common/Store';
import Quest from '../common/Quest';
import Page from './Page';
import i18n from 'utils/i18n';

@observer
export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {}

    this.action = new Actions();
    this.endpoints = APIEndpoints;
    this.paginacao = Paginacao;
    this.origemApontamento = OrigemApontamento;
    this.store = Store;
    this.utils = Utils;
    this.browserHistory = createBrowserHistory({ forceRefresh: true });
    this.handleErrorWindow = this.handleErrorWindow.bind(this);
    this.showErrorWindow = this.showErrorWindow.bind(this);
  }

  showAlert(msg, title, type) {
    switch (type) {
      case 'error':
        Notifier.error(msg || 'Ops!', title || i18n.t('labels.error', { ns: 'common' }));
        break;
      case 'warning':
        Notifier.warning(msg || 'Ops!', title || i18n.t('labels.warning', { ns: 'common' }));
        break;
      case 'info':
        Notifier.info(msg || 'Ops!', title || i18n.t('labels.information', { ns: 'common' }));
        break;
      default:
        Notifier.success(msg || 'Ops!', title || i18n.t('labels.warning', { ns: 'common' }));
    }
  }

  handleErrorWindow() {
    this.store.clearErrors();
  }

  showErrorWindow(msg) {
    return <Quest question={msg} handleModal={this.handleErrorWindow} />;
  }

  render() {
    let { modal, contentModal } = this.props;
    if (this.store.errors) {
      contentModal = () => this.showErrorWindow(this.store.getError());
      modal = true
    }

    // detecta Internet Explorer
    let isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    if (isIE)
      document.body.classList.add('ie');
    document.body.classList.add(window.navigator.appVersion.match(/MSIE 10/) != null ? 'ie-10' : 'ie-10-plus');

    return <Page {...this.state} origemApontamento={OrigemApontamento} endpoints={this.endpoints} action={this.action} store={this.store} {...this.props} blur={this.props.blur} modal={modal} contentModal={contentModal} />;
  }
}
