import {
  observable,
  computed,
  action
} from "mobx";

import moment from 'moment'

class FracionarApontamento {
  @observable apontamento;
  @observable listApontamentoNovo;
  @observable apontamentoOriginalDataHoraInicio
  @observable apontamentoOriginalDataHoraFim
  @observable dataFracionamento
  @observable horaFracionamento
  @observable customFunctionSalvar;

  @action
  carregarValorApontamento = apontamento => {
    let dataHoraInicio = moment(apontamento.inicio).format("L LTS");
    let dataHoraFim = moment(apontamento.fim).format("L LTS");

    this.setApontamentoOriginalDataHoraInicio(dataHoraInicio);
    this.setApontamentoOriginalDataHoraFim(dataHoraFim);
    this.setApontamento(apontamento);
    this.limpar();
  }

  @action
  limpar = () => {
    this.setDataFracionamento('')
    this.setHoraFracionamento('')
  }

  @action
  setApontamento = apontamento => {
    this.apontamento = apontamento;
  }

  @action
  setApontamentoOriginalDataHoraInicio = dateTime => {
    this.apontamentoOriginalDataHoraInicio = dateTime;
  }

  @action
  setApontamentoOriginalDataHoraFim = dateTime => {
    this.apontamentoOriginalDataHoraFim = dateTime;
  }

  @action
  setDataFracionamento = date => {
    this.dataFracionamento = date;
  }

  @action
  setHoraFracionamento = hour => {
    this.horaFracionamento = hour;
  }

  @action
  setCustomFunctionSalvar = func => {
    this.customFunctionSalvar = func
  }

  @action
  salvarFracionamento = (criarApontamentoFunc, splitApontamento, alertBox) => {
    let dataSplit = `${moment(this.dataFracionamento, 'L').format('YYYY-MM-DD')} ${this.horaFracionamento}`;
    dataSplit = moment(dataSplit).format('YYYY-MM-DDTHH:mm:ss')
    let valido = !(moment(dataSplit).isAfter(moment(this.apontamento.inicio))) || !(moment(dataSplit).isBefore(this.apontamento.fim))

    if (!valido) {
      let novoApontamentoInicio = criarApontamentoFunc(this.apontamento, null, dataSplit)
      let novoApontamentoFim = criarApontamentoFunc(this.apontamento, dataSplit, null)

      splitApontamento(novoApontamentoInicio, novoApontamentoFim)
    } else {
      alertBox('Data/hora do Fracionamento deve ser superior a Data/hora início e inferior a Data/hora fim do Apontamento.', 'Período do fracionamento inválido', 'warning')
    }
  }

  @computed get ApontamentoOriginalDataHoraInicio() {
    return this.apontamentoOriginalDataHoraInicio;
  }

  @computed get ApontamentoOriginalDataHoraFim() {
    return this.apontamentoOriginalDataHoraFim;
  }

  @computed get DataFracionamento() {
    return this.dataFracionamento;
  }

  @computed get HoraFracionamento() {
    return this.horaFracionamento;
  }
}

export default FracionarApontamento;
