import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import MDSpinner from 'react-md-spinner';
import FilterBox from '../../common/FilterBox';
import Utils from '../../utils/Utils';
import InfiniteScroll from 'react-infinite-scroller';
import { hasRole } from '../../helper/SecurityHelper';
import styles from './batches.css';

@observer
export default class BatchesList extends Component {
  constructor() {
    super();
    this.state = {
      makeSearch: null
    };

    this.onFilter = this.onFilter.bind(this);
    this.referenciaSecundaria = localStorage.getItem('ReferenciaSecundaria');
  }

  componentWillMount() {
    this.props.store.searchModel = 'batchList';
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }

  onFilter(val) {
    clearTimeout(this.state.makeSearch);

    // Remove todos os caracteres não imprimíveis da pesquisa
    let value = val.trim().replace(/[\t\n\r\f\v]/gi, '');
    this.props.setSearchText(value, true);

    this.setState({
      makeSearch: setTimeout(() => {
        this.props.carregarLotes();
      }, 1000) // 1 segundo
    });
  }

  render() {
    let loader = (
      <div key={0} className="loader">
        <MDSpinner singleColor='#2D4F7F' size={20} style={{ marginTop: -5 }} />
        &nbsp;&nbsp;Carregando...
      </div>
    );

    let items = this.props.lotes.map((item, k) => {
      let typeEdit = 'upBuild';
      let skuProblem = item.rotasProdutosId == null ? "SemRota" : (item.produtoApagado == true ? "SkuApagado" : "");
      let skuCode = item.produto ? item.produto.sku != 0 ? item.produto.sku : '' : '';
      let lookUpSku = {
        'SkuApagado': {
          color: '#F9D054',
          className: 'fa fa-exclamation-triangle fa-lg',
          text: this.props.t('messages.skuDeleted')
        },
        'SemRota': {
          color: '#BF0617',
          className: 'fa fa-exclamation-circle fa-lg',
          text: this.props.t('messages.skuWithoutRoute')
        }
      };

      if (item.isShowEnvironment) typeEdit = 'up';

      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.num}</td>
          {this.referenciaSecundaria ? <td>{item.numSecundario}</td> : null}
          <td>
            {skuCode}
            {skuProblem == "" ? null :
              <i id="skuBadge"
                style={{ color: lookUpSku[skuProblem].color, marginLeft: '10px' }}
                title={lookUpSku[skuProblem].text}
                className={lookUpSku[skuProblem].className}>
              </i>
            }
          </td>
          <td>{item.produto ? item.produto.name : ''}</td>
          <td>
            <div className={styles.orderType}>
              {item.tipoLote == '0' ? this.props.t("standardBatch") : (item.tipoLote == '1' ? this.props.t("reprocess") : this.props.t("qualitySample"))}
              {item.tipoLote != '0' ? <i className='material-icons' title={this.props.t("relationship")} onClick={() => this.props.handleModal(item, 'related')}>info</i> : null}
            </div>
          </td>
          <td>{moment(item.dt_release).format('L LT')}</td>
          <td>
            {
              item.Descartado ?
                <div style={{ float: 'right' }}><img title={this.props.t("discarded")} src={'../../assets/trash-delete.svg'} width={24} height={24} /></div>
                : item.finished
                  ? <div className={styles.iconAcoes}>
                    {item.tipoLote == 0 ? <i className='material-icons options' title={this.props.t("createQualitySample")} onClick={() => this.props.handleModal(item, 'copy')}>content_copy</i> : null}
                    <i title={this.props.t("finished")} className="material-icons">check_circle</i>
                  </div>
                  : item.situation
                    ? <div className={styles.iconAcoes}>
                      {item.tipoLote == 0 ? <i className='material-icons options' title={this.props.t("createQualitySample")} onClick={() => this.props.handleModal(item, 'copy')}>content_copy</i> : null}
                      <i className='material-icons options' title={this.props.t("edit")} onClick={() => this.props.handleModal(item, typeEdit)}>edit</i>
                      <i title={this.props.t("production")} className="material-icons">build</i>
                    </div>
                    : <div className={styles.iconAcoes}>
                      {item.tipoLote == 0 ? <i className='material-icons options' title={this.props.t("createQualitySample")} onClick={() => this.props.handleModal(item, 'copy')}>content_copy</i> : null}
                      <i className='material-icons options' title={this.props.t("edit")} onClick={() => this.props.handleModal(item, 'up')}>edit</i>
                      <i className='material-icons options' title={this.props.t("delete")} onClick={() => hasRole('ROLE_LOTES_EXCLUIR') && this.props.lotesComPredecessorSucessor(item)}>cancel</i>
                    </div>
            }
          </td>
        </tr>
      )
    });
    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton
              disabled={!hasRole('ROLE_LOTES_CRIAR')}
              className='box_add'
              onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <FloatingActionButton
              style={{ display: `${!hasRole('ROLE_LOTES_CRIAR') ? 'none' : 'inline-block'}` }}
              className='box_upload'
              onClick={() => this.props.onOpenUpload()}>
              <i className='material-icons'>file_upload</i>
            </FloatingActionButton>
            <CardHeader className='box_header' title={Utils.getReferenciaLotesOrdens()} />
            <CardText className='table_overflow'>
              <InfiniteScroll
                loadMore={this.props.carregarLotes}
                hasMore={this.props.hasMoreLotes}
                initialLoad={false}
                useWindow={false}
                loader={loader}
              >
                <FilterBox onFilter={this.onFilter} waitingRequest={this.props.waitingRequest} searchTextFilterBox={this.props.searchTextFilterBox} />
                <table className='mui-table mui-table--bordered'>
                  <thead>
                    <tr>
                      <td className='id'>#</td>
                      <td className='number' style={{ width: '15%', textTransform: 'capitalize' }}>{Utils.getReferenciaLoteOrdem()}</td>
                      {this.referenciaSecundaria ? <td className='number' style={{ width: '10%', textTransform: 'capitalize' }}>{Utils.getReferenciaOrdemLote()}</td> : null}
                      <td className='sku' style={{ width: '12%' }}>{this.props.t("sku")}</td>
                      <td className='name' style={{ width: '25%' }}>{this.props.t("product")}</td>
                      <td className='tipoLote' style={{ width: '12%', textTransform: 'capitalize' }}>{this.props.t("batchType", { batch: Utils.getReferenciaLoteOrdem() })}</td>
                      <td className='release' style={{ width: '13%' }}>{this.props.t("release")}</td>
                      <td className='actions' style={{ width: '10%' }}>{this.props.t("actions")}</td>
                    </tr>
                  </thead>
                  <tbody className='box_items'>
                    {items}
                  </tbody>
                </table>
              </InfiniteScroll>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_LOTES_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <FloatingActionButton
              style={{ display: `${!hasRole('ROLE_LOTES_CRIAR') ? 'none' : 'inline-block'}` }}
              className='box_upload'
              onClick={() => this.props.onOpenUpload()}>
              <i className='material-icons'>file_upload</i>
            </FloatingActionButton>
            <CardHeader className='box_header' title='Lotes' />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t("emptyList")}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}