import React from "react";
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import DeleteItem from '../../common/DeleteItem';
import StagesList from './StagesList';
import { request, requestAll } from '../../common/request';
import Quest from '../../common/Quest';
import { alertBox } from '../../common/NForm';
import { RaisedButton, TextField, Checkbox } from 'material-ui';
import MDSpinner from 'react-md-spinner';
import styles from './Stage.css';
import { respostaEtapas, requisicaoEtapas } from '../../common/mappers';
import { hasRole } from '../../helper/SecurityHelper';
import TextTag from 'common/Components/TextTag/TextTag';
import { withTranslation } from 'react-i18next';

@observer
class StagesPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      field: '',
      loadingPage: true,
      typeModal: '',
      nome: '',
      erroNOME: '',
      comEquipamento: false,
      unidadeIndustrial: '',
      msgQuest: '',
      tagsTipoProducao: [],
      isKeyReleased: false
    };
    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.clearState = this.clearState.bind(this);
    this.deleteStage = this.deleteStage.bind(this);
    this.refreshStage = this.refreshStage.bind(this);
    this.updateStage = this.updateStage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setStateTags = this.setStateTags.bind(this);
  }

  componentWillMount() {
    this.refreshStage();
  }

  refreshStage() {
    requestAll([
      {
        url: this.endpoints.ETAPA,
        modelo: 'stages',
        modificador: respostaEtapas
      }
    ], this);
  }

  delete(id) {
    let data = {
      nome: this.state.nome,
      criado_usuario: this.state.field.user_cad,
      criado_em: this.state.field.dt_cad,
      atualizado_usuario: this.state.field.user_up,
      atualizado_em: this.state.field.dt_up,
      excluido_usuario: sessionStorage.getItem('login'),
      com_equipamento: !this.state.comEquipamento,
    };

    if (this.state.unidadeIndustrial.length <= 0) {
      request(this.endpoints.ETAPA + `/${id}`, {
        method: 'DELETE',
        body: JSON.stringify(data)
      }).then((response) => {
        if (response.status == 200) {
          this.refreshStage();
          this.handleModal();
          alertBox(this.props.t("messages.deleteSuccess"), this.props.t("messages.stageDelete"), 'success');
        } else {
          alertBox(this.props.t("messages.deleteError"), this.props.t("messages.stageDelete"), 'error');
        }
      })
        .catch(error => {
          alertBox(this.props.t("messages.deleteError"), this.props.t("messages.stageDelete"), 'error');
        });
    } else {
      this.updateStage('DELETE');
    }
  }

  buildTags(data) {
    if (data && data.tipos != null)
      return data.tipos.map(m => m);

    return [];
  }

  handleModal(f, t, msg) {
    this.setState({
      modal: !this.state.modal,
      field: f,
      typeModal: t
    });

    if (t == 'add') {
      this.clearState();
    }

    if (t == 'quest') {
      this.setState({
        modal: true,
        msgQuest: msg,
        typeModal: t
      });
    }

    if (t == 'up' || t == 'del') {
      this.setState({
        modal: true,
        typeModal: 'loading'
      });

      request(this.endpoints.UNIDADE_INDUSTRIAL + `/etapa/${f.id}`).then((response) => {
        if (response.status == 200) return response.json();
      }).then((data) => {
        var tags = this.buildTags(f.TipoProducao);

        this.setState({
          typeModal: t,
          nome: f.name,
          comEquipamento: !f.ComEquipamento,
          unidadeIndustrial: data,
          tagsTipoProducao: tags
        });
      });
    }
  }

  clearState() {
    this.setState({
      nome: '',
      comEquipamento: false,
      tagsTipoProducao: []
    });
  }

  updateStage(method) {
    let data = {
      nome: this.state.nome,
      criado_usuario: this.state.field.user_cad,
      criado_em: this.state.field.dt_cad,
      atualizado_usuario: this.state.field.user_up,
      atualizado_em: this.state.field.dt_up,
      excluido_usuario: sessionStorage.getItem('login'),
      com_equipamento: !this.state.comEquipamento,
    };

    let updateEtapa = request(this.endpoints.ETAPA + `/${this.state.field.id}`, {
      method: method == 'DELETE' ? 'DELETE' : 'PUT',
      body: JSON.stringify(data)
    });

    let updateRota = request(this.endpoints.ROTA + `/etapa/${this.state.field.id}`, {
      method: 'DELETE',
    });

    let updateEquipamento = request(this.endpoints.EQUIPAMENTOS + `/etapa/${this.state.field.id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    Promise.all([updateEtapa, updateRota, updateEquipamento]).then(response => {
      return Promise.all([response[0], response[1], response[2]]);
    }).then(response => {
      if ((response[0].status == 200) && (response[1].status == 200) && (response[2].status == 200)) {
        this.refreshStage();
        this.handleModal();
        alertBox(this.props.t("messages.deleteSuccess"), this.props.t("messages.stageDelete"), 'success');
      } else {
        alertBox(this.props.t("messages.deleteError"), this.props.t("messages.stageDelete"), 'error');
      }
    }).catch(error => {
      alertBox(this.props.t("messages.deleteError"), this.props.t("messages.stageDelete"), 'error');
    });
  }

  submit() {
    let erro = false, erroEtapa = false;
    var { field, nome, comEquipamento, typeModal, unidadeIndustrial } = this.state;

    let data = {
      nome: nome?.trim(),
      com_equipamento: !comEquipamento,
      TipoProducao: {
        Tipos: this.state.tagsTipoProducao
      }
    };

    if (!data.nome) {
      this.setState({
        erroNOME: this.props.t("messages.requiredField")
      });
      erro = true;
    } else {
      this.setState({
        erroNOME: ''
      });
    }

    if (!erro) {
      if (typeModal == 'up') {
        if (unidadeIndustrial.length > 0 && comEquipamento) {
          let mensagem;
          erroEtapa = true;
          unidadeIndustrial.length == 1
            ?
            mensagem = this.props.t("messages.stageWithIndustrialUnitySingular", { industrialUnity: unidadeIndustrial[0].nome })
            :
            mensagem = this.props.t("messages.stageWithIndustrialUnityPlural", { industrialUnity: unidadeIndustrial.map(u => u.nome).join(',') });

          this.handleModal(field, 'quest', mensagem);
        }

        if (!erroEtapa) {
          request(this.endpoints.ETAPA + `/${field.id}`, {
            method: 'PUT',
            body: JSON.stringify(requisicaoEtapas(data))
          }).then((response) => {
            if (response.status == 200) {
              this.refreshStage();
              this.handleModal();
              alertBox(this.props.t("messages.updateSuccess"), this.props.t("messages.stageUpdate"), 'success');
            } else {
              alertBox(this.props.t("messages.updateError"), this.props.t("messages.stageUpdate"), 'error');
            }
          })
            .catch(error => {
              alertBox(this.props.t("messages.updateError"), this.props.t("messages.stageUpdate"), 'error');
            });
        }
      } else {
        request(this.endpoints.ETAPA, {
          method: 'POST',
          body: JSON.stringify(requisicaoEtapas(data))
        }).then((response) => {
          if (response.status == 200) {
            this.refreshStage();
            this.closeModal();
            alertBox(this.props.t("messages.createSuccess"), this.props.t("messages.stageCreate"), 'success');
          } else if (response.status == 409) {
            alertBox(this.props.t("messages.stageAlreadyExists"), this.props.t("stage"), 'error');
          } else {
            alertBox(this.props.t("messages.createError"), this.props.t("messages.stageCreate"), 'error');
          }
        })
          .catch(error => {
            alertBox(this.props.t("messages.createError"), this.props.t("messages.stageCreate"), 'error');
          });
        this.clearState();
        this.closeModal();
      }
    }
  }

  closeModal() {
    this.setState({
      modal: false,
    });
  }

  handleText(text, event) {
    this.setState({
      [text]: event.target.value
    });
  }

  deleteStage() {
    var { unidadeIndustrial } = this.state;
    let msgModal;

    if (unidadeIndustrial.length > 0) {
      msgModal = this.props.t("messages.stageLinkedRoutes");

      return <DeleteItem {...this.state} delete={this.delete} handleModal={this.handleModal} msgModal={msgModal} />;
    }
    else {
      return <DeleteItem {...this.state} delete={this.delete} handleModal={this.handleModal} />;
    }
  }

  setStateTags(value) {
    this.setState({ tagsTipoProducao: value });
  }

  contentModal() {
    let { typeModal } = this.state;

    switch (typeModal) {
      case 'del':
        return this.deleteStage();

      case 'loading':
        return (
          <div className="modal" id={styles.loading} style={{ width: '80px', height: '100%' }}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        );

      case 'quest':
        return <Quest question={this.state.msgQuest} handleModal={this.handleModal} action={this.updateStage} notAction={this.handleModal} />;

      default:
        return <div style={{ width: '100%' }} className="modal">
          <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}> {this.state.typeModal == 'up' ? this.props.t("messages.stageUpdate") : this.props.t("messages.stageCreate")}</div>
          <div className={styles.form}>

            <TextField
              ref='nome' fullWidth={true}
              underlineFocusStyle={{ borderColor: '#000000' }}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("name")}
              defaultValue={this.state.nome}
              errorText={this.state.erroNOME}
              onBlur={this.handleText.bind(this, 'nome')} />

            <Checkbox
              style={{ marginTop: '1em' }}
              checked={this.state.comEquipamento}
              onCheck={(event, value) => this.setState({ comEquipamento: value })}
              label={this.props.t("stageWithoutEquipment")}
              iconStyle={{ fill: '#2D4F7F' }} />

            {!this.state.comEquipamento
              ?
              <TextTag
                placeHolder={this.props.t("insertType")}
                tags={this.state.tagsTipoProducao}
                onInput={this.setStateTags}
                labelTags={this.props.t("productionTypes")}
              />
              : null
            }

          </div>

          <div className={'flex flex-just-end'} style={{ paddingTop: '1em' }} >
            <RaisedButton onClick={this.submit.bind(this)} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} labelColor='#FFF' label={this.props.t("save")}
              disabled={((!hasRole('ROLE_ETAPAS_CRIAR') && this.state.typeModal === 'add') || (!hasRole('ROLE_ETAPAS_ATUALIZAR') && this.state.typeModal == 'up'))} />
            <RaisedButton onClick={this.handleModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '1em' }} label={this.props.t("cancel")} />
          </div>
        </div>;
    }
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={{ width: this.state.typeModal == 'loading' ? 'none' : 350 }} contentModal={this.contentModal}>
          <StagesList ref='list' store={this.store} handleModal={this.handleModal} showAlert={this.showAlert} t={this.props.t} />
        </Container>
      );
    }
  }
}

export default withTranslation('stages')(StagesPage);
