import React from 'react'

import { default as MaterialSelectField } from 'material-ui/SelectField';

export class SelectField extends MaterialSelectField {
    render() {
        return (
            <MaterialSelectField
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                {...this.props}
            />
        )
    }
}