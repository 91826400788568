import React from 'react'
import { NEditableBagde } from '../../common/NForm';

export default props => (
    <NEditableBagde
        editable={props.editable}
        onChange={props.onChange}
        onDelete={(i) => props.onDelete(i)}
        key={`key-${props.keys}`}
        text={props.text}
        active={props.active}
        onClick={props.onClick}
    />
)