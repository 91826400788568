import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Notifier from '../utils/Notifier';
import Form from '../common/Form';
import Button from '../common/Button';
import animate from 'animate.css/animate.css';
import i18n from 'utils/i18n';

@observer
export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [
        { name: 'login', label: i18n.t('labels.user', { ns: 'common' }), type: 'text', autoFocus: true },
        { name: 'password', label: i18n.t('labels.password', { ns: 'common' }), type: 'password', onFinished: this.props.onSubmit }
      ],
      buttons: [
        { name: i18n.t('labels.login', { ns: 'common' }).toUpperCase(), type: 'round', action: this.props.onSubmit }
      ],
      language: 'pt-BR'
    };

    this.loginActiveDirectory = this.loginActiveDirectory.bind(this);
  }

  componentDidMount() {
    var language = navigator.language || navigator.userLanguage;

    if (language) {
      if (language !== 'pt-BR') {
        language = 'en-US'
      }
      
      i18n.changeLanguage(language);

      this.setState({
        fields: [
          { name: 'login', label: i18n.t('labels.user', { ns: 'common' }), type: 'text', autoFocus: true },
          { name: 'password', label: i18n.t('labels.password', { ns: 'common' }), type: 'password', onFinished: this.props.onSubmit }
        ],
        buttons: [
          { name: i18n.t('labels.login', { ns: 'common' }).toUpperCase(), type: 'round', action: this.props.onSubmit }
        ],
        language
      });
    }
  }

  loginActiveDirectory() {
    window.location.assign(this.props.activeDirectory.url);
  }

  decodeToken(token) {
    return decoded;
  }

  showAlert(msg, title) {
    Notifier.error(msg, title);
    setTimeout(() => store.clearErrors(), 3000);
  }

  render() {
    let { styles, store } = this.props;
    let { fields, buttons } = this.state;
    if (store.errors) this.showAlert(store.getError(), i18n.t('labels.warning', { ns: 'common' }), 'error');

    return (
      <div className={styles.loginForm + " " + animate.animated + " " + animate.fadeInLeft}>
        <div className={this.props.activeDirectory.url === null ? styles.loginBox : styles.loginBox_ad}>
          {
            this.props.activeDirectory.url !== undefined
              ?
              this.props.activeDirectory.url === null
                ?
                <div className={styles.loginBox}>
                  <img src="../assets/logoWhite.svg" className={styles.loginLogo} />
                  <label>
                    {
                      this.props.loading
                        ?
                        <img width={73} src='../assets/loading.gif' />
                        :
                        null
                    }
                  </label>
                  <Form ref="form" styles={styles} fields={fields} buttons={buttons} />
                </div>
                :
                <div className={styles.loginBox}>
                  <img src="../assets/logoWhite.svg" className={styles.loginLogo} />
                  <Button button={{ type: 'round', className: 'button_logn_ad', text: i18n.t('labels.login', { ns: 'common' }), onClick: () => this.loginActiveDirectory() }} />
                </div>
              :
              <div>
                <label><img width={80} src='../assets/loading.gif' /></label>
              </div>
          }
        </div>
      </div>
    );
  }
}