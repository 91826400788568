import React from 'react';
import moment from 'moment';
import './monthYearPicker.scss';

const MonthYearPicker = ({ value, onChange, minDate, maxDate, disabledMonths, className, label }) => {
    const handleMonthChange = (e) => {
        const newDate = moment(value).set('month', e.target.value);
        onChange(newDate.toDate());
    };

    const handleYearChange = (e) => {
        const newDate = moment(value).set('year', e.target.value);
        onChange(newDate.toDate());
    };

    const months = moment.months();
    const years = [];
    const currentYear = moment().year();
    const startYear = minDate ? moment(minDate).year() : currentYear - 10;
    const endYear = maxDate ? moment(maxDate).year() : currentYear;
    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }

    const isDisabled = (month) => {
        const date = moment(value).month(month).startOf('month');
        return (minDate && date.isBefore(minDate, 'month')) || (maxDate && date.isAfter(maxDate, 'month')) || (disabledMonths && disabledMonths(date));
    };

    return (
        <div className={`month-year-picker ${className}`}>
            <div className='form-group'>
                {label && <label className='form-label'>{label}</label>}
                <div className='month-year-picker-content'>
                    <select value={moment(value).month()} onChange={handleMonthChange} className="custom-select month-picker">
                        {months.map((month, index) => {
                            // const disabled = isDisabled(month) ? 'disabled' : '';
                            return (
                                <option key={index} value={index} disabled={isDisabled(month)}>
                                    {month}
                                </option>
                            );
                        })}
                    </select>
                    <select value={moment(value).year()} onChange={handleYearChange} className="custom-select">
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div >
    );
};

export default MonthYearPicker;
