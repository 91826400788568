import React, { Component } from 'react';
import Button from '../../common/Button';
import lbStyles from './ListBatches.scss';
import { alertBox } from '../../common/NForm';
import utils from '../../utils/Utils';
import ListBatchesTable from './ListBatchesTable';
import { withTranslation } from 'react-i18next';

class ListBatches extends Component {
    constructor(props) {
        super(props);

        this.detalhamentoLote = this.detalhamentoLote.bind(this);
    }

    detalhamentoLote(obj) {
        if (obj.inconsistente) {
            alertBox(this.props.t("errors.cannotDetailInconsistent", { primaryReference: utils.getReferenciaoLoteaOrdem().toLowerCase() }), utils.getReferenciaLoteInconsistenteOrdemInconsistente(), 'error');
        } else {
            this.props.activeModal('detailLote', obj);
        }
    }

    render() {
        var temp;
        var { handleModal, equipment, etapa, referenciaSecundaria, watingBatches, batches } = this.props;

        var codProd = this.props.store.events.filter(e => e.fk_id_table == 2)[0];
        var codFim = this.props.store.events.filter(e => e.fk_id_table == 12)[0];

        let process = batches.reduce((acc, b) => {
            temp = b.batches[0].listProcess.reduce((acc1, e) => {
                if (e.fk_id_equipments == equipment.equipment.id) {
                    acc1 = acc1.concat(e);
                }
                return acc1;
            }, []);
            return acc.concat(temp);
        }, []);

        let watingBatchesValues = watingBatches !== undefined ? Object.values(watingBatches) : [];
        let waiting = watingBatchesValues.length > 0 ? watingBatchesValues[0] : [];

        // É feita a verificação de evento_id = 0 devido ao bug tracking 37008330. deve arrumar no back, o lote não deveria ser exibido no flowview.
        let warning = batches.reduce((acc, b) => {
            temp = b.batches[0].listWarning.reduce((acc1, e) => {
                if ((e.fk_id_equipments == equipment.equipment.id && (codFim != undefined && e.fk_id_events == codFim.id || e.fk_id_events == 0)) || (codProd != undefined && e.fk_id_equipments_last == equipment.equipment.id && e.fk_id_events == codProd.id)) {
                    acc1 = acc1.concat(e);
                }
                return acc1;
            }, []);
            return acc.concat(temp);
        }, []);

        return (
            <div className={lbStyles.list_modal}>
                <div>
                    <div><h3>{this.props.t("labels.equipmentDetail")}</h3> <Button button={{ type: 'buttonIcon', icon: 'fa fa-close', text: '', onClick: handleModal }} /></div>
                </div>
                <div>
                    <ListBatchesTable codProd={codProd}
                        store={this.props.store}
                        handleSession={this.props.handleSession}
                        handleModal={this.props.handleModal}
                        listWaiting={waiting}
                        listWarning={warning}
                        listProcess={process}
                        activeModal={this.props.activeModal}
                        endpoints={this.props.endpoints}
                        etapa={etapa.stage}
                        equipamento={equipment.equipment}
                        detalhamentoLote={this.detalhamentoLote}
                        referenciaSecundaria={referenciaSecundaria}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation("flowView")(ListBatches);
