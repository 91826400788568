import React, { Component, Fragment } from 'react';

import styles from './index.scss';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import TimeToExtensive from '../../../utils/TimeToExtensive';
import Utils from '../../../utils/Utils';
import { withTranslation } from 'react-i18next';

class TimeLineChart extends Component {

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const { data } = this.props;
    const tamanhoPrimeiro = data && data[0].metricas.reduce((acc, current) => acc += current.porcentagem, 0);

    return (
      <div className={styles.container_timeline}>
        <div className={styles.box_time_line} id="scroll-style">
          {
            (data || []).map((time, index) => (
              <div key={index} className={`${styles.periodo} ${styles.periodo_granularidade}`}>
                <span style={{
                    pointerEvents: 'none',
                    marginTop: Utils.isIE() && "-.5em",
                  }}
                  className={styles.title}> {time.tituloGranularidade}
                </span>

                <div className={styles.box_granularidade}>
                  {
                    time.metricas.map((metrica, indexMetrica) => (
                      <div
                        key={indexMetrica}
                        className={styles[metrica.cor]}
                        data-for='global'
                        data-iscapture='true'
                        data-html={true}
                        data-tip={`
                          <div class='flex flex-dir-col'>
                            <span class='color-white'>${this.props.t("charts.labels.start")}: ${moment(metrica.inicio).format('L LTS')}</span>
                            <span class='color-white'>${this.props.t("charts.labels.end")}: ${moment(metrica.fim).format('L LTS')}</span>
                            <span class='color-white'>${this.props.t("charts.labels.realDuration")}: ${TimeToExtensive(metrica.duracao)}</span>
                          </div>
                      `}
                        style={{ width: `${metrica.porcentagem}%`, height: '100%' }}>
                      </div>)
                    )
                  }
                </div>
              </div>
            )
            )
          }
        </div>

        {
          data && data.length ? (
            <div className='flex container-legenda flex-just-center w-100'>
              <div className={`flex flex-just-between flex-wrap ${styles.responsivelegenda}`} id={styles.box_legenda}>
                <div className={styles.container_legenda}>
                  <div className={`${styles.quadrado_legenda} ${styles.verde}`} />
                  {this.props.t("charts.labels.production")}
                </div>

                <div className={styles.container_legenda}>
                  <div className={`${styles.quadrado_legenda} ${styles.vermelho}`} />
                  {this.props.t("charts.labels.stop")}
                </div>

                <div className={styles.container_legenda}>
                  <div className={`${styles.quadrado_legenda} ${styles.laranja}`} />
                  {this.props.t("charts.labels.maintenance")}
                </div>

                <div className={styles.container_legenda}>
                  <div className={`${styles.quadrado_legenda} ${styles.azul}`} />
                  {this.props.t("charts.labels.setup")}
                </div>

                <div className={styles.container_legenda}>
                  <div className={`${styles.quadrado_legenda} ${styles.cinza_escuro}`} />
                  {this.props.t("charts.labels.gap")}
                </div>

                <div className={styles.container_legenda}>
                  <div className={`${styles.quadrado_legenda} ${styles.cinza_claro}`} />
                  {this.props.t("charts.labels.unavailableHours")}
                </div>
              </div>
            </div>
          ) : null
        }

        <ReactTooltip
          id='global'
          html={true} />
      </div>
    )
  }
}

export default withTranslation("oee")(TimeLineChart)