import React, { Component } from 'react';
import Button from './Button';
import { withTranslation } from 'react-i18next';

class Quest extends Component {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(event) {
    if (event.keyCode == '27') this.props.handleModal();
  }

  componentWillMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    let { question, notAction, handleModal, action, deny, simText, naoText, width, height, showClose, disabledButton, onlyOkButton } = this.props;
    return (
      <div className="modal" style={{ width: width || '500px', height: height || '100%' }}>
        <div>
          <div>
            {question}
          </div>
          {
            showClose === true || typeof (showClose) == 'undefined' ?
              <div>
                <i className="fa fa-close cursor-pointer" onClick={() => handleModal('')} />
              </div>
              : null
          }
        </div>
        {
          action && !deny ?
            <div className={'flex flex-dir-row flex-just-center'}>
              <Button button={{ type: 'round', className: 'button_primary', text: simText || this.props.t("labels.yes"), onClick: action, disabled: disabledButton }} />
              {onlyOkButton ? null : <Button button={{ type: 'round', text: naoText || this.props.t("labels.no"), onClick: notAction || handleModal }} />}
            </div> : null
        }
      </div>
    );
  }
}

export default withTranslation("common")(Quest);
