

/**
 * Verifica se o usuário tem a 'role' associada a sua credenciais de acesso.
 * 
 * @param string[] roles 
 */
export const hasRole = (roles) => {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    if (!roles || roles.length === 0) {
        return true;
    }

    const data = localStorage.getItem('roles');
    const userRoles = JSON.parse(data);

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    let valid = false;

    if (typeof roles === 'string') {
        valid = userRoles.filter((userRole) => userRole === roles).length !== 0;
    } else {
        for (let index = 0; index < roles.length; index++) {
            let role = roles[index];
            let count = userRoles.filter((userRole) => userRole === role).length;

            if (count > 0) {
                valid = true;
                break;
            }
        }
    }

    return valid;
}