import React, { Component } from "react";
import { Divider } from "material-ui"
import Grid from "../../Grid/grid"
import Row from "../../Grid/row"
import { inject, observer } from "mobx-react";
import moment from "moment";
import {
  NForm,
  NFormTitle,
  NButtonGroup,
  NButton,
  NInputTime,
  NDatePickerInput,
  alertBox
} from "../../NForm";
import i18n from 'utils/i18n'

@inject("store")
@observer
class FracionarModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campoData: "",
      campoHora: ""
    }

    this.fracionarApontamentoStore = this.props.store.fracionarApontamentoStore;
    this.fracionarApontamentoStore.carregarValorApontamento(this.props.apontamento);
    this.fecharModal = this.fecharModal.bind(this);
    this.validarSalvar = this.validarSalvar.bind(this);
    this.validarDataHora = this.validarDataHora.bind(this);
    this.onChangeDateRaw = this.onChangeDateRaw.bind(this);
  }

  validarDataHora() {
    let store = this.fracionarApontamentoStore;

    if (!store.DataFracionamento || !store.HoraFracionamento) return;

    let horaValida = true, dataValida = true;
    let novaData = moment(store.DataFracionamento, "L");
    let novaHora = moment(store.HoraFracionamento, "LTS");

    if (!novaData.isValid()) {
      dataValida = false;
      this.fracionarApontamentoStore.setDataFracionamento("");

      alertBox("Data inválida!", "Data Fracionada", "warning");
    }

    if (!novaHora.isValid()) {
      horaValida = false;
      this.fracionarApontamentoStore.setHoraFracionamento("");

      alertBox("Hora inválida!", "Hora Fracionada", "warning");
    }

    if (!horaValida || !dataValida) return;

    let novaDataHora = moment(novaData.format("L") + " " + novaHora.format("LTS"), "L LTS");

    if (!novaDataHora.isValid()) {
      store.limpar();

      alertBox("Data/hora inválidos!", "Data & hora", "warning");
    }

    let dataHoraInicial = moment(store.apontamentoOriginalDataHoraInicio, "L LTS");
    let dataHoraFinal = moment(store.apontamentoOriginalDataHoraFim, "L LTS");

    if (!novaDataHora.isBetween(dataHoraInicial, dataHoraFinal)) {
      store.limpar();

      alertBox("Data infomada não está no intervalo do apontamento fracionado.", "Data", "warning");
    }
  }

  fecharModal() {
    this.fracionarApontamentoStore.limpar()
    this.props.handleModal()
  }

  validarSalvar() {
    let valido = true;

    if (!this.fracionarApontamentoStore.HoraFracionamento) {
      valido = false;

      this.setState({
        campoHora: i18n.t('messages.requiredField', { ns: 'common' })
      })
    }

    if (!this.fracionarApontamentoStore.DataFracionamento) {
      valido = false;

      this.setState({
        campoData: i18n.t('messages.requiredField', { ns: 'common' })
      })
    }

    if (valido) {
      this.setState({
        campoData: "",
        campoHora: ""
      })

      this.fracionarApontamentoStore.salvarFracionamento(this.props.criarApontamento, this.props.splitApontamento, alertBox);
    }
  }

  onChangeDateRaw(event) {
    var v = event.target.value.replace(/\D/g, '');

    if (v.length < 8) {
      v = v.replace(/(\d{2})(\d)/, '$1/$2');
      v = v.replace(/(\d{2})(\d)/, '$1/$2');
      event.target.value = v;
      this.fracionarApontamentoStore.setDataFracionamento(moment(v, 'L'));

    } else {
      v = v.replace(/(\d{2})(\d)/, '$1/$2');
      v = v.replace(/(\d{2})(\d)/, '$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1');
      event.target.value = v;
      this.fracionarApontamentoStore.setDataFracionamento(moment(v, 'L'));
    }

    if (v.length === 10) {
      let data = moment(v, 'L');

      if (data.isValid()) {
        this.fracionarApontamentoStore.setDataFracionamento(data);
        this.validarDataHora();
      }
    }
  }

  render() {
    const floatingLabelStyle = {
      color: "#5B5B5B",
      textTransform: "uppercase",
      textOverflow: "ellipsis",
      width: "130%",
      overflow: "hidden",
      background: "#fff",
      whiteSpace: "nowrap"
    },
      gridCols = "3 3 3";

    let store = this.fracionarApontamentoStore;

    let [dataInicio, horaInicio] = store.ApontamentoOriginalDataHoraInicio.split(" ");
    let [dataFim, horaFim] = store.ApontamentoOriginalDataHoraFim.split(" ");

    return (
      <div style={{ height: "100%" }}>
        <NForm>
          <NFormTitle>Apontamento atual</NFormTitle>
          <Row>
            <Grid cols={gridCols}>
              <NInputTime label="DATA INÍCIO"
                value={dataInicio}
                floatingLabelStyle={floatingLabelStyle}
                disabled={true}
              />
            </Grid>

            <Grid cols={gridCols}>
              <NInputTime label="HORA INÍCIO"
                value={horaInicio}
                floatingLabelStyle={floatingLabelStyle}
                disabled={true}
              />
            </Grid>

            <Grid cols={gridCols}>
              <NInputTime label="DATA FIM"
                value={dataFim}
                floatingLabelStyle={floatingLabelStyle}
                disabled={true}
              />
            </Grid>

            <Grid cols={gridCols}>
              <NInputTime label="HORA FIM"
                value={horaFim}
                floatingLabelStyle={floatingLabelStyle}
                disabled={true}
              />
            </Grid>
          </Row>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <NFormTitle>Fracionamento</NFormTitle>
          <Row>
            <Grid cols={gridCols}>
              <NDatePickerInput label="DATA"
                onChange={date => store.setDataFracionamento(date)}
                onChangeRaw={event => this.onChangeDateRaw(event)}
                onBlur={this.validarDataHora}
                value={store.DataFracionamento}
                minDate={moment(dataInicio, "L")}
                maxDate={moment(dataFim, "L")}
                errorText={this.state.campoData}
                require={this.state.campoData}
                floatingLabelStyle={{ fontSize: "0.7rem", textTransform: "uppercase", marginBottom: 0 }}
              />
            </Grid>

            <Grid cols={gridCols}>
              <NInputTime label="HORA"
                onChange={(_event, value) => { store.setHoraFracionamento(value) }}
                onBlur={this.validarDataHora}
                value={store.HoraFracionamento}
                type="time"
                errorText={this.state.campoHora}
                require={true}
                step={1}
                validarHora={this.validarDataHora}
              />
            </Grid>
          </Row>

          <NButtonGroup>
            <NButton
              type="primary"
              onClick={() => this.validarSalvar()}>
              Salvar
            </NButton>

            <NButton
              style={{ marginLeft: 5 }}
              onClick={() => this.fecharModal()}>
              Cancelar
            </NButton>
          </NButtonGroup>
        </NForm>
      </div>
    )
  }
}

export default FracionarModal;
