import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { TextField } from 'material-ui';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@observer
class UsersList extends Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.state = {
      usuarios: [],
      usuarioList: []
    };
  }

  componentDidMount() {
    this.setState({
      usuarios: this.props.usuarios,
      usuarioList: this.props.usuarios
    });
  }

  componentDidUpdate() {
    if (this.state.usuarios !== this.props.usuarios) {
      this.setState({
        usuarios: this.props.usuarios,
        usuarioList: this.props.usuarios
      });
    }
  }

  onFilter(value) {
    let regex = new RegExp(value, 'i');
    var lista = this.state.usuarios.filter(l => regex.test(l.nome));

    this.setState({
      usuarioList: lista
    });
  }

  render() {
    let { AD } = this.props;
    let items = this.state.usuarioList.map((item, k) => {
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.nome}</td>
          <td>
            <div className='options'>
              {
                !AD ? <i className="material-icons" title={this.props.t("form.password.title")} onClick={() => hasRole('ROLE_USUARIOS_ATUALIZAR') && this.props.onOpenModal(item, 'up_senha')}>lock</i> : null
              }
              <i className='material-icons' title={this.props.t("list.actions.edit", { ns: "common" })} onClick={() => this.props.onOpenModal(item, 'up')}>edit</i>
              {
                item.username !== localStorage.getItem('login')
                  ?
                  <i className='material-icons' title={this.props.t("list.actions.delete", { ns: "common" })} onClick={() => hasRole('ROLE_USUARIOS_EXCLUIR') && this.props.onOpenModal(item, 'del')}>cancel</i>
                  :
                  null
              }
            </div>
          </td>
        </tr>
      )
    });

    if (this.state.usuarios.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_USUARIOS_CRIAR')} className='box_add' onClick={() => this.props.onOpenModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t("title")} />
            <CardText className='table_overflow'>
              <div>
                <i className='fa fa-search' />
                <TextField underlineFocusStyle={{ borderColor: '#000000' }}
                  hintStyle={{ color: '#5B5B5B', fontSize: '.9em' }}
                  hintText={this.props.t("list.search", { ns: "common" })} onChange={(event, value) => this.onFilter(value)}
                  style={{ width: '95%', paddingLeft: '.5em' }} />
              </div>
              <table className='mui-table mui-table--bordered'>
                <thead>
                  <tr>
                    <td className='id'>#</td>
                    <td className='name'>{this.props.t("list.columns.name")}</td>
                    <td className='actions'>{this.props.t("list.columns.action_other", { ns: "common" })}</td>
                  </tr>
                </thead>
                <tbody className='box_items'>
                  {items}
                </tbody>
              </table>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_USUARIOS_CRIAR')} className='box_add' onClick={() => this.props.onOpenModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title='Usuários' />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t("list.empty", { ns: "common" })}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation("user")(UsersList);