import React, { Component } from 'react';
import ListarEtapa from './ListarEtapa';
import $ from 'jquery';

import styles from './style.scss';
import utils from '../../../utils/Utils';
import { withTranslation } from 'react-i18next';

class Lote extends Component {
  constructor() {
    super();
    this.state = {
      contPredecessores: 0
    };
  }

  componentWillMount() {
    if(this.props.detalhes.predecessores !== null) {
      this.setState({
        contPredecessores: this.props.detalhes.predecessores.length
      });
    }
  }

  componentDidMount() {
    let {contPredecessores} = this.state;
    var widthPredecessores = 0, simulateDiv = 0,  widthLotePai = 0,  maxWidthPredecessores = 0;

    for(let j=0; j<contPredecessores; j++) {
      var conts = j-1;
      if($('#divPredecessores'+j).width() > $('#divPredecessores'+conts).width()) {
        maxWidthPredecessores = $('#divPredecessores'+j).width();
      }
    }

    for(let i=1; i<contPredecessores; i++) {      
      var cont = i-1;
      var simulatePredecessores = maxWidthPredecessores - $('#divPredecessores'+cont).width();
      widthLotePai = $('#divLotePai').width();
      $('#simulateDivLotePai'+cont).css('width', widthLotePai);
      $('#simulateDivPredecessores'+cont).css('width', simulatePredecessores);
    } 
    document.getElementById('scrollContainer').scrollTop = document.getElementById('scrollContainer').scrollHeight;   
  }

  render() {
    let {detalhes} = this.props;
    let lengthPis = 0;

    if(detalhes.predecessores != null) {
      lengthPis = detalhes.predecessores.length;
    }
    return (
      <div className={styles.contentFlex}>
        { detalhes.predecessores != null
        ?
          detalhes.predecessores.map((predecessores, i) => {
            let numeroSecundario = predecessores.lote.numero_secundario != null ? predecessores.lote.numero_secundario : '-';
            return(
              <div className={styles.containerLote}>
              
                <div style={{display: 'flex', flexDirection: 'row', marginRight: '1em'}} id={'divPredecessores'+i}>
                  <div className={styles.nomeProduto}>
                    <div className={'flex'}>
                      {detalhes.lote.inconsistente
                        ?
                          <div style={{backgroundColor: '#787878', padding: '3px', marginLeft: '-2px', marginBottom: '2px', marginTop: '-2px'}}>
                            <i className="fa fa-chain-broken" style={{color:'white', marginRight: '-4px'}}></i>
                          </div>
                        :
                          null
                      }
                      <h3 className={styles.tooltip} style={{color:'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}} >
                        {`${predecessores.lote.numero} - ${predecessores.lote.produto.sku} - ${predecessores.lote.produto.nome}`}
                        <span className={styles.toolTipText}>
                          {this.props.t("labels.SKU")}: {predecessores.lote.produto.sku}
                          <br/> 
                          <span style={{color:'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{this.props.t("labels.product")}: {predecessores.lote.produto.nome}</span>
                          <br/> 
                          {`${utils.getReferenciaLoteOrdem()}: ${predecessores.lote.numero}`} 
                          <br/> 
                          { utils.getReferenciaOrdemLote() != '' ?
                            `${this.props.t("labels.order", { ns: 'common' })}: ${numeroSecundario}`
                            :
                            null
                          }
                        </span> 
                      </h3>
                    </div>
                  </div>
                  
                  <div style={{display: 'flex', flexDirection: 'row'}} id={'divInternaPredecessores'+i}>
                    <div style={{display: 'flex', flexDirection: 'row'}} id={'simulateDivPredecessores'+i}>
                    </div>
                    {
                      predecessores.etapas.map((etapa, j) => {     
                        return (
                          <div className={'flex'}>
                            <ListarEtapa detalhe={etapa} />
                            {
                            !(predecessores.etapas.length-1 === j)
                            ?
                              <div className={styles.etapaNext}>
                                <i className={'material-icons ' + styles.detail_divider}>navigate_next</i>
                              </div>
                            :
                              null
                            }
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}} id={'simulateDivLotePai'+i}>
                </div>
                {
                  i+1 === lengthPis ?
                    <ContainerLotePai detalhes={this.props.detalhes}/>
                  :
                    null
                }
                
              </div>
            );
          })
        :
          <ContainerLotePai detalhes={this.props.detalhes}/>
        }
      </div>
    );
  }
}

class ContainerLotePaiExport extends React.Component {
  render() {
    let {detalhes} = this.props;
    let numeroSecundario = detalhes.lote.numero_secundario != null ? detalhes.lote.numero_secundario : '-';

    if(detalhes.predecessores != null) {
      return (
        <div style={{display: 'flex', flexDirection: 'row', marginRight: '1em'}} id={'divLotePai'}>
          <div className={styles.nomeProduto}>
            <div className={'flex'}>
              {detalhes.lote.inconsistente
                ?
                  <div style={{backgroundColor: '#787878', padding: '3px', marginLeft: '-2px', marginBottom: '2px', marginTop: '-2px'}}>
                    <i className="fa fa-chain-broken" style={{color:'white', marginRight: '-4px'}}></i>
                  </div>
                :
                  null
              }
              <h3 className={styles.tooltip} style={{color:'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}} title={detalhes.lote.numero +' - '+ detalhes.lote.produto.sku +' - '+ detalhes.lote.produto.nome}>

                {`${detalhes.lote.numero} - ${detalhes.lote.produto.sku} - ${detalhes.lote.produto.nome}`}
                <span className={styles.toolTipText} style={{color:'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                  {this.props.t("labels.SKU")}: {detalhes.lote.produto.sku}
                  <br/> 
                  {this.props.t("labels.product")}: {detalhes.lote.produto.nome}
                  <br/> 
                  {`${utils.getReferenciaLoteOrdem()}: ${detalhes.lote.numero}`} 
                  <br/> 
                  { utils.getReferenciaOrdemLote() != '' ?
                    `${this.props.t("labels.order", { ns: 'common' })}: ${numeroSecundario}`
                    :
                    null
                  }
                </span> 
              </h3>
            </div>
          </div>
          {
            detalhes.etapas.map((etapa, i) => {     
              return (
                <div className={'flex'}>
                  <ListarEtapa detalhe={etapa} />
                  {
                  !(detalhes.etapas.length-1 === i)
                  ?
                    <div className={styles.etapaNext}>
                      <i className={'material-icons ' + styles.detail_divider}>navigate_next</i>
                    </div>
                  :
                    null
                  }
                </div>
              );
            })
          }
        </div>
      );
    }else{

      return (
        <div className={styles.containerLote} id={'containerLotePai'}>
          <div style={{display: 'flex', flexDirection: 'row', marginRight: '1em'}} id={'divLotePai'}>
            <div className={styles.nomeProduto}>
              <div className={'flex'}>
                {detalhes.lote.inconsistente
                  ?
                    <div style={{backgroundColor: '#787878', padding: '3px', marginLeft: '-2px', marginBottom: '2px', marginTop: '-2px'}}>
                      <i className="fa fa-chain-broken" style={{color:'white', marginRight: '-4px'}}></i>
                    </div>
                  :
                    null
                }
                
                <h3 className={styles.tooltip} style={{color:'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}} >

                  {`${detalhes.lote.numero} - ${detalhes.lote.produto.sku} - ${detalhes.lote.produto.nome}`}

                  <span className={styles.toolTipText} style={{color:'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                    {this.props.t("labels.SKU")}: {detalhes.lote.produto.sku}
                    <br/> 
                    {this.props.t("labels.product")}: {detalhes.lote.produto.nome}
                    <br/> 
                    {`${utils.getReferenciaLoteOrdem()}: ${detalhes.lote.numero}`} 
                    <br/> 
                    { utils.getReferenciaOrdemLote() != '' ?
                      `Ordem: ${numeroSecundario}`
                      :
                      null
                    }
                  </span> 
                </h3>
              </div>
            </div>
    
            {
              detalhes.etapas.map((etapa, i) => {     
                return (
                  <div className={'flex'}>
                    <ListarEtapa detalhe={etapa} />
                    {
                    !(detalhes.etapas.length-1 === i)
                    ?
                      <div className={styles.etapaNext}>
                        <i className={'material-icons ' + styles.detail_divider}>navigate_next</i>
                      </div>
                    :
                      null
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      );
    }
  }
}

export const ContainerLotePai = withTranslation("flowView")(ContainerLotePaiExport);

export default withTranslation("flowView")(Lote);
