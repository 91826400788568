import React, { Component } from 'react';
import { observer } from 'mobx-react';
import listStyles from './list.scss';
import ListTitles from './ListTitles';
import ListStages from './ListStages';
import $ from 'jquery';
import 'jquery-mousewheel';
import _ from 'lodash';
import { request } from '../../common/request';
import { withTranslation } from 'react-i18next';

function getScrollBarWidth() {
  var inner = document.createElement('p');
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement('div');
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);

  var w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';

  var w2 = inner.offsetWidth;

  if (w1 == w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return (w1 - w2);
};

@observer
class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBlur: false,
      arrAcitve: [],
      equipamentosOEE: [],
      carregouOee: false
    };

    this.activeRoute = this.activeRoute.bind(this);
    this.loadOEE = this.loadOEE.bind(this);
  }

  activeRoute(e, data) {
    let equipments = data.id ? this.props.products.filter(product => product.id == data.id)[0].routes.map(route => route.fk_id_equipments) : [];
    this.setState({
      isBlur: !this.state.isBlur,
      arrAcitve: equipments
    });
  }

  async loadOEE() {
    var turnoPersonalizado = false;
    let { dataInicio, dataFim, endpoints } = this.props;

    let equipamentos = [];

    var etapasEquipamentos = this.props.session.stages.map((stage) => stage.equipments);

    for (var i = 0; i < etapasEquipamentos.length; i++) {
      equipamentos = equipamentos.concat(etapasEquipamentos[i].map(equipamento => equipamento.id));
    }

    return request(`${endpoints.OEE}/multiplo/${dataInicio}/${dataFim}/${turnoPersonalizado}?equipamentos=${equipamentos.join('&equipamentos=')}&turnos=${[]}&dataInicioTurno=${0}&dataFimTurno=${0}&incluirDiario=false`, {
      method: 'get',
    })
      .then(response => {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then(data => {
        let equipamentosOEE = [];

        data.forEach(item => {
          let equipamentoOEE = {
            id: item.equipmentId,
            oee: {
              resultado: item.oee.oee.com_setup.resultado
            }
          };

          equipamentosOEE.push(equipamentoOEE);
        });

        this.props.store.inserirEquipamentosOEE(equipamentosOEE);
      });
  }

  componentDidMount() {
    if (this.props.loadingOee) {
      this.loadOEE();
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.loadingOee && !prevProps.loadingOee) {
      this.loadOEE();
    }

    if (this.props.session.id != prevProps.session.id) {

      var SCROLL_WIDTH = getScrollBarWidth();
      var steps = 0;
      var invisbleHeight = 0;

      var scrollBar = $('#scroll_bar');
      var listContainer = $('#list_container');
      var listTitle = $('#list_title');
      var listItems = $('#list_items');
      var fakeContent = $('<div></div>');
      var listItemsWapper = $('#list_items_wrapper');
      var wrapperHeight = listContainer.height() - listTitle.height();
      var listHeight = listItems.height();

      var pageY = 0;
      var tabletY = 0;
      var lastSteps = 0;

      // Reiniciar para 0
      listItemsWapper.animate({ 'scrollTop': '0px' }, 0);
      scrollBar.animate({ 'scrollTop': '0px' }, 0);

      scrollBar.empty();
      scrollBar.append(fakeContent);

      scrollBar.css({
        'overflow-y': 'scroll',
        'height': (wrapperHeight + 25) + 'px',
        'width': SCROLL_WIDTH,
        'display': listHeight <= wrapperHeight ? 'none' : 'block',
      });

      fakeContent.css({
        'height': listHeight + 'px',
        'overflow': 'visible',
      });

      invisbleHeight = listHeight;

      scrollBar.scroll(function () {
        listItemsWapper.animate({ 'scrollTop': scrollBar.scrollTop() + 'px' }, 0);
      });

      $('#list_container').on('touchstart', function (event) {
        tabletY = event.touches[0].pageY;
      });

      $('#list_container').on('touchmove', function (event) {
        steps = lastSteps == 0 ? tabletY - pageY : (tabletY + lastSteps) - pageY;
        steps = Math.max(steps, 0);
        steps = Math.min(steps, invisbleHeight);

        listItemsWapper.animate({ 'scrollTop': steps + 'px' }, 0);
        scrollBar.animate({ 'scrollTop': steps + 'px' }, 0);
        pageY = event.touches[0].pageY;
      });

      $('#list_container').on("touchend", function (event) {
        lastSteps = steps;
      });
    }
  }

  // calculoAltura

  render() {
    const { session, batches, loading, store, sensores } = this.props;

    let { stages } = session;
    let widthViewPort = window.innerWidth;
    let widthList = stages.length * 200;

    if (stages.length === 0) {
      return <div className="loading"><h3>{this.props.t("messages.industrialUnitWithoutLayout")}</h3></div>;
    }

    let totalHeight = 0;

    stages.map(stage => {
      // calculo: qtd. fifos + (altura do elemento = 25) + 77 (altura do equipamento + margens)
      totalHeight += (stage.equipments.fifo * 25) + 77;
    });

    totalHeight += 30; // margem wrapper
    if (loading == true) {
      if (batches.length === 0) {
        return <div className="loading"><h3>{this.props.t("messages.noRoutes")}</h3></div>;
      }
    }

    let innerContent = [];

    stages.map(stage => (innerContent.length < stage.equipments.length) ? innerContent[0] = stage : null);

    return (
      <div className={`${stages.length > 4 ? listStyles.resolutionCheck : ''} ${listStyles.d_mobile_height}`} >
        <div className={`${listStyles.listScrollWrapper} ${listStyles.d_none_mobile}`}>
          <div id="list_container" className={listStyles.list + ' clearfix'}>
            <div id="list_title" style={{ width: widthViewPort < widthList ? widthList + 15 : null }}>
              <ListTitles stages={stages} />
            </div>
            <div style={{ width: widthViewPort < widthList ? widthList + 15 : null }}>
              <div className={listStyles.box_lists} style={{ height: totalHeight }}>
                <div id="list_items_wrapper" className={listStyles.list_wrapper}>
                  <div id="list_items" style={{ height: '100%' }}>
                    {(batches.length > 0) &&
                      <ListStages
                        tipo='desktop'
                        store={store}
                        activeRoute={this.activeRoute}
                        stages={stages}
                        heightStage={totalHeight}
                        batches={this.props.batches}
                        {...this.props}
                        {...this.state}
                      />
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${listStyles.d_block_mobile}`}>
          {(batches.length > 0) &&
            <ListStages
              tipo='mobile'
              store={store}
              activeRoute={this.activeRoute}
              stages={stages}
              {...this.props}
              {...this.state}
            />
          }
        </div>
      </div>
    );
  }
}

export default withTranslation("flowView")(List);