import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class NenhumaInformacao extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div
            className={'flex flex-dir-row flex-just-center'}
            style={{ height: '100%', width: '100%', textAlign: 'center' }}>
            <i style={{ marginRight: '15px', color: 'rgba(0, 0, 0, 0.4)' }} className="material-icons">warning</i>
            <div>
                <h3 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>{this.props.t("customIndicator.noData", { ns: "components" })}</h3>
            </div>
            </div>
        );
    }
}

export default withTranslation("components")(NenhumaInformacao);
