import React, { Component } from 'react';
import style from './styles.scss';
import { request } from '../../common/request';
import { APIEndpoints } from 'common/Constants';
import { url } from 'common/helpers';
import { alertBox } from 'common/NForm';
import i18n from 'utils/i18n';

export class MovideskTooltip extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            finalLink: undefined,
            modulo: window.location.pathname.replace('/', ''),
            showTooltip: true,
            idFromModulo: undefined,
        };
    }

    componentDidMount() {
        if (!this.props.modulo) {
            this.getModulo();
        }

        if (this.state.showTooltip) {
            setTimeout(() => {
                this.setState({
                    showTooltip: false
                })
            }, 3000)
        }
    }

    getValidModulo = () => {
        const moduloProps = this.props.modulo;
        const moduloState = this.state.modulo;

        return typeof (moduloProps) !== 'string' ? moduloState : moduloProps;
    }

    buildLink = async () => {
        return await request(url(APIEndpoints.MOVIDESK_REDIRECT, { id: this.state.idFromModulo }), {
            method: 'GET'
        })
            .then(async res => {
                if (res.ok) {
                    const link = await res.text();
                    return link;
                }
            },
                error => {
                    console.log(error);
                });
    }

    getModulo = async () => {
        await request(url(APIEndpoints.MOVIDESK_MODULO, { modulo: (this.getValidModulo()) }), {
            method: 'GET'
        })
            .then(async res => {
                if (res.ok) {
                    const modulo = await res.json();
                    this.setState({ idFromModulo: modulo.id });
                }
            },
                error => {
                    console.log(error);
                });
    }

    openNewTab = async () => {
        var { finalLink } = this.state;
        const link = await this.buildLink();

        if (finalLink !== undefined) {
            window.open(finalLink);
        }
        else if (link !== undefined) {
            this.setState({ finalLink: link });
            window.open(link);
        } else {
            return alertBox(i18n.t('messages.moviDeskError', { ns: 'common' }), i18n.t('lables.error', { ns: 'common' }), 'error');
        }
    }

    render() {
        return (
            <div
                className={style.movideskTip}
                onClick={() => this.openNewTab()}
                style={this.props.style}>
                <svg className={style.movideskIcon}></svg>
                <div className={style.tooltip}
                    style={!this.state.showTooltip ?
                        {
                            visibility: 'hidden',
                            opacity: 0,
                            transition: 'visibility 0s 2s, opacity 2s linear'
                        } : {}}>
                    {i18n.t('messages.accessKB', { ns: 'common' })}</div>
            </div>
        );
    }
}

export default MovideskTooltip;