import React, { useCallback, useEffect, useState } from 'react';
import { RaisedButton } from 'material-ui';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { request } from 'common/request';
import { alertBox } from 'common/NForm';
import { APIEndpoints } from 'common/Constants';
import MDSpinner from 'react-md-spinner';
import styles from './updatePasswordModal.scss';

const UpdatePasswordModal = ({
    uuid,
    strongPassword,
    currentUser,
    closeModal,
    onSuccess
}) => {
    const { t } = useTranslation('user');
    const [user, setUser] = useState({});

    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(true);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        setLoading(true);

        loadUser(uuid).then((response) => {
            setUser(response);
        }).finally(() => setLoading(false))
    }, []);


    const loadUser = useCallback((uuid) => {
        const endpoint = currentUser ? '/current' : `/${uuid}`;

        return request(APIEndpoints.SGCA_USUARIO + endpoint, { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { return data });
    }, [])

    const handleSubmit = () => {
        let payload = {
            senha: password,
            confirmarSenha: confirmPassword,
            senha_forte: strongPassword
        }

        setDisabled(true);

        const endpoint = currentUser ? '/current/password' : `/senha/${user.uuid}`;

        request(APIEndpoints.SGCA_USUARIO + endpoint, {
            method: 'put',
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === 200) {
                return Promise.resolve();
            }
            return Promise.reject(response);
        }).then(() => {
            alertBox(t("messages.alterPasswordSuccess"), t("title"), 'success');
            if (onSuccess) onSuccess();
            handleCloseModal();
        }).catch(error => {
            alertBox(t("errors.alterPasswordError"), t("title"), 'error');
        }).finally(() => setDisabled(false));
    }

    const handleCloseModal = () => {
        closeModal ? closeModal() : null;
    }

    return <>
        {loading ?
            <div className="modal">
                <div className={'loading-wrapper'} >
                    <MDSpinner singleColor='#2D4F7F' size={60} />
                </div>
            </div>
            :
            <div className="modal">
                <div className={'flex-align-center'} style={{ padding: '10px', fontWeight: 'bold' }}>{t("form.password.title")}</div>
                {
                    !currentUser ?
                        <div style={{ textAlign: 'center' }}>
                            {user.nome}
                        </div>
                        :
                        null
                }
                {currentUser && user.senhaExpirada ?
                    <div className={styles.passwordAlert}>
                        {t("messages.expiredPassword", { ns: 'common' })}
                    </div>
                    : null}

                <div className={styles.form}>
                    <PasswordField
                        defaultValue={user.senha}
                        onChangePassword={(value) => setPassword(value)}
                        onChangeConfirmPassword={(value) => setConfirmPassword(value)}
                        passwordLabel={t("form.password.labels.newPassword")}
                        passwordConfirmLabel={t("form.password.labels.confirmNewPassword")}
                        usernameRule={user.username}
                        onValidPassword={() => setDisabled(false)}
                        onInvalidPassword={() => setDisabled(true)}
                        strongPassword={user.senha_forte}
                    />
                </div>
                <div className={'flex flex-just-end'} style={{ paddingTop: '1em' }} >
                    <RaisedButton disabled={disabled} onClick={handleSubmit} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} labelColor='#FFF' label={t("labels.save", { ns: "common" })} />
                    {currentUser && user.senhaExpirada ? null : <RaisedButton onClick={handleCloseModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ marginLeft: '1em' }} label={t("labels.cancel", { ns: 'common' })} />}
                </div>
            </div >
        }
    </>
};

export default UpdatePasswordModal;