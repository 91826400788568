import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import CustomLegends from './../CustomLegends/CustomLegends';
import MetaMediaMovelChartCss from '../../../assets/styles/painel-fluxo/MetaMediaMovelChart.css';
import FlexBoxGridCss from './../../../assets/styles/painel-fluxo/FlexboxGrid.scss';
import * as _ from 'lodash';
import moment from 'moment';
import style from './chart.scss';
import NenhumaInformacao from '../../../utils/NenhumaInformacao'
import { withTranslation } from 'react-i18next';

const legendOpts = {
	display: false
};	

class MetaMediaMovelChart extends Component {
	constructor(props) {
		super(props);
		this.chartRef = null;
		this.state = {
			width: 0
		};
	}

	formatOptions(maxY) {
		let maxYTick = parseInt(maxY/10) * 10 + 10;
		return {
			responsive: false,
			maintainAspectRatio: true,
			layout: {
				padding: {
					left: 5,
					right: 0,
					top: 0,
					bottom: 0
				}
			},
			scales: {
				yAxes: [{
					scaleLabel: {
						display: false
					},
					ticks: {
						min: 0,
						max: maxYTick,
						stepSize: maxYTick/3
					},
					gridLines: {
						display: false
					}
				}],
				xAxes: [{
					gridLines: {
						display: false,
					},
					scaleLabel: {
						display: false,
					}
				}]
			},
			tooltips: {
				titleFontSize: 16,
				bodyFontSize: 14,
				displayColors: false,
				yAlign: 'center',
				mode: 'index',
				intersect: false,
				backgroundColor: 'black'
			}
		}
	}

	formatData(data) {
		if (!data) return {};
		let meta = data.meta;
		let mediaMovel = (data.dados && data.dados.length > 0) ? _.map(data.dados, (data) => data.media_movel) : [];
		let labels = (data.dados && data.dados.length > 0) ? _.map(data.dados, (data) => moment(data.data).locale('pt-br').format('DD/MM') ) : [];
		meta = _.map(labels, () => meta);
		return {
			labels: labels,
			datasets: [
				{
					label: this.props.t("labels.movingAverage"),
					fill: false,
					backgroundColor: 'rgb(12, 67, 152)',
					borderColor: 'rgb(12, 67, 152)',
					borderCapStyle: 'butt',
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgb(2, 27, 102)',
					pointBackgroundColor: 'rgb(2, 27, 102)',
					pointHoverBackgroundColor: 'rgb(2, 27, 102)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointRadius: 3,
					pointHitRadius: 10,
					borderWidth:0,
					data: mediaMovel
				},
				{
					label: this.props.t("labels.goal"),
					fill: false,
					backgroundColor: '#E65100',
					borderColor: '#E65100',
					borderCapStyle: 'butt',
					borderJoinStyle: 'miter',
					pointBorderColor: '#E65100',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverBackgroundColor: '#BF360C',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointRadius: 0,
					data: meta
				}
			]
		}
	}

	componentDidMount() { 
		this.setState({
			width: this.chartMediaMovelRef.offsetWidth - 25
		});
	}

	render() {
		let valoresZerados = this.props.data.dados.filter(r => r.media_movel != 0);
		let maxY = _.maxBy(this.props.data.dados, (d) => d.media_movel);
		let options = this.formatOptions(maxY.media_movel);
		let data = this.formatData(this.props.data);
		let _legends = CustomLegends(this.chartRef ? this.chartRef.chartInstance : null, null, data);
		let hideChart = !this.props.data || this.props.data.meta < 0;
		return (
			valoresZerados && valoresZerados.length == 0
			?
				<div id={this.props.id} className={`${style.mobile_flex_column} flex-dir-row flex`}  style={{width:'100%', height: '100%'}} ref={chartMediaMovelRef => { this.chartMediaMovelRef = chartMediaMovelRef }}>
					<NenhumaInformacao/>
				</div>
			:
			<div id={this.props.id} className={`${style.mobile_flex_column} flex-dir-row flex`}  style={{width:'100%', height: '100%'}}>
				<div style={{ height: '100%', width: '100%', alignItems:'center', color: '#B4B4B4', display: hideChart ? 'flex' : 'none' }} className={'flex flex-dir-row flex-align-center'}>
					<i style={{ marginRight: '15px' }} className="material-icons">warning</i>
					<div>
						<h3>{this.props.t("customIndicator.noData", { ns: 'components' })}</h3>
					</div>
				</div>
				<div id='chartMediaMovel' className="col-xs-10 flex-dir-col flex" style={{ display: !hideChart ? 'flex' : 'none', width:'100%', padding: '0 5px'}} ref={chartMediaMovelRef => { this.chartMediaMovelRef = chartMediaMovelRef } }>
					<div className="col-xs-12 flex-dir-row" style={{ position: 'relative',}}>
						<span style={{ left: 0, top: '60%', position: 'absolute', fontSize: '13px', fontWeight: 'bold', transform: 'rotate(270deg)', transformOrigin: 'left top 0', textTransform: 'capitalize' }}>
							{`${this.props.t("labels.hours", { ns: 'common' })} >`}
						</span>
						{
							this.state.width > 0
							?
								<Line height={100} width={this.state.width} style={{padding: '0 80px'}} ref={(chart) => { this.chartRef = chart; }} data={data} legend={legendOpts} options={options} />
							:
								null
						}
					</div>
				</div>
				<div className={`custom-legend-container ${style.d_none_mobile} ${style.tablet_left} flex`} style={{ display: !hideChart ? 'flex' : 'none', marginLeft: '-5px' }}>
					<div className={`${style.d_none_mobile} `}>
					{_legends}
					</div>
				</div>
				<div className={`row ${style.d_block_mobile}`}>
					<div className={`col-xs-12 ${style.mobile_flex_chart}`}>
					{_legends}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("flowDashboard")(MetaMediaMovelChart);
