import { request } from "../../common/request";
import Notifier from "../../utils/Notifier";

// Classe generica para funcoes que serão usadas em varios locais
// Obs: Realizar uma melhoria nessa classe separar as funções internas em outras classes e realizar um factory para retornar as mesmas
class Utils {
    
    submit(values, method, url, header, callBack) {
        request(`${url}`, {
            method: method,
            headers: header,
            body: JSON.stringify(values)
        }).then(response => {
            if (response.status === 200) return response.json()
        }).then(callBack);
    }

    showAlert(msg, title, type) {
        switch (type) {
            case 'error':
                Notifier.error(msg || 'Oops!', title || 'Notificação');
                break;
            case 'warning':
                Notifier.warning(msg || 'Oops!', title || 'Notificação');
                break;
            case 'info':
                Notifier.info(msg || 'Oops!', title || 'Notificação');
                break;
            default:
                Notifier.success(msg || 'Oops!', title || 'Notificação');
        }
    }
}


export default Utils