import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@observer
class ShiftsList extends Component {

  render() {
    let items = this.props.store.shifts.map((item, k) => {
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.name}</td>
          <td>{item.start_time}</td>
          <td>{item.end_time}</td>
          <td>{item.dia_start}</td>
          <td>{item.dia_end}</td>
          <td>
            <div className='options'>
              <i className='material-icons' title={this.props.t('list.actions.edit', { ns: 'common' })} onClick={() => this.props.handleModal(item, 'up')}>edit</i>
              <i className='material-icons' title={this.props.t('list.actions.delete', { ns: 'common' })} onClick={() => hasRole('ROLE_TURNOS_EXCLUIR') && this.props.handleModal(item, 'del')}>cancel</i>
            </div>
          </td>
        </tr>
      )
    });

    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_TURNOS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('title')} />
            <CardText className='table_overflow'>
              <table className='mui-table mui-table--bordered'>
                <thead>
                  <tr>
                    <td className='id'>#</td>
                    <td className='name'>{this.props.t('list.columns.name')}</td>
                    <td className='start'>{this.props.t('list.columns.startTime')}</td>
                    <td className='finish'>{this.props.t('list.columns.endTime')}</td>
                    <td className='start_day'>{this.props.t('list.columns.startDay')}</td>
                    <td className='finish_day'>{this.props.t('list.columns.endDay')}</td>
                    <td className='actions'>{this.props.t('list.columns.action', { ns: 'common', count: 2 })}</td>
                  </tr>
                </thead>
                <tbody className='box_items'>
                  {items}
                </tbody>
              </table>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_TURNOS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('title')} />
            <CardText>
              <div style={{marginTop: 30}}>
                <h1>{this.props.t('list.empty', { ns: 'common' })}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}

export default withTranslation("shifts")(ShiftsList);