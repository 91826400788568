import React, { Component } from 'react';
import { observer } from 'mobx-react';
import listStyles from './list.scss';
import StageList from './StageList';
import mobileStyle from './ListMobile.css';

@observer
export default class ListStages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipamentos: [],
      listWaiting: [],
      listProcess: [],
      listWarning: []
    }

    this.calculateLists = this.calculateLists.bind(this);
  }

  componentDidMount() {
    this.calculateLists();
  }

  componentDidUpdate(prevProps) {
    let { batches } = this.props;

    if (batches !== prevProps.batches) {
      this.calculateLists();
    }
  }

  calculateLists = () => {
    const { batches, session } = this.props;

    let listProcess = [], listWaiting = [], listWarning = [];

    batches.map(batch => {
      if (batch.batches && batch.batches.length) {
        let isSession = batch.id == session.id;

        batch.batches.map(b => {
          b.listProcess.map(bp => { bp.isSession = isSession; listProcess.push(bp) });
          b.listWaiting.map(bp => { bp.isSession = isSession; listWaiting.push(bp) });
          b.listWarning.map(bp => { bp.isSession = isSession; listWarning.push(bp) });
        })
      }
    });

    this.setState({
      listWaiting: listWaiting,
      listProcess: listProcess,
      listWarning: listWarning
    })
  }

  render() {
    const { store, tipo, ...rest } = this.props;
    const { listProcess, listWaiting, listWarning } = this.state;

    return (
      <div>
        {tipo === 'mobile' ?
          <div className={mobileStyle.card__flex}>
            {this.props.stages.map(stage =>
              <div key={stage.id} className={mobileStyle.card}>
                <h1 className={mobileStyle.card__title}>{stage.name}</h1>
                <div className={mobileStyle.card__container}>
                  <StageList
                    stage={stage}
                    store={store}
                    tipo={tipo}
                    listProcess={listProcess.filter(batch => batch.fk_id_stages == stage.id)}
                    listWaiting={listWaiting.filter(batch => batch.fk_id_stages == stage.id)}
                    listWarning={listWarning}
                    {...rest}
                  />
                </div>
              </div>
            )}
          </div>
          :
          <ul className={`${listStyles.list_stages} ${listStyles.full_height_content}`}>
            {
              this.props.stages.map(stage =>
                <li key={stage.id}>
                  <StageList
                    stage={stage}
                    store={store}
                    tipo={tipo}
                    listProcess={listProcess}
                    listWaiting={listWaiting}
                    listWarning={listWarning}
                    {...rest}
                  />
                </li>
              )
            }
          </ul>
        }
      </div>
    );
  }
}