import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styles from './route.scss';
import ListNameProduct from './ListNameProduct';
import RoutesStages from './RoutesStages';
import FilterBox from './FilterBox';

@observer
export default class RoutesBox extends Component {
  constructor() {
    super();
    this.state = {
      metrics: {},
      scrollTop: 0
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.styleProductsName = this.styleProductsName.bind(this);
  }

  styleProductsName(margin, arrHeight) {
    let metrics = {
      marginTop: margin,
      arrHeight: arrHeight
    };
    this.setState({
      metrics: metrics
    });
  }

  handleScroll(scrollTop) {
    this.setState({
      scrollTop: scrollTop
    });
  }

  render() {
    return (
      <div className={styles.container_list_route}>
        <FilterBox {...this.props} />
        {
          this.props.session ?
            <div className={styles.list_box}>
              <ListNameProduct {...this.state} {...this.props} />
              <RoutesStages {...this.props} styleProductsName={this.styleProductsName} handleScroll={this.handleScroll} />
            </div>
          : null
        }
      </div>
    );
  }
}