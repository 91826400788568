import _ from 'lodash';

class MaskFieldTime {
  Mask (value) {
    let invalid = false;

    if (value.includes(":")){
      let test = value.split(":");
      invalid = (isNaN(test[0]) || isNaN(test[1]));
    } else {
      invalid = isNaN(value);
    }

    if (!invalid){
      let v = value.replace(/\D/g,"");
      if(v.length === 1) {
        v="000:0"+v;
      } else {
        v=v.replace(/(\d{4})(\d)/,"$1:$2");
        v=v.replace(/(\d{0})(\d)/,"$1");
      }

      return v;
    }

    return '';
  }

  MaskInterval (value,origem) {
    if(typeof origem != 'undefined'){
      return _.padStart(value.replace(/[\D\:]/g, ''), 6, '0')
        .substr(-6, 6)
        .replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3');
    }

    return _.padStart(value.replace(/[\D\:]/g, ''), 5, '0')
      .substr(-5, 5)
      .replace(/(\d{3})(\d{2})/, '$1:$2');

    
  }

  MaskHoraMinutoSegundo (value) {
    let invalid = false;
    if (value.includes(":")){
      let test = value.split(":");
      invalid = (isNaN(test[0]) || isNaN(test[1]));
    } else {
      invalid = isNaN(value);
    }

    if (!invalid){
      let v = value.replace(/\D/g,"");
      if(v.length === 1) {
        v="00:00:0"+v;
      } else {
        v=v.replace(/(\d{5})(\d)/,"$1:$2");
        v=v.replace(/(\d{3})(\d)/,"$1:$2");
        v=v.replace(/(\d{0})(\d)/,"$1");
      }
      
      return v;
    }

    return '';
  }
};

export default new MaskFieldTime();
