import React, { Component } from 'react';
import { NButton } from '../../../NForm';
import { observer, inject } from 'mobx-react';


@inject('store')
@observer
class DeleteHoldingTime extends Component{
    constructor(props){
        super(props);
        this.alertDeleteHoldingTime = this.props.store.alertDeleteHoldingTime;
        this.modalStore = this.props.store.modalCommon;
    }

    componentDidMount() {
        this.props.store.modalStepOne.setRender(false)
    }

    render(){
        return(
            <div>
                <NButton type="primary" onClick={() => {
                    this.props.saveRoutes(null, true)
                    this.props.hideModal()
                    this.modalStore.close()
                    }}>
                    Sim
                </NButton>
                <NButton onClick={() => {
                    this.alertDeleteHoldingTime.No()
                    this.modalStore.close();
                    }}>
                    Não
                </NButton>
            </div>
        )
    }
}

export default DeleteHoldingTime