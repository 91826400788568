import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { APIEndpoints } from '../../common/Constants';
import {
  AutoComplete, Toggle, MenuItem,
  SelectField, Card, RaisedButton
} from 'material-ui';
import Container from '../../common/Container';
import SemPermissao from '../../paginaErro/SemPermissao';
import { request } from '../../common/request';
import CardIndicator from './CardIndicator/CardIndicator';
import CustomCard from './CustomCard/CustomCard';
import MetaMediaMovelChart from './MetaMediaMovelChart/MetaMediaMovelChart';
import RealMediaMovelMetaChart from './RealMediaMovelMetaChart/RealMediaMovelMetaChart';
import HoldingTimeChart from './HoldingTimeChart/HoldingTimeChart';
import FluxoProcessoChart from './FluxoProcessoChart/FluxoProcessoChart';
import PainelFluxoCss from '../../assets/styles/painel-fluxo/PainelFluxo.scss';
import { Chart } from 'react-chartjs-2';
import ChartHelpers from './Helpers/ChartJsHelper';
import style from './responsive.scss';
import { respostaUnidadeIndustrial } from '../../common/mappers';
import Store from '../../common/Store';
import MDSpinner from "react-md-spinner";
import { IF } from '../../common/NForm';
import Utils from '../../utils/Utils';
import i18n from 'utils/i18n';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

ChartHelpers(Chart);
var time = '';

if (Store.getUser() != null) {
  request(APIEndpoints.CONFIGURACAO_PARAMETROS, {
    method: 'get'
  }).then(response => {
    if (response.status === 200) {
      return response.json()
    }
  }).then(data => {
    if (data != null) {
      data.map(d => {
        localStorage.setItem(d.chave, d.valor);

        if (d.chave === 'Language') {
          i18n.changeLanguage(d.valor);
          moment.locale(d.valor);
        }
      });
    }
  });
}

@observer
class PainelFluxo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mtbf: null,
      mtbfMobile: null,
      mttr: null,
      mttrMobile: null,
      wip: null,
      wipMobile: null,
      txProd: null,
      txProdMobile: null,
      leadtime: null,
      leadtimeMobile: null,
      indicadores: [],
      holdingTime: null,
      holdingTimeTotal: null,
      holdingTimeTitle: 'labels.period',
      holdingTimePeriodo: null,
      unidadesIndustriais: [],
      unidadeIndustrialId: null,
      unidadeIndustrialText: '',
      dadosPainelFluxo: '',
      periodo: '',
      inconsistentesPeriodo: 0,
      atualizarAutomaticamente: false,
      fluxoProcesso: null,
      visibility: 'hidden',
      erroUnidadeIndustrial: false,
      erroPeriodo: false,
      autorizacao: true,
      loadingPage: true,
      lotesAtrasados: {
        header: Utils.getReferenciaLotesOrdensAtrasadas(),
        total: null,
        indicador: null,
        percent: null,
        load: ''
      },
      lotesInconsistentes: {
        total: null,
        indicador: null,
        percent: null,
        load: ''
      },
      equipmantentoManut: {
        total: null,
        indicador: null,
        percent: null,
        load: ''
      },
      tempoMedioSetup: {
        total: null,
        indicador: null,
        percent: null,
        load: '',
        tipo: 'horas'
      },
      riscoHoldingTime: {
        total: null,
        indicador: null,
        percent: null,
        load: ''
      },
      fluxoProcessoChart: false,
      fluxoProcessoLoad: true,
      mtbfChart: false,
      mtbfLoad: true,
      mttrChart: false,
      mttrLoad: true,
      leadtimeChart: false,
      leadtimeLoad: true,
      wipChart: false,
      wipLoad: true,
      taxaProdChart: false,
      taxaProdLoad: true,
      lotesAtrasadosIndicator: false,
      lotesAtrasadosFooter: false,
      lotesAtrasadosLoad: true,
      lotesInconsistentesIndicator: false,
      lotesInconsistentesFooter: false,
      lotesInconsistentesLoad: true,
      equipmantentoManutIndicator: false,
      equipmantentoManutFooter: false,
      equipmantentoManutLoad: true,
      tempoMedioSetupIndicator: false,
      tempoMedioSetupFooter: false,
      tempoMedioSetupLoad: true,
      riscoHoldingTimeIndicator: false,
      riscoHoldingTimeFooter: false,
      riscoHoldingTimeLoad: true,
      holdingTimeChart: false,
      holdingTimeLoad: true,
      switchHoldingTimePeriodo: true
    };

    this.endpoints = APIEndpoints;
    this.store = Store;
    this.histogramaHoldingTime = this.histogramaHoldingTime.bind(this);
  }

  generateChartMobile(arry, type) {
    let data = 0;

    switch (arry.dados.length) {
      case 5:
        this.setState({ [type]: arry });
        break;
      case 15:
        data = { meta: arry.meta, dados: this.calcChartPointsMobile(3, arry.dados) }
        this.setState({ [type]: data })
        break;
      case 30:
        data = { meta: arry.meta, dados: this.calcChartPointsMobile(6, arry.dados) }
        this.setState({ [type]: data })
        break;
      case 45:
        data = { meta: arry.meta, dados: this.calcChartPointsMobile(9, arry.dados) }
        this.setState({ [type]: data })
        break;
      case 90:
        data = { meta: arry.meta, dados: this.calcChartPointsMobile(18, arry.dados) }
        this.setState({ [type]: data })
        break;
    }
  }

  calcChartPointsMobile(interval, data) {
    const newArray = [];

    data.forEach((value, index) => {
      if ((index === 0) || (index % interval === 0)) {
        newArray.push(value)
      }
    });

    return newArray;
  }

  componentDidMount() {
    request(this.endpoints.SGCA_AUTENTICAR + '/validar?modulo=painel-de-fluxo', {
      method: 'get'
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            loadingPage: false,
          });

          return Promise.resolve();
        } else if (response.status == 401) {
          this.setState({
            autorizacao: false,
            loadingPage: false,
          });
        }
      })
      .then(() => {
        this.getUnidadeIndustrial();
      });
  }

  getUnidadeIndustrial() {
    request(this.endpoints.UNIDADE_INDUSTRIAL, {
      method: 'get'
    }, true)
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        if (data != null) {
          this.setState({
            unidadesIndustriais: respostaUnidadeIndustrial(data)
          });
        } else {
          this.setState({
            unidadesIndustriais: []
          });
        }
      });
  }

  setUnidadeIndustrial(nomeUnidade, allUnd, value) {
    let obj = allUnd.find(i => i.text === nomeUnidade);

    if (obj !== undefined) {
      this.setState({
        unidadeIndustrialId: obj.value,
        unidadeIndustrialText: obj.text
      });
    } else {
      this.setState({
        unidadeIndustrialId: null,
        unidadeIndustrialText: ''
      });
    }
  }

  requisicaoGrafico(url, campoState, idLoad, done, then) {
    request(url, {
      method: 'get'
    }, true)
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        if (data != null) {
          switch (campoState) {
            case 'mtbf':
              this.generateChartMobile(data, 'mtbfMobile');
              break;
            case 'mttr':
              this.generateChartMobile(data, 'mttrMobile');
              break;
            case 'leadtime':
              if (!this.state.leadtimeMobile) {
                this.generateChartMobile(data, 'leadtimeMobile');
              }
              break;
            case 'wip':
              if (!this.state.wipMobile) {
                this.generateChartMobile(data, 'wipMobile');
              }
              break;
            case 'txProd':
              if (!this.state.txProdMobile) {
                this.generateChartMobile(data, 'txProdMobile');
              }
              break;
          }

          this.setState({
            [campoState]: data,
            [idLoad + 'Chart']: true,
            [idLoad + 'Load']: false
          }, () => {
            if (done) done();
          });
        } else {
          this.setState({
            [campoState]: null
          });
        }
      });
  }

  requisicaoIndicadores(url, campoState, idLoad) {
    this.state[campoState].load = '';

    this.setState({
      [campoState]: this.state[campoState]
    });

    request(url, {
      method: 'get'
    }, true)
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        if (data != null) {
          this.state[campoState].total = data.total;
          this.state[campoState].percent = data.porcentagem;
          this.state[campoState].indicador = data.indicador;

          this.setState({
            [campoState]: this.state[campoState],
            [idLoad + 'Indicator']: true,
            [idLoad + 'Footer']: true,
            [idLoad + 'Load']: false
          });
        } else {
          this.state[campoState].load = 'badrequest';
          this.setState({
            [campoState]: this.state[campoState],
            [idLoad + 'Load']: false
          });
        }
      });
  }

  requisicaoLotesInconsistentesPeriodo(url) {
    request(url, {
      method: 'get'
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        if (data !== null) {
          this.setState({
            inconsistentesPeriodo: data.total
          });
        } else {
          this.setState({
            inconsistentesPeriodo: 0
          });
        }
      });
  }

  atualizarAutomatico() {
    if (!this.state.atualizarAutomaticamente) {
      this.setState({
        atualizarAutomaticamente: true
      });
      time = setInterval(() => this.gerarPainelFluxo(), 300000);
    } else {
      this.setState({
        atualizarAutomaticamente: false
      });

      clearInterval(time);
    }
  }

  gerarPainelFluxo() {
    if (this.state.unidadeIndustrialId != null && this.state.periodo !== '') {
      this.setState({
        erroUnidadeIndustrial: false,
        erroPeriodo: false,
        visibility: 'visible',
        fluxoProcessoChart: false,
        fluxoProcessoLoad: true,
        mtbfChart: false,
        mtbfLoad: true,
        mttrChart: false,
        mttrLoad: true,
        leadtimeChart: false,
        leadtimeLoad: true,
        wipChart: false,
        wipLoad: true,
        taxaProdChart: false,
        taxaProdLoad: true,
        lotesAtrasadosIndicator: false,
        lotesAtrasadosFooter: false,
        lotesAtrasadosLoad: true,
        lotesInconsistentesIndicator: false,
        lotesInconsistentesFooter: false,
        lotesInconsistentesLoad: true,
        equipmantentoManutIndicator: false,
        equipmantentoManutFooter: false,
        equipmantentoManutLoad: true,
        tempoMedioSetupIndicator: false,
        tempoMedioSetupFooter: false,
        tempoMedioSetupLoad: true,
        riscoHoldingTimeIndicator: false,
        riscoHoldingTimeFooter: false,
        riscoHoldingTimeLoad: true,
        holdingTimeChart: false,
        holdingTimeLoad: true,
        holdingTimeTitle: 'labels.period',
      });

      this.requisicaoGrafico(this.endpoints.UNIDADE_INDUSTRIAL + '/relacao-fluxo-processo/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'fluxoProcesso', 'fluxoProcesso');
      this.requisicaoGrafico(this.endpoints.UNIDADE_INDUSTRIAL + '/mtbf/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'mtbf', 'mtbf')
      this.requisicaoGrafico(this.endpoints.UNIDADE_INDUSTRIAL + '/mttr/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'mttr', 'mttr');
      this.requisicaoGrafico(this.endpoints.UNIDADE_INDUSTRIAL + '/lead-time/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'leadtime', 'leadtime');
      this.requisicaoGrafico(this.endpoints.UNIDADE_INDUSTRIAL + '/wip/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'wip', 'wip');
      this.requisicaoGrafico(this.endpoints.UNIDADE_INDUSTRIAL + '/taxa-producao/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'txProd', 'taxaProd');
      this.requisicaoIndicadores(this.endpoints.UNIDADE_INDUSTRIAL + '/lote/atrasado/' + this.state.unidadeIndustrialId, 'lotesAtrasados', 'lotesAtrasados');
      this.requisicaoIndicadores(this.endpoints.LOTES + '/inconsistente/' + this.state.unidadeIndustrialId, 'lotesInconsistentes', 'lotesInconsistentes');
      this.requisicaoIndicadores(this.endpoints.EQUIPAMENTOS + '/manutencao/' + this.state.unidadeIndustrialId, 'equipmantentoManut', 'equipmantentoManut');
      this.requisicaoIndicadores(this.endpoints.UNIDADE_INDUSTRIAL + '/setup/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'tempoMedioSetup', 'tempoMedioSetup');
      this.requisicaoIndicadores(this.endpoints.HOLDING_TIME + '/risco/' + this.state.unidadeIndustrialId, 'riscoHoldingTime', 'riscoHoldingTime');
      this.requisicaoLotesInconsistentesPeriodo(this.endpoints.LOTES + '/inconsistente/periodo/' + this.state.unidadeIndustrialId + '/' + this.state.periodo);
      this.requisicaoGrafico(this.endpoints.HOLDING_TIME + '/histograma/' + this.state.unidadeIndustrialId, 'holdingTimeTotal', 'holdingTimeTotal');
      this.requisicaoGrafico(this.endpoints.HOLDING_TIME + '/histograma/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'holdingTimePeriodo', 'holdingTimePeriodo');
      this.requisicaoGrafico(this.endpoints.HOLDING_TIME + '/histograma/' + this.state.unidadeIndustrialId + '/' + this.state.periodo, 'holdingTime', 'holdingTime');
    } else {
      if (this.state.unidadeIndustrialId == null && this.state.periodo === '') {
        this.setState({
          erroUnidadeIndustrial: true,
          erroPeriodo: true
        });
      } else if (this.state.unidadeIndustrialId == null) {
        this.setState({
          erroUnidadeIndustrial: true
        });
      } else if (this.state.periodo === '') {
        this.setState({
          erroPeriodo: true
        });
      } else {
        this.setState({
          erroUnidadeIndustrial: false,
          erroPeriodo: false
        });
      }
    }
  }

  histogramaHoldingTime() {
    const { holdingTimeTitle, holdingTimeTotal, holdingTimePeriodo } = this.state;

    let periodo = (holdingTimeTitle === 'labels.period');

    this.setState({
      holdingTimeTitle: (periodo ? 'labels.current' : 'labels.period'),
      holdingTime: (periodo ? holdingTimeTotal : holdingTimePeriodo)
    });
  }

  render() {
    var opcoesUnidadeIndustrial = [];

    for (let i = 0; i < this.state.unidadesIndustriais.length; i++) {
      if (this.state.unidadesIndustriais[i] !== undefined) {
        opcoesUnidadeIndustrial.push({ text: this.state.unidadesIndustriais[i].name, value: this.state.unidadesIndustriais[i].id });
      }
    }

    return (
      <div>
        <IF test={this.state.loadingPage}>
          <Container sectionStyle={{ height: '94vh' }}>
            <div className={'loading-wrapper'}>
              <MDSpinner singleColor='#2D4F7F' size={60} />
            </div>
          </Container>
        </IF>
        <IF test={!this.state.loadingPage}>
          <div>
            <IF test={this.state.autorizacao}>
              <div>
                <Container style={{ marginTop: 0 }}>
                  <Card>
                    <div className={`row ${style.d_none_mobile}`} style={{ padding: '0 50px', width: '100%' }}>
                      <div className={`row ${style.tablet_size} flex-just-start flex-align-center`} style={{ width: '60%' }}>
                        <div>
                          <AutoComplete
                            textFieldStyle={{ marginTop: '-10px', width: '254px' }}
                            ref='unidadeIndustrial'
                            id='unidadeIndustrial'
                            fullWidth={true}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelStyle={{ color: '#5B5B5B' }}
                            floatingLabelText={this.props.t("labels.industrialUnit")}
                            title={this.props.t("labels.industrialUnit")}
                            onUpdateInput={this.setUnidadeIndustrial.bind(this)}
                            filter={AutoComplete.fuzzyFilter}
                            dataSource={opcoesUnidadeIndustrial !== [] ? opcoesUnidadeIndustrial : null}
                            onClick={() => this.setState({ unidadeIndustrialText: '' })}
                            openOnFocus={true}
                          />
                          {this.state.erroUnidadeIndustrial ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '-1' }} ref="validate">{this.props.t("messages.requiredField", { ns: 'common' })}</div> : ''}
                        </div>
                        <div style={{ marginLeft: '15px', marginTop: '-10px' }}>
                          <SelectField
                            hintText={this.props.t("labels.period")}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelText={this.props.t("labels.period")}
                            title={this.props.t("labels.period")}
                            floatingLabelStyle={{ color: '#5B5B5B' }}
                            value={this.state.periodo}
                            onChange={(...props) => this.setState({ periodo: props[2] })}>
                            <MenuItem value={5} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 5 })} />
                            <MenuItem value={15} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 15 })} />
                            <MenuItem value={30} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 30 })} />
                            <MenuItem value={45} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 45 })} />
                            <MenuItem value={90} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 90 })} />
                          </SelectField>
                          {this.state.erroPeriodo ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '-1' }} ref="validate">{this.props.t("messages.requiredField", { ns: 'common' })}</div> : ''}
                        </div>
                        <RaisedButton onClick={() => this.gerarPainelFluxo()} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ margin: '1em 2em' }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
                      </div>
                      <div className="row flex-just-end flex-align-center" style={{ width: '40%' }}>
                        <div className={`${style.d_none_tablet} inline-flex flex-just-end`}>
                          <div>
                            <Toggle
                              label={this.props.t("labels.autoRefresh")}
                              onToggle={() => this.atualizarAutomatico()}
                              toggled={this.state.atualizarAutomaticamente}
                              thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                              trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }} />
                          </div>
                          <i className='material-icons'
                            style={{ marginLeft: '20px', cursor: 'pointer' }}
                            title={this.props.t("labels.refresh")}
                            onClick={() => this.gerarPainelFluxo()}>
                            refresh
                          </i>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <div style={{ height: '100vh', paddingTop: '0' }} className={`${PainelFluxoCss.scroll}`} >
                    <Card>
                      <div className={`${style.d_block_mobile}`}>
                        <div className="col col-xs-12 " style={{ display: 'flex', justifyContent: 'center' }}>
                          <AutoComplete
                            textFieldStyle={{ marginTop: '-10px' }}
                            ref='unidadeIndustrial'
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelStyle={{ color: '#5B5B5B' }}
                            floatingLabelText={this.props.t("labels.industrialUnit")}
                            title={this.props.t("labels.industrialUnit")}
                            onUpdateInput={this.setUnidadeIndustrial.bind(this)}
                            filter={AutoComplete.fuzzyFilter}
                            dataSource={opcoesUnidadeIndustrial !== [] ? opcoesUnidadeIndustrial : null}
                            openOnFocus={true} id='unidadeIndustrial' />
                        </div>
                        {this.state.erroUnidadeIndustrial ? <div style={{ color: 'red', textAlign: 'center', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '-1' }} ref="validate">{this.props.t("messages.requiredField", { ns: 'common' })}</div> : ''}
                        <div className="col col-xs-12" style={{ display: 'flex', justifyContent: 'center' }}>
                          <SelectField
                            style={{ width: '256px' }}
                            hintText={this.props.t("labels.period")}
                            underlineFocusStyle={{ borderColor: '#000000' }}
                            floatingLabelText={this.props.t("labels.period")}
                            title={this.props.t("labels.period")}
                            floatingLabelStyle={{ color: '#5B5B5B' }}
                            value={this.state.periodo}
                            onChange={(...props) => this.setState({ periodo: props[2] })}>
                            <MenuItem value={5} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 5 })} />
                            <MenuItem value={15} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 15 })} />
                            <MenuItem value={30} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 30 })} />
                            <MenuItem value={45} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 45 })} />
                            <MenuItem value={90} primaryText={this.props.t("date.calendar.nowAndDays", { ns: 'common', count: 90 })} />
                          </SelectField>
                        </div>
                        {this.state.erroPeriodo ? <div style={{ color: 'red', fontSize: '12px', textAlign: 'center', lineHeight: '12px', position: 'relative', top: '-1' }} ref="validate">{this.props.t("messages.requiredField", { ns: 'common' })}</div> : ''}
                        <div className="col col-xs-12" style={{ textAlign: 'right', paddingBottom: '1em' }}>
                          <RaisedButton onClick={() => this.gerarPainelFluxo()} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ marginRight: '2.5em' }} labelColor='#FFF' label="GERAR" />
                        </div>
                      </div>
                    </Card>
                    <div style={{ visibility: this.state.visibility }} className={style.padding_container}>
                      <div className={`row first-row `} >
                        <div className={`col col-lg-8 col-md-12 col-xs-12 col-sm-12 ${style.d_none_tablet}`}>
                          <CustomCard id='FluxoProcesso'
                            className={'flex'}
                            header={this.props.t("widgets.flowProcessRelation.title")}
                            store={this.store}
                            inconsistentesPeriodo={this.state.inconsistentesPeriodo}>
                            <IF test={this.state.fluxoProcessoLoad}>
                              <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                <MDSpinner
                                  singleColor='#2D4F7F'
                                  size={60} />
                              </div>
                            </IF>
                            <IF test={this.state.fluxoProcessoChart}>
                              <FluxoProcessoChart id='FluxoProcessoChart' data={this.state.fluxoProcesso} />
                            </IF>
                          </CustomCard>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className={`col col-lg-4 col-md-12 col-xs-12 col-sm-12 ${style.landscape_full_size} flex flex-dir-col`} >
                          <CustomCard id='mtbf' header={this.props.t("widgets.mtbf.title")} style={{ flex: 1, minHeight: '150px' }}>
                            <div className={`${style.d_none_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.mtbfLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.mtbfChart}>
                                <MetaMediaMovelChart id='mtbfChart' data={this.state.mtbf} />
                              </IF>
                            </div>
                            <div className={`${style.d_block_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.mtbfLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.mtbfMobile}>
                                <MetaMediaMovelChart id='mtbfChart' data={this.state.mtbfMobile} />
                              </IF>
                            </div>
                          </CustomCard>
                          <CustomCard id='mttr' header={this.props.t("widgets.mttr.title")} style={{ marginTop: '10px', flex: 1, minHeight: '150px' }}>
                            <div className={`${style.d_none_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.mttrLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.mttrChart}>
                                <MetaMediaMovelChart id='mttrChart' data={this.state.mttr} />
                              </IF>
                            </div>
                            <div className={`${style.d_block_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.mttrLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.mttrMobile}>
                                <MetaMediaMovelChart id='mttrChart' data={this.state.mttrMobile} />
                              </IF>
                            </div>
                          </CustomCard>
                        </div>
                      </div>
                      <div className="row second-row">
                        <div className="col col-md-6 col-lg-4 col-xs-12 col-sm-6">
                          <CustomCard id='leadtime' header={this.props.t("widgets.leadTime.title")} style={{ height: '293px' }}>
                            <div className={`${style.d_none_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.leadtimeLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.leadtimeChart}>
                                <RealMediaMovelMetaChart id='leadtimeChart' data={this.state.leadtime} yLabel={this.props.t("widgets.leadTime.labels.yAxis")} suggestedMax={20} />
                              </IF>
                            </div>
                            <div className={`${style.d_block_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.leadtimeLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.leadtimeChart}>
                                <RealMediaMovelMetaChart id='leadtimeChart' data={this.state.leadtimeMobile} yLabel={this.props.t("widgets.leadTime.labels.yAxis")} suggestedMax={20} />
                              </IF>
                            </div>
                          </CustomCard>
                        </div>
                        <div className="col col-md-6 col-lg-4 col-xs-12 col-sm-6">
                          <CustomCard id='wip' header={this.props.t("widgets.wip.title")} style={{ height: '293px' }}>
                            <div className={`${style.d_none_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.wipLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.wipChart}>
                                <RealMediaMovelMetaChart id='wipChart' data={this.state.wip} yLabel={`${Utils.getReferenciaLotesOrdens()} >`} suggestedMax={20} />
                              </IF>
                            </div>
                            <div className={`${style.d_block_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.wipLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.wipMobile}>
                                <RealMediaMovelMetaChart id='wipChart' data={this.state.wipMobile} yLabel={`${Utils.getReferenciaLotesOrdens()} >`} suggestedMax={20} />
                              </IF>
                            </div>
                          </CustomCard>
                        </div>
                        <div className="col col-md-6 col-lg-4 col-xs-12 col-sm-6">
                          <CustomCard id='taxaProd' header={this.props.t("widgets.productionRate.title")} style={{ height: '293px' }}>
                            <div className={`${style.d_none_tablet}`} style={{ width: '100%' }}>
                              <IF test={this.state.taxaProdLoad}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.taxaProdChart}>
                                <RealMediaMovelMetaChart id='taxaProdChart' data={this.state.txProd} yLabel={`${Utils.getReferenciaLotesOrdens()} >`} suggestedMax={20} />
                              </IF>
                            </div>
                            <div className={`${style.d_block_tablet}`} style={{ width: '100%' }}>
                              <IF test={!this.state.txProdMobile}>
                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                  <MDSpinner singleColor='#2D4F7F' size={60} />
                                </div>
                              </IF>
                              <IF test={this.state.txProdMobile}>
                                <RealMediaMovelMetaChart id='taxaProdChart' data={this.state.txProdMobile} yLabel={`${Utils.getReferenciaLotesOrdens()} >`} suggestedMax={20} />
                              </IF>
                            </div>
                          </CustomCard>
                        </div>
                        <div className={`${style.d_block_tablet} col col-sm-6 col-xs-12`}>
                          <CustomCard id='holdingTime' header={this.props.t("widgets.holdingTimeHistogram.title")} style={{ height: '293px' }}
                            subheader={
                              <span style={{ float: 'right', verticalAlign: 'middle' }}>
                                <span style={{ fontSize: '14px', color: '#A5A5A5' }}>
                                  {this.props.t(this.state.holdingTimeTitle)}
                                </span>
                                <span>
                                  <a href="#" onClick={this.histogramaHoldingTime}>
                                    <img src='../../assets/clock.svg' height="13" width="13" />
                                  </a>
                                </span>
                              </span>
                            }>
                            <IF test={this.state.holdingTimeLoad}>
                              <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                <MDSpinner style={{ top: '25%' }} singleColor='#2D4F7F' size={60} />
                              </div>
                            </IF>
                            <IF test={this.state.holdingTimeChart}>
                              <HoldingTimeChart id='holdingTimeChart' data={this.state.holdingTime} />
                            </IF>
                          </CustomCard>
                        </div>
                      </div>
                      <div className={`${style.padding_none} row third-row`}>
                        <div className={`col-xl-7 col-lg-7 col-sm-12 col-xs-12 col-no ${style.tablet_height_container}`}>
                          <div className="indicator-row">
                            <div className={`card-indicator col ${style.mobile_div_spacing} small-box`}>
                              <CardIndicator id='lotesAtrasadosIndicator' idFooter='lotesAtrasadosFooter' load={this.state.lotesAtrasadosLoad} header={Utils.getReferenciaLoteInconsistenteOrdemAtrasado()} data={this.state.lotesAtrasados} />
                            </div>
                            <div className={`card-indicator col ${style.mobile_div_spacing} small-box`}>
                              <CardIndicator id='lotesInconsistentesIndicator' idFooter='lotesInconsistentesFooter' load={this.state.lotesInconsistentesLoad} header={this.props.t("widgets.inconsistentBatches.title", { primaryReference: Utils.getReferenciaLotesOrdens()})} data={this.state.lotesInconsistentes} />
                            </div>
                            <div className={`card-indicator col ${style.mobile_div_spacing} small-box`}>
                              <CardIndicator id='equipmantentoManutIndicator' idFooter='equipmantentoManutFooter' load={this.state.equipmantentoManutLoad} header={this.props.t("widgets.maintenanceEquipment.title")} data={this.state.equipmantentoManut} />
                            </div>
                            <div className={`card-indicator col ${style.mobile_div_spacing} small-box`}>
                              <CardIndicator id='tempoMedioSetupIndicator' idFooter='tempoMedioSetupFooter' load={this.state.tempoMedioSetupLoad} header={this.props.t("widgets.setupMeanTime.title")} data={this.state.tempoMedioSetup} />
                            </div>
                            <div className={`card-indicator col ${style.mobile_div_spacing} small-box`}>
                              <CardIndicator id='riscoHoldingTimeIndicator' idFooter='riscoHoldingTimeFooter' load={this.state.riscoHoldingTimeLoad} header={this.props.t("widgets.holdingTimeRisk.title")} data={this.state.riscoHoldingTime} />
                            </div>
                          </div>
                        </div>
                        <div className={`col-xl-5 col-lg-5 ${style.d_none_tablet} holding col`}>
                          <CustomCard id='holdingTime' header={this.props.t("widgets.holdingTimeHistogram.title")} style={{ height: '196px' }}
                            subheader={
                              <span style={{ float: 'right', verticalAlign: 'middle' }}>
                                <span style={{ fontSize: '14px', color: '#A5A5A5' }}>
                                  {this.props.t(this.state.holdingTimeTitle)}
                                </span>
                                <span>
                                  &nbsp;<a href="#" onClick={this.histogramaHoldingTime}>
                                    <img src='../../assets/clock.svg' height="13" width="13" />
                                  </a>
                                </span>
                              </span>
                            }>
                            <IF test={this.state.holdingTimeLoad}>
                              <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                <MDSpinner style={{ top: '25%' }} singleColor='#2D4F7F' size={60} />
                              </div>
                            </IF>
                            <IF test={this.state.holdingTimeChart}>
                              <HoldingTimeChart id='holdingTimeChart' data={this.state.holdingTime} />
                            </IF>
                          </CustomCard>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </IF>
            <IF test={!this.state.autorizacao}>
              <SemPermissao />
            </IF>
          </div>
        </IF>
      </div>
    );
  }
}

export default withTranslation("flowDashboard")(PainelFluxo);