import { observable, action, computed } from "mobx";

class AlertHoldingTime {
    @observable customYesFunction = undefined
    @observable customNoFunction = undefined
   
    
    @action
    Yes = () => {
        if (this.customYesFunction) this.customYesFunction()
        
    }
    @action
    No = () => {
        if (this.customNoFunction) this.customNoFunction();

    }

    // Getters e Setters
    @action
    setCustomYesFunction = customYesFunction => {
        this.customYesFunction = customYesFunction
    }
    @action
    setCustomNoFunction = customNoFunction => {
        this.customNoFunction = customNoFunction
    }
    
    @computed get CustomNoFunction(){
        return this.customNoFunction
    }
    @computed get CustomYesFunction(){
        return this.customYesFunction
    }



}

export default AlertHoldingTime