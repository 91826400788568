import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FilterBox from '../../common/FilterBox';
import { hasRole } from '../../helper/SecurityHelper';

@observer
export default class FieldsList extends Component {
  constructor() {
    super();
    this.onFilter = this.onFilter.bind(this);
  }

  componentWillMount() {
    this.props.store.searchModel = 'fields';
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }

  onFilter(val) {
    this.props.store.searchValue = val;
  }

  render() {
    let items = this.props.store.searchItems.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    }).map((item, k) => {
      return (
        <tr key={k}>
          <td>{k + 1}</td>
          <td>{item.name}</td>
          <td>
            <div className='options'>
              <i className='material-icons' title={this.props.t('edit')} onClick={() => this.props.handleModal(item, 'up')}>edit</i>
            </div>
          </td>
        </tr>
      )
    });
    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_AREAS_PRODUTIVAS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('productiveAreas')} />
            <CardText className='table_overflow'>
              <FilterBox onFilter={this.onFilter} />
              <table className='mui-table mui-table--bordered'>
                <thead>
                  <tr>
                    <td className='id'>#</td>
                    <td className='name'>{this.props.t('name')}</td>
                    <td className='actions'>{this.props.t('actions')}</td>
                  </tr>
                </thead>
                <tbody className='box_items'>
                  {items}
                </tbody>
              </table>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_AREAS_PRODUTIVAS_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={this.props.t('productiveAreas')} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t('noRecordsFound')}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}
