export default (time) => {
  const timeArr = time.split(':');
  const dia = timeArr[0].split('.').length === 2 ? parseInt(timeArr[0].split('.')[0]) : 0;
  const hora = timeArr[0].split('.').length === 2 ? parseInt(timeArr[0].split('.')[1]) : parseInt(timeArr[0]);
  const minuto = parseInt(timeArr[1]);
  const segundo = parseInt(timeArr[2]);

  const diaString = dia > 0 ? `${dia}d ` : '';
  const horaString = hora > 0 ? `${hora}h ` : '';
  const minutosString = minuto > 0 ? `${minuto}min ` : '';
  const segundosString = segundo > 0 ? `${segundo}seg` : '';

  return `${diaString}${horaString}${minutosString}${segundosString}`
}