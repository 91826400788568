import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import ListWarning from './ListWarning';
import styles from './viewer.scss';
import List from './List';
import ListBatches from './ListBatches';
import moment from 'moment';
import { Avatar, IconMenu, Checkbox } from 'material-ui';
import { FormSelect } from '../../common/Form';
import Dispatcher from '../../common/Dispatcher';
import Quest from '../../common/Quest';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import $ from 'jquery';
import update from 'immutability-helper';
import { request, requestAll } from '../../common/request';
import { url } from '../../common/helpers.js';
import DetalhamentoLote from './detalhamentoLote/Detalhe';
import MDSpinner from "react-md-spinner";
import ListSubtitle from './ListSubtitle';
import ListWaitingBatchesStage from './ListWaitingBatchesStage';
import MoveBatch from './MoveBatch';
import BatchComments from './BatchComments';
import {
  respostaUnidadeIndustrial,
  respostaEventos,
  respostaClassesEventos,
  respostaEquipamentos,
  respostaEtapas,
  respostaParametros
} from '../../common/mappers';
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';
import DashboardSession from 'clients/DashboardSession';
import { Mixpanel } from 'utils/Mixpanel';

function flipBit(data, bit) {
  return data ^ (1 << bit);
}

var time = 0;
var interval60 = 0;

@observer
class FlowViewerContainer extends Container {
  constructor(props) {
    super(props);

    this.state = {
      atualizarAutomaticamente: false,
      leadtimes: false,
      sensores: false,
      temposdeciclo: false,
      modal: false,
      batchId: '',
      modalType: '',
      stars: [],
      warnings: '',
      watingBatches: '',
      start_date: null,
      end_date: null,
      unidadeIndustrialSelected: '',
      opcoesUnidadeIndustrial: [],
      opcoesEtapasUnidadeIndustrial: [],
      opcoesEquipamentosEtapa: [],
      erroDataInicial: false,
      erroDataFinal: false,
      erroDatas: false,
      textUnidade: '',
      nameUnidadeIndustrial: '',
      idUnidadeIndustrial: '',
      etapa: '',
      equipamento: '',
      erroUnidadeIndustrial: '',
      searchTextEtapa: '',
      searchTextEquipmento: '',
      processo: false,
      encerrado: false,
      unidadeIndustrialModal: '',
      batchBit: 0,
      errors: {},
      holdingTimeCalculado: [],
      setup_flow_view: [],
      loadingPage: true,
      erroMSG: '',
      unidadeIndustrial: '',
      periodoRelatorio: '',
      analitico: false,
      corLeadTime: '',
      days_avg_lead: undefined,
      sensores_data: undefined,
      modo: true,
      interval: null,
      permissoes: {
        relatorio: {
          desvioHoldingTime: hasRole(['ROLE_RELATORIO_DESVIO_HOLDING_TIME_VISUALIZAR', 'ROLE_RELATORIO_DESVIO_HOLDING_TIME_GERAR_RELATORIO']),
          desvioRota: hasRole(['ROLE_RELATORIO_DESVIO_ROTA_VISUALIZAR', 'ROLE_RELATORIO_DESVIO_ROTA_GERAR_RELATORIO']),
          gargalo: hasRole(['ROLE_RELATORIO_GARGALO_VISUALIZAR', 'ROLE_RELATORIO_GARGALO_GERAR_RELATORIO']),
          lotesInconsistentes: hasRole(['ROLE_RELATORIO_LOTES_INCONSISTENTE_VISUALIZAR', 'ROLE_RELATORIO_LOTES_INCONSISTENTE_GERAR_RELATORIO']),
          leadTime: hasRole(['ROLE_RELATORIO_LEAD_TIME_VISUALIZAR', 'ROLE_RELATORIO_LEAD_TIME_GERAR_RELATORIO']),
        }
      },
      dataInicio: '',
      dataFim: '',
      gargalos: [],
      loadingFlowView: true,
      loadingOee: false,
      referenciaSecundaria: false,
      posicaoFifo: 0,
      batches: []
    };

    this.handleSession = this.handleSession.bind(this);
    this.handleSessionCurrent = this.handleSessionCurrent.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.setRemStar = this.setRemStar.bind(this);
    this.toQuality = this.toQuality.bind(this);
    this.flowMount = this.flowMount.bind(this);
    this.setEtapa = this.setEtapa.bind(this);
    this.setequipamento = this.setequipamento.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this.radioButton = this.radioButton.bind(this);
    this.activeModal = this.activeModal.bind(this);
    this.buscarLotesFavoritos = this.buscarLotesFavoritos.bind(this);
    this.atualizarAutomatico = this.atualizarAutomatico.bind(this);
    this.carregarGargaloUnidadeIndustrial = this.carregarGargaloUnidadeIndustrial.bind(this);
    this.obterUnidadePelaURL = this.obterUnidadePelaURL.bind(this);
    this.setUnidadeURL = this.setUnidadeURL.bind(this);
    this.setReferenciaSecundaria = this.setReferenciaSecundaria.bind(this);
    this.mediaLeadtimeUnidadeIndustrial = this.mediaLeadtimeUnidadeIndustrial.bind(this);
  }

  componentWillUnmount() {
    this.store.days_avg_lead = null;
    this.store.sensores_data = null;
    clearInterval(interval60);
    clearInterval(time);
  }

  setRemStar(idx) {
    let tmpStars = this.state.stars;

    if (tmpStars.indexOf(idx) > -1) {
      tmpStars.splice(tmpStars.indexOf(idx), 1);
    } else {
      tmpStars.push(idx);
    }

    this.setState({
      stars: tmpStars
    });
  }

  activeModal(type, obj) {
    this.setState({
      modal: true,
      modalType: type,
      batchId: obj
    });
  }

  carregarGargaloUnidadeIndustrial(sessionId, endpoints, data_inicio, data_fim) {
    request(`${endpoints.GARGALO}/${sessionId}/${data_inicio}/${data_fim}`, {
      method: 'get',
    })
      .then(response => {
        return response.json();
      })
      .then(dataGargalo => {
        let maioresGargalos = dataGargalo.slice(0, 5);
        this.setState({
          gargalos: maioresGargalos
        })
      });
  }

  flowMount() {
    requestAll([
      {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial,
        acao: true
      },
      {
        url: this.endpoints.EVENTO + '/classe',
        modelo: 'events',
        modificador: respostaEventos
      },
      {
        url: `${this.endpoints.CLASSES}/eventos`,
        modelo: 'type_events',
        modificador: respostaClassesEventos
      },
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      },
      {
        url: this.endpoints.ETAPA,
        modelo: 'stages',
        modificador: respostaEtapas,
        acao: true
      },
      {
        url: this.endpoints.PARAMETROS,
        modelo: 'parameters',
        modificador: respostaParametros,
        acao: true
      },
      {
        url: this.endpoints.FAVORITO,
        modelo: 'favorites'
      }
    ], this);

    if (hasRole('ROLE_HOLDING_TIME_PORCENTAGEM_LOTE_VISUALIZAR')) {
      request(this.endpoints.HOLDING_TIME_CALCULADO)
        .then(function (response) {
          if (response.status == 200) return response.json();
        }).then((data) => {
          this.setState({
            holdingTimeCalculado: data,
          });
        });
    }

    this.obterUnidadePelaURL();
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  buscarLotesFavoritos() {
    requestAll([
      {
        url: this.endpoints.FAVORITO,
        modelo: 'favorites'
      }
    ], this);
  }

  componentDidMount() {
    localStorage.setItem('sessionId', '');
    this.flowMount();
  }

  componentDidUpdate() {
    let scroll = document.getElementById('list_items_wrapper');

    if (scroll != null) {
      let hasVerticalScrollbar = scroll.scrollHeight > scroll.clientHeight;

      if (hasVerticalScrollbar && (scroll.scrollHeight - scroll.offsetHeight) > 10) {
        var interval;
        var suave = 1;
        // true = descer / false = subir
        if (this.state.atualizarAutomaticamente && this.state.interval == null) {

          var inter = setInterval(() => {
            let scroll = document.getElementById('list_items_wrapper')
            if (this.state.modo && (scroll.scrollTop + scroll.offsetHeight) >= scroll.scrollHeight) this.setState({ modo: !this.state.modo });  // chegou ao fim                
            if (!this.state.modo && (scroll.scrollTop) == 0) this.setState({ modo: !this.state.modo }); // chegou ao inicio

            if (this.state.modo) scroll.scrollTop += suave
            if (!this.state.modo) scroll.scrollTop -= suave

            if (!this.state.atualizarAutomaticamente) {
              clearInterval(inter);
              this.setState({ interval: null })
            }
          }, 100)
          this.setState({ interval: inter })
        }
      } else {
        clearInterval(interval)
      }
    }
  }

  obterUnidadePelaURL() {
    let reg = /\?unidade=([0-9]{0,})/

    if (location.search) {

      if (reg.test(location.search)) {
        let sessionId = parseInt(location.search.match(reg)[1]);

        this.store.setSession(sessionId);

        let payload = {
          target: {
            value: sessionId
          }
        };

        //Faço uma requisição para carregar os parametros
        //de periodo de visao do flow view
        request(url(this.endpoints.PARAMETROS), {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': localStorage.getItem('token')
          },
        })
          .then(function (response) {
            if (response.status == 200) {
              return response.json();
            }
          })
          .then((data) => {
            this.setState({
              parametros: respostaParametros(data)
            })
            Dispatcher.dispatch({
              type: 'get',
              model: 'parameters',
              data: respostaParametros(data)
            });
          }).then(() => this.handleSession(payload));
      }
    }
  }

  setUnidadeURL(sessionId) {
    history.pushState({}, 'flowview', `${location.pathname}?unidade=${sessionId}`);
  }

  atualizarAutomatico() {
    if (!this.state.atualizarAutomaticamente) {
      this.setState({
        atualizarAutomaticamente: true
      });

      time = setInterval(() => this.handleSession(), 900000);

      interval60 = setInterval(() => {
        DashboardSession.performKeepAlive('flow_view', { industrialUnitId: this.store.getSession() });
      }, 1000 * 60);
    } else {
      this.setState({
        atualizarAutomaticamente: false
      });
      clearInterval(time);
      clearInterval(interval60);
    }
  }

  mediaLeadtimeUnidadeIndustrial(sessionId) {
    if (!sessionId) return;

    try {
      request(`${this.endpoints.FLOW_VIEW}/lead-time/${sessionId}`, {
        method: 'get'
      }, true).then(res => {
        res.json().then(data => {
          this.setState({
            days_avg_lead: data.lead_time,
          });
        })
      });
    } catch (e) { }
  }

  sensoresUnidadeIndustrial(sessionId) {
    if (!sessionId) return;

    try {
      request(`${this.endpoints.FLOW_VIEW}/sensor/${sessionId}`, {
        method: 'get'
      }, true).then(res => {
        res.json().then(data => {
          this.setState({
            sensores_data: data,
          });
        })
      });
    } catch (e) { }
  }

  handleSession(event) {
    //Reinicia a state de loading de OEE
    this.setState({
      loadingOee: false,
      modal: false,
      loadingFlowView: true
    });

    this.setState({
      batches: []
    });

    this.handleModal('loading');

    let sessionId = (event != undefined) ? event.target.value : this.store.getSession();
    let session = this.store.sessions.find(s => s.id == sessionId);

    session = session == undefined ? { id: sessionId, name: '' } : session;

    // Track do Mixpanel aqui pois session pode voltar null e na linha acima é feito o tratamento
    Mixpanel.track('FLOWVIEW_UNIT', { name: session.name })

    let diasOeeEquipamento = 15;

    let store = this.store;
    let periodoVisaoFlowview = store.parameters.filter(p => p.type == 'PeriodoVisaoFlowView')[0].value;
    //Ifs para saber a quantidade de dias que devem ser levados em consideração
    // para fazer o calculo do oee
    if (periodoVisaoFlowview == 1) {
      diasOeeEquipamento = 0;
    } else if (periodoVisaoFlowview == 7) {
      diasOeeEquipamento = moment().day();
    } else if (periodoVisaoFlowview == 30) {
      diasOeeEquipamento = moment().date() - 1;
    } else {
      diasOeeEquipamento = periodoVisaoFlowview;
    }

    let data_inicio = moment().subtract(diasOeeEquipamento, 'day').format('DD-MM-YYYY') + ' 00:00:00';
    let data_fim = moment().format('DD-MM-YYYY') + ' 23:59:59';

    this.setState({
      dataInicio: data_inicio,
      dataFim: data_fim,
    });

    this.mediaLeadtimeUnidadeIndustrial(sessionId);
    this.sensoresUnidadeIndustrial(sessionId);
    this.carregarGargaloUnidadeIndustrial(sessionId, this.endpoints, data_inicio, data_fim);

    request(url(this.endpoints.FLOWVIEW + '/' + sessionId), {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
    })
      .then(function (response) {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then((data) => {
        Dispatcher.dispatch({
          type: 'get',
          model: 'batches',
          data: data
        });

        this.setState({
          modal: false,
          loadingOee: true,
          textUnidade: session.name,
          unidadeIndustrialSelected: { text: session.name, value: sessionId },
          batches: data
        });
      })
      .finally(() => this.setState({ loadingFlowView: false }));

    request(this.endpoints.OEE_SETUP_FLOW_VIEW + `/${sessionId}`)
      .then(response => {
        return response.json()
      }).then(data => {
        this.setState({
          setup_flow_view: data
        });
      });

    this.store.setSession(sessionId);
    this.setUnidadeURL(sessionId);
  }

  handleSessionCurrent() {
    if (this.store.getSession()) {
      store.getSession() ? this.action.execute('get', url(this.endpoints.FLOWVIEW + '/' + session.id), 'batches') : null;
    }
  }

  handleComments(batch) {
    let batches = this.state.batches.map(b => {
      const listWaitingIndex = b.batches[0].listWaiting.findIndex(f => f.fk_id_batches === batch.fk_id_batches);
      const listWarningIndex = b.batches[0].listWarning.findIndex(f => f.fk_id_batches === batch.fk_id_batches);
      const listProcessIndex = b.batches[0].listProcess.findIndex(f => f.fk_id_batches === batch.fk_id_batches);

      if (listWaitingIndex !== -1) {
        b.batches[0].listWaiting[listWaitingIndex] = batch;
      }

      if (listWarningIndex !== -1) {
        b.batches[0].listWarning[listWarningIndex] = batch;
      }

      if (listProcessIndex !== -1) {
        b.batches[0].listProcess[listProcessIndex] = batch;
      }

      return b;
    });

    this.setState({
      batches: batches
    });
  }

  toQuality() {
    this.action.execute('put', this.endpoints.BATCHES + '/' + this.state.batchId, '', { type: 'inQuality', obj: this.state.batch });
    this.handleSessionCurrent();
    this.setState({ modal: false });
  }

  _onChange(target) {
    switch (target) {
      case 'leadtimes':
        this.setState({
          leadtimes: !this.state.leadtimes,
          temposdeciclo: false
        });

        Dispatcher.dispatch({
          type: 'get',
          model: 'flowViewLeadTimes',
          data: !this.state.leadtimes
        });
        Dispatcher.dispatch({
          type: 'get',
          model: 'flowViewTemposCiclo',
          data: false
        });

        break;

      case 'temposdeciclo':
        this.setState({
          temposdeciclo: !this.state.temposdeciclo,
          leadtimes: false
        });

        Dispatcher.dispatch({
          type: 'get',
          model: 'flowViewTemposCiclo',
          data: !this.state.temposdeciclo
        });
        Dispatcher.dispatch({
          type: 'get',
          model: 'flowViewLeadTimes',
          data: false
        });

        break;

      case 'sensores':
        this.setState({
          sensores: !this.state.sensores
        });

        Dispatcher.dispatch({
          type: 'get',
          model: 'flowViewSensores',
          data: !this.state.sensores
        });

        break;
    }
  }

  handleModal(type, id, warnings, watingBatches, batch, equip, stage, corLeadTime, posicaoFifo) {
    this.setState({
      modal: !this.state.modal,
      modalType: type || '',
      batchId: id || '',
      warnings: warnings,
      batch: batch,
      watingBatches: watingBatches,
      equip: equip,
      etapa: stage,
      corLeadTime: corLeadTime,
      posicaoFifo: posicaoFifo
    });
  }

  activeModal(type, obj, batch, equip, stage, posicaoFifo) {
    this.setState({
      modal: true,
      modalType: type,
      batchId: obj,
      batch: batch,
      equip: equip,
      etapa: stage,
      posicaoFifo: posicaoFifo
    });
  }

  setUnidadeIndustrial(nomeUnidade, allUnd) {
    let obj = allUnd.find(i => i.text == nomeUnidade);

    this.setState({
      unidadeIndustrial: obj,
      searchTextEtapa: '',
      searchTextEquipmento: ''
    });

    //limpar opções e colocar as novas
    this.state.opcoesEtapasUnidadeIndustrial = [];
    this.state.opcoesEquipamentosEtapa = [];
    this.state.etapa = '';
    this.state.equipamento = '';

    if (obj != undefined) {
      let unidadeIndustrialSelected = this.store.sessions.find(s => s.id == obj.value);
      let etapasUnidadeIndustrial = unidadeIndustrialSelected.stages.filter(s => !s.length);

      for (let i = 0; i < etapasUnidadeIndustrial.length; i++) {
        if (this.state.opcoesEtapasUnidadeIndustrial[i] == undefined)
          this.state.opcoesEtapasUnidadeIndustrial.push({ text: etapasUnidadeIndustrial[i].name, value: etapasUnidadeIndustrial[i].id });
      }
    }

  }

  setEtapa(nomeEtapa, allEtp) {
    let obj = allEtp.find(i => i.text == nomeEtapa);

    this.setState({
      etapa: obj,
      searchTextEquipmento: ''
    });

    this.state.opcoesEquipamentosEtapa = [];
    this.state.equipamento = '';

    if (obj != undefined) {
      this.setState({
        searchTextEtapa: obj.text
      });

      let unidadeIndustrialSelected = this.store.sessions.find(s => s.id == this.state.unidadeIndustrial.value);
      let etapaSelected = unidadeIndustrialSelected.stages.filter(u => u.id == obj.value);
      let equipamentosEtapa = etapaSelected[0].equipments.filter(e => !e.length);

      for (let i = 0; i < equipamentosEtapa.length; i++) {
        if (this.state.opcoesEquipamentosEtapa[i] == undefined)
          this.state.opcoesEquipamentosEtapa.push({ text: equipamentosEtapa[i].name, value: equipamentosEtapa[i].id });
      }
    }
  }

  setequipamento(nomeEquipamento, allEqp) {
    let obj = allEqp.find(i => i.text == nomeEquipamento);
    this.setState({
      equipamento: obj
    });

    if (obj != undefined) {
      this.setState({
        searchTextEquipmento: obj.text
      });
    }
  }

  _onDateChange(event) {
    var v = event.target.value.replace(/\D/g, '');
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1");
    event.target.value = v;

    if (v.length === 10) {
      if (event.target.id == 'start_date') {
        this.setState({
          start_date: moment(event.target.value, 'DD/MM/YYYY')
        });
      } else {
        this.setState({
          end_date: moment(event.target.value, 'DD/MM/YYYY')
        });
      }
    } else {
      event.target.value = '';
      if (event.target.id == 'start_date') {
        this.setState({
          start_date: null
        });
      } else {
        this.setState({
          end_date: null
        });
      }
    }
  }


  _handleCheck(bit) {
    this.setState(update(this.state, {
      batchBit: { $set: flipBit(this.state.batchBit, bit) },
      errors: {
        selecionar_tipo_lote: { $set: undefined }
      }
    }));
  }

  errorMessage() {
    return (
      <div className="modal" style={{ width: '500px', height: '100%' }}>
        <div>
          <div style={{ textAlign: 'left' }} >
            {this.state.erroMSG}
          </div>
          <div>
            <i className="fa fa-close cursor-pointer" onClick={() => this.handleModal()} />
          </div>
        </div>
        <div style={{ paddingTop: '1em' }} >
          E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
        </div>
      </div>
    );
  }

  /* USADA PRA SETAR O ID DA UNIDADE INDUSTRIAL NOS MODAIS DE RELATORIO */
  setUnidadeIndustrialComum(nomeUnidade, allUnd) {
    let obj = allUnd.find(i => i.text == nomeUnidade);

    if (obj == '' || obj == undefined) {
      this.state.unidadeIndustrialSelected = '';
    } else {
      this.setState(update(this.state, {
        unidadeIndustrialSelected: { $set: obj },
        errors: {
          selecionar_unidade_industrial: { $set: undefined }
        }
      }));
    }
  }

  radioButton(event) {
    var value = $(event.target).data('value');
    this.setState({
      periodoRelatorio: value
    });

  }

  checkProcesso() {
    var ischecked = $('#processo').is(':checked');

    ischecked ?
      this.setState({
        processo: true
      })
      :
      this.setState({
        processo: false
      })
  }

  checkEncerrado() {
    var ischecked = $('#encerrado').is(':checked');

    ischecked ?
      this.setState({
        encerrado: true
      })
      :
      this.setState({
        encerrado: false,
        data_inicio: '',
        data_fim: ''
      })
  }

  buildAvgLeadTime(num) {
    if (num !== undefined) {
      num = '' + parseFloat(num || 0);

      return ` | ${this.props.t("labels.avgLeadTime")}: ${num.replace('.', ',')} ${this.props.t("date.labels.days", { ns: 'common', count: num })}`;
    }

    return '';
  }

  contentModal() {
    switch (this.state.modalType) {
      case 'detailLote':
        return <DetalhamentoLote handleModal={this.handleModal} corLeadTime={this.state.corLeadTime}
          loteId={this.state.batchId.fk_id_batches} endpoints={this.endpoints}
          store={this.store} action={this.action} buscarLotesFavoritos={this.buscarLotesFavoritos} />;
      case 'listWarning':
        return <ListWarning store={this.store} handleModal={this.handleModal} listWarning={this.state.warnings} />;
      case 'toQuality':
        return <Quest question={this.props.t("messages.sendToQuality", { primaryReference: this.utils.getReferenciaoLoteaOrdem().toLowerCase() })} handleModal={this.handleModal} action={this.toQuality} notAction={this.handleModal} />
      case 'listLote':
        return <ListBatches
          store={this.store}
          handleSession={this.handleSession}
          endpoints={this.endpoints}
          equipment={this.state.equip}
          referenciaSecundaria={this.state.referenciaSecundaria}
          etapa={this.state.etapa}
          activeModal={this.activeModal}
          handleModal={this.handleModal}
          watingBatches={this.state.watingBatches}
          batches={this.state.batches}
        />;
      case 'listWaitingBatchStage':
        return <ListWaitingBatchesStage
          store={this.store}
          handleSession={this.handleSession}
          endpoints={this.endpoints}
          listaLotesEtapa={this.state.batch}
          referenciaSecundaria={this.state.referenciaSecundaria}
          etapa={this.state.etapa}
          activeModal={this.activeModal}
          handleModal={this.handleModal}
        />;
      case 'moveBatch':
        return <MoveBatch
          store={this.store}
          handleSession={this.handleSession}
          endpoints={this.endpoints}
          lote={this.state.batch}
          etapa={this.state.etapa}
          activeModal={this.activeModal}
          handleModal={this.handleModal}
          posicaoFifo={this.state.posicaoFifo}
        />;
      case 'loading':
        return <div className="modal" id={styles.loading} style={{ width: '80px', height: '100%' }}>
          <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
        </div>;
      case 'listSubtitle':
        return <ListSubtitle handleModal={this.handleModal} />
      case 'no_selected_session':
        return <Quest question={this.props.t("messages.industrialUnitNotSelected")} handleModal={() => this.setState({ modal: false })} />
      case 'erro':
        return this.errorMessage();
      case 'outQuality':
        return <Quest question={this.props.t("messages.removeFromQuality", { primaryReference: this.utils.getReferenciaoLoteaOrdem().toLowerCase() })} handleModal={this.handleModal} action={this.outQuality} notAction={this.handleModal} />
      case 'batchComments':
        return <BatchComments
          endpoints={this.endpoints}
          store={this.store}
          handleModal={this.handleModal}
          lote={this.state.batch}
          handleComments={this.handleComments} />;
    }
  }

  calculateWIP(session, batches) {
    let wip = '';

    try {
      let batch = batches.filter(b => session.id == b.id);

      if (batch.length > 0) {
        batch = batch[0];

        batches = batch.batches.filter(b => !!b);

        if (batches.length > 0) {
          batches = batches[0];
          let listProcess = batches.listProcess.filter(l => !!l);
          let listQuality = batches.listQuality.filter(l => !!l);
          let listWaiting = batches.listWaiting.filter(l => !!l);
          let listWarning = batches.listWarning.filter(l => !!l);

          let total = this.countUniqueNum([listProcess, listQuality, listWaiting, listWarning]);

          switch (total) {
            case 0:
              wip = ` | ${this.props.t("labels.WIP")}: ${this.utils.getReferenciaNenhumLoteNenhumaOrdem()}`;
              break;
            default:
              wip = ` | ${this.props.t("labels.WIP")}: ${total} ${total > 1 ? this.utils.getReferenciaLotesOrdens() : this.utils.getReferenciaLoteOrdem()}`;
          }
        }
      }
    } catch (e) { }

    return wip;
  }

  countUniqueNum(listsOfList) {
    var uniq = {}, total = 0;

    for (const list of listsOfList) {
      for (const el of list) {
        if (!uniq[el.num]) {
          uniq[el.num] = true;
          total++;
        }
      }
    }

    return total;
  }

  menuIconClicked(e, value) {
    this.setState({
      modal: true,
      modalType: value,
      errors: {},
    });
  }

  setReferenciaSecundaria() {
    this.setState({
      referenciaSecundaria: !this.state.referenciaSecundaria
    })
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      let sessionOpts = this.store.sessions.filter(s => s.stages.length).map((s) => { return { value: s.id, text: s.name } });
      let session = this.store.sessions.find(s => s.id == this.store.getSession());
      let listWarning = [];
      let listProcess = [];
      let listWaiting = [];

      let days_avg_lead = this.buildAvgLeadTime(this.state.days_avg_lead);

      let wip = this.calculateWIP(session, this.state.batches);

      if (session !== undefined && session !== null && session !== '') {
        let batches = this.state.batches.find(b => b.id == session.id);

        if (batches) {
          listWarning = batches.batches[0].listWarning;
          listProcess = batches.batches[0].listProcess;
          listWaiting = batches.batches[0].listWaiting;
        };
      }

      var flags = {};

      let listWarningUnique = listProcess.concat(listWaiting)
        .filter(c => c.inconsistente)
        .filter(c => {
          if (flags[c.num]) {
            return false;
          }
          flags[c.num] = true;

          return true;
        });

      return (
        <Container modal={this.state.modal} contentModal={this.contentModal}>
          <div style={{ maxWidth: '100%', width: 'fit-content', minWidth: '20%' }} className='box_list'>
            <Card className='box_list_card' style={{ minWidth: '30%', maxWidth: '98%' }}>
              <div className={`${styles.d_none_mobile}`}>
                <CardHeader
                  className={`box_header ${styles.box_header}`}
                  title={this.props.t("title").toUpperCase() + wip + days_avg_lead}
                  titleStyle={{ display: 'inline-block', verticalAlign: 'top', whiteSpace: 'inherit' }}
                >
                  {session ?
                    <div className={styles.buttons_column}>
                      {
                        listWarningUnique.length ?
                          <div title={this.props.t("labels.inconsistencies")} className={styles.tooltip}>
                            <span className={styles.iconStack} onClick={() => this.handleModal('listWarning', '', listWarningUnique)}>
                              <i className={'fa fa-chain-broken ' + styles.iconStack3x}></i>
                              <i className={'fa fa-circle ' + styles.warningIcon}></i>
                            </span>
                            <span className={styles.toolTipText}>{this.props.t("labels.inconsistencies")}</span>
                            <span className={styles.counter}>{listWarningUnique.length}</span>
                          </div>
                          :
                          null
                      }
                      <IconMenu
                        menuStyle={{ overflow: 'hidden' }}
                        style={{ cursor: 'pointer' }}
                        iconButtonElement={<Avatar icon={<i className='material-icons'>extension</i>} color='#FFF' backgroundColor='#D84042' size={30} />}>
                        <Checkbox
                          style={{ marginLeft: '3px' }}
                          label={this.props.t("labels.autoRefresh")}
                          onCheck={() => this.atualizarAutomatico()}
                          labelPosition={"right"}
                          iconStyle={{ fill: '#0C2340' }}
                          checked={this.state.atualizarAutomaticamente} />

                        <hr style={{ margin: '0 0 0 0', width: '100%' }} />

                        {
                          this.state.sensores_data !== undefined && this.state.sensores_data.length > 0 ?
                            (
                              <>
                                <Checkbox
                                  style={{ marginLeft: '3px' }}
                                  label={this.props.t("labels.sensors")}
                                  labelStyle={{ color: '#143055' }}
                                  onCheck={() => this._onChange('sensores')}
                                  labelPosition={"right"}
                                  iconStyle={{ fill: '#0C2340' }}
                                  checked={this.state.sensores} />

                                <hr style={{ margin: '0 0 0 0', width: '100%' }} />
                              </>
                            )
                            : null
                        }

                        <Checkbox
                          style={{ marginLeft: '3px' }}
                          label={this.props.t("labels.leadTimes")}
                          labelStyle={{ color: '#143055' }}
                          onCheck={() => this._onChange('leadtimes')}
                          labelPosition={"right"}
                          iconStyle={{ fill: '#0C2340' }}
                          checked={this.state.leadtimes} />

                        <hr style={{ margin: '0 0 0 0', width: '100%' }} />

                        <Checkbox
                          style={{ marginLeft: '3px' }}
                          label={this.props.t("labels.cycleTime")}
                          labelStyle={{ color: '#143055' }}
                          onCheck={() => this._onChange('temposdeciclo')}
                          labelPosition={"right"}
                          iconStyle={{ fill: '#0C2340' }}
                          checked={this.state.temposdeciclo} />

                        {this.utils.getReferenciaOrdemLote() != '' ?
                          <hr style={{ margin: '0 0 0 0', width: '100%' }} />
                          :
                          null
                        }
                        {this.utils.getReferenciaOrdemLote() != '' ?
                          <Checkbox
                            style={{ marginLeft: '3px' }}
                            label={this.props.t("labels.secondaryReference")}
                            onCheck={() => this.setReferenciaSecundaria()}
                            labelPosition={"right"}
                            iconStyle={{ fill: '#0C2340' }}
                            checked={this.state.referenciaSecundaria} />
                          :
                          null
                        }

                      </IconMenu>
                      <FormSelect styles={styles} id='session' ref='session' label={this.props.t("labels.select")} title={true} options={sessionOpts} onChange={this.handleSession} value={session ? session.id : ''} novalidate='true' />

                      <span style={{ top: '18px', marginRight: '12px' }} className={styles.iconStack + ' ' + styles.questionIcon} onClick={() => this.handleModal('listSubtitle')}>
                        <i style={{ color: '#fff' }} className="material-icons">help_outline</i>
                      </span>
                    </div>
                    :
                    <FormSelect styles={styles} id='session' ref='session' label={this.props.t("labels.select")} title={true} options={sessionOpts} onChange={this.handleSession} value={session ? session.id : ''} novalidate='true' />
                  }
                </CardHeader>
              </div>

              <div className={`${styles.d_block_mobile}`}>
                <CardHeader className={`box_header ${styles.box_header}`} title={this.props.t("title").toUpperCase() + wip + days_avg_lead}></CardHeader>
                <FormSelect id='session' ref='session' label={this.props.t("labels.select")} title={true} options={sessionOpts} onChange={this.handleSession} value={session ? session.id : ''} novalidate='true' />
              </div>

              <CardText className={`${session ? styles.height_mobile : ''}`}>
                {
                  session && !this.state.loadingFlowView ?
                    <List
                      ref='list'
                      session={session}
                      action={this.action}
                      endpoints={this.endpoints}
                      setup_flow_view={this.state.setup_flow_view}
                      parameters={this.store.parameters}
                      products={this.store.products}
                      batches={this.state.batches}
                      referenciaSecundaria={this.state.referenciaSecundaria}
                      favorites={this.store.favorites}
                      events={this.store.events}
                      handleModal={this.handleModal}
                      loadingOee={this.state.loadingOee}
                      store={this.store}
                      handleSessionCurrent={this.handleSessionCurrent}
                      loading={this.state.loadingPage}
                      dataInicio={this.state.dataInicio}
                      dataFim={this.state.dataFim}
                      gargalos={this.state.gargalos}
                      holdingTimeCalculado={this.state.holdingTimeCalculado}
                      sensores={this.state.sensores}
                      sensores_data={this.state.sensores_data}
                    />
                    : <div ref='list' />
                }
              </CardText>
            </Card>
          </div>
        </Container>
      );
    }
  }
}

export default withTranslation("flowView")(FlowViewerContainer);
