import { AutoComplete } from 'common/MaterialForm';
import { NButton, NButtonGroup, NDatePickerInput, NForm, NFormTitle, NInputTime } from 'common/NForm';
import { request, requestRelatorioXLSX } from 'common/request';
import { default as MultiSelect } from 'components/AutoComplete';
import { Checkbox, Divider, RaisedButton } from 'material-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import { APIEndpoints } from '../../common/Constants';
import { orderBy } from 'lodash';
import moment from 'moment';
import styles from '../menuRelatorio.scss';

const ModalRelatorioPareto = ({
    closeModal
}) => {
    const { t } = useTranslation('reports');
    const [firstNoteAt, setFirstNoteAt] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState();
    const [equipment, setEquipment] = useState([]);
    const [shifts, setShifts] = useState([]);

    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(7);

    const [selectedPeriodGrouping, setSelectedPeriodGrouping] = useState('daily');
    const [selectedRange, setSelectedRange] = useState(
        {
            start: moment().subtract(7, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        });

    const [selectedCustomShift, setSelectedCustomShift] = useState({
        startTime: moment().format("HH:mm:ss"),
        endTime: moment().format("HH:mm:ss")
    })

    const [disregardSetups, setDisregardSetups] = useState(false);
    // const [completeData, setCompleteData] = useState(false);

    const [optViewType, setOptViewType] = useState([]);
    const [viewType, setViewType] = useState('');
    const [optEventType, setOptEventType] = useState([]);
    const [eventType, setEventType] = useState('');

    useEffect(() => {
        setLoading(true);

        setOptViewType([
            {
                Id: 0,
                Text: t("labels.downTime")
            },
            {
                Id: 1,
                Text: t("labels.totalTime")
            }
        ]);

        setOptEventType([
            {
                Id: 0,
                Text: t("labels.allEvents")
            },
            {
                Id: 1,
                Text: t("labels.considered")
            },
            {
                Id: 2,
                Text: t("labels.disregarded")
            }
        ]);

        Promise.all([
            loadEquipment(),
            loadEquipmentGroups(),
            loadFirstNote(),
            loadShifts()
        ]).then((response) => {
            let result = orderBy(response[1], ['name'], 'asc');
            result = result.concat(orderBy(response[0], ['name'], 'asc'));

            setEquipment(result);
        }).finally(() => setLoading(false))
    }, []);

    const loadFirstNote = useCallback(() => {
        return request(APIEndpoints.APONTAMENTO + '/primeiro', { method: 'get' })
            .then((response) => {
                if (response.status == 200) return response.json();
            })
            .then(data => {
                if (data !== null) setFirstNoteAt(new Date(data.inicio));

                return data;
            });
    }, [])

    const loadEquipment = useCallback(() => {
        return request(APIEndpoints.EQUIPAMENTOS, { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { return data.map(c => ({ id: c.id, name: c.nome, isGroup: false })) });
    }, [])

    const loadEquipmentGroups = useCallback(() => {
        return request(APIEndpoints.GRUPO_EQUIPAMENTO + '?comEquipamento=true', { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => { if (data) return data.map(c => ({ id: c.id, uuid: c.uuid, name: c.nome, isGroup: true, equipamentos: c.equipamentos })) });
    }, [])

    const loadShifts = useCallback(() => {
        return request(APIEndpoints.TURNO, { method: 'get' })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => {
                let values = data.map(c => ({ id: c.id, name: c.nome }));

                values = values.concat({ id: 0, name: t("labels.customize", { ns: 'common' }) })

                setShifts(values)
            });
    }, [])

    const handleEquipmentSelect = (searchText) => {
        let equipmentIds = [];

        let eq = equipment.filter(e => e.name.trim() == searchText.trim())[0];

        if (eq) {
            equipmentIds.push(eq.id);
        }

        setSelectedEquipment(equipmentIds);
    }

    const handlePeriodChanged = (e) => {
        let value = parseInt(e.currentTarget.value);

        let range = {
            start: moment().subtract(value, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        }

        if (value == 0) {
            range.start = moment().subtract(30, 'days').startOf('day').toDate(),
                range.end = moment().endOf('day').toDate()
        }

        setSelectedPeriod(value);
        setSelectedRange(range);
    }

    const handlePeriodGroupingChanged = (e) => {
        let value = e.currentTarget.value;

        let range = {
            start: moment().subtract(30, 'days').startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        }

        if (value === 'monthly') {
            range.start = moment().subtract(1, 'month').startOf('month').toDate(),
                range.end = moment().endOf('month').toDate()
        }

        setSelectedPeriodGrouping(value);
        setSelectedRange(range);
    }

    const handleStartDate = (date) => {
        let range = {
            start: moment(date).startOf('day').toDate(),
            end: moment(date).endOf('day').toDate()
        }

        setSelectedRange(range);
    }

    const handleStartTime = (value) => {
        let parsedTime = value.split(':');

        let range = {
            start: moment(selectedRange.start).set(
                {
                    hour: parsedTime[0],
                    minute: parsedTime[1],
                    second: parsedTime[2]
                }).toDate(),
            end: selectedRange.end
        }

        setSelectedRange(range);
    }

    const handleEndDate = (date) => {
        let range = {
            start: selectedRange.start,
            end: moment(date).endOf('day').toDate()
        }

        setSelectedRange(range);
    }

    const handleEndTime = (value) => {
        let parsedTime = value.split(':');

        let range = {
            start: selectedRange.start,
            end: moment(selectedRange.end).set(
                {
                    hour: parsedTime[0],
                    minute: parsedTime[1],
                    second: parsedTime[2]
                }).toDate()
        }

        setSelectedRange(range);
    }

    const handleViewType = (searchText, dataSource) => {
        var value = dataSource.filter(arr => arr.Text == searchText)[0];

        if (value) {
            setViewType(value.Id);
        }
        else {
            setViewType('');
        }
    }

    const handleEventType = (searchText, dataSource) => {
        var value = dataSource.filter(arr => arr.Text == searchText)[0];

        if (value) {
            setEventType(value.Id);
        }
        else {
            setEventType('');
        }
    }

    const submit = () => {
        var erroCampo = false;

        if (!erroCampo) {
            setSending(true);

            let startDAte = moment(selectedRange.start).format('DD-MM-YYYY HH:mm:ss');
            let endDate = moment(selectedRange.end).format('DD-MM-YYYY HH:mm:ss')

            let equipamentos = selectedEquipment.map((e, i) => {
                return `equipamentos[${i}]=${e}`;
            }).join('&');

            let customShiftStart = "00:00:00";
            let customShiftEnd = "23:59:59";

            let hasCustomShift = selectedShifts.map(c => c.id).includes(0);

            if (hasCustomShift) {
                customShiftStart = selectedCustomShift.startTime;
                customShiftEnd = selectedCustomShift.endTime;
            }

            let params = `/${startDAte}/${endDate}/${hasCustomShift}?${equipamentos}&dataInicioTurno=${customShiftStart}&dataFimTurno=${customShiftEnd}`;

            params += `&desconsiderarSetups=${disregardSetups}`;
            // params += `&retornaDadosCompletos=${completeData}`;

            params += `&tempoConsideradoPareto=${viewType}`;
            params += `&tipoEventoConsiderado=${eventType}`;

            let turnos = selectedShifts.map((e, i) => {
                return `turnos[${i}]=${e.id}`;
            }).join('&');

            if (turnos) {
                params += `&${turnos}`;
            }

            return requestRelatorioXLSX(
                APIEndpoints.RELATORIO_PARETO + params,
                t("titles.paretoReport"))
                .then(() => handleCloseModal())
                .catch(error => {
                    console.log(error);
                    setError({
                        message: t("errors.cannotGenerateReport")
                    })
                })
                .finally(() => setSending(false));
        }
    }

    const handleCloseModal = () => {
        closeModal ? closeModal() : null;
    }

    const modalError = () => {
        return (
            <div className="modal" style={{ width: '500px', height: '100%' }}>
                <div>
                    <div style={{ textAlign: 'left', fontWeight: 'bold' }} >
                        {t("labels.warning", { ns: 'common' })}
                    </div>
                    <div>
                        <i className="fa fa-close cursor-pointer" onClick={handleCloseModal} />
                    </div>
                </div>
                <div style={{ textAlign: 'left' }} >
                    {error.message}
                </div>
                {
                    error.support ?
                        <div style={{ paddingTop: '1em' }} >
                            E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
                        </div>
                        :
                        null
                }
                <NButtonGroup>
                    <NButton style={{ backgroundColor: '#2D4F7F', color: 'white' }} onClick={handleCloseModal}>OK</NButton>
                </NButtonGroup>
            </div>
        )
    }

    return <>
        {loading ?
            <div className="report_pareto">
                <div className={'loading-wrapper'}>
                    <MDSpinner singleColor='#2D4F7F' size={60} />
                </div>
            </div>
            :
            error ?
                modalError()
                :
                <div className="report_pareto">
                    <NForm style={{ minWidth: '36em' }}>
                        <NFormTitle>{t("titles.paretoReport")}</NFormTitle>
                        <div className={'flex flex-flow-col flex-just-between'} style={{ marginTop: '-2em', height: '15em', paddingBottom: '3em' }}>
                            <span style={{ paddingTop: '1em' }}>
                                <AutoComplete
                                    textFieldStyle={{ marginTop: '-10px' }}
                                    fullWidth={true}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    floatingLabelStyle={{ color: '#5B5B5B' }}
                                    listStyle={{ width: '100%' }}
                                    floatingLabelText={t("labels.equipment")}
                                    title={t("labels.equipment")}
                                    onUpdateInput={handleEquipmentSelect}
                                    filter={AutoComplete.fuzzyFilter}
                                    dataSource={equipment.filter(e => !e.isGroup)}
                                    dataSourceConfig={{ value: 'id', text: 'name' }}
                                    openOnFocus={true}
                                    popoverProps={{ className: 'autocomplete' }} />
                            </span>

                            <span>
                                <AutoComplete
                                    textFieldStyle={{ marginTop: '-10px' }}
                                    fullWidth={true}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    floatingLabelStyle={{ color: '#5B5B5B' }}
                                    listStyle={{ width: '100%' }}
                                    floatingLabelText={t("labels.viewType")}
                                    title={t("labels.viewType")}
                                    onUpdateInput={(searchText, dataSource) => { handleViewType(searchText, dataSource) }}
                                    filter={AutoComplete.fuzzyFilter}
                                    dataSource={optViewType}
                                    dataSourceConfig={{
                                        value: 'Id',
                                        text: 'Text'
                                    }}
                                    openOnFocus={true}
                                    popoverProps={{ className: 'autocomplete' }} />
                            </span>

                            <span>
                                <AutoComplete
                                    textFieldStyle={{ marginTop: '-10px' }}
                                    fullWidth={true}
                                    underlineFocusStyle={{ borderColor: '#000000' }}
                                    floatingLabelStyle={{ color: '#5B5B5B' }}
                                    listStyle={{ width: '100%' }}
                                    floatingLabelText={t("labels.eventTypes")}
                                    title={t("labels.eventTypes")}
                                    onUpdateInput={(searchText, dataSource) => { handleEventType(searchText, dataSource) }}
                                    filter={AutoComplete.fuzzyFilter}
                                    dataSource={optEventType}
                                    dataSourceConfig={{
                                        value: 'Id',
                                        text: 'Text'
                                    }}
                                    openOnFocus={true}
                                    popoverProps={{ className: 'autocomplete' }} />
                            </span>

                            <div className={'flex'} style={{ maxWidth: '33em', paddingTop: '1em' }}>
                                <div style={{ width: '56.5%' }}>
                                    <MultiSelect
                                        isMulti
                                        value={selectedShifts}
                                        options={shifts}
                                        allowSelectAll={false}
                                        placeholder={t("labels.shift")}
                                        onGetOptionLabel={(option) => option.name}
                                        onGetOptionValue={(option) => option.id}
                                        onSelectOption={(e) => setSelectedShifts(e)}
                                        style={{ marginLeft: '0px' }}
                                    />
                                </div>
                                {
                                    selectedShifts.map(c => c.id).includes(0)
                                        ?
                                        <div style={{ width: '40%', marginLeft: '2em', marginTop: '-2em' }} className={'flex'}>
                                            <NInputTime
                                                type='time'
                                                step={1}
                                                label={t("labels.startHour")}
                                                style={{ marginRight: 10 }}
                                                value={selectedCustomShift.startTime}
                                                onChange={(_, value) => setSelectedCustomShift({ startTime: value, endTime: selectedCustomShift.endTime })}
                                                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9em', background: '#fff' }}
                                            />

                                            <NInputTime
                                                type='time'
                                                step={1}
                                                label={t("labels.endHour")}
                                                value={selectedCustomShift.endTime}
                                                onChange={(_, value) => setSelectedCustomShift({ startTime: selectedCustomShift.startTime, endTime: value })}
                                                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '.9em', background: '#fff' }}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>

                        <br />
                        <Divider />

                        <h3 style={{ paddingTop: '1.5em', paddingBottom: '1.5em', fontSize: '.8em', textTransform: 'uppercase' }}> {t("labels.selectPeriod")} <span style={{ fontSize: '.7em' }} > ({t("labels.days")}) </span> </h3>
                        <div className="control-group">
                            <div style={{ textAlign: 'center' }}>
                                <label>7</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 7} value={7} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label>15</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 15} value={15} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label>30</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 30} value={30} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label>90</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 90} value={90} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                            <Divider className={styles.divider_periodo} />
                            <div style={{ textAlign: 'center' }}>
                                <label style={{ marginLeft: '-2em' }}>{t("labels.customize", { ns: 'common' })}</label>
                                <label className="control control-radio">
                                    <input checked={selectedPeriod == 0} value={0} type="radio" name="periodo" onChange={handlePeriodChanged} />
                                    <div className="control_indicator"></div>
                                </label>
                            </div>
                        </div>

                        {
                            selectedPeriod == 0
                                ?
                                <>
                                    <br />
                                    <div>
                                        <div className={'flex'} style={{ marginTop: '-2em', marginBottom: '1em' }}>
                                            <div style={{ margin: '0 0.8em', width: '25%' }}>
                                                <NDatePickerInput
                                                    id="start_date"
                                                    label={t("labels.startDate")}
                                                    value={moment(selectedRange.start)}
                                                    maxDate={moment()}
                                                    onChange={handleStartDate}
                                                    onChangeRaw={(_, date) => { handleStartDate(date) }}
                                                    autoComplete="off" />
                                            </div>
                                            <NInputTime
                                                type='time'
                                                step={1}
                                                label={t("labels.startHour")}
                                                style={{ width: '28%', marginRight: '3.5%' }}
                                                value={moment(selectedRange.start).format('HH:mm:ss')}
                                                onChange={(_, value) => handleStartTime(value)}
                                                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'uppercase' }}
                                            />

                                            <div style={{ margin: '0 0.8em', width: '25%' }}>
                                                <NDatePickerInput
                                                    id="end_date"
                                                    value={moment(selectedRange.end)}
                                                    minDate={selectedRange.start ? selectedRange.start : null}
                                                    maxDate={selectedRange.start && moment().diff(moment(selectedRange.start), 'days') > 30 ? moment(selectedRange.start).add(30, 'days') : moment()}
                                                    label={t("labels.endDate")}
                                                    onChange={date => handleEndDate(date)}
                                                    onChangeRaw={(_, date) => handleEndDate(date)}
                                                    autoComplete="off" />
                                            </div>

                                            <NInputTime
                                                type='time'
                                                step={1}
                                                label={t("labels.endHour")}
                                                style={{ width: '26%', marginRight: '3.5%' }}
                                                value={moment(selectedRange.end).format('HH:mm:ss')}
                                                onChange={(_, value) => handleEndTime(value)}
                                                floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'uppercase' }}
                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }

                        <Divider />

                        <h3 style={{ paddingTop: '1.5em', paddingBottom: '1.5em', fontSize: '.8em', justifyContent: 'space-between', textTransform: 'uppercase' }}> {t("labels.otherOptions")}</h3>
                        <div className={'flex flex-flow-col flex-just-between'} style={{ paddingBottom: '1.5em' }} >
                            <div>
                                <Checkbox
                                    label={t("labels.disregardSetups")}
                                    checked={disregardSetups}
                                    onCheck={((_, value) => setDisregardSetups(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div>
                            {/* <div>
                                <Checkbox
                                    label={t("labels.completeData")}
                                    checked={completeData}
                                    onCheck={((_, value) => setCompleteData(value))}
                                    iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
                                />
                            </div> */}
                        </div>

                        <div className={'flex flex-just-end'}>
                            <RaisedButton
                                onClick={submit}
                                disabled={selectedEquipment.length === 0 || viewType === '' || eventType === '' || sending}
                                backgroundColor='#2D4F7F'
                                buttonStyle={{ fontFamily: '' }}
                                style={{ marginRight: '1em' }}
                                labelColor='#FFF'
                                label={t("labels.generate", { ns: 'common' })} />
                            <RaisedButton onClick={handleCloseModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} label={t("labels.cancel", { ns: 'common' })} />
                        </div>
                    </NForm>
                </div>
        }
    </>
}

export default ModalRelatorioPareto;