import $ from 'jquery';
import React from 'react';
import moment from 'moment';
import MDSpinner from "react-md-spinner";
import { observer } from 'mobx-react';
import { AutoComplete } from 'material-ui';
import TextField from 'material-ui/TextField';
import Container from '../../common/Container';
import RaisedButton from 'material-ui/RaisedButton';
import { request, requestAll } from '../../common/request';
import { NDatePickerInput, alertBox, NInputTime, NInputNumber } from '../../common/NForm';
import styles from './batches.css';
import BatchesList from './BatchesList';
import { hasRole } from '../../helper/SecurityHelper';
import {
  respostaLotes,
  requisicaoLotes,
  respostaProdutos
} from '../../common/mappers';
import { withTranslation } from 'react-i18next';
import Utils from '../../utils/Utils';
import UploadModal from './UploadModal';

@observer
class BatchesPage extends Container {
  constructor() {
    super();
    this.state = {
      modal: false,
      uploadModalOpen: false,
      field: '',
      lotes: [],
      produto: '',
      produtoTexto: '',
      tipoLoteText: '',
      num: '',
      numSecundario: '',
      tipoLote: '',
      opts: [],
      loteRelacionado: '',
      numeroRelacionado: '',
      rotasProdutosId: '',
      sessionsId: '',
      idProduto: '',
      dtRelease: '',
      dtReleaseDate: '',
      dtReleaseTime: '00:00',
      dataSource: '',
      loadingPage: true,
      erroProduto: false,
      erroTipoLote: false,
      erroNum: false,
      erroDtReleaseDate: false,
      erroDtReleaseTime: false,
      loteTemLink: false,
      linkPiPA: [],
      produtos: [],
      isValid: true,
      hasMoreLotes: true,
      searchText: '',
      searching: false,
      paginationPage: 0,
      waitingRequest: false,
      listaProdutos: [],
      botaoDesabilitado: false,
      tamanho: undefined,
      regexChaveLote: /[^a-zA-Z\d\-\_]/g,
      searchTextTipoLote: '',
      searchTextProduto: ''
    };

    this.carregarLotes = this.carregarLotes.bind(this);
    this.delete = this.delete.bind(this);
    this.lotesComPredecessorSucessor = this.lotesComPredecessorSucessor.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.onOpenUpload = this.onOpenUpload.bind(this);
    this.validation = this.validation.bind(this);
    this.handleProduto = this.handleProduto.bind(this);
    this.handleTipoLote = this.handleTipoLote.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.maxLength = this.maxLength.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearStateOnCancel = this.clearStateOnCancel.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
    this.handleProdutoClick = this.handleProdutoClick.bind(this);
    this.loadOpts = this.loadOpts.bind(this);
  }

  componentDidMount() {
    this.carregarLotes();
    this.carregarProdutos();
  }

  carregarLotes() {
    request(
      this.endpoints.LOTES
      + `?limit=${this.paginacao.ITENS_POR_PAGINA}`
      + `&offset=${this.state.paginationPage * this.paginacao.ITENS_POR_PAGINA}`
      + `&pesquisa=${this.state.searchText}`,
      { method: 'get' }
    )
      .then(response => {
        if (response.status === 200)
          return response.json();
      })
      .then(data => {
        data = respostaLotes(data);
        let listaLotes = (this.state.searching && data.length > 0)
          ? data
          : [...this.state.lotes, ...data];

        if (this.state.searching && data.length === 0) {
          alertBox(this.props.t("emptySearch"), this.props.t("searchBatches", { batches: this.utils.getReferenciaLotesOrdens() }), 'warning');
        }

        this.loadOpts();

        this.setState(state => {
          return {
            lotes: listaLotes,
            hasMoreLotes: (data.length === this.paginacao.ITENS_POR_PAGINA),
            searching: false,
            paginationPage: state.paginationPage + 1,
            loadingPage: false,
            waitingRequest: false
          }
        });
      });
  }

  handleProdutoClick(event) {
    if (event.target.value !== '')
      this.setState({ produtoTexto: '' })
  }

  loadOpts() {
    this.setState({
      opts: [
        {
          value: 0,
          text: this.props.t("standardBatch")
        },
        {
          value: 1,
          text: this.props.t("reprocess")
        },
        {
          value: 2,
          text: this.props.t("qualitySample")
        }
      ]
    });
  }

  carregarProdutos() {
    request(
      this.endpoints.PRODUTO + "/lote",
      { method: 'get' }
    )
      .then(response => {
        if (response.ok)
          return response.json();
      })
      .then(data => {
        data = respostaProdutos(data);

        this.setState({
          produtos: data,
          listaProdutos: this.listarProdutos(data)
        });
      })
  }

  listarProdutos(produtos) {
    let listaProdutos = [];

    for (let i = 0; i < produtos.length; i++) {
      if (produtos[i] !== undefined) {
        listaProdutos.push({ text: produtos[i].sku + ' - ' + produtos[i].name, value: produtos[i].id, sistema: produtos[i].sistema });
      }
    }

    return listaProdutos;
  }

  setSearchText(textToFind, searching) {
    this.setState({
      searchText: textToFind,
      searching: searching,
      paginationPage: 0,
      waitingRequest: true
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      uploadModalOpen: false,
      modalType: ''
    });
  }

  delete(loteId, loteTemLink) {
    request(this.endpoints.LOTES + `/${loteId}`, {
      method: 'DELETE'
    }).then(response => {
      if (response.status == 200 || response.status == 201) {
        alertBox(this.props.t("messages.successDefault", { batch: this.utils.getReferenciaLoteOrdemExcluido() }), this.utils.getReferenciaLoteOrdem(), 'success');
        this.clearPosition();
        this.carregarLotes();
        return response;
      } else {
        alertBox(this.props.t("messages.deleteError", { batch: this.utils.getReferenciaLoteOrdemInformado() }), this.utils.getReferenciaLoteOrdem(), 'error');
      }
    }).then(() => requestAll([
      {
        url: this.endpoints.LOTES,
        modelo: 'batchList',
        modificador: respostaLotes
      }
    ], this)).catch(() => {
      alertBox(this.props.t("messages.deleteError", { batch: this.utils.getReferenciaLoteOrdemInformado() }), this.utils.getReferenciaLoteOrdem(), 'error');
    });

    this.setState({
      modal: false,
      loteTemLink: false
    });
  }

  clearPosition() {
    this.setSearchText('', true);
    $('.table_overflow').scrollTop(0);
  }

  onOpenUpload() {
    this.handleUpload();
  }

  handleUpload() {
    this.setState({
      uploadModalOpen: true
    });
  }

  handleModal(b, t) {
    if (b && b.dt_release) {
      b.dt_release_date = moment(b.dt_release);
      b.dt_release_time = moment(b.dt_release).format('HH:mm');
    }

    if (t == 'up' || t == 'upBuild' || t == 'del' || t == 'copy' || t == 'related') {
      this.state.produtos.filter((produto) => {
        if (produto.id === b.fk_id_products) {
          this.setState({
            produtoTexto: produto.sku + ' - ' + produto.name
          });
        }
      });

      this.setState({
        modal: !this.state.modal,
        field: b,
        idProduto: b.fk_id_products,
        num: t == 'copy' ? '' : b.num,
        numSecundario: t == 'copy' ? '' : b.numSecundario,
        tipoLote: t != 'copy' ? (b.tipoLote == null ? '0' : b.tipoLote) : (b.tipoLote == null || b.tipoLote == '0' ? '2' : b.tipoLote),
        loteRelacionado: t == 'copy' ? b.num : '',
        numeroRelacionado: b.numeroRelacionado,
        rotasProdutosId: t != 'copy' ? b.rotasProdutosId : '',
        sessionsId: t != 'copy' ? b.sessionsId : '',
        dtReleaseDate: t == 'copy' ? '' : b.dt_release_date,
        dtReleaseTime: t == 'copy' ? '00:00' : b.dt_release_time,
        tamanho: b.tamanho,
        typeModal: t,
        tipoLoteText: this.state.opts.filter(f => f.value == b.tipoLote)[0].text
      });

    } else {
      this.setState({
        modal: !this.state.modal,
        field: '',
        idProduto: '',
        num: '',
        numSecundario: '',
        tipoLote: '0',
        loteRelacionado: '',
        numeroRelacionado: '',
        rotasProdutosId: '',
        sessionsId: '',
        dtReleaseDate: '',
        produtoTexto: '',
        dtReleaseTime: '00:00',
        tamanho: undefined,
        typeModal: t
      });
    }

    let chave = 'RegexChaveLote';

    request(this.endpoints.CONFIGURACAO_PARAMETROS + `?chave=${chave}`, {
      method: 'get'
    }).then(res => {
      if (res.ok) {
        res.json()
          .then(data => {
            if (data != null) {
              this.setState({ regexChaveLote: new RegExp(data.valor, 'g') })
            }
          })
      }
    });
  }

  habilitarBotao() {
    this.setState({
      botaoDesabilitado: false
    })
  }

  desabilitarBotao() {
    this.setState({
      botaoDesabilitado: true
    });
  }

  handleStart(date) {
    this.setState({
      dtReleaseDate: date,
      erroDtReleaseDate: false,
      botaoDesabilitado: false,
    });
  }

  validation(event) {
    if (this.state.typeModal == 'add') {
      let batch = this.store.batchList.find(b => b.num == event.target.value);

      if (batch) {
        this.store.errors = this.utils.getReferenciaLoteOrdemJaCadastrado();
      }
    }
  }

  handleProduto = (searchText, dataSource, params) => {
    this.setState({ searchTextProduto: searchText, idProduto: '', produtoTexto: '' });

    var value = dataSource.filter(arr => arr.text == searchText)[0];

    if (value) {
      this.setState({
        idProduto: value.value,
        produtoTexto: value.text,
        erroProduto: false,
        botaoDesabilitado: false,
      });
    }
  }

  handleTipoLote = (searchText, dataSource, params) => {
    this.setState({ searchTextTipoLote: searchText, tipoLote: '', tipoLoteText: '' });

    var value = dataSource.filter(arr => arr.text == searchText)[0];

    if (value) {
      this.setState({
        tipoLote: value.value,
        tipoLoteText: value.text,
        erroTipoLote: false
      });
    }
  }

  handleSubmit() {
    this.desabilitarBotao();

    let erro = false;
    let {
      dtReleaseDate,
      dtReleaseTime,
      idProduto,
      num,
      numSecundario,
      tipoLote,
      loteRelacionado,
      rotasProdutosId,
      sessionsId,
      tamanho,
    } = this.state;

    var dt_release = moment(moment(dtReleaseDate).format('YYYY-MM-DD') + ' ' + (dtReleaseTime), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

    let data = {
      fk_id_products: idProduto,
      num: num,
      numSecundario: numSecundario,
      tipoLote: tipoLote,
      loteRelacionado: loteRelacionado,
      rotasProdutosId: rotasProdutosId,
      sessionsId: sessionsId,
      dt_release: dt_release,
      tamanho: tamanho
    };

    this.setState({
      erroProduto: !idProduto,
      erroNum: !num,
      erroDtReleaseDate: !dtReleaseDate,
      erroDtReleaseTime: !dtReleaseTime,
      erroTipoLote: tipoLote === ""
    });

    if (!idProduto || !num || !dtReleaseDate || !dtReleaseTime || tipoLote === "") {
      erro = true;
    }

    if (!erro) {
      if (this.state.typeModal == 'up' || this.state.typeModal == 'upBuild') {
        request(this.endpoints.LOTES + `/${this.state.field.id}`, {
          method: 'put',
          body: JSON.stringify(requisicaoLotes(data))
        }).then(response => {
          if (response.ok) {
            alertBox(this.props.t("messages.successDefault", { batch: this.utils.getReferenciaLoteOrdemAlterado() }), this.utils.getReferenciaLoteOrdem(), 'success');
            this.clearState();
            this.setState({
              modal: false,
            });
            return response;
          } else {
            alertBox(this.props.t("messages.updateError", { batch: this.utils.getReferenciaLoteOrdemInformado() }), this.utils.getReferenciaLoteOrdem(), 'error');
          }
        }).then(() => {
          this.clearPosition();
          this.carregarLotes();

        }).catch(() => {
          alertBox(this.props.t("messages.updateError", { batch: this.utils.getReferenciaLoteOrdemInformado() }), this.utils.getReferenciaLoteOrdem(), 'error');

        }).finally(() => {
          this.habilitarBotao();
        });

      } else {
        request(this.endpoints.LOTES, {
          method: 'post',
          body: JSON.stringify(requisicaoLotes(data))
        }).then(response => {
          if (response.status == 200 || response.status == 201) {
            this.clearPosition();
            this.carregarLotes();
            this.clearState();
            this.setState({
              modal: false,
            });
            alertBox(this.props.t("messages.successDefault", { batch: this.utils.getReferenciaLoteOrdemCriado() }), this.utils.getReferenciaLoteOrdem(), 'success');
          } else if (response.status == 409) {
            alertBox(this.props.t("messages.alreadyExists", { batch: this.utils.getReferenciaLoteOrdemInformado(), batch2: this.utils.getReferenciaoLoteaOrdem().toLowerCase() }), this.utils.getReferenciaLoteOrdem(), 'error');
          } else {
            alertBox(this.props.t("messages.createError", { batch: this.utils.getReferenciaLoteOrdemInformado() }), this.utils.getReferenciaLoteOrdem(), 'error');
          }
        })
          .catch(() => {
            alertBox(this.props.t("messages.createError", { batch: this.utils.getReferenciaLoteOrdemInformado() }), this.utils.getReferenciaLoteOrdem(), 'error');
          }).finally(() => this.habilitarBotao());
      }
    }
  }

  clearState() {
    this.setState({
      fk_id_products: '',
      num: '',
      dt_release_date: '',
      dt_release_time: '',
      tamanho: undefined,
      tipoLote: '',
      tipoLoteText: '',
      loteRelacionado: '',
      numeroRelacionado: '',
      rotasProdutosId: '',
      sessionsId: '',
      searchTextTipoLote: '',
      searchTextProduto: ''
    });

    this.loadOpts();
  }

  lotesComPredecessorSucessor(lote) {
    request(this.endpoints.LOTES + `/${lote.id}/predecessores-sucessores`, {
      method: 'get'
    }).then(request => {
      return request.json();
    }).then(data => {
      this.setState({
        field: lote,
        modal: true,
        typeModal: 'del',
        loteTemLink: data.temLinkPIPA
      });
    });
  }

  modalDeletarLote() {
    var { field, loteTemLink } = this.state;

    return (
      <div className="modal" style={{ width: '300px', height: '100%' }}>
        <div>
          <div>
            <h3>
              {
                loteTemLink ?
                  this.props.t("messages.haveBPFPLink", { batch: this.utils.getReferenciaEsteLoteEstaOrdem() })
                  :
                  null
              }
              <br />
              {
                loteTemLink ?
                  this.props.t("messages.sureDelete", { batch: this.utils.getReferenciaEsteLoteEstaOrdem().toLowerCase() })
                  :
                  this.props.t("messages.sureDeleteBatch", { batch: this.utils.getReferenciaEsteLoteEstaOrdem().toLowerCase() })
              }
            </h3>
          </div>
        </div>
        <div>
          <div className={styles.box}>
            <div>
              <h2><strong>{field.num}</strong></h2>
            </div>
            <div style={{ marginTop: '20px' }}>
              <RaisedButton backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }}
                style={{ fontWeight: 100 }}
                labelColor='#FFF' label={this.props.t("yes")}
                onClick={() => this.delete(field.id, loteTemLink)} />
              <RaisedButton onClick={() => { this.setState({ modal: false, produtoTexto: '', idProduto: '', num: '', dtReleaseDate: '', dtReleaseTime: '00:00', loteTemLink: false }) }} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '1em' }} label={this.props.t("no")} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  modalRelated(ref, numeroRelacionado) {
    return (
      <div className="modal" style={{ width: '300px', height: '100%' }}>
        <div>
          <div>
            <h3>
              {this.props.t("batchRelation", { batch: ref })}
            </h3>
          </div>
        </div>
        <div>
          <div className={styles.box}>
            <div>
              <h2><strong>{numeroRelacionado}</strong></h2>
            </div>
          </div>
          <div className={styles.closeRelated}>
            <RaisedButton onClick={() => { this.setState({ modal: false }) }}
              backgroundColor='#FFF'
              buttonStyle={{ fontFamily: '' }}
              label={this.props.t("close")} />
          </div>
        </div>
      </div>
    );
  }

  maxLength(event, campoState) {
    if (event.target.value.length >= 30) {
      this.setState({
        [campoState]: event.target.value.slice(0, -1)
      })
    }
  }

  handleChange(value, isValid) {
    this.setState(() => {
      return {
        isValid: isValid,
        dtReleaseTime: value,
        erroDtReleaseTime: false
      }
    })
  }

  clearStateOnCancel() {
    this.setState({
      modal: false,
      uploadModalOpen: false,
      produtoTexto: '',
      idProduto: '',
      num: '',
      numSecundario: '',
      tipoLote: '',
      tipoLoteText: '',
      loteRelacionado: '',
      numeroRelacionado: '',
      rotasProdutosId: '',
      sessionsId: '',
      dtReleaseDate: '',
      dtReleaseTime: '00:00',
      erroProduto: false,
      erroNum: false,
      erroDtReleaseDate: false,
      erroDtReleaseTime: false,
      isValid: true,
      botaoDesabilitado: false,
      tamanho: undefined,
      searchTextTipoLote: '',
      searchTextProduto: ''
    });

    this.loadOpts();
  }

  contentModal() {
    let {
      produtoTexto,
      listaProdutos,
    } = this.state;

    if (this.state.typeModal == 'add') {
      listaProdutos = listaProdutos.filter(f => !f.sistema);
    }

    let referenciaSecundaria = localStorage.getItem('ReferenciaSecundaria');

    if (this.state.typeModal == 'del') {
      return this.modalDeletarLote();
    }
    else if (this.state.typeModal == 'related') {
      return this.modalRelated(Utils.getReferenciaLoteOrdem(), this.state.numeroRelacionado);
    }
    else {
      return (
        <div style={{ width: '85%' }} className="modal">
          <div style={{ padding: '10px', fontWeight: 'bold', textAlign: 'center', textTransform: 'capitalize' }}>
            {this.state.typeModal == 'add' ? this.props.t("batchRegister", { batch: this.utils.getReferenciaLoteOrdem() })
              : (this.state.typeModal == 'copy' ? this.props.t("qualitySample")
                : this.props.t("batchUpdate", { batch: this.utils.getReferenciaLoteOrdem() }))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
              {
                this.state.typeModal == 'copy' ?
                  <div>
                    <AutoComplete
                      value={this.state.opts.find(opt => opt.value == 2).text}
                      floatingLabelText={this.props.t("batchType", { batch: this.utils.getReferenciaLoteOrdem() })}
                      dataSource={this.state.opts.filter(tipo => tipo.value == 2)}
                      onChange={() => this.setState({ tipoLote: 2 })}
                      disabled={true}
                      searchText={this.state.opts.find(opt => opt.value == 2).text}
                    />
                  </div>
                  :
                  <div>
                    <AutoComplete
                      value={this.state.tipoLoteText}
                      searchText={this.state.searchTextTipoLote == '' ? this.state.tipoLoteText : this.state.searchTextTipoLote}
                      onUpdateInput={this.handleTipoLote}
                      onClick={() => { this.setState({ tipoLote: '', tipoLoteText: '', searchTextTipoLote: '' }) }}
                      onChange={(event, value) => { this.setState({ tipoLoteText: value, erroTipoLote: false }) }}
                      textFieldStyle={{ width: '100%', fontSize: '1em' }}
                      dataSource={this.state.opts.filter(tipo => tipo.value != 1)}
                      popoverProps={{ className: 'autocomplete' }}
                      floatingLabelText={this.props.t("batchType", { batch: this.utils.getReferenciaLoteOrdem() })}
                      ref={ref => this.autoComplete = ref}
                      underlineFocusStyle={{ borderColor: '#000000' }}
                      fullWidth={true}
                      id={this.props.t("batchType", { batch: this.utils.getReferenciaLoteOrdem() })}
                      name={this.props.t("batchType", { batch: this.utils.getReferenciaLoteOrdem() })}
                      filter={AutoComplete.fuzzyFilter}
                      errorText={this.state.erroTipoLote ? this.props.t("requiredField") : null}
                      floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                      openOnFocus={true}
                      disabled={this.state.typeModal == 'up' || this.state.typeModal == 'upBuild' || this.state.typeModal === 'copy'}
                    />
                  </div>
              }
              {
                this.state.typeModal == 'copy' ?
                  <TextField
                    id="loteRelacionado"
                    style={{ width: '100%' }}
                    floatingLabelText={this.props.t("mainBatch", { batch: Utils.getReferenciaLoteOrdem() })}
                    underlineFocusStyle={{ borderColor: '#000000' }}
                    value={this.state.loteRelacionado}
                    onKeyUp={(event) => this.maxLength(event, 'loteRelacionado')}
                    floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                    disabled={true}
                  />
                  :
                  null
              }
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <AutoComplete
                  value={produtoTexto}
                  searchText={this.state.searchTextProduto == '' ? produtoTexto : this.state.searchTextProduto}
                  onClick={() => { this.setState({ produtoTexto: '', idProduto: '', searchTextProduto: '' }) }}
                  onUpdateInput={this.handleProduto}
                  onChange={(event, value) => { this.setState({ produtoTexto: value, erroProduto: false }) }}
                  textFieldStyle={{ width: '100%', fontSize: '1em' }}
                  dataSource={listaProdutos}
                  popoverProps={{ className: 'autocomplete' }}
                  floatingLabelText={this.props.t('product')}
                  ref={ref => this.autoComplete = ref}
                  underlineFocusStyle={{ borderColor: '#000000' }}
                  fullWidth={true}
                  id={'produto'} name={'produto'}
                  filter={AutoComplete.fuzzyFilter}
                  underlineStyle={this.state.erroProduto ? { borderColor: 'red' } : null}
                  errorText={this.state.erroProduto ? this.props.t("requiredField") : null}
                  floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                  openOnFocus={true}
                  disabled={this.state.typeModal === 'upBuild' || this.state.typeModal === 'copy'}
                />

                <TextField
                  id="num"
                  style={{ width: '100%' }}
                  floatingLabelText={this.state.typeModal === "copy" || this.state.tipoLote == '2' ? this.props.t("sample") : Utils.getReferenciaLoteOrdem()}
                  underlineFocusStyle={{ borderColor: '#000000' }}
                  value={this.state.num}
                  onKeyUp={(event) => this.maxLength(event, 'num')}
                  onChange={(event, value) => {
                    this.setState({
                      num: value.replace(this.state.regexChaveLote, ""),
                      erroNum: false,
                      botaoDesabilitado: false
                    })
                  }}
                  underlineStyle={this.state.erroNum ? { borderColor: 'red' } : null}
                  errorText={this.state.erroNum ? this.props.t("requiredField") : null}
                  floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                  disabled={this.state.typeModal === 'upBuild'}
                />

                {
                  referenciaSecundaria !== '' && this.state.typeModal != "copy" && this.state.tipoLote == "0" ?
                    <TextField
                      id="numSecundario"
                      style={{ width: '100%' }}
                      floatingLabelText={Utils.getReferenciaOrdemLote()}
                      underlineFocusStyle={{ borderColor: '#000000' }}
                      value={this.state.numSecundario}
                      onKeyUp={(event) => this.maxLength(event, 'numSecundario')}
                      onChange={(event, value) => {
                        this.setState({
                          numSecundario: value.replace(this.state.regexChaveLote, "")
                        })
                      }}
                      floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                      disabled={this.state.typeModal === 'upBuild'}
                    />
                    :
                    null
                }

                <NDatePickerInput
                  id='dtReleaseDate'
                  value={this.state.dtReleaseDate == '' ? this.setState({ dtReleaseDate: moment() }) : moment(this.state.dtReleaseDate)}
                  onChange={date => this.handleStart(date)}
                  onChangeRaw={(_, date) => this.handleStart(date)}
                  require={this.state.erroDtReleaseDate}
                  maxDate={moment()}
                  label={this.props.t("releaseDate")}
                  style={{ width: '100%' }}
                />

                <NInputTime
                  handleChange={this.handleChange}
                  type={'text'}
                  withoutSeconds={true}
                  id="dtReleaseTime"
                  value={this.state.dtReleaseTime}
                  require={this.state.erroDtReleaseTime}
                  floatingLabelStyle={{
                    color: '#5B5B5B',
                    textTransform: 'uppercase',
                    background: '#fff'
                  }}
                  underlineFocusStyle={{ borderColor: '#000000' }}
                  label={this.props.t("releaseHour")}
                />

                <NInputNumber
                  onChange={(_event, value) => { this.setState({ tamanho: value.replace(/\D/g, '') }) }}
                  type="numeric"
                  underlineFocusStyle={{ borderColor: '#000000' }}
                  label={this.props.t("size")}
                  value={this.state.tamanho}
                  fullWidth={true}
                />

              </div>
            </div>
          </div>
          <div className={'flex flex-just-end'} style={{ paddingTop: '30px', marginRight: '1em' }} >
            <RaisedButton
              label={this.props.t("save")}
              backgroundColor='#2D4F7F'
              buttonStyle={{ fontFamily: '' }}
              style={{ fontWeight: 100 }}
              onClick={this.handleSubmit}
              labelColor='#FFF'
              disabled={(!hasRole('ROLE_LOTES_CRIAR') && this.state.typeModal === 'add') ||
                (!hasRole('ROLE_LOTES_ATUALIZAR') && (this.state.typeModal == 'up' || this.state.typeModal == 'upBuild')) || this.state.saving}
            />

            <RaisedButton onClick={() => {
              this.loadOpts(),
                this.setState({
                  modal: false,
                  produtoTexto: '',
                  idProduto: '',
                  num: '',
                  dtReleaseDate: '',
                  dtReleaseTime: '00:00',
                  erroProduto: false,
                  erroNum: false,
                  erroDtReleaseDate: false,
                  erroDtReleaseTime: false,
                  isValid: true,
                  tipoLote: '',
                  tipoLoteText: '',
                  searchTextTipoLote: '',
                  searchTextProduto: ''
                })
            }} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeight: 100, marginLeft: '1em' }} label={this.props.t("cancel")} />

          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container
          showSideBar
          modal={this.state.modal}
          styleModal={{ width: 350 }}
          contentModal={this.contentModal}
        >
          <BatchesList
            ref='list'
            store={this.store}
            handleModal={this.handleModal}
            showAlert={this.showAlert}
            utils={this.utils}
            lotesComPredecessorSucessor={this.lotesComPredecessorSucessor}
            carregarLotes={this.carregarLotes}
            lotes={this.state.lotes}
            hasMoreLotes={this.state.hasMoreLotes}
            searchTextFilterBox={this.state.searchText}
            setSearchText={this.setSearchText}
            waitingRequest={this.state.waitingRequest}
            t={this.props.t}
            onOpenUpload={this.onOpenUpload}
          />

          {this.state.uploadModalOpen &&
            <UploadModal
              open={this.state.uploadModalOpen}
              onSuccess={this.clearPosition}
              onCancel={this.closeModal}
            />
          }
        </Container>
      );
    }
  }
}

export default withTranslation('batches')(BatchesPage);