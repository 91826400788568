import React, { Component } from 'react';
import _ from 'lodash';
import listStyles from './list.scss';
import Equipment from './Equipment';
import { observer } from 'mobx-react';

@observer
export default class ListEquipments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipamentos: [],
      listWaitingOrder: [],
      listProcessOrder: []
    }

    this.calculateProperties = this.calculateProperties.bind(this);
  }

  componentDidMount() {
    this.calculateProperties();
  }

  componentDidUpdate(prevProps, prevState) {
    let codProd = this.props.events.filter(e => e.fk_id_table == 2)[0];

    if (prevProps.listWaiting != this.props.listWaiting) {
      let { listWarning, listWaiting } = this.props;

      let listWaitingOrder = listWarning
        .filter(b => codProd != undefined && b.fk_id_events !== codProd.id)
        .map(b => ({
          ...b,
          type: 'warningWaiting'
        }))
        .concat(
          listWaiting
            .map(b => ({
              ...b,
              type: 'waiting'
            }))
        )
        .sort((a, b) => {
          return new Date(a.reference_time).getTime() - new Date(b.reference_time).getTime();
        });

      this.setState({
        listWaitingOrder: listWaitingOrder
      });
    }

    if (prevProps.listProcess != this.props.listProcess || prevProps.listWarning != this.props.listWarning) {
      let { listWarning, listProcess } = this.props;

      let listProcessOrder = listWarning.filter(b => {
        if (codProd != undefined && b.fk_id_events == codProd.id) {
          return b;
        }
      })
        .map(b => ({
          ...b,
          type: 'warningProcess'
        }))
        .concat(
          listProcess
            .map(b => ({
              ...b,
              type: 'process'
            }))
        )
        .sort((a, b) => {
          return new Date(a.end_time).getTime() - new Date(b.end_time).getTime();
        });

      this.setState({
        listProcessOrder: listProcessOrder
      });
    }

    if (prevProps.equipments != this.props.equipments) {
      this.calculateProperties();
    }
  }

  calculateProperties() {
    let { equipments } = this.props;
    var greenHT, blackHT, greenLT, redLT;

    greenHT = this.props.parameters.find(p => p.type == 'holdingTime' && p.color == 'green');
    blackHT = this.props.parameters.find(p => p.type == 'holdingTime' && p.color == 'black');
    greenLT = this.props.parameters.find(p => p.type == 'leadtime' && p.color == 'green');
    redLT = this.props.parameters.find(p => p.type == 'leadtime' && p.color == 'red');

    var equipamentos = [];

    //Reordena o array de equipamentos de acordo com o nome.
    equipamentos = equipments.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    this.setState({
      equipamentos: equipamentos,
      greenHT: greenHT,
      blackHT: blackHT,
      greenLT: greenLT,
      redLT: redLT
    })
  }

  render() {
    let { isBlur, activeRoute, heightStage, sensores_data, sensores } = this.props;
    const equipamentosOrdenados = _.orderBy(this.state.equipamentos, ['ordem'], ['asc']);

    return (
      <div className={listStyles.list_equipments} style={{ height: heightStage }} onClick={isBlur ? activeRoute : () => { }}>
        {
          equipamentosOrdenados.map(equipment => {
            let oee = this.props.store.equipamentosOEE.find(oee => oee.id == equipment.id);
            let sensores = sensores_data !== undefined ? sensores_data.filter(sensor => sensor.fk_id_equipments == equipment.id) : [];

            return (
              <Equipment
                key={equipment.id}
                equipment={equipment}
                dataInicio={this.props.dataInicio}
                dataFim={this.props.dataFim}
                gargalos={this.props.gargalos}
                handleModal={this.props.handleModal}
                {...this.props}
                oee={oee == undefined ? {} : oee.oee}
                greenHT={this.state.greenHT} blackHT={this.state.blackHT} greenLT={this.state.greenLT} redLT={this.state.redLT}
                listWaiting={
                  this.state.listWaitingOrder.filter(batch => batch.fk_id_equipments == equipment.id && batch.master).sort((a, b) => {
                    return new Date(a.reference_time).getTime() - new Date(b.reference_time).getTime();
                  })
                }
                listProcess={
                  this.state.listProcessOrder.filter(batch => {
                    return batch.fk_id_equipments == equipment.id;
                  })
                }
              />
            );
          })
        }
      </div>
    );
  }
}