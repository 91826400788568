import { observable, action, computed } from "mobx";

class DisponibilidadeStore {
    @observable exibirDisponibilidadeZerada = false;

    @computed get ExibirDisponibilidadeZerada(){
        return this.exibirDisponibilidadeZerada;
    }

    @action setExibirDisponibilidade = exibirDisponibilidadeZerada => {
        this.exibirDisponibilidadeZerada = exibirDisponibilidadeZerada
    }
}

export default DisponibilidadeStore;
