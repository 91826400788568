import React from 'react';
import { observer } from 'mobx-react';
import MDSpinner from "react-md-spinner";
import ProductsList from './ProductsList';
import DeleteItem from '../../common/DeleteItem';
import Container from '../../common/Container';
import { alertBox } from '../../common/NForm';
import HoldingTime from './HoldingTime';
import { construirSequenciaRotasCSharp, fromSecondsToHours } from '../../common/helpers';
import { request, requestAll } from '../../common/request';
import {
  respostaAreaProdutivas,
  respostaProdutos,
  respostaEtapas,
  respostaProdutosLazy,
} from '../../common/mappers';
import styles from './product.scss';
import { Product } from '../../clients/Products/Product';
import ProductModal from './ProductModal';
import UploadModal from './UploadModal';
import { withTranslation } from 'react-i18next';
import Utils from '../../utils/Utils';

const updateStateAfterRequestAll = false;
const style = { width: 500, overflowY: 'auto' };

const DEFAULT_HT = {
  nome: 'Hold Time 1',
  tempo_total: '',
  ordem_destino: null,
  posicao_etapa_inicio: null,
  ordemdestino: null,
  posicao_etapa_fim: null,
  produto_destino: {},
  errors: {}
};

@observer
class ProductsPage extends Container {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      field: '',
      typeModal: '',
      style: style,
      holding_time: {
        product: {
          nome: '1 - Produto 1',
          stages: [],
        },
        holding_times: [],
        cur_ht: 0,
      },
      ativo: true,
      pi_pa: false,
      area: '',
      nome: '',
      codigo: '',
      Predecessor1: '',
      Predecessor2: '',
      Predecessor3: '',
      erroNOME: '',
      erroCODIGO: '',
      erroAREA: '',
      erroTIPO: '',
      erroPredecessor1: '',
      erroVinculo: '',
      loadingPage: true,
      estado: true,
      produtos: [],
      hasMoreProducts: true,
      searchText: '',
      searching: false,
      paginationPage: 0,
      waitingRequest: false,
      product: new Product()
    };

    this.baseState = this.state;
    delete this.baseState.modal;
    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.carregarProdutos = this.carregarProdutos.bind(this);
    this.clearPosition = this.clearPosition.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
    this.setStateHoldingTime = this.setStateHoldingTime.bind(this);
    this.buscarProdutos = this.buscarProdutos.bind(this);
    this.onLoadProducts = this.onLoadProducts.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onOpenUpload = this.onOpenUpload.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onLoadProducts() {
    let params = {
      limit: this.paginacao.ITENS_POR_PAGINA,
      offset: this.state.paginationPage * this.paginacao.ITENS_POR_PAGINA,
    }

    if (this.state.searchText) {
      params['pesquisa'] = this.state.searchText;
    }

    return Product.all(params).then(data => {
      if (data != null) {
        data = respostaProdutos(data);
        const isSearching = (this.state.searching && data.length > 0)
        let listaProdutos = isSearching ? data : [...this.state.produtos, ...data];

        if (data.length === 0 && this.state.searching) {
          alertBox(this.props.t('messages.emptySearch'), this.props.t('title'), 'warning');
        }

        this.setState(state => {
          return {
            searching: false,
            waitingRequest: false,
            produtos: listaProdutos,
            paginationPage: state.paginationPage + 1,
            hasMoreProducts: (data.length === this.paginacao.ITENS_POR_PAGINA),
          }
        });
      }
      else {
        this.setState(() => {
          return {
            searching: false,
            waitingRequest: false,
            produtos: [],
            paginationPage: 1,
            hasMoreProducts: false
          }
        })
      }
    });
  }

  carregarProdutos() {
    this.onLoadProducts().then(() => {
      this.setState({ loadingPage: false });
    });
  }

  setStateHoldingTime() {
    if (this.state.holding_time.product) {
      let produtosList = this.state.produtos;

      produtosList.map(p => p.id == this.state.holding_time.product.id
        ? (p.holding_times == 0 ? p.holding_times = 1 : p.holding_times = 0)
        : null
      );

      this.setState({
        produtos: produtosList,
      });
    }

    this.clearPosition();
    this.carregarProdutos();
  }

  setSearchText(textToFind, searching) {
    this.store.searchValue = textToFind;
    this.setState(() => {
      return {
        searchText: textToFind,
        searching: searching,
        paginationPage: 0,
        waitingRequest: true,
      }
    })
  }

  clearPosition() {
    var tableOverflow = document.getElementsByClassName('table_overflow');
    var position = tableOverflow.length > 0 ? document.getElementsByClassName('table_overflow')[0].scrollTop : 0;

    if (position == 0) {
      this.setSearchText('', true);
    }
    else {
      this.setSearchText('', true);
      document.getElementsByClassName('table_overflow')[0].scrollTo(0, 0);
    }
  }

  componentWillMount() {
    const requests = [
      {
        url: this.endpoints.AREA_PRODUTIVA,
        modelo: 'fields',
        modificador: respostaAreaProdutivas,
        acao: true
      }, {
        url: this.endpoints.ETAPA,
        modelo: 'stages',
        modificador: respostaEtapas,
        acao: true
      }
    ];

    this.setState({ loadingPage: true });
    this.onLoadProducts().then(() => {
      requestAll(requests, this, updateStateAfterRequestAll).then(() => {
        this.setState({ loadingPage: false });
      });
    });
  }

  delete(id) {
    request(this.endpoints.PRODUTO + '/' + id, {
      method: 'delete'
    })
      .then(response => {
        this.handleModal();
        this.clearPosition();
        this.carregarProdutos();

        if (response.status === 200) {
          alertBox(this.props.t('messages.deleteSuccess'), this.props.t('title'), 'success');
        } else if (response.status === 422) {
          var primaryReference = Utils.getReferenciaLotesOrdens().toLowerCase();

          alertBox(this.props.t('errors.deleteUnprocessable', { orders: primaryReference }), this.props.t('title'), 'error');
        } else {
          alertBox(this.props.t('errors.cannotDeleteProduct'), this.props.t('title'), 'error');
        }
      })
      .catch(() => {
        this.handleModal();
        this.clearPosition();
        this.carregarProdutos();

        alertBox(this.props.t('errors.cannotDeleteProduct'), this.props.t('title'), 'error');
      })
  }

  clearState() {
    this.setState({
      modal: false,
      field: '',
      typeModal: '',
      style: style,
      holding_time: {
        product: {
          nome: '1 - Produto 1',
          stages: [],
        },
        holding_times: [],
        cur_ht: 0,
      },
      ativo: true,
      pi_pa: false,
      area: '',
      nome: '',
      codigo: '',
      Predecessor1: '',
      Predecessor2: '',
      Predecessor3: '',
      erroNOME: '',
      erroCODIGO: '',
      erroAREA: '',
      erroTIPO: '',
      erroPredecessor1: '',
      erroVinculo: '',
      loadingPage: false
    });
  }

  onOpenModal(produto, typeModal) {
    this.handleModal(produto, typeModal);
  }

  onOpenUpload(produto) {
    this.handleUpload(produto);
  }

  handleModal(f, type, keepModalOpen = false) {
    let state = {
      product: f,
      field: f,
      typeModal: type,
      style: style
    }

    if (type === 'up' || type === 'add') {
      state.productModalOpen = true;
    } else if (!type) {
      this.clearState();
    } else {
      state.modal = keepModalOpen || !this.state.modal
    }

    this.setState(state);
  }

  handleUpload(f) {
    let state = {
      product: f,
      field: f,
      uploadModalOpen: true,
      style: style
    }

    this.setState(state);
  }

  buscarProdutos() {
    return Product.all({}, true).then(response => {
      if (response.ok) {
        return response.json();
      }
    }).then(data => {
      data = respostaProdutosLazy(data);
    });
  }

  onSuccess() {
    this.buscarProdutos();
    this.clearPosition();
    this.carregarProdutos();
  }

  contentModal() {
    const { typeModal, product } = this.state;

    switch (typeModal) {
      case 'del':
        return <DeleteItem {...this.state} delete={this.delete} handleModal={this.onOpenModal} />;
      case 'add_hold':
        return <HoldingTime
          holding_time={this.state.holding_time}
          product={product}
          message={this.message.bind(this)}
          restoreHoldingTime={this.restoreHoldingTime.bind(this)}
          closeModal={this.closeModal.bind(this)}
          reloadProducts={this.setStateHoldingTime}
          endpoints={this.endpoints}
          produtos={this.state.products}
          type="add_hold"
        />;
      case 'edit_hold':
        return <HoldingTime
          holding_time={this.state.holding_time}
          product={product}
          message={this.message.bind(this)}
          restoreHoldingTime={this.restoreHoldingTime.bind(this)}
          closeModal={this.closeModal.bind(this)}
          reloadProducts={this.setStateHoldingTime}
          produtos={this.state.products}
          endpoints={this.endpoints}
          type="edit_hold"
        />;
      case 'hold_msg':
        return this.state.message;
      case 'loading':
        return (
          <div className="modal" id={styles.loading} style={{ width: '80px', height: '100%' }}>
            <MDSpinner singleColor='#2D4F7F' size={60} />
          </div>
        );
      default:

        if (this.state.typeModal == 'del') {
          var id = product.id, dependencias = [], k = 0, msg;
          this.store.products.map(p => {
            if (p.pis) {
              dependencias = p.pis.reduce((acc, pis) => {
                if (pis.id == id) {
                  acc[k] = p.name;
                  k++;
                }
                return acc;
              }, dependencias);
            }
          });

          if (dependencias.length == 1) {
            msg = this.props.t("messages.warningDeleteLinkPIPA", { productPiPA: dependencias[0] });
            return <DeleteItem {...this.state} msgModal={msg} delete={this.delete} handleModal={this.onOpenModal} />;
          } else if (Object.keys(dependencias).length > 1) {
            msg = this.props.t("messages.warningDeleteLinksPIPA", { products: dependencias.map(p => { return p }).join(', ') });
            return <DeleteItem {...this.state} msgModal={msg} delete={this.delete} handleModal={this.onOpenModal} />;
          } else {
            return <DeleteItem {...this.state} delete={this.delete} handleModal={this.onOpenModal} />;
          }
        }
    }
  }

  message(message) {
    this.setState({
      modal: true,
      typeModal: 'hold_msg',
      message: message,
      style: null,
    });
  }

  restoreHoldingTime(result, type) {
    Promise.resolve(result).then((holding_time) => {
      if (holding_time.holding_times.length <= 0) {
        this.closeModal();
      } else {
        this.setState({
          modal: true,
          typeModal: type,
          holding_time: holding_time,
          style: null,
        });
      }
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      product: undefined,
      productModalOpen: false,
      uploadModalOpen: false
    });
  }

  addHoldingTime(product, stages) {
    this.setState({
      modal: true,
      typeModal: 'add_hold',
      product: product,
      holding_time: this.generateHoldingTime(product, stages),
      style: null,
    });
  }

  setHoldingTime(product, stages) {
    this.setState({
      modal: true,
      typeModal: 'edit_hold',
      product: product,
      holding_time: this.generateHoldingTime(product, stages),
      style: null,
    });
  }

  loading(modal) {
    this.setState({
      modal: modal,
      typeModal: 'loading'
    });
  }

  generateHoldingTime(product, stagesList) {
    var seq1 = construirSequenciaRotasCSharp((product.rotas));
    var seq2 = construirSequenciaRotasCSharp((product.predecessor.rotas || []));

    var stagesDict = stagesList.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    var produtosPredecessores = product.produtosPredecessores;
    var seqPredecessores = product.rotasPredecessoras.map(p => p.map(pred => construirSequenciaRotasCSharp((pred) || [])));
    var stagePredecessores = seqPredecessores.map(pred => pred.map(remap => remap.map(r => ({
      nome: stagesDict[r[0].etapa_atual.id].name,
      id: r[0].etapa_atual.id,
      productId: r[0].produto.id,
    }))));

    var stages =
      seq1.map(r => ({
        nome: stagesDict[r[0].etapa_atual.id].name,
        id: r[0].etapa_atual.id,
        productId: product.id,
        disabled: !r[0].com_equipamento
      }));

    var hts = product.holding_times;

    hts = hts.length > 0 ? hts : [DEFAULT_HT];
    return {
      product: {
        nome: product.nome,
        sku: product.sku,
        stages: stages,
        id: product.id,
        pi_stage_size: seq2.length,
        stagePredecessores: stagePredecessores,
        produtosPredecessores: produtosPredecessores
      },
      cur_ht: 0,
      holding_times: hts.map(ht => {
        return {
          ...ht,
          tempo_total: fromSecondsToHours(ht.tempo_total),
          errors: {}
        };
      }),
      deleted: [],
    };
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container
          showSideBar
          blur={this.state.productModalOpen || this.state.uploadModalOpen}
          modal={this.state.modal}
          styleModal={!this.state.modalType ? null : this.state.style}
          contentModal={this.contentModal}
        >
          <ProductsList
            ref='list'
            store={this.store}
            carregarProdutos={this.carregarProdutos}
            hasMoreProducts={this.state.hasMoreProducts}
            produtos={this.state.produtos}
            searchTextFilterBox={this.state.searchText}
            setSearchText={this.setSearchText}
            waitingRequest={this.state.waitingRequest}
            onOpenModal={this.onOpenModal}
            onOpenUpload={this.onOpenUpload}
            showAlert={this.showAlert}
            addHoldingTime={this.addHoldingTime.bind(this)}
            setHoldingTime={this.setHoldingTime.bind(this)}
            message={this.message.bind(this)}
            closeModal={this.closeModal.bind(this)}
            loading={this.loading.bind(this)}
            endpoints={this.endpoints}
          />

          {this.state.productModalOpen &&
            <ProductModal
              open={this.state.productModalOpen}
              closeModal={this.closeModal}
              product={this.state.product}
              store={this.store}
              onSuccess={this.onSuccess}
            />
          }

          {this.state.uploadModalOpen &&
            <UploadModal
              open={this.state.uploadModalOpen}
              onSuccess={this.onSuccess}
              onCancel={this.closeModal}
            />
          }
        </Container>
      );
    }
  }
}

export default withTranslation('products')(ProductsPage)