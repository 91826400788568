import { CLIENTV2 } from '../common/Constants';

/**
 * Responsável por encapsular a complexidade de 
 * redirecionamento do cient 1.0 para o client 2.0.
 * 
 * @param path string
 * @param queryParams any
 */

export const redirectClient = (path, queryParams) => {
    if (path === '#')
        return;

    const params = {
        token: localStorage.getItem('token'),
        path: `/${path.trim()}`,
        queryParams
    };

    let value = JSON.stringify(params);
    value = btoa(value);

    return `${CLIENTV2}/access/${value}`;
}