const DEFAULT_VALUES = [
    { id: 'A', name: 'A' },
    { id: 'B', name: 'B' },
    { id: 'C', name: 'C' }
]

export class StockCurve {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }

    static all = () => {
        return new Promise((resolve, _reject) => {
            resolve(DEFAULT_VALUES);
        })
    }
}