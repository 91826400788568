import React, { Component } from 'react';
import styles from './route.scss';
import { observer } from 'mobx-react';
import { FormInput, FormSelect } from '../../common/Form';
import { withTranslation } from 'react-i18next';

@observer
class FilterBox extends Component {
  render() {
    let {searchProduct, setSearchProduct, store} = this.props;
    let sessionOpts = store.sessions.filter(s => s.stages.length).map((s) => { return { value: s.id, text: s.name } });
    
    return (
      <div className={styles.filterBox}>
        <span style={{position: 'relative'}}>
          <FormSelect
              styles={styles}
              id='session'
              title={true}
              label={this.props.t("filterBox.select")}
              options={sessionOpts}
              onChange={this.props.handleSession}
              value={this.props.session?this.props.session:''}
              novalidate='true' />
        </span>
        <FormInput
            styles={styles}
            id='search'
            placeholder={this.props.t("filterBox.search")}
            value={searchProduct}
            onChange={(event) => setSearchProduct(event.target.value)}/>
      </div>
    );
  }
}

export default withTranslation('routes')(FilterBox);