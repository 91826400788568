export function construirSequenciaRotas(rotas) {
    var seq = [];
    var rotasDict = {};
    var cur, curArr, obj;
    var etapaAtual = null;
    var inserted = {};
    var etapaCiclo = {};
    var i = 0;
    var ciclo;
    var key;

    rotas.forEach(r => {
        if (rotasDict[r.fk_id_stages]) {
            rotasDict[r.fk_id_stages].push(r);
        } else {
            rotasDict[r.fk_id_stages] = [r];
        }

        if (r.etapa_anterior == null) {
            etapaAtual = r.fk_id_stages;
        }
    });


    if (etapaAtual) {
        etapaCiclo[etapaAtual] = 2;

        curArr = rotasDict[etapaAtual].filter(r => r.ciclo == 1);

        cur = curArr[0];

        seq.push(curArr);

        inserted[`${etapaAtual}1`] = true;

        while (cur.fk_id_front != null) {
            etapaAtual = cur.fk_id_front;

            curArr = rotasDict[etapaAtual];

            if (!curArr) break;

            if (!etapaCiclo[etapaAtual]) {
                etapaCiclo[etapaAtual] = 1;
            }

            ciclo = etapaCiclo[etapaAtual];

            key = `${etapaAtual}${ciclo}`;

            if (inserted[key]) break;

            inserted[key] = true;

            etapaCiclo[etapaAtual] = etapaCiclo[etapaAtual] + 1;

            curArr = curArr.filter(r => r.ciclo == ciclo);

            cur = curArr[0];

            seq.push(curArr);

            i++;

            if (i > 9999999) break;
        }
    }

    return seq;
}

export function construirSequenciaRotasCSharp(rotas) {
    var sequencia = [];
    var etapa = null;
    var rota = null;

    var etapaCiclo = {};

    rotas = AgruparRotasPorEtapa(rotas);

    for (let etapaId in rotas) {
        let rota = rotas[etapaId];

        let rot = rota.filter(r => !r.etapa_anterior);

        if (rot.length > 0) {
            etapa = rot;
            break;
        }
    }

    if (etapa != null) {
        etapa = EtapaComEquipamento(etapa, etapaCiclo, rotas);

        if (etapa != null) {

            sequencia.push(etapa);

            rota = etapa[0];

            // Construi a sequencia
            etapaCiclo[rota.etapa_atual.id] = 2;

            while ((!!rota.etapa_proxima)) {
                etapa =
                    EtapaComEquipamento(
                        ProximaEtapa(etapa, etapaCiclo, rotas),
                        etapaCiclo,
                        rotas
                    );

                if (etapa == null || etapa.length < 0) break;

                sequencia.push(etapa);

                rota = etapa[0];
            }
        }
    }

    return sequencia;
}

export function transformarObjeto(obj, mapper) {
    var ret = {};
    var key, k, val;

    for (key in mapper) {
        k = mapper[key];
        val = obj[key];
        if (val !== undefined) {
            ret[k] = obj[key];
        }
    }

    return ret;
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function fromTimeToSeconds(date) {
    let [h, m] = ('' + date).split(':');

    return h * 3600 + m * 60;
}

export function fromHoursToSeconds(hours) {
    return hours * 3600;
}

export function fromSecondsToTime(date) {
    date = parseFloat(date);

    let h, m;

    h = parseInt(date / 3600);
    m = (date % 3600) / 60;

    return '' + h + ':' + m;
}

export function fromSecondsToHours(date) {
    let d = parseFloat(date);

    if (isNaN(d)) d = 0;

    if (date) return ('' + parseInt(d / 3600));

    return '';
}

export function url(str, data = {}) {

    for (let key in data) {
        str = str.replace(`{${key}}`, data[key]);
    }

    return str;
}

export function ProximaEtapa(etapa, etapaCiclo, rotas) {
    var rota = etapa[0];

    if (!rota.etapa_proxima) return null;

    var etapaId = rota.etapa_proxima.id;

    var ciclo = etapaCiclo[etapaId] || 1;

    etapaCiclo[etapaId] = (etapaCiclo[etapaId] || 1) + 1;

    return (!!rotas[etapaId]) ? rotas[etapaId].filter(r => r.ciclo == ciclo) : null;
}

export function EtapaComEquipamento(etapa, etapaCiclo, rotas) {
    if (etapa == null || etapa.length < 0) return null;

    var rota = etapa[0];

    etapa.disabled = !rota.com_equipamento;

    return etapa;
}

export function AgruparRotasPorEtapa(rotas) {
    var etapas = {};

    for (let rota of rotas) {
        if (etapas[rota.etapa_atual.id]) {
            etapas[rota.etapa_atual.id].push(rota);
        } else {
            etapas[rota.etapa_atual.id] = [rota];
        }
    }

    return etapas;
}

export function filtrarPorCampos(dados, campos, valor, customs) {
    dados = dados || [];
    campos = campos || [];
    customs = customs || {};

    if (!valor) {
        return dados;
    }

    return dados.filter(dado => {
        return campos.some(campo => {
            const [_, chave] = campo.split('custom:');

            if (campo.indexOf('custom:') !== -1) {
                return (customs[chave] || (() => false))(dado[chave], valor);
            } else if (campo.indexOf('is:') !== -1) {
                return dado[chave];
            } else if (campo.indexOf('not:') !== -1) {
                return !dado[chave];
            } else {
                return dado[campo].toUpperCase().indexOf(valor.toUpperCase()) !== -1;
            }
        });
    });
}

export function dictValuesToArray(dict) {
    var arr = [];

    for (var key in dict) {
        arr.push(dict[key]);
    }

    return arr;
}
