import 'whatwg-fetch';
import moment from 'moment';
import { alertBox } from './NForm';
import fileDownload from 'react-file-download';
import Dispatcher from './Dispatcher';
import i18n from 'utils/i18n';

const modificador = (data => (data || []));

function headers(contentType, tokenApplication, tokenAccess = undefined, action = false) {
  var headers = {
    'Pragma': 'no-cache'
  };

  if (contentType) {
    headers = Object.assign(headers, { 'Content-type': contentType });
  }

  if (tokenApplication) {
    headers = Object.assign(headers, { 'Authorization': 'Bearer ' + tokenApplication });
  }

  if (tokenAccess !== undefined) {
    headers = Object.assign(headers, { 'Access-Token': tokenAccess });
  }

  if (action) {
    headers = Object.assign(headers, { 'Action': 'Dependencia' });
  }

  return headers;
}

export function requestNotSession(requisicao, send, token = undefined) {
  var head = send ? send : {};
  head.headers = headers('application/json', undefined, token);
  return fetch(requisicao, head);
}

export function request(requisicao, send, action) {
  var head = send ? send : {};
  head.headers = headers('application/json', localStorage['tokenApp'], localStorage['token'], action);
  return fetch(requisicao, head);
}

export function requestFile(requisicao, send, action) {
  var head = send ? send : {};
  head.headers = headers(null, localStorage['tokenApp'], localStorage['token'], action);

  return fetch(requisicao, head);
}

export function requestRelatorio(requisicao, nome, postData = null) {
  return new Promise((resolve, reject) => {
    var head = {
      headers: headers('text/comma-separated-values; charset=UTF-8;', localStorage['tokenApp'], localStorage['token'])
    };

    if (postData != null) {
      head.headers['Content-type'] = 'application/json';
      head.body = JSON.stringify(postData);
      head.method = 'POST';
    }

    fetch(requisicao, head)
      .then(response => {
        if (response.ok && response.status !== 204) {
          return response.text();
        } if (response.status === 204) {
          alertBox(i18n.t('messages.noDataInPeriod', { ns: 'common' }), i18n.t('labels.report', { ns: 'common' }), 'warning');
          resolve();
        } else if (response.status === 422) {
          response.json().then((responseFail) => {
            alertBox(responseFail.data.msg, responseFail.data.title, 'warning');
            resolve('Error response');
          });
        } else {
          reject(i18n.t('messages.reportGenerateError', { ns: 'common' }));
        }
      })
      .then(data => {
        if (isJson(data) && JSON.parse(data).status === 'erro') {
          reject(data);
          return;
        }
        if (data) {
          fileDownload('\ufeff' + data, `${nome} - ${moment().format('YYYY-MM-DD_HH-MM-ss')}.csv`);
          resolve(i18n.t('messages.reportGenerateSuccess', { ns: 'common' }));
        }
      });
  });
}

export function requestRelatorioXLSX(requisicao, nome, postData = null, extensao = 'xlsx') {
  extensao = extensao.toLowerCase();

  return new Promise((resolve, reject) => {
    var head = {
      headers: headers('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;', localStorage['tokenApp'], localStorage['token'])
    };

    if (postData != null) {
      head.headers['Content-type'] = 'application/json';
      head.body = JSON.stringify(postData);
      head.method = 'POST';
    }

    fetch(requisicao, head).then(response => {
      if (response.status === 200) {
        return response.arrayBuffer();
      } else if (response.status === 422) {

        response.json().then((responseFail) => {
          alertBox(responseFail.data.msg, responseFail.data.title, 'warning');
          resolve('Error response');
        });

      } else if (response.status === 404) {

        response.json().then((responseFail) => {
          alertBox(responseFail.data.msg, responseFail.data.title, 'warning');
          reject('Error response');
        });

      } else {

        response.json().then((data) => {
          reject(data);
        }).catch(() => {
          reject({
            status: 'erro',
            mensagem: i18n.t('messages.reportGenerateError', { ns: 'common' })
          });
        });

        throw Error(i18n.t('messages.reportGenerateError', { ns: 'common' }));
      }
    }).then(data => {
      if (data) {
        if (extensao === 'pdf') {
          fileDownload(data, `${nome} - ${moment().format('DD-MM-YYYY HH-mm-ss')}.pdf`, 'application/pdf');
        }
        else {
          fileDownload(data, `${nome} - ${moment().format('DD-MM-YYYY HH-mm-ss')}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8');
        }

        resolve(i18n.t('messages.reportGenerateSuccess', { ns: 'common' }));
      }
      resolve();
    });
  });
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function requestAll(requisicoes, self, isUpdateStateLoading = true) {
  return request(requisicoes[0].url, requisicoes[0].send, requisicoes[0].acao)
    .then(response => {
      if (response.ok || response.status == 204) {
        let reqs = [];
        requisicoes.forEach((e, i) => {
          if (i > 0) {
            return reqs.push(
              request(e.url, e.send, e.acao)
            );
          }
        });

        if (requisicoes.length > 1) {
          Promise.all(reqs)
            .then(response => {
              return Promise.all(response.map(e => {
                if (e.status == 200) {
                  return e.json();
                }
              }));
            })
            .then(data => {
              let j = 0;
              requisicoes.forEach((element, i) => {
                element.modificador = element.modificador || modificador;
                element.destino = element.destino || 'store';
                if (i > 0) {
                  if (element.destino == 'store') {
                    Dispatcher.dispatch({
                      type: 'get',
                      model: element.modelo,
                      data: element.modificador(data[j])
                    });
                  } else {
                    self.setState({ [element.modelo]: element.modificador(data[j]) });
                  }
                  j++;
                }
              });
              self.setState({ autorizacao: true });
              if (isUpdateStateLoading) {
                self.setState({ loadingPage: false });
              }

            });


        } else {
          self.setState({ autorizacao: true });
          if (isUpdateStateLoading) {
            self.setState({ loadingPage: false });
          }
        }

        if (response.status == 204) {
          return [];
        }

        if (response.ok) {
          return response.json();
        }
      } else if (response.status == 401 || response.status == 403) {
        self.setState({ autorizacao: false });
        if (isUpdateStateLoading) {
          self.setState({ loadingPage: false });
        }
        return;
      } else {
        self.setState({ autorizacao: true });
        if (isUpdateStateLoading) {
          self.setState({ loadingPage: false });
        }
        alertBox(i18n.t('messages.tryAgainLater', { ns: 'common' }), i18n.t('labels.warning', { ns: 'common' }), 'error');
        return;
      }
    })
    .then(data => {
      requisicoes[0].modificador = requisicoes[0].modificador || modificador;

      Dispatcher.dispatch({
        type: 'get',
        model: requisicoes[0].modelo,
        data: requisicoes[0].modificador(data)
      });
    });
}