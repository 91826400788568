import React from 'react';
import { observer } from 'mobx-react';
import Container from '../../common/Container';
import ModalForm from '../../common/ModalForm';
import DeleteItem from '../../common/DeleteItem';
import GroupEventList from './GroupEventList';
import { request, requestAll } from '../../common/request';
import { respostaGrupoEventos, requisicaoGrupoEventos } from '../../common/mappers';
import { alertBox } from '../../common/NForm';
import MDSpinner from "react-md-spinner";
import { hasRole } from '../../helper/SecurityHelper';
import { withTranslation } from 'react-i18next';

@observer
class GroupEventPage extends Container {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      field: '',
      typeModal: '',
      loadingPage: true,
    }
    this.delete = this.delete.bind(this);
    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.buscarGrupoEvento = this.buscarGrupoEvento.bind(this);
  }

  componentWillMount() {
    this.buscarGrupoEvento();
  }

  buscarGrupoEvento() {
    requestAll([
      {
        url: this.endpoints.GRUPO_EVENTO,
        modelo: 'group_event',
        modificador: respostaGrupoEventos
      }
    ], this);
  }

  delete(id) {
    request(this.endpoints.GRUPO_EVENTO + '/' + id, {
      method: 'delete'
    })
      .then(response => {
        if (response.status == 200 || response.status == 201) {
          this.handleModal();
          this.buscarGrupoEvento();
          alertBox(this.props.t("messages.deleteSuccess"), this.props.t("title"), 'success');
        } else {
          alertBox(this.props.t("errors.cannotDelete"), this.props.t("title"), 'error');
        }
      })
      .catch(() => {
        alertBox(this.props.t("errors.cannotDelete"), this.props.t("title"), 'error');
      });
  }

  handleModal(f, t) {
    this.setState({
      modal: !this.state.modal,
      field: f,
      typeModal: t
    });
  }

  contentModal() {
    let fields = [
      { name: 'name', title: this.props.t("form.labels.name"), label: this.props.t("form.labels.name"), type: 'text', required: true }
    ];
    if (this.state.typeModal == 'del') return <DeleteItem {...this.state} delete={this.delete} handleModal={this.handleModal} />;
    return <ModalForm model='group_event' genero='M' tituloAlerta={this.props.t("title")} modificador={requisicaoGrupoEventos}
      title={!this.state.field ? this.props.t("form.titleCreate") : this.props.t("form.titleUpdate")}
      action={this.action} endpoints={this.endpoints} utils={this.utils} field={this.state.field} aposSalvar={this.buscarGrupoEvento}
      fields={fields} handleModal={this.handleModal} showAlert={this.showAlert} url={this.endpoints.GRUPO_EVENTO}
      disabledSave={((!hasRole('ROLE_GRUPOS_EVENTOS_CRIAR') && this.state.typeModal === 'add') || (!hasRole('ROLE_GRUPOS_EVENTOS_ATUALIZAR') && this.state.typeModal == 'up'))} />;
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={{ width: 350 }} contentModal={this.contentModal}>
          <GroupEventList ref='list' store={this.store} handleModal={this.handleModal} />
        </Container>
      );
    }
  }
}

export default withTranslation("groupEvent")(GroupEventPage);
