import { observable, computed, action } from "mobx";
import update from "immutability-helper";

class ModalStepOneStore {
    @observable product = [];
    @observable stages = [];
    @observable equipment = [];
    @observable productStage = { product: [], stages: []}
    @observable selectedRotasProdutos = 0
    @observable productStageToShow = { product: [], stages: [] }
    @observable render = false;

    
   
    @action
    renderNewValues = () => {
        let newValuesToRender;

        // Verifico se é o primeiro cadastroda rota
    
        if (this.SelectedRotasProdutos != 0){
            let routes = this.Product.routes.filter(route => route.RotasProdutosId == this.SelectedRotasProdutos)
            newValuesToRender = update(this.productStage, { product: { routes: { $set: routes } } })
        }else{
            newValuesToRender = update(this.productStage, { product: { $set: this.productStage.product } })
        }
        
        this.setProductStageToShow(newValuesToRender);
    }
    

    // Setters
    @action
    setSelectedRotasProdutos = selectedRotasProdutos => {
        this.selectedRotasProdutos = selectedRotasProdutos;

        if (this.selectedRotasProdutos != 0)
            this.setRender(true);
    }
    @action
    setProduct = product => {
        this.product = product;
    }
    @action
    setStages = stages => {
        this.stages = stages;
    }
    @action
    setEquipament = equipment => {
        this.equipment = equipment;
    }
    @action
    setProductStage = productStage => {
        this.productStage = productStage;
    }
    @action
    setProductStageToShow = productStageToShow => {
        this.productStageToShow = productStageToShow;
        return this.productStageToShow;
    }
    @action
    setRender = render => {
        this.render = render;
    }

    // Getters
    @computed get Product(){
        return this.product;
    } 

    @computed get Stages(){
        return this.stages;
    } 

    @computed get Equipment(){
        return this.equipment;
    } 

    @computed get ProductStage(){
        return this.productStage;
    }
    @computed get ProductStageToShow(){
        return this.productStageToShow;
    }
    @computed get SelectedRotasProdutos(){
        return this.selectedRotasProdutos;
    }
    @computed get Render(){
        return this.render;
    }


}


export default ModalStepOneStore