import React, { Component } from 'react';
import BatchCommentsCard from './BatchCommentsCard';
import { withTranslation } from 'react-i18next';
import { CirclePicker } from 'react-color';
import moment from 'moment';
moment.locale('pt-br');
import './batchComments.scss';

class BatchCommentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            lote: null
        }

        this.setExpanded = this.setExpanded.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
    }

    componentWillMount() {
        this.setState({
            lote: this.props.lote
        });
    }

    setExpanded() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    handleChangeComplete = (color, event) => {
        let colorHex = color.hex === "transparent" ? "#143055" : color.hex;

        this.props.salvarCor(colorHex);
    };

    render() {
        let { getInitials, userName, removerComentarios } = this.props;
        let { lote } = this.state;
        const colors = ["transparent", "#fba234", "#f83aa7", "#66d7ed", "#9ded4f", "#fbdd04", "#ff0000"];

        return (
            <>
                {
                    lote.comentarios !== undefined && lote.comentarios !== null && lote.comentarios.length > 0
                        ?
                        <div className={'container-comments'}>
                            <div className={'container-colors'}>
                                <CirclePicker
                                    circleSize={28}
                                    colors={colors}
                                    width='300px'
                                    onChangeComplete={this.handleChangeComplete} />
                            </div>
                            <div className='current-color'>
                                <div className='color-bar' style={{ backgroundColor: lote.comentarios[0].color }} />
                            </div>
                            {
                                lote.comentarios
                                    .sort((a, b) => (a.date > b.date) ? -1 : 1)
                                    .map((cmt) => (
                                        <BatchCommentsCard
                                            comentario={cmt}
                                            getInitials={getInitials}
                                            userName={userName}
                                            removerComentarios={removerComentarios} />
                                    ))
                            }
                        </div >
                        :
                        null
                }
            </>
        );
    }
}

export default withTranslation("flowView")(BatchCommentsList);