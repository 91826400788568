import React from 'react';
import moment from 'moment';
import Container from '../../common/Container';
import { alertBox } from '../../common/NForm';
import 'regenerator-runtime/runtime';
import update from 'immutability-helper';
import CustomFieldsList from './CustomFieldsList';
import CustomFieldsForm from './CustomFieldsForm';
import { request } from '../../common/request';
import { NConfirm } from '../../common/NForm';
import { CircularProgress } from 'material-ui';
import { withTranslation } from 'react-i18next';

moment.locale('pt-br');



function requisicao(method, data) {
  return {
    method: method,
    body: data,
  }
}

class CustomFields extends Container {
  constructor(props) {
    super(props);

    this.state = {
      loadingPage: false,
      modal: false,
      modelo: null,
      estato_modal: null,
      equipamentos: [],
      eventos: [],
      campos_personalizados: [],
    };

    this.contentModal = this.contentModal.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.carregarCamposPersonalizados = this.carregarCamposPersonalizados.bind(this);
    this.carregarEquipamentos = this.carregarEquipamentos.bind(this);
    this.carregarEventos = this.carregarEventos.bind(this);
    this.formatarRequisicaoEquipamentoEvento = this.formatarRequisicaoEquipamentoEvento.bind(this);
    this.formatarRequisicao = this.formatarRequisicao.bind(this);
    this.salvar = this.salvar.bind(this);
    this.atualizar = this.atualizar.bind(this);
    this.excluir = this.excluir.bind(this);
    this.confirmarExcluir = this.confirmarExcluir.bind(this);
    this.existeCampo = this.existeCampo.bind(this);
    this.opcaoTodos = this.opcaoTodos.bind(this);
  }

  opcaoTodos() {
    return [{ label: this.props.t("values.all"), value: -1 }];
  }

  componentDidMount() {
    this.carregarCamposPersonalizados();
    this.carregarEquipamentos();
    this.carregarEventos();
  }

  carregarEquipamentos() {
    request(this.endpoints.EQUIPAMENTOS, null, true)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState(update(this.state, {
          equipamentos: {
            $set: this.opcaoTodos().concat(data.map(e => ({
              label: e.nome,
              value: e.id,
            })))
          }
        }))
      });
  }

  carregarEventos() {
    request(this.endpoints.EVENTO, null, true)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState(update(this.state, {
          eventos: {
            $set: this.opcaoTodos().concat(data.map(e => ({
              label: e.nome,
              value: e.id,
            })))
          }
        }))
      });
  }

  carregarCamposPersonalizados() {
    request(this.endpoints.CAMPOS_PERSONALIZADOS, null, true)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState(update(this.state, {
          campos_personalizados: { $set: data || [] }
        }))
      });
  }

  existeCampo(modelo) {
    return this.state.campos_personalizados.filter(campo => campo.nome == modelo.nome && campo.uuid != modelo.uuid).length > 0;
  }

  contentModal() {
    const tipo = this.state.estato_modal;

    switch (tipo) {
      case 'add':
        return (
          <CustomFieldsForm
            handleModal={this.handleModal}
            campos_personalizados={this.state.campos_personalizados}
            salvar={this.salvar.bind(this)}
            modelo={this.state.modelo}
            endpoints={this.endpoints}
            eventos={this.state.eventos}
            equipamentos={this.state.equipamentos}
            existeCampo={this.existeCampo}
            tipo='add' />
        );
      case 'edit':
        return (
          <CustomFieldsForm
            handleModal={this.handleModal}
            campos_personalizados={this.state.campos_personalizados}
            salvar={this.atualizar.bind(this)}
            modelo={this.state.modelo}
            endpoints={this.endpoints}
            eventos={this.state.eventos}
            equipamentos={this.state.equipamentos}
            existeCampo={this.existeCampo}
            tipo='edit' />
        );
      case 'confirm_delete':
        return (
          <NConfirm
            yes={() => this.excluir(this.state.modelo)}
            no={() => this.handleModal()}
            mensagens={[<span> {this.props.t("messages.wantToDelete", {name: this.state.modelo.nome})}</span>]}
          />
        );
    }
  }

  handleModal(modelo, estato_modal) {
    this.setState({
      modal: !!estato_modal,
      estato_modal: estato_modal,
      modelo: modelo,
    });
  }

  salvar(modelo) {
    request(this.endpoints.CAMPOS_PERSONALIZADOS, requisicao('POST', this.formatarRequisicao(modelo)))
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.carregarCamposPersonalizados();
          this.handleModal();
          alertBox(this.props.t("messages.successSave"), this.props.t("title"), 'success');
        } else {
          alertBox(this.props.t("errors.errorSave"), this.props.t("title"), 'error');
        }
      });
  }

  atualizar(modelo) {
    request(`${this.endpoints.CAMPOS_PERSONALIZADOS}`, requisicao('PUT', this.formatarRequisicao(modelo)))
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.carregarCamposPersonalizados();
          this.handleModal();
          alertBox(this.props.t("messages.successSave"), this.props.t("title"), 'success');
        } else {
          alertBox(this.props.t("errors.errorEditSave"), this.props.t("title"), 'error');
        }
      });
  }

  excluir(modelo) {
    request(`${this.endpoints.CAMPOS_PERSONALIZADOS}/${modelo.uuid}`, requisicao('DELETE'))
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.carregarCamposPersonalizados();
          this.handleModal();
          alertBox(this.props.t("messages.successDelete"), this.props.t("title"), 'success');
        } else {
          alertBox(this.props.t("errors.errorDelete"), this.props.t("title"), 'error');
        }
      });
  }

  confirmarExcluir(modelo) {
    this.handleModal(modelo, 'confirm_delete');
  }

  formatarRequisicao(modelo) {
    let equipamentos = modelo.equipamentos;
    let eventos = modelo.eventos;

    delete modelo.equipamentos;
    delete modelo.eventos;

    modelo.opcoes =
      modelo.opcoes.map(opcao => ({ nome: opcao }));

    modelo.por_equipamentos_eventos =
      this.formatarRequisicaoEquipamentoEvento(equipamentos, eventos);

    modelo.habilitado = true;

    return JSON.stringify([modelo]);
  }

  formatarRequisicaoEquipamentoEvento(equipamentos, eventos) {
    let equip_events = [];

    for (var equip of equipamentos) {
      for (var event of eventos) {
        equip_events.push({
          equipamento: equip.value == -1 ? null : { id: equip.value },
          evento: event.value == -1 ? null : { id: event.value },
        });
      }
    }

    return equip_events;
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <Container>
          <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', textAlign: 'center', alignItems: 'center', height: '80%' }}>
            <CircularProgress color='#2D4F7F' size={60} thickness={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container showSideBar modal={this.state.modal} styleModal={{ width: 700 }} contentModal={this.contentModal}>
          <CustomFieldsList
            ref={instance => { this.lista = instance; }}
            handleModal={this.handleModal}
            endpoints={this.endpoints}
            eventos={this.state.eventos}
            equipamentos={this.state.equipamentos}
            campos_personalizados={this.state.campos_personalizados}
            carregarCamposPersonalizados={this.carregarCamposPersonalizados}
            excluir={this.confirmarExcluir}
          />
        </Container>
      );
    }
  }
};

export default withTranslation('customFields')(CustomFields);