import moment from "moment";
import { toJS } from "mobx";
import { filter } from "lodash";
import { Product } from "../clients/Products/Product";
import i18n from "utils/i18n";

moment.locale("pt-br");

export function respostaLotes(data) {
  return data
    ? data.map((d) => ({
        Descartado: d.descartado,
        dt_finished: d.finalizado_em,
        dt_release: d.data_lancamento,
        finished: d.finalizado,
        fk_id_products: d.produto ? d.produto.id : 0,
        id: d.id,
        num: d.numero,
        numSecundario: d.numero_secundario,
        tipoLote: d.tipo_lote,
        loteRelacionado: d.fk_related_batch,
        numeroRelacionado: d.numero_relacionado,
        rotasProdutosId: d.rotaId,
        produtoApagado: d.produto_apagado,
        sessionsId: d.unidadeIndustrialId,
        tamanho: d.tamanho,
        situation: d.comApontamento,
        isShowEnvironment: d.isShowEnvironment,
        produto: {
          name: d.produto ? d.produto.nome : "",
          sku: d.produto ? d.produto.sku : "",
        },
      }))
    : [];
}

export function requisicaoLotes(data) {
  return data
    ? {
        produto: {
          id: data.fk_id_products,
        },
        numero: data.num,
        numero_secundario: data.numSecundario,
        data_lancamento: data.dt_release,
        tamanho: data.tamanho,
        tipo_lote: data.tipoLote,
        fk_related_batch: data.loteRelacionado,
        fk_id_rotas_produtos: data.rotasProdutosId,
        fk_id_sessions: data.sessionsId,
      }
    : {};
}

export function respostaEtapasIntegradas(data) {
  return data
    ? data.map((d) => ({
        ciclo: d.integrado ? d.integrado.ciclo : undefined,
        ciclo_atual: d.etapa ? d.etapa.ciclo : undefined,
        equipamento_id: d.equipamento ? d.equipamento.id : undefined,
        etapa_atual: d.etapa ? d.etapa.id : undefined,
        etapa_id: d.integrado ? d.integrado.id : undefined,
        ordem: d.ordem,
        primeiro: d.primeiro,
        ultimo: d.ultimo,
      }))
    : [];
}

export function requisicaoEtapasIntegradas(data) {
  return data
    ? data.map((d) => ({
        integrado: {
          id: d.etapa_id,
          ciclo: d.ciclo,
        },
        etapa: {
          id: d.etapa_atual,
          ciclo: d.ciclo_atual,
        },
        ordem: d.ordem,
        primeiro: d.primeiro,
        ultimo: d.ultimo,
        equipamento: {
          id: d.equipamento_id,
        },
      }))
    : [];
}

export function requisicaoRotas(data) {
  return data
    ? data.map((d) => ({
        id: d.id || 0,
        oee: d.oee || 0.0,
        oee_padrao: d.oee_padrao,
        tempo_ciclo: d.proc_time || 0,
        tempo_espera: d.wait_time || 0,
        ciclo: d.cycle || 0,
        principal: d.master || false,
        quantidade: d.quantity || 0,
        etapa_proxima: d.fk_id_front
          ? {
              id: d.fk_id_front,
            }
          : null,
        etapa_anterior: d.fk_id_back
          ? {
              id: d.fk_id_back,
            }
          : null,
        etapa_atual: {
          id: d.fk_id_stages,
        },
        produto: {
          id: d.fk_id_products,
        },
        unidade_industrial: {
          id: d.fk_id_sessions,
        },
        equipamento: d.fk_id_equipments
          ? {
              id: d.fk_id_equipments,
            }
          : null,
        com_equipamento: d.ComEquipamento || true,
        ordem: d.ordem || 0,
        etapas_integradas: requisicaoEtapasIntegradas(d.etapa_integrada).map(
          (e) => {
            e.produto = { id: d.fk_id_products };
            return e;
          },
        ),
        tempo_espera_padrao: d.wait_time_default || 0,
        ciclo_anterior: d.CicloAnterior || 0,
        ciclo_proximo: d.CicloProximo || 0,
        etapa_anterior_real: d.EtapaAnteriorReal
          ? {
              id: d.EtapaAnteriorReal,
            }
          : null,
        etapa_proximo_real: d.EtapaProximaReal
          ? {
              id: d.EtapaProximaReal,
            }
          : null,
        ciclo_anterior_real: d.CicloAnteriorReal || 0,
        ciclo_proximo_real: d.CicloProximoReal || 0,
        etapa_simultaneo: d.EtapaSimultaneo || false,
        simultaneo: d.parallel || false,
        simultaneo_extendido: d.parallel_extended || false,
        MasterType: d.MasterType,
        QuantityConversion: d.QuantityConversion,
        etapa_nao_linear: d.EtapaNaoLinear || false,
        etapa_opcional: d.EtapaOpcional || false,
        tipo_producao: d.TipoProducao,
      }))
    : [];
}

export function respostaRotas(data) {
  return data
    ? data.map((r) => ({
        id: r.id,
        oee: r.oee,
        oee_padrao: r.oee_padrao,
        proc_time: r.tempo_ciclo,
        wait_time: r.tempo_espera,
        cycle: r.ciclo,
        master: r.principal,
        quantity: r.quantidade,
        parallel: r.simultaneo,
        parallel_extended: r.simultaneo_extendido,
        fk_id_front: r.etapa_proxima ? r.etapa_proxima.id : null,
        fk_id_back: r.etapa_anterior ? r.etapa_anterior.id : null,
        fk_id_products: r.produto.id,
        fk_id_sessions: r.unidade_industrial.id,
        fk_id_stages: r.etapa_atual.id,
        fk_id_equipments: r.equipamento ? r.equipamento.id : null,
        com_equipamento: r.com_equipamento,
        ordem: r.ordem,
        etapa_integrada: respostaEtapasIntegradas(r.etapas_integradas),
        wait_time_default: r.tempo_espera_padrao,
        CicloAnterior: r.ciclo_anterior,
        CicloProximo: r.ciclo_proximo,
        EtapaAtual: r.etapa_atual,
        EtapaAnteriorReal: r.etapa_anterior_real
          ? r.etapa_anterior_real.id
          : null,
        CicloAnteriorReal: r.ciclo_anterior_real,
        EtapaProximoReal: r.etapa_proximo_real ? r.etapa_proximo_real.id : null,
        CicloProximoReal: r.ciclo_proximo_real,
        EtapaSimultaneo: r.etapa_simultaneo,
        EtapaNaoLinear: r.etapa_nao_linear,
        EtapaOpcional: r.etapa_opcional,
        TipoProducao: r.tipo_producao,
        RotasProdutosId: r.Rotas_produtos_id,
        TimeMachine: r.time_machine,
        QuantityConversion: r.QuantityConversion,
        MasterType: r.MasterType,
        unidadeIndustrial: r.unidade_industrial,
      }))
    : [];
}

export function respostaAreaProdutivas(data) {
  return data
    ? data.map((d) => ({
        id: d.id,
        name: d.nome,
      }))
    : [];
}

export function requisicaoAreaProdutivas(data) {
  return data
    ? {
        nome: data.name,
      }
    : [];
}

export function respostaTimeMachineAba(datas) {
  return datas
    ? datas
        .sort((a, b) => moment(a.dataEfetivo) - moment(b.dataEfetivo))
        .map((data) => {
          return {
            id: data.id,
            title: `${moment(data.dataEfetivo).format("L")} ${data.dataFim ? ` ${i18n.t("labels.to", { ns: "common" })} ` + moment(data.dataFim).format("L") : ""}`,
            ativo: data.ativo,
          };
        })
    : [];
}

export function mapearModal(dados, chave) {
  return dados ? dados.map((dado) => ({ text: dado[chave] })) : [];
}

export function respostaEquipamentos(data) {
  return data
    ? data.map((d) => {
        var eventos = _.orderBy(d.eventos, ["evento.id"], ["asc"]);
        var senha =
          d.equipamento_iot != null ? window.atob(d.equipamento_iot.senha) : "";
        var mapeado = {
          id: d.id,
          name: d.nome,
          is_batch: d.batch,
          is_checkout: d.checkout,
          tag: d.tag,
          state: d.estado,
          wait_time: d.tempo_espera,
          fifo: d.fifo == 0 ? "0" : d.fifo,
          status: d.status,
          meta_time_medium_setup: d.meta_tempo_medio_setup,
          meta_time_troca_lote: d.meta_tempo_troca_lote,
          meta_time_performance_setup_completo:
            d.meta_tempo_performace_setup_completo,
          meta_time_mttr: d.meta_tempo_mttr,
          meta_time_mtbf: d.meta_tempo_mtbf,
          meta_time_mwt: d.meta_time_mwt,
          meta_time_mttp: d.meta_tempo_mttp,
          meta_time_mtbp: d.meta_tempo_mtbp,
          meta_time_mwt: d.meta_tempo_mwt,
          meta_oee_real_com_setup: d.meta_oee_real_com_setup,
          meta_oee_real_sem_setup: d.meta_oee_real_sem_setup,
          meta_oee_effetive_com_setup: d.meta_oee_efetivo_com_setup,
          meta_oee_effetive_sem_setup: d.meta_oee_efetivo_sem_setup,
          meta_oee_otimized_com_setup: d.meta_oee_otimizado_com_setup,
          meta_oee_otimized_sem_setup: d.meta_oee_otimizado_sem_setup,
          meta_oee_teep_com_setup: d.meta_oee_teep_com_setup,
          meta_oee_teep_sem_setup: d.meta_oee_teep_sem_setup,
          meta_time_performance_geral: d.meta_tempo_performace_geral,
          meta_time_preventive_corretive: d.meta_tempo_preventivo_corretivo,
          isMultiple: d.multiplo,
          moMandatoryInEvents: !!d.tipo_coleta_mo
            ? d.tipo_coleta_mo.eventosIds
            : [],
          zerarMO: !!d.tipo_coleta_mo ? d.tipo_coleta_mo.zerarMO : false,
          moAppCollet: (() =>
            !!d.tipo_coleta_mo ? d.tipo_coleta_mo.tipoColetaMO : -1)(),
          hasLinkedEquipments: d.hasLinkedEquipments
            ? d.hasLinkedEquipments
            : false,
          linkedEquipments: d.linkedEquipments ? d.linkedEquipments : [],
          multipleQuantity: d.quantidade_multiplo,
          requestMultiplier: d.fator_conversao_apontamento,
          customGoal: d.custom_goal,
          customGoalValue: d.custom_goal_value,
          fields: d.areas_industriais.map((f) => ({
            id: f.id,
            name: f.nome,
            equipments_fields: {
              fk_id_equipments: d.id,
              fk_id_fields: f.id,
            },
          })),
          shifts: d.turnos.map((s) => ({
            id: s.id,
            name: s.nome,
            dia_start: s.dia_inicio,
            dia_end: s.dia_fim,
            start_time: s.inicio,
            end_time: s.fim,
            equipments_shifts: {
              fk_id_equipments: d.id,
              fk_id_shifts: s.id,
            },
          })),
          events: _.groupBy(eventos, (e) => e.vigencia.id),
          grupo_equipamentos: d.grupo_equipamentos.map((g) => ({
            id: g.id,
            name: g.nome,
            equipamento_grupo_equipamento: {
              GrupoEquipamen: g.id,
              EquipamentoId: d.id,
            },
          })),
          equipamentoIot:
            d.equipamento_iot != null
              ? {
                  iotAtivo: d.equipamento_iot.ativo,
                  ip: d.equipamento_iot.ip,
                  tolerancia: d.equipamento_iot.tolerancia,
                  producaoEvento:
                    d.equipamento_iot.producao_evento.id != 0
                      ? {
                          id: d.equipamento_iot.producao_evento.id,
                          nome: d.equipamento_iot.producao_evento.nome,
                          codigo: d.equipamento_iot.producao_evento.codigo,
                        }
                      : {},
                  microparada: d.equipamento_iot.micro_parada,
                  microParadaEvento:
                    d.equipamento_iot.micro_parada_evento.id != 0
                      ? {
                          id: d.equipamento_iot.micro_parada_evento.id,
                          nome: d.equipamento_iot.micro_parada_evento.nome,
                          codigo: d.equipamento_iot.micro_parada_evento.codigo,
                        }
                      : {},
                  produzido: d.equipamento_iot.produzido,
                  multiplicador_produzido:
                    d.equipamento_iot.multiplicador_produzido,
                  rejeitado: d.equipamento_iot.rejeitado,
                  multiplicador_rejeitado:
                    d.equipamento_iot.multiplicador_rejeitado,
                  tipoDispositivo: d.equipamento_iot.tipoDispositivo,
                  dispositivoId: d.equipamento_iot.dispositivoId,
                  total: d.equipamento_iot.total,
                  usuario: d.equipamento_iot.username,
                  senha: senha,
                }
              : null,
        };

        return mapeado;
      })
    : [];
}

export function requisicaoValorPadraoIndicadores(
  indicadoresPersonalizados,
  id,
  isEquipment,
) {
  // Indicadores Personalizados
  let indicadores = indicadoresPersonalizados || [];
  indicadores = filter(indicadores, (indicator) => indicator.uuid);

  indicadores = indicadores.map((indicator) => {
    if (indicator.uuid) {
      const value = parseFloat(indicator.value) || 0;
      let result = { uuid: indicator.uuid, valor_padrao: value };

      if (isEquipment) {
        result.equipamento_id = id;
      } else {
        result.grupo_equipamento_id = id;
      }

      return result;
    }
  });

  return indicadores;
}

export function requisicaoEquipamentos(data) {
  // separa o tempo de espera em horas e minutos
  let espera = data.detalhes.wait_time.split(":");

  // soma os dois tempos em minutos para gerar um valor só
  espera = parseFloat(espera[0] * 60) + parseFloat(espera[1]);
  var setups = [];
  data.setup.eventos = Object.values(toJS(data.setup.eventos));
  data.setup.eventos.map((vigencia) =>
    vigencia.map((evento) => setups.push(evento)),
  );

  // Indicadores Personalizados
  const indicadores = requisicaoValorPadraoIndicadores(
    data.metas.indicadores,
    data.detalhes.id,
    true,
  );

  return {
    id: data.detalhes.id,
    //Detalhes
    nome: data.detalhes.name,
    batch: data.detalhes.is_batch,
    checkout: data.detalhes.is_checkout,
    tag: data.detalhes.tag,
    estado: data.detalhes.state,
    tempo_espera: espera,
    fifo: data.detalhes.fifo,
    status: data.detalhes.state,
    areas_industriais: data.detalhes.fields.map((f) => ({ id: f.id })),
    turnos: data.detalhes.shifts.map((f) => ({ id: f.id })),
    grupo_equipamentos: data.detalhes.grupo_equipamentos.map((g) => ({
      id: g.id,
    })),
    multiplo: data.tecnico.isMultiple,
    tipo_coleta_mo: data.tecnico.tipo_coleta_mo,
    hasLinkedEquipments: data.tecnico.hasLinkedEquipments,
    linkedEquipments: data.tecnico.linkedEquipments.map(
      (equipment) => equipment.id,
    ),
    quantidade_multiplo: data.tecnico.multipleQuantity,
    fator_conversao_apontamento: data.tecnico.requestMultiplier,
    custom_goal: data.tecnico.customGoal,
    custom_goal_value: data.tecnico.customGoalValue,
    //Metas
    meta_tempo_mttr: data.metas.meta_time_mttr,
    meta_tempo_mtbf: data.metas.meta_time_mtbf,
    meta_tempo_mttp: data.metas.meta_time_mttp,
    meta_tempo_mtbp: data.metas.meta_time_mtbp,
    meta_tempo_mwt: data.metas.meta_time_mwt,
    // Indicadores Personalizados
    indicadores: indicadores,
    //OEE
    meta_oee_real_com_setup: data.oee.meta_oee_real_com_setup,
    meta_oee_real_sem_setup: data.oee.meta_oee_real_sem_setup,
    meta_oee_efetivo_com_setup: data.oee.meta_oee_effetive_com_setup,
    meta_oee_efetivo_sem_setup: data.oee.meta_oee_effetive_sem_setup,
    meta_oee_otimizado_com_setup: data.oee.meta_oee_otimized_com_setup,
    meta_oee_otimizado_sem_setup: data.oee.meta_oee_otimized_sem_setup,
    meta_oee_teep_com_setup: data.oee.meta_oee_teep_com_setup,
    meta_oee_teep_sem_setup: data.oee.meta_oee_teep_sem_setup,
    //SETUP
    eventos: setups,
    //IOT
    equipamento_iot: data.iot
      ? {
          ativo: data.iot.ativo,
          ip: data.iot.ip,
          tolerancia:
            data.iot.tempo_tolerancia == "" ? 0 : data.iot.tempo_tolerancia,
          producao_evento: {
            id: data.iot.producaoEventoId,
          },
          micro_parada:
            data.iot.tempo_micro_parada == "" ? 0 : data.iot.tempo_micro_parada,
          micro_parada_evento: {
            id: data.iot.microParadaEventoId,
          },
          produzido: parseInt(data.iot.produzido),
          multiplicador_produzido: parseInt(data.iot.multiplicador_produzido),
          rejeitado: parseInt(data.iot.rejeitado),
          multiplicador_rejeitado: parseInt(data.iot.multiplicador_rejeitado),
          tipoDispositivo: data.iot.tipoDispositivo,
          dispositivoId: data.iot.dispositivoId,
          total: parseInt(data.iot.total),
          username: data.iot.usuario,
          senha: btoa(data.iot.senha),
        }
      : null,
  };
}

export function respostaTurnos(data) {
  return data
    ? data.map((d) => ({
        id: d.id,
        name: d.nome,
        dia_start: d.dia_inicio,
        dia_end: d.dia_fim,
        start_time: d.inicio,
        end_time: d.fim,
      }))
    : [];
}

export function requisicaoTurnos(data) {
  return data
    ? {
        nome: data.name,
        inicio: data.start_time,
        fim: data.end_time,
        dia_inicio: data.dia_start,
        dia_fim: data.dia_end,
      }
    : {};
}

export function respostaEventos(data) {
  return data
    ? data.map((e) => ({
        id: e.id,
        cod: e.codigo,
        descr: e.nome,
        is_batch: e.fluxo,
        fk_id_table: e.classe,
        status: e.status,
        oee_real: e.oee_real,
        instant: e.instantaneo,
        oee_effetive: e.oee_efetivo,
        oee_otimized: e.oee_otimizado,
        oee_teep: e.oee_teep,
        oee_mtta_mtba: e.mtta_mtba,
        oee_mttp_mtbp: e.mttp_mtbp,
        oee_mttr_mtbf: e.mttr_mtbf,
        oee_mwt: e.mwt,
        sys_default: e.padrao_sistema,
        classe: e.classe_mobile,
        pemissionQrCode: e.permissao_qrcode,
        tags: e.tags ? e.tags.values.map((i) => i) : [],
        grupo_permissao_evento: e.grupo_permissao_evento
          ? e.grupo_permissao_evento
          : [],
        group_events: e.grupo_eventos
          ? e.grupo_eventos.map((d) => ({
              id: d.id,
              name: d.nome,
            }))
          : [],
      }))
    : [];
}

export function requisicaoEventos(data) {
  return data
    ? {
        id: data.id,
        nome: data.descr,
        codigo: data.cod,
        classe: data.fk_id_table,
        fluxo: data.is_batch,
        instantaneo: data.instant,
        grupo_eventos: data.group_events
          ? data.group_events.map((ev) => ({ id: ev }))
          : null,
        mtta_mtba: data.oee_mtta_mtba,
        mttp_mtbp: data.oee_mttp_mtbp,
        mttr_mtbf: data.oee_mttr_mtbf,
        mwt: data.oee_mwt,
        oee_real: data.oee_real,
        oee_efetivo: data.oee_effetive,
        oee_otimizado: data.oee_otimized,
        oee_teep: data.oee_teep,
        permissao_qrcode: data.pemissionQrCode,
        tags: { values: data.tags },
        grupo_permissao_evento: data.userGroup
          ? data.userGroup.map((item) => {
              return { uuid: item.uuid };
            })
          : null,
      }
    : {};
}

export function respostaClassesEventos(data) {
  return data
    ? data.map((d) => ({
        id_table: d.id,
        descr: d.nome,
        status: d.status,
      }))
    : [];
}

export function respostaGrupoEventos(data) {
  return data
    ? data.map((e) => ({
        id: e.id,
        name: e.nome,
      }))
    : [];
}

export function requisicaoGrupoEventos(data) {
  return data
    ? {
        id: data.id,
        nome: data.name,
      }
    : [];
}

export function respostaPredecessores(data) {
  return data
    ? data.map((d) => ({
        id_lotePai: d.sucessor.id,
        numero_lotePai: d.sucessor.numero,
        numero_sku_lotePai: d.sucessor.produto.sku,
        predecessores: [...d.predecessores],
      }))
    : [];
}

export function requisicaoPredecessores(data) {
  return data
    ? data.map((d) => ({
        lote_predecessor: {
          id: d.fk_id_batches,
        },
        lote_pai: {
          id: d.fk_pa_id,
        },
      }))
    : [];
}

export function respostaUnidadeIndustrial(data) {
  return data
    ? data.map((d) => ({
        id: d.id,
        name: d.nome,
        fk_id_fields: d.area_produtiva.id,
        nome_area: d.area_produtiva.nome,
        stages: d.etapas.map((e) => ({
          id: e.id,
          name: e.nome,
          ComEquipamento: e.com_equipamento,
          TipoProducao: e.TipoProducao,
          equipments: e.equipamentos.map((eq) => ({
            id: eq.id,
            name: eq.nome,
            tag: eq.tag,
            state: eq.estado,
            ordem: eq.ordem,
            wait_time: eq.tempo_espera,
            situation: eq.situacao,
            ultimo_evento: eq.ultimo_evento,
            fifo: eq.fifo,
            is_batch: eq.batch,
          })),
        })),
        batchTypes: d.tiposLote.map((e) => ({
          id: e.id,
          name: e.name,
        })),
      }))
    : [];
}

export function respostaProdutosLazy(data) {
  return data
    ? data.map(
        (d) =>
          new Product({
            id: d.id,
            name: d.nome,
            sku: d.sku,
            total_routes: d.total_rotas || 0,
          }),
      )
    : [];
}

export function respostaProdutos(data) {
  return data
    ? data.map(
        (d) =>
          new Product({
            id: d.id,
            name: d.nome,
            estado: d.estado,
            sku: d.sku,
            type: d.tipo,
            fk_id_fields: d.area_industrial != null ? d.area_industrial.id : 0,
            status: d.status,
            pi_pa: d.possui_link_pipa,
            holding_times: d.total_holding_time,
            routes: respostaRotas(d.rotas),
            sistema: d.sistema,
            predecessores:
              d.predecessores != null
                ? d.predecessores.map((p) => ({
                    fk_id_products: d.id,
                    fk_id_pi: p.id,
                    sku: p.sku,
                    nome: p.nome,
                    status: p.status,
                    predecessores: predecessoresRecursive(
                      p.id,
                      p.predecessores,
                    ),
                  }))
                : {},
          }),
      )
    : [];
}

export function respostaValorPadraoIndicadoresPersonalizados(data) {
  let result = [];

  if (data) {
    result = data.map((d) => ({
      uuid: d.indicador_uuid,
      name: d.indicador_nome,
      value: d.valor_padrao || 0,
      idEquipment: d.equipamento_id,
      resultFormat: d.formato_resultado,
    }));
  }

  return result;
}

function predecessoresRecursive(produtoId, predecessores) {
  return predecessores
    ? predecessores.map((pre) => ({
        fk_id_products: produtoId,
        fk_id_pi: pre.id,
        status: pre.status,
        predecessores: predecessoresRecursive(pre.id, pre.predecessores),
      }))
    : [];
}

export function requisicaoProdutos(data) {
  return {
    id: data.id,
    nome: data.nome?.trim(),
    state: data.ativo,
    estado: data.estado,
    sku: data.sku?.trim(),
    tipo: data.tipo,
    area_industrial: data.area_industrial,
    politica_estoque: {
      ...data.politica_estoque,
    },
    possui_predecessor: data.possui_link_pipa,
    possui_link_pipa: data.possui_link_pipa,
    predecessores: data.predecessores,
  };
}

export function requisicaoUnidadeIndustrial(data) {
  return data
    ? {
        id: data.id,
        nome: data.name,
        area_produtiva: {
          id: data.fk_id_fields,
          nome: data.nome_area,
        },
        etapas: data.stages
          ? data.stages.map((st) => ({
              id: st.id,
              nome: st.name,
              ordem: st.ordem,
              equipamentos: st.equipments.map((eq) => ({
                id: eq.id,
                nome: eq.name,
                ordem: eq.ordem,
              })),
            }))
          : [],
        tiposLote: data.batchTypes
          ? data.batchTypes.map((bt) => ({
              id: bt.id,
              name: bt.name,
              status: bt.status,
            }))
          : [],
      }
    : {};
}

export function respostaUltimosApontamentos(data) {
  return data
    ? data.map((d) => ({
        fk_id_equipments: d.equipamento ? d.equipamento.id : null,
        fk_id_events: d.evento ? d.evento.id : null,
        id: d.id,
        ProduzidoAcumulado: d.produzido_acumulado,
        RejeitadoAcumulado: d.rejeitado_acumulado,
        cod: d.evento.codigo,
        descr: d.evento.nome,
        classe: d.evento.classe,
        evento: d.evento,
        start_time: d.inicio,
        end_time: d.fim,
        inicio: d.inicio,
        fim: d.fim,
        name: d.produto != null ? d.produto.nome : "",
        campo: d.nome_campo,
        campo1: d.nome_campo1,
        campo2: d.nome_campo2,
        campo3: d.nome_campo3,
        note: d.observacao,
        note1: d.observacao1,
        note2: d.observacao2,
        note3: d.observacao3,
        num: d.lote != null ? d.lote.numero : d.numero_lote,
        produced: d.produzido,
        rejected: d.rejeitado,
        sku: d.produto != null ? d.produto.sku : "",
        origem_apontamento: d.origem_apontamento,
        tipo_producao: d.tipo_producao,
      }))
    : [];
}

export function respostaEtapas(data) {
  return data
    ? data.map((d) => ({
        ComEquipamento: d.com_equipamento,
        dt_cad: d.criado_em,
        dt_del: d.excluido_em,
        dt_up: d.atualizado_em,
        id: d.id,
        name: d.nome,
        status: d.status,
        user_cad: d.criado_usuario,
        user_del: d.excluido_usuario,
        user_up: d.atualizado_usuario,
        TipoProducao: d.TipoProducao,
      }))
    : [];
}

export function respostaTipoLotes(data) {
  return data
    ? data.map((d) => ({
        id: d.id,
        name: d.name,
        primary_label: d.primary_label,
        secondary_label: d.secondary_label,
        dt_cad: d.criado_em,
        dt_del: d.excluido_em,
        dt_up: d.atualizado_em,
        status: d.status,
        user_cad: d.criado_usuario,
        user_del: d.excluido_usuario,
        user_up: d.atualizado_usuario,
      }))
    : [];
}

export function requisicaoEtapas(data) {
  return data
    ? {
        nome: data.nome,
        com_equipamento: data.com_equipamento,
        TipoProducao: data.TipoProducao,
      }
    : {};
}

export function respostaPipas(data) {
  return data;
}

export function respostaParametros(data) {
  return data
    ? data.map((d) => ({
        color: d.cor,
        id: d.id,
        status: d.status,
        type: d.tipo,
        value: d.valor,
      }))
    : [];
}

export function requisicaoParametros(data) {
  return data
    ? {
        id: data.id,
        tipo: data.tipo,
        valor: data.valor,
      }
    : {};
}

export function repostaLeadTime(data) {
  return data;
}

export function respostaDadosColeta(data) {
  const tipos_coleta = {
    0: i18n.t("labels.web", { ns: "common" }),
    1: i18n.t("labels.eventRecordsManagement", { ns: "common" }),
    2: i18n.t("labels.automatic", { ns: "common" }),
    3: i18n.t("labels.semiAutomatic", { ns: "common" }),
  };

  return data
    ? data.map((d) =>
        moment(d.ultimaComunicacao).isBefore(moment().subtract(7, "days"))
          ? {
              id: d.id,
              equipamento: {
                id: d.equipamento.id,
                name: d.equipamento.nome,
                tag: d.equipamento.tag,
              },
              collect_mode: "-",
              version: "",
              version_number: "",
              current_version: "",
              user: {
                name: "-",
              },
              session_time: "-",
              switch_user_screen: null,
              last_communication: "",
              last_note: "",
            }
          : {
              id: d.id,
              equipamento: {
                id: d.equipamento.id,
                name: d.equipamento.nome,
                tag: d.equipamento.tag,
              },
              collect_mode: tipos_coleta[d.modoColeta],
              version: d.versao,
              version_number: d.numeroVersao,
              current_version: d.versaoCorrente,
              user: {
                name: d.usuario.nome,
              },
              session_time: d.tempoSessao,
              switch_user_screen: d.trocaUsuario,
              last_communication: d.ultimaComunicacao,
              last_note: d.ultimoApontamento,
            },
      )
    : {};
}

export function respostaTimeMachineSetup(data) {
  return data
    ? data.map((d) => ({
        time_machine: {
          ativo: d.ativo,
          destinoId: d.destinoId,
          dataEfetivo: d.dataEfetivo,
          dataFim: d.dataFim,
          id: d.id,
          originalUuid: d.originalUuid,
          observacao: d.observacao,
          originalId: d.originalId,
        },
        setups: d.eventos.map((e) => ({
          id: e.id,
          setup: e.codigo + " " + e.nome,
          value: e.tempo_duracao,
        })),
      }))
    : {};
}

export function respostaMonitoresEquipamento(data) {
  return data
    ? data.map((d) => ({
        equipamento: d.equipamento,
        oeeEfetivo: null,
        oeeReal: null,
        oeeCarregado: false,
        tmf: d.tmf,
        ultimoApontamento: d.ultimoApontamento,
      }))
    : [];
}

