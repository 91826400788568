import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styles from './route.scss';
import ListTitle from './ListTitle';
import ListStages from './ListStages';
import { withTranslation } from 'react-i18next';

@observer
class RoutesStages extends Component {
  constructor() {
    super();
    this.styleProductsList = this.styleProductsList.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.session !== nextProps.session || this.props.products !== nextProps.products
  }

  styleProductsList(metrics) {
    this.props.styleProductsName(this.refs.list_titles.refs.list_title.offsetHeight, metrics);
  }

  render() {
    let {products, stages} = this.props, widthViewPort = document.documentElement.clientWidth, styl= {};

    if (stages !== undefined) {
      let minWidthList = (200+(stages.length*420));
      styl = minWidthList > widthViewPort?{minWidth: stages.length*420}:null;
    }

    return (
      <div className={styles.list}>
        <div style={styl}>
          <ListTitle ref="list_titles" stages={stages} />
          <ListStages ref="list_stages" {...this.props} styleProductsList={this.styleProductsList}/>
        </div>
      </div>
    );
  }
}

export default withTranslation('routes')(RoutesStages);