export const Colors = {
    amarelo: {
        startColor: '#9E9118',
        endColor: '#CBBA23',
        backgroundColor: '#EFEAB8'
    },
    vermelho: {
        startColor: "#6F150E",
        endColor: "#CE3024",
        backgroundColor: '#F4D7D6'
    },
    verde: {
        startColor: '#087919',
        endColor: '#01A319',
        backgroundColor: '#E6F8E6'
    }
};

export const TextColors = {
    vermelho: "#cc3333",
    amarelo: "#cccc33", 
    verde: "#00cc33"
}

export const IndicatorColorsBgs = {
    amarelo: 'rgba(115, 96, 17, 1)',
    verde: 'rgba(25, 110, 68, 1)',
    vermelho: 'rgba(115, 24, 17, 1)',
};

export const IndicatorColors = {
    amarelo: 'rgba(206, 203, 45, 1)',
    verde: 'rgba(29, 202, 104, 1)',
    vermelho: 'rgba(208, 56, 46, 1)',
};
