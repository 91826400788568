import React from 'react';
import { chunk, endsWith, padStart, padEnd, isEmpty } from 'lodash';

import InputTime from '../../common/InputTime';
import { FormatoResultado } from '../../common/Constants';

import MaskFieldTime from '../../utils/MaskFieldTime';
const { MaskInterval } = MaskFieldTime;

import TimeConversion from '../../utils/TimeConversion';
const { THoursMinutes } = TimeConversion;

import styles from '../../common/form.scss';
import { InputPercentage } from '../../common/NForm';
import { withTranslation } from 'react-i18next';

class CustomIndicatorForm extends React.Component {

  constructor(props) {
    super(props);
    this.convertHoursInMinutes = this.convertHoursInMinutes.bind(this);
    this.getCustomIndicatorFieldChunck= this.getCustomIndicatorFieldChunck.bind(this);

    this.decimalValueFormated = this.decimalValueFormated.bind(this);
    this.renderIntegers = this.renderIntegers.bind(this);
    this.renderHoursMinutes = this.renderHoursMinutes.bind(this);
    this.renderCustomIndicators = this.renderCustomIndicators.bind(this);
  }

  convertHoursInMinutes(hours) {
    const hoursSplited = hours.split(":");
    const hour = hoursSplited[0];
    const minutes = hoursSplited[1];

    const result = parseFloat((hour * 60)) + parseFloat(minutes);
    return result;
  }

  decimalValueFormated(indicator) {
    const value = indicator.value.toString();
    if (indicator.valueFormated) {
      return;
    }

    const valueMaskedArray = value.toString().split('.');
    const integers = padStart(valueMaskedArray[0], 3, '0');
    const decimals = padEnd(valueMaskedArray[1], 2, '0');

    indicator.valueFormated = integers + '.' + decimals;
    indicator.value = indicator.valueFormated;
  };

  renderHoursMinutes(indicator, index) {
    indicator.hourMasked = indicator.hourMasked || '000:00';
    const labelStyle = this.props.groupEquipments ? {left: '0px'} : undefined;
    return (
        <InputTime
          styles={styles}
          max={15} min={7}
          id={indicator.reference}
          key={`input-time-${indicator.reference}`}
          keyElement={indicator.reference}
          className={styles.balloon}
          value={indicator.hourMasked}
          label={`${indicator.name} (${this.props.t(indicator.resultFormatEnum.label)})`}
          labelStyle={labelStyle}
          onChange={(event) => {
            indicator.hourMasked = MaskInterval(event.target.value);
            this.props.setStateMeta(`indicators[${index}]`, indicator, indicator.reference);
          }}
          onBlur={() => {
            const hourMasked = indicator.hourMasked || '000:00';
            indicator.value = this.convertHoursInMinutes(hourMasked);
            this.props.setStateMeta(`indicators[${index}]`, indicator, indicator.reference);
          }}
        />
    );
  }

  renderPercent(indicator, index) {
    indicator.value =  indicator.value || 0;

    if (!endsWith(indicator.value.toString(), '%')) {
      indicator.value =  indicator.value.toString() + '%';
    }

    const paddingLabel = this.props.groupEquipments ? '0.4rem' : '1rem';

    return (
      <span
        id={`span-decimal-${indicator.reference}-${index}`}
        key={`span-decimal-${indicator.reference}-${index}`}
        ref={`span-decimal-${indicator.reference}-${index}`}
      >
        <InputPercentage
          value={indicator.value}
          id={indicator.reference}
          ref={indicator.reference}
          underlineShow={false}
          floatingLabelStyle={{
            top: '0px',
            color: '#5B5B5B', 
            textTransform: 'uppercase', 
            textOverflow: 'ellipsis', 
            overflow: 'hidden', 
            whiteSpace: 'nowrap', 
            paddingLeft: paddingLabel
          }}
          inputStyle={{
            padding: '.4rem',
            marginTop: '0px',
            borderBottom: '1px solid #CCC',
          }}
          title={`${indicator.name} (${this.props.t(indicator.resultFormatEnum.label)})`}
          style={{textAlign: 'left', display: 'block', width: '100%', height: '40px;'}}
          floatingLabelText={`${indicator.name} (${this.props.t(indicator.resultFormatEnum.label)})`}
          onChange={(value) => {
            let newValue = 0;
            if (value && !isEmpty(value)) {
              newValue = value;
            }

            indicator.value = newValue;
            indicator.valueMasked = newValue;
            this.props.setStateMeta(`indicators[${index}]`, indicator, indicator.reference);
          }}
        />
      </span>
    );
  }

  renderDecimal(indicator, index) {
    return (
      <span 
        id={`span-decimal-${indicator.reference}-${index}`}
        ref={`span-decimal-${indicator.reference}-${index}`}
        key={`span-decimal-${indicator.reference}-${index}`}
      >
        <input
          min={'0'}
          step='0.01'
          type={'number'}
          autoComplete='off'
          id={indicator.reference} 
          ref={indicator.reference} 
          className={styles.balloon + ' custom-indicator-decimals'}
          value={indicator.value}
          onChange={(event) => {
            let value = event.target.value;
            if (value.length > 6) {
              value = indicator.value
            }

            indicator.value = value;
            this.props.setStateMeta(`indicators[${index}]`, indicator, indicator.reference);
          }}
        />
        <label 
          htmlFor={indicator.reference}
          style={this.props.groupEquipments ? {left: '0px'} : {}}
        >
          {`${indicator.name} (${this.props.t(indicator.resultFormatEnum.label)})`}
        </label>
      </span>
    );
  }

  renderIntegers(indicator, index) {
    return (
      <span 
        id={`span-integer-${indicator.reference}-${index}`}
        ref={`span-integer-${indicator.reference}-${index}`}
        key={`span-integer-${indicator.reference}-${index}`}
      >
        <input
          type={'text'}
          maxLength={9}
          autoComplete="off"
          value={indicator.value}
          id={indicator.reference}
          ref={indicator.reference}
          className={styles.balloon}
          title={`${indicator.name} (${this.props.t(indicator.resultFormatEnum.label)})`}
          onKeyDown={(event) => {
            const newValue = event.target.value.replace(/[^0-9]/, '');
            indicator.value = parseInt(newValue);

            this.props.setStateMeta(`indicators[${index}]`, indicator, indicator.reference);
          }}
          onChange={(event) => {
            const newValue = event.target.value.replace(/[^0-9]/, '');

            let integerValue = 0;
            if (newValue && !isEmpty(newValue)) {
              integerValue = parseInt(newValue);
            }

            indicator.value = integerValue;
            this.props.setStateMeta(`indicators[${index}]`, indicator, indicator.reference);
          }}
        />
        <label 
          htmlFor={indicator.reference}
          style={this.props.groupEquipments ? {left: '0px'} : {}}
        >
          {`${indicator.name} (${this.props.t(indicator.resultFormatEnum.label)})`}
        </label>
      </span>
    );
  }

  getCustomIndicatorFieldChunck(indicators) {
    const customIndicatorFieldChunck = indicators.map((indicator, index) => {
      let customIndicatorField = '';
      indicator.reference = `input-indicator-${indicator.uuid || index }`;

      switch (indicator.resultFormat) {
        case FormatoResultado.HORAS.id:
          indicator.resultFormatEnum = FormatoResultado.HORAS;
          indicator.hourMasked = indicator.hourMasked || THoursMinutes(indicator.value);

          customIndicatorField = this.renderHoursMinutes(indicator, index);
          break;

        case FormatoResultado.MINUTOS.id:
          indicator.resultFormatEnum = FormatoResultado.MINUTOS;
          indicator.hourMasked = indicator.hourMasked || THoursMinutes(indicator.value);

          customIndicatorField = this.renderHoursMinutes(indicator, index);
          break;

        case FormatoResultado.INTEIRO.id:
          indicator.resultFormatEnum = FormatoResultado.INTEIRO;
          customIndicatorField = this.renderIntegers(indicator, index);
          break;

        case FormatoResultado.PORCENTAGEM.id:
          indicator.resultFormatEnum = FormatoResultado.PORCENTAGEM;
          customIndicatorField = this.renderPercent(indicator, index);
          break;

        case FormatoResultado.DECIMAL.id:
          indicator.resultFormatEnum = FormatoResultado.DECIMAL;
          this.decimalValueFormated(indicator);

          customIndicatorField = this.renderDecimal(indicator, index);
          break;

        default:
          indicator.resultFormatEnum = { label: 'Não Informado' };
          return <div style={{ width: '101%' }}></div>
          break;
      }

      return customIndicatorField;
    });

    return customIndicatorFieldChunck;
  }

  /**
   * Render form custom indicators.
   */
  renderCustomIndicators() {
    /**
     * Tratamento para realizar a disposição correta com dois elementos
     * em cada 'linha' no body do modal.
     */
    const listCustomIndicators = this.props.customIndicators;
    if (Math.abs(listCustomIndicators.length % 2) == 1 && this.props.beautifulDisplay) {
      listCustomIndicators.push({});
    }

    const sizeChunk = this.props.sizeChunk || listCustomIndicators.length;
    const customIndicatorsChunk = chunk(listCustomIndicators, sizeChunk);
    const customIndicatorsFields = customIndicatorsChunk.map((indicators, count) => {
      const customIndicatorFieldChunck = this.getCustomIndicatorFieldChunck(indicators);

      return (
        <div
          id={`data-indicators-${count}`}
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          {customIndicatorFieldChunck}
        </div>
      );
    }
    );

    return customIndicatorsFields;
  }

  render() {
    return (
      <div>
        {this.renderCustomIndicators()}
      </div>
    );
  }
}

export default withTranslation("components")(CustomIndicatorForm);