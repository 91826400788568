import { observable, computed, action } from "mobx";

import Utils from "../../Utils/Utils";
import { APIEndpoints } from '../../../common/Constants';

class AlertLastVigencia extends Utils {
    @observable customNoFunction = undefined
    @observable customYesFunction = undefined


    _checkEmpty(id, urlRequest){
        super.submit(id, 'post', `${APIEndpoints.TIMEMACHINE}${urlRequest}/${id}`, { 'Content-Type': 'application/json' })
    }

    @action
    Yes(item, urlRequest, callBackFunction) {
        if (this.CustomYesFunction) {
            // Um array de comandos para tomar atitudes diferentes
            let dispatch = this.customYesFunction()
            
            if (dispatch[0] == 'checkEmpty') {
                this._checkEmpty(dispatch[1], urlRequest)
            }

            return;
        }
        
        super.submit(item, 'post', `${APIEndpoints.TIMEMACHINE}${urlRequest}`, { 'Content-Type': 'application/json' }, callBackFunction)
    }

    @action
    No = () => {
        this.customNoFunction();
    }

    @action
    setCustomNoFunction = customNoFunction => {
        this.customNoFunction = customNoFunction;
    }
    @action
    setCustomYesFunction = customYesFunction => {
        this.customYesFunction = customYesFunction;
    }

    @computed get CustomNoFunction(){
        return this.customNoFunction;
    }
    @computed get CustomYesFunction(){
        return this.customYesFunction;
    }
}

export default AlertLastVigencia