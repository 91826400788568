import Rotas from "./timeMachine/Rotas";
import ModalItens from "./modal/modalItens";
import Modal from './modal/modalCommon';
import AlertVigenciaRotas from "./alerta/timeMachine/alertVigenciaRotas";
import AlertLastVigenciaRotas from "./alerta/timeMachine/alertLastVigenciaRotas";
import ModalStepOneStore from "./modal/modalStepOne";
import DeleteHoldingTime from "./alerta/holdingTime/alertHoldingTime";
import RouteContainerStore from "./routes/routeContainerStore";
import Setups from "./timeMachine/Equipamento/Setups";
import ModalControlVigencias from "./timeMachine/Modal/ModalControlVigencias";
import AlertLastVigencia from "./alerta/timeMachine/alertLastVigencia";
import AlertVigencia from "./alerta/timeMachine/alertVigencia";
import ContainerStore from "./containerStore";
import FracionarApontamento from './apontamentos/fracionarApontamento';
import DisponibilidadeStore from './disponibilidade/disponibilidadeStore';


class Store {
    register(){
        return {
            timeMachineRotas: new Rotas(),
            modalItens: new ModalItens(),
            modalCommon: new Modal(),
            modalStepOne: new ModalStepOneStore(),
            alertVigenciaRotas: new AlertVigenciaRotas(),
            alertLastVigenciaRotas: new AlertLastVigenciaRotas(),
            alertDeleteHoldingTime: new DeleteHoldingTime(),
            routeContainerStore: new RouteContainerStore(),
            fracionarApontamentoStore: new FracionarApontamento(),
            setupStore: new Setups(),
            modalControlVigenciasStore: new ModalControlVigencias(),
            lastAlertaVigencia: new AlertLastVigencia(),
            alertaVigencia: new AlertVigencia(),
            containerStore: new ContainerStore(),
            disponibilidadeStore : new DisponibilidadeStore()
        };
    }
}

const store = new Store().register();
export default store;
