import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import InfiniteScroll from 'react-infinite-scroller';
import FilterBox from '../../common/FilterBox';
import { hasRole } from '../../helper/SecurityHelper';
import MDSpinner from "react-md-spinner";

@observer
export default class PredecessoresList extends Component {
  constructor() {
    super();
    this.onFilter = this.onFilter.bind(this);
    this.state = {
      makeSearch: null
    }
  }

  componentWillMount() {
    this.props.store.searchModel = 'predecessores';
  }

  componentWillUpdate() {
    this.listLink();
  }

  componentDidMount() {
    this.props.handleLoadMore();
  }

  listLink(inter) {
    if (this.props.store.predecessores[0] && !this.props.store.predecessores[0].pis) {
      clearInterval(inter);

      predecessor = this.props.store.predecessores.filter(b => !!b).reduce((acc, b) => {
        if (!temp.includes(b.id_lotePai)) {
          temp.push(b.id_lotePai);
          acc[k] = b;
          k++;
        }
        return acc;
      }, {});

      Object.keys(predecessor).forEach(l => {
        lotePai = predecessor[l].id_lotePai;
        k = 0;
        predecessores = this.props.store.predecessores.filter(b => !!b).reduce((acc, b, i) => {
          if (lotePai == b.id_lotePai) {
            acc[k] = b;
            k++;
          }
          return acc;
        }, {});

        predecessor[l].pis = predecessores;
      });

      for (let o in predecessor) {
        if (predecessor.hasOwnProperty(o)) {
          arrPredecessores.push(predecessor[o]);
        }
      }

      setTimeout(() => {
        this.props.store.predecessores.replace(arrPredecessores);
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.props.store.searchModel = '';
    this.props.store.searchValue = '';
  }

  onFilter(val) {
    clearTimeout(this.state.makeSearch);

    let value = val.trim().replace(/[^a-z0-9 à-ý]/gi, '');
    this.props.setSearchText(value, true);

    this.setState({
      makeSearch: setTimeout(() => {
        this.props.handleLoadMore();
      }, 1000)
    });
  }

  render() {
    const {
      handleLoadMore,
      hasMore,
      predecessoresPaginacao
    } = this.props;

    let loader = (
      <div className="loader">
        <MDSpinner singleColor='#2D4F7F' size={20} style={{ marginTop: -5 }} />
        &nbsp;&nbsp;{this.props.t("loading")}
      </div>
    );

    let items = predecessoresPaginacao.map((item, i) => {
      if (item) {
        return (
          <tr key={i}>
            <td style={{ textAlign: 'center' }}>{i + 1}</td>
            <td style={{ textAlign: 'center' }}>{item.numero_sku_lotePai}</td>
            <td style={{ textAlign: 'center' }}>{item.numero_lotePai}</td>

            {item.predecessores[0] ? <td style={{ textAlign: 'center' }}>{item.predecessores[0].produto.sku}</td> : <td>  </td>}
            {item.predecessores[0] ? <td style={{ textAlign: 'center' }}>{item.predecessores[0].numero}</td> : <td>  </td>}

            {item.predecessores[1] ? <td style={{ textAlign: 'center' }}>{item.predecessores[1].produto.sku}</td> : <td>  </td>}
            {item.predecessores[1] ? <td style={{ textAlign: 'center' }}>{item.predecessores[1].numero}</td> : <td>  </td>}

            {item.predecessores[2] ? <td style={{ textAlign: 'center' }}>{item.predecessores[2].produto.sku}</td> : <td>  </td>}
            {item.predecessores[2] ? <td style={{ textAlign: 'center' }}>{item.predecessores[2].numero}</td> : <td>  </td>}

            <td style={{ textAlign: 'center' }}>
              <div className='options'>
                <i className='material-icons' title={this.props.t("delete")} onClick={() => hasRole('ROLE_LINK_PI_PA_EXCLUIR') && this.props.handleModal(item, 'del')}>cancel</i>
              </div>
            </td>
          </tr>
        );
      }
    });
    if (items.length) {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_LINK_PI_PA_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={localStorage.getItem("reference") == "true" ? this.props.t('batchesLink') : this.props.t('ordersLink')} />
            <CardText className='table_overflow'>
              <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={hasMore}
                useWindow={false}
                initialLoad={false}
                loader={loader}
              >
                <FilterBox waitingRequest={this.props.waitingRequest} onFilter={this.onFilter} />
                <table className='mui-table mui-table--bordered'>
                  <thead>
                    <tr>
                      <td style={{ textAlign: 'center' }}>#</td>
                      <td style={{ textAlign: 'center' }}>{this.props.t("skuProduct")}</td>
                      <td style={{ textAlign: 'center' }}>{localStorage.getItem('reference') == 'true' ? this.props.t("batch") : this.props.t("order")}</td>
                      <td style={{ textAlign: 'center' }}>{this.props.t("skuPred1")}</td>
                      <td style={{ textAlign: 'center' }}>{localStorage.getItem('reference') == 'true' ? this.props.t('orderPred1', { batch: this.props.t('batch') }) : this.props.t('orderPred1', { batch: this.props.t('order') })}</td>
                      <td style={{ textAlign: 'center' }}>{this.props.t("skuPred2")}</td>
                      <td style={{ textAlign: 'center' }}>{localStorage.getItem('reference') == 'true' ? this.props.t('orderPred2', { batch: this.props.t('batch') }) : this.props.t('orderPred2', { batch: this.props.t('order') })}</td>
                      <td style={{ textAlign: 'center' }}>{this.props.t("skuPred3")}</td>
                      <td style={{ textAlign: 'center' }}>{localStorage.getItem('reference') == 'true' ? this.props.t('orderPred3', { batch: this.props.t('batch') }) : this.props.t('orderPred3', { batch: this.props.t('order') })}</td>
                      <td style={{ textAlign: 'center' }}>{this.props.t("actions")}</td>
                    </tr>
                  </thead>
                  <tbody className='box_items'>
                    {items}
                  </tbody>
                </table>
              </InfiniteScroll>
            </CardText>
          </Card>
        </div>
      )
    }
    else {
      return (
        <div className='box_list'>
          <Card className='box_list_card'>
            <FloatingActionButton disabled={!hasRole('ROLE_LINK_PI_PA_CRIAR')} className='box_add' onClick={() => this.props.handleModal('', 'add')}>
              <ContentAdd />
            </FloatingActionButton>
            <CardHeader className='box_header' title={localStorage.getItem('reference') == 'true' ? this.props.t("batchesLink") : this.props.t("ordersLink")} />
            <CardText>
              <div style={{ marginTop: 30 }}>
                <h1>{this.props.t("noItemsFound")}</h1>
              </div>
            </CardText>
          </Card>
        </div>
      );
    }
  }
}
