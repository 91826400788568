import React, { Component } from 'react';
import Container from '../../common/Container';
import MDSpinner from 'react-md-spinner';
import moment from 'moment';
import Util from '../../utils/Utils';
import update from 'immutability-helper';
import {
  requestRelatorioXLSX,
  request,
} from '../../common/request';
import {
  RaisedButton,
  Checkbox,
  Divider,
  AutoComplete,
  TextField,
} from 'material-ui';
import {
  NForm,
  NFormTitle,
  NButtonGroup,
  NDatePicker,
  NContainer,
  alertBox,
} from '../../common/NForm';
import { withTranslation } from 'react-i18next';
import TextTag from 'common/Components/TextTag/TextTag';

var loteRequestTimeout = {};

class ModalRelatorioApontamentos extends Container {
  constructor(props) {
    super(props);

    this.state = {
      unidadeIndustrialSelected: undefined,
      equipamentoSelected: undefined,
      ordens: [],
      etapaSelected: undefined,

      // Checkboxes
      unidadeIndustrialChecked: false,
      equipamentoChecked: false,
      loteChecked: false,
      rejeitadosChecked: false,
      ordensPredecessorasChecked: true,
      normaisInconsistentesChecked: true,
      referenciaSecundariaChecked: false,
      loteCodigoParadasChecked: false,
      etapaChecked: false,
      separarColunaDataHoraChecked: false,
      templateErpChecked: false,

      // InputDisplayTexts
      unidadeIndustrialText: '',
      equipamentoText: '',
      periodoRelatorioText: this.props.t('date.calendar.today', { ns: 'common' }),
      etapaText: '',

      // Errors
      errors: {
        dataInicioObrigatoria: undefined,
        dataFimObrigatoria: undefined,
      },
      erroSessaoUm: false,
      erroUnidadeIndustrial: false,
      erroEquipamento: false,
      erroLote: false,
      erroEtapa: false,

      // Datas e Validações de data
      dataInicio: undefined,
      dataFim: undefined,
      dataInicioText: '',
      dataFimText: '',
      maxDate: moment(),

      // Outros
      linhaErro: '2px solid red',
      linhaNormal: '1px solid rgb(224, 224, 224)',
      opcoesEquipamentos: [],
      btnGerarDisabled: false,
      periodoOpcoes: [
        { label: this.props.t('date.calendar.today', { ns: 'common' }), value: this.props.t('date.calendar.today', { ns: 'common' }) },
        { label: this.props.t('date.calendar.yesterday', { ns: 'common' }), value: this.props.t('date.calendar.yesterday', { ns: 'common' }) },
        { label: this.props.t('date.calendar.currentWeek', { ns: 'common' }), value: this.props.t('date.calendar.currentWeek', { ns: 'common' }) },
        { label: this.props.t('date.calendar.currentMonth', { ns: 'common' }), value: this.props.t('date.calendar.currentMonth', { ns: 'common' }) },
        { label: this.props.t('labels.customize', { ns: 'common' }), value: this.props.t('labels.customize', { ns: 'common' }) },
      ],
      opcoesEtapas: [],
      equipamentos: [],
      ehGrupoEquipamento: false
    };

    this.checkUnidadeIndustrial = this.checkUnidadeIndustrial.bind(this);
    this.checkEquipamento = this.checkEquipamento.bind(this);
    this.carregarTodosEquipamentos = this.carregarTodosEquipamentos.bind(this);
    this.checkLote = this.checkLote.bind(this);
    this.setUnidadeIndustrial = this.setUnidadeIndustrial.bind(this);
    this.limpaUnidadeIndustrial = this.limpaUnidadeIndustrial.bind(this);
    this.filtrarEquipamentosPorEtapa = this.filtrarEquipamentosPorEtapa.bind(this);
    this.handlePeriodoClick = this.handlePeriodoClick.bind(this);
    this.setLote = this.setLote.bind(this);
    this.gerarRelatorioApontamento = this.gerarRelatorioApontamento.bind(this);
    this.erroRelatorio = this.erroRelatorio.bind(this);
    this.requestLote = this.requestLote.bind(this);
    this.setEquipamento = this.setEquipamento.bind(this);
    this.atualizarStatePorNome = this.atualizarStatePorNome.bind(this);
    this.validarDatas = this.validarDatas.bind(this);
    this.updateDataInicio = this.updateDataInicio.bind(this);
    this.updateDataFim = this.updateDataFim.bind(this);
    this.limparData = this.limparData.bind(this);
    this.checkEtapa = this.checkEtapa.bind(this);
    this.setEtapa = this.setEtapa.bind(this);
    this.limpaEtapa = this.limpaEtapa.bind(this);
    this.carregarEquipamentos = this.carregarEquipamentos.bind(this);
  }

  // MANIPULAÇÃO DA UNIDADE INDUSTRIAL
  checkUnidadeIndustrial(value) {
    if (!value) {
      this.limpaUnidadeIndustrial();
      this.checkEtapa(false);
      this.carregarTodosEquipamentos();
    }

    this.setState({
      unidadeIndustrialChecked: value,
      erroSessaoUm: false
    });
  }

  setUnidadeIndustrial(nomeUnidade, unidadesIndustriais) {
    let unidadeIndustrial = unidadesIndustriais.find(i => i.text === nomeUnidade);

    if (!unidadeIndustrial) {
      this.setState({
        unidadeIndustrialSelected: undefined,
        unidadeIndustrialText: '',
      });

      return;
    }

    const unidadeIndustrialStore = this.store.sessions.find(s => s.id === unidadeIndustrial.value);

    if (unidadeIndustrialStore) {
      this.carregarEquipamentos(unidadeIndustrialStore);
      this.carregarEtapaPorUnidadeIndustrial(unidadeIndustrialStore);
    }

    this.setState((_state, _props) => ({
      unidadeIndustrialSelected: unidadeIndustrial,
      unidadeIndustrialText: unidadeIndustrial.text,
      erroUnidadeIndustrial: false,
    }));
  }

  limpaUnidadeIndustrial() {
    this.setState({
      unidadeIndustrialSelected: undefined,
      unidadeIndustrialText: '',
      equipamentoSelected: undefined,
      equipamentoText: '',
      ordens: [],
      etapaSelected: undefined,
      opcoesEtapas: [],
      etapaText: '',
    });
  }

  // MANIPULAÇÃO DO EQUIPAMENTO
  checkEquipamento(equipChecked) {
    this.setState({
      equipamentoChecked: equipChecked,
      erroSessaoUm: false
    });

    if (!equipChecked) {
      this.setState({
        equipamentoText: '',
        equipamentoSelected: undefined
      });

    } else {
      if (!this.state.unidadeIndustrialSelected) {
        this.carregarTodosEquipamentos();
      };
    }
  }

  /**
   * Limpa os atributos associados com a seleção de 'Etapa'.
   */
  limpaEtapa() {
    this.setState({
      equipamentoSelected: undefined,
      equipamentoText: '',
      etapaSelected: undefined,
      etapaText: '',
    });

    const unidadeIndustrial = this.store.sessions.find(unidade => unidade.id === this.state.unidadeIndustrialSelected.value);
    this.carregarEquipamentos(unidadeIndustrial);
  }

  /**
   * Atualiza o atributo 'etapaChecked' e suas dependências conforme a ação do Usuário.
   * 
   * @param {*} etapaChecked 
   */
  checkEtapa(etapaChecked) {
    this.setState({
      etapaText: '',
      opcoesEtapas: [],
      erroSessaoUm: false,
      etapaSelected: undefined,
      etapaChecked: etapaChecked,
      equipamentoSelected: undefined,
      equipamentoText: ''
    });

    if (!etapaChecked) {
      if (this.state.unidadeIndustrialSelected) {
        const unidadeIndustrial = this.store.sessions.find(unidade => unidade.id === this.state.unidadeIndustrialSelected.value);
        this.carregarEquipamentos(unidadeIndustrial);
      } else {
        this.carregarTodosEquipamentos();
      }
      return;
    }

    if (this.state.unidadeIndustrialSelected) {
      const unidadeIndustrial = this.store.sessions.find(unidade => unidade.id === this.state.unidadeIndustrialSelected.value);
      this.carregarEtapaPorUnidadeIndustrial(unidadeIndustrial);
    };
  }

  /**
   * Atualiza a state com a opção selecionada pelo Usuário.
   * 
   * @param {*} descricao 
   * @param {*} etapas 
   */
  setEtapa(descricao, etapas) {
    const etapa = etapas.find(i => i.text === descricao);

    if (!etapa) {
      this.setState({
        etapaSelected: undefined,
        etapaText: '',
      });

      return;
    }

    const unidadeIndustrial = this.store.sessions.find(unidade => unidade.id === this.state.unidadeIndustrialSelected.value);
    this.carregarEquipamentos(unidadeIndustrial, etapa);

    this.setState({
      etapaSelected: etapa,
      etapaText: etapa.text,
      erroEtapa: false,
    });
  }

  /**
   * Carrega as etapas da store conforme a Unidade Industrila selecionada.
   * 
   * @param {*} unidadeIndustrial
   */
  carregarEtapaPorUnidadeIndustrial(unidadeIndustrial) {
    let etapas = [];

    for (const etapaUnidade of unidadeIndustrial.stages) {
      etapas.push({ text: etapaUnidade.name, value: etapaUnidade.id });
    }

    this.setState({
      opcoesEtapas: etapas
    });
  }

  setEquipamento(nomeEquipamento, equipamentos) {
    let equipamentoSelecionado = equipamentos.find(i => i.text === nomeEquipamento);

    this.setState({
      equipamentoSelected: equipamentoSelecionado,
      equipamentoText: nomeEquipamento,
      erroEquipamento: false,
      ehGrupoEquipamento: equipamentoSelecionado?.ehGrupo ? true : false
    });
  }

  carregarTodosEquipamentos() {
    if (this.state.equipamentos.length > 0) {
      const opcoesEquipamentos = this.state.equipamentos.map(e => {
        return { text: `${e.tag} - ${e.nome}`, value: e.id };
      });

      this.setState({
        opcoesEquipamentos: opcoesEquipamentos,
        ehGrupoEquipamento: false
      });

      return;
    }

    request(this.endpoints.EQUIPAMENTOS, { method: 'GET' })
      .then(response => {
        if (response.ok)
          {return response.json();}
      })
      .then(equipamentos => {
        const opcoesEquipamentos = equipamentos.map(e => {
          return { text: `${e.tag} - ${e.nome}`, value: e.id };
        });

        this.setState({
          equipamentos: equipamentos,
          opcoesEquipamentos: opcoesEquipamentos,
          ehGrupoEquipamento: false
        });
      });
  }

  /**
   * Carrega as opções de equipamento conforme os parâmetros informados.
   * 
   * @param {*} unidadeIndustrial 
   * @param {*} etapaSelected 
   */
  carregarEquipamentos(unidadeIndustrial, etapaSelected) {
    const opcoesEquipamentos = this.filtrarEquipamentosPorEtapa(unidadeIndustrial.stages, etapaSelected);

    this.setState({
      opcoesEquipamentos: opcoesEquipamentos,
      ehGrupoEquipamento: false
    });
  }

  filtrarEquipamentosPorEtapa(etapas, etapaSelected) {
    let equipamentos = [];
    let opcoesEquipamentos = [];

    if (etapaSelected) {
      const etapa = etapas.find(item => item.id === etapaSelected.value);
      equipamentos.push(...etapa.equipments);
    } else {
      for (const etapa of etapas) {
        equipamentos.push(...etapa.equipments);
      }
    }

    for (const equipamento of equipamentos) {
      opcoesEquipamentos.push({
        text: `${equipamento.tag} - ${equipamento.name}`,
        value: equipamento.id
      });
    }

    return opcoesEquipamentos;
  }

  // MANIPULAÇÃO DO LOTE
  checkLote(_event, value) {
    this.setState({
      loteChecked: value,
      ordensPredecessorasChecked: true,
      erroSessaoUm: false
    });

    if (!value) {
      this.setState({
        ordens: [],
      });
    }
  }

  setLote(v) {
    clearTimeout(loteRequestTimeout);
    let numeroLote = v.trim();

    if (numeroLote) {
      this.setState((_state, _props) => ({ btnGerarDisabled: true, erroLote: false }));
      loteRequestTimeout = setTimeout(() => this.requestLote(numeroLote), 1000);
    }
  }

  requestLote(numeroLote) {
    request(this.endpoints.LOTE_NUMERO + `/${numeroLote}?finalizado=true`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then(data => {
        if (!data) {
          this.setState((s) => {
            return {
              btnGerarDisabled: false,
            };
          });

          return;
        }

        this.setState((s) => ({
          ordens: [...s.ordens, {
            id: data.id,
            numero: data.numero,
          }],
          btnGerarDisabled: false,
        }));
      });
  }

  handlePeriodoClick(_event, value) {
    this.setState({
      periodoRelatorioText: value
    });
  }

  gerarRelatorioApontamento() {
    let {
      unidadeIndustrialSelected,
      equipamentoSelected,
      periodoRelatorioText,
      dataInicio,
      dataFim,
      normaisInconsistentesChecked,
      rejeitadosChecked,
      ordensPredecessorasChecked,
      ordens,
      unidadeIndustrialChecked,
      equipamentoChecked,
      loteChecked,
      loteCodigoParadasChecked,
      referenciaSecundariaChecked,
      etapaSelected,
      etapaChecked,
      separarColunaDataHoraChecked,
      templateErpChecked
    } = this.state;

    var error = false, reqRejeitado = '';

    if (unidadeIndustrialChecked && !unidadeIndustrialSelected) {
      this.setState({ erroUnidadeIndustrial: true });
      error = true;
      alertBox('Informe uma unidade industrial', this.props.t('labels.warning', { ns: 'common' }), 'warning');
    } else {
      this.setState({ erroUnidadeIndustrial: false });
    }

    if (etapaChecked && unidadeIndustrialSelected && !etapaSelected) {
      this.setState({ erroEtapa: true });
      error = true;
      alertBox('Informe uma etapa', this.props.t('labels.warning', { ns: 'common' }), 'warning');
    } else {
      this.setState({ erroEtapa: false });
    }

    if (equipamentoChecked && !equipamentoSelected) {
      this.setState({ erroEquipamento: true });
      alertBox('Informe um equipamento.', this.props.t('labels.warning', { ns: 'common' }), 'warning');
      error = true;
    } else {
      this.setState({ erroEquipamento: false });
    }

    if (loteChecked && ordens.length === 0) {
      this.setState({ erroLote: true });
      alertBox(
        `Insira ${this.utils.getReferenciaUmLoteUmaOrdem()} existente.`,
        this.utils.getReferenciaLoteInvalidoOrdemInvalida(),
        'warning');
      error = true;
    } else {
      this.setState({ erroLote: false });
    }

    if ((!unidadeIndustrialChecked && !equipamentoChecked && !loteChecked) ||
      (!normaisInconsistentesChecked && !rejeitadosChecked)) {
      alertBox(this.props.t('errors.noOptionsSelected'), this.props.t('labels.warning', { ns: 'common' }), 'warning');
      error = true;
    }

    if (periodoRelatorioText === this.props.t('labels.custom')) {
      if (!dataInicio || !dataInicio.isValid()) {
        this.setState((_state, _props) => ({ dataInicio: undefined }));
        alertBox(this.props.t('errors.invalidStartDate'), this.props.t('labels.warning', { ns: 'common' }), 'warning');
        error = true;
      }

      if (!dataFim || !dataFim.isValid()) {
        this.setState((_state, _props) => ({ dataFim: undefined }));
        alertBox(this.props.t('errors.invalidEndDate'), this.props.t('labels.warning', { ns: 'common' }), 'warning');
        error = true;
      }

      if (!dataInicio || !dataFim) {
        this.setState({ erroDatas: true });
        error = true;
      }

      if (!dataInicio && dataFim) {
        this.setState({ erroDataInicial: true });
        error = true;
      }

      if (dataInicio && !dataFim) {
        this.setState({ erroDataFinal: true });
        error = true;
      }

      if (dataInicio && !dataFim) {
        if (moment(dataFim).format('YYYY-MM-DD').localeCompare(moment(dataInicio).format('YYYY-MM-DD')) === -1) {
          this.setState({
            erroDatas: false,
            erroDataFinal: true
          });

          error = true;
          alertBox(this.props.t('errors.endDateLessThanStartDate'), this.props.t('labels.warning', { ns: 'common' }), 'warning');

        } else {
          this.setState({
            erroDatas: false,
            erroDataInicial: false,
            erroDataFinal: false
          });
        }
      }
    }

    if (!ordens.length) {
      if (!periodoRelatorioText) {
        this.setState({ erroPeriodo: true });
        error = true;
      } else {
        this.setState({ erroPeriodo: false });
      }
    }

    if (!unidadeIndustrialChecked && !equipamentoChecked && !loteChecked) {
      this.setState({ erroSessaoUm: true });

      error = true;
    }

    if (!normaisInconsistentesChecked && !rejeitadosChecked) {
      this.setState({ erroInformacoes: true });
      error = true;

    } else {
      this.setState({ erroInformacoes: false });
    }

    let url = `${this.endpoints.RELATORIO_APONTAMENTO}?`;

    if (unidadeIndustrialSelected) {
      url += 'unidadeIndustrialId=' + unidadeIndustrialSelected.value;
    }

    if (etapaSelected) {
      url += this.ampersand(url) + 'etapaId=' + etapaSelected.value;
    }

    if (equipamentoSelected) {
      url += this.ampersand(url) + 'equipamentoId=' + equipamentoSelected.value;
    }

    url += this.ampersand(url) + 'ehGrupo=' + this.state.ehGrupoEquipamento;

    if (ordens.length !== 0) {
      const formatLoteIds = (array) =>  {
        let result = '';
        array.forEach((orden, index) => {
            result += `loteIds[${index}]=${orden.id}`;
            if (index < array.length - 1) {
                result += '&';
            }
        });
        return result;
    };
      url += this.ampersand(url) + `${formatLoteIds(ordens)}`;
    }

    if (loteChecked && ordensPredecessorasChecked) {
      url += this.ampersand(url) + `ordensPredecessoras=${ordensPredecessorasChecked}`;
    }

    if (rejeitadosChecked) {
      reqRejeitado = url + this.ampersand(url) + 'normal=' + !rejeitadosChecked;

      if (separarColunaDataHoraChecked) {
        reqRejeitado = reqRejeitado + this.ampersand(url) + 'separarColunaDataHora=' + separarColunaDataHoraChecked;
      }
    }

    if (normaisInconsistentesChecked) {
      url += this.ampersand(url) + 'normal=' + normaisInconsistentesChecked;
    }

    if (loteCodigoParadasChecked) {
      url += this.ampersand(url) + 'numeroLoteNaParada=' + loteCodigoParadasChecked;
    }

    if (referenciaSecundariaChecked) {
      url += this.ampersand(url) + 'referenciaSecundaria=' + referenciaSecundariaChecked;
    }

    if (templateErpChecked) {
      url += this.ampersand(url) + 'templateErp=' + templateErpChecked;
    }

    if (separarColunaDataHoraChecked) {
      url += this.ampersand(url) + 'separarColunaDataHora=' + separarColunaDataHoraChecked;
    }

    if (!loteChecked) {
      if (periodoRelatorioText === this.props.t('labels.custom')) {
        if (dataInicio && dataFim) {
          url += this.ampersand(url) + `dataInicio=${moment(dataInicio).format('DD-MM-YYYY')}&dataFim=${moment(dataFim).format('DD-MM-YYYY')}`;
          reqRejeitado += this.ampersand(url) + `dataInicio=${moment(dataInicio).format('DD-MM-YYYY')}&dataFim=${moment(dataFim).format('DD-MM-YYYY')}`;
        }
      } else {
        dataFim = moment();

        switch (periodoRelatorioText) {
          case this.props.t('date.calendar.today', { ns: 'common' }):
            dataInicio = moment();
            break;

          case this.props.t('date.calendar.yesterday', { ns: 'common' }):
            dataInicio = dataFim = moment().subtract(1, 'days');
            break;

          case this.props.t('date.calendar.currentWeek', { ns: 'common' }):
            dataInicio = moment().startOf('week');
            break;

          case this.props.t('date.calendar.currentMonth', { ns: 'common' }):
            dataInicio = moment().startOf('month');
            break;
        }

        url += this.ampersand(url) + `dataInicio=${dataInicio.format('DD-MM-YYYY')}&dataFim=${dataFim.format('DD-MM-YYYY')}`;
        reqRejeitado += this.ampersand(url) + `dataInicio=${dataInicio.format('DD-MM-YYYY')}&dataFim=${dataFim.format('DD-MM-YYYY')}`;
      }
    }

    if (error === false) {
      if (normaisInconsistentesChecked) {
        this.props.openModal('loading_relatorio');

        requestRelatorioXLSX(url, this.props.t('titles.consistentInconsistentNotesReport'))
          .then(() => this.props.closeModal(),
            erro => this.erroRelatorio(erro))
          .catch(erro => this.erroRelatorio(erro));
      }

      if (reqRejeitado !== '' && rejeitadosChecked) {
        this.props.openModal('loading_relatorio');

        requestRelatorioXLSX(reqRejeitado, this.props.t('titles.rejectedNotesReport'))
          .then(() => this.props.closeModal(),
            erro => this.erroRelatorio(erro))
          .catch(erro => this.erroRelatorio(erro));
      }
    }
  }

  erroRelatorio(erro) {
    if (typeof erro === 'object') {
      alertBox(erro.mensagem, this.props.t('labels.warning', { ns: 'common' }), 'warning');
      this.props.openModal('apontamento');
    } else if (Util.isJson(erro) && JSON.parse(erro).status === 'erro') {
      alertBox(JSON.parse(erro).mensagem, this.props.t('labels.warning', { ns: 'common' }), 'warning');
      this.props.openModal('apontamento');
    } else {
      this.setState({
        modal: true,
        tipoModal: 'erro',
        erroMSG: this.props.t('errors.cannotGenerateReport')
      });
    }
  }

  validarDatas() {
    let dataInicio = this.state.dataInicio;
    let dataFim = this.state.dataFim;
    let hoje = moment();

    if (dataInicio && dataInicio.isAfter(hoje)) {
      this.alertDataMaiorQueAtual('dataInicio');

      return;
    }

    if (dataFim && dataFim.isAfter(hoje)) {
      this.alertDataMaiorQueAtual('dataFim');

      return;
    }

    if (!dataInicio || !dataFim) return;

    if (dataFim.isAfter(this.state.maxDate)) {
      alertBox(this.props.t('errors.periodLimited'), this.props.t('labels.warning', { ns: 'common' }), 'warning');

      this.limparData('dataFim');
    }

    if (dataInicio.isAfter(dataFim)) {
      alertBox(this.props.t('errors.endDateLessThanStartDate'), this.props.t('labels.warning', { ns: 'common' }), 'warning');

      this.limparData('dataFim');
    }
  }

  alertDataMaiorQueAtual(campoData) {
    alertBox(this.props.t('errors.selectDateBeforeCurrentDate'), this.props.t('labels.warning', { ns: 'common' }), 'warning');
    this.limparData(campoData);
  }

  limparData(nomeCampo) {
    this.setState((_state, _props) => ({
      [nomeCampo]: undefined,
      [nomeCampo + 'Text']: '',
      [nomeCampo + this.props.t('labels.required')]: undefined,
    }));
  }

  ampersand(url) {
    return (url[url.length - 1] === '?') ? '' : '&';
  }

  async atualizarStatePorNome(property) {
    let { name, value } = property;

    this.setState({
      [name]: moment(value, 'DD/MM/YYYY'),
      [name + 'Text']: value,
    });
  }

  updateDataInicio(date) {
    this.setState(update(this.state, {
      dataInicio: { $set: date },
      dataInicioText: { $set: moment(date, 'DD/MM/YYYY') },
      dataFim: { $set: '' },
      maxDate: { $set: moment().isBefore(moment(date).add(3, 'months')) ? moment() : moment(date).add(3, 'months') },
      errors: { dataInicioObrigatoria: { $set: undefined } }
    }));
  }

  updateDataFim(date) {
    this.setState(update(this.state, {
      dataFim: { $set: date },
      dataFimText: { $set: moment(date, 'DD/MM/YYYY') },
      errors: { dataFimObrigatoria: { $set: undefined } },
    }));
  }

  render() {
    let {
      erroUnidadeIndustrial,
      erroEquipamento,
      erroEtapa,
      erroLote,
      linhaErro,
      linhaNormal,
    } = this.state;

    let unidadesIndustriais = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];

    unidadesIndustriais.forEach(unidade => {
      opcoesUnidade.push({
        text: unidade.name,
        value: unidade.id
      });
    });

    return (
      <NForm style={{ width: 490 }}>
        <NFormTitle>
          {this.props.t('titles.notesReport')}
        </NFormTitle>

        <div className={'flex flex-dir-col'} style={{ margin: '10px 0 20px', position: 'relative' }}>
          <div className={'flex flex-align-center'} style={{ minHeight: 35, boxSizing: 'border-box' }}>
            <Checkbox
              label={this.props.t('labels.industrialUnit')}
              checked={this.state.unidadeIndustrialChecked}
              onCheck={(_event, value) => this.checkUnidadeIndustrial(value)}
              style={{ width: '40%' }}
              iconStyle={{ fill: this.state.erroSessaoUm ? '#F00' : '#2b4d7c', marginRight: 10 }}
              labelStyle={{ width: '100%' }}
            />

            {
              this.state.unidadeIndustrialChecked ?
                <AutoComplete
                  id="unidadeIndustrial"
                  fullWidth={true}
                  style={{ width: '60%' }}
                  inputStyle={{ marginTop: 0, height: 35 }}
                  underlineFocusStyle={{ borderColor: '#000000', bottom: 2 }}
                  underlineStyle={{ bottom: 2, borderBottom: (erroUnidadeIndustrial ? linhaErro : linhaNormal) }}
                  textFieldStyle={{ marginTop: -1, height: 35, width: '100%' }}
                  floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', top: 0 }}
                  placeholder={this.props.t('labels.industrialUnit')}
                  title={this.props.t('labels.industrialUnit')}
                  onUpdateInput={this.setUnidadeIndustrial}
                  filter={AutoComplete.fuzzyFilter}
                  dataSource={opcoesUnidade}
                  onClick={() => {
                    this.limpaUnidadeIndustrial();
                    this.carregarTodosEquipamentos();
                  }}
                  openOnFocus={true}
                  popoverProps={{ className: 'autocomplete' }}
                />
                : null
            }
          </div>

          <div className={'flex flex-align-center'} style={{ minHeight: 35, boxSizing: 'border-box' }}>
            <Checkbox
              label={this.props.t('labels.stage')}
              style={{ width: '40%' }}
              labelStyle={{ width: '100%' }}
              checked={this.state.etapaChecked}
              onCheck={(_event, value) => this.checkEtapa(value)}
              disabled={!this.state.unidadeIndustrialChecked}
              iconStyle={{ fill: this.state.erroSessaoUm ? '#F00' : '#2b4d7c', marginRight: 10 }}
            />

            {this.state.etapaChecked && (
              <AutoComplete
                id="etapa"
                fullWidth={true}
                style={{ width: '60%' }}
                inputStyle={{ marginTop: 0, height: 35 }}
                underlineFocusStyle={{ borderColor: '#000000', bottom: 2 }}
                underlineStyle={{ bottom: 2, borderBottom: (erroEtapa ? linhaErro : linhaNormal) }}
                textFieldStyle={{ marginTop: -1, height: 35, width: '100%' }}
                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', top: 0 }}
                placeholder={this.props.t('labels.stage')}
                title={this.props.t('labels.stage')}
                disabled={this.state.opcoesEtapas.length === 0}
                onUpdateInput={this.setEtapa}
                filter={AutoComplete.fuzzyFilter}
                dataSource={this.state.opcoesEtapas}
                onClick={this.limpaEtapa}
                openOnFocus={true}
                popoverProps={{ className: 'autocomplete' }}
              />
            )}
          </div>

          <div className={'flex flex-align-center'} style={{ minHeight: 35, boxSizing: 'border-box' }}>
            <Checkbox
              label={this.props.t('labels.equipment')}
              checked={this.state.equipamentoChecked}
              onCheck={(_event, value) => this.checkEquipamento(value)}
              style={{ width: '40%' }}
              iconStyle={{ fill: this.state.erroSessaoUm ? '#F00' : '#2b4d7c', marginRight: 10 }}
            />
            {
              this.state.equipamentoChecked
                ?
                <AutoComplete
                  onUpdateInput={this.setEquipamento}
                  id={'equipamento'}
                  fullWidth={true}
                  style={{ width: '60%' }}
                  inputStyle={{ marginTop: 0, height: 35 }}
                  underlineFocusStyle={{ borderColor: '#000000', bottom: 2 }}
                  underlineStyle={{ bottom: 2, borderBottom: (erroEquipamento ? linhaErro : linhaNormal) }}
                  textFieldStyle={{ marginTop: -1, height: 35, width: '100%' }}
                  floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase', top: 0 }}
                  filter={AutoComplete.fuzzyFilter}
                  placeholder={this.props.t('labels.equipment')}
                  title={this.props.t('labels.equipment')}
                  dataSource={this.props.listEquipments}
                  openOnFocus={true}
                  popoverProps={{ className: 'autocomplete' }}
                  onClick={() => this.setState({
                    equipamentoText: '',
                    equipamentoSelected: undefined
                  })}
                />
                : null
            }
          </div>

          <div className={'flex flex-align-center'} style={{ minHeight: 35, boxSizing: 'border-box' }}>
            <Checkbox
              label={this.utils.getReferenciaLoteOrdem()}
              checked={this.state.loteChecked}
              onCheck={this.checkLote}
              style={{ width: '40%' }}
              iconStyle={{ fill: this.state.erroSessaoUm ? '#F00' : '#2b4d7c', marginRight: 10 }}
            />

            {
              this.state.loteChecked
                ?
                <span style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
              <TextTag
                 placeHolder={this.props.t('labels.ordens_relatorio')}
                 tags={this.state.ordens.map((o) => o.numero)}
                 limit={5}
                 onInput={(e) => {                  
                  this.setState(({ordens}) => {
                    const result = ordens.map((o) => o.numero).filter(item => !e.includes(item));

                    if(e.length < this.state.ordens.length) {
                      return {
                        ordens: this.state.ordens.filter((o) => !result.includes(o.numero))
                      };
                    } else {
                      const ordemNova = [...e];
                      this.setLote(ordemNova.pop());
                      return;
                    }
                  });
                 }}
                 style={{
                    width: '20.8rem',

                 }}
          />
                  {
                    this.state.btnGerarDisabled
                      ? <MDSpinner singleColor="#2D4F7F" size={20} style={{ marginTop: 4, marginRight: 6 }} />
                      : null
                  }
                </span>
                : null
            }
          </div>
        </div>

        {
          !this.state.loteChecked ?
            <div>
              <Divider />

              <h3 style={{ paddingTop: '1.5em', paddingBottom: '.5em', fontSize: '0.8em', textTransform: 'uppercase' }}>
                {this.props.t('labels.selectPeriod')}
              </h3>

              <div className="flex flex-dir-row flex-align-end" style={{ marginBottom: 20, paddingTop: 10 }}>
                <div className="flex flex-dir-col flex-align-start" style={{ width: '30%', justifyContent: 'center' }}>
                  {
                    this.state.periodoOpcoes.map((radio, index) => {
                      return <RadioPeriodo
                        key={index}
                        periodoRelatorioText={this.state.periodoRelatorioText}
                        onClick={this.handlePeriodoClick}
                        label={radio.label}
                        value={radio.value}
                      />;
                    })
                  }
                </div>

                <div className={'flex'} style={{ width: '70%' }}>
                  {
                    this.state.periodoRelatorioText == this.props.t('labels.custom') ?
                      <NContainer direction="horizontal">
                        <NDatePicker
                          compact={true}
                          required={this.state.errors.dataInicioObrigatoria}
                          selected={this.state.dataInicio}
                          label={this.props.t('labels.startDate')}
                          autoComplete={'off'}
                          maxDate={moment()}
                          onChangeRaw={event => Util.formatDate(event, this.atualizarStatePorNome, 'dataInicio', this.validarDatas)}
                          onChange={this.updateDataInicio}
                          value={this.state.dataInicioText}
                        />

                        <NDatePicker
                          compact={true}
                          required={this.state.errors.dataFimObrigatoria}
                          selected={this.state.dataFim}
                          minDate={this.state.dataInicio}
                          maxDate={this.state.maxDate}
                          label={this.props.t('labels.endDate')}
                          autoComplete={'off'}
                          onChangeRaw={event => Util.formatDate(event, this.atualizarStatePorNome, 'dataFim', this.validarDatas)}
                          onChange={this.updateDataFim}
                          value={this.state.dataFimText}
                        />
                      </NContainer>
                      : null
                  }
                </div>
              </div>
            </div>
            : null
        }

        <Divider />

        <h3 style={{ paddingTop: '1.5em', paddingBottom: '.5em', fontSize: '0.8em', textTransform: 'uppercase' }}>
          {this.props.t('labels.showingInfo')}
        </h3>

        <div className={'flex flex-dir-col'}
          style={{
            padding: '10px 0 1em 0',
            position: 'relative',
          }}>
          {
            this.state.loteChecked
              ?
              <Checkbox
                style={{ marginBottom: 10 }}
                label={this.props.t('labels.predecessorsInfo', { primaryReference: this.utils.getReferenciaLotesPredecessoresOrdensPredecessoras() })}
                checked={this.state.ordensPredecessorasChecked}
                onCheck={(_event, value) => this.setState({ ordensPredecessorasChecked: value })}
                iconStyle={{ fill: '#2b4d7c', marginRight: 10 }}
              />
              : null
          }

          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t('labels.notesAndInconsistent')}
            checked={this.state.normaisInconsistentesChecked}
            onCheck={(_event, value) => this.setState({ normaisInconsistentesChecked: value, erroInformacoes: false })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t('labels.rejectedNotes')}
            checked={this.state.rejeitadosChecked}
            onCheck={(_event, value) => this.setState({ rejeitadosChecked: value, erroInformacoes: false })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t('labels.secondaryReference')}
            checked={this.state.referenciaSecundariaChecked}
            onCheck={(_event, value) => this.setState({ referenciaSecundariaChecked: value, erroInformacoes: false })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t('labels.stopCodesWithBatch', { primaryReference: this.utils.getReferenciaLoteOrdem() })}
            checked={this.state.loteCodigoParadasChecked}
            onCheck={(_event, value) => this.setState({ loteCodigoParadasChecked: value, erroInformacoes: false })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t('labels.erpTemplate')}
            checked={this.state.templateErpChecked}
            onCheck={(_event, value) => this.setState({ templateErpChecked: value, erroInformacoes: false })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t('labels.splitDateHour')}
            checked={this.state.separarColunaDataHoraChecked}
            onCheck={(_event, value) => this.setState({ separarColunaDataHoraChecked: value, erroInformacoes: false })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />
        </div>

        <NButtonGroup>
          <RaisedButton
            onClick={this.gerarRelatorioApontamento}
            backgroundColor="#2D4F7F"
            buttonStyle={{ fontFamily: '' }}
            style={{ fontWeigth: 100 }}
            labelColor="#FFF"
            label={this.props.t('labels.generate', { ns: 'common' })}
            disabled={this.state.btnGerarDisabled}
          />

          <RaisedButton
            onClick={this.props.closeModal}
            buttonStyle={{ fontFamily: '' }}
            style={{
              fontWeigth: 100,
              marginLeft: 16
            }}
            label={this.props.t('labels.cancel', { ns: 'common' })} />
        </NButtonGroup>
      </NForm>
    );
  }
}

class RadioPeriodo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      periodoRelatorioText,
      onClick,
      label,
      value
    } = this.props;

    return (
      <div className={'flex flex-dir-row flex-just-between flex-align-center'}>
        <label
          className="control control-radio"
          htmlFor={`radioSete-${value}`}
          style={{
            height: '1.2em',
            width: 130,
            textAlign: 'left',
            paddingTop: 9
          }}
          onClick={event => onClick(event, value)}>
          <span style={{ marginLeft: '2em', width: 300 }}>
            {label}
          </span>

          <input type="radio" name="periodo" checked={periodoRelatorioText === value} id={`radioSete-${value}`} />
          <div
            style={{ top: '.2em' }}
            className="control_indicator"
            data-value={value}>
          </div>
        </label>
      </div>
    );
  }
}

export default withTranslation('reports')(ModalRelatorioApontamentos);
