import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import listStyles from './list.scss';
import moment from 'moment';
moment.locale('pt-br');
import { alertBox } from '../../common/NForm';
import utils from '../../utils/Utils';
import { hasRole } from '../../helper/SecurityHelper';
import { request, requestAll } from '../../common/request';
import { withTranslation } from 'react-i18next';

/*
*ESTE COMPONENTE FAZ REFERENCIA A LOTES EM ESPERA NA ETAPA
*OU SEJA LOTES QUE NAO TEM EQUIPAMENTO PRINCIPAL NA ROTA NA ETAPA EM QUESTAO
*/
@observer
class Lot extends Component {
  activeModal(type, favorite) {
    let obj = this.props.obj;
    obj.type = type;

    if (obj.inconsistente) {
      alertBox(this.props.t("errors.cannotDisplayDetails", { primaryReference: utils.getReferenciaoLoteaOrdem().toLowerCase() }), utils.getReferenciaLoteInconsistenteOrdemInconsistente(), 'error');
    } else {
      this.props.handleModal('detailLote', obj);
    }
  }

  enviarSegregacao(obj) {
    this.props.handleModal('enviarSegregacao', obj.fk_id_batches, '', obj);
  }

  outQuality(obj) {
    this.props.handleModal('outQuality', obj.fk_id_batches, '', obj);
  }

  leadTime(value) {
    return parseInt(value) + 'h';
  }

  toQuality(obj) {
    this.props.handleModal('toQuality', obj.fk_id_batches, '', obj);
  }

  buscarLotesFavoritos() {
    requestAll([
      {
        url: this.props.endpoints.FAVORITO,
        modelo: 'favorites'
      }
    ], this);
  }

  setLoteFavorito(idBatch, favorite, corLeadTime, adicionarLote) {
    let idUsuarioStorage = localStorage.getItem('id');
    favorite = favorite.find(f => f.lote.id == idBatch);

    let data = {
      id: favorite?.id,
      lote: {
        id: idBatch
      },
      usuario: {
        id: idUsuarioStorage
      },
      corleadtime: corLeadTime
    };

    request(`${this.props.endpoints.FAVORITO}/${adicionarLote}`, {
      method: 'post',
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {

          let mensagem = adicionarLote ?
            utils.getReferenciaLoteOrdem() == this.props.t("labels.batch", { ns: 'common' }) ? this.props.t("messages.batchAddedToFavorite") : this.props.t("messages.orderAddedToFavorite")
            :
            this.props.t("messages.deleteFavoriteSuccess", { primaryReference: utils.getReferenciaLoteOrdemExcluido() })
          alertBox(mensagem, this.props.t("labels.success", { ns: 'common' }), 'sucess');
        }
        else {
          return response.json();
        }
      })
      .catch(() => {
        alertBox(this.props.t("errors.cannotDelete", { primaryReference: utils.getReferenciaoLoteaOrdem() }), this.props.t("labels.error", { ns: 'common' }), 'error');
      })

    var findNewFavorites = setInterval(() => this.buscarLotesFavoritos(), 700);
    setInterval(() => clearInterval(findNewFavorites), 750);
  }

  render() {
    let { obj, type, greenHT, blackHT, referenciaSecundaria, store } = this.props;
    let favorite = null, colorProgressBar;
    var loteHT, BackgroundLote;
    var { lead_time, tempo_ciclo } = obj;
    var leadTimeBadge = lead_time.cor != 'verde' && lead_time.cor != null && obj.type != 'process';
    let leadtimes = store.flowViewLeadTimes;
    let temposCiclo = store.flowViewTemposCiclo;
    let favorites = this.props.favorites;
    let porcProgressBar;
    let hasComments = obj.comentarios !== undefined && obj.comentarios.length > 0;
    let colorBookmark = hasComments ? (obj.comentarios[0].color === "#fff" ? "#143055" : obj.comentarios[0].color) : "#143055";

    favorite = favorites.find(f => f.lote.id == obj.fk_id_batches && f.Status !== false);

    BackgroundLote = {
      background: !obj.isSession ? '#bac0d6' : '#E6E6E6',
      fontWeight: !obj.isSession ? 'bold' : '',
      color: '#626262',
    };

    if (type == 'process') {
      porcProgressBar = lead_time.porcentagem_processo;
      widthProgressBar = Math.min(porcProgressBar, 100);

      switch (lead_time.cor_processo) {
        case 'verde':
          colorProgressBar = listStyles.progress_bar_green;
          break;

        case 'amarelo':
          colorProgressBar = listStyles.progress_bar_yellow;
          break;

        case 'vermelho':
          colorProgressBar = listStyles.progress_bar_red;
          break;
      }
    }

    loteHT = this.props.holdingTimeCalculado != undefined ? this.props.holdingTimeCalculado.find(ht => ht.loteDestino.id == obj.fk_id_batches) : false;

    if (loteHT && !loteHT.inconsistente && loteHT.porcentagem >= parseFloat(greenHT.value) && loteHT.porcentagem < parseFloat(blackHT.value)) {
      BackgroundLote.background = '#B70909';
      BackgroundLote.color = '#fff';
    }

    if (loteHT && !loteHT.inconsistente && loteHT.porcentagem >= parseFloat(blackHT.value)) {
      BackgroundLote.background = '#000';
      BackgroundLote.color = '#fff';
    }

    var corLTHelper = {
      amarelo: '#EC981B',
      vermelho: '#C13929',
      verde: ''
    }

    var numeroSecundario = obj.num_secundario != null ? obj.num_secundario : '-';

    return (
      <li style={{ borderBottom: '1px solid #888', margin: '1px 0' }}>
        {

          <ContextMenu id={'lot' + obj.fk_id_batches + '_' + obj.fk_id_stages + '_' + obj.cycle + '_' + obj.fk_id_equipments + '_' + this.props.tipo}>
              {
              hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
              <MenuItem data={{ id: obj.fk_id_products }} onClick={() => this.props.handleModal('listWaitingBatchStage', '', '', '', this.props.listOthers, '', this.props.stage)}>
                  {this.props.t("actions.detailFifoStage")}
                </MenuItem>
                :
                null
            }
            
            {hasRole(['ROLE_LOTE_FAVORITO_VISUALIZAR', 'ROLE_FLOW_VIEW_VISUALIZAR']) ?
              favorite ?
                <MenuItem onClick={() => this.setLoteFavorito(obj.fk_id_batches, this.props.favorites, null, false)}>
                  {this.props.t("actions.removeFavorite", { primaryReference: utils.getReferenciaLoteOrdem().toLowerCase() })}
                </MenuItem>
                :
                <MenuItem onClick={() => this.setLoteFavorito(obj.fk_id_batches, this.props.favorites, null, true)}>
                  {this.props.t("actions.addFavorite", { primaryReference: utils.getReferenciaLoteOrdem().toLowerCase() })}
                </MenuItem>
              :
              null
            }
            {
              hasRole(['ROLE_MOVIMENTACAO_LOTE_FLOW_VIEW_CRIAR']) ?
                <MenuItem data={{ id: obj.fk_id_products }} onClick={() => this.props.handleModal('moveBatch', '', '', '', obj, '', this.props.stage, '', this.props.indexFifo)}>
                  {this.props.t("actions.moveToEquipment")}
                </MenuItem>
                :
                null
            }
          
            {
              hasRole(['ROLE_FLOW_VIEW_VISUALIZAR']) ?
                <MenuItem
                  onClick={() => this.props.handleModal('batchComments', obj.fk_id_batches, '', '', obj)}>
                  {this.props.t("actions.comments")}
                </MenuItem>
                :
                null
            }
          </ContextMenu>
        }
        <ContextMenuTrigger id={'lot' + obj.fk_id_batches + '_' + obj.fk_id_stages + '_' + obj.cycle + '_' + obj.fk_id_equipments + '_' + this.props.tipo}>

          <div className={listStyles.batch_compact + ' ' + listStyles.batch_compact_process}>
            <div style={{ height: '100%' }}>
              <div className={listStyles.cont_icon} style={{ background: BackgroundLote.background }}>
                <div className={!obj.master || leadTimeBadge ? listStyles.lt_badge : listStyles.normal}>
                  <div style={{ display: 'flex', height: '100%' }}>
                    {
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '19px', marginRight: '1px' }}>
                        {favorite ? <i style={{ fontSize: '14px' }} className={'material-icons ' + listStyles.batch_compact_star}>star</i> : null}
                        {/* {hasComments ? <i style={{ fontSize: '14px' }} className={'material-icons ' + listStyles.batch_compact_comment}>comment</i> : null} */}
                      </div>
                    }
                    {
                      hasComments ?
                        <div style={{ backgroundColor: colorBookmark, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ backgroundColor: colorBookmark }} className={listStyles.list_badge}>
                            <i className='fa fa-solid fa-bookmark' style={{ color: '#e6e6e6', fontSize: '13px', padding: '2px' }}></i>
                          </div>
                        </div>
                        : null
                    }
                    {
                      leadTimeBadge
                        ?
                        <div style={{ backgroundColor: corLTHelper[lead_time.cor] || '#B5D7F3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ backgroundColor: corLTHelper[lead_time.cor] }} className={listStyles.list_badge}>
                            <i className='material-icons' style={{ color: '#E6E6E6', fontSize: '1.6em', padding: '2px' }}>info</i>
                          </div>
                        </div>
                        : null
                    }
                    {
                      obj.inconsistente
                        ?
                        <div className={listStyles.tooltipWarning}>
                          <i className="fa fa-chain-broken" style={{ color: 'white', fontSize: '1.3em', padding: '3px' }}></i>
                          <span className={listStyles.tooltipTextWarning}>
                            {obj.tp_error}
                          </span>
                        </div>
                        : null
                    }
                  </div>
                </div>
              </div>
              <div className={listStyles.batchTooltip}>
                <div className={listStyles.toolTipText}>
                  <div style={{ fontWeight: 600, color: '#fff' }}>{!referenciaSecundaria ? obj.num : numeroSecundario} | {obj.sku}</div>
                  <div style={{ color: '#fff' }}>{obj.product}</div>
                </div>
                <div style={BackgroundLote} className={[listStyles[this.props.className], listStyles.flowViewBatch].join(' ')}>
                  <div style={{ fontWeight: 600 }}>{!referenciaSecundaria ? obj.num : numeroSecundario} | {obj.cod || obj.sku}</div>
                  <div>{obj.desc || obj.product}</div>
                </div>
              </div>
              {
                leadtimes && !obj.inconsistente ?
                  <div className={listStyles.lead_time}>
                    <div className={leadtimes ? listStyles.tooltip : 'hidden'}>
                      {lead_time.real_etapa + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("labels.stageLeadTime")}</span>
                    </div>
                    <div className={leadtimes ? listStyles.tooltip : 'hidden'}>
                      {lead_time.real_acumulado + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("labels.accumulatedLeadTime")}</span>
                    </div>
                  </div>
                  : null
              }
              {
                temposCiclo && !obj.inconsistente ?
                  <div className={listStyles.lead_time}>
                    <div className={temposCiclo ? listStyles.tooltip : 'hidden'}>
                      {tempo_ciclo.tempo_padrao + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("labels.defaultTime")}</span>
                    </div>
                    <div className={temposCiclo ? listStyles.tooltip : 'hidden'}>
                      {tempo_ciclo.tempo_real + 'h' || 0}
                      <span className={listStyles.toolTipText}>{this.props.t("labels.defaultTimeOEE")}</span>
                    </div>
                  </div>
                  : null
              }
            </div>
          </div>
        </ContextMenuTrigger>
      </li>
    )
  }
}

export default withTranslation("flowView")(Lot);