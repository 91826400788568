import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './pipefyTable.scss';
import PipefyTableHeader from './PipefyTableHeader';
import PipefyTableBody from './PipefyTableBody';

export default class PipefyTable extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <table className={styles.pipefy_table}>
                <PipefyTableHeader header={this.props.header} />
                
                <PipefyTableBody data={this.props.data} />
            </table>
        );
    }
}

PipefyTable.PropTypes = {
    data: PropTypes.array.isRequired,
    header: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired
    }))
};