import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './sidebar.scss';
import Utils from '../utils/Utils';
import LinkClientV2 from './LinkClientV2';
import { withTranslation } from 'react-i18next';

class Sidebar extends Component {
  render() {
    let URL = window.location.href.split('/');
    URL = URL[URL.length - 1];

    return (
      <aside className={styles.sidebar} style={{ height: this.props.heightContainer, justifyContent: this.props.center, paddingTop: this.props.padding }}>
        <div className={(URL == 'equipamento' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/equipamento'}>
            <i className='material-icons'>settings</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.equipment')}</div>
        </div>
        <div className={(URL == 'produto' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/produto'}>
            <i className='material-icons'>eject</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.products')}</div>
        </div>
        <div className={(URL == 'usuario' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/usuario'}>
            <i className='material-icons'>person</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.users')}</div>
        </div>
        <div className={(URL == 'grupo-de-usuario' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/grupo-de-usuario'}>
            <i className='fa fa-users'></i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.userGroups')}</div>
        </div>

        <div className={(URL == 'grupo-apontamento-equipamento' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <LinkClientV2 to={'/grupo-apontamento-equipamento'} >
            <img style={{ marginTop: "0.4rem", width: "25px", height: "25px" }} src="../../assets/icone-permissao-por-equipamento.svg" />
          </LinkClientV2>
          <div className={styles.tooltip}>{this.props.t('titles.equipmentPermission')}</div>
        </div>

        <div className={(URL == 'estoque' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <LinkClientV2 to={'estoque'} >
            <img style={{ marginTop: "0.4rem", height: "25px" }} src="../../assets/warehouse.svg" />
          </LinkClientV2>
          <div className={styles.tooltip}>{this.props.t('titles.stock')}</div>
        </div>

        <div className={(URL == 'campo-personalizado' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_primary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/campo-personalizado'}>
            <i className="material-icons">border_color</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.customFields')}</div>
        </div>
        <div className={(URL == 'area-produtiva' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/area-produtiva'}>
            <i className='material-icons'>view_module</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.fields')}</div>
        </div>
        <div className={(URL == 'unidade-industrial' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/unidade-industrial'}>
            <i className='material-icons'>bookmark_border</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.industrialUnits')}</div>
        </div>
        <div className={(URL == 'rota' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/rota'}>
            <i className='material-icons'>timeline</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.routes')}</div>
        </div>
        <div className={(URL == 'predecessor' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/predecessor'}>
            <i className='material-icons'>link</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.batchLinks', { primaryReference: Utils.getReferenciaLotesOrdens() })}</div>
        </div>
        <div className={(URL == 'evento' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/evento'}>
            <i className='material-icons'>flag</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.events')}</div>
        </div>
        <div className={(URL == 'lote' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/lote'}>
            <i className='material-icons'>widgets</i>
          </Link>
          <div className={styles.tooltip}>{Utils.getReferenciaLotesOrdens()}</div>
        </div>
        <div className={(URL == 'etapa' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_secondary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/etapa'}>
            <i className='material-icons'>format_list_numbered</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.stages')}</div>
        </div>
        <div className={(URL == 'grupo-de-equipamento' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_tertiary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/grupo-de-equipamento'}>
            <img style={{ width: '32px', height: '32px', marginTop: '0.19rem', marginLeft: '-0.21rem' }} src="../assets/iconaee.svg" />
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.groupEquipment')}</div>
        </div>
        <div className={(URL == 'opcoes-de-calculo' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_tertiary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/opcoes-de-calculo'}>
            <i className='material-icons'>playlist_add_check</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.availability')}</div>
        </div>
        <div className={(URL == 'indicadores-personalizados' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_tertiary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <LinkClientV2 to={'/indicadores-personalizados'} >
            <img src="../../assets/icon-indicadores-personalizados.svg" />
          </LinkClientV2>
          <div className={styles.tooltip}>{this.props.t('titles.customIndicators')}</div>
        </div>
        <div className={(URL == 'turno' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_tertiary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/turno'}>
            <i className='material-icons'>wb_sunny</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.shifts')}</div>
        </div>
        <div className={(URL == 'manutencao' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_tertiary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/manutencao'}>
            <img src='../assets/hammer.svg' />
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.maintenance')}</div>
        </div>
        <div className={(URL == 'agrupador-manutencao' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_tertiary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <LinkClientV2 to={'/agrupador-manutencao'} >
            <img src="../../assets/icon-agrupador-manutencao.svg" />
          </LinkClientV2>
          <div className={styles.tooltip}>{this.props.t('titles.groupMaintenance')}</div>
        </div>
        <div className={(URL == 'grupo-de-evento' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_quaternary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <Link to={'/grupo-de-evento'}>
            <i className="material-icons">dashboard</i>
          </Link>
          <div className={styles.tooltip}>{this.props.t('titles.groupEvent')}</div>
        </div>
        <div className={(URL == 'setup-guiado' ? styles.active + ' ' : '') + styles.hex + ' ' + styles.hex_quaternary}>
          <div className={styles.hex_1}></div>
          <div className={styles.hex_2}></div>
          <LinkClientV2 to={'/setup-guiado'} >
            <img src="../../assets/icon-setup-guiado.svg" />
          </LinkClientV2>
          <div className={styles.tooltip}>{this.props.t('titles.assistedEvent')}</div>
        </div>
      </aside>
    );
  }
}

export default withTranslation('common')(Sidebar);