import React, { Component } from 'react';
import styles from './route.scss';
import Button from '../../common/Button';
import listDStyles from './listDrop.scss';
import RouteEquipment from './RouteEquipment';
import Toggle from 'material-ui/Toggle';
import { observer, inject } from 'mobx-react';
import { APIEndpoints } from '../../common/Constants';
import { NButton } from '../../common/NForm';
import AlertContainer from '../../common/Alerta/alertContainer';
import AlertContent from '../../common/Alerta/alertContent';
import DeleteHoldingTime from '../../common/Alerta/customAlert/Rotas/deleteHoldingTime';
import _ from 'lodash';
import { request } from '../../common/request';
import { hasRole } from '../../helper/SecurityHelper';
import { MenuItem } from 'material-ui';
import { SelectField } from '../../common/MaterialForm';
import { withTranslation } from 'react-i18next';

@inject('store')
@observer
class ModalOneDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parallel: false,
      parallel_extended: false,
      etapaSimultanea: false,
      etapaNaoLinear: false,
      etapaOpcional: false,
      tagsTipoProducao: [],
      tipoProducao: null,
      routeOrders: []
    }
    this._onDrop = this._onDrop.bind(this);
    this._onOver = this._onOver.bind(this);
    this._onSave = this._onSave.bind(this);
    this._onChangeTipoProducao = this._onChangeTipoProducao.bind(this);

    this.modal = this.props.store.modalCommon
    this.timeMachineRotas = this.props.store.timeMachineRotas;
    this.alertDeleteHoldingTime = this.props.store.alertDeleteHoldingTime;
  }

  _onOver(event) {
    event.preventDefault();
  }

  _onDrop(event) {
    let equipment = event.dataTransfer.getData('equipment');
    if (equipment !== undefined && equipment != '') {
      this.props.addRoute(equipment);
    }
  }

  async _onSave() {
    let alertContent = <AlertContainer>
      <AlertContent title={this.props.t("drop.alerts.title")} subtitle={this.props.t("drop.alerts.holdingTime")}>
        <DeleteHoldingTime saveRoutes={this.props.saveRoutes} hideModal={this.props.hideModal} />
      </AlertContent>
    </AlertContainer>

    var rotasAnteriores;
    var sessionId = localStorage.getItem('sessionId');
    await request(`${APIEndpoints.ROTA}/produto/${this.props.obj.product.id}/unidadeIndustrial/${sessionId}`, { method: 'get' })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      }
      ).then(data => {
        rotasAnteriores = data;
      });

    var arrayStageAtual = [];
    var arrayStageAntigo = [];

    rotasAnteriores.forEach(stage => {
      arrayStageAntigo.push(stage.etapa_atual.id + '#' + stage.ordem);
    });

    this.props.obj.product.routes.forEach(stage => {
      arrayStageAtual.push(stage.fk_id_stages + '#' + stage.ordem);
    });

    arrayStageAtual = _.uniq(arrayStageAtual);
    arrayStageAntigo = _.uniq(arrayStageAntigo);

    var stagesChanged = false;

    if (arrayStageAtual.length != arrayStageAntigo.length) {
      stagesChanged = true;

    } else {
      for (var i = 0; i <= arrayStageAtual.length; i++) {
        if (arrayStageAtual[i] == arrayStageAntigo[i]) {
          stagesChanged = false;
        } else {
          stagesChanged = true;
          // Se houver alteração, sai do loop
          break;
        }
      }
    }

    if (stagesChanged) {
      await request(`${APIEndpoints.HOLDING_TIME}/produto/${this.props.obj.product.id}`, { method: 'get' })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(data => {
          if (data.length > 0) {
            this.props.hideModal();
            this.alertDeleteHoldingTime.setCustomNoFunction(this.props.hideModal);
            this.modal.open(alertContent, []);
          } else {
            this.props.saveRoutes();
          }
        });
    } else {
      this.props.saveRoutes();
    }
  }

  _onChangeTipoProducao(ids, value) {
    this.props.onChangeTipoProducao(ids, value);
  }

  buildRouteOrder(data) {
    if (data)
      return data.map(m => (m.ordem));

    return [];
  }

  render() {
    let { equipments, routes, ComEquipamento } = this.props;

    let eqps = equipments.filter(equipment => {
      let rts = routes.filter(route => route.fk_id_equipments == equipment.id);

      if (!rts || !rts.length) return false;

      return true;
    });

    return (
      <div className={styles.modal_step_one_drop}>
        {
          ComEquipamento
            ?
            <div>
              <ul className={listDStyles.list_drop_titles}>
                <li>{this.props.t("drop.registeredEquipment")}</li>
                <li>{this.props.t('stepOne.waitTimeShort')}</li>
                <li>{this.props.t('stepOne.cycleTimeShort')}</li>
                <li>{this.props.t('stepOne.quantityShort')}</li>
                <li>{this.props.t('stepOne.conversionFactorShort')}</li>
                <li className={listDStyles.head_oee}>OEE (%)</li>
              </ul>
              <ul onDragOver={this._onOver} onDrop={this._onDrop} className={listDStyles.modal_step_ap}>
                {
                  eqps.length > 0 ? equipments.filter(equipment => {
                    let rts = routes.filter(route => route.fk_id_equipments == equipment.id);
                    if (!rts || !rts.length) return false;
                    let rtsOrder = this.buildRouteOrder(this.props.routes);
                    equipment.stage = rts[0].fk_id_stages;
                    equipment.master = rts[0].master;
                    equipment.cycle = rts[0].cycle;
                    equipment.wait_time_default = rts[0].wait_time_default;
                    equipment.wait_time = rts[0].wait_time;
                    equipment.proc_time = rts[0].proc_time;
                    equipment.oee = rts[0].oee;
                    equipment.oee_padrao = rts[0].oee_padrao;
                    equipment.quantity = rts[0].quantity;
                    equipment.QuantityConversion = rts[0].QuantityConversion;
                    equipment.is_batch = equipment.is_batch;
                    this.state.parallel = rts[0].parallel;
                    this.state.parallel_extended = rts[0].parallel_extended;
                    this.state.etapaSimultanea = rts[0].EtapaSimultaneo;
                    this.state.etapaNaoLinear = rts[0].EtapaNaoLinear;
                    this.state.etapaOpcional = rts[0].EtapaOpcional;
                    this.state.tagsTipoProducao = rts[0].EtapaAtual == undefined ? [] : rts[0].EtapaAtual.TipoProducao;
                    this.state.tipoProducao = this.props.routes[0].TipoProducao == null ? "" : this.props.routes[0].TipoProducao;
                    this.state.routeOrders = rtsOrder;

                    return true
                  }).map((equipment) => <RouteEquipment {...this.props} key={equipment.id} idx={equipment.id} equipment={equipment} onChange={this.props.saveRouteEquipment} />)
                    : <li><div>{this.props.t("drop.dropHere")}</div></li>
                }
              </ul>
              <div className={listDStyles.parallel_container}>
                <div className={listDStyles.parallel}>
                  <Toggle
                    label={`${this.props.t('stepOne.horizontallyScaled')}:`}
                    toggled={this.state.etapaSimultanea}
                    onToggle={() => this.props.onToggle(this, 'EtapaSimultaneo')}
                    defaultToggled={false}
                    labelStyle={{ color: '#5b5b5b' }}
                    thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                    trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                    style={{ marginBottom: '.3em' }}
                  />
                  <Toggle
                    label={`${this.props.t('stepOne.verticallyScaled')}:`}
                    toggled={this.state.parallel}
                    onToggle={() => this.props.onToggle(this, 'parallel')}
                    defaultToggled={false}
                    labelStyle={{ color: '#5b5b5b' }}
                    thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                    trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                    style={{ marginBottom: '.3em' }}
                  />
                  <Toggle
                    label={`${this.props.t('stepOne.verticallyExtended')}:`}
                    toggled={this.state.parallel_extended && this.state.parallel}
                    onToggle={() => this.props.onToggle(this, 'parallel_extended')}
                    defaultToggled={false}
                    labelStyle={{ color: '#5b5b5b' }}
                    thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                    trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                    disabled={!this.state.parallel}
                  />
                </div>
                <div className={listDStyles.parallel}>
                  <Toggle
                    label={`${this.props.t('stepOne.notLinearStage')}:`}
                    toggled={this.state.etapaNaoLinear}
                    onToggle={() => this.props.onToggle(this, 'EtapaNaoLinear')}
                    defaultToggled={false}
                    labelStyle={{ color: '#5b5b5b' }}
                    thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                    trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                    style={{ marginBottom: '.3em' }}
                  />
                  <Toggle
                    label={`${this.props.t('stepOne.optionalStage')}:`}
                    toggled={this.state.etapaOpcional}
                    onToggle={() => this.props.onToggle(this, 'EtapaOpcional')}
                    defaultToggled={false}
                    labelStyle={{ color: '#5b5b5b' }}
                    thumbSwitchedStyle={{ backgroundColor: '#2D4F7F' }}
                    trackSwitchedStyle={{ backgroundColor: '#3E7AD1' }}
                  />
                </div>
                {
                  (this.state.tagsTipoProducao
                    && this.state.tagsTipoProducao.tipos != null
                    && this.state.etapaNaoLinear)
                    ?
                    <SelectField
                      id="tipo_producao"
                      name="tipo_producao"
                      floatingLabelText={this.props.t('stepOne.productionType')}
                      value={this.state.tipoProducao}
                      onChange={(event, index, value) => this._onChangeTipoProducao(this.state.routeOrders, value)}
                    >
                      {this.state.tagsTipoProducao.tipos.map(e => { return <MenuItem key={e} value={e} primaryText={e} /> })}
                    </SelectField>
                    : null
                }
              </div>
              <div className={styles.modal_step_footer}>
                <div>
                </div>
                <div>
                  <NButton
                    type="button"
                    onClick={this._onSave}
                    disabled={((!hasRole('ROLE_ROTAS_CRIAR') && this.props.obj.typeModal === 'add') || (!hasRole('ROLE_ROTAS_ATUALIZAR') && this.props.obj.typeModal === 'up'))}
                  >{this.props.t("drop.save")}</NButton>
                </div>
                <div><Button button={{ type: 'round', text: this.props.t("drop.cancel"), onClick: this.props.handleModal }} /></div>
              </div>
            </div>
            :
            <div className={'flex flex-just-end'}>
              <div>
                <NButton
                  type="button"
                  onClick={this.props.saveRoutes}
                  disabled={((!hasRole('ROLE_ROTAS_CRIAR') && this.props.obj.typeModal === 'add') || (!hasRole('ROLE_ROTAS_ATUALIZAR') && this.props.obj.typeModal === 'up'))}
                >{this.props.t("drop.save")}</NButton>
              </div>
              <div><Button button={{ type: 'round', text: this.props.t("drop.cancel"), onClick: this.props.handleModal }} /></div>
            </div>
        }
      </div>
    );
  }
}

export default withTranslation('routes')(ModalOneDrop);
