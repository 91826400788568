import React, { Component } from 'react'

import { default as MaterialAutoComplete } from 'material-ui/AutoComplete';

export class AutoComplete extends MaterialAutoComplete {
    render() {
        return (
            <MaterialAutoComplete
                underlineFocusStyle={{ borderColor: '#000000' }}
                floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
                popoverProps={{ className: 'autocomplete' }}
                {...this.props}
            />
        )
    }
}