import React, { Component } from 'react';
import listStyles from './list.scss';
import Lot from './Lot';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
moment.locale('pt-br');

class ListOthers extends Component {
  render() {
    let {isBlur, setShow, listOthers, handleModal, stage, store} = this.props;
   
    let lots = [];
    listOthers
    .sort((a, b) => {
      return new Date(b.reference_time).getTime() - new Date(a.reference_time).getTime();
    })
    .map((obj, i) => {
      obj.cod = obj.sku;
      obj.desc = obj.product;
      lots.push(<Lot type="others" className='others' {...this.props} store={store} opQualidade={true} indexFifo={i} key={i} obj={obj}/>);
    });
    
    return (
      <ul className={isBlur?listStyles.list_others+' blur':listStyles.list_others}>
        { lots.length > 0 ?<div onClick={() => handleModal('listWaitingBatchStage','','', listOthers, '', stage)}>{this.props.t("labels.waiting")}</div>:null}
        { lots }
      </ul>
    );
  }
}

export default withTranslation("flowView")(ListOthers);
